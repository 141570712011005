/**
 * 
 * @typedef Request
 * @property {String|null} sort
 * @property {String|null} search
 * @property {String} endDate
 * @property {String} statDate
 * @property {Array} channelIds
 * @property {String} communityId
 */

class ListEventsRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        sort,
        search,
        endDate,
        startDate,
        channelIds,
        communityId,
    }) {
        this.sort = sort
        this.search = search
        this.endDate = endDate
        this.startDate = startDate
        this.channelIds = channelIds
        this.communityId = communityId
    }


    getPayload = () => {
        
        const payload = {
            "end_date": this.endDate,
            "start_date": this.startDate,
            "community_id": this.communityId,
            "use_description_media_type": true,
        }

        if (this.search) {
            payload['search'] = this.search
        }
        
        if (this.sort) {
            payload['sort'] = this.sort
        }
        
        if (this.channelIds.length > 0) {
            payload["channel_ids"] = this.channelIds
        }

        return payload
    }
    
}

export default ListEventsRequest