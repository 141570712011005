import i18next from "i18next"
import { pickBy, identity, isEmpty, mapKeys } from "lodash"
import Config from "../../config"
import { filterNulls } from "../../utils/helper"

class UpdateProfileRequest {

    constructor(request) {
        
        this.otp = request?.otp
        this.email = request?.email
        this.data = request?.data //Email or Phone Number
        this.lastName = request?.lastName
        this.firstName = request?.firstName
        this.phoneNumber = request?.phoneNumber
    }


    getPayload = () => {
        
        let payload = {
            "family_name": this.lastName,
            "given_name": this.firstName,
        }

        return filterNulls(payload)
    }
    
    getEmailUpdatePayload = () => {
        
        let payload = {
            "debug": Config.isLocal,
            "email": this.email,
        }

        return filterNulls(payload)
    }

    getPhoneUpdatePayload = () => {
        
        let payload = {
            "debug": Config.isLocal,
            "phone_number": this.phoneNumber,
        }

        return filterNulls(payload)
    }
    
    getPhoneValidatePayload = () => {
        
        let payload = {
            "phone_number": this.data,
            "verification_token": this.otp,
        }

        return filterNulls(payload)
    }

    getEmailValidatePayload = () => {
        
        let payload = {
            "email": this.data,
            "verification_token": this.otp,
        }

        return filterNulls(payload)
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validator = () => {
        
        const errors = []

        if (isEmpty(this.lastName)) {
            errors.push({
                field: 'lastName',
                isValid: false,
                message: i18next.t('requestObjects.UpdateProfileRequest.required.firstname'),
            })
        }

        if (isEmpty(this.firstName)) {
            errors.push({
                field: 'firstName',
                isValid: false,
                message: i18next.t('requestObjects.UpdateProfileRequest.required.lastname'),
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }
}

export default UpdateProfileRequest