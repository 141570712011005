import { actions, constants } from '../constants'
import { map } from 'lodash'
import i18next from 'i18next'

const { CHANNELS } = actions

const initialState = {
    loading: false,
    channels: [],
    filters: {},
    channelDetails: null,
    pagination: [],
    errors: null,
    messages: null,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case CHANNELS.RESET: {
            return {
                ...state, 
                loading: false, 
                pagination: [], 
                channels: [], 
                filters: {}, 
                errors: null, 
                messages: null, 
                channelDetails: null
            }
        }
        case CHANNELS.START: {
            return {...state, loading: true}
        }
        case CHANNELS.START_MEMBER_LOAD: {
            return {
                ...state,
                channelDetails: {
                    ...state.channelDetails,
                    members: {
                        loading: true
                    }
                }
            }
        }
        case CHANNELS.CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null, errorCode: null}
        }
        case CHANNELS.ERROR: {
            let error = action.payload.response || {}

            const errorCode = error?.data?.code ?? null
            error = error.data ? error.data.message : "error.errorOccurred"
            
            return {
                ...state, 
                loading: false, 
                errors: error, 
                errorCode: errorCode, 
                messages: null, 
                channelDetails: {
                    ...state.channelDetails, 
                    loading: false,
                    members: {
                        loading: false,
                    }
                }
            }
        }
        case CHANNELS.SUCCESS: {
            return {...state, loading: false, errors: null, messages: action.payload}
        }
        case CHANNELS.SAVE: {
            return {
                ...state, 
                loading: false, 
                channels: action.payload.data,
                channelsTransformed: map(action.payload.data, c => {
                    
                    let tags = map(c.tags, r => {return {color: 'color-gray', name: r}})
                    
                    return {
                        ...c,
                        is_visible: !c.is_hidden,
                        tags: tags,
                        actions: [
                            constants.MENU_ACTION_DELETE,
                        ]
                    }
                }), 
                pagination: action.payload.pagination
            }
        }
        case CHANNELS.SAVE_CHANNEL_DETAILS: {
            return {
                ...state, 
                loading: false,
                channelDetails: {
                    ...state.channelDetails,
                    ...action.payload,
                    loading: false,
                    tags: map(action.payload.tags, 'id'),
                }, 
            }
        }
        case CHANNELS.SAVE_CHANNEL_DETAILS_MEMBERS: {
            return {
                ...state, 
                channelDetails: {
                    ...state.channelDetails,
                    loading: false,
                    members: {
                        loading: false,
                    },
                    pagination: action.payload.pagination,
                    users: map(action.payload.data, c => {

                        const tags = map(c.tags, r => {return {color: 'color-primary', name: r.name}})
                        
                        const platforms = {}

                        if (c.reachable_via_app) {
                            platforms['app'] = {
                                name: i18next.t('notices.detail.table.pills.app'),
                            }
                        }

                        if (c.reachable_via_email) {
                            platforms['email'] = {
                                name: i18next.t('notices.detail.table.pills.email'),
                            }
                        }
                        
                        if (c.reachable_via_sms) {
                            platforms['sms'] = {
                                name: i18next.t('notices.detail.table.pills.sms'),
                            }
                        }
                        
                        if (c.reachable_via_whatsapp && c.reachable_via_whatsapp_visible) {
                            platforms['whatsapp'] = {
                                name: i18next.t('notices.detail.table.pills.whatsapp'),
                            }
                        }

                        return {
                            ...c,
                            is_checked: false,
                            first_name: c.first_name,
                            last_name: c.last_name,
                            email: c.email,
                            id_number: c.id_number,
                            reference: c.reference,
                            tags: tags,
                            platforms: platforms,
                            actions: [
                                constants.MENU_ACTION_REMOVE,
                            ]
                        }
                    }), 
                }, 
            }
        }
        case CHANNELS.SAVE_CHANNEL_DETAILS_ATTACHABLE_MEMBERS: {
            return {
                ...state, 
                channelDetails: {
                    ...state.channelDetails,
                    loading: false,
                    persons: map(action.payload, u => {return {name: u.name || "Unknown", value: u.id}}),
                }, 
            }
        }
        case CHANNELS.SAVE_FILTERS: {
            return {
                ...state, 
                loading: false, 
                filters: {
                    tags: map(action.payload.tags, r => {return {key: r.id, value: r.id, text: r.name}}),
                },
            }
        }
        case CHANNELS.SAVE_CHANNEL_DETAILS_ATTACHABLE_MEMBERS_FILTERS: {
            return {
                ...state, 
                channelDetails: {
                    ...state.channelDetails,
                    loading: false, 
                    filters: {
                        tags: map(action.payload.tags, r => {return {key: r.id, value: r.id, text: r.name}}),
                    },
                }
            }
        }
        case CHANNELS.CLEAR_CHANNEL_DETAILS: {
            return {
                ...state, 
                loading: false,
                channelDetails: null, 
            }
        }
        default: {}
    }

    return state
}