import i18next from "i18next"
import { every, filter, find, first, has, identity, isEmpty, isNil, isUndefined, map, mapKeys, omit, pickBy, reverse, size, toString, get } from "lodash"
import moment from "moment"
import { formTypes, messageBuilder, paymentTypes, templateIcons } from "../../constants"
import { filterNulls, formatMoneyLocale, getTransformedFiles, getTransformedFilesForUpdate, isPartiallyUploaded, websiteValidator } from "../../utils/helper"

class PostCreateNoticeRequest {

  constructor({
    users,
    pinItem,
    channels,
    noticeId,
    important,
    components,
    deadlineAt,
    noticeTitle,
    communityId,
    pinUntilDate,
    publishRelationship,
    sendDeadlineReminders,
    hasHardDeadline,
  }) {
    this.users = users
    this.pinItem = pinItem
    this.noticeId = noticeId
    this.channels = channels
    this.important = important
    this.components = components
    this.noticeTitle = noticeTitle
    this.communityId = communityId
    this.pinUntilDate = pinUntilDate
    this.publishRelationship = publishRelationship
    this.deadlineAt = deadlineAt
    this.sendDeadlineReminders = sendDeadlineReminders
    this.hasHardDeadline = hasHardDeadline
  }

  // Since a form component has more types
  determineFormPostData = (item) => {
    switch (item.data.formType) {
      case formTypes.yesNo.post_name:
      case formTypes.textField.post_name:
      case formTypes.fileUpload.post_name:
      case formTypes.imageUpload.post_name: {

        const payload = {
          type: item.data.formType,
          required: item.data.isRequired ?? false,
          title: item.data.question,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.title) {
          errors.push({
            field: "title",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.form.requiredTitle')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        return payload
      }
      case formTypes.multipleChoice.post_name: {

        const payload = {
          type: item.data.formType,
          title: item.data.question,
          options: map(item.data.options, "option"),
          required: item.data.isRequired ?? false,
          min: item.data.singleSelect ? 1: item.data.min,
          max: item.data.singleSelect? 1: item.data.max,
          single_selection: item.data.singleSelect, // @TODO: To be removed in favour of [min=1,max=1]
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.title) {
          errors.push({
            field: "title",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.form.requiredTitle')
          })
        }

        if (size(payload.options) === 0) {
          errors.push({
            field: "options",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.form.requiredOptions')
          })
        }
        
        // When there are options, we need to make sure that at least one of them has a valid name
        if (payload.options.length > 0 && (payload.options.includes(undefined) || payload.options.includes(null) || payload.options.includes(""))) {
          errors.push({
            field: "options",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.form.invalidOptionNames')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        return payload
      }
      default:
        return {
          type: item.data.formType
        }
    }
  }

  /**
   * Create POST Body content from the given component item data
   * @param {Component} item 
   */
  transformComponent = (item) => {

    let payload = {
      type: item?.data?._type ?? item.type, // We have temporary types in [Album] components, we need to make sure we also identify them correctly
    }

    switch (payload.type) {
      case templateIcons.notice: {
        payload = {
          type: item.type,
        }
        break
      }
      case templateIcons.event: {

        // When an event is an all day event we take out the times
        let sDate = moment(`${item.data.startDate}T${item.data.allDay ? '00:00:00.000Z' : item.data.startTime}`)
        let eDate = moment(`${item.data.endDate}T${item.data.allDay ? '00:00:00.000Z' : item.data.endTime}`)

        // When we have an all day event and we have the same start date and end date
        // we should offset the end dates times to end of day
        // this will basically make the timestamp = midnight
        if (item.data.allDay) {

          const newSDate = sDate.clone() // fresh start date  
          
          if (newSDate.isSame(eDate)) {
            eDate = newSDate.endOf("day")
          }
        }

        payload = {
          type: item.type,
          title: item.data.title,
          start_date: sDate.toISOString(),
          end_date: eDate.toISOString(),
          errors: {
            id: item.id,
          }
        }

        if (!isEmpty(item.data.content)) {
          payload["description"] = item.data.content
          payload["description_media_type"] = item.data.mediaType
        }
        

        payload["all_day"] = item.data.allDay

        payload["add_calendar"] = item.data.addToCalendar

        if (item.data.location) {
          payload["address"] = item.data.location
        }

        //?NOTE: Setting the remind minutes to zero for now, as the backend will help set this value automatically
        payload["send_reminder"] = item.data.remind
        payload["remind_minutes"] = 0

        // if (item.data.remind) {
        //   payload["remind_minutes"] = item.data.remindMinutes
        // }
        
        if (item.data.eventId) {
          payload["event_id"] = item.data.eventId
        }
        
        if (!isNil(item.data.merge_channels)) {
          payload["merge_channels"] = item.data.merge_channels
        }
        
        const errors = []

        // End date should not be before the end date
        if (!eDate.isAfter(sDate)) {
          errors.push({
            field: "endDate",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.invalidEndDate')
          })
        }

        if (item.data.type === 1 && isNil(payload.event_id)) {
          errors.push({
            field: "requiredEvent",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.event.requiredEvent')
          })
        }

        if (!payload.title) {
          errors.push({
            field: "title",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.event.requiredTitle')
          })
        }

        // if (!payload.description) {
        //   errors.push({
        //     field: "description",
        //     isValid: false,
        //     message: i18next.t('requestObjects.PostCreateNoticeRequest.event.requiredDescription')
        //   })
        // }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.attachment:
      case templateIcons.file: {

        // TODO: Files have the potential to be multiple selection in the future, we are handling that, but for now wil always pick the first
        const files = map(item?.data?.files ?? [])
        const file = first(files)
        
        payload = {
          type: item.type,
          file_id: file?.id ?? null,
          resource_category_ids: item?.data?.resourceCategoryIds ?? null,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.file_id) {
          errors.push({
            field: "file",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.file.requiredFile')
          })
        }

        if (payload.file_id && isPartiallyUploaded([payload.file_id])) {
          errors.push({
            field: "file",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.files.partialy-uploaded')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.payment: {

        payload = {
          title: item.data.title,
          payment_type: item.data.type,
          type: item.type,
          currency_iso_4217: item.data.currency,
          errors: {
            id: item.id
          }
        }

        // We are not sending through the amount for Open payments
        if (payload.payment_type != paymentTypes.open.value) {
          payload['amount_smallest_unit'] = item.data.amount
        }
        
        // For Itemised payments
        if (payload.payment_type === paymentTypes.itemised.value) {
          payload['item_quantity_limit'] = item.data.maxQuantityLimit
        }

        const errors = []

        if (item.data.showQuantity && item.data.maxQuantityLimit <= 0) {
          errors.push({
            field: "maxQuantityLimit",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.payment.maxQuantityLimitRequired')
          })
        }

        if (!payload.title) {
          errors.push({
            field: "title",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.payment.titleRequired')
          })
        }

        if (has(payload, "amount_smallest_unit") && payload["amount_smallest_unit"] <= 0) {
          errors.push({
            field: "amount",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.payment.amountRequired')
          })
        }

        if (has(payload, "amount_smallest_unit") && payload["amount_smallest_unit"] <= 100) {
          const amount = formatMoneyLocale(100, i18next.language, item.data.currency)
          errors.push({
            field: "amount",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.payment.minAmountRequired', {formattedAmount: amount})
          })
        }

        if (!payload.payment_type) {
          errors.push({
            field: "type",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.payment.typeRequired')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.text: {

        payload = {
          type: item.type,
          text: item.data.content,
          media_type: item.data.mediaType ?? messageBuilder.MARKUP.markdown,
          errors: {
            id: item.id
          }
        }

        const errors = []

        if (!payload.text) {
          errors.push({
            field: "text",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.text.requiredDescription')
          })
        }
        
        if (!isEmpty(payload.text) && isEmpty(payload.text.replace("<p></p>", "").trim())) {
          errors.push({
            field: "text",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.text.requiredDescription')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.video:
      case templateIcons.vimeo:
      case templateIcons.youTube: {

        payload = {
          url: item.data.url,
          type: item.type,
          resource_category_ids: item?.data?.resourceCategoryIds ?? null,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.url) {
          errors.push({
            field: "url",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.video.requiredUrl')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.image: {
        payload = {
          type: item.type,
          resource_category_ids: item?.data?.resourceCategoryIds ?? null,
        }

        payload["type"] = templateIcons.image
        
        const file = first(map(item.data.files))
        
        payload["image_id"] = file?.id
        
        const errors = []

        // We should have at least one image uploaded to continue
        if (size(item.data.files) == 0) {
          errors.push({
            field: "image",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.image.required.atLeastOne')
          })
        }
        
        if (isPartiallyUploaded(map(item.data.files, 'id'))) {
          errors.push({
            field: "imagePartiallyUploaded",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.files.partialy-uploaded')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.album: {

        // We can now safely assume that we are creating an Album Component
        payload["resource_category_ids"] = item?.data?.resourceCategoryIds ?? null
        payload["type"] = templateIcons.album
        payload["name"] = item.data?.albumTitle ?? null
        payload["medias"] = map(item.data?.files ?? [], file => {
          return {
            "image_id": file.id,
            "original_id": file?.originalId,
          }
        })
        
        payload["medias"] = map(payload["medias"], file => filterNulls(file))
        payload["medias"] = reverse(payload["medias"])

        const errors = []

        // We should have at least one image uploaded to continue
        if (size(item.data.files) == 0) {
          errors.push({
            field: "image",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.image.required.atLeastOne')
          })
        }

        if (isPartiallyUploaded(map(item.data.files, 'id'))) {
          errors.push({
            field: "image",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.files.partialy-uploaded')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.location: {
        payload = {
          type: item.type,
          latitude: item.data.latitude,
          longitude: item.data.longitude,
          address: item.data.location,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.address) {
          errors.push({
            field: "address",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.address.requiredAddress')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.url: {
        payload = {
          type: item.type,
          url: item.data.url,
          title: item.data.title ?? null,
          thumbnail_url: item.data.thumbnail ?? null,
          description: item.data.description ?? null,
          resource_category_ids: item?.data?.resourceCategoryIds ?? null,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.url) {
          errors.push({
            field: "url",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.url.required')
          })
        }

        // We need to validate that the URL is a valid URL
        const isValid = websiteValidator(payload.url || '')
        if (!isEmpty(payload.url) && !isValid) {
          errors.push({
            field: "url",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.url.invalid-format')
          })
        }


        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.deadline: {
        payload = {
          type: item.type,
        }
        break
      }
      case templateIcons.date: {
        payload = {
          type: item.type,
        }

        break
      }
      case templateIcons.form: {
        const postData = this.determineFormPostData(item)

        payload = postData
        break
      }
      case templateIcons.vote: {

        payload = {
          options: [],
          type: templateIcons.vote,
          results_visible_at: null,
          campaign_type: item.data.voteType,
          can_change_answer: item.data.canChangeResults,
          results_system_access: item.data.visibleFor,
          confirmation_markdown: item.data.declarationSnippet ?? '',
          is_voter_anonymous: item.data.shouldBeAnonymous,
          num_assigned_votes_person: item.data.numAssignedVotesPerson,
          min_required_votes_per_person: item.data.minRequiredVotesPerPerson,
          quorum_percentage: item.data.quorumPercentage,
        }

        if (!isEmpty(item.data.declarationSnippet)) {
          payload['confirmation_markdown'] = item.data.declarationSnippet
        }
        
        if (item.data.image_id) {
          payload['image_id'] = item.data.image_id
        }
        
        if (item.data.resultAt === 'immediately') {
          payload['results_visible_at'] = moment().toISOString()
        }
        
        if (item.data.resultAt === 'after_deadline') {
          payload['results_visible_at'] = this.deadlineAt
        }

        payload['voter_results_view_option'] = item.data.resultAt

        const optionTitleValidators = []
        payload['options'] = map(item.data.options ?? [], option => {
          
          const file = first(map(option.files))

          // Items need to have title
          optionTitleValidators.push(isEmpty(option.title))
          
          const _payload = {
            id: option.id,
            title: option.title,
            original_id: option.originalId,
            description: option.description,
            image_url: file?.url,
          }

          if (file?.id) {
            _payload['image_id'] = file.id
          }

          return _payload
        })
        
        const optionFiles = map(payload['options'], 'image_id').filter(i => !isUndefined(i))

        const errors = []
        
        // We should have at least one option
        if (item.data.resultAt === 'after_deadline' && !this.deadlineAt) {
          errors.push({
            field: "deadline",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.noDeadline.required')
          })
        }
        
        // We should have at least one option
        if (size(item.data.options) == 0) {
          errors.push({
            field: "options",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.options.required')
          })
        }

        if (isPartiallyUploaded(optionFiles)) {
          errors.push({
            field: "options",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.files.partialy-uploaded')
          })
        }
        
        // For any of the options we have, we need to make sure there is at least a title provided
        if (optionTitleValidators.includes(true)) {
          errors.push({
            field: "options",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.options.title.required')
          })
        }

        if (parseInt(item.data.quorumPercentage) > 100) {
          errors.push({
            field: "quorumPercentage",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.quorum.max.label')
          })
        }
        
        // Missing max options allowed
        const emptyAssignedVotes = size(toString(item.data.numAssignedVotesPerson)) === 0
        if (emptyAssignedVotes) {
          errors.push({
            field: "minRequiredVotesPerPerson",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.numAssignedVotesPerson.required')
          })
        }
        
        // Missing min options allowed
        const emptyMinVotes = size(toString(item.data.minRequiredVotesPerPerson)) === 0
        if (emptyMinVotes) {
          errors.push({
            field: "minRequiredVotesPerPerson",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.minRequiredVotesPerPerson.required')
          })
        }
        
        // Validate the number of votes allowed or give
        if (emptyAssignedVotes && emptyMinVotes) {
          errors.push({
            field: "minRequiredVotesPerPerson",
            isValid: false,
            message: i18next.t('general.missingFields.required')
          })
        } else {

          // Validate the number of votes allowed or give
          if ((!emptyAssignedVotes && !emptyMinVotes) && (item.data.minRequiredVotesPerPerson > item.data.numAssignedVotesPerson)) {
            errors.push({
              field: "minRequiredVotesPerPerson",
              isValid: false,
              message: i18next.t('requestObjects.PostCreateNoticeRequest.vote.maxedVotes.required', {
                min: item.data.minRequiredVotesPerPerson,
                max: item.data.numAssignedVotesPerson,
              })
            })
          }
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.iframe: {
        payload = {
          type: item.type,
          url: item.data.url,
          height: item.data.height,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.url) {
          errors.push({
            field: "url",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.iframe.requiredUrl')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.contact: {
        payload = {
          type: item.type,
          first_name: item.data.firstName,
          last_name: item.data.lastName,
          title_or_role: item.data.jobTitle,
          description: item.data.description,
          website: item.data.website,
          primary_email: item.data.email,
          secondary_email: item.data.secondaryEmail,
          birthday: item.data.birthday,
          twitter_handle: item.data.twitterHandle,
          facebook_url: item.data.facebookUrl,
          primary_phone_number: item.data.phoneNumber,
          secondary_phone_number: item.data.secondaryPhoneNumber,
          errors: {
            id: item.id,
          }
        }

        const errors = []

        if (!payload.first_name) {
          errors.push({
            field: "first_name",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.contact.requiredFirstName')
          })
        }

        if (!payload.last_name) {
          errors.push({
            field: "last_name",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.contact.requiredLastName')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.progressBar: {

        payload = {
          type: item.type,
          title: item.data.title,
          // description: item.data.description,
          from_display: item.data.fromDisplay,
          to_display: item.data.toDisplay,
          current_display: item.data.currentDisplay,
          current_percentage: item.data.currentPercentage,
          current_tint: item.data.currentTint,
          bar_tint: item.data.barTint,
        }

        if (item.data.averagePercentage > 0) {
          payload['average_display'] = item.data.averageDisplay
          payload['average_percentage'] = item.data.averagePercentage
        }

        const errors = []

        if (!payload.title) {
          errors.push({
            field: "title",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.progressBar.requiredTitle')
          })
        }

        if (!payload.current_percentage) {
          errors.push({
            field: "current_percentage",
            isValid: false,
            message: i18next.t('requestObjects.PostCreateNoticeRequest.progressBar.requiredPercentage')
          })
        }

        if (errors.length > 0) {
          payload["errors"] = {
            id: item.id,
            isValid: false,
            messages: mapKeys(errors, error => error.field)
          }
        }

        break
      }
      case templateIcons.customTemplate: {
        payload = {
          type: item.type,
        }

        break
      }
      default:
        payload = {
          type: item.type,
        }
        break
    }
   
    // When a component has resource category ids, we need to send it to the API
    if (item.resourceCategoryIds) {
      payload['resource_category_ids'] = item.resourceCategoryIds
    }

    return {
      ...payload,
      id: item.data.originalId,
    }
  }

  /**
   * Some house cleaning before we send the data to the API, we only should send the data the API requires
   * 
   * @param {*} payload 
   * @returns 
   */
  cleanPayload = (payload) => {
    payload["components"] = map(payload["components"], c => omit(c, ['errors'])) //TODO Review special cases
    payload["channel_ids"] = filter(payload["channel_ids"], c => c !== messageBuilder.ADD_INDIVIDUALS_TAG_ID) //Remove the special case for the Add Recipients tag
    
    // Convert empty strings, undefined to Null on components payload
    payload["components"] = map(payload["components"], c => filterNulls(c))

    // We will remove the special case for targeting all,
    const _channels = filter(payload["channel_ids"], c => c === messageBuilder.TARGET_ALL_TAG_ID)
    const isTargetingAll = (_channels.length > 0)
    
    //? We will also clear any channels and persons selected, although at this points its highly unlikely for these to leak through, good safeguard
    payload["target_everyone"] = isTargetingAll
    
    if (isTargetingAll) {
      payload["channel_ids"] = []
      payload["person_ids"] = []
    }

    return filterNulls(payload)
  }

  /**
   * @returns {Object}
   */
  getPayload = () => {

    // We are taking all the temporary types which are often from images and album components, and making them solid types so out request 
    // transformers can be able to parse data correctly
    //? @NOTE: This operation should only happen on create and never on Update
    const _components = map(getTransformedFiles(this.components), component => {
      return {
        ...component,
        type: component._type ?? component.type
      }
    })

    const payload = {
      "person_ids": this.users,
      "title": this.noticeTitle,
      "channel_ids": this.channels,
      "important": this.important,
      "pin_item": this.pinItem,
      "pin_until_date": this.pinUntilDate,
      "components": map(_components, component => this.transformComponent(component)),
      "community_id": this.communityId,
      "deadline_at": this.deadlineAt,
      "send_deadline_reminders": this.sendDeadlineReminders,
      "publish_relationship": this.publishRelationship,
    }

    if (this.hasHardDeadline) {
      payload["has_hard_deadline"] = true
    }

    return this.cleanPayload(payload)
  }

  /**
   * When we create a new template, we need to determine if the are any new or existing Image, File or Album components, so we can be able to map correctly for the API to mark
   * for a successful transfer of these media items.
   * 
   * @returns {Object}
   */
  getSaveTemplateAsNewPayload = (originals = []) => {

    const payload = {
      ...this.getPayload()
    }

    const _components = map(payload['components'], component => {
      
      // Handle Image Components
      if (component.type === templateIcons.image) {
        const transformed = {
          type: component.type,
          notice_template_image_component_id: component.image_id,
          resource_category_ids: component.resource_category_ids ?? null
        }

        // Was not part of the original, so we use no prefix
        if (originals.length > 0 && !originals.includes(component.id)) {
          return component
        }

        return transformed
      }
      
      // Handle File Components
      if (component.type === templateIcons.file) {
        const transformed = {
          type: component.type,
          notice_template_file_component_id: component.file_id,
          resource_category_ids: component.resource_category_ids ?? null
        }

        // Was not part of the original, so we use no prefix
        if (originals.length > 0 && !originals.includes(component.id)) {
          return component
        }

        return transformed
      }
      
      // Handle Album components
      if (component.type === templateIcons.album) {
        const transformed = {
          type: component.type,
          name: component.name,
          medias: [],
          resource_category_ids: component.resource_category_ids ?? null
        }

        // Was not part of the original, so we use no prefix
        if (originals.length > 0 && !originals.includes(component.id)) {
          return component
        }

        // From the original components, has the Album been tempered with
        const album = find(this.components, ['data.originalId', component.id])
        const existingImages = filter(album?.data?.files ?? [], (item) => item.originalId != undefined).map(item => item.originalId)
        const _medias = map(component["medias"], media => {
          
          // When these items are not part of the original Album, we should treat them as new items
          if (!existingImages.includes(media.image_id)) {
            return media
          }
          
          return {
            notice_template_image_media_id: media.image_id
          }
        })

        // transformed['medias'] = reverse(_medias)
        transformed['medias'] = _medias

        return transformed
      }
      
      // Handle Vote Components
      if (component.type === templateIcons.vote) {
        const transformed = {
          ...component,
        }

        transformed['options'] = map(component["options"], option => {
          
          const _option = {
            ...option,
            is_image_removed: isNil(option.image_id),
            notice_template_option_id: option.original_id,
          }

          // only remove the image_id if it has not been touched.
          if (option.image_id === option.id) {
            delete _option['image_id']
          }

          // remove 'original_id' and 'image_id'
          delete _option['image_url']
          delete _option['original_id']

          return _option
        })

        return transformed
      }

      return component
    })
    
    payload['components'] = _components

    return payload
  }
  
  getSaveTemplateFromNoticePayload = (originals = []) => {

    const payload = {
      ...this.getPayload()
    }

    const _components = map(payload['components'], component => {
      
      // Handle Image Components
      if (component.type === templateIcons.image) {
        const transformed = {
          type: component.type,
          notice_image_component_id: component.image_id,
          resource_category_ids: component.resource_category_ids ?? null
        }

        // Was not part of the original, so we use no prefix
        if (originals.length > 0 && !originals.includes(component.id)) {
          return component
        }

        return transformed
      }
      
      // Handle File Components
      if (component.type === templateIcons.file) {
        const transformed = {
          type: component.type,
          notice_file_component_id: component.file_id,
          resource_category_ids: component.resource_category_ids ?? null
        }

        // Was not part of the original, so we use no prefix
        if (originals.length > 0 && !originals.includes(component.id)) {
          return component
        }

        return transformed
      }
      
      // Handle Album components
      if (component.type === templateIcons.album) {
        const transformed = {
          type: component.type,
          name: component.name,
          medias: [],
          resource_category_ids: component.resource_category_ids ?? null
        }

        // From the original components, has the Album been tempered with
        const album = find(this.components, ['data.originalId', component.id])
        const existingImages = filter(album?.data?.files ?? [], (item) => item.originalId != undefined).map(item => item.originalId)

        transformed['medias'] = map(component["medias"], media => {
          
          // When these items are not part of the original Album, we should treat them as new items
          if (!existingImages.includes(media.image_id)) {
            return media
          }

          return {
            notice_image_media_id: media.image_id
          }
        })

        return transformed
      }

      // Handle Vote Components
      if (component.type === templateIcons.vote) {
        const transformed = {
          ...component,
        }

        transformed['options'] = map(component["options"], option => {
          
          const _option = {
            ...option,
            is_image_removed: isNil(option.image_id),
            notice_option_id: option.original_id,
          }

          // only remove the image_id if it has not been touched.
          if (option.image_id === option.id) {
            delete _option['image_id']
          }

          // remove 'original_id' and 'image_id'
          delete _option['image_url']
          delete _option['original_id']

          return _option
        })

        return transformed
      }

      return component
    })
    
    payload['components'] = _components

    return payload
  }
  
  /**
   * We need to create the update payload from the original create params, but also update the payload to indicate which 
   * components are being kept from the original data and which are new.
   * 
   * New components wont have "original_id" in their data payload while the Ogs will, this will indicate to the API
   * that we are updating some, and changing some
   * 
   * ?Note: If a component is an original and no "original_id" has been parsed in its data payload, this component will be delete
   * 
   * @param {Array} originalComponentIds 
   * @returns {JSON}
   */
  getUpdatePayload = (originalComponentIds) => {

    const payload = {
      "person_ids": this.users,
      "title": this.noticeTitle,
      "channel_ids": this.channels,
      "important": this.important,
      "pin_item": this.pinItem,
      "pin_until_date": this.pinUntilDate,
      "community_id": this.communityId,
      "publish_relationship": this.publishRelationship,
      "deadline_at": this.deadlineAt,
      "send_deadline_reminders": this.sendDeadlineReminders,
    }

    if (this.hasHardDeadline) {
      payload["has_hard_deadline"] = true
    }
    
    const _components = map(getTransformedFilesForUpdate(this.components, originalComponentIds))

    // We will redo the components payload item, since we need the ids before we can continue
    payload["components"] = map(_components, component => this.transformComponent(component))
    
    payload["components"] = map(payload["components"], component => {
      
      let p = {...component}

      // We need to ignore the image for Image Component when the image has not being touched
      // We can know this if the image_id is different from the ID, because the only way for these to be different is if another file go uploaded in its place
      if (p.type === templateIcons.image) {
        if (p.id !== undefined && p.id === p.image_id) {
          p = {
            type: p.type
          }
        }
      }
      
      // We need to update the medias, and take out the "image_id" when nothing has changed
      // We can scope that nothing has changed by checking if the "image_id" is the same as the "original_id"
      if (p.type === templateIcons.album) {
        
        let _medias = map(p["medias"], m => {
          const mPayload = {
            original_id: m.original_id,
            image_id: ((m.image_id === m.original_id) && m.original_id !== undefined) ? undefined : m.image_id
          }

          return mPayload
        })

        _medias = map(_medias, c => filterNulls(c))
        
        p = {
          type: p.type,
          name: p.name,
          // medias: reverse(_medias),
          medias: _medias,
        }
      }
      
      // For Vote options, we need to send through the image_url in place of the "image_id"
      if (p.type === templateIcons.vote) {
        
        const _options = map(p["options"], option => {
          const optionPayload = {
            ...option
          }

          // When the option_id is the same as the image_id, then we know there have not been modification on this option's image
          optionPayload['image_url'] = undefined
          optionPayload['is_image_removed'] = isNil(option.image_id)

          if (option.id === option.image_id) {
            optionPayload['image_id'] = undefined
          } else {
            optionPayload['image_id'] = option.image_id ?? undefined
          }
          
          delete optionPayload['id']

          return filterNulls(optionPayload)
        })

        p = {
          ...p,
          options: _options,
        }
      }

      // We need to ignore the file for File Component when the file has not being touched
      // We can know this if the file_id is different from the ID, because the only way for these to be different is if another file go uploaded in its place
      if (p.type === templateIcons.file) {
        if (p.id !== undefined && p.id === p.file_id) {
          p = {
            type: p.type
          }
        }
      }

      // When the component id exists in the original edit notice payload, we need to add the original_id
      if ( originalComponentIds.includes(component.id)) {
        p["original_id"] = component.id
      }
      
      // Update resource category ids if available
      if (component.resource_category_ids) {
        p["resource_category_ids"] = component.resource_category_ids
      }
      
      // When the id is not the same as the 'image_id'|'file_id' then this file is a new file
      if (p.type === templateIcons.image && p.id !== p.image_id) {
        delete p["original_id"]
      }
      
      if (p.type === templateIcons.file && p.id !== p.file_id) {
        delete p["original_id"]
      }

      return omit(p, ['errors', 'id'])
    })

    return this.cleanPayload(payload)
  }
  
  /**
   * @TODO: THIS IS A DUPLICATION OF {getUpdatePayload} WE DONT WANT TO COUPLE THING TOO MUCH AS THERE ARE MANY POINTS OF FAILURES
   * 
   * @param {Array} originalComponentIds 
   * @returns {JSON}
   */
  getTemplateUpdatePayload = (originalComponentIds) => {

    const payload = {
      "person_ids": this.users,
      "title": this.noticeTitle,
      "channel_ids": this.channels,
      "important": this.important,
      "pin_item": this.pinItem,
      "pin_until_date": this.pinUntilDate,
      "community_id": this.communityId,
      "publish_relationship": this.publishRelationship,
      "deadline_at": this.deadlineAt,
      "send_deadline_reminders": this.sendDeadlineReminders,
    }

    if (this.hasHardDeadline) {
      payload["has_hard_deadline"] = true
    }
    
    const _components = map(getTransformedFilesForUpdate(this.components, originalComponentIds))

    // We will redo the components payload item, since we need the ids before we can continue
    payload["components"] = map(_components, component => this.transformComponent(component))
    
    payload["components"] = map(payload["components"], component => {
      
      let p = {...component}

      // We need to ignore the image for Image Component when the image has not being touched
      // We can know this if the image_id is different from the ID, because the only way for these to be different is if another file go uploaded in its place
      if (p.type === templateIcons.image) {
        if (p.id !== undefined && p.id === p.image_id) {
          p = {
            type: p.type
          }
        }
      }
      
      // We need to update the medias, and take out the "image_id" when nothing has changed
      // We can scope that nothing has changed by checking if the "image_id" is the same as the "original_id"
      if (p.type === templateIcons.album) {
        
        let _medias = map(p["medias"], m => {
          const mPayload = {
            original_id: m.original_id,
            image_id: ((m.image_id === m.original_id) && m.original_id !== undefined) ? undefined : m.image_id
          }

          return mPayload
        })

        _medias = map(_medias, c => filterNulls(c))
        
        p = {
          type: p.type,
          name: p.name,
          // medias: reverse(_medias),
          medias: _medias,
        }
      }

      // For Vote options, we need to send through the image_url in place of the "image_id"
      if (p.type === templateIcons.vote) {
  
        const _options = map(p["options"], option => {
          const optionPayload = {
            ...option
          }

          // When the option_id is the same as the image_id, then we know there have not been modification on this option's image
          optionPayload['image_url'] = undefined
          optionPayload['is_image_removed'] = isNil(option.image_id)

          if (option.id === option.image_id) {
            optionPayload['image_id'] = undefined
          } else {
            optionPayload['image_id'] = option.image_id ?? undefined
          }
          
          delete optionPayload['id']

          return filterNulls(optionPayload)
        })

        p = {
          ...p,
          options: _options,
        }
      }

      // We need to ignore the file for File Component when the file has not being touched
      // We can know this if the file_id is different from the ID, because the only way for these to be different is if another file go uploaded in its place
      if (p.type === templateIcons.file) {
        if (p.id !== undefined && p.id === p.file_id) {
          p = {
            type: p.type
          }
        }
      }

      // When the component id exists in the original edit notice payload, we need to add the original_id
      if ( originalComponentIds.includes(component.id)) {
        p["original_id"] = component.id
      }

      // Update resource category ids if available
      if (component.resource_category_ids) {
        p["resource_category_ids"] = component.resource_category_ids
      }
      
      // When the id is not the same as the 'image_id'|'file_id' then this file is a new file
      if (p.type === templateIcons.image && p.id !== p.image_id) {
        delete p["original_id"]
      }
      
      if (p.type === templateIcons.file && p.id !== p.file_id) {
        delete p["original_id"]
      }

      return omit(p, ['errors', 'id'])
    })

    return this.cleanPayload(payload)
  }

  /**
   * 
   */
  validator = () => {
    let components = map(this.components, component => this.transformComponent(component))
    components = filter(map(components, 'errors'), x => !isUndefined(x) && has(x, 'messages'))
    return mapKeys(components, error => error.id)
  }
  
  /**
   * 
   */
  templateValidator = () => {
    let components = map(this.components, component => this.transformComponent(component))
    components = filter(map(components, 'errors'), x => !isUndefined(x) && has(x, 'messages') && !has(x, 'messages.deadline'))
    return mapKeys(components, error => error.id)
  }
  
  /**
   * 
   */
  noticeEvents = () => {
    const components = map(this.components, component => this.transformComponent(component))
    return {
      // These new events need to have had their "add_to_calendar=True"
      newTotal: filter(components, x => isUndefined(x.event_id) && x.add_calendar === true).length,
      existingTotal: filter(components, x => !isUndefined(x.event_id)).length,
    }
  }

}

export default PostCreateNoticeRequest