import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, Grid, Header, Image, List, Radio, Segment } from 'semantic-ui-react'
import { useEffect } from 'react'
import { find, map, filter } from 'lodash'
import routes from '../../routes/route-constants'
import { appendCommunity, getError, getParameterByName } from '../../utils/helper'
import { calendarsActions } from '../../actions/calendarsActions'
import { PostCreateCalendarRequest } from '../../api/requestObjects'
import { useTranslation } from 'react-i18next'
import ErrorLabel from '../partials/ErrorLabel'
import Role from '../partials/Role'
import AnimLoader from '../partials/AnimLoader'
import Messages from '../partials/Messages'

import { messageBuilder } from '../../constants'

const CreateCalendar = () => {

	const router = useSelector(state => state.router)
	const navigation = useSelector(state => state.navigation)
	const calendarManagement = useSelector(state => state.calendars)

	const [calendarPayload, setCalendarPayload] = useState({
        name: "",
        sourceId: null,
        channelIds: [],
        targetEveryone: false,
        syncAccountId: router.location?.query?.accountId,
        syncAutomatically: false,
        sendReminderDayBefore: false,
        communityId: navigation.communityId,
    })
	
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [validationMessages, setValidationMessages] = useState([])

	useEffect(() => {
		document.body.classList.add('link-calendar')

		return () => {
			document.body.classList.remove('link-calendar')
		}
	}, [])
	
	useEffect(() => {
		
		if (!router.location?.query?.accountId) return;

		dispatch(calendarsActions.startSources())
		
		dispatch(calendarsActions.fetchCalendarSyncSources({
			syncAccountId: router.location?.query?.accountId,
			strategyId: router.location?.query?.strategyId
		}))

		dispatch(calendarsActions.fetchCalendarFilters({ communityId: navigation.communityId }))
	}, [navigation.communityId])

	const handleNavigation = () => {
		// HARD Refresh
		window.location.href = appendCommunity(
			navigation.communityId,
			routes.private.MANAGE_CALENDARS
		)
	}

	const handleCheckChanged = ({name}) => {
        setCalendarPayload(prev => ({...prev, [name]: !prev[name]}))
    }

	const handleRolesChange = ({name, value}) => {
		let selections = value

		// When the current selection has our "-901" id already, we should remove it and select everything else that was already selected
        if (value.indexOf(messageBuilder.TARGET_ALL_TAG_ID) > -1) {
            selections = filter(value, c => c !== messageBuilder.TARGET_ALL_TAG_ID)
        }

        setCalendarPayload(prev => ({...prev, [name]: selections, targetEveryone: false}))
    }
	
	const handleChangeSource = (e, { value })=> {
		
		const source = find(calendarManagement?.sources?.dataTransformed ?? [], {key: value})
        
		setCalendarPayload(prev => ({
			...prev, 
			sourceId: value,
			name: source.text
		}))
    }

	const _handleAllTargetsClick = (_, {value}) => {
        
        // Remove all selections on recipients list
        const selections = [messageBuilder.TARGET_ALL_TAG_ID]
        
        setCalendarPayload(prev => ({
            ...prev,
			targetEveryone: true,
            channelIds: selections
        }))
    }

	const handlePostCreateCalendar = () => {
        const request = new PostCreateCalendarRequest(calendarPayload)
        const validator = request.validator()

        // Validate the request
        if (!validator.isValid) {
            setValidationMessages(validator.messages)
            return;
        }

        setValidationMessages([])
        dispatch(calendarsActions.start())
        dispatch(calendarsActions.startSources())
        dispatch(calendarsActions.postCreateCalendar(calendarPayload))
    }

	return (
		<div className="link-calendar-full-page">
			
			<AnimLoader loading={calendarManagement.loadingSources || calendarManagement.loading} />

			<Grid className="calendar-items-container">
				<Grid.Row columns={1}>
					<Grid.Column width={16}>
						<h1>{t('calendars.settings.modal.title')}</h1>
						<p>
							{t('calendars.settings.modal.description')}
						</p>
						{calendarManagement.errors && (
							<Messages
								onClose={() => dispatch(calendarsActions.clearMessages())}
								errors={calendarManagement.errors || ""}/>
						)}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className="no-pad-top no-margin link-calendar-container">
					<Grid.Column width={16}>
						<Segment className={`list-header ${!getError("sourceId", validationMessages).isValid ? 'has-error' : ''}`} attached='top'>
							<Header as='h3'>
								{t('calendars.manage.link.header')}
							</Header>
							<ErrorLabel className='ui w-fit-content' error={getError("sourceId", validationMessages)} />
						</Segment>

						{map(calendarManagement?.sources?.dataTransformed ?? [], (calendar, index) => (
							<Segment attached key={index} className='list-item'>
								<List.Content verticalAlign='middle' as={'label'} htmlFor={`link-calendar-${calendar.key}`}>
									<Radio 
										value={calendar.key} 
										onClick={handleChangeSource} 
										name='link-calendar'
										id={`link-calendar-${calendar.key}`}
										checked={calendarPayload.sourceId === calendar.key} />
									<Image {...calendar.image} />
									<List.Header>{calendar.text}</List.Header>
								</List.Content>
							</Segment>
						))}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className="no-pad-bot ui form">
					<Grid.Column width={16}>
						<Form.Field
							required
							error={!getError("channelIds", validationMessages).isValid}
							className="input-container theme-color">

							<Role
								role={{
									id: "channelIds",
									name: t('calendar.input.channels.title'),
									smallDescription: t('calendar.input.channels.description')
								}}
								virtualised
								placeholder={t('calendar.input.channels.placeholder')}
								selected={calendarPayload.channelIds}
								onChange={handleRolesChange}
								options={[
									navigation?.community?.can_target_everyone ? { 
                                        onClick: _handleAllTargetsClick,
                                        key: messageBuilder.TARGET_ALL_TAG_ID, 
                                        value: messageBuilder.TARGET_ALL_TAG_ID, 
                                        text: t("messageBuilder.recipients.targetAll"),
                                        className: "special-actions",
                                    } : false,
								].concat(calendarManagement?.filters?.channels ?? []).filter(Boolean)} />

							<ErrorLabel className='ui pointing prompt label w-fit-content' error={getError("channelIds", validationMessages)} />
						</Form.Field>
					</Grid.Column>
					<Grid.Column>
						<div className='mg-top-15'>
							<Checkbox
								onChange={e => handleCheckChanged({name: 'sendReminderDayBefore'})} 
								checked={calendarPayload.sendReminderDayBefore} 
								label={t('calendar.detail.remindDayBefore')} />
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns="equal" className='mg-top-20'>
					<Grid.Column width={16} textAlign="right">
						<Button
							onClick={handleNavigation}
							className="secondary mg-right-5"
						>
							{t('general.cancel')}
						</Button>
						<Button
							disabled={calendarManagement.loading}
							onClick={handlePostCreateCalendar}
							className="primary no-margin"
						>
							{t('calendars.settings.modal.confirm.text')}
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}

export default CreateCalendar
