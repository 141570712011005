import React, { Fragment } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import BaseIconCard from './BaseIconCard'
import BaseTemplate from './BaseTemplate'
import Text from './Text'
// import EventText from './EventText'
import moment from 'moment'
import { size } from 'lodash'
import EventText from './EventText'
import i18next from 'i18next'
import { RenderAllDay, RenderNormal } from '../../../calendar/events/EventDetail'


const Event = ({ item }) => {

    return (
        <Fragment>
            
            {(size(item?.data?.content) > 0) && <Text classNames='event-notes' item={item} />}

            <BaseTemplate classNames="no-padding no-auto-height icon-card-container" render={() => (
                <BaseIconCard
                    content={() => (
                        <Fragment>
                            <Grid>
                                {item.data.title && (
                                    <Grid.Row className='no-pad-bot'>
                                        <Grid.Column width={16} className="vertical-align">
                                            <h3 className="mg-bot-5 black-text trim-text">{ item.data.title }</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                <div className='row event-detail-row'>
                                    <Grid.Column className='event-icon-container'>
                                        <Icon name='calendar alternate' className="primary-text"/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Grid>
                                            {item.data.allDay ? <RenderAllDay data={item.data} /> : <RenderNormal data={item.data} />}
                                        </Grid>
                                    </Grid.Column>
                                </div>
                                {item.data.location && (
                                    <Grid.Row columns='equal' style={{paddingTop: 0}}>
                                        <Grid.Column width={2}>
                                            <Icon name='map marker alternate' className="primary-text"/>
                                        </Grid.Column>
                                        <Grid.Column className='no-pad-left'>
                                            <p className="no-margin black-text trim-text">{ item.data.location }</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                )}    
                                <Grid.Row style={{paddingTop: 0}} columns="equal">
                                    <Grid.Column>
                                        <p className="no-margin primary-text"><strong>{i18next.t('messageBuilder.components.addToCalendar')}</strong></p>
                                    </Grid.Column>
                                    {item.data.location && (
                                        <Grid.Column textAlign='right'>
                                            <p className="no-margin primary-text"><strong>{i18next.t('messageBuilder.components.directions')}</strong></p>
                                        </Grid.Column>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )} />
            )} />
        </Fragment>
    )
}

export default Event
