import React, { useState, Fragment } from 'react'
import { Grid, Checkbox } from 'semantic-ui-react'
import Switch from "react-switch"
import { map } from 'lodash'
import { SelectionCopyHelperText } from '../../../morphedTemplates/formTypes/MultipleChoiceQuestion'

export default function MultipleChoiceQuestionPreview({item}) {
    
    return (
        <Grid.Row>
            <Grid.Column>
                <div className="input-container theme-color">
                    <label className='label mg-bot-10 black-text'>
                        {item.data.question} {item.data.isRequired && <span style={{marginLeft: 5}} className="required-star secondary-color-text">*</span>}
                    </label>
                    <Grid.Row>
                        {(!item.data.singleSelect) && (
                            <SelectionCopyHelperText
                                min={item.data.min}
                                max={item.data.max}
                                className="grey-text" />
                        )}
                        {map(item.data.options, (option, index) => (
                            <Grid.Column key={index}>
                                <Checkbox radio={item.data && item.data.singleSelect} label={option.name ?? option.option} />
                            </Grid.Column>
                        ))}
                    </Grid.Row>
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}
