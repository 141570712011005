import { pickBy, identity, map } from "lodash"

class ListNoticeRecipientsRequest {

    constructor({
        sort, 
        page,
        search,
        perPage,
        channels,
        noticeId,
        interactions,
        relationship,
    }) {
        this.page = page
        this.sort = sort
        this.search = search
        this.perPage = perPage
        this.channels = channels
        this.relationship = relationship
        this.noticeId = noticeId
        this.interactions = interactions
    }


    getPayload = () => {
        
        const payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "per_page": this.perPage?.value,
            "group_by": this.relationship,
            "channel_ids": [].concat(this.channels).join(","),
            "interactions": [].concat(this.interactions).join(","),
        }

        return pickBy(payload, identity)
    }
    
}

export default ListNoticeRecipientsRequest