/**
 * 
 * These are constants for our frontend routes
 */
const routes = {
    
    // Open Frontend Routes
    public: {
        LOGIN:             '/login',
        TERMS:             '/term',
        INVITE:            '/invites/:id',
        WEB_FORM:          '/webform.html',
        // LOGIN_REDIRECT:    '/signin-callback.html',
        LOGIN_REDIRECT: '/signin-oidc',
        LOGOUT_REDIRECT:   '/signout-callback-oidc',
    },

    // Authenticated Routes
    private: {
        
        // Default
        HOME:               '/',
        HOME_COMM:             '/:communityId/home',
        BLOCKED:               '/:communityId/blocked',
        
        // Communication
        MANAGE_NOTICE:      '/:communityId/notices/',
        NOTICE_DETAIL:      '/:communityId/notices/:id',
        CREATE_NOTICE:      '/:communityId/notice/create',
        UPDATE_NOTICE:      '/:communityId/notice/update/:id',
        MESSAGE_BUILDER:    '/:communityId/notice/builder',
        NOTICE_CUSTOM:      '/:communityId/notice/builder-custom',
        NOTICE_UPDATE:      '/:communityId/notice/builder-edit',
        MESSAGE_BUILDER_DYNAMIC:    '/:communityId/notice/builder-dynamic',
        NOTICE_RECIPIENT_DETAIL:      '/:communityId/notices/:id/recipient/:recipientId',
        SMS:                '/:communityId/sms',
        EMAIL:              '/:communityId/email',
        
        // Channels
        CHANNELS:           '/:communityId/channels',
        CHANNEL_DETAIL:     '/:communityId/channels/:id',
        CHANNEL_CREATE:     '/:communityId/channels/create',
        
        // Calendar
        CALENDAR:           '/:communityId/calendar',
        CALENDAR_DETAIL:    '/:communityId/calendar/:id',
        CALENDAR_CREATE:    '/:communityId/calendar/create',
        CALENDAR_CREATE_ICAL:    '/:communityId/calendar/create/ical',
        MANAGE_EVENTS:      '/:communityId/calendar/events',
        MANAGE_CALENDARS:    '/:communityId/calendar/manage',
        CALENDAR_CREATE_EVENT:  '/:communityId/calendar/:id/events/create',
        CALENDAR_EVENT_EDIT:  '/:communityId/calendar/:calendarId/events/:id/edit',
        CALENDAR_EVENT_DETAIL:  '/:communityId/calendar/:calendarId/events/:id/detail',
        MANAGE_CALENDAR_ACCOUNTS:'/:communityId/calendar/accounts',
        
        // Content
        MANAGE_RESOURCES:   '/:communityId/resources',
        RESOURCE_DETAIL:    '/:communityId/resources/:id',
        GALLERY:            '/:communityId/gallery',
        
        // Communities
        MANAGE_COMMUNITIES:  '/:communityId/communities',
        COMMUNITY_DETAIL:    '/:communityId/communities/:id',
        
        // Users
        MANAGE_USERS:       '/:communityId/users',
        USER_DETAIL:        '/:communityId/users/:id',
        INVITES:            '/:communityId/invitations',
        INVITE_USER:        '/:communityId/invitations/invite',
        MEMBER_DETAIL:      '/:communityId/members/:id',
        MEMBER_CREATE:      '/:communityId/members/create',
        MEMBERS_IMPORT:     '/:communityId/members/import',
        MANAGE_MEMBERS:      '/:communityId/members',
        ROLES_PERMISSIONS:  '/:communityId/roles-permissions',

        // Accept Invite
        INVITE:             '/invites/:token',
        
        // User does not exist and User is not linked to any community
        NO_LINK:             '/no-link',
        
        // When the user hits a 403 status, meaning they are not allowed to be there
        NOT_ALLOWED:             '/not-allowed',

        LOGOUT_REDIRECT:         '/logout-redirect',
        
        // Profile
        UPDATE_DETAILS:                '/:communityId/account',
        UPDATE_DETAILS_NO_COMM:        '/account',
        USER_DETAIL_UPDATE_EMAIL:      '/:communityId/account/email',
        USER_DETAIL_UPDATE_PHONE:      '/:communityId/account/phone',
        USER_DETAIL_UPDATE_EMAIL_OTP:  '/:communityId/account/email/otp',
        USER_DETAIL_UPDATE_PHONE_OTP:  '/:communityId/account/phone/otp',

        // Account details
        SETTING_BILLING:      '/:communityId/settings/billing',
        SETTING_ACCOUNT:      '/:communityId/settings/account',
        SETTINGS_CONTACT:     '/:communityId/settings/contacts',
        SETTINGS_MESSAGE_PUBLISHING:     '/:communityId/settings/message-publishing',
        
        SETTINGS_CONTACT_CREATE:     '/:communityId/settings/contacts/create',
        SETTINGS_CONTACT_DETAIL:     '/:communityId/settings/contacts/:id',
        
        SETTINGS_CONTACT_CATEGORY_CREATE:     '/:communityId/settings/category/create',
        SETTINGS_CONTACT_CATEGORY_DETAIL:     '/:communityId/settings/category/:id',

        // Link Calendar
        CALENDAR_CHOOSE_SYNC_METHODS: '/:communityId/source/select',
        CALENDAR_GOOGLE_AUTH_CALLBACK: '/:communityId/google/calendar/callback',
        CALENDAR_MICROSOFT_AUTH_CALLBACK: '/:communityId/microsoft/calendar/callback',
        
        // Re-Link/Re-Auth Calendar
        CALENDAR_RE_AUTH: '/:communityId/account/:id/select',
    }
}

export default routes