import React, {useEffect, useRef, useState} from 'react'

import { EditorState, ContentState, CharacterMetadata, Modifier } from 'draft-js'
import { useTranslation } from 'react-i18next'

import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'

const emojiIcon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHZpZXdCb3g9IjE1LjcyOSAyMi4wODIgMTcgMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI5LjcwOCAyNS4xMDRjLTMuMDIxLTMuMDIyLTcuOTM3LTMuMDIyLTEwLjk1OCAwLTMuMDIxIDMuMDItMy4wMiA3LjkzNiAwIDEwLjk1OCAzLjAyMSAzLjAyIDcuOTM3IDMuMDIgMTAuOTU4LS4wMDEgMy4wMi0zLjAyMSAzLjAyLTcuOTM2IDAtMTAuOTU3em0tLjg0NSAxMC4xMTJhNi41NiA2LjU2IDAgMCAxLTkuMjY4IDAgNi41NiA2LjU2IDAgMCAxIDAtOS4yNjcgNi41NiA2LjU2IDAgMCAxIDkuMjY4IDAgNi41NiA2LjU2IDAgMCAxIDAgOS4yNjd6bS03LjUyNC02LjczYS45MDYuOTA2IDAgMSAxIDEuODExIDAgLjkwNi45MDYgMCAwIDEtMS44MTEgMHptNC4xMDYgMGEuOTA2LjkwNiAwIDEgMSAxLjgxMiAwIC45MDYuOTA2IDAgMCAxLTEuODEyIDB6bTIuMTQxIDMuNzA4Yy0uNTYxIDEuMjk4LTEuODc1IDIuMTM3LTMuMzQ4IDIuMTM3LTEuNTA1IDAtMi44MjctLjg0My0zLjM2OS0yLjE0N2EuNDM4LjQzOCAwIDAgMSAuODEtLjMzNmMuNDA1Ljk3NiAxLjQxIDEuNjA3IDIuNTU5IDEuNjA3IDEuMTIzIDAgMi4xMjEtLjYzMSAyLjU0NC0xLjYwOGEuNDM4LjQzOCAwIDAgMSAuODA0LjM0N3oiLz48L3N2Zz4='

export const CustomEmojiPicker = (props) => {
    
    const ref = useRef()

    const {t} = useTranslation()
    const [isShowPicker, setPickerVisible] = useState(false)

    const onSelect = (emoji) => {
        
        const { editorState, onChange } = props
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            emoji.native,
            editorState.getCurrentInlineStyle(),
        )

        onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    }

    /**
     * This utility function will give this component the ability to close the emoji picker when anything but the picker is clicked in
     * 
     * @param {Event} event 
     * @returns 
     */
    const checkClickOutside = (event) => {
        
        /** @type {HTMLElement} */
        const element = event.target
        
        // We should also not close the modal when we are interacting inside the EmojiModal
        /** @type {HTMLElement} */ 
        const thisElement = ref.current
        const pickerContainer = thisElement.querySelector('.picker-open')

        if (
          element.classList.contains("rdw-option-wrapper") ||
          element.classList.contains("rdw-option-wrapper--icon") ||
          pickerContainer.contains(element)
        ) {
          return
        }

        // Remove the event when the picker gets closed
        document.removeEventListener('click', checkClickOutside)
        setPickerVisible(false)
    }

    const handleClick = (event) => {
        
        event.stopPropagation()
        event.preventDefault()
        
        if (!isShowPicker) {
            // attach/remove event handler
            document.addEventListener('click', checkClickOutside)
        } else {
            document.removeEventListener('click', checkClickOutside)
        }
        
        setPickerVisible((val) => !val)
    }

    return (
        <div 
            ref={ref}
            className={`rdw-emoji-wrapper ${isShowPicker ? 'wrapper-picker-open' : ''}`}
            aria-label="rdw-emoji-control" 
            title={t("components.controls.addEmoji")} >
            <div
                className="rdw-option-wrapper"
                onClick={handleClick}>
                <img src={emojiIcon} alt="" className="rdw-option-wrapper--icon" />
            </div>
            {isShowPicker && (
                <div 
                    className={`custom-emoji__picker ${isShowPicker ? 'picker-open' : ''}`}
                    onClick={e => e.stopPropagation()}>
                    <Picker
                        showPreview={false}
                        showSkinTones={false}
                        set="apple"
                        onSelect={onSelect} />
                </div>
            )}
        </div>
    )
}