import React, { useState, useEffect, Fragment, useMemo } from 'react'
import { Table as STable, Popup, Dropdown, Checkbox, Icon, Segment, Label, Image, Dimmer, Loader, Button, Header } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'
import { actions, calendarConstants, constants, messageBuilder } from '../../constants'
import { map, trimStart, startsWith, size, isArray, take, difference, differenceBy, every, concat, filter, omit, isEmpty, mapKeys, mapValues, isBoolean, first, isNull, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import moment from 'moment'

import logoAnim from '../../images/d6_logo.riv'
import AnimLoader from './AnimLoader'
import { useDispatch, useSelector } from 'react-redux'
import { tableActions } from '../../actions/tableActions'
import RecipientHoverItem from './table/RecipientHoverItem'
import LogoTeal from './LogoTeal'

const propTypes = {
    id: PropTypes.string,                   // Table's unique reference
    loading: PropTypes.bool,
    headers: PropTypes.array,                // Table headers to be displayed
    keys: PropTypes.array,                   // Record keys to map to columns
    records: PropTypes.array,                // Items to be shown in the table
    sortBy: PropTypes.string,                // Current Sort Key
    classNames: PropTypes.array,             // Any additional table classnames for customization
    selection: PropTypes.array,              // Currently selected items
    hasSelection: PropTypes.bool,            // Currently selected items as boolean
    noRecordsMessage: PropTypes.string,      // The message to show when there are no items available
    actionFunctions: PropTypes.object,       // Action clicks
    batchActions: PropTypes.object,          // Actions for batch calls

    // Functions
    onRecordClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),          // When record is clicked on
    onAllCheck: PropTypes.func,             // Header checkbox checked
    onSortChange: PropTypes.func,           // When the header column is clicked to sort
    onRecordCheck: PropTypes.func,          // Record checkbox checked
    onCheckUpdate: PropTypes.func,          // When the checkbox is updated
}

const defaultProps = {
    id: "",
    loading: false,
    headers: [],
    keys: [],
    records: [],
    sortBy: "",
    selection: [],
    classNames: [],
    noRecordsMessage: i18next.t('partials.Table.noRecordsMessage'),
    batchActions: {},
    actionFunctions: {},
    hasSelection: false,

    // Functions
    onRecordClick: null,
    onAllCheck: () => { },
    onRecordCheck: () => { },
    onSortChange: () => { },
    onCheckUpdate: () => { },
}

const SuperTable = (props) => {

    const {
        id,
        loading,
        headers,                // Table headers to be displayed
        keys,                   // Record keys to map to columns
        records,                // Items to be shown in the table
        selection,              // Currently selected items
        hasSelection,           // Currently selected items as boolean
        sortBy,                 // Current Sort Key
        noRecordsMessage,       // The message to show when there are no items available
        classNames,             // Any additional table classnames for customization

        // Functions
        onAllCheck,             // When the all checkbox is 
        onRecordClick,          // When record is clicked on
        batchActions,           // Actions for batch calls
        actionFunctions,        // Action clicks
        onSortChange,           // When the header column is clicked to sort
        onCheckUpdate,          // When the checkbox is updated
    } = props

    const [state, setState] = useState({
        orderBy: sortBy,
        searching: false,
        checked: false,

        // Create a map of all the users' on the table
        currentCheckedItems: mapKeys(selection)
    })

    const table = useSelector(state => state.table)
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const renderMenuCaption = (menuItem) => {

        switch (menuItem) {
            case constants.MENU_ACTION_COPY:
                return {
                    title: t('menu.copy'),
                    icon: 'copy'
                }
            case constants.MENU_ACTION_DELETE:
                return {
                    title: t('menu.delete'),
                    icon: 'trash'
                }
            case constants.MENU_ACTION_REMOVE:
                return {
                    title: t('menu.remove'),
                    icon: 'trash'
                }
            case constants.MENU_ACTION_DISABLE:
                return {
                    title: t('menu.disable'),
                    icon: 'pause circle'
                }
            case constants.MENU_ACTION_ENABLE:
                return {
                    title: t('menu.enable'),
                    icon: 'play circle'
                }
            case constants.MENU_ACTION_VIEW:
                return {
                    title: t('menu.view'),
                    icon: 'eye'
                }
            case constants.MENU_ACTION_RESEND:
                return {
                    title: t('menu.resend'),
                    icon: 'undo alternate'
                }
            case constants.MENU_ACTION_SUSPEND:
                return {
                    title: t('menu.suspend'),
                    icon: 'pause'
                }
            case constants.MENU_ACTION_DOWNLOAD:
                return {
                    title: t('menu.download'),
                    icon: 'download'
                }
            case constants.MENU_ACTION_EDIT:
                return {
                    title: t('menu.edit'),
                    icon: 'pencil'
                }
            case constants.MENU_ACTION_SYNC:
                return {
                    title: t('menu.sync'),
                    icon: 'sync'
                }
            case constants.MENU_ACTION_MANAGE:
                return {
                    title: t('menu.manage'),
                    icon: 'settings'
                }
            case constants.MENU_ACTION_REAUTHENTICATE:
                return {
                    title: t('menu.re-authenticate'),
                    icon: 'redo'
                }
            default:
                return {
                    title: 'Unknown',
                    icon: 'balance scale'
                }
        }
    }

    const handleColumnOrder = (column) => {

        var orderByDirection = ""
        var orderByColumn = column.name

        if (startsWith(state.orderBy, "-")) {

            if (column.name === trimStart(state.orderBy, "-")) {
                orderByDirection = ""
            }

        } else {
            if (column.name === state.orderBy) {
                orderByDirection = "-"
            }
        }

        setState({
            ...state,
            orderBy: orderByDirection + orderByColumn
        })


        onSortChange(orderByDirection + orderByColumn)
    }

    const handleAllCheck = (e) => {

        const checked = e.target.checked

        // Add all the items into the checked list, when checked true
        // We are going to take all the records on this page and make them automatically selected
        // We should not add items that are {disabled: true}
        const filtered = filter(records, r => !r.disabled)
        const usersValueMap = mapValues(mapKeys(filtered, u => u.id), u => checked)

        setState(prev => ({
            ...prev,
            checked: !state.checked,
            currentCheckedItems: {
                ...prev.currentCheckedItems,
                ...usersValueMap
            },
        }))

        onAllCheck(checked)
    }

    const handleCheck = (record, e) => {

        var value = e.target.checked

        setState({
            ...state,
            currentCheckedItems: {
                ...state.currentCheckedItems,
                [record.id]: value
            }
        })
    }

    // When the checked items update, we need to update overall and send data back to main
    useEffect(() => {

        // Since this map will contain {id: True/False}
        // we need only take the ids which are True
        const selections = map(state.currentCheckedItems, (value, key) => {
            return { id: key, isChecked: isBoolean(value) ? value : value === key }
        })

        const all = map(filter(selections, { isChecked: true }), "id")
        const usersValueMap = mapValues(mapKeys(records, u => u.id))

        onCheckUpdate(all, all.map(id => usersValueMap[id]).filter(u => !isUndefined(u)))
    }, [state.currentCheckedItems])


    const handleClearAll = () => {
        onAllCheck(false)
        onCheckUpdate([])
        setState(prev => ({
            ...prev,
            currentCheckedItems: {}
        }))
    }

    // Cleanup
    useEffect(() => {
        return () => {
            dispatch(tableActions.reset())
        }
    }, [])

    useEffect(() => {

        if (table?.tableId !== id) return;
        if (table?.type !== actions.TABLE.CLEAR_ALL) return;

        // Clear Selection
        handleClearAll()

    }, [table])

    // When the checked items update, we need to update overall and send data back to main
    useEffect(() => {

        const _records = records.reduce((acc, user) => {
            acc[user["id"]] = state.currentCheckedItems[user["id"]] ?? false
            return acc
        }, {})

        setState(prev => ({
            ...prev,
            currentCheckedItems: {
                ...prev.currentCheckedItems,
                ..._records
            }
        }))

        // setState({
        //     ...state,
        //     currentCheckedItems: records.reduce((acc, user) => {
        //         acc[user["id"]] = state.currentCheckedItems[user["id"]] ?? false
        //         return acc
        //     }, {})
        // })
    }, [records])

    /**
     * Determine if a checkbox for a specific record should be checked
     * 
     * @param {*} record 
     * @returns {Boolean}
     */
    const determineChecked = (record) => {
        return state.currentCheckedItems[record.id] || false
    }


    /**
     * Determine if the checkbox for all on this table should be checked, this will imply the selected items for this specific page
     * 
     * @returns {Boolean}
     */
    const determineAllChecked = useMemo(() => {

        // Grab  selected from checked items map on this page by looking at the current records
        const filtered = filter(records, r => !r.disabled) // Making sure that disabled records do not need to count towards the "All Selection"
        const selected = filtered.map(r => isBoolean(state.currentCheckedItems[r.id]) ? state.currentCheckedItems[r.id] : state.currentCheckedItems[r.id] === r.id)

        // return state.checked && every(selected, i => i ===  true)
        return every(selected, i => i === true)
    }, [records, state.currentCheckedItems])

    /**
     * NOTE: Creating this custom TableCell because this gives us a central point to be able to have more control
     * over which props we are parsing to and from, and now with this  separated, we can now add global handlers 
     * without duplicating ourselves
     * 
     * @param {*} props 
     * @returns 
     */
    const TableCell = (props) => {

        const {
            record,
            isTags,
            checkbox,
            className,
        } = props

        const handleOnClick = () => {

            if (record?.disabled || isTags) {
                return;
            }

            if (record && record.id && onRecordClick && (checkbox === undefined)) {
                onRecordClick(record.id, record)
            }
        }

        return (
            <STable.Cell
                className={className || ""}
                onClick={handleOnClick}
                {...omit(props, ['record', 'checkbox', 'isTags'])}>
            </STable.Cell>
        )
    }

    return (
        <div className='relative'>

            {/* <AnimLoader loading={loading} loadingText={t("general.loading")} /> */}
            <Dimmer
                active={loading}
                inverted>
                <Loader
                    inverted
                    className='table-loader-container'
                    style={{ width: 100 }}
                    content={
                        <Fragment>
                            {loading && (
                                <div className='rive-loader'>
                                    <LogoTeal />
                                </div>
                            )}
                            {t("general.loading")}
                        </Fragment>
                    } />
            </Dimmer>
            <STable unstackable className={["primary-table", "super-t", ...classNames].join(" ")}>
                <STable.Header>
                    <STable.Row>
                        {map(headers, (header, i) => {

                            if (keys[i].type === constants.TABLE_KEY_CHECKBOX) {
                                return (
                                    <STable.HeaderCell
                                        className='checkbox'
                                        textAlign='center'
                                        key={i}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={determineAllChecked}
                                                onChange={handleAllCheck} />
                                        </label>
                                        {(size(batchActions?.actions) > 0 && hasSelection) && (
                                            <Dropdown
                                                inline
                                                labeled
                                                className='batch-actions-dropdown'
                                                text=" "
                                                value=""
                                                options={batchActions.actions} />
                                        )}
                                    </STable.HeaderCell>
                                )
                            }

                            return (
                                <STable.HeaderCell
                                    key={i}
                                    textAlign={header.align ? header.align : 'left'}
                                    selectable={header.sortable}
                                    className={`${header.sortable ? 'sortable' : ''} ${keys[i].classNames ? keys[i].classNames : ''}`}
                                    onClick={header.sortable ? handleColumnOrder.bind(this, keys[i]) : () => { }}>
                                    <span title={header.name}>{header.name}</span>
                                    {
                                        keys[i].name === state.orderBy || keys[i].name === trimStart(state.orderBy, "-") ?
                                            (
                                                startsWith(state.orderBy, "-") ?
                                                    (<span>&emsp;<Icon name="chevron down" /></span>) :
                                                    (<span>&emsp;<Icon name="chevron up" /></span>)
                                            ) :
                                            null
                                    }
                                </STable.HeaderCell>
                            )
                        })}
                    </STable.Row>
                </STable.Header>

                <STable.Body className={`${loading ? 'loading' : ''}`}>

                    {map(records, (record, i) => (
                        <STable.Row data-disabled={record?.disabled} data-status={record?.status ?? ''} className={["pointable", record?.type ?? ''].join(' ')} key={i}>
                            {map(keys, (key, j) => {
                                switch (key.type) {

                                    case constants.TABLE_KEY_IMAGE:
                                        {

                                            return (
                                                <TableCell
                                                    key={j} 
                                                    record={record} 
                                                    className={key.classNames}
                                                    textAlign={key.align ? key.align : 'center'} 
                                                    collapsing={key.collapsing ?? false}>
                                                    <Image src={record[key.name]} rounded className={key.size ?? 'mini'} />
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_AUDIT_HISTORY_ACTION:
                                        {
                                            const finalText = record[key.name]
                                            
                                            return (
                                                <TableCell
                                                    className={key.classNames}
                                                    record={record} key={j} textAlign={key.align ? key.align : 'left'} collapsing={key.collapsing ?? false}>
                                                    <Trans
                                                        i18nKey='utils.generic'
                                                        values={{
                                                            text: finalText,
                                                        }}
                                                        components={{
                                                            div: <div className='history-action-text' />,
                                                            b: <b/>,
                                                            p: <p className='no-margin' />,
                                                            span: <span />,
                                                            tg: <Label size='small' as='a' className="color-primary-light" />,
                                                            ch: <Label size='small' as='a' className="color-primary-light" />,
                                                            prsn: <Label size='small' as='a' className="person-tag" />
                                                        }} />
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_STATUS:
                                        {

                                            switch (record[key.name]) {
                                                case messageBuilder.STATUS.DRAFT: {
                                                    return (
                                                        <TableCell
                                                            className={key.classNames}
                                                            record={record} key={j} textAlign={key.align ? key.align : 'center'} collapsing={key.collapsing ?? false}>
                                                            <strong className="red-text">{i18next.t(record[key.name])}</strong>
                                                        </TableCell>
                                                    )
                                                }
                                                case messageBuilder.STATUS.PUBLISHED: {
                                                    return (
                                                        <TableCell
                                                            className={key.classNames}
                                                            record={record} key={j} textAlign={key.align ? key.align : 'center'} collapsing={key.collapsing ?? false}>
                                                            <strong className="green-text">{i18next.t(record[key.name])}</strong>
                                                        </TableCell>
                                                    )
                                                }
                                                case messageBuilder.STATUS.PUBLISHING: {
                                                    return (
                                                        <TableCell
                                                            className={key.classNames}
                                                            record={record} key={j} textAlign={key.align ? key.align : 'center'} collapsing={key.collapsing ?? false}>
                                                            <strong className="orange-text mr-1">{i18next.t(record[key.name])}</strong><Icon name='spinner' className='orange-text' loading />
                                                        </TableCell>
                                                    )
                                                }
                                                case messageBuilder.STATUS.SCHEDULED: {
                                                    return (
                                                        <TableCell
                                                            className={key.classNames}
                                                            record={record} 
                                                            key={j}
                                                             textAlign={key.align ? key.align : 'center'} 
                                                             collapsing={key.collapsing ?? false}
                                                             title={i18next.t(record[key.name])}>
                                                                <Icon size='small' name='time' className='orange-text mr-2'/>
                                                                <span className="orange-text">
                                                                    {record.scheduled_publish_at ? moment(record.scheduled_publish_at).format('L [at] LT') : i18next.t(record[key.name])}
                                                                </span>
                                                        </TableCell>
                                                    )
                                                }
                                                default: {
                                                    return (
                                                        <TableCell
                                                            className={key.classNames}
                                                            record={record} key={j} textAlign={key.align ? key.align : 'center'} collapsing={key.collapsing ?? false}>
                                                            <strong>{i18next.t(record[key.name])}</strong>
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                        }
                                    case constants.TABLE_KEY_SYNC_STATUS:
                                        {

                                            switch (record[key.name].status) {
                                                case calendarConstants.STATUS.SYNCED: {
                                                    return (
                                                        <TableCell
                                                            key={j} 
                                                            record={record} 
                                                            className={key.classNames}
                                                            collapsing={key.collapsing ?? false}
                                                            textAlign={key.align ? key.align : 'center'}>
                                                            <span>{record[key.name].text}</span>
                                                        </TableCell>
                                                    )
                                                }
                                                case calendarConstants.STATUS.SYNCING: {
                                                    return (
                                                        <TableCell
                                                            key={j} 
                                                            record={record} 
                                                            className={key.classNames}
                                                            textAlign={key.align ? key.align : 'center'} 
                                                            collapsing={key.collapsing ?? false}>
                                                            <Icon name='sync' size='small' className='primary-text mr-1' loading /> <strong className="primary-text">{record[key.name].text}</strong>
                                                        </TableCell>
                                                    )
                                                }
                                                default: {
                                                    return (
                                                        <TableCell
                                                            className={key.classNames}
                                                            record={record} key={j} textAlign={key.align ? key.align : 'center'} collapsing={key.collapsing ?? false}>
                                                            <span>{record[key.name].text}</span>
                                                        </TableCell>
                                                    )
                                                }
                                            }
                                        }
                                    case constants.TABLE_KEY_ACTIONS:
                                        {
                                            return (
                                                <TableCell
                                                    record={record}
                                                    key={j}
                                                    textAlign='center'
                                                    collapsing={key.collapsing ?? false}
                                                    className={key.classNames}
                                                    checkbox>
                                                    
                                                    {!isEmpty(actionFunctions) && (
                                                        <Button.Group className="action-group" basic size='small'>
                                                            {map(record.actions, (action, jj) => {
                                                                const isLocked = record.is_locked
                                                                const _menu = renderMenuCaption(action)
                                                                const _icon = _menu.icon
                                                                const _title = _menu.title
                                                                
                                                                // When the action is a Delete and we have a Lock, we should show the lock in place of the delete action
                                                                if ([constants.MENU_ACTION_DELETE, constants.MENU_ACTION_REMOVE].includes(action) && isLocked) {
                                                                    return (
                                                                        <Popup
                                                                            position="top center"
                                                                            content={record.locked_reason ?? i18next.t('partials.Table.lockedReason')}
                                                                            trigger={
                                                                                <Button className='is-locked' key={jj}>
                                                                                    <Icon name='lock' /><span>{i18next.t('partials.Table.locked')}</span>
                                                                                </Button>
                                                                            } />
                                                                    )
                                                                }

                                                                return (
                                                                    <Button key={jj} onClick={() => actionFunctions[action] ? actionFunctions[action](record) : null}>
                                                                        <Icon name={_icon} />{_title}
                                                                    </Button>
                                                                )
                                                            })}
                                                            {/* <Button icon="ellipsis vertical" /> */}
                                                        </Button.Group>
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_CHECKBOX:
                                        {
                                            return (
                                                <TableCell
                                                    className="checkbox"
                                                    record={record}
                                                    key={j}
                                                    textAlign='center'
                                                    checkbox
                                                    collapsing={key.collapsing ?? false}>
                                                    <label>
                                                        {!isEmpty(record.disabled_reason) && (
                                                            <Popup
                                                                basic
                                                                size="small"
                                                                trigger={
                                                                    <Icon className={`${record.disabled_icon_name ?? 'info circle'} info-icon community-roles no-margin`} />
                                                                }
                                                                content={record.disabled_reason}
                                                            />
                                                        )}
                                                        {isEmpty(record.disabled_reason) && (
                                                            <input
                                                                type="checkbox"
                                                                checked={determineChecked(record)}
                                                                onChange={handleCheck.bind(this, record)} />
                                                        )}
                                                    </label>
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_HOVERABLE:
                                        {
                                            let content = record[key.name].data

                                            if (isArray(record[key.name].data)) {
                                                content = [].concat(record[key.name].data).join(", ")
                                            }

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} 
                                                    key={j} 
                                                    textAlign={key.align ?? 'center'} 
                                                    collapsing={key.collapsing ?? false}>
                                                    <Popup
                                                        position="top center"
                                                        content={content}
                                                        trigger={
                                                            <Icon
                                                                name={record[key.name].icon}
                                                                className="primary-icon" />
                                                        } />
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_HOVERABLE_COUNT:
                                        {

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} 
                                                    key={j} 
                                                    textAlign={key.align ?? 'center'} 
                                                    collapsing={key.collapsing ?? false}>
                                                    {size(record[key.name].data) > 0 && (
                                                        <Popup
                                                            position="top center"
                                                            content={[].concat(record[key.name].data).join(", ")}
                                                            trigger={
                                                                <Label size='small' as='a' className="color-primary-light">
                                                                    {size(record[key.name].data)}
                                                                </Label>
                                                            } />
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_CUSTOM_ICON:
                                        {
                                            return (
                                                <TableCell
                                                    className={`${key.classNames || ''} custom-icon`}
                                                    record={record} key={j} textAlign='center' collapsing={key.collapsing ?? false}>
                                                    {!isEmpty(key.message) ? (
                                                        <Popup
                                                            position="top center"
                                                            content={(key.name === 'has_app' && record[key.name]) ? <p>{key.message}<br/><br/><strong>{t('manageMembers.table.headers.appLastActive', {date: record.app_last_seen_at})}</strong></p> : key.message}
                                                            trigger={
                                                                record[key.name] ? (
                                                                    <div className="icon-container">
                                                                        <span className={`material-icons ${key.active ?? 'disabled-icon'}`}>{key.icon}</span>
                                                                    </div>
                                                                ) : null
                                                            } />
                                                    ) : (
                                                        record[key.name] ? <div className="icon-container">
                                                            <span className={`material-icons ${key.active ?? 'disabled-icon'}`}>{key.icon}</span>
                                                        </div> : null
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_HOVERABLE_DUAL:
                                        {
                                            const channels = record[key.name].channels
                                            const persons = record[key.name].persons
                                            const everyone = record[key.name].everyone

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} key={j} collapsing={key.collapsing ?? false}>
                                                    {size(channels.items) > 0 && (
                                                        <Popup
                                                            basic
                                                            content={[].concat(channels.items).join(", ")}
                                                            trigger={
                                                                <Label size='small' as='a' className={channels.color}>
                                                                    {`${channels.name} (${size(channels.items)})`}
                                                                </Label>
                                                            } />
                                                    )}
                                                    {size(persons.items) > 0 && (
                                                        <Popup
                                                            basic
                                                            content={[].concat(persons.items).join(", ")}
                                                            trigger={
                                                                <Label size='small' as='a' className={persons.color}>
                                                                    {`${persons.name} (${size(persons.items)})`}
                                                                </Label>
                                                            } />
                                                    )}
                                                    {everyone && (
                                                        <Label size='small' as='a' className={everyone.color}>
                                                            {everyone.name}
                                                        </Label>
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_PARENT_LINK_ICON:
                                        {
                                            const parents = record[key.name].parents
                                            const children = record[key.name].children

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    textAlign='center'
                                                    record={record} key={j} collapsing={key.collapsing ?? false}>
                                                    {size(parents) > 0 && (
                                                        <Popup
                                                            basic
                                                            content={
                                                                <div>
                                                                    <strong>{t('communityMembers.ManageMembers.table.header.parent.label-description', {count: size(parents)})}</strong>
                                                                    {[].concat(parents).map((item, index) => <p className='no-margin' key={`${index}`}>{item}</p>)}
                                                                </div>
                                                            }
                                                            trigger={
                                                                <Label size='small' as='a' className='color-primary-light'>
                                                                    {t('communityMembers.ManageMembers.table.header.child')}
                                                                </Label>
                                                            } />
                                                    )}
                                                    {size(children) > 0 && (
                                                        <Popup
                                                            basic
                                                            content={
                                                                <div>
                                                                    <strong>{t('communityMembers.ManageMembers.table.header.child.label-description', {count: size(children)})}</strong>
                                                                    {[].concat(children).map((item, index) => <p className='no-margin' key={`${index}`}>{item}</p>)}
                                                                </div>
                                                            }
                                                            trigger={
                                                                <Label size='small' as='a' className='color-primary-light'>
                                                                    {t('communityMembers.ManageMembers.table.header.parent')}
                                                                </Label>
                                                            } />
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_DISTRIBUTION:
                                        {

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} key={j} textAlign='left' collapsing={key.collapsing ?? false}>
                                                    {map(record[key.name], (distribution, index) => {

                                                        // When the distribution is just Label only
                                                        if (!distribution.items) {
                                                            return (
                                                                <Label size='small' key={index} as='a' className="color-primary-light">
                                                                    {distribution.name}
                                                                </Label>
                                                            )
                                                        }

                                                        const emptyParentData = every(map(distribution.parents, "oValue"), i => i === null)
                                                        const emptyData = every(map(distribution.items, "oValue"), i => i === null)

                                                        // Check that the distribution item doesn't contain Null values on both parent and children
                                                        if (emptyData && emptyParentData) {
                                                            return null
                                                        }

                                                        return (
                                                            <Popup
                                                                basic
                                                                flowing
                                                                size="tiny"
                                                                key={index}
                                                                content={
                                                                    <div>

                                                                        {!emptyData && (
                                                                            <Fragment>
                                                                                <p className="no-margin">{distribution.description}</p>
                                                                                <ul>
                                                                                    {map(distribution.items, (item, itemIndex) => (
                                                                                        <li key={itemIndex}>
                                                                                            <strong>{item.name}:</strong> {moment(item.value).isValid() ? moment(item.value).format("L LT") : ""}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </Fragment>
                                                                        )}

                                                                        {!emptyParentData && (
                                                                            <Fragment>
                                                                                <p className="no-margin">{distribution.description} ({t('distribution.parentChild.parent')})</p>
                                                                                <ul>
                                                                                    {map(distribution.parents, (item, itemIndex) => (
                                                                                        <li key={itemIndex}>
                                                                                            <strong>{item.name}:</strong> {moment(item.value).isValid() ? moment(item.value).format("L LT") : ""}
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                            </Fragment>
                                                                        )}
                                                                    </div>
                                                                }
                                                                trigger={
                                                                    <Label size='small' as='a' className="color-primary-light">
                                                                        {distribution.name}
                                                                    </Label>
                                                                } />
                                                        )
                                                    })}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_RECIPIENT_TAGS:
                                        {

                                            return (
                                                <TableCell
                                                    isTags
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} key={j} textAlign='left' collapsing={key.collapsing ?? false}>
                                                    {map(record[key.name], (hovers, index) => {

                                                        // When the hovers is just Label only
                                                        if (size(hovers.items) === 0) {
                                                            return (
                                                                <Label size='small' key={index} as='a'>
                                                                    {hovers.title}
                                                                </Label>
                                                            )
                                                        }

                                                        return (
                                                            <Popup
                                                                basic
                                                                size="mini"
                                                                closeOnPortalMouseLeave // this will make sure when we hover inside the Popup it does not dismiss
                                                                key={index}
                                                                className='recipient-hover-popup'
                                                                content={
                                                                    <div className='recipient-hover-items'>
                                                                        <RecipientHoverItem hoverItem={hovers} />
                                                                    </div>
                                                                }
                                                                trigger={
                                                                    <Label size='small' as='a' className='tertiary-green lighten-5'>
                                                                        {hovers.title}
                                                                    </Label>
                                                                } />
                                                        )
                                                    })}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_ICON:
                                        {
                                            return (
                                                <TableCell
                                                    className={`${key.classNames || ''} custom-icon`}
                                                    record={record} key={j} textAlign='center' collapsing={key.collapsing ?? false}>
                                                    {isBoolean(record[key.name]) ? (
                                                        record[key.name] ? <Icon size='large' className={key.icon} /> : null
                                                    ) : <Icon size='large' className={key.icon} />}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_CUSTOM_ICON_DUAL_STATE:
                                        {
                                            return (
                                                <TableCell
                                                    className={`${key.classNames || ''} custom-icon`}
                                                    record={record} key={j} textAlign='center' collapsing={key.collapsing ?? false}>
                                                    {record[key.name] ? (
                                                        <span className={`material-icons active-icon`}>{key.activeIcon}</span>
                                                    ) : (
                                                        <span className={`material-icons disabled-icon`}>{key.disabledIcon}</span>
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_ROLES:
                                        {
                                            let roles = record[key.name].roles
                                            let communityRoles = record[key.name].community

                                            const fRoles = filter(roles, r => size(r.channels) > 0)

                                            let all = concat(fRoles, communityRoles)
                                            let content = ''
                                            let _limit = 2

                                            if (size(all) > _limit) {
                                                content = [].concat(map(differenceBy(all, take(all, _limit), "name"), "name")).join(", ")
                                            }

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} key={j} collapsing={key.collapsing ?? false}>
                                                    {map(take(all, _limit), (role, ri) => {

                                                        if (role.type === 'channels') {

                                                            if (size(role.channels) === 0) {
                                                                return null
                                                            }

                                                            return (
                                                                <Popup
                                                                    basic
                                                                    key={ri}
                                                                    content={[].concat(role.channels).join(", ")}
                                                                    trigger={
                                                                        <Label size='small' as='a' className={role.color}>
                                                                            {role.name + "  " + size(role.channels)}
                                                                        </Label>
                                                                    } />
                                                            )
                                                        }

                                                        if (role.type === 'community') {
                                                            return <Label size='small' key={ri} as='span'>{role.name}</Label>
                                                        }
                                                    })}

                                                    {size(all) > _limit && (
                                                        <Popup
                                                            basic
                                                            content={content}
                                                            trigger={
                                                                <Label size='small' as='a' className='color-primary'>
                                                                    <small className="primary-color-text">+{size(all) - _limit}</small>
                                                                </Label>
                                                            } />
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_TAGS:
                                        {
                                            let initial = take(record[key.name], 3)
                                            let remaining = map(difference(record[key.name], initial), "name")

                                            return (
                                                <TableCell
                                                    className={key.classNames ? key.classNames : ''}
                                                    record={record} key={j} collapsing={key.collapsing ?? false}>
                                                    {map(initial, (k, ki) => (
                                                        <Label size='small' key={ki} as='span' className={k.color}>{k.name}</Label>
                                                    ))}
                                                    {size(record[key.name]) > 3 && (
                                                        <Popup
                                                            basic
                                                            content={[].concat(remaining).join(", ")}
                                                            trigger={
                                                                <Label size='small' as='a' className="color-primary">
                                                                    <span className="primary-color-text">+{size(remaining)}</span>
                                                                </Label>
                                                            } />
                                                    )}
                                                </TableCell>
                                            )
                                        }
                                    case constants.TABLE_KEY_TAGS_GENERIC_HOVER_COUNT:
                                        {
                                            let tags = record[key.name]
                                            let content = ''
                                            let _limit = 2

                                            if (size(tags) > _limit) {
                                                content = [].concat(map(differenceBy(tags, take(tags, _limit), "name"), "name")).join(", ")
                                            }

                                            return (
                                                <TableCell
                                                    record={record}
                                                    key={j}
                                                    className={key.classNames ? key.classNames : ''}
                                                    collapsing={key.collapsing ?? false}>
                                                    {map(take(tags, _limit), (k, ki) => (
                                                        <Label size='small' key={ki} as='span' className={k.color}>{k.name}</Label>
                                                    ))}
                                                    {size(tags) > _limit && (
                                                        <Popup
                                                            content={content}
                                                            trigger={
                                                                <Label size='small' as='a' className='color-primary'>
                                                                    <small className="primary-color-text"><strong>+{size(record[key.name]) - _limit}</strong></small>
                                                                </Label>
                                                            } />
                                                    )}
                                                </TableCell>
                                            )
                                        }

                                    default: {

                                        if (!onRecordClick) {
                                            return (
                                                <TableCell
                                                    record={record}
                                                    className={key.classNames ? key.classNames : ''}
                                                    collapsing={key.collapsing ?? false}
                                                    textAlign={key.align ? key.align : 'left'}
                                                    key={j}>
                                                    <span title={record[key.name]}>{record[key.name]}</span>
                                                </TableCell>
                                            )
                                        }

                                        return (
                                            <TableCell
                                                record={record}
                                                collapsing={key.collapsing ?? false}
                                                className={`pointer ${key.classNames ? key.classNames : ''}`}
                                                textAlign={key.align ? key.align : 'left'}
                                                key={j}>
                                                {!record[key.sub] && <span title={record[key.name]}>{record[key.name]}</span>}
                                                {record[key.sub] && (
                                                    <span className='content-w-subtext'>
                                                        <span title={record[key.name]}>{record[key.name]}</span>
                                                        <span className='sub header' title={record[key.sub]}>{record[key.sub]}</span>
                                                    </span>
                                                )}
                                            </TableCell>
                                        )
                                    }
                                }
                            })}
                        </STable.Row>
                    ))}

                    {(size(records) === 0 && !loading) && (
                        <STable.Row className="no-data">
                            <STable.Cell
                                colSpan={keys.length}
                                className="hide-on-small-down">
                                <h2 className="center-align">{noRecordsMessage}</h2>
                            </STable.Cell>
                        </STable.Row>
                    )}
                </STable.Body>
            </STable>
        </div>
    )
}

SuperTable.propTypes = propTypes
SuperTable.defaultProps = defaultProps

export default SuperTable