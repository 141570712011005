import { 
    createAction
} from 'redux-actions'

import { actions }      from '../constants'
import { userService } from '../api'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'
import { authActions } from './authActions'
import { appendCommunity, downloadBlob, downloadFileFromBlob, showNotification } from '../utils/helper'
import i18next from 'i18next'

const { USERS } = actions

export const usersActions = {

    /**
     * Start loading
     */
    start: createAction(USERS.START),
    
    /**
     * Show error messages
     */
    error: createAction(USERS.ERROR),

    /**
     * Show success messages
     */
    success: createAction(USERS.SUCCESS),

    /**
     * reset data
     */
    reset: createAction(USERS.RESET),
    
    /**
     * Save users
     */
    saveUsers: createAction(USERS.SAVE),
    
    /**
     * Save community members
     */
    saveMembers: createAction(USERS.SAVE_MEMBERS),
    
    /**
     * Save invites
     */
    saveInvites: createAction(USERS.SAVE_INVITES),
    
    /**
     * Save user details
     */
    saveUser: createAction(USERS.SAVE_USER_DETAILS),
    
    /**
     * Save member details
     */
    saveMember: createAction(USERS.SAVE_MEMBER_DETAILS),
    
    /**
     * Clean user details
     */
    cleanUserDetails: createAction(USERS.CLEAR_USER_DETAILS),
    
    /**
     * Clear errors and messages
     */
    clearMessages: createAction(USERS.CLEAR_MESSAGES),
    
    /**
     * Clean member details
     */
    cleanMemberDetails: createAction(USERS.CLEAR_MEMBER_DETAILS),

    /**
     * Save users' filters
     */
    startFilters: createAction(USERS.LOAD_FILTERS),
    saveFilters: createAction(USERS.SAVE_FILTERS),
    
    /**
     * Save user invite filters
     */
    saveInviteFilters: createAction(USERS.SAVE_INVITE_FILTERS),
    
    /**
     * Save Members' filters
     */
    saveMemberFilters: createAction(USERS.SAVE_MEMBER_FILTERS),
    
    /**
     * Save Users' invitation details
     */
    saveInviteDetails: createAction(USERS.SAVE_INVITE_DETAILS),

    /**
     * Fetch users from API
     * 
     * @param {JSON} params 
     */
    fetchUsers (params) {
        return (dispatch, getState) => userService.getUsers(params)
            .then(response => {
                dispatch(this.saveUsers(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch user from API
     * 
     * @param {JSON} params 
     */
    fetchUser (params) {
        return (dispatch, getState) => userService.getUser(params)
            .then(response => {
                dispatch(this.saveUser(response.data))
            })
            .catch(error => {
                // check that the error is not an Unauthorized action, so we can redirect the user to the home screen
                // TODO: This functionality could be moved to the Parent API Service so we can capture other events of the same behaviour
                const { status, data } = error.response
                
                if (status && status === 403) {
                    dispatch(push(routes.private.HOME))
                    return
                }

                dispatch(this.error(error))
            })
    },
    
    /**
     * Post updated user data to API
     * 
     * @param {JSON} params 
     */
    postUpdateUser (params) {
        return (dispatch, getState) => userService.postUpdateUser(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'usersActions.updateUser.success.header',
                    // content: 'usersActions.updateUser.success.message',
                    refreshData: true,
                }))
                dispatch(this.saveUser(response.data))

                if (params.forceRefresh) {
                    // We need to do a full reset, so we are not going to use the redux router to navigate
                    window.location.href = routes.private.HOME_COMM.replace(':communityId', params.communityId)
                }
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post detach user from community
     * 
     * @param {JSON} params 
     */
    postDetachUser (params) {
        return (dispatch, getState) => userService.postDetachUser(params)
            .then(response => {
                dispatch(this.success({
                    refreshData: true
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post detach users from community
     * 
     * @param {JSON} params 
     */
    postDetachManyUsers (params) {
        return (dispatch, getState) => userService.postDetachManyUsers(params)
            .then(response => {
                dispatch(this.success({
                    refreshData: true
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post invite user
     * 
     * @param {JSON} params 
     */
    postInviteUser (params) {
        return (dispatch, getState) => userService.postInviteUser(params)
            .then(response => {
                dispatch(this.success({
                    header: 'usersActions.inviteUser.success.header',
                    content: 'usersActions.inviteUser.success.message'
                }))
                dispatch(push(
                    routes.private.INVITES
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post delete invite
     * 
     * @param {JSON} params 
     */
    postDeleteInvite (params) {
        return (dispatch, getState) => userService.postDeleteInvite(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Invitation Deleted',
                    // content: 'Invitation was deleted successfully',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post delete invites
     * 
     * @param {JSON} params 
     */
    postDeleteMultiInvites (params) {
        return (dispatch, getState) => userService.postDeleteMultiInvites(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Invitation Deleted',
                    // content: 'Invitation was deleted successfully',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post resend invite
     * 
     * @param {JSON} params 
     */
    postResendInvite (params) {
        return (dispatch, getState) => userService.postResendInvite(params)
            .then(response => {
                dispatch(this.success({
                    header: 'usersActions.resendInvite.success.header',
                    content: 'usersActions.resendInvite.success.message',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch user invites
     * 
     * @param {JSON} params 
     */
    fetchInvites (params) {
        return (dispatch, getState) => userService.getInvites(params)
            .then(response => {
                dispatch(this.saveInvites(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch filters from API
     * 
     * @param {*} param 
     */
    fetchFilters ({communityId}) {
        return (dispatch, getState) => userService.getFilters({communityId})
            .then(response => {
                dispatch(this.saveFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch invitation filters from API
     * 
     * @param {*} param 
     */
    fetchInviteFilters ({communityId}) {
        return (dispatch, getState) => userService.getInviteFilters({communityId})
            .then(response => {
                dispatch(this.saveInviteFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch members from API
     * 
     * @param {JSON} params 
     */
    fetchMembers (params) {
        return (dispatch, getState) => userService.getMembers(params)
            .then(response => {
                dispatch(this.saveMembers(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch Member from API
     * 
     * @param {JSON} params 
     */
    fetchMember (params) {
        return (dispatch, getState) => userService.getMember(params)
            .then(response => {
                dispatch(this.saveMember(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post updated member data to API
     * 
     * @param {JSON} params 
     */
    postUpdateMember (params) {
        return (dispatch, getState) => userService.postUpdateMember(params)
            .then(response => {
                dispatch(this.saveMember(response.data))
                dispatch(this.success({
                    header: 'usersActions.updateMember.success.header',
                    content: 'usersActions.updateMember.success.message'
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post updated member data to API
     * 
     * @param {JSON} params 
     */
    postCreateMember (params) {
        return (dispatch, getState) => userService.postCreateMember(params)
            .then(response => {
                dispatch(this.saveMember(response.data))
                dispatch(push(
                    routes.private.MANAGE_MEMBERS
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch member filters from API
     * 
     * @param {*} param 
     */
    fetchMemberFilters ({communityId}) {
        return (dispatch, getState) => userService.getMemberFilters({communityId})
            .then(response => {
                dispatch(this.saveMemberFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post delete member
     * 
     * @param {JSON} params 
     */
    postDeleteMember (params) {
        return (dispatch, getState) => userService.postDeleteMember(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Community Member Deleted',
                    // content: 'Community Member was deleted successfully',
                    refreshData: true,
                    memberDeleted: true
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post delete members
     * 
     * @param {JSON} params 
     */
    postDeleteMultiMembers (params) {
        return (dispatch, getState) => userService.postDeleteMultiMembers(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Community Member Deleted',
                    // content: 'Community Member was deleted successfully',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post delete members via filters.
     * 
     * @param {JSON} params 
     */
     postDeleteMembersByFilters (params) {
        return (dispatch, getState) => userService.postDeleteMembersByFilters(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Community Member Deleted',
                    // content: 'Community Member was deleted successfully',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post add/remove members to or from members lists
     * 
     * @param {JSON} params 
     */
    postPromoteOrDemoteMembers (params) {
        return (dispatch, getState) => userService.postPromoteOrDemote(params)
            .then(response => {
                dispatch(this.success({
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Download selected members list in a provided format [csv|xlsx]
     * 
     * @param {JSON} params 
     */
     postExportMembers (params) {
        return (dispatch, getState) => userService.postExportMembers(params)
            .then(response => {
                dispatch(this.success({
                    header: 'usersActions.exportMembers.success.header',
                    content: 'usersActions.exportMembers.success.message',
                    // content: t('usersActions.exportMembers.success.message'),
                }))

                // downloadFileFromBlob(response, params.filename ?? 'Export')
            })
            .catch(error => {
                let _error = error.response

                if (_error?.data.code === 5000) {
                    _error = _error?.data?.message ?? "error.errorOccurred"

                    dispatch(this.success({
                        exportErrors: true,
                        header: 'usersActions.exportMembers.success.header',
                        content: _error,
                    }))

                } else {
                    dispatch(this.error(error))
                }
            })
    },
    
    /**
     * Post add/remove members to or from members lists
     * 
     * @param {JSON} params 
     */
     postPromoteOrDemoteMembersByFilters (params) {
        return (dispatch, getState) => userService.postPromoteOrDemoteByFilters(params)
            .then(response => {
                dispatch(this.success({
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post invite members to use the App
     * 
     * @param {JSON} params 
     */
     postInviteMemberToApp (params) {
        return (dispatch, getState) => userService.postInviteMemberToApp(params)
            .then(response => {
                if (params.isRemind) {
                    dispatch(this.success({
                        header: 'usersActions.remindUsers.success.header',
                        content: 'usersActions.remindUsers.success.message',
                    }))
                } else {
                    dispatch(this.success({
                        header: 'usersActions.inviteUsers.success.header',
                        content: 'usersActions.inviteUsers.success.message',
                    }))
                }
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post invite multiple members to the App
     * 
     * @param {JSON} params 
     */
     postInviteMembersToApp (params) {
        return (dispatch, getState) => userService.postInviteMembersToApp(params)
            .then(response => {

                if (params.isRemind) {
                    dispatch(this.success({
                        header: 'usersActions.remindUsers.success.header',
                        content: 'usersActions.remindUsers.success.message',
                    }))
                } else {
                    dispatch(this.success({
                        header: 'usersActions.inviteUsers.success.header',
                        content: 'usersActions.inviteUsers.success.message',
                    }))
                }
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Import members
     * 
     * @param {JSON} params 
     */
    postImportMembers (params) {
        return (dispatch, getState) => {

            const community = getState().navigation.community
            
            // Just a convenient way to show consistent pop-ups
            let hasErrors = false

            userService.postImportMembers(params)
                .then(response => {
                    hasErrors = false
                    if (!params.mode === 'VALIDATE-IMPORT') {
                        showNotification({
                            icon: community.image,
                            title: i18next.t('usersActions.importMembers.success.header'),
                            body: i18next.t('usersActions.importMembers.success.message'),
                            data: {
                                id: response.id,
                                communityId: community.id,
                            }
                        })
                    }
                })
                .catch(error => {

                    hasErrors = true

                    // We only need to look at validation errors nothing more
                    if (error?.response?.status === 422) {
                        dispatch(this.clearMessages())
                        dispatch(this.error({
                            response: { data: { message: error.response.data }}
                        }))
                        
                        return;
                    }

                    let _error = error.response

                    if (_error?.data.code === 5000) {
                        _error = _error?.data?.message ?? "error.errorOccurred"
                        dispatch(this.clearMessages())
                        dispatch(this.success({
                            exportErrors: true,
                            header: 'usersActions.exportMembers.success.header',
                            content: _error,
                        }))

                    } else {
                        dispatch(this.error(error))
                    }
                }).finally(() => {

                    if (!hasErrors) {
                        dispatch(this.success({
                            header: 'usersActions.importMembers.success.header',
                            content: 'usersActions.importMembers.success.message',
                            refreshData: true,
                            showImportProcessing: true,
                        }))
                    }
                })
        }
    },
    
    /**
     * Get invitation details
     * 
     * @param {JSON} params 
     */
    fetchInvitationDetails (params) {
        return (dispatch, getState) => userService.getInvitationDetails(params)
            .then(response => {
                dispatch(this.saveInviteDetails(response.data))
            })
            .catch(error => {
                // When a user lands on the invite details and there is a [404] meaning we could not find their invite code, we can redirect them to 
                if (error?.response?.status === 404) {
                    dispatch(push(routes.private.HOME))
                } else {
                    dispatch(this.error(error))
                }
            })
    },
    
    /**
     * Post Accept Invitation
     * 
     * @param {JSON} params 
     */
    postAcceptInvite (params) {
        return (dispatch, getState) => userService.postAcceptInvite(params)
            .then(response => {
                // HARD Refresh data and Pre-select the Accepted Invite's Community as the default
                window.location.href = appendCommunity(params.communityId, routes.private.HOME_COMM)
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
} 