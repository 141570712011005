import React, { useEffect, useState } from 'react'
import { Dropdown, Form, Grid, Input, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import ConfirmModal from './ConfirmModal'
import { map, mapKeys, size } from 'lodash'
import { calendarConstants } from '../../constants'
import ReactDatePicker from 'react-datepicker'
import { getError, weekOfMonth } from '../../utils/helper'
import moment from 'moment-timezone'
import CustomDateInput from './CustomDateInput'
import ErrorLabel from './ErrorLabel'
import PostEventRecurringRulesRequest from '../../api/requestObjects/PostEventRecurringRulesRequest'

const GenerateDays = (props) => {
    
    const { t } = useTranslation()
    const { onChange, errors } = props    
    const [selected, setSelected] = useState(mapKeys(props.selected ?? []))

    const handleDaysChange = (day) => {
        const tmpArray = map(selected)

        // When the item exists in the array, we remove it
        const indexOf = tmpArray.indexOf(day)
        
        if (indexOf !== -1) {
            tmpArray.splice(indexOf, 1)
        } else {
            // When the item does not exist, we add it
            tmpArray.push(day)
        }

        setSelected(mapKeys(tmpArray))
        onChange(tmpArray)
    }

    return (
        <div className='days-container'>
            <Form.Field
                error={!getError('days', errors).isValid}>
                <label className='label'>{t('event.create.repeat.custom.modal.input.placeholder.repeatsOn')}</label>
                <ErrorLabel className='ui pointing below prompt label w-fit-content' error={getError("days", errors)}  />
            </Form.Field>
            <div className='flex'>
                {map(calendarConstants.REPEAT_WEEKDAYS, (weekday) => (
                    <Form.Checkbox 
                        key={weekday.index} 
                        label={weekday.label} 
                        checked={selected[weekday.index] === weekday.index} 
                        onChange={() => handleDaysChange(weekday?.rruleKey?.getJsWeekday())}/>
                ))}
            </div>
        </div>
    )
}

const CustomRRulesModal = (props) => {
    
    const validStartDate = moment(props?.startDate ?? null).isValid() ? moment(props?.startDate).toDate() : moment().toDate()
    const minStartDate = moment(validStartDate).add(1, 'day').toDate()

    let endsOnDate = moment(props.selected?.endsOnDate ?? null).isValid() ? props.selected?.endsOnDate : minStartDate

    // When the start date is somehow greater than the end date, we need to make sure the endsOnDate is in the future.
    if (moment(validStartDate).isSameOrAfter(moment(endsOnDate))) {
        endsOnDate = moment(minStartDate).toDate()
    }
    
    const [data, setData] = useState({
        days: props.selected?.days ?? [],
        interval: props.selected?.interval ?? 1,
        doesEnd: props.selected?.doesEnd ?? 'never',
        monthlyOn: props.selected?.monthlyOn ?? calendarConstants.REPEAT_MONTHLY_OPTIONS.MONTHLY_ON_DATE,
        afterOccurrences: props.selected?.afterOccurrences ?? 10,
        frequency: props.selected?.frequency ?? calendarConstants.REPEAT_FREQUENCY.WEEKLY.key,
        endsOnDate: endsOnDate,
    })

    const [validationMessages, setValidationMessages] = useState([])

    const { t } = useTranslation()

    const _options = map(calendarConstants.REPEAT_FREQUENCY, c => {
        return {
            key: c.key,
            value: c.key,
            text: t(c.i18Key, {context: data.interval > 1 && 'plural'}),
        }
    })
    
    const [options, setOption] = useState(_options)

    useEffect(() => {
        
        // When the interval changes, we should make sure our translated strings capture plural formulas
        const _options = map(calendarConstants.REPEAT_FREQUENCY, c => {
            return {
                key: c.key,
                value: c.key,
                text: t(c.i18Key, {context: parseInt(data.interval) > 1 && 'plural'}),
            }
        })

      setOption(_options)

    }, [data.interval])
    
    const onChange = (event) => {

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData(prev => ({...prev, [name]: value}))
    }

    const onOptionsChange = (e, { value }) => {
        setData(prev => ({...prev, frequency: value}))
    }
    
    const onMonthOptionsChange = (e, { value }) => {
        setData(prev => ({...prev, monthlyOn: value}))
    }
    
    const handleUpdateDays = (days) => {
        setData(prev => ({...prev, days: days}))
    }

    const handleDateTimeChange = ({name, date}) => {

        setData((prevData) => ({
            ...prevData,
            [name]: date,
        }))
    }
    
    const handleEndsChange = (value) => {

        setData((prevData) => ({
            ...prevData,
            doesEnd: value,
        }))
    }

    const handleConfirm = (_data) => {
        
        const request = new PostEventRecurringRulesRequest(_data)
        const validator = request.validator()

        if (!validator.isValid) {
            setValidationMessages(validator.messages)
            return;
        }

        setValidationMessages([])
        
        if (props.onDone) {
            props.onDone(request.getPayload())
        }
    }

    return (
        <ConfirmModal 
            {...props} 
            withContent
            errors={validationMessages}
            data={{
                ...data,
                options,
            }}
            confirmText={t('event.create.repeat.custom.modal.confirmText')} 
            cancelText={t('modal.confirm.default.button.cancel')}
            header={t('event.create.repeat.custom.modal.header')}
            onConfirm={handleConfirm}
            parentClassName='recurrence-rule-modal'
            render={({ errors, data: _data }) => (
                <Form error={size(errors) > 0}>
                    <Grid>
                        <Grid.Row columns='equal'>
                            <Grid.Column verticalAlign='middle' width={4}>
                                <Form.Field>
                                    <label className='label'>{t('event.create.repeat.custom.modal.input.placeholder.repeatInterval')}</label>
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Form.Field
                                    required
                                    error={!getError("interval", errors).isValid}>
                                    <Form.Input 
                                        name='interval' 
                                        onChange={onChange} 
                                        value={_data.interval} 
                                        type='number'
                                        error={getError("interval", errors).message} />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    className="input-container theme-color"
                                    error={!getError("frequency", errors).isValid}>
                                    <Dropdown
                                        fluid
                                        selection
                                        options={_data.options}
                                        value={_data.frequency}
                                        className='theme-color'
                                        onChange={onOptionsChange}
                                        error={getError("frequency", errors).message} />
                                    <ErrorLabel className='ui pointing above prompt label w-fit-content' error={getError("frequency", errors)}  />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        {(_data.frequency === calendarConstants.REPEAT_FREQUENCY.WEEKLY.key) && (
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <GenerateDays errors={errors} selected={_data.days} onChange={handleUpdateDays}/>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {(_data.frequency === calendarConstants.REPEAT_FREQUENCY.MONTHLY.key && moment(props?.startDate).isValid()) && (
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Form.Field
                                        className="input-container theme-color"
                                        error={!getError("monthlyOn", errors).isValid}>
                                        <Dropdown
                                            fluid
                                            selection
                                            options={[
                                                {
                                                    key: 0,
                                                    value: calendarConstants.REPEAT_MONTHLY_OPTIONS.MONTHLY_ON_DATE,
                                                    text: t('event.create.repeat.custom.modal.monthly.placeholder', {day: moment(props?.startDate).format('D')}),
                                                },
                                                {
                                                    key: 1,
                                                    value: calendarConstants.REPEAT_MONTHLY_OPTIONS.MONTHLY_ON_WEEK_DAY,
                                                    text: t('event.create.repeat.custom.modal.monthly.onWeekDay.placeholder', {
                                                        day: moment().date(weekOfMonth(moment(props?.startDate))).format("Do"),
                                                        dayName: moment(props?.startDate).format('dddd')
                                                    }),
                                                }
                                            ]}
                                            value={_data.monthlyOn}
                                            className='theme-color'
                                            onChange={onMonthOptionsChange}
                                            error={getError("monthlyOn", errors).message} />
                                    </Form.Field>
                                    {/* <Message>
                                        {t('event.create.repeat.custom.modal.monthly.placeholder', {day: moment(props?.startDate).format('D')})}
                                    </Message> */}
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Form.Field>
                                    <label className='label'>{t('event.create.repeat.custom.modal.input.placeholder.endsOn')}</label>
                                </Form.Field>
                                <Form.Radio name='until' checked={_data.doesEnd === 'never'} onChange={() => handleEndsChange('never')} label={t('event.create.repeat.custom.modal.input.placeholder.neverEnds')} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column verticalAlign='middle' width={4}>
                                <Form.Radio name='until' checked={_data.doesEnd === 'ends_on'} onChange={() => handleEndsChange('ends_on')} label={t('event.create.repeat.custom.modal.input.placeholder.endsOnDate')} />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Field
                                    disabled={_data.doesEnd !== 'ends_on'}
                                    required
                                    error={!getError("endsOnDate", errors).isValid}
                                    className="input-container theme-color">
                                        <ReactDatePicker 
                                            className="date-picker"
                                            selected={_data.endsOnDate}
                                            onChange={date => handleDateTimeChange({name: "endsOnDate", date: date})}
                                            dateFormat="yyyy/MM/dd"
                                            // minDate={minStartDate}
                                            customInput={<CustomDateInput icon="calendar" />} />
                                    <ErrorLabel className='ui pointing above prompt label w-fit-content' error={getError("endsOnDate", errors)}  />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column verticalAlign='middle' width={4}>
                                <Form.Radio name='until' checked={_data.doesEnd === 'ends_after'} onChange={() => handleEndsChange('ends_after')} label={t('event.create.repeat.custom.modal.input.placeholder.endsAfter')} />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Field
                                    disabled={_data.doesEnd !== 'ends_after'}
                                    required
                                    error={!getError("endsAfter", errors).isValid}
                                    className="input-container theme-color">
                                        <Input
                                            name='afterOccurrences' 
                                            onChange={onChange}
                                            type='number' 
                                            value={_data.afterOccurrences} 
                                            labelPosition='right'
                                            label={{ basic: true, content: t('event.create.repeat.custom.modal.input.placeholder.afterOccurrences') }}/>
                                    <ErrorLabel className='ui pointing above prompt label w-fit-content' error={getError("endsAfter", errors)}  />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )} />
    )
}

export default CustomRRulesModal