import React, { useState, Fragment, useEffect, useCallback, useMemo } from 'react'
import { Grid, Input, Popup, Button, Icon, Checkbox, Segment, Form, Image } from 'semantic-ui-react'
import { clone, cloneDeep, findIndex, first, map, mapKeys, mapValues, omit, size, without } from 'lodash'
import { v4 as uuidv4, v4 } from 'uuid'
import { getError, setError } from '../../../../utils/helper'
import RichTextEditor from '../RichTextEditor'
import { useDropzone } from 'react-dropzone'
import { messageBuilder } from '../../../../constants'
import { uploadService } from '../../../../api'
import { ImageThumb } from '../ImageMorphed'
import { useDispatch } from 'react-redux'
import { builderActions } from '../../../../actions/builderActions'

function Option({option, handleOptionChange, handleRemoveOption, t}) {
    
    // Houses the item_ids that are currently being uploaded or being loaded
    // When an item is busy, its ID will remain in the list, but once done, the ID will be removed from the list
    const [busyItems, setBusyItems] = useState([])
    const mimes = messageBuilder.SUPPORTED_IMAGE_MIMES.split(",")

    const [validationMessages, setValidationMessages] = useState([])
    const dispatch = useDispatch()

    const {
        open,
        getRootProps,
        getInputProps,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        multiple: false,
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        accept: mapValues(mapKeys(mimes), k => []),
        validator: (file) => {
            
            // Validate the File Size
            if (file.size > messageBuilder.MAX_FILE_SIZE) {
                return {
                    code: "file-too-big",
                    message: t('messageBuilder.morphedTemplates.AttachmentMorphed.maxFileError')
                }
            }
            
            // Validate the Mime Type and block .exe upload
            if ([
                "application/vnd.microsoft.portable-executable", 
                "application/x-msdownload"
              ].includes(file.type)) {
                return {
                    code: "file-not-supported",
                    message: t('messageBuilder.morphedTemplates.imageMorphed.unsupportedFileError')
                }
            }

            return null
        }
    })
    
    useEffect(() => {
        
        if (acceptedFiles.length === 0) return;

        const incoming = map(acceptedFiles, file => {
            
            const tempId = v4() + messageBuilder.TEMP_ID_PREFIX
            
            return {
                file: {
                    url: null,
                    id: tempId,
                    blob: file,
                    mime: file.type,
                },
                id: tempId,
                _parentId: option.id
            }
        })

        const mappedIncoming = mapKeys(incoming, i => i.id)

        // When images are dropped here, they are going to be processed by the uploader, so we are going to add all images and their temp ids starting here
        setBusyItems(map(incoming, "id"))
        
        onChange({name: 'files', value: {
            ...mappedIncoming
        }})
    }, [acceptedFiles])
    
    useEffect(() => {
        
        if (fileRejections.length === 0) {
            setValidationMessages([])
            return;
        }
        
        const rejection = first(fileRejections)
        const error = fileRejections.length > 1 ? t("requestObjects.PostCreateNoticeRequest.file.required.atLeastOne") : (rejection.errors.length > 0 ? rejection.errors[0].message : t("error.errorOccurred"))
        setValidationMessages(setError("file", error))
        
    }, [fileRejections])

    useEffect(() => {
        
        // With anything happening in the busy items, we will update the builder with the status
        // The busy indicator should only stop once the are no more items in the busyItems list
        dispatch(builderActions.busyIndicator({
            loading: (busyItems.length > 0),
            items: busyItems.length
        }))

    }, [busyItems])
    
    const onChange = ({name, value}) => {
        handleOptionChange({
            ...option,
            ...{name, value},
        })
    }
    
    const onImageDecoded = ({imageData, index}) => {
        const _images = {...option.files}
        const _file = {...imageData}
        
        onChange({name: 'files', value: {
            ..._images,
            [index]: _file,
        }})
    }
    
    const onUploadSuccess = ({imageData, id}) => updateFileUploadStatus({imageData, id}, false)

    const onUploadError = ({imageData, id}) => updateFileUploadStatus({imageData, id}, true)

    const updateFileUploadStatus = ({imageData, id}, hasError = false) => {
        const _images = {...option.files}
        const _file = {...imageData, loading: false}
        
        onChange({name: 'files', value: {
            ..._images,
            [id]: _file,
        }})

        setBusyItems(prev => without(prev, id)) // Remove an item once its done loaded
    }
    
    const onRemoveImage = ({index}) => {

        // Get all of the images already listed
        const _all = {...option.files}
        
        // Remove the item using the given index
        const _images = omit(_all, [index])
        
        // Update the items
        onChange({name: 'files', value: _images})
    }
    
    const handleTextChange = (markdown, jsonContent) => {
        onChange({name: 'content', value: {markdown, jsonContent}})
    }

    return (
        <Grid.Row className='vote-option-container mg-bot-15'>
            <Grid.Column>
                <Form>
                    <label className='label'>{t("general.form.multi.option")}</label>
                    <Form.Field
                        required
                        error={!getError("title", validationMessages).isValid}
                        className="input-container theme-color mg-top-10">
                        <Form.Input
                            label={t('components.vote.title.placeholder')}
                            id="title-input"
                            placeholder={t('components.vote.title.placeholder')}
                            name="name"
                            value={option.title}
                            error={getError("title", validationMessages).message}
                            autoComplete="off"
                            onChange={(e, data) => onChange({name: 'title', value: data.value})} />
                    </Form.Field>
                    <RichTextEditor
                        content={option.content} 
                        jsonContent={option.jsonContent}
                        placeholder={t('components.vote.input.description.label')}
                        onChange={handleTextChange} />
                    <Form.Field>
                        <div style={{marginTop: 15}} {...getRootProps({className: `dropzone ${!getError("file", validationMessages).isValid ? 'error' : ''}`})}>
                            <input {...getInputProps()} />
                            {size(option.files) == 0 && (
                                <div className='vertical-align w-full'>
                                    <Button 
                                        size="mini"
                                        className="secondary"
                                        onClick={open}>{t('messageBuilder.morphedTemplates.AttachmentMorphed.chooseFile')}</Button>
                                    <p>{t('messageBuilder.morphedTemplates.AttachmentMorphed.selectFilePlaceholder')}</p>
                                </div>
                            )}

                            <Image.Group size='tiny' className='w-100 no-padding'>
                                {map(option.files, (file, i) => (
                                    <ImageThumb
                                        key={i} 
                                        index={i} 
                                        data={file}
                                        onRemove={onRemoveImage}
                                        onUploadError={onUploadError}
                                        onImageDecoded={onImageDecoded}
                                        onUploadSuccess={onUploadSuccess} />
                                ))}
                                
                                {size(option.files) > 0 && (
                                    <div className="ui image mg-similar-morphed">
                                        <div className="ui image add-new no-margin" onClick={open}>
                                            <Icon name='plus' size='big' className='no-margin' />
                                        </div>
                                    </div>
                                )}
                            </Image.Group>
                        </div>
                    </Form.Field>
                </Form>
                <Button.Group basic size='small' className='option-action-buttons'>
                    <Button icon='trash' onClick={handleRemoveOption} />
                    {/* <Button icon='chevron down' onClick={() => {}} /> */}
                </Button.Group>
            </Grid.Column>
        </Grid.Row>
    )
}

export default function OptionBuilder({item, handleInputChange, t}) {
    
    const defaultOptions = useMemo(() => cloneDeep(item?.data?.options ?? []), [item?.data?.options])

    const [options, setOptions] = useState(defaultOptions)
    
    const handleAddOption = () => {
        const newOptions = options.concat([{
            id: uuidv4(), // Whenever we add a new option, we can create a UUID for the option to avoid clashes
            title: "",
            description: "",
            files: {}
        }])

        setOptions(newOptions)
        handleInputChange({name: "options", value: newOptions})
    }

    const handleOptionChange = (data) => {
        
        const { id, name, value } = data

        // Options copy
        const _options = options.map(option => {
            if (option.id !== id) {
                return option
            }

            if (name === 'content') {
                const {markdown, jsonContent} = value
                return {
                    ...option,
                    [name]: markdown,
                    description: markdown,
                    jsonContent: jsonContent,
                }
            }

            return {
                ...option,
                [name]: value
            }
        })
        setOptions(_options)

    }

    const handleRemoveOption = (id) => {
        
        const index = findIndex(options, {id: id})
        
        // Options copy
        const _options = [...options]
        
        if (index !== -1) {
            // Remove the found item
            _options.splice(index, 1)

            if (!item?.data?.originalId) {
                // Update the named indexes
                map(_options, (_option, index) => {
                    _option.title = _option.title ?? t('builder.form.multichoice.option.placeholder', {index: index + 1})
                })
            }

            // Update list
            setOptions(_options)
        }
    }

    useEffect(() => {
        // When a component has an original_id, means we are editing
        if (item?.data?.originalId) {
            return;
        }

        // When a component already has some options, we will not add a placeholder option
        if (size(options) > 0) {
            return;
        }

        // On initialisation, when there are no options, we will prefill with default first
        const placeholderText = t('builder.form.multichoice.option.placeholder', {index: 1})
        handleAddOption(placeholderText)
    }, [])
    
    useEffect(() => {
        handleInputChange({name: "options", value: options})
    }, [options])
    

    return (
        <Fragment>
            {map(options, (option, index) => (
                <Option 
                    t={t}
                    key={index}
                    option={option}
                    handleRemoveOption={() => handleRemoveOption(option.id)}
                    handleOptionChange={handleOptionChange} />
            ))}
            <Grid.Row>
                <Grid.Column className="right-align">
                    <Button className="secondary" onClick={() => handleAddOption(t('builder.form.multichoice.option.placeholder', {index: size(options) + 1}))}>{t("general.form.multi.addOption")}</Button>
                </Grid.Column>
            </Grid.Row>
        </Fragment>
    )
}
