import React, { useState, useEffect, Fragment, createRef, useRef } from 'react'
import { Menu, Dropdown, Icon, Flag, Button, Search, DropdownItem } from 'semantic-ui-react'

import logo from '../../images/d6_logo_teal.svg'
import logoPlaceholder from '../../images/image_placeholder.png'

import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '../../actions/authActions'
import { navigationActions } from '../../actions/navigationActions'
import { delay, escapeRegExp, filter, find, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import routes from '../../routes/route-constants'
import { appendCommunity } from '../../utils/helper'
import { push } from 'connected-react-router'

import AnnounceKit from 'announcekit-react'
import { tieringActions } from '../../actions/tieringActions'
import { FaCrown } from 'react-icons/fa'
import { constants } from '../../constants'

/**
 * 
 * @param {*} props 
 */
function TopNavigation(props) {
  
  const {
    params,
    collapsed, 
    toggled, 
    handleToggleChange,
    handleCollapsedChange
  } = props

  const { t, i18n } = useTranslation()

  const defaultLocales = {
    'en': { ['data-test-id']: 'en', key: 'en', value: 'en', flag: 'gb', text: 'English' },
    'es': { ['data-test-id']: 'es', key: 'es', value: 'es', flag: 'es', text: 'Spanish' },
    'af': { ['data-test-id']: 'af', key: 'af', value: 'af', flag: 'za', text: 'Afrikaans' },
    'ar': { ['data-test-id']: 'ar', key: 'ar', value: 'ar', flag: 'ae', text: 'Arabic' },
    'fr': { ['data-test-id']: 'fr', key: 'fr', value: 'fr', flag: 'fr', text: 'French' },
  }

  const determineDefaultFlag = () => {
    const language = defaultLocales[i18n.language]
    if (!language) {
      return defaultLocales['en'].flag
    }

    return language.flag
  }

  const refDropdown = useRef()
  const profile = useSelector(state => state.auth.profile)
  const navigation = useSelector(state => state.navigation)
  const [community, setCommunity] = useState(params.communityId ?? null)
  const [locale, setLocale] = useState(i18n.language || 'en')
  const [flag, setFlag] = useState(determineDefaultFlag())
  const [communities, setCommunities] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [announceKitUser, setAnnounceKitUser] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [experimentalFlag, setExperimentalFlag] = useState(0)

  const dispatch = useDispatch()

  const handleCommunityChange = (e, { value }) => {
    setCommunity(value)
    dispatch(navigationActions.selectCommunity(value))
    delay(() => {
      window.location.href = appendCommunity(value, routes.private.HOME_COMM)
    }, 300)
  }

  const handleLanguageChange = (e, data) => {
    
    const selected = find(data.options, {value: data.value})
    
    setLocale(selected.value)
    setFlag(selected.flag)

    i18n.changeLanguage(selected.value)
    dispatch(navigationActions.toggleLocale(selected.value))
  }

  const handleOpenZohoSales = () => {
    window.open(routes.public.WEB_FORM, "_blank")
  }

  useEffect(() => {

    setCommunity(params.communityId)

    return () => {}
  }, [params.communityId])
  
  useEffect(() => {
    
    if (!profile?.id) return;

    setAnnounceKitUser({
      id: profile.id,
      email: profile.email,
      name: `${profile.first_name} ${profile.last_name}`
    })

  }, [profile?.id])
  
  useEffect(() => {
    
    if (!navigation.locale) return;
    
    // when the language gets changed, we will do a full browser reload for now
    dispatch(authActions.start())

    window.location.reload()

  }, [navigation.locale])
  
  const _getSearchResults = (items) => {
    const results = map(items, c => {
      return {
        id: c.key,
        title: c.text,
      }
    })

    return results
  }

  useEffect(() => {
    const decorated = map(navigation.decoratedCommunities, c => {
      return {
        key: c.key,
        value: c.value,
        text: (
          <Fragment><img src={c.image.src || logoPlaceholder} className="ui image logo-holder" alt={c.text} /><span data-test-id={c.value} className="community-name">{c.text}</span></Fragment>
        )
      }
    })
    
    const results = _getSearchResults(navigation.decoratedCommunities)
    
    setCommunities(decorated)
    setSearchResults(results)

  }, [navigation.decoratedCommunities])
  
  useEffect(() => {
    if (experimentalFlag > 4) {
      localStorage.setItem(constants.EXPERIMENTAL_FEATURE_FLAGS, true)
    }
  }, [experimentalFlag])


  const _handleShowUpgrade = () => dispatch(tieringActions.modal(true))

  const handleShowSearch = () => {
    setIsSearching(prev => (!prev))
  }
  
  const handleSearchChange = (e, data) => {
    const results = _getSearchResults(navigation.decoratedCommunities ?? [])
    
    if (data.value.length === 0) {
      setSearchResults(results)
      return
    }

    const re = new RegExp(escapeRegExp(data.value), 'i')
    const isMatch = (result) => re.test(result.title)
    setSearchResults(filter(results, isMatch))
  }
  
  const handleResultSelected = (e, data) => {
    if (data?.result?.id) {
      handleCommunityChange(e, {value: data.result.id})
    }
  }

  return (
    <Menu className="top-navigation">
     
      <Menu.Item 
        icon="bars" 
        className="no-line hoverable ham-container hidden-md-down"
        onClick={() => handleCollapsedChange(!collapsed)}/>

      <Menu.Item 
        icon="bars" 
        className="no-line hoverable ham-container visible-md-down"
        onClick={() => handleToggleChange(!toggled)}/>

      <Menu.Item className="logo-container">
        <img src={ logo } alt="" onClick={() => setExperimentalFlag(prev => prev + 1)} />
      </Menu.Item>
      
      {community && profile && (
        <Menu.Item>
          {isSearching ? (
            <Search onResultSelect={handleResultSelected} results={searchResults} onSearchChange={handleSearchChange}  />
          ) :
          (
            <Dropdown
              ref={refDropdown}
              placeholder='Select Community'
              floating
              item
              className='org-dropdown-container no-line'
              onChange={ handleCommunityChange }
              value={community}
              options={communities}
            />
          )}
          {communities.length > 10 && <Button icon={isSearching ? 'cancel' : 'search'} size='small' className='transparent' onClick={handleShowSearch} />}
        </Menu.Item>
      )}

      <Menu.Menu position='right'>
        
        {navigation.community && (
          <Menu compact borderless className='tiering-plan-menu'>
            <Menu.Item>
              {navigation.community.tiering_show_plan_name && <span>{navigation.community.tiering_plan_name ? t(navigation.community.tiering_plan_name) : ""}</span>}
              {navigation.community.tiering_show_upgrade && <Button onClick={_handleShowUpgrade} size='mini' className='primary solid'> <FaCrown className='secondary-text mg-right-2' /> <span className='hide-on-small-down'>{t('tiering.plan.upgrade')}</span></Button>}
            </Menu.Item>
          </Menu>
        )}
        
        <Dropdown 
            floating 
            item
            icon="help circle"
            className="no-line icon-only">
            <Dropdown.Menu className="profile-menu">
              <Dropdown.Item className="black-text">
                <a className="black-text" 
                  href={routes.public.WEB_FORM} 
                  rel="noopener noreferrer" 
                  target="_blank">
                    {t('general.help')}
                </a>
              </Dropdown.Item>
              <Dropdown.Item>
                <a className="black-text" 
                  href="https://docs.google.com/document/d/1PTwSPRSlRWSVsxMVSIWbuZ6hKv9zTkqjf1UN9XN78uc/edit?usp=sharing" 
                  rel="noopener noreferrer" 
                  target="_blank">
                    {t('general.training')}
                </a>
              </Dropdown.Item>
              <Dropdown.Item>
                <a className="black-text" 
                  href="https://docs.google.com/document/d/1RKPZhuRX-agHQSbFa89JGiZ0iQdabzwxNZ0OD5P8aAs/edit" 
                  rel="noopener noreferrer" 
                  target="_blank">
                    {t('general.documents')}
                </a>
              </Dropdown.Item>
              <Dropdown.Item>
                <a className="black-text" 
                  href="https://help.d6.co.za/portal/en/community/d6-communicator" 
                  rel="noopener noreferrer" 
                  target="_blank">
                    {t('general.requestFeature')}
                </a>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        {announceKitUser ? (
          <Menu.Item
            name='notification'
            className='no-line icon-only'
            id="notifications"
            content={
              <AnnounceKit user={announceKitUser} widget='https://announcekit.co/widgets/v2/3Fcfks'>
                <Icon className="announcekit-widget" name="announcement" />
              </AnnounceKit>
            }/>
        ) : null}

        <Menu.Item
          name='notification'
          className='no-line icon-only locale-switcher'
          content={
            <div className="input-container theme-color">
              <Dropdown
                inline
                labeled
                className='icon'
                text=" "
                icon={
                  <Fragment>
                    <Flag name={flag}/>
                    <Icon name='dropdown'/>
                  </Fragment>
                }
                onChange={handleLanguageChange}
                options={map(defaultLocales)}
                value={locale} />
            </div>
          }/>

        { profile && (
          <Dropdown 
            floating 
            className='profile-dropdown' 
            item
            icon={
              <Fragment>
                <Icon name="user" />
                <Icon name="dropdown" />
              </Fragment>
            }
            text={ `${profile.first_name  } ${  profile.last_name}` }>
            <Dropdown.Menu className="profile-menu">
              {/* <Dropdown.Item>{t('general.settings')}</Dropdown.Item> */}
              <Dropdown.Item onClick={() => dispatch(push(appendCommunity(navigation.communityId, routes.private.UPDATE_DETAILS)))}>{t('general.profile')}</Dropdown.Item>
              <Dropdown.Item onClick={() => dispatch(authActions.doLogout())}>{t('general.logout')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        { !profile && (
          <Dropdown floating className='profile-dropdown no-line' item text={ t('general.anonymous') }>
            <Dropdown.Menu className="profile-menu">
              <Dropdown.Item onClick={() => dispatch(authActions.doLogout())}>{t('general.logout')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

      </Menu.Menu>
    </Menu>
  )
}

export default TopNavigation