import React, { useEffect, useState } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function DateMorphed({item}) {

    const [data, setData] = useState({
        startDate: item.data ? item.data.startDate : moment().format("Y-M-D"),
        endDate: item.data ? item.data.endDate : moment().format("Y-M-D"),
        startTime: item.data ? item.data.startTime : moment().format("HH:mm"),
        endTime: item.data ? item.data.endTime : moment().format("HH:mm"),
        originalId: item?.data?.originalId
    })

    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({
            item, 
            data: {
                ...data,
                previewStartDate: moment(data.startDate).format("D MMM 'YY"),
                previewStartTime: data.startTime,
                previewEndDate: moment(data.endDate).format("D MMM 'YY"),
                previewEndTime: data.endTime,
            }
        }))
        return () => {}
    }, [data, dispatch])
    
    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }

    return (
        <BaseMorphed item={item} render={( onDataChange ) => (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.DateMorphed.startDate')}</label>
                            <Input 
                                name="startDate"
                                type="date"
                                value={data.startDate} 
                                onChange={handleInputChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.DateMorphed.startDate')} />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.DateMorphed.startTime')}</label>
                            <Input 
                                name="startTime"
                                type="time"
                                value={data.startTime} 
                                onChange={handleInputChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.DateMorphed.startTime')} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.DateMorphed.endDate')}</label>
                            <Input 
                                name="endDate"
                                type="date"
                                value={data.endDate} 
                                onChange={handleInputChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.DateMorphed.endDate')} />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.DateMorphed.endTime')}</label>
                            <Input 
                                name="endTime"
                                type="time"
                                value={data.endTime} 
                                onChange={handleInputChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.DateMorphed.endTime')} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )} />
    )
}
