import React, { Fragment, useEffect, useState } from "react"
import { Dropdown, Grid } from "semantic-ui-react"
import ErrorLabel from "./ErrorLabel"
import VirtualisedDropdown from "./VirtualisedDropdown"
import { useSelector } from "react-redux"

const Role = ({
    role, 
    options, 
    onChange, 
    placeholder, 
    selected, 
    popup, 
    disabled = false,
    error = null,
    onLabelClick = undefined,
    virtualised = false,
    multiple = true,
    search = true,
    lazyLoad = true,
    className = undefined,
    showLabel = true,
    handleOpen = () => {}
}) => {
    const [state, setState] = useState(selected)
    /**
     * 
     * @param {import("react").SyntheticEvent} event 
     * @param {*} param1 
     * @returns 
     */
    const handleChange = (event, {value}) => {
        
        if (event?.currentTarget?.classList?.contains('special-actions')) {
            return;
        }

        onChange({name: role.id, value, e: event})
        setState(value)
    }

    useEffect(() => {
        setState(selected)
    }, [selected])

    return (
        <Grid.Column className={className}>
            <div className={"input-container theme-color ".concat(`${error ? 'field error' : ''}`)}>
                {showLabel && <label className='label'>{ role.name } {popup ?? <Fragment />}</label>}
                {role.description && ( <p>{ role.description }</p> )}
                {role.smallDescription && ( <small className="grey-text mg-bot-10">{ role.smallDescription }</small> )}
                {options && !virtualised && (
                    <Dropdown
                        disabled={disabled}
                        className='theme-color'
                        placeholder={placeholder}
                        fluid
                        search={search}
                        selection
                        multiple={multiple}
                        value={state}
                        onChange={handleChange}
                        options={options}
                        lazyLoad
                        onLabelClick={onLabelClick} />
                )}
                {options && virtualised && (
                    <VirtualisedDropdown
                        disabled={disabled}
                        className='theme-color'
                        placeholder={placeholder}
                        fluid
                        search={search}
                        selection
                        multiple={multiple}
                        value={state}
                        onChange={handleChange}
                        options={options}
                        lazyLoad
                        onLabelClick={onLabelClick}
                        onOpen={() => handleOpen(true)}
                        onClose={() => handleOpen(false)} />
                )}
                {error && (<p className={`ui pointing above prompt label`}>{error}</p>)}
            </div>
        </Grid.Column>
    )
}

export default Role