import i18next from "i18next"
import { mapKeys, isEmpty, isUndefined, filter, omit } from "lodash"
import { dropEmptyKeys, filterNulls } from "../../utils/helper"
import moment from "moment"
import { messageBuilder } from "../../constants"

/**
 * 
 * @typedef Request
 * @property {String} title
 * @property {String} notes
 * @property {String} repeat
 * @property {Boolean} allDay
 * @property {String} endDate
 * @property {Array} personIds
 * @property {String} location
 * @property {String} startDate
 * @property {Array} channelIds
 * @property {String} calendarId
 * @property {String} communityId
 * @property {Number} remindMinutes
 * @property {Boolean} sendNotification
 * @property {Boolean} notify
 * @property {RRule} customRRules
 */

/**
 * 
 * @typedef RRule
 * @property {Number} interval
 * @property {String} frequency
 * @property {Array}  days
 * @property {Array}  _days: This is the days formatted, where the selected days values are capitalised as the API post requires
 * @property {String} until
 * @property {String} endsOnDate
 * @property {String} monthlyOn
 * @property {Number} afterOccurrences
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostCreateEventRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        title,
        notes,
        repeat,
        allDay,
        notify,
        endDate,
        endTime,
        eventId,
        location,
        startDate,
        startTime,
        personIds,
        channelIds,
        calendarId,
        communityId,
        remindMinutes,
        syncAccountId,
        sendNotification,
        customRRules,
    }) {
        this.title = title
        this.notes = notes
        this.notify = notify
        this.repeat = repeat
        this.allDay = allDay
        this.endTime = endTime
        this.endDate = endDate
        this.eventId = eventId
        this.location = location
        this.personIds = personIds
        this.startDate = startDate
        this.startTime = startTime
        this.channelIds = channelIds
        this.calendarId = calendarId
        this.communityId = communityId
        this.remindMinutes = remindMinutes
        this.syncAccountId = syncAccountId
        this.sendNotification = sendNotification
        this.customRRules = customRRules
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        // When an event is an all day event we take out the times
        const startDate = moment(`${this.startDate}T${this.allDay ? '00:00:00.000Z' : this.startTime}`)
        const endDate = moment(`${this.endDate}T${this.allDay ? '00:00:00.000Z' : this.endTime}`)

        const payload = {
            "title": this.title,
            "all_day": this.allDay ?? false,
            "end_date": endDate.toISOString(),
            "start_date": startDate.toISOString(),
            "community_id": this.communityId,
            "send_notification": this.sendNotification,
        }

        if (this.channelIds.length > 0) {
            payload["channel_ids"] = this.channelIds
        }
        
        if (this.personIds.length > 0) {
            payload["person_ids"] = this.personIds
        }
        
        if (!isUndefined(this.remindMinutes)) {
            
            const hasRemind = (this.remindMinutes > 0)
            payload["send_reminder"] = hasRemind
            
            //?NOTE: Setting the remind minutes to zero for now, as the backend will help set this value automatically
            payload["remind_minutes"] = 0
        }
        
        if (this.repeat) {
            payload["repeat"] = this.repeat
        }
        
        if (!isEmpty(this.notes)) {
            payload["notes"] = this.notes
        }
        
        if (!isEmpty(this.location)) {
            payload["location"] = this.location
        }
        
        if (!isEmpty(this.customRRules)) {
            const d = {
                ...this.customRRules,
                days: this.customRRules._raw_days,
                monthly_on: this.customRRules.monthlyOn
            }

            payload["custom_repeat_rule"] = omit(d, ['_days', 'doesEnd', '_raw_days', 'monthlyOn', 'endsOnDate', 'afterOccurrences'])
            payload["custom_repeat_rule"] = dropEmptyKeys(payload["custom_repeat_rule"])
        }

        return this.cleanPayload(payload)
    }

    cleanPayload = (payload) => {
       
        // We will remove the special case for targeting all,
        const _channels = filter(payload["channel_ids"], c => c === messageBuilder.TARGET_ALL_TAG_ID)
        const isTargetingAll = (_channels.length > 0)
        
        //? We will also clear any channels and persons selected, although at this points its highly unlikely for these to leak through, good safeguard
        payload["target_everyone"] = isTargetingAll
        
        if (isTargetingAll) {
            payload["person_ids"] = []
            payload["channel_ids"] = []
        }

        return filterNulls(payload)
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        if (!this.title) {
            errors.push({
                field: "title",
                isValid: false,
                message: i18next.t("calendar.events.create.title.required")
            })
        }
        
        if (!this.startDate) {
            errors.push({
                field: "startDate",
                isValid: false,
                message: i18next.t("calendar.events.create.startDate.required")
            })
        }
        
        if (!this.endDate) {
            errors.push({
                field: "endDate",
                isValid: false,
                message: i18next.t("calendar.events.create.endDate.required")
            })
        }
        
        if (!isEmpty(this.customRRules)) {

            if (moment(this.startDate).isSameOrAfter(moment(this.customRRules.endsOnDate))) {
                errors.push({
                    field: "repeatRules",
                    isValid: false,
                    message: i18next.t("calendar.events.create.repeatRules.required")
                })
            }
        }
        
        //?NOTE: Disabling the recipient validation, as they are not required for an event to be created.
        // if (this.channelIds.length === 0 && this.personIds.length === 0) {
        //     errors.push({
        //         field: "channelIds",
        //         isValid: false,
        //         message: i18next.t("calendar.events.create.channels.required")
        //     })
        // }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostCreateEventRequest