import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import homePlaceholder from '../images/Landing.svg'

export default function ComingSoon() {
    
    return (
        <div className='coming-soon vertical-align' style={{backgroundImage: `url(${homePlaceholder})`}}>
            <h1 className="page-caption center-align primary-text">
                <Trans i18nKey='landing.welcome' />
            </h1>
        </div>
    )
}
