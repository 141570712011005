import ApiService from "./ApiService"
import {
  CustomRequest,
  GenericListRequest,
} from "../api/requestObjects"

class ResourceApiService extends ApiService {

  /**
   * Fetch list of resources
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getResources = (params) => {

    const { GET_RESOURCE_CATEGORIES } = this.endpoints
    const request = new GenericListRequest(params)

    return this.API.get(this.getEndpoint({
      path: GET_RESOURCE_CATEGORIES,
      params: request.getPayload()
    }))
  }

  /**
   * Delete resource
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postDeleteResource = (params) => {
    
    const { DELETE_RESOURCE_CATEGORY } = this.endpoints
    
    return this.API.delete(this.getEndpoint({
      path: DELETE_RESOURCE_CATEGORY,
      data: params.categoryId
    }))
  }

  /**
   * Update resource
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postUpdateResource = (params) => {
    
    const { PUT_UPDATE_RESOURCE_CATEGORY } = this.endpoints
    
    const request = new CustomRequest({
      community_id: params.communityId,
      name: params.name
    })

    return this.API.put(this.getEndpoint({
      path: PUT_UPDATE_RESOURCE_CATEGORY,
      data: params.categoryId
    }), request.getPayload())
  }

  /**
   * Create resource
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postCreateResource = (params) => {
    
    const { POST_CREATE_RESOURCE_CATEGORY } = this.endpoints
    
    const request = new CustomRequest({
      community_id: params.communityId,
      name: params.name
    })

    return this.API.post(this.getEndpoint({
      path: POST_CREATE_RESOURCE_CATEGORY
    }), request.getPayload())
  }
}

export default ResourceApiService