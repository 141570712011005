import React from 'react'

export const AppPreview = ({content, phoneClass = '', contentClass = ''}) => {
    return (
        <div className={`smartphone ${phoneClass}`}>
            <div className={`s-content ${contentClass}`}>
                {content}
            </div>
        </div>
    )
}
