import { identity, pickBy } from "lodash"
import { filterNulls } from "../../utils/helper"

class CustomRequest {

    constructor(params) {
        this.params = params
    }

    getPayload = () => {
        return filterNulls(this.params)
    }
}

export default CustomRequest