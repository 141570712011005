import React, { useState, useEffect } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid, Dropdown } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import { useTranslation } from 'react-i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'

export default function WebFrameMorphed({item}) {
    
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const [heights] = useState([
        {
            key: 0,
            value: 0,
            text: t('messageBuilder.morphedTemplates.WebFrameMorphed.quarterScreen')
        },
        {
            key: 1,
            value: 1,
            text: t('messageBuilder.morphedTemplates.WebFrameMorphed.halfScreen')
        },
        {
            key: 2,
            value: 2,
            text: t('messageBuilder.morphedTemplates.WebFrameMorphed.fullScreen')
        },
    ])

    const [height, setHeight] = useState(item.data ? item.data.height : 0)

    const [data, setData] = useState({
        url: item.data ? item.data.url : "",
        height: item.data ? item.data.height : 0,
        originalId: item?.data?.originalId
    })

    const handleChange = (e, d) => {
        
        onDataChange({
            item, 
            data: {
                ...data, 
                height: d.value
            }
        })

        setHeight(d.value)
    }
    
    useEffect(() => {

        dispatch(builderActions.saveTemplateData({item, data}))
    }, [data, dispatch])

    const handleChangeUrl = (e, d) => {
        setData({
            ...data, 
            url: d.value
        })

        onDataChange({
            item, 
            data: {
                ...data, 
                url: d.value
            }
        })
    }

    // Dispatch Action to save this template's data
    const onDataChange = (d) => dispatch(builderActions.saveTemplateData(d))

    return (
        <BaseMorphed item={item} 
        render={({errors}) => (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='label'>URL</label>
                            <Input 
                                value={data.url} 
                                onChange={handleChangeUrl} 
                                placeholder="https://www.example.com"
                                autoComplete="off"  />
                        </div>
                        <ErrorLabel error={getError("url", errors)} />
                    </Grid.Column>
                </Grid.Row>
                {/* <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='label vertical-align'>
                                {t('messageBuilder.morphedTemplates.WebFrameMorphed.label.height')}
                            </label>
                            <Dropdown 
                                placeholder={t('messageBuilder.morphedTemplates.WebFrameMorphed.label.height')}
                                // search 
                                selection
                                onChange={handleChange}
                                value={height} 
                                options={heights} />
                        </div>
                    </Grid.Column>
                </Grid.Row> */}
            </Grid>
        )} />
    )
}
