import React, { useState, useEffect, Fragment } from 'react'

import Switch from "react-switch"
import BaseMorphed from './BaseMorphed'
import { Grid, Dropdown, Checkbox, Popup, Label, Accordion, Icon, Input, Form, Button } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import { votingTypes } from '../../../constants'

import { clone, filter, find, isNil, map, mapKeys, omit } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError, publish } from '../../../utils/helper'
import { FaCrown } from 'react-icons/fa'
import i18next from 'i18next'
import { tieringActions } from '../../../actions/tieringActions'
import OptionBuilder from './voteTypes/OptionBuilder'
import Role from '../../partials/Role'
import classNames from 'classnames'
import RichTextEditor from './RichTextEditor'
import { FormComponentSelectionLimits } from './formTypes/MultipleChoiceQuestion'

// Depending on the type of form, we need to render the relevant form type
function DynamicVote({item, data, handleInputChange, t}) {
    switch (data.voteType) {
        case votingTypes.standard.post_name:
        case votingTypes.zaSGB.post_name:
            return <OptionBuilder 
                data={data}
                item={item}
                t={t}
                handleInputChange={handleInputChange}/>
        default:
            return <span>Unknown vote item</span>;
    }
}

export default function VoteMorphed({item}) {
    
    //? @NOTE: This is only being done because we the SGB voting cycle for South Africa may be different to some of our International clients, 
    //? so we do not need to show this until the next cycle.
    // When we are working on an existing vote template, we do not need to filter the vote types
    const filteredVoteTypes = !isNil(item?.data?.originalId) ? votingTypes : filter(votingTypes, t => t.visible)
    const [types] = useState(map(filteredVoteTypes, t => {
        
        const tiering = item?.tiering[t.post_name] ?? {}

        return {
            key: t.id, 
            text: t.name,
            value: t.post_name,
            _meta: {
                component: t.name,
                upgrade_required: tiering.upgrade_required ?? false
            },
            label: tiering.upgrade_required ? (
                <Popup 
                    basic
                    trigger={
                        <FaCrown className='crown-icon t-upgrade-required in-dropdown' />
                    }
                    content={i18next.t('general.plan.upgrade')} />
            ) : null
        }
    }))

    const [isCollapsed, setCollapsed] = useState(true)
    const [visibilityOptions] = useState([
        {
            key: 'immediately', 
            text: i18next.t('components.vote.results.immediately'),
            value: 'immediately',
        },
        {
            key: 'after_deadline', 
            text: i18next.t('components.vote.results.after_deadline'),
            value: 'after_deadline',
        },
        {
            key: 'invisible', 
            text: i18next.t('components.vote.results.notVisible'),
            value: 'invisible',
        },
    ])
    
    const [adminVisibilityOptions] = useState([
        {
            key: 'all_system_users', 
            text: i18next.t('components.vote.results.all_system_users'),
            value: 'open',
        },
        {
            key: 'only_me', 
            text: i18next.t('components.vote.results.only_me'),
            value: 'author_only',
        },
        // {
        //     key: 'system_users', 
        //     text: i18next.t('components.vote.results.system_users'),
        //     value: 'system_users',
        // },
    ])

    const defaultContentState = {
        "blocks": [
            {
                "key": "637gr",
                "text": "",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
            }
        ],
        "entityMap": {}
    }

    const [data, setData] = useState({
        question: item?.data?.question ?? "",
        isRequired: item?.data?.isRequired ?? false,
        voteType: item.data ? item.data.voteType : votingTypes.standard.post_name,
        numAssignedVotesPerson: item?.data?.numAssignedVotesPerson ?? 1,
        minRequiredVotesPerPerson: item?.data?.minRequiredVotesPerPerson ?? 1,
        resultAt: item.data ? item.data.resultAt : visibilityOptions[0].value,
        visibleFor: item.data ? item.data.visibleFor : adminVisibilityOptions[0].value,
        options: item.data ? item.data.options : [],
        shouldBeAnonymous: item.data ? item.data.shouldBeAnonymous : false,
        canChangeResults: item.data ? item.data.canChangeResults : true,
        shouldAcceptConfirmationDeclaration: item.data ? item.data.shouldAcceptConfirmationDeclaration : false,
        declarationSnippet: item.data ? item.data.declarationSnippet : null,
        quorumPercentage: item.data ? item.data.quorumPercentage : 0,
        originalId: item?.data?.originalId,
        content: item?.data?.content ?? null,
        jsonContent: item?.data?.jsonContent ?? null
    })

    const dispatch = useDispatch()
    const {t} = useTranslation()

    const handleChangeType = (e, {value}) => {

        if (find(types, {value: value})?._meta?.upgrade_required) {
            dispatch(tieringActions.modalMini({
                show: true,
                header: 'general.plan.upgrade',
                component: find(types, {value: data.voteType})?._meta?.component,
                message: 'tiering.message.builder.components.message.upgradeRequired',
            }))
            return;
        }

        // set the defaults depending on the chose voteType
        if (value === votingTypes.standard.post_name) {
            setData(prev => ({
                ...prev,
                voteType: value,
                numAssignedVotesPerson: 1,
                minRequiredVotesPerPerson: 1,
                resultAt: visibilityOptions[0].value,
                visibleFor: adminVisibilityOptions[0].value,
                shouldBeAnonymous: false,
                canChangeResults: true,
                shouldAcceptConfirmationDeclaration: false,
                quorumPercentage: 0,
            }))
            return
        }
        
        if (value === votingTypes.zaSGB.post_name) {
            setData(prev => ({
                ...prev,
                voteType: value,
                numAssignedVotesPerson: 1,
                minRequiredVotesPerPerson: 1,
                resultAt: visibilityOptions[2].value,
                visibleFor: adminVisibilityOptions[1].value,
                shouldBeAnonymous: true,
                canChangeResults: false,
                shouldAcceptConfirmationDeclaration: true,
                quorumPercentage: 15,
            }))
        }
    }
    
    const handleRequireChange = (e, d) => {
        setData(prev => ({...prev, isRequired: !data.isRequired}))
    }
    
    const handleInputChange = ({name, value}) => {
        setData(prev => ({ ...prev, [name]: value }))
    }

    const handleOptionChange = ({ name, value }) => {
		setData(prev => ({ ...prev, [name]: value }))
	}
    
    const handleChangeDeclarationSnippet = (markdown, jsonContent) => {
        setData(prev => ({
            ...prev, 
            content: markdown, 
            jsonContent: jsonContent,
            declarationSnippet: markdown, 
        }))
	}
    
    useEffect(() => {
        
        if (data.resultAt !== 'after_deadline') {
            return
        }
        
        // We need to fire an event that should alert the builder to show the deadline modal
        publish('onSetDeadline', {
            source: data.originalId,
            showModal: true,
        })
    }, [data.resultAt])
    
    useEffect(() => {

        const storeData = map(data.options, (option) => {
            const _files = map(option.files, (file) => {
                return omit(file, ['file.blob'])
            })
            return {
                ...option,
                files: mapKeys(_files, i => i.id)
            }
        })

        dispatch(builderActions.saveTemplateData({item, data: {
            ...data,
            options: storeData,
        }}))
    }, [data, dispatch])

    return (
        <BaseMorphed item={item}
            // required={() => (
            //     <div className='vertical-align'>
            //         <span className='caption mg-right-15'>{t('general.required')}</span>
            //         <Checkbox
            //             checked={data.isRequired}
            //             onChange={handleRequireChange}/>
            //     </div>
            // )}
            render={({errors}) => (
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <label className='label'>{t("components.vote")}</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-float-right">
                            <Dropdown 
                                placeholder={t("general.voteType")}
                                selection
                                onChange={handleChangeType}
                                value={data.voteType}
                                options={types} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='equal'>
                        <Grid.Column className="no-padding mg-bot-10">
                            <Accordion>
                                <Accordion.Title
                                    className='vertical-align primary-color-text'
                                    active={isCollapsed}
                                    onClick={() => setCollapsed(prev => !prev)}>
                                    {t("components.vote.settings.label")}
                                    <Icon name='dropdown' />
                                </Accordion.Title>
                                <Accordion.Content active={isCollapsed}>
                                    <Grid className='ui form'>
                                        <Grid.Row columns='equal'>
                                            <Grid.Column>
                                                <Role
                                                    role={{
                                                        id: "resultAt",
                                                        name: t("components.vote.results.visible-when"),
                                                    }}
                                                    search={false}
                                                    multiple={false}
                                                    placeholder={t("components.vote.results.visible-when")}
                                                    selected={data.resultAt}
                                                    onChange={handleOptionChange}
                                                    options={visibilityOptions} />

                                                <div className='input-container theme-color mt-3'>
                                                    <Form.Field required className='no-margin'>
                                                        <label className={classNames("label no-margin", !getError("numVotesRequired", errors).isValid && 'error')} htmlFor="id-number">
                                                            {t('components.vote.input.votes-per-person-for-times.placeholder')}
                                                        </label>
                                                    </Form.Field>
                                                    {/* <FormComponentSelectionLimits
                                                        data={data}
                                                        className={'w-full important'}
                                                        minField='minRequiredVotesPerPerson'
                                                        maxField='numAssignedVotesPerson'
                                                        numItems={data?.options?.length}
                                                        handleInputChange={handleInputChange}
                                                        maxStopValue={0} /> */}
                                                    <div>
                                                        <span className='mr-2'>{t('components.vote.input.min-votes.placeholder')}</span>
                                                        <Input
                                                            type='number'
                                                            width={1}
                                                            min={0}
                                                            placeholder="0"
                                                            id="minRequiredVotesPerPerson"
                                                            name="minRequiredVotesPerPerson"
                                                            value={data.minRequiredVotesPerPerson}
                                                            onChange={(e, {value}) => handleInputChange({
                                                                name: 'minRequiredVotesPerPerson',
                                                                value,
                                                            })}
                                                            size='mini'
                                                            className='mini-input'
                                                            autoComplete="off"
                                                        />
                                                        <span className='ml-2 mr-2 lowercase'>{t('components.vote.input.up-to.placeholder')}</span>
                                                        <Input
                                                            type='number'
                                                            width={1}
                                                            min={0}
                                                            placeholder="0"
                                                            id="numAssignedVotesPerson"
                                                            name="numAssignedVotesPerson"
                                                            value={data.numAssignedVotesPerson}
                                                            onChange={(e, {value}) => handleInputChange({
                                                                name: 'numAssignedVotesPerson',
                                                                value,
                                                            })}
                                                            size='mini'
                                                            className='mini-input lowercase'
                                                            autoComplete="off"
                                                        />
                                                        <span className='ml-2'>{t('components.vote.input.num-total-votes-times.placeholder')}</span>
                                                    </div>
                                                    <ErrorLabel error={getError("minRequiredVotesPerPerson", errors)} />
                                                </div>
                                                <div className='field input-container theme-color mt-3'>
                                                    <label className="label" htmlFor="id-number">
                                                        {t('components.vote.input.quorumPercentage.placeholder')}
                                                    </label>
                                                    <Input
                                                        min={0}
                                                        max={100}
                                                        type='number'
                                                        placeholder={t('components.vote.input.quorumPercentage.placeholder')}
                                                        id="quorumPercentage"
                                                        name="quorumPercentage"
                                                        value={data.quorumPercentage}
                                                        onChange={(e, {value}) => handleInputChange({
                                                            name: 'quorumPercentage',
                                                            value,
                                                        })}
                                                        autoComplete="off"
                                                    />
                                                    <ErrorLabel noMargin error={getError("quorumPercentage", errors)} />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Role
                                                    role={{
                                                        id: "visibleFor",
                                                        name: t("components.vote.results.visible-for"),
                                                    }}
                                                    search={false}
                                                    multiple={false}
                                                    placeholder={t("components.vote.results.visible-for")}
                                                    selected={data.visibleFor}
                                                    onChange={handleOptionChange}
                                                    options={adminVisibilityOptions} />
                                                
                                                <div className='field mt-3'>
                                                    <Checkbox
                                                        checked={data.canChangeResults}
                                                        onChange={() => setData(prev => ({...prev, canChangeResults: !prev.canChangeResults}))}
                                                        label={t('components.vote.input.canChangeResults.label')} />
                                                </div>
                                                <div className='field mt-3'>
                                                    <Checkbox
                                                        checked={data.shouldBeAnonymous}
                                                        onChange={() => setData(prev => ({...prev, shouldBeAnonymous: !prev.shouldBeAnonymous}))}
                                                        label={t('components.vote.input.shouldBeAnonymous.label')} />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <div>
                                                    <Checkbox
                                                        checked={data.shouldAcceptConfirmationDeclaration}
                                                        onChange={() => setData(prev => ({...prev, shouldAcceptConfirmationDeclaration: !prev.shouldAcceptConfirmationDeclaration}))}
                                                        label={t('components.vote.input.shouldAcceptConfirmationDeclaration.label')} />
                                                    
                                                    {data.shouldAcceptConfirmationDeclaration && (
                                                        <div className='mt-3'>
                                                            <RichTextEditor
                                                                content={data.content}
                                                                jsonContent={data.jsonContent}
                                                                placeholder={t('components.vote.input.declarationSnippet.label')}
                                                                onChange={handleChangeDeclarationSnippet} />
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Accordion.Content>
                            </Accordion>
                        </Grid.Column>
                    </Grid.Row>
                    <DynamicVote 
                        item={item} 
                        data={data}
                        t={t}
                        handleInputChange={handleOptionChange}/>
                    <Grid.Row className='no-padding mb-5'>
                        <Grid.Column>
                            <ErrorLabel error={getError("title", errors)} />
                            <ErrorLabel error={getError("options", errors)} />
                            <ErrorLabel error={getError("deadline", errors)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )} />
    )
}
