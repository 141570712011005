import { identity, isEmpty, pickBy } from "lodash"
import GenericListRequest from "./GenericListRequest"

class ListContactsRequest extends GenericListRequest {

    // Override incase we need Contact specific filters
    constructor(params) {
        super(params)
    }
}

export default ListContactsRequest