import React from 'react'
import { Icon, List } from 'semantic-ui-react'
import { transformYouTubeVideoUrl } from '../../../../utils/helper'
import BaseTemplate from './BaseTemplate'

import { Fragment } from 'react'

const Video = ({ item }) => {
    
    const youTubeUrl = transformYouTubeVideoUrl(item.data.url || "")

    return (
        <BaseTemplate classNames={youTubeUrl ? "bg-transparent" : ""} render={() => (
            <Fragment>
                {item.data.url ? (
                    <List.Content className="video-container">
                        <iframe 
                            width="100%" 
                            height="100%" 
                            src={youTubeUrl}
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen
                            title="Title" />
                    </List.Content>
                ) : (
                    <List.Content className="youtube-placeholder">
                        <Icon name="youtube play" />
                    </List.Content>
                )}
            </Fragment>
        )} />
    )
}

export default Video
