import { identity, isString, map, pickBy } from "lodash"
import { filterNulls } from "../../utils/helper"
import GetPublishBreakdownRequest from "./GetPublishBreakdownRequest"

class PostPublishBreakdownRequest extends GetPublishBreakdownRequest {
    
    getPayload = () => {
        
        let payload = {
            "strategy": this.strategy,
            "relationship": this.relationship,
            "platforms": this.getSelectedPlatform(),
        }

        if (this.scheduledPublishAt) {
            payload['scheduled_publish_at'] = this.scheduledPublishAt
        }
        
        // Fake sleep Period to test slow notice publishing [60 seconds is our test]
        // payload['artificial_sleep_seconds'] = 60

        // When App is un-checked we should also remove the "pin_notice" and "send_push_notification"
        if (payload.platforms.includes("app")) {
            payload = {
                ...payload,
                "pin_notice": this.pinItem,
                "send_push_notification": this.sendPush,
            }
        }
        
        // When the users unticks the app, we should soft target to app  and "send_push_notification"
        payload['soft_target_platform_app'] = !payload.platforms.includes("app")
        if (payload['soft_target_platform_app']) {
            payload['send_push_notification'] = this.sendPush
        }

        return filterNulls(payload)
    }

}

export default PostPublishBreakdownRequest