import React from 'react'

import { templateIcons } from '../../constants'
import { Icon } from 'semantic-ui-react'

const TaskIcon = ({task}) => {

    switch (task.type) {
      case templateIcons.notice: {
        return <Icon name="mail"/>
      }
      case templateIcons.event: {
        return <Icon name="calendar alternate"/>
      }
      case templateIcons.file: {
        return <Icon name="file"/>
      }
      case templateIcons.payment: {
        return <Icon name="dollar sign"/>
      }
      case templateIcons.text: {
        return <Icon name="quote right"/>
      }
      case templateIcons.attachment: {
        return <Icon name="attach"/>
      }
      case templateIcons.video: {
        return <Icon name="file video"/>
      }
      case templateIcons.image: {
        return <Icon name="image"/>
      }
      case templateIcons.location: {
        return <Icon name="map marker alternate"/>
      }
      case templateIcons.deadline: {
        return <Icon name="stopwatch"/>
      }
      case templateIcons.form: {
        return <Icon name="wpforms"/>
      }
      case templateIcons.iframe: {
        return <Icon name="globe"/>
      }
      case templateIcons.contact: {
        return <Icon name="call square"/>
      }
      case templateIcons.progressBar: {
        return <Icon name="chart line"/>
      }
      case templateIcons.customTemplate: {
        
        if (!task.icon_name) {
          return null
        }

        return <Icon name={task.icon_name} />
      }
      case templateIcons.date: {
        return <Icon name="calendar check outline" />
      }
      case templateIcons.vimeo: {
        return <Icon name="vimeo" />
      }
      case templateIcons.youTube: {
        return <Icon name="youtube" />
      }
      case templateIcons.vote: {
        return <Icon name="pie chart" />
      }
      case templateIcons.url: {
        return <Icon name="linkify" />
      }
      default: {
        return <Icon name="mail"/>
      }
    }
}

export default TaskIcon