import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const  propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    header: PropTypes.string,
    cancelText: PropTypes.string,
    loading: PropTypes.bool,
}

const  defaultProps = {
    open: false,
    loading: false,
    onClose: () => {},
    header: "Header",
    message: "Message",
    cancelText: "Cancel",
}

const PreviewModal = (props) => {
    
    const {
        open, 
        onClose,
        header,
        message, 
        loading, 
        cancelText,
    } = props

    const { t } = useTranslation()

    return (
        <Modal
            size="large"
            open={ open }
            onClose={ onClose }
            >
        <Modal.Header className="primary-color white-text">{ header }</Modal.Header>
        <Modal.Content>
            <Dimmer active={loading} inverted>
                <Loader inverted content={t("general.loading")} />
            </Dimmer>
            { message }
        </Modal.Content>
        <Modal.Actions className="no-border">
            <Button className="secondary" onClick={ onClose }>
              { cancelText }
            </Button>
        </Modal.Actions>
      </Modal>
    )
}

PreviewModal.propTypes = propTypes
PreviewModal.defaultProps = defaultProps

export default PreviewModal