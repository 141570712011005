import React, { Fragment, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Input, Grid, Button, Menu, Icon, Dimmer, Loader, Popup, Dropdown } from 'semantic-ui-react'
import { Template } from '../message-builder/templates'
import { Droppable } from 'react-beautiful-dnd'
import TaskIcon from '../message-builder/TaskIcon'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../actions/builderActions'
import { filter, size, take } from 'lodash'
import ConfirmModal from './ConfirmModal'

const BuilderTopNavigation = ({ collapsed, toggled, mainCollapse, handleCollapsedChange }) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const builder = useSelector(state => state.builder)
  const navigation = useSelector(state => state.navigation)

  const [searchTerm, setSearchTerm] = useState("")
  const [canSearch, setCanSearch] = useState(false)
  const [isShowMini, setShowMini] = useState(true)

  const [confirm, setConfirm] = useState({
    showModal: false,
    onConfirm: () => { },
    onClose: () => { },
    message: "",
    header: "",
    confirmText: "",
    cancelText: "",
  })

  // Cleanup
  useEffect(() => {
    return () => {
      dispatch(builderActions.clearMessages())
    }
  }, [])

  // Refresh templates upon builder refreshData flag
  useEffect(() => {

    if (!builder.messages) return;

    if (builder.messages.refreshData) {
      dispatch(builderActions.startTemplates())
      dispatch(builderActions.fetchTemplates({
        communityId: navigation.communityId
      }))
    }

  }, [builder.messages])

  const handleDeleteTemplate = (task) => {
    handleCloseConfirm()
    dispatch(builderActions.startTemplates())
    dispatch(builderActions.postDeleteTemplate({
      templateId: task.id
    }))
  }

  const confirmDeleteTemplate = (e, task) => {
    e.stopPropagation()

    setConfirm({
      showModal: true,
      onConfirm: () => handleDeleteTemplate(task),
      onClose: () => handleCloseConfirm(),
      message: (
        <Trans
          i18nKey="modal.builder.sidenavigation.message"
          components={{
            strong: <strong />
          }}
          values={{
            name: task.name
          }}
        />
      ),
      header: t('modal.builder.sidenavigation.header'),
      cancelText: t('modal.builder.sidenavigation.cancelText'),
      confirmText: t('modal.builder.sidenavigation.confirmText'),
    })
  }

  const handleCloseConfirm = () => setConfirm({})

  const handleClickDrop = ({ item, index }) => {

    dispatch(builderActions.composeComponentDynamic({
      id: item.id,
      index: index,
    }))
  }

  return (
    <Grid
      verticalAlign='middle'
      className={`builder-top-navigation ${!mainCollapse ? 'sn-opened' : 'sn-collapsed'} ${toggled ? 'toggled' : ''}`}>
      <ConfirmModal
        header={confirm.header}
        open={confirm.showModal}
        onClose={confirm.onClose}
        message={confirm.message}
        onConfirm={confirm.onConfirm}
        confirmText={confirm.confirmText} />

      <Grid.Row className='builder-tn-row' columns="equal">
        <Grid.Column>
          <Popup
            basic
            trigger={
              <span className="side-menu-col-name">
                <Icon name="configure" />{t("components.columns.component.title")}
              </span>
            }
            content={t("components.columns.component.description")}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={10}>

          {builder.templates.columnOrder.map((columnId) => {
            const column = builder.templates.columns[columnId]
            let oTasks = column.taskIds.map(taskId => builder.templates.tasks[taskId])

            let uTasks = []
            let cpTasks = []
            let cpTasksLastIndex = 0

            if (column.id === 'column-1') {
              cpTasks = isShowMini ? take(filter(oTasks, { isUserTemplate: false }), 5) : filter(oTasks, { isUserTemplate: false })
              uTasks = isShowMini ? take(filter(oTasks, { isUserTemplate: true }), 5) : filter(oTasks, { isUserTemplate: true })
            } else {
              cpTasks = isShowMini ? take(oTasks, 5) : oTasks
            }

            // Skip templates and content area columns
            if (['column-4', 'column-1'].includes(column.id)) {
              return <Fragment key={columnId} />
            }

            return (
              <Grid.Column
                width={16}
                key={columnId}>
                <Grid className={`templates ${column.id}`}>
                  <Droppable
                    isDropDisabled
                    droppableId={column.id}>
                    {(provided, snapshot) => (
                      <div
                        className={`row ${snapshot.isDraggingOver ? 'incoming-drop' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}>

                        {/* Only Show Community and Public templates */}
                        {cpTasks.map((task, index) => (
                          <Grid.Column
                            key={index}
                            index={index}
                            mobile={6}
                            tablet={4}
                            verticalAlign="middle">

                            <Template
                              item={task}
                              index={index}
                              onClick={handleClickDrop}
                              disabled={true} // We will disable all dragging by default
                              icon={<TaskIcon task={task} />} />
                          </Grid.Column>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Grid>
              </Grid.Column>
            )
          })}
        </Grid.Column>
        <Grid.Column>
          <Button size='tiny' className='tertiary'
            onClick={
              () => setShowMini(!isShowMini)} >
            {isShowMini ? (
              <b>{t('messageBuilder.components.more')}&nbsp;<Icon name='chevron up' /></b>
            ) : (
              <b>{t('messageBuilder.components.less')}&nbsp;<Icon name='chevron down' /></b>
            )}
          </Button>
        </Grid.Column>
        <Grid.Column className='no-padding'>
          {builder.templates.columnOrder.map((columnId) => {
            const column = builder.templates.columns[columnId]
            let oTasks = column.taskIds.map(taskId => builder.templates.tasks[taskId])

            // Skip components and content area columns
            if (['column-4', 'column-2'].includes(column.id)) {
              return <Fragment key={columnId} />
            }

            return (
              <Dropdown
                key={columnId} 
                floating 
                item
                icon={
                  <Button size='tiny' className='tertiary'>
                    <Icon name="save" />{t("components.columns.template.title")}<Icon name="dropdown" />
                  </Button>
                }>
                <Dropdown.Menu>
                  <Droppable
                    droppableId={column.id}>
                    {(provided, snapshot) => (
                      <div
                        className={`row ${snapshot.isDraggingOver ? 'incoming-drop' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}>

                        {oTasks.map((task, index) => (
                          <Dropdown.Item key={index} index={index}>
                            <Grid.Column className='w-100 no-margin'>
                              <Template
                                item={task}
                                index={index}
                                onClick={handleClickDrop}
                                disabled={true}
                                icon={<TaskIcon task={task} />}
                                className={task.isUserTemplate ? 'user-template': ''}
                                render={() => 
                                  task.isUserTemplate ? 
                                    <Icon 
                                      onClick={(e) => confirmDeleteTemplate(e, task)} 
                                      className="trash delete-icon no-margin" /> : 
                                    <Fragment />
                                } />
                            </Grid.Column>
                          </Dropdown.Item>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Dropdown.Menu>
              </Dropdown>
            )
          })}

        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default BuilderTopNavigation
