import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import { useState } from 'react'
import PhoneInput, { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input'
import { useSelector } from 'react-redux'
import { debounce, isEmpty } from 'lodash'

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    defaultCountry: PropTypes.string,
    editableCode: PropTypes.bool,
    disabled: PropTypes.bool,

    // Functions
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
}

const defaultProps = {
    title: i18next.t("general.input.phoneNumber"),
    name: "phoneNumber",
    defaultValue: "",
    defaultCountry: "ZA",
    defaultCountryCallingCode: 27,
    disabled: false,
    editableCode: false,
    placeholder: i18next.t("general.input.phoneNumber"),

    onChange: (value) => {},
    onBlur: (value) => {},
    onKeyUp: (value) => {}
}

const PhoneNumberInput = props => {
    
    const {
        name,
        title,
        onChange,
        onBlur,
        onKeyUp,
        disabled,
        placeholder,
        editableCode,
        defaultValue,
        defaultCountry,
        defaultCountryCallingCode
    } = props

    const [state, setState] = useState(defaultValue)
    const [stateDefaultCountry, setDefaultCountry] = useState(defaultCountry ?? 'ZA')
    const navigation = useSelector(state => state.navigation)

    const handleChange = value => {
        const newInput = formatPhoneNumberIntl(value).replaceAll(" ", "")

        setState(newInput)

        if (onChange) {
            onChange(newInput)
        }
    }

    const _determineDefaultCallingCode = () => {

        const defaultCallingCode = navigation?.community?.default_country_calling_code ?? defaultCountryCallingCode
        //? NOTE: This is not any special or magic phone number, is a randomly picked phone number that enables us to 
        //? leverage 'libphonenumber-js' to get the country from the number and calling code
        const phoneNumber = parsePhoneNumber(`+${defaultCallingCode}7929019289`) 
        
        if (!isEmpty(phoneNumber?.country)) {
            return phoneNumber.country
        }

        return defaultCountry
    }

    useEffect(() => {
        const country = _determineDefaultCallingCode()
        setDefaultCountry(country)

    }, [defaultCountry])
    
    useEffect(() => {
        setState(defaultValue)
    }, [defaultValue])

    return (
        <div className="input-container theme-color">
            {title && <label className='label'>{title}</label>}
            <PhoneInput 
                name={name}
                value={state}
                international
                className="ui input"
                disabled={disabled}
                onBlur={onBlur}
                onKeyUp={onKeyUp}
                onChange={handleChange}
                placeholder={placeholder}
                countryCallingCodeEditable={editableCode}
                defaultCountry={stateDefaultCountry} />
        </div>
    )
}

PhoneNumberInput.propTypes = propTypes
PhoneNumberInput.defaultProps = defaultProps

export default PhoneNumberInput
