import React from 'react'
import { actions, constants } from '../constants'
import { isEmpty, map, pick, random } from 'lodash'
import Bugsnag from '@bugsnag/js'
import moment from 'moment'

const { 
    AUTH_START,
    AUTH_END_LOADING,
    AUTH_ERROR,
    AUTH_ERROR_UPDATE,
    AUTH_SAVE_PROFILE,
    AUTH_SAVE_SSO_USER,  
    AUTH_FINISH_INIT,
    AUTH_SAVE_RETURN_URL,
    AUTH_CLEAR_MESSAGES,
    AUTH_SUCCESS,
    AUTH_SAVE_CONTACTS,
    AUTH_SAVE_ACCOUNT,
    AUTH_SAVE_BILLING,
    AUTH_CLEAR_SETTINGS
} = actions

const initialState = {
    loading: true,
    initialising: true,
    ssoUser: {},
    profile: null,
    errors: null,
    updateErrors: null,
    messages: null,
    settings: {},
    pagination: {
        totalPages: 0,
        currentPage: 1,
    }
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case AUTH_START: {
            return {...state, loading: true}
        }
        case AUTH_END_LOADING: {
            return {...state, loading: false}
        }
        case AUTH_CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null, updateErrors: null}
        }
        case AUTH_SUCCESS: {
            return {...state, loading: false, errors: null, updateErrors: null, messages: action.payload}
        }
        case AUTH_CLEAR_SETTINGS: {
            return {
                ...state, 
                settings: {},
            }
        }
        case AUTH_SAVE_CONTACTS: {
            return {
                ...state, 
                loading: false, 
                settings: {
                    ...state.settings,
                    contact: {
                        ...action.payload,
                        topicsTransformed: map(action.payload.topics, c => {
                            return {
                                ...c,
                                actions: [
                                    constants.MENU_ACTION_VIEW,
                                    constants.MENU_ACTION_DELETE,
                                ]
                            }
                        }),
                        contactsTransformed: map(action.payload.contacts, c => {
                            return {
                                ...c,
                                actions: [
                                    constants.MENU_ACTION_VIEW,
                                    constants.MENU_ACTION_DELETE,
                                ]
                            }
                        })
                    }
                }, 
            }
        }
        case AUTH_SAVE_ACCOUNT: {
            return {
                ...state, 
                loading: false, 
                settings: {
                    ...state.settings,
                    account: action.payload
                }, 
            }
        }
        case AUTH_SAVE_BILLING: {
            return {
                ...state, 
                loading: false, 
                settings: {
                    ...state.settings,
                    billing: action.payload
                }, 
            }
        }
        case AUTH_FINISH_INIT: {
            return {...state, initialising: false}
        }
        case AUTH_SAVE_RETURN_URL: {
            // return {...state, returnUrl: action.payload}
            localStorage.setItem(AUTH_SAVE_RETURN_URL, action.payload)
            return {...state}
        }
        case AUTH_ERROR: {
            let error = action.payload.response || {}
            
            // to show error with headers
            if (error.data && error.data.header) {
                error = error.data
            } else {
                error = error.data ? error.data.message : "error.errorOccurred"
            }

            return {
                ...state, 
                loading: false, 
                errors: error
            }
        }
        case AUTH_ERROR_UPDATE: {
            let error = action.payload.response || {}
            error = error.data ? error.data.message : "error.errorOccurred"
            
            return {
                ...state, 
                loading: false, 
                updateErrors: error
            }
        }
        case AUTH_SAVE_SSO_USER: {
            return {
                ...state, 
                loading: false, 
                // initialising: false,
                errors: null,
                ssoUser: action.payload,
            }
        }
        case AUTH_SAVE_PROFILE: {

            // Set Bugsnag user
            const user = {
                id: action.payload.id,
                email: action.payload.email,
                name: `${action.payload.first_name} ${action.payload.last_name}`
            }

            Bugsnag.setUser(user.id, user.email, user.name)
            
            const selectedCommunity = pick(action.payload.current_community, ['id', 'name', 'image'])

            const _communities = map(action.payload.other_communities, c => {
                if (c.id === selectedCommunity.id) {
                    return {
                        order: 1,
                        ...selectedCommunity
                    }
                }
                return {
                    ...c,
                    order: 0
                }
            })

            return {
                ...state, 
                loading: false,
                initialising: false, 
                errors: null,
                profile: {
                    ...action.payload,
                    email: action.payload.email || "",
                    first_name: action.payload.first_name || "Anonymous",
                    last_name: action.payload.last_name || "",
                    display_name: action.payload.first_name || action.payload.last_name || action.payload.email || action.payload.phone_number,
                    decoratedCommunities: map(_communities, c => {
                        return {
                            key: c.id, 
                            value: c.id, 
                            text: c.name, 
                            image: {
                                avatar: true, 
                                src: c.image
                            }
                        }
                    }),
                    open_ai_feature_is_new: moment().isSameOrBefore(moment('2023-07-27T14:22:22+02:00')), // The "New" badge should fall away after 30 days
                },

            }
        }
        case "@@router/LOCATION_CHANGE": {
            return {...state, loading: false}
        }
        default: {}
    }

    return state
}