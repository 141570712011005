import React, { useState, useEffect, Fragment } from 'react'

import Switch from "react-switch"
import BaseMorphed from './BaseMorphed'
import { Grid, Dropdown, Checkbox, Popup, Label } from 'semantic-ui-react'
import { batch, useDispatch } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import { formTypes } from '../../../constants'
import { 
    ImageUploadQuestion, 
    TextFieldQuestion, 
    YesNoQuestion, 
    MultipleChoiceQuestion, 
    FileUploadQuestion
} from './formTypes'

import { find, map } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'
import { FaCrown } from 'react-icons/fa'
import i18next from 'i18next'
import { tieringActions } from '../../../actions/tieringActions'

// Depending on the type of form, we need to render the relevant form type
function DynamicForm({item, data, handleInputChange, t}) {
    switch (data.formType) {
        case formTypes.textField.post_name:
            return <TextFieldQuestion 
                data={data} 
                item={item}
                t={t} 
                handleInputChange={handleInputChange}/>
        case formTypes.yesNo.post_name:
            return <YesNoQuestion 
                data={data}
                item={item}
                t={t} 
                handleInputChange={handleInputChange}/>
        case formTypes.multipleChoice.post_name:
            return <MultipleChoiceQuestion
                data={data}
                item={item}
                t={t} 
                handleInputChange={handleInputChange}/>
        case formTypes.imageUpload.post_name:
            return <ImageUploadQuestion
                data={data}
                item={item}
                t={t}
                handleInputChange={handleInputChange}/>
        case formTypes.fileUpload.post_name:
            return <FileUploadQuestion
                data={data}
                item={item}
                t={t} 
                handleInputChange={handleInputChange}/>
    
        default:
            return <span>{i18next.t('requestObjects.PostCreateNoticeRequest.form.unknownFormType')}</span>;
    }
}

export default function FormMorphed({item}) {
    
    const [types] = useState(map(formTypes, t => {
        
        const tiering = item?.tiering[t.post_name] ?? {}

        return {
            key: t.id, 
            text: t.name,
            value: t.post_name,
            _meta: {
                component: t.name,
                upgrade_required: tiering.upgrade_required ?? false
            },
            label: tiering.upgrade_required ? (
                <Popup 
                    basic
                    trigger={
                        <FaCrown className='crown-icon t-upgrade-required in-dropdown' />
                    }
                    content={i18next.t('general.plan.upgrade')} />
            ) : null
        }
    }))

    const defaultSelected = types.find(t => t._meta?.upgrade_required === false)

    const [data, setData] = useState({
        question: item.data ? item.data.question : "",
        isRequired: item.data ? item.data.isRequired : false,
        formType: item.data ? item.data.formType : defaultSelected?.value,
        options: item.data ? item.data.options : [],
        singleSelect: item.data ? item.data.singleSelect : false,
        originalId: item?.data?.originalId,
        min: item?.data?.min ?? 0,
        max: item?.data?.max ?? 2
    })

    const dispatch = useDispatch()
    const {t} = useTranslation()

    const handleChange = (e, {value}) => {

        if (find(types, {value: value})?._meta?.upgrade_required) {
            dispatch(tieringActions.modalMini({
                show: true,
                header: 'general.plan.upgrade',
                component: find(types, {value: data.formType})?._meta?.component,
                message: 'tiering.message.builder.components.message.upgradeRequired',
            }))
            return;
        }

        setData({...data, formType: value})
    }
    
    const handleRequireChange = (e, d) => {
        setData({...data, isRequired: !data.isRequired})
    }
    
    const handleInputChange = (args) => {

        // Add ability to be able to update more than one item at a time
        if (args instanceof Array) {
            args.map(arg => {
                
                const {name, value} = arg
                
                setData((prev) => ({
                    ...prev,
                    [name]: value
                }))
            })
            return
        }

        const {name, value} = args

        setData({...data, [name]: value})
    }

    useEffect(() => {

        const payload = JSON.stringify({
            item, 
            data
        })
        dispatch(builderActions.saveTemplateData(JSON.parse(payload)))
        return () => {}
    }, [data, dispatch])

    return (
        <BaseMorphed item={item}
            required={() => (
                <div className='vertical-align'>
                    <span className='caption mg-right-15'>{t('general.required')}</span>
                    <Checkbox
                        checked={data.isRequired}
                        onChange={handleRequireChange}/>
                </div>
            )}
            render={({errors}) => (
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <label className='label'>{t("general.form")}</label>
                        </Grid.Column>
                        <Grid.Column className="dropdown-float-right">
                            <Dropdown 
                                placeholder={t("general.formType")}
                                selection
                                onChange={handleChange}
                                value={data.formType}
                                options={types} />
                        </Grid.Column>
                    </Grid.Row>
                    <DynamicForm 
                        item={item} 
                        data={data}
                        t={t}
                        handleInputChange={handleInputChange}/>
                    <Grid.Row className='no-padding mb-5'>
                        <Grid.Column>
                            <ErrorLabel error={getError("title", errors)} />
                            <ErrorLabel error={getError("options", errors)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )} />
    )
}
