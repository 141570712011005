import React, { Fragment } from 'react'
import { BaseTemplate } from '../../message-builder/preview/templates'
import { List } from 'semantic-ui-react'
import { map, mapValues } from 'lodash'
import moment from 'moment'
import i18next from "i18next"

const BaseItem = ({ item }) => {
	const substitutions = mapValues(item.dates, (value) => moment(value).format('L LT'))
	const label = i18next.t(item.string, substitutions)

	return (
		<div className="mg-bot-2">
			<p className="no-margin">{label}</p>
		</div>
	)
}

export default function ReadItem({ hoverItem }) {
	return (
		<BaseTemplate
			render={() => (
				<Fragment>
					<List.Content>
						{map(hoverItem.items, (item, index) => (
							<BaseItem item={item} key={`read-item-${index}`} />
						))}
					</List.Content>
				</Fragment>
			)}
		/>
	)
}
