import React, { useState, Fragment } from 'react'
import { Grid, Image, Input } from 'semantic-ui-react'
import srcPlaceholder from '../../../../../images/image_placeholder.svg'

export default function ImageUploadQuestionPreview({item}) {
    
    return (
        <Fragment>
            <Grid.Row>
                <Grid.Column>
                    <div className="input-container theme-color">
                        <label className='label mg-bot-10 black-text center-align'>
                            {item.data.question} {item.data.isRequired && <span style={{marginLeft: 5}} className="required-star secondary-color-text">*</span>}
                        </label>
                        <Image.Group className='mg-auto' size='tiny'>
                            <Image src={srcPlaceholder} />
                        </Image.Group>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Fragment>
    )
}
