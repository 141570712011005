import React, { useEffect, useState } from "react"
import { Dropdown, Form, Popup } from "semantic-ui-react"
import { getError } from "../../utils/helper"
import { FaCrown } from "react-icons/fa"
import i18next from "i18next"

const CustomDropdown = ({
    id,
    required, 
    options, 
    onChange,
    fieldName, 
    placeholder, 
    selected, 
    validationMessages, 
    disabled = false,
}) => {
    const [state, setState] = useState(selected)
    
    /**
     * 
     * @param {import("react").SyntheticEvent} event 
     * @param {import("semantic-ui-react").DropdownProps} data 
     * @returns 
     */
    const handleChange = (event, {value, name}) => {
        
        if (event?.currentTarget?.classList?.contains('special-actions')) {
            return;
        }

        onChange({name: name, value, e: event})
        setState(value)
    }

    useEffect(() => {
        setState(selected)
    }, [selected])

    return (
        <Form.Field 
            required={required}
            error={!getError(fieldName, validationMessages).isValid}
            className="input-container theme-color">
            <label className='label' htmlFor={id}>{placeholder}</label>
            <Dropdown
                className='theme-color'
                fluid
                placeholder={placeholder}
                selection
                id={id}
                name={fieldName}
                error={!getError(fieldName, validationMessages).isValid}
                onChange={handleChange}
                options={options}
                value={state} />
        </Form.Field>
    )
}

export const TieredDropdownItem = (props) => {
    
    const {
        template,
        originalProps,
    } = props

    if (template?.upgrade_required) {
        return (
            <Popup
                basic
                trigger={
                    <Dropdown.Item 
                        {...originalProps}
                        text={template.caption}
                        disabled={true}
                        onClick={null}
                        className="t-upgrade-required override"
                        label={<FaCrown className='crown-icon t-upgrade-required in-dropdown' />} />
                }
                content={i18next.t('general.plan.upgrade')} />
        )
    }
    
    return (
        <Dropdown.Item {...originalProps} />
    )
}
export default CustomDropdown