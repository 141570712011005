import React, { useState, useEffect } from 'react'

import BaseMorphed from './BaseMorphed'
import { Grid } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch } from 'react-redux'
import SearchLocationInput from '../../../utils/SearchLocationInput'
import i18next from 'i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'

export default function LocationMorphed({item}) {
    
    const [data, setData] = useState({
        location: item.data ? item.data.location : "",
        latitude: item.data ? item.data.latitude : "",
        longitude: item.data ? item.data.longitude : "",
        previewText: item.data ? item.data.location : "",
        originalPlace: item.data ? item.data.originalPlace : null,
        originalId: item?.data?.originalId
    })

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({item, data}))
        return () => {}
    }, [data, dispatch])

    const handleLocationChange = (placeDetails) => {
        
        const { address, location, original } = placeDetails

        setData((prevData) => ({
            ...prevData,
            location: address,
            previewText: address,
            latitude: location.lat,
            longitude: location.lng,
            originalPlace: {}
            // originalPlace: original
        }))
    }

    return (
        <BaseMorphed item={item} render={({errors}) => (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <SearchLocationInput 
                            placeholder={i18next.t('messageBuilder.morphedTemplates.EventMorphed.searchLocation')}
                            name="location"
                            title={i18next.t('messageBuilder.morphedTemplates.LocationMorphed.location')}
                            defaultValue={data.location}
                            onChange={handleLocationChange} />
                        <ErrorLabel error={getError("address", errors)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )} />
    )
}
