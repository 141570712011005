import i18next from "i18next"
import { pickBy, identity, mapKeys, size, filter } from "lodash"
import { messageBuilder } from '../../constants'
/**
 * 
 * @typedef Request
 * @property {String} name
 * @property {Array} channelIds
 * @property {String} communityId
 * @property {String} syncAccountId
 * @property {Boolean} targetEveryone
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostCreateCalendarRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        name,
        sourceId,
        channelIds,
        communityId,
        syncAccountId,
        targetEveryone,
        syncAutomatically,
        syncEventChannels,
        sendReminderDayBefore,
    }) {
        this.name = name
        this.sourceId = sourceId
        this.channelIds = channelIds
        this.communityId = communityId
        this.syncAccountId = syncAccountId
        this.targetEveryone = targetEveryone
        this.syncAutomatically = syncAutomatically
        this.syncEventChannels = syncEventChannels
        this.sendReminderDayBefore = sendReminderDayBefore
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        const payload = {
            "name": this.name,
            "source_id": this.sourceId,
            "community_id": this.communityId,
            "sync_account_id": this.syncAccountId,
            "notify_synced_events": this.sendReminderDayBefore,
            "target_everyone": this.targetEveryone,
            "sync_event_channels": this.syncEventChannels,
        }

        const channelIds = filter(this.channelIds, c => c !== messageBuilder.TARGET_ALL_TAG_ID)

        payload["channel_ids"] = channelIds.length > 0 ? channelIds : []

        return payload
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        if (!this.name) {
            errors.push({
                field: "name",
                isValid: false,
                message: i18next.t("calendar.accounts.feed.link.requiredName")
            })
        }
        
        // if (size(this.channelIds) === 0) {
        //     errors.push({
        //         field: "channelIds",
        //         isValid: false,
        //         message: i18next.t("calendar.accounts.feed.link.requiredChannels")
        //     })
        // }
        
        if (!this.sourceId) {
            errors.push({
                field: "sourceId",
                isValid: false,
                message: i18next.t("calendar.accounts.feed.link.requiredCalendar")
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostCreateCalendarRequest