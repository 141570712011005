import React, { useState, useEffect } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid, Button, Icon, Dropdown, Image } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch } from 'react-redux'
import Switch from 'react-switch'
import srcPlaceholder from '../../../images/video_placeholder.svg'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'

export default function VideoMorphed({item}) {
    
    const [data, setData] = useState({
        url: item.data ? item.data.url : "",
        originalId: item?.data?.originalId
    })
    
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({item, data}))
        return () => {}
    }, [data, dispatch])

    const handleChangeUrl = (e, d) => {

        setData({
            ...data, 
            url: d.value
        })
    }

    return (
        <BaseMorphed item={item} render={({errors}) => (
            <Grid className="attachment-morphed">
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='label'>YouTube URL</label>
                            <Input 
                                value={data.url} 
                                onChange={handleChangeUrl} 
                                placeholder="YouTube URL"
                                autoComplete="off"  />
                        </div>
                        <ErrorLabel error={getError("url", errors)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )} />
    )
}
