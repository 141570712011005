
class GetUserFiltersRequest {

    constructor({
        communityId, 
    }) {
        this.communityId = communityId
    }


    getPayload = () => {
        return {
            "community_id": this.communityId,
        }
    }
    
}

export default GetUserFiltersRequest