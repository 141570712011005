import React from 'react'
import PropTypes from 'prop-types'

const  propTypes = {
    error: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf([null])
    ]),
    className: PropTypes.string,
    noMargin: PropTypes.bool,
}

const  defaultProps = {
    error: {field: null, message: null, isValid: true},
    className: '',
    noMargin: false,
}

const ErrorLabel = (props) => {
    
    const {
        error,
        noMargin,
        className,
    } = props

    if (error.isValid) {
        return null
    }

    return (
        <p className={`${noMargin ? '' : 'mg-top-10'} error-label ${className}`}>{error.message}</p>
    )
}

ErrorLabel.propTypes = propTypes
ErrorLabel.defaultProps = defaultProps

export default ErrorLabel