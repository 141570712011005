import React, { Fragment } from 'react'
import { Grid, Icon, List } from 'semantic-ui-react'

export default function ImageUploadQuestionPreview({item}) {
    
    return (
        <Fragment>
            <Grid.Row>
                <Grid.Column>
                    <div className="input-container theme-color">
                        <label className='label mg-bot-10 black-text center-align'>
                            {item.data.question} {item.data.isRequired && <span style={{marginLeft: 5}} className="required-star secondary-color-text">*</span>}
                        </label>
                        <List.Content className="file-upload-placeholder">
                            <Icon name="file" />
                        </List.Content>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Fragment>
    )
}
