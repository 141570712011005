import React, { useState, useEffect, Fragment } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid, Button, Icon, Popup, Dropdown } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch, useSelector } from 'react-redux'
import srcPlaceholder from '../../../images/image_placeholder.svg'
import { useTranslation } from 'react-i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError, immutableMove, isPartiallyUploaded, setError } from '../../../utils/helper'
import { messageBuilder, templateIcons } from '../../../constants'
import { useDropzone } from 'react-dropzone'
import { indexOf, map, mapKeys, mapValues, merge, omit, size, without, filter } from 'lodash'
import { uploadService } from '../../../api'
import i18next from 'i18next'
import { v4 } from 'uuid'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import classNames from 'classnames'

/**
 * @TODO: Failed file uploads will not re-upload when a component gets re-arranged, this is because the "Physical File Blob" will not be cached because its not a serialisable object.
 * 
 */
export const ImageThumb = (props) => {
    
    const {
        data,
        index,
        onRemove,
        isDragging = false,
        onUploadError,
        onImageDecoded,
        onUploadSuccess,
    } = props

    const [state, setState] = useState({
        loading: false,
        thumb: data?.thumb ?? null,
        mime: data?.mime ?? null,
        error: null,
        fileId: data?.id ?? null,
        blob: data?.file?.blob,
        validationError: data?.error ?? null,
        retryCount: 0,
    })


    useEffect(() => {

        if (!isPartiallyUploaded([state.fileId]) && !state.blob) {

            const handleGetFilePreview = async () => {
                const previewData = await uploadService.getFilePreview({fileId: state.fileId})
                const blob = new Blob([previewData.data], {type: "image/jpg"})
                
                setState(prev => ({
                    ...prev,
                    thumb: URL.createObjectURL(blob),
                }))

                // @NOTE: at the point that the builder receives a revoked blob url, once we get a new blob preview url, we should now alert the builder about this change
                onUploadSuccess({
                    id: data.id,
                    imageData: {
                        ...data,
                        thumb: URL.createObjectURL(blob),
                    },
                })
            }

            // When our thumb is one from our CDN we do not need to fetch any preview
            if (!state.thumb?.includes(`https://cdnrly`)) {
                handleGetFilePreview()
            }
        }

        // Cleanup to avoid memory leaks
        return () => {
            URL.revokeObjectURL(state.thumb)
        }
    }, [])

    // When the the Thumb enters the fray, we should check that we have a valid file so we can upload
    useEffect(() => {

        if (!data?.file) return;

        // We dont need to create image data when we arrive here with valid imageUrls on the file
        if (data.file?.url) return;

        if (!data.file?.blob) return;

        // We dont have to re-upload the files when new items get added and when the component if being moved around
        // We will make the assumption that when an ImageThumb Component has a thumbnail generated, we no longer need to 
        // do an API Call to upload the physical file AKA Blob
        if (state.thumb) return;

        setState(prev => ({
            ...prev,
            thumb: URL.createObjectURL(data.file.blob),
            loading: true
        }))

        
        handleUpload(data.file.blob)

    }, [data?.file?.blob])


    const handleUpload = (file) => {
        
        const incomingMime = data.file?.mime
        const payload = {
            file: file, 
            type: 'image',
            mime: incomingMime
        }

        // Start Uploading to the server
        uploadService.upload(payload).then(response => {

            // Upload finished with success
            setState(prev => ({
                ...prev, 
                fileId: response.id ?? data?.id,
                thumb: response.data.url ?? prev.thumb, 
                blob: response.data.blob ?? prev.blob,
                loading: false, 
                error: null
            }))

            onUploadSuccess({
                id: data.id,
                imageData: { ...data },
            })

        }).catch(error => {

            onUploadError({
                id: data.id,
                imageData: { ...data }
            })

            // Upload finished with errors
            setState(prev => ({
                ...prev,
                loading: false,
                error: error?.data?.message || i18next.t("error.errorOccurred")
            }))
        })
    }

    useEffect(() => {

        if (!data?.retryToken) return;
        if (!data?.file?.blob) return; // There is nothing we can do without the blob

        // When there is a signal to retry an upload, we need to upload the file again
        handleUpload(data.file.blob)

        // Increment the retry count
        setState(prev => ({ ...prev, loading: true, retryCount: prev.retryCount + 1 }))

    }, [data?.retryToken])

    useEffect(() => {

        if (!state.fileId) return;

        // When the upload is finished, we can now send back the data from the uploader
        onImageDecoded({
            imageData: {
                ...data,
                file: {
                    ...data.file,
                    id: state.fileId,
                    blob: state.blob,
                },
                id: state.fileId,
                thumb: state.thumb,
                mime: state.mime
            },
            index: index,
        })

    }, [state.fileId])

    const handleRemoveImage = () => {

        // To remove this image from the list, we simply just invoke the 
        // onRemove function given by the parent and parse the index of this image thumb
        onRemove({ index: index, id: state.fileId })
    }

    // Re-upload the file that may have failed
    const handleRedo = () => {
        setState(prev => ({ ...prev, loading: true, retryCount: prev.retryCount + 1 }))
        handleUpload(data.file.blob)
    }

    // @TODO: Edit an existing Image and add the new Image in place
    const handleEdit = () => { }

    return (
        <div key={index} className={`ui image thumb-image-container ${state.loading ? 'loading' : ''} ${(state.error || state.validationError) ? 'error' : ''} ${isDragging ? 'is-dragging' : ''}`}>
            {/* {!state.loading && <Icon className='remove-image-icon' name="times circle" onClick={handleRemoveImage} />} */}
            {state.loading && <Icon className='loading-icon' name="spinner" loading />}
            {(!state.loading || !isDragging) && (
                <Button.Group basic size='small' className='file-action-buttons'>
                    <Button icon='trash' onClick={handleRemoveImage} />
                    <ThumbHandler />
                    {/* <Button icon='edit' onClick={handleEdit} /> */}
                    {((state.error || state.validationError) && state.blob) && <Button icon='redo' onClick={handleRedo} />}
                </Button.Group>
            )}
            {!state.loading && (state.error || state.validationError) && (
                <Popup
                    basic
                    size='tiny'
                    className='morphed'
                    trigger={<Icon className='loading-icon error' name={classNames("warning", state.retryCount > 3 ? "circle" : "sign")} />}
                    content={state.retryCount > 3 ? i18next.t("error.errorOccurred.maxRetries") : (state.error ?? state.validationError)}
                />
            )}
            <div className="image-component morphius" style={{ backgroundImage: `url("${state.thumb ?? srcPlaceholder}")` }} />
        </div>
    )
}

const ThumbHandler = SortableHandle(() => <Button className='img-thumb-move' icon='move' onClick={() => {}} />)

const SortableItem = SortableElement((props) => {
    
    const { 
        image, 
        imageKey,
        isDragging, 
        onRemoveImage, 
        onUploadError, 
        onImageDecoded, 
        onUploadSuccess 
    } = props

    return (
        <ImageThumb 
            data={image}
            key={imageKey} 
            index={imageKey} 
            isDragging={isDragging}
            onRemove={onRemoveImage}
            onUploadError={onUploadError}
            onImageDecoded={onImageDecoded}
            onUploadSuccess={onUploadSuccess} />
    )
})

const SortableList = SortableContainer(props => {
        
    const {
        open,
        items,
        images,
        isDragging,
        onRemoveImage,
        onUploadError,
        onImageDecoded,
        onUploadSuccess
    } = props

    return (
        <ul className='no-padding'>
            {map(items, (imageKey, index) => (
                <SortableItem 
                    key={imageKey} 
                    index={index}
                    imageKey={imageKey} 
                    isDragging={isDragging} 
                    image={images[imageKey]}
                    onRemoveImage={onRemoveImage}
                    onUploadError={onUploadError}
                    onImageDecoded={onImageDecoded}
                    onUploadSuccess={onUploadSuccess} />
            ))}
            {size(images) > 0 && (
                <div className="ui image mg-similar-morphed">
                    <div className="ui image add-new no-margin" onClick={open}>
                        <Icon name='plus' size='big' className='no-margin' />
                    </div>
                </div>
            )}
        </ul>
    )
})


/**
 * @NOTE
 * 
 * ON_CREATE: Creating an Image Component will allow abilities to be able to add one or more images in one ImageComponent, and this
 * will update the ImageComponent into an AlbumComponent once the number of images reach more than {4}. Conversely modifying these 
 * images in a Transformed Component from anything less than or equal to {4} will make this a normal ImageComponent.
 * 
 * ON_UPDATE: During update, the ability for an ImageComponent to upload multiple images will be disabled since we cannot update from one component to another
 * equally we will disable the ability for an Album Component to be updated into an Image Component once we update and delete all images and leave only {1}
 * 
 */
export default function ImageMorphed({ item }) {

    const [validationMessages, setValidationMessages] = useState([])
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const mimes = messageBuilder.SUPPORTED_IMAGE_MIMES.split(",")

    const [data, setData] = useState({
        originalId: item?.data?.originalId,
        _type: item?.data?._type ?? templateIcons.image,
        files: item.data?.files ?? {},
        albumTitle: item?.data?.albumTitle ?? "",
        resourceCategoryIds: item?.data?.resourceCategoryIds ?? [],
    })

    // Image IDs for re-ordering
    const [items, setItems] = useState(map(item.data?.files ?? {}, (v, k) => k))

    const [dragging, setDragging] = useState(false)

    // We need to determine whether we are creating or updating this component
    // This can be done by looking at the {originalId} a component's {originalId} will only be set if we are updating
    const [settings, setSettings] = useState({
        isUpdate: (data.originalId !== undefined),
        isImageComponent: (data._type === templateIcons.image)
    })

    // Houses the item_ids that are currently being uploaded or being loaded
    // When an item is busy, its ID will remain in the list, but once done, the ID will be removed from the list
    const [busyItems, setBusyItems] = useState([])

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        open
    } = useDropzone({
        maxFiles: (settings.isImageComponent && settings.isUpdate) ? 1 : messageBuilder.MAX_IMAGES_IN_ALBUM,
        noClick: true,
        noKeyboard: true,
        multiple: (settings.isImageComponent && settings.isUpdate) === false,
        accept: mapValues(mapKeys(mimes), k => []),
        validator: (file) => {

            // Validate the File Size
            if (file.size > messageBuilder.MAX_FILE_SIZE) {
                return {
                    code: "file-too-big",
                    message: t('messageBuilder.morphedTemplates.AttachmentMorphed.maxFileError')
                }
            }

            // Validate the Mime Type
            if (!mimes.includes(file.type)) {
                return {
                    code: "file-not-supported",
                    message: t('messageBuilder.morphedTemplates.imageMorphed.unsupportedFileError')
                }
            }

            return null
        }
    })

    useEffect(() => {
        
        const storeData = map(data.files, (file) => {
            return omit(file, ['file.blob'])
        })

        dispatch(builderActions.saveTemplateData({ item, data: {
            ...data,
            files: mapKeys(storeData, i => i.id)
        }}))

        // When we have at least one failed item, we should show an error
        const failed = filter(storeData, (file) => file.hasError)
        if (failed.length > 0) {
            setValidationMessages(setError("imagePartiallyUploaded", i18next.t('requestObjects.PostCreateNoticeRequest.files.partialy-uploaded')))
        } else {
            setValidationMessages([])
        }

        return () => {}
    }, [data, dispatch])

    useEffect(() => {
        
        // Get new image ordering
        const _newOrder = {}
        
        map(items, (v, k) => {
            _newOrder[v] = data.files[v]  
        })
        
        if (size(items) > 0) {
            setData(prev => ({
                ...prev, files: _newOrder
            }))
        }

        return () => { }
    }, [items])

    useEffect(() => {

        if (acceptedFiles.length === 0) return;

        const incoming = map(acceptedFiles, (file, index) => {

            const tempId = v4() + messageBuilder.TEMP_ID_PREFIX
            const total = size(data.files)

            return {
                file: {
                    url: null,
                    id: tempId,
                    blob: file,
                    mime: file.type,
                },
                id: tempId,
                index: total === 0 ? index : index - 1,
                _parentId: item.id
            }
        })

        const mappedIncoming = mapKeys(incoming, i => i.id)

        // When images are dropped here, they are going to be processed by the uploader, so we are going to add all images and their temp ids starting here
        setBusyItems(map(incoming, "id"))

        // When we are updating ImageComponent, we should replace and only allow one Image
        if (settings.isUpdate && settings.isImageComponent) {

            // Update draggable order list
            setItems(map(mappedIncoming, (v, k) => k))

            setData(prev => ({
                ...prev, 
                files: {
                    ...mappedIncoming
                }
            }))

            return;
        }

        // Update draggable order list
        setItems(map({...data.files, ...mappedIncoming}, (v, k) => k))

        setData(prev => ({
            ...prev, files: {
                ...prev.files,
                ...mappedIncoming
            }
        }))
    }, [acceptedFiles])

    useEffect(() => {

        // With anything happening in the busy items, we will update the builder with the status
        // The busy indicator should only stop once the are no more items in the busyItems list
        dispatch(builderActions.busyIndicator({
            loading: (busyItems.length > 0),
            items: busyItems.length
        }))

    }, [busyItems])
    
    const onImageDecoded = ({ imageData, index }) => {
        const _images = { ...data.files }
        const _file = { ...imageData, index: imageData.index ?? size(_images) }

        setData(prev => ({ 
            ...prev,
            files: {
                ...prev.files,
                [index]: _file,
            }
        }))
    }

    const updateFileUploadStatus = ({ imageData, id }, hasError = false) => {
        const _images = { ...data.files }
        const _file = { ...imageData, retryToken: null, loading: false, hasError: hasError, index: imageData.index ?? size(_images) }
        
        setData(prev => ({ 
            ...prev, 
            files: {
                ...prev.files,
                [id]: _file,
            }
        }))

        // Remove an item once its done loaded
        setBusyItems(prev => without(prev, id))
    }

    const onUploadSuccess = ({ imageData, id }) => {
        updateFileUploadStatus({ imageData, id }, false)
    }

    const onUploadError = ({ imageData, id }) => {
        updateFileUploadStatus({ imageData, id }, true)
    }

    const onRemoveImage = ({ index }) => {

        // Get all of the images already listed
        const _all = { ...data.files }

        // Remove the item using the given index
        const _images = omit(_all, [index])

        // Update the items
        setData(prev => ({ ...prev, files: _images }))

        setItems(map(_images, (v, k) => k))
    }

    const handleTitleChange = (e, data) => {

        // When the user enters more than 40 characters, we stop them from going any further
        if (size(data.value) > 40) {
            return;
        }

        setData(prev => ({
            ...prev,
            albumTitle: data.value
        }))
    }


    const onSortStart = () => setDragging(true)

    const onSortEnd = ({ oldIndex, newIndex }) => {
        
        setDragging(false)

        // No need to re-order if its the same place
        if (oldIndex === newIndex) return;
        
        setItems(prev => immutableMove(prev, oldIndex, newIndex))
    }

    const handleRedoAll = () => {
        // Get all the files that have failed to upload
        const _files = map(data.files, (file) => {

            if (file.id.includes(messageBuilder.TEMP_ID_PREFIX)) {
                return {
                    ...file,
                    retryToken: v4()
                }
            }

            return file
        })

        const mappedFiles = mapKeys(_files, i => i.id)
        
        // Update busy items
        setBusyItems(map(mappedFiles, "id").filter(id => id.includes(messageBuilder.TEMP_ID_PREFIX)))

        // Update the items
        // setData(prev => ({ ...prev, files: {...mappedFiles} }))
        setData(prev => ({ ...prev, files: mappedFiles }))
    }

    const handleCategoryChange = ({name, value, e}) => {
        setData(prev => ({ ...prev, [name]: value }))
    }

    return (
        <BaseMorphed item={item} className={`${(!getError("imagePartiallyUploaded", validationMessages).isValid || fileRejections.length > 0) ? 'has-errors' : ''}`} render={({ errors }) => (
            <Grid className="attachment-morphed">
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div className='relative'>
                            <label className='label'>{t('messageBuilder.morphedTemplates.ImageMorphed.input.image.placeholder')}</label>
                            {!getError('imagePartiallyUploaded', validationMessages).isValid && (
                                <Button size='tiny' floated='right' className='absolute' onClick={handleRedoAll}>
                                    <Icon name="repeat" />{t('messageBuilder.morphedTemplates.ImageMorphed.input.image.button.retry-failed-uploads')}
                                </Button>
                            )}
                            <div style={{marginTop: 15}} {...getRootProps({className: `dropzone ${dragging ? 'incoming' : ''}`})}>
                                <input {...getInputProps()} accept="image/*" />
                                
                                {size(data.files) == 0 && (
                                    <Fragment>
                                        <p>{t('messageBuilder.morphedTemplates.AttachmentMorphed.selectFilePlaceholder')}</p>
                                        <Button 
                                            size="tiny"
                                            className="secondary" 
                                            onClick={open}>{t('messageBuilder.morphedTemplates.AttachmentMorphed.chooseFile')}</Button>
                                    </Fragment>
                                )}

                                <div className='ui tiny w-100 no-padding images'>
                                    
                                    <SortableList
                                        axis='xy'
                                        open={open}
                                        items={items}
                                        images={data.files}
                                        isDragging={dragging}
                                        onSortEnd={onSortEnd}
                                        onSortStart={onSortStart}
                                        onRemoveImage={onRemoveImage}
                                        onUploadError={onUploadError}
                                        onImageDecoded={onImageDecoded}
                                        onUploadSuccess={onUploadSuccess}
                                        useDragHandle={true} />
                                </div>
                            </div>
                        </div>
                        
                        <ErrorLabel error={getError("image", errors)} />
                        <ErrorLabel error={getError("imagePartiallyUploaded", errors)} />

                        {/* Show Local Errors */}
                        {map(fileRejections, (file, i) => {
                            const error = file.errors.length > 0 ? file.errors[0].message : t("error.errorOccurred")
                            return (<p key={i} className={`mg-top-10 error-label`}>{error}</p>)
                        })}
                    </Grid.Column>

                    {((settings.isUpdate && !settings.isImageComponent) || size(data.files) > messageBuilder.NUM_IMAGES_TO_ALBUM) && (
                        <Grid.Column width={16} className="mg-top-15">
                            <div className="input-container theme-color">
                                <label className='label'>{t('messageBuilder.morphedTemplates.ImageMorphed.input.albumTitle')}</label>
                                <Input
                                    name="title"
                                    value={data.albumTitle}
                                    onChange={handleTitleChange}
                                    autoComplete="off"
                                    placeholder={t('messageBuilder.morphedTemplates.ImageMorphed.input.albumTitle')} />
                            </div>
                            <ErrorLabel error={getError("title", errors)} />
                            <p className={`mg-top-12 label ${(size(data.albumTitle) < 40) ? 'grey-text' : 'error-text'}`}>{t("builder.label.maxChars")} ({size(data.albumTitle)}/40)</p>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        )} />
    )
}
