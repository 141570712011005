import i18next from "i18next"
import { pickBy, identity, mapKeys, filter, map, isEmpty, isNull } from "lodash"
import { dropEmptyKeys, filterNulls, mailValidator, phoneValidator } from "../../utils/helper"

/**
 * @typedef Request
 * @property {String} id
 * @property {String} name
 * @property {String} image
 * @property {String} shortName
 * @property {String} coverImage
 * @property {String} email
 * @property {String} phoneNumber
 * @property {String} website
 * @property {String} address
 * @property {String} timezone
 * @property {String} accountContactContactPerson
 * @property {String} accountContactEmail
 * @property {String} accountContactPhoneNumber
 * @property {String} defaultAppNoticeDistributionRelationship
 * @property {String} defaultPortalNoticeDistributionRelationship
 * @property {String} defaultAppNoticePlatformDistributionGrouping
 * @property {String} defaultPortalNoticePlatformDistributionGrouping
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */

class UpdateCommunityRequest {

  /**
   * 
   * @param {Request} request
   */
  constructor({
    id,
    name,
    image,
    email,
    website,
    address,
    timezone,
    shortName,
    imageFile,
    coverImage,
    phoneNumber,
    coverImageFile,
    accountContactEmail,
    accountContactNotes,
    accountContactPhoneNumber,
    accountContactContactPerson,
    defaultAppNoticeDistributionRelationship,
    defaultPortalNoticeDistributionRelationship,
    defaultAppNoticePlatformDistributionGrouping,
    defaultPortalNoticePlatformDistributionGrouping,
  }) {
    this.id = id
    this.name = name
    this.image = image
    this.email = email
    this.website = website
    this.address = address
    this.timezone = timezone
    this.shortName = shortName
    this.imageFile = imageFile
    this.coverImage = coverImage
    this.phoneNumber = phoneNumber
    this.coverImageFile = coverImageFile
    this.accountContactEmail = accountContactEmail
    this.accountContactNotes = accountContactNotes
    this.accountContactPhoneNumber = accountContactPhoneNumber
    this.accountContactContactPerson = accountContactContactPerson
    this.defaultAppNoticeDistributionRelationship = defaultAppNoticeDistributionRelationship
    this.defaultPortalNoticeDistributionRelationship = defaultPortalNoticeDistributionRelationship
    this.defaultAppNoticePlatformDistributionGrouping = defaultAppNoticePlatformDistributionGrouping
    this.defaultPortalNoticePlatformDistributionGrouping = defaultPortalNoticePlatformDistributionGrouping
  }

  /**
   * Post data ready for usage by the API
   * 
   * @returns {Request}
   */
  getPayload = () => {

    let payload = {
      "name": this.name,
      "email": this.email,
      "image": isNull(this.imageFile) ? undefined : this.imageFile,
      "website": this.website,
      "address": this.address,
      "timezone": this.timezone,
      "short_name": this.shortName,
      "cover_image": isNull(this.coverImageFile) ? undefined : this.coverImageFile,
      "phone_number": this.phoneNumber,
      "account_contact_email": this.accountContactEmail,
      "account_contact_notes": this.accountContactNotes,
      "account_contact_name": this.accountContactContactPerson,
      "account_contact_phone_number": this.accountContactPhoneNumber,
      "default_app_notice_distribution_relationship": this.defaultAppNoticeDistributionRelationship,
      "default_portal_notice_distribution_relationship": this.defaultPortalNoticeDistributionRelationship,
      "default_app_notice_platform_distribution_grouping": this.defaultAppNoticePlatformDistributionGrouping,
      "default_portal_notice_platform_distribution_grouping": this.defaultPortalNoticePlatformDistributionGrouping,
    }

    // We need to create FormData since the request has to be a multipart/form-data
    const formData = new FormData()

    // We only need to send data that is not null
    payload = filterNulls(payload, true)

    // Add our request payload to the form-data
    map(payload, (value, key) => formData.append(key, value))

    return formData
  }

  /**
 * Determine error messages from the given data if any
 * 
 * @returns {ValidationError}
 */
  validator = () => {
    const errors = []

    if (!this.name) {
      errors.push({
        field: "name",
        isValid: false,
        message: i18next.t("requests.community.form.requiredName")
      })
    }

    if (this.email && !mailValidator.test(this.email)) {
      errors.push({
        field: "email",
        isValid: false,
        message: i18next.t("requestObjects.CreateMemberRequest.invalidEmail")
      })
    }

    if (!this.timezone) {
      errors.push({
        field: "timezone",
        isValid: false,
        message: i18next.t("requestObjects.UpdateCommunityRequest.timezone.required")
      })
    }

    if (this.shortName && this.shortName.length > 9) {
      errors.push({
        field: "shortName",
        isValid: false,
        message: i18next.t("requestObjects.UpdateCommunityRequest.shortName.maxLength.error")
      })
    }

    return {
      isValid: (errors.length === 0),
      messages: mapKeys(errors, error => error.field)
    }
  }

}

export default UpdateCommunityRequest