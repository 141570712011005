import React from 'react'

import { EditorState, ContentState, CharacterMetadata } from 'draft-js'
import { useTranslation } from 'react-i18next'

/**
 * 
 * @param {EditorState} editorState 
 * @param {*} retainInlineStyles 
 * @returns 
 */
export const removeInlineStyles = (editorState, retainInlineStyles) => {
    
    const blocks = editorState
        .getCurrentContent()
        .getBlocksAsArray()
        .map(singleBlock =>
            singleBlock.set(
                "characterList",
                singleBlock.getCharacterList().map(charMetaData => {
                    
                    if (!charMetaData) {
                        return charMetaData
                    }

                    const entity = charMetaData.getEntity()
                    const style = charMetaData.getStyle()
                    
                    return CharacterMetadata.create({
                        entity: entity,
                        style: style.intersect(retainInlineStyles)
                    })
                })
            )
        ).map(singleBlock => singleBlock.set("type", "unstyled"))

    const _newState = EditorState.createWithContent(
        ContentState.createFromBlockArray(blocks)
    )

    return _newState
}

export const removeAllStyles = (editorState) => {
    
    const currentText = editorState.getCurrentContent().getPlainText()

    const _newState = EditorState.createWithContent(
        ContentState.createFromText(currentText)
    )

    return _newState
}

const eraserIcon = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNOC4xIDE0bDYuNC03LjJjLjYtLjcuNi0xLjgtLjEtMi41bC0yLjctMi43Yy0uMy0uNC0uOC0uNi0xLjMtLjZIOC42Yy0uNSAwLTEgLjItMS40LjZMLjUgOS4yYy0uNi43LS42IDEuOS4xIDIuNWwyLjcgMi43Yy4zLjQuOC42IDEuMy42SDE2di0xSDguMXptLTEuMy0uMXMwLS4xIDAgMGwtMi43LTIuN2MtLjQtLjQtLjQtLjkgMC0xLjNMNy41IDZoLTFsLTMgMy4zYy0uNi43LS42IDEuNy4xIDIuNEw1LjkgMTRINC42Yy0uMiAwLS40LS4xLS42LS4yTDEuMiAxMWMtLjMtLjMtLjMtLjggMC0xLjFMNC43IDZoMS44TDEwIDJoMUw3LjUgNmwzLjEgMy43LTMuNSA0Yy0uMS4xLS4yLjEtLjMuMnoiLz48L3N2Zz4='

export const RichTextCleaner = (props) => {

    const clean = () => {
        const { editorState, onChange, isEventNotes } = props

        if (isEventNotes) {
            onChange(removeAllStyles(editorState))
        } else {
            onChange(EditorState.push(removeInlineStyles(editorState), editorState.getCurrentContent(), 'change-inline-style'))
        }
    }

    const {t} = useTranslation("editor")

    return (
        <div className="rdw-remove-wrapper" aria-label="rdw-remove-control" onClick={clean}>
            <div className="rdw-option-wrapper" title={t("components.controls.removeStyles")}>
            <img
                src={eraserIcon}
                alt=""
            />
            </div>
        </div>
    )
}