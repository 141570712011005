import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { Grid, Input, Popup, Button, Icon, Checkbox, Form } from 'semantic-ui-react'
import { findIndex, isEmpty, isEqual, isUndefined, map, size } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

function Option({option, handleOptionChange, handleRemoveOption, t, hideDelete}) {
    
    const onChange = (e, d) => {
        handleOptionChange({
            ...option,
            option: d.value,
        })
    }
    
    return (
        <Grid.Row>
            <Grid.Column>
                <div className="input-container theme-color">
                    <label className='label'>{t("general.form.multi.option")}</label>
                    <div className="vertical-align">
                        <Input 
                            value={ option.option ?? "" }
                            onChange={onChange}
                            placeholder={t("general.form.multi.option")}
                            className="w-90 no-padding"
                            autoComplete="off"  />
                        <div className="w-10">
                            {!hideDelete && (
                                <Button 
                                    size="tiny" 
                                    onClick={handleRemoveOption}
                                    icon="trash alternate"
                                    className='pointable'
                                     />
                            )}
                        </div>
                    </div>
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}

export function SelectionCopyHelperText ({min, max, className = "no-margin grey-text"}) {

    if (isUndefined(min) || isUndefined(max)) {
        return null
    }
    
    // This is practically [SingleSelect=True]
    if (min === 1 && max === 1) {
        return null
    }

    return (
        <p className={className}>
            {min !== max && (
                <small>{i18next.t('components.form.input.choices-per-person.helper-text', {
                    max: max, 
                    min: min, 
                    context: min > 0 && 'minincluded'
                })}</small>
            )}
            {min === max && (
                <small>{i18next.t('components.form.input.choices-per-person.helper-text', {
                    max: max, 
                    min: min,
                    context: max === 1 ? 'exactmatch_plural' : 'exactmatch',
                })}</small>
            )}
        </p>
    )
}

export function FormComponentSelectionLimits({data, handleInputChange, numItems, maxField = 'max', minField = 'min', className, maxStopValue = 2}) {
    const {t} = useTranslation()
    return (
        <div className='input-container theme-color mt-3 gap-5'>
            <label className="label no-margin">
                {t('components.form.input.choices-per-person.placeholder')}
            </label>
            <div className={classNames('selection-limits', className)}>
                <div className='parent-container'>
                    <span>{t('components.form.input.min-choice.placeholder', {min: data[minField], context: data[minField] === 0 && 'nolimit'})}</span>
                    <div className='action-buttons'>
                        <Button
                            circular
                            className={classNames('no-margin', 'primary solid')}
                            icon="minus"
                            disabled={data[minField] < 1 || (data.isRequired && data[minField] === 1)}
                            onClick={() => handleInputChange({name: minField, value: data[minField] - 1})} />
                        <Button
                            circular
                            className={classNames('no-margin', 'primary solid')}
                            icon="plus"
                            disabled={data[minField] === data[maxField]}
                            onClick={() => handleInputChange({name: minField, value: data[minField] + 1})} />
                    </div>
                </div>
                <div className='parent-container'>
                    <span>{t('components.form.input.max-choice.placeholder', {max: data[maxField]})}</span>
                    <div className='action-buttons'>
                        <Button
                            circular
                            className={classNames('no-margin', 'primary solid')}
                            icon="minus"
                            disabled={data[maxField] <= maxStopValue}
                            onClick={() => handleInputChange({name: maxField, value: data[maxField] - 1})} />
                        <Button
                            circular
                            className={classNames('no-margin', 'primary solid')}
                            icon="plus"
                            disabled={data[maxField] === numItems}
                            onClick={() => handleInputChange({name: maxField, value: data[maxField] + 1})} />
                    </div>
                </div>
            </div>
            <SelectionCopyHelperText
                min={data[minField]}
                max={data[maxField]} />
        </div>
    )
}

export default function MultipleChoiceQuestion({item, data, handleInputChange, t}) {
    
    const [singleSelect, setSingleSelect] = useState(item.data ? item.data.singleSelect : false)
    const _tmpOptions = item?.data?.options ?? []
    const [options, setOptions] = useState(_tmpOptions)
    const [hideDelete, setHideDelete] = useState(options.length === 2)

    const handleSingleSelectChange = () => {
        setSingleSelect(!singleSelect)
        handleInputChange({name: "singleSelect", value: !singleSelect})
    }
    
    const handleAddOption = (placeholderText = null) => {
        const newOptions = options.concat([{
            id: uuidv4(), // Whenever we add a new option, we can create a UUID for the option to avoid clashes
            option: placeholderText ?? ""
        }])

        setOptions(newOptions)
        handleInputChange({name: "options", value: newOptions})
    }

    const handleOptionChange = (data) => {
        
        const {
            id,
            option
        } = data

        // Options copy
        const _options = [...options]

        // Get the item via its index
        const index = findIndex(_options, {id: id})
        const found = _options[index]

        // Check existence
        if (!found) return;

        found.option = option

        // Mutate the original list with the updated item
        _options[index] = found
        setOptions(_options)
        handleInputChange({name: "options", value: _options})
    }

    const handleRemoveOption = (id) => {
        
        const index = findIndex(options, {id: id})
        
        // Options copy
        const _options = [...options]
        
        if (index !== -1) {
            // Remove the found item
            _options.splice(index, 1)

            if (!item?.data?.originalId) {
                // Update the named indexes
                map(_options, (_option, index) => {
                    _option.option = _option.option ?? t('builder.form.multichoice.option.placeholder', {index: index + 1})
                })
            }

            // Update list
            setOptions(_options)
            
            // Update listeners
            handleInputChange({name: "options", value: _options})
        }
    }

    useEffect(() => {
        setHideDelete(options.length === 2)
        handleInputChange([
            {
                name: "max", 
                value: !isEqual(options, _tmpOptions) ? options.length : data.max
            },
            {
                name: "min", 
                value: !isEqual(options, _tmpOptions) && data.min > options.length ? options.length : data.min
            }
        ])

    }, [options])

    // useEffect(() => {
    //     // When this form is made required, we should have the min and max set to 1
    //     if (singleSelect) {
    //         handleInputChange([
    //             {
    //                 name: 'min',
    //                 value: 1
    //             },
    //             {
    //                 name: 'max',
    //                 value: 1
    //             }
    //         ])
    //     } else {

    //         // When this form is made not required, we should have the max set to {options.count}
    //         handleInputChange({
    //             name: 'max',
    //             value: !isEqual(options, _tmpOptions) ? options.length : data.max,
    //         })
    //     }
    // }, [singleSelect])

    useEffect(() => {
        if (data.isRequired) {
            handleInputChange([
                {
                    name: 'min',
                    value: data.min > 0 ? data.min : 1 // we should check that min is not zero, if it is zero, we should it to 1, otherwise. 
                },
            ])
        }
    }, [data.isRequired])
    
    useEffect(() => {
        // When a component has an original_id, means we are editing
        if (item?.data?.originalId) {
            return;
        }

        // We do not have to recreate options when there are defaults already.
        if (options.length !== 0) {
            return
        }

        // On initialisation, when there are no options, we will prefill with default first
        const newOptions = options
            .concat([{
                id: uuidv4(), // Whenever we add a new option, we can create a UUID for the option to avoid clashes
                option: t('builder.form.multichoice.option.placeholder', {index: 1}),
            }])
            .concat([{
                id: uuidv4(), // Whenever we add a new option, we can create a UUID for the option to avoid clashes
                option: t('builder.form.multichoice.option.placeholder', {index: 2}),
            }])

        setOptions(newOptions)
        handleInputChange([
            {
                name: "options", 
                value: newOptions
            },
            {
                name: 'min',
                value: data.min ?? 0
            },
        ])
    }, [])
    

    return (
        <Fragment>
            <Grid.Row>
                <Grid.Column>
                    <div className="input-container theme-color">
                        <label className='label'>{t("general.form.multi.placeholder")}</label>
                        <Input 
                            value={data.question} 
                            name="question"
                            autoComplete="off" 
                            onChange={(e, {value}) => handleInputChange({name: "question", value})} 
                            placeholder={t("general.form.multi.placeholder")} />
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <div className='vertical-align'>
                        <Checkbox
                            checked={singleSelect}
                            onChange={handleSingleSelectChange}/>
                        <span className='caption mg-left-15'>{t("general.form.multi.singleSelect")}</span>
                        <label className="label vertical-align">
                            <Popup
                                basic
                                trigger={<Icon className="info circle info-icon morphed in-label primary-text"/>}
                                content={t("general.form.multi.singleSelect.moreInfo")}/>
                        </label>
                    </div>
                    {singleSelect === false && (
                        <FormComponentSelectionLimits
                            data={data}
                            minField='min'
                            maxField='max'
                            numItems={options.length}
                            maxStopValue={2}
                            handleInputChange={handleInputChange} />
                    )}
                </Grid.Column>
            </Grid.Row>
            {map(options, (option, index) => (
                <Option 
                    t={t}
                    key={index}
                    option={option}
                    hideDelete={hideDelete}
                    handleRemoveOption={() => handleRemoveOption(option.id)}
                    handleOptionChange={handleOptionChange} />
            ))}
            <Grid.Row>
                <Grid.Column className="right-align">
                    <Button className="secondary" onClick={() => handleAddOption(t('builder.form.multichoice.option.placeholder', {index: size(options) + 1}))}>{t("general.form.multi.addOption")}</Button>
                </Grid.Column>
            </Grid.Row>
        </Fragment>
    )
}
