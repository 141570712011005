import React, { useState, useEffect, useRef, Fragment } from 'react'

import { EditorState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import BaseMorphed from './BaseMorphed'
import { Input, Grid, Popup, Button, Icon, Dropdown, Label, Checkbox } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import { useTranslation } from 'react-i18next'
import { paymentTypes } from '../../../constants'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'
import i18next from 'i18next'
import IntlCurrencyInput from '../../../utils/IntlCurrencyInput'

export default function PaymentMorphed({item}) {

    // ID For Open projects
    const openType = 'open'
    
    // ID For Itemised Payments
    const itemisedType = paymentTypes.itemised.value
    const {t} = useTranslation()
    const community = useSelector(state => state.navigation.community)

    const [types] = useState([
        {
            key: paymentTypes.open.id,
            value: paymentTypes.open.value,
            text: paymentTypes.open.name
        },
        {
            key: paymentTypes.fixed.id,
            value: paymentTypes.fixed.value,
            text: paymentTypes.fixed.name
        },
        {
            key: paymentTypes.itemised.id,
            value: paymentTypes.itemised.value,
            text: paymentTypes.itemised.name
        },
        {
            key: paymentTypes.partial.id,
            value: paymentTypes.partial.value,
            text: paymentTypes.partial.name
        },
    ])

    const [data, setData] = useState({
        type: item.data ? item.data.type : null,
        title: item.data ? item.data.title : "",
        amount: item.data ? item.data.amount : 0,
        floatAmount: item.data ? item.data.floatAmount : 0,
        showQuantity: item.data?.showQuantity ?? false,
        maxQuantityLimit: item.data ? item.data.maxQuantityLimit : '',
        currency: item.data ? item.data.currency : community.payment_currency_iso_4217,
        originalId: item?.data?.originalId,
    })

    const dispatch = useDispatch()
    const inputRef = useRef()
    const dropdownRef = useRef()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({
            item, 
            data: {
                ...data,
                maxQuantityLimit: data.showQuantity ? data.maxQuantityLimit : ''
            }
        }))
        return () => {}
    }, [data, dispatch])

    const handleTypeChange = (e, d) => {
        dropdownRef.current.close()
        // We will clear the amount when an open type is selected
        if (d.value === openType) {
            setData({...data, type: d.value, amount: 0, floatAmount: 0})
            return;
        }
        setData({...data, type: d.value})
    }
    
    const handleChangeTitle = (e, d) => {
        setData({item, ...data, title: d.value})
    }
    
    const handleChangeAmount = (e, floatValue, maskedValue, minorUnits) => {
        setData({item, ...data, amount: minorUnits, floatAmount: floatValue})
    }
    
    const handleCheckChange = (e, d) => {
        setData((prev) => ({
            ...prev, showQuantity: !prev.showQuantity,
            maxQuantityLimit: !prev.showQuantity ? '' : prev.maxQuantityLimit
        }))
    }

    const handleQuantityChange = (e, d) => {
        setData((prev) => ({...prev, maxQuantityLimit: d.value}))
    }

    const amountLabel = data.type === itemisedType ? t('messageBuilder.morphedTemplates.PaymentMorphed.amountPerItem') : t('messageBuilder.morphedTemplates.PaymentMorphed.amount')
    
    return (
        <BaseMorphed item={item} render={({errors}) => (
            <Grid>
                {(data.type === paymentTypes.partial.value) && (
                    <Grid.Row>
                        <Grid.Column>
                            <div className="partial-payments-note">
                                {t("messageBuilder.morphedTemplates.PaymentMorphed.types.partialDeadlineRecommended")}
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.PaymentMorphed.paymentItemTitle')}</label>
                            <Input autoComplete="off" value={data.title} onChange={handleChangeTitle} placeholder={t('messageBuilder.morphedTemplates.PaymentMorphed.paymentItemTitle')} />
                        </div>
                        <ErrorLabel error={getError("title", errors)} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='label'>
                                {t('messageBuilder.morphedTemplates.PaymentMorphed.paymentType')}
                                <Popup
                                    trigger={<Icon className="info circle info-icon morphed in-label primary-text"/>}
                                    content={(
                                        <div>
                                            <p className="no-margin">{t('messageBuilder.morphedTemplates.PaymentMorphed.paymentTypeBreakdown')}</p>
                                            <ul>
                                                <li><strong>{paymentTypes.fixed.name}:</strong> {t('messageBuilder.morphedTemplates.PaymentMorphed.types.fixedDescription')}</li>
                                                <li><strong>{paymentTypes.itemised.name}:</strong> {t('messageBuilder.morphedTemplates.PaymentMorphed.types.itemisedDescription')}</li>
                                                <li><strong>{paymentTypes.open.name}:</strong> {t('messageBuilder.morphedTemplates.PaymentMorphed.types.openDescription')}</li>
                                                <li><strong>{paymentTypes.partial.name}:</strong> {t('messageBuilder.morphedTemplates.PaymentMorphed.types.partialDescription')}</li>
                                            </ul>
                                        </div>
                                    )}
                                />
                            </label>
                            <Dropdown 
                                ref={dropdownRef}
                                placeholder={t('messageBuilder.morphedTemplates.PaymentMorphed.paymentType')}
                                fluid 
                                selection
                                onChange={handleTypeChange}
                                value={data.type} 
                                options={types} />
                        </div>
                        <ErrorLabel error={getError("type", errors)} />
                    </Grid.Column>
                </Grid.Row>
                
                {/* Hide Amount for Open Types */}
                {(data.type !== openType && community != null) && (
                    <Grid.Row>
                        <Grid.Column>
                            <div className="input-container theme-color">
                                <label className='label'>{amountLabel}</label>
                                <div className="ui input">
                                    <IntlCurrencyInput
                                        currencyOverride={community.payment_currency_iso_4217 === 'ZAR' ? {"ZAR": "R"} : null}
                                        currency={community.payment_currency_iso_4217}
                                        onChange={handleChangeAmount}
                                        value={data.floatAmount}
                                        config={{
                                            locale: i18next.language,
                                            formats: {
                                                number: {
                                                    [community.payment_currency_iso_4217]: {
                                                        currency: community.payment_currency_iso_4217,
                                                        style: "currency",
                                                    }
                                                }
                                            }
                                        }} />
                                </div>
                            </div>
                            <ErrorLabel error={getError("amount", errors)} />
                        </Grid.Column>
                    </Grid.Row>
                )}
                
                {/* Hide Amount for Open Types */}
                {(data.type === paymentTypes.itemised.value) && (
                    <Fragment>
                        <Grid.Row>
                            <Grid.Column>
                                <div className='vertical-align'>
                                    <Checkbox
                                        checked={data.showQuantity}
                                        label={t('messageBuilder.morphedTemplates.PaymentMorphed.quantity.limit')}
                                        onChange={handleCheckChange}/>
                                    <Popup
                                        basic
                                        size="small"
                                        trigger={
                                            <Icon className="info circle info-icon community-roles" />
                                        }
                                        content={t('messageBuilder.morphedTemplates.PaymentMorphed.quantity.limit.popup')}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        {data.showQuantity && (
                            <Grid.Row>
                                <Grid.Column>
                                    <div className="input-container theme-color">
                                        <label className='label'>{t('messageBuilder.morphedTemplates.PaymentMorphed.quantity.limit.input')}</label>
                                        <Input 
                                            autoComplete="off" 
                                            value={data.maxQuantityLimit} 
                                            onChange={handleQuantityChange}
                                            type='number'
                                            min={data.maxQuantityLimit}
                                            max={data.maxQuantityLimit}
                                            placeholder={t('messageBuilder.morphedTemplates.PaymentMorphed.quantity.limit.input')} />
                                    </div>
                                    <ErrorLabel error={getError("maxQuantityLimit", errors)} />
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Fragment>
                )}
            </Grid>
        )} />
    )
}
