import i18next from "i18next"
import { pickBy, identity, flatten, map, find, isEmpty, mapKeys, size } from "lodash"
import { filterNulls, mailValidator } from "../../utils/helper"

class InviteUserRequest {
    
    constructor({
        email,
        communityId,
        channelRoles,
        channelRoleIds,
        communityRoleIds,

    }) {
        this.email = email
        this.communityId = communityId
        this.channelRoles = channelRoles
        this.channelRoleIds = channelRoleIds
        this.communityRoleIds = communityRoleIds
    }
    
    getPayload = () => {
        
        const payload = {
            "email": this.email,
            "community_id": this.communityId,
            "channel_roles": this.getInverseRoleChannelSelection(),
            "community_roles": this.communityRoleIds,
        }

        return filterNulls(payload)
    }

    getInverseRoleChannelSelection = () => {
        
        /**
         *  1. Get the selected channels from the selected channel roles, this will return a list
         *  2. Create new attributes for ease of access [role, channels]
         *  3. Upon creation of the new attributes, create the channels with a reference to the  original channel role that was selected on that channel
         *  4. Finally, create a one unified list from the from the x number of channel roles
         * 
         * */
        var channels = flatten(map(map(this.channelRoles, c => {return {role: c.id, channels: map(c.channels, cr => {return {role: c.id, channel: cr}})}}), "channels"))
        
        const _selected = []

        for (const x in channels) {
            const channel = {
                id: channels[x].channel,
                role: channels[x].role
            }

            // Check that the channel is already in the selected list
            const _channel = find(_selected, {channel_id: channel.id})
            if (_channel) {
                
                // When we find that the channel exists, we will append the role into the current list
                _channel.roles = _channel.roles.concat(channel.role)
            } else {
                
                // When there is no existing channel, we create a new
                _selected.push({
                    channel_id: channels[x].channel,
                    roles: [channels[x].role]
                })
            }
        }

        return _selected
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validator = () => {

        const errors = []
        
        if (!this.email || !mailValidator.test(this.email)) {
            errors.push({
                field: "email",
                isValid: false,
                message:!this.email ? i18next.t('requestObjects.InviteUserRequest.requiredEmail') : i18next.t('requestObjects.InviteUserRequest.invalidEmail'),
            })
        }
        
        if (size(this.getInverseRoleChannelSelection()) == 0 && size(this.communityRoleIds) == 0) {
            errors.push({
                field: "roles",
                isValid: false,
                message: i18next.t('requestObjects.InviteUserRequest.atLeastOneRole'),
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }
    
}

export default InviteUserRequest