import faker from "faker"
import { map } from "lodash"

class FakerApiService {

    init = ({limit = 5, isList, properties, dataProperties}) => {
        this.limit = limit
        this.isList = isList
        this.properties = properties
        this.dataProperties = dataProperties
    }

    /**
     * Create an array of [limit] items
     * 
     */
    fetchList = () => {
        let items = new Array(this.limit)

        return map(items, _ => this.fetchObject())
    }
    
    /**
     * Create an Object using the faker properties provide
     * 
     */
    fetchObject = () => {
        return this.properties.reduce((acc, p) => { 
            acc[p.name] = faker[p.parent][p.type]()
            return acc
        }, {})
    }

    fetchData = () => {
        return this.isList ? this.fetchList() : this.fetchObject()
    }
}

export default FakerApiService