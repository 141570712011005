import React, { useEffect, useState } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid, Form } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import { useTranslation } from 'react-i18next'
import PhoneNumberInput from '../../partials/PhoneNumberInput'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'

export default function ContactMorphed({ item }) {

    const [data, setData] = useState({
        firstName: item.data ? item.data.firstName : "",
        lastName: item.data ? item.data.lastName : "",
        email: item.data ? item.data.email : "",
        jobTitle: item.data ? item.data.jobTitle : "",
        phoneNumber: item.data ? item.data.phoneNumber : "",
        originalId: item?.data?.originalId
    })

    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({
            item,
            data: {
                ...data,
                previewText: data.jobTitle
            }
        }))
        return () => { }
    }, [data, dispatch])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({ ...data, [name]: value })
    }

    const handlePhoneChange = (value) => {
        setData({ ...data, phoneNumber: value })
    }

    return (
        <BaseMorphed item={item} render={({ errors }) => (
            <Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field
                                required
                                error={!getError("first_name", errors).isValid}
                                className="input-container theme-color">
                                <label className='label'>{t('messageBuilder.morphedTemplates.ContactMorphed.input.name.placeholder')}</label>
                                <Input
                                    name="firstName"
                                    value={data.firstName}
                                    onChange={handleInputChange}
                                    autoComplete="none"
                                    placeholder={t('messageBuilder.morphedTemplates.ContactMorphed.input.name.placeholder')} />
                            </Form.Field>
                            <ErrorLabel noMargin error={getError("first_name", errors)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Form.Field
                                required
                                error={!getError("last_name", errors).isValid}
                                className="input-container theme-color">
                                <label className='label'>{t('messageBuilder.morphedTemplates.ContactMorphed.input.surname.placeholder')}</label>
                                <Input
                                    name="lastName"
                                    value={data.lastName}
                                    onChange={handleInputChange}
                                    autoComplete="none"
                                    placeholder={t('messageBuilder.morphedTemplates.ContactMorphed.input.surname.placeholder')} />
                            </Form.Field>
                            <ErrorLabel noMargin error={getError("last_name", errors)} />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="input-container theme-color">
                                <label className='label'>{t('messageBuilder.morphedTemplates.ContactMorphed.input.jobTitle.placeholder')}</label>
                                <Input
                                    name="jobTitle"
                                    value={data.jobTitle}
                                    onChange={handleInputChange}
                                    autoComplete="none"
                                    placeholder={t('messageBuilder.morphedTemplates.ContactMorphed.input.jobTitle.placeholder')} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="input-container theme-color">
                                <Grid style={{ marginBottom: 5 }}>
                                    <Grid.Column floated='left' width={5}>
                                        <label className='label'>{t('messageBuilder.morphedTemplates.ContactMorphed.input.phoneNumber.placeholder')}</label>
                                    </Grid.Column>
                                </Grid>
                                <PhoneNumberInput
                                    name="phoneNumber"
                                    defaultCountry="ZA"
                                    defaultValue={data.phoneNumber}
                                    onChange={handlePhoneChange}
                                    title={null}
                                    placeholder={t('messageBuilder.morphedTemplates.ContactMorphed.input.phoneNumber.placeholder')} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div className="input-container theme-color">
                                <Grid>
                                    <Grid.Column floated='left' width={5}>
                                        <label className='label'>{t('messageBuilder.morphedTemplates.ContactMorphed.input.email.placeholder')}</label>
                                    </Grid.Column>
                                </Grid>
                                <Input
                                    autoComplete="none"
                                    style={{ marginTop: 5 }}
                                    name="email"
                                    value={data.email}
                                    onChange={handleInputChange}
                                    placeholder={t('messageBuilder.morphedTemplates.ContactMorphed.input.email.placeholder')} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        )} />
    )
}
