import React, { Fragment } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import BaseIconCard from './BaseIconCard'
import BaseTemplate from './BaseTemplate'

const Date = ({ item }) => {

    return (
        <BaseTemplate classNames="no-padding no-auto-height icon-card-container" render={() => (
            <BaseIconCard
                icon="calendar check outline"
                content={() => (
                    <Fragment>
                        <Grid>
                            <Grid.Row  className="no-margin">
                                <Grid.Column width={7}>
                                    <p className="no-margin black-text">{item.data.previewStartDate}</p>
                                    <p className="no-margin primary-color-text"><strong>{item.data.previewStartTime}</strong></p>
                                </Grid.Column>
                                <Grid.Column width={2} verticalAlign="middle" className="no-padding">
                                    <h1 className="no-margin black-text left-align">-</h1>
                                </Grid.Column>
                                <Grid.Column  width={7}>
                                    <p className="no-margin black-text">{item.data.previewEndDate}</p>
                                    <p className="no-margin primary-color-text"><strong>{item.data.previewEndTime}</strong></p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Fragment>
                )} />
        )} />
    )
}

export default Date
