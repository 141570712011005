import { pickBy, identity, size } from "lodash"

class ListChannelMembersRequest {

    constructor({
        sort, 
        page,
        search,
        tagIds,
        perPage,
        channelId,
    }) {
        this.page = page
        this.sort = sort
        this.tagIds = tagIds
        this.search = search
        this.perPage = perPage
        this.channelId = channelId
    }


    getPayload = () => {
        
        const payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "per_page": this.perPage.value,
        }

        if (size(this.tagIds) > 0) {
            payload["tag_ids"] = this.tagIds
        }

        return pickBy(payload, identity)
    }
    
}

export default ListChannelMembersRequest