import { pickBy, identity } from "lodash"

class ListUsersRequest {

    constructor({
        sort, 
        page,
        search,
        perPage,
        channelIds,
        communityId,
        channelRoleIds,
        communityRoleIds,
    }) {
        this.page = page
        this.sort = sort
        this.search = search
        this.perPage = perPage
        this.channelIds = channelIds
        this.communityId = communityId
        this.channelRoleIds = channelRoleIds
        this.communityRoleIds = communityRoleIds
    }


    getPayload = () => {
        
        let payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "per_page": this.perPage.value,
            "channel_ids": [].concat(this.channelIds).join(","),
            "community_id": this.communityId,
            "channel_role_ids": [].concat(this.channelRoleIds).join(","),
            "community_role_ids": [].concat(this.communityRoleIds).join(","),
        }

        return pickBy(payload, identity)
    }
    
}

export default ListUsersRequest