/**
 * 
 * @typedef Request
 * @property {String|null} sort
 * @property {String|null} search
 * @property {String} endDate
 * @property {Number} page
 * @property {Number} perPage
 * @property {String} statDate
 * @property {Array} channelIds
 * @property {String} communityId
 */

import ListEventsRequest from "./ListEventsRequest"
import { dropEmptyKeys, filterNulls } from "../../utils/helper"
import { identity, pickBy } from "lodash"

class SearchEventsRequest extends ListEventsRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        sort,
        page,
        search,
        perPage,
        endDate,
        startDate,
        channelIds,
        communityId,
    }) {
        super({
            sort,
            search,
            endDate,
            startDate,
            channelIds,
            communityId,
        })

        this.page = page
        this.perPage = perPage
    }

    getPayload = () => {
        
        const payload = {
            "sort": this?.sort ?? 'start_date',
            "search": this?.search,
            "per_page": this?.perPage ?? 25,
            "page": this?.page ?? 1,
            "community_id": this?.communityId,
        }
        
        if (this.channelIds.length > 0) {
            payload["channel_ids"] = this.channelIds
        }

        return dropEmptyKeys(payload)
    }
    
}

export default SearchEventsRequest