import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { Icon, Popup } from 'semantic-ui-react'

const propTypes = {
    disabled: PropTypes.bool,
    highlighted: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired
}

const defaultProps = {
    disabled: false,
    highlighted: false,
    label: ''
}

const ListItem = (props) => {

    const handleClick = () => {
        
        if (props.disabled) {
            return
        }

        const {
            onClick,
            value
        } = props

        onClick(value)
    }

    const {
        disabled,
        highlighted,
        label,
        disabledReason
    } = props

    const className = 'msts__list-item'

    return (
        <li
            className={classNames(className, disabled && `${className}_disabled`, highlighted && `${className}_highlighted`)}
            onClick={handleClick}>
            {label}
            {!isEmpty(disabledReason) && (
                <Popup
                    basic
                    size="small"
                    trigger={
                        <Icon className="info circle info-icon community-roles float-right" />
                    }
                    content={disabledReason}
                />
            )}
        </li>
    )

}

ListItem.propTypes = propTypes
ListItem.defaultProps = defaultProps

export default ListItem