import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import routes from '../routes/route-constants'
import { Button, Grid, Icon, Loader } from 'semantic-ui-react'
import { builderActions } from '../actions/builderActions'
import { find, findIndex, has, map } from 'lodash'
import { builderConstants, relationshipConstants } from '../constants'
import { goBack, push } from 'connected-react-router'
import { useTranslation } from 'react-i18next'
import { appendCommunity } from '../utils/helper'

import LogoTeal from './partials/LogoTeal'

const MessageBuilderRedirector = () => {

  const auth = useSelector(state => state.auth)
  const router = useSelector(state => state.router)
  const builder = useSelector(state => state.builder)
  const navigation = useSelector(state => state.navigation)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  // onMount/onUpdate
  useEffect(() => {
    // Add loading class to add image background
    document.body.classList.add('loading')

    return () => {
      document.body.classList.remove('loading')
    }

  }, [])

  // Use a sideEffect to load the templates
  useEffect(() => {
  
    if (!navigation.communityId) return;
  
    dispatch(builderActions.startTemplates())
    dispatch(builderActions.fetchTemplates({
      communityId: navigation.communityId
    }))
    
  }, [navigation.communityId])

  // Once the builder is finished loading we need to access the template data
  // this is so we can be able to redirect accordingly
  useEffect(() => {
    
    if (!has(builder._templates, "community")) return;
    
    const column = {...builder.templates.columns[builderConstants.templateArea]}
    const tasks = map(column.taskIds, taskId => builder.templates.tasks[taskId])
    
    const templateId = router.location.query.templateId

    const templateIndex = findIndex(tasks, ['id', templateId])
    
    const template = find(tasks, {'id': templateId})

    if (!template) {
      throw new Error(t("general.errors.messageBuilder.template", {
        templateId: templateId
      }))
    }

    dispatch(builderActions.composeTemplateDynamic({
      id: templateId,
      is_custom: null,
      index: templateIndex,
      noticeTitle: template.notice_title ?? "",
    }))

    dispatch(builderActions.saveTemplateEditableMessage({
        template: {...template},
        builderData: {
          community: navigation.community,
          users: template.users ?? [],
          title: template.notice_title ?? "",
          persons: template.persons ?? [],
          channels: template.channels ?? [],
          components: template.components ?? [],
          important: template.important ?? false,
          target_everyone: template.target_everyone ?? false,
          publish_relationship: template.publish_relationship ?? null,
          send_deadline_reminders: template.send_deadline_reminders ?? false,
          deadline_at: template.deadline_at,
        }
    }))

    // Redirect to notice builder
    let url = appendCommunity(
      navigation.communityId, 
      routes.private.CREATE_NOTICE
    ).concat('?templateId=').concat(templateId)

    if (router.location?.state?.readOnlyBuilder) {
      url = url.concat('&readOnlyBuilder=1')
    }

    const originals = map(template.tasks, 'data.originalId')
    dispatch(push(url, {
      originals: originals
    }))
    
  }, [builder._templates])


  const renderLoaderState = () => {
    
    if (auth.loading) {
      return <Loader inverted active content={t("general.loading")} />
    }
    
    if (builder.loadingTemplates) {
      return <Loader inverted active content={t("general.loadingTemplates")} />
    }
  }

  const handleNavigateHome = () => {
    
    // HARD Refresh
    window.location.href = appendCommunity(
      navigation.communityId,
      routes.private.HOME_COMM
    )
  }
  
  return (
    <div className='ui middle aligned center aligned grid no-auth full-ui-block data-init-screen'>
      <div className="loader-container">
        <Loader 
          inverted 
          active 
          content={
            <Fragment>
              <LogoTeal />
              {auth.loading && t("general.loading")}
              {builder.loadingTemplates && t("general.loadingTemplates")}
              {(!builder.loadingTemplates && builder.errors) &&(
                <div className='error-explanation'>
                  { builder.errors === "This action is unauthorized." ? (<p className='error-message'>{t("error.notAllowedError")}</p>) : (
                    <p className='error-message'>{t("error.errorOccurredCrash")}</p>
                  )}
                  <Grid className='mg-top-10'>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Button className='primary' onClick={handleNavigateHome}>{t('general.takeMeHome')}</Button>
                        <Button className='primary' onClick={() => dispatch(goBack())}><Icon name='arrow left' />{t('general.back')}</Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                )}
            </Fragment>
          } />
      </div>
    </div>
  )

}

export default MessageBuilderRedirector