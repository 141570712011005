import React, { useState, Fragment } from 'react'
import { Checkbox, Form, Grid, Input } from 'semantic-ui-react'

export default function YesNoQuestionPreview({item}) {
    
    return (
        <Form>
            <Form.Field>
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='mg-bot-10 black-text'>
                                {item.data.question} {item.data.isRequired && <span style={{marginLeft: 5}} className="required-star secondary-color-text">*</span>}
                            </label>
                            <Grid columns="2">
                                <Grid.Column width="8">
                                    <Checkbox checked={false} radio label={"Yes"} />
                                </Grid.Column>
                                <Grid.Column width="8">
                                    <Checkbox checked={false} radio label={"No"} />
                                </Grid.Column>
                            </Grid>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Form.Field>
        </Form>
    )
}
