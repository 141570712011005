import React, { useContext, useEffect, useState } from 'react'
import SideNavigation from '../views/partials/SideNavigation'
import Content from '../views/partials/Content'
import { withTranslation } from 'react-i18next'
import TopNavigation from '../views/partials/TopNavigation'
import ErrorFallback from '../views/partials/ErrorFallback'
import BuilderSideNavigation from '../views/partials/BuilderSideNavigation'
import BuilderSidePreview from '../views/message-builder/BuilderSidePreview'
import { DragDropContext } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'

import { navigationActions } from "../actions/navigationActions"
import { builderActions } from '../actions/builderActions'
import AuthenticatedRoutes from '../routes/AuthenticatedRoutes'
import routes from '../routes/route-constants'
import { appendCommunity, isLocaleRtl, matchLink } from '../utils/helper'
import 'react-phone-number-input/style.css'
import DefaultsContext from '../context/DefaultsContext'
import { matchPath, Route, Switch } from 'react-router'
import { map } from 'lodash'
import NoLink from '../views/NoLink'
import AcceptInvite from '../views/AcceptInvite'
import NotAllowed from '../views/NotAllowed'
import Dinero from 'dinero.js'

import { getISO4217byCode } from "../utils/IntlCurrencyInput/format-currency"
import i18next from 'i18next'
import { push } from 'connected-react-router'
import BuilderTopNavigation from '../views/partials/BuilderTopNavigation'
import { ErrorBoundary } from 'react-error-boundary'
import GoogleRedirectCallback from '../views/GoogleRedirectCallback'
import LinkCalendarChooser from '../views/calendar/LinkCalendarChooser'
import ReAuthCalendar from '../views/calendar/ReAuthCalendar'
import CreateCalendar from '../views/calendar/CreateCalendar'
import CreateCalendarICal from '../views/calendar/CreateCalendarICal'
import LogoutRedirect from '../views/LogoutRedirect'

function MasterAuthLayout() {

  const context = useContext(DefaultsContext)
  const router = useSelector(state => state.router)
  const showPreview = useSelector(state => state.builder.showPreview)
  const navigation = useSelector(state => state.navigation)
  const collapsed = useSelector(state => state.navigation.collapsed)
  const toggled = useSelector(state => state.navigation.mdCollapsed)
  const showBuilderSidebar = useSelector(state => state.navigation.showBuilderSidebar)
  const collapsedBuilderSidebar = useSelector(state => state.navigation.collapsedBuilderSidebar)
  const [community, setCommunity] = useState(null)
  const [dragging, setDragging] = useState(false)
  
  const [isRtl] = useState(isLocaleRtl(navigation.locale).valid)
  const [localeDir] = useState(isLocaleRtl(navigation.locale).dir)
  
  const dispatch = useDispatch()

  const match = matchPath(
    router.location.pathname, {
    path: map(routes.private).filter(
      x => ![routes.private.HOME].includes(x)
    )
  }) ?? { params: {} }

  const handleCollapsedChange = (checked) => dispatch(navigationActions.toggleSidebar(checked))
  const handleCollapsedChangeMobileScreen = (checked) => dispatch(navigationActions.toggleMdSidebar(checked))
  const handleBuilderSideCollapsedChange = (checked) => dispatch(navigationActions.toggleBuilderSidebar(checked))

  /**
   * When dragging starts, we should freeze the scroll container on the builder sidebar
   * by adding a class on the main container
   * 
   * @param {*} initial 
   */
  const onDragStart = (initial) => {
    setDragging(true)
  }

  const onDragEnd = (result, provided) => {

    const { destination, source, draggableId } = result

    setDragging(false)

    if (!destination) {
      return
    }

    // When the dragging is a SubItem drag, we should only re-order the items
    if (result.type.includes("droppableSubItem")) {
      // We need this behaviour to be handled by the local state of the component that has fired this dnd event
      dispatch(builderActions.broadcastGeneral(result))
      return
    }
    
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // Handle On item drop
    dispatch(builderActions.composeTemplate(result))
  }

  useEffect(() => {

    if (!navigation.defaults) return;

    context.initDefaults(navigation.defaults)

    return () => { }
  }, [navigation.defaults])

  useEffect(() => {

    // When there is a Community already  selected, we will use that as the default

    let _community = match.params.communityId ?? navigation.communityId

    if ([routes.public.LOGOUT_REDIRECT.replace("/", "")].includes(_community)) {
      _community = navigation.communityId
    }

    setCommunity(_community)
    dispatch(navigationActions.selectCommunity(_community))

  }, [])

  useEffect(() => {

    if (!community) {
      setCommunity(navigation.communityId)
    }

    if (navigation.community) {
      // Initialize DineroJS defaults
      const iso4217currency = getISO4217byCode(navigation.community.payment_currency_iso_4217)

      Dinero.globalLocale = i18next.language
      Dinero.defaultCurrency = iso4217currency.Code
      Dinero.defaultPrecision = parseInt(iso4217currency.D)

      // when a blocked community is selected, we should show the blocked view
      if (!navigation.community.portal_accessible) {

        // We need to ignore this logic for routes like Invite and Not Linked
        if (matchLink({
          pathname: router.location.pathname, patterns: [
            routes.private.INVITE,
            routes.private.NO_LINK,
            routes.private.NOT_ALLOWED,
            routes.private.LOGOUT_REDIRECT,
          ]
        })) { return }

        if (navigation.community.portal_accessible === false) {
          dispatch(push(
            appendCommunity(navigation.communityId, routes.private.BLOCKED)
          ))
        }
      }
    }



  }, [navigation.communityId])

  const handleNavigation = () => {
    
    // HARD Refresh
    window.location.href = appendCommunity(
      navigation.communityId,
      routes.private.HOME_COMM
    )
  }

  // When user does not exist in the system
  // When a user has no content, we can show a different UI
  if (matchLink({
    pathname: router.location.pathname, patterns: [
      routes.private.INVITE,
      routes.private.NO_LINK,
      routes.private.NOT_ALLOWED,
      routes.private.LOGOUT_REDIRECT,
      routes.private.CALENDAR_CREATE,
      routes.private.CALENDAR_CREATE_ICAL,
      routes.private.CALENDAR_CHOOSE_SYNC_METHODS,
      // routes.private.UPDATE_DETAILS,
    ]
  })) {
    return (
      <div className={`ui middle aligned center aligned grid no-auth`}>
        <Switch>
          <Route
            path={routes.private.NO_LINK}
            exact={true}
            component={NoLink} />

          <Route
            path={routes.private.INVITE}
            exact={true}
            component={AcceptInvite} />
          
          <Route
            path={routes.private.NOT_ALLOWED}
            exact={true}
            component={NotAllowed} />
          
          <Route
            path={routes.private.CALENDAR_CREATE}
            exact={true}
            component={CreateCalendar} />
          
          <Route
            path={routes.private.CALENDAR_CREATE_ICAL}
            exact={true}
            component={CreateCalendarICal} />
          
          <Route
            path={routes.private.CALENDAR_CHOOSE_SYNC_METHODS}
            exact={true}
            component={LinkCalendarChooser} />
          
          <Route
            path={routes.private.CALENDAR_RE_AUTH}
            exact={true}
            component={ReAuthCalendar} />
          
          <Route
            path={routes.private.LOGOUT_REDIRECT}
            exact={true}
            component={LogoutRedirect} />
        </Switch>
      </div>
    )
  }

  return (

    <ErrorBoundary
      onReset={handleNavigation}
      FallbackComponent={ErrorFallback}>
      <div className={`app ${dragging ? 'main-is-dragging' : ''} ${localeDir}`}>
        <TopNavigation
          collapsed={collapsed}
          toggled={toggled}
          params={{
            communityId: navigation.communityId,
          }}
          handleToggleChange={handleCollapsedChangeMobileScreen}
          handleCollapsedChange={handleCollapsedChange} />

        <SideNavigation
          collapsed={collapsed}
          toggled={toggled}
          rtl={isRtl}
          handleToggleChange={handleCollapsedChangeMobileScreen}
          handleCollapsedChange={handleCollapsedChange}
        />

        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}>

          {/* {showBuilderSidebar && (
          <BuilderSideNavigation
            collapsed={collapsedBuilderSidebar}
            mainCollapse={collapsed}
            toggled={toggled}
            handleCollapsedChange={handleBuilderSideCollapsedChange}
          />
        )} */}

          <Content
            collapsed={collapsed}
            handleCollapsedChange={handleCollapsedChange}>
            <AuthenticatedRoutes communityId={navigation.communityId} />
          </Content>

        </DragDropContext>
        
        <div id='portal-menu-portal' />
        <BuilderSidePreview
          visible={showPreview} />

      </div>
    </ErrorBoundary>
  )
}

export default withTranslation()(MasterAuthLayout)
