import React from 'react'
import PaymentItem from './PaymentItem'
import FormItem from './FormItem'
import { templateIcons } from '../../../constants'
import ReadItem from './ReadItem'

export default function RecipientHoverItem({hoverItem}) {
	
    if (hoverItem.type === 'reads') {
        return <ReadItem hoverItem={hoverItem} />
    }
    
    if (hoverItem.type === templateIcons.payment) {
        return <PaymentItem hoverItem={hoverItem} />
    }
    
    return <FormItem hoverItem={hoverItem} />
}
