import { RRule, Weekday } from "rrule"
import i18next from "../i18n"
import { map } from "lodash"
import { weekdaysMin } from "moment"

// Constants that can be used for anything, from LocalStorage Keys, to icon types
export const constants = {
    
    // LOCAL STORAGE KEYS
    STORAGE_USER:       'user',

    // Access Token
    STORAGE_ACCESS_TOKEN: 'storage_access_token',
    
    // Access Token For Relay Uploads
    STORAGE_UPLOADS_ACCESS_TOKEN: 'storage_uploads_access_token',
    STORAGE_UPLOADS_REFRESH_TOKEN: 'storage_uploads_refresh_token',
    
    // Selected community
    STORAGE_SELECTED_COMMUNITY: 'storage_selected_community',
    
    EXPERIMENTAL_FEATURE_FLAGS: 'storage_experimental_flag',

    //  Table Pagination
    PAGINATION_DEFAULTS : [
        {text: '25', value: 25}, 
        {text: '50', value: 50},
        {text: '75', value: 75},
        {text: '100', value: 100}
    ],

    //  Table keys
    TABLE_KEY_ICON: 'icon',
    TABLE_KEY_TAGS: 'tags',
    TABLE_KEY_IMAGE: 'image',
    TABLE_KEY_ROLES: 'roles',
    TABLE_KEY_STATUS: 'status',
    TABLE_KEY_ACTIONS: 'actions',
    TABLE_KEY_CHECKBOX: 'checkbox',
    TABLE_KEY_HOVERABLE: 'hoverable',
    TABLE_KEY_SYNC_STATUS: 'sync_status',
    TABLE_KEY_CUSTOM_ICON: 'custom-icon',
    TABLE_KEY_DISTRIBUTION: 'distribution',
    TABLE_KEY_RECIPIENT_TAGS: 'recipient-tags',
    TABLE_KEY_HOVERABLE_DUAL: 'hoverable-dual',
    TABLE_KEY_TAGS_GENERIC_HOVER: 'tags-hover',
    TABLE_KEY_PARENT_LINK_ICON: 'parent-link-icon',
    TABLE_KEY_HOVERABLE_COUNT: 'tags-hover-count-only',
    TABLE_KEY_CUSTOM_ICON_DYNAMIC: 'custom-icon-dynamic',
    TABLE_KEY_TAGS_GENERIC_HOVER_COUNT: 'tags-hover-count',
    TABLE_KEY_CUSTOM_ICON_DUAL_STATE: 'custom-icon-dual-state',
    TABLE_KEY_AUDIT_HISTORY_ACTION: 'audit-html',
    TABLE_KEY_AUDIT_HISTORY_AUTHOR: 'audit-author',

    // Table action menu types
    MENU_ACTION_DELETE:      0,
    MENU_ACTION_DISABLE:     1,
    MENU_ACTION_ENABLE:      2,
    MENU_ACTION_COPY:        3,
    MENU_ACTION_REMOVE:      4,
    MENU_ACTION_VIEW:        5,
    MENU_ACTION_RESEND:      6,
    MENU_ACTION_SUSPEND:     7,
    MENU_ACTION_DOWNLOAD:    8,
    MENU_ACTION_EDIT:        9,
    MENU_ACTION_SYNC:        10,
    MENU_ACTION_MANAGE:      11,
    MENU_ACTION_REAUTHENTICATE: 12,

    // Message Status
    NOTICE_PUBLISHED:       "published",

    // Notice Recipient Group By Filter
    RECIPIENT_GROUP_BYS: {
        GROUP_BY_ROOT_ONLY: 'root_only', // All Recipients
        GROUP_BY_PARENT_CHILD: 'parent_child', // Parent to Child
        GROUP_BY_PARENTS_ONLY: 'parents_only', // Parents Only
        GROUP_BY_CHILDREN_ONLY: 'children_only', // Children Only
        GROUP_BY_CHILD_PARENT: 'child_parent', // Child to Parent
    },

    INTERACTIONS: {
        READ: 'read', // Read
        UNREAD: 'unread', // Unread
        PAID: 'paid', // Paid
        ANSWERED: 'answered', // Answered
        REMINDED: 'reminded', // Reminded
        NOT_PAID: 'not_paid', // Not Paid
        NOT_ANSWERED: 'not_answered', // Not Answered
        NOT_REMINDED: 'not_reminded', // Not Reminded
    }
}


// Action Type constants which we will use mostly from our reducers
export const actions = {
    DEFAULT: 'DEFAULT',

    AUTH_START:          'AUTH_START',
    AUTH_ERROR:          'AUTH_ERROR',
    AUTH_SUCCESS:        'AUTH_SUCCESS',
    AUTH_SAVE_BILLING:    'AUTH_SAVE_BILLING',
    AUTH_SAVE_ACCOUNT:    'AUTH_SAVE_ACCOUNT',
    AUTH_CLEAR_SETTINGS:   'AUTH_CLEAR_SETTINGS',
    AUTH_SAVE_CONTACTS:   'AUTH_SAVE_CONTACTS',
    AUTH_END_LOADING:    'AUTH_END_LOADING',
    AUTH_FINISH_INIT:    'AUTH_FINISH_INIT',
    AUTH_ERROR_UPDATE:   'AUTH_ERROR_UPDATE',
    AUTH_SAVE_PROFILE:   'AUTH_SAVE_PROFILE',
    AUTH_SAVE_SSO_USER:  'AUTH_SAVE_SSO_USER',
    AUTH_CLEAR_MESSAGES: 'AUTH_CLEAR_MESSAGES',
    AUTH_SAVE_RETURN_URL:  'AUTH_SAVE_RETURN_URL',
    AUTH_SAVE_INVITE_DETAILS: 'AUTH_SAVE_INVITE_DETAILS',
    
    NOTICE: {
        SAVE:  'NOTICES_SAVE',
        START: 'NOTICES_START',
        ERROR: 'NOTICES_ERROR',
        RESET: 'NOTICES_RESET',
        SUCCESS: 'NOTICES_SUCCESS',
        SAVE_FILTERS: 'NOTICES_SAVE_FILTERS',
        CLEAR_MESSAGES: 'NOTICES_CLEAR_MESSAGES',
        START_GRAPHS_LOAD: 'NOTICES_START_GRAPHS_LOAD',
        SAVE_NOTICE_DETAILS: 'NOTICES_SAVE_NOTICE_DETAILS',
        START_RECIPIENT_LOAD: 'NOTICES_START_RECIPIENT_LOAD',
        CLEAR_NOTICE_DETAILS: 'NOTICES_CLEAR_NOTICE_DETAILS',
        SAVE_NOTICE_GRAPH_DETAILS: 'NOTICES_SAVE_NOTICE_GRAPH_DETAILS',
        SAVE_NOTICE_MANUAL_PAYMENTS: 'NOTICES_SAVE_NOTICE_MANUAL_PAYMENTS',
        SAVE_NOTICE_RECIPIENT_DETAILS: 'NOTICES_SAVE_NOTICE_RECIPIENT_DETAILS',
        SAVE_NOTICE_DETAILS_RECIPIENTS: 'NOTICES_SAVE_NOTICE_DETAILS_RECIPIENTS',
        SAVE_NOTICE_DETAILS_RECIPIENTS_FILTERS: 'NOTICES_SAVE_NOTICE_DETAILS_RECIPIENTS_FILTERS',
    },
    
    BUILDER: {
        START:          'BUILDER_START',
        ERROR:          'BUILDER_ERROR',
        SUCCESS:        'BUILDER_SUCCESS',
        SHOW_PREVIEW:   'BUILDER_SHOW_PREVIEW',
        HIDE_PREVIEW:   'BUILDER_HIDE_PREVIEW',
        SAVE_TEMPLATE:  'BUILDER_SAVE_TEMPLATE',
        TOGGLE_PREVIEW: 'BUILDER_TOGGLE_PREVIEW',
        SAVE_TEMPLATES: 'BUILDER_SAVE_TEMPLATES',
        SAVE_CHANNELS:  'BUILDER_SAVE_CHANNELS',
        CLEAR_MESSAGES: 'BUILDER_CLEAR_MESSAGES',
        SAVE_RECIPIENTS:'BUILDER_SAVE_RECIPIENTS',
        START_TEMPLATES:'BUILDER_START_TEMPLATES',
        START_EDIT_MESSAGE:  'BUILDER_START_EDIT_MESSAGE',
        SAVE_EDIT_MESSAGE:  'BUILDER_SAVE_EDIT_MESSAGE',
        SHOW_PUBLISH_MODAL:  'BUILDER_SHOW_PUBLISH_MODAL',
        CLEAR_PUBLISH_MODAL: 'BUILDER_CLEAR_PUBLISH_MODAL',
        SAVE_RESOURCE_GROUPS:  'BUILDER_SAVE_RESOURCE_GROUPS',
        SAVE_EDIT_TEMPLATE_MESSAGE:  'BUILDER_SAVE_EDIT_TEMPLATE_MESSAGE',
        
        COMPOSE_CLEAR:                'BUILDER_COMPOSE_CLEAR',
        COMPOSE_TEMPLATE:             'BUILDER_COMPOSE_TEMPLATE',
        COMPOSE_CLEAR_EDIT:           'BUILDER_COMPOSE_CLEAR_EDIT',
        COMPOSE_DELETE_ITEM:          'BUILDER_COMPOSE_DELETE_ITEM',
        COMPOSE_UPDATE_ITEMS:         'BUILDER_COMPOSE_UPDATE_ITEMS',
        COMPOSE_SAVE_TEMPLATE:        'BUILDER_COMPOSE_SAVE_TEMPLATE_DATA',
        COMPOSE_DUPLICATE_ITEM:       'BUILDER_COMPOSE_DUPLICATE_ITEM',
        COMPOSE_TEMPLATE_DYNAMIC:     'BUILDER_COMPOSE_TEMPLATE_DYNAMIC',
        COMPOSE_COMPONENT_DYNAMIC:    'BUILDER_COMPOSE_COMPONENT_DYNAMIC',
        COMPOSE_SAVE_TEMPLATE_TITLE:  'BUILDER_COMPOSE_SAVE_TEMPLATE_TITLE',
        COMPOSE_SAVE_CUSTOM_MESSAGE:  'BUILDER_COMPOSE_SAVE_CUSTOM_MESSAGE',
        COMPOSE_SAVE_LIST_ITEM_PREVIEW:  'BUILDER_COMPOSE_SAVE_LIST_ITEM_PREVIEW',
        
        BUSY_INDICATOR:  'BUILDER_BUSY_INDICATOR',
        
        GENERAL_DISPATCHER:            'BUILDER_GENERAL_DISPATCHER',
        CLEAN_GENERAL_DISPATCHER:      'BUILDER_CLEAN_GENERAL_DISPATCHER',
        ASSISTED_WRITING:  'BUILDER_ASSISTED_WRITING',
    },

    NAVIGATION: {
        SHOW_SIDEBAR:                'NAVIGATION_SHOW_SIDEBAR', 
        HIDE_SIDEBAR:                'NAVIGATION_HIDE_SIDEBAR',
        SELECT_COMMUNITY:            'NAVIGATION_SELECT_COMMUNITY',
        SAVE_TO_HASH_ROUTER:         'NAVIGATION_SAVE_TO_HASH_ROUTER', 
        SHOW_BUILDER_SIDEBAR:        'NAVIGATION_SHOW_BUILDER_SIDEBAR', 
        TOGGLE_ACTIVE_LOCALE:        'NAVIGATION_TOGGLE_ACTIVE_LOCALE',
        HIDE_BUILDER_SIDEBAR:        'NAVIGATION_HIDE_BUILDER_SIDEBAR',
        TOGGLE_SIDEBAR_VISIBILITY:   'NAVIGATION_TOGGLE_SIDEBAR_VISIBILITY',
        TOGGLE_MD_SIDEBAR_VISIBILITY:'NAVIGATION_TOGGLE_MD_SIDEBAR_VISIBILITY',
        TOGGLE_BUILDER_SIDEBAR_VISIBILITY:   'NAVIGATION_TOGGLE_BUILDER_SIDEBAR_VISIBILITY',
    },

    USERS: {
        SAVE:  'USERS_SAVE',
        START: 'USERS_START',
        ERROR: 'USERS_ERROR',
        RESET: 'USERS_RESET',
        SUCCESS: 'USERS_SUCCESS',
        LOAD_FILTERS: 'USERS_LOAD_FILTERS',
        SAVE_FILTERS: 'USERS_SAVE_FILTERS',
        SAVE_INVITES: 'USERS_SAVE_INVITES',
        CLEAR_MESSAGES: 'USERS_CLEAR_MESSAGES',
        SAVE_USER_DETAILS: 'USERS_SAVE_USER_DETAILS',
        CLEAR_USER_DETAILS: 'USERS_CLEAR_USER_DETAILS',
        SAVE_INVITE_FILTERS: 'USERS_SAVE_INVITE_FILTERS',
        
        SAVE_MEMBERS: 'USERS_SAVE_MEMBERS',
        SAVE_MEMBER_DETAILS: 'USERS_SAVE_MEMBER_DETAILS',
        SAVE_MEMBER_FILTERS: 'USERS_SAVE_MEMBER_FILTERS',
        SAVE_INVITE_DETAILS: 'USERS_SAVE_INVITE_DETAILS',
        CLEAR_MEMBER_DETAILS: 'USERS_CLEAR_MEMBER_DETAILS',
    },

    CHANNELS: {
        SAVE:  'CHANNELS_SAVE',
        START: 'CHANNELS_START',
        ERROR: 'CHANNELS_ERROR',
        RESET: 'CHANNELS_RESET',
        SUCCESS: 'CHANNELS_SUCCESS',
        SAVE_FILTERS: 'CHANNELS_SAVE_FILTERS',
        CLEAR_MESSAGES: 'CHANNELS_CLEAR_MESSAGES',
        START_MEMBER_LOAD: 'CHANNELS_START_MEMBER_LOAD',
        SAVE_CHANNEL_DETAILS: 'CHANNELS_SAVE_CHANNEL_DETAILS',
        CLEAR_CHANNEL_DETAILS: 'CHANNELS_CLEAR_CHANNEL_DETAILS',
        SAVE_CHANNEL_DETAILS_MEMBERS: 'CHANNELS_SAVE_CHANNEL_DETAILS_MEMBERS',
        SAVE_CHANNEL_DETAILS_ATTACHABLE_MEMBERS: 'CHANNELS_SAVE_CHANNEL_DETAILS_ATTACHABLE_MEMBERS',
        SAVE_CHANNEL_DETAILS_ATTACHABLE_MEMBERS_FILTERS: 'CHANNELS_SAVE_CHANNEL_DETAILS_ATTACHABLE_MEMBERS_FILTERS',
    },
    
    RESOURCES: {
        SAVE:  'RESOURCES_SAVE',
        SAVE_COMMUNITIES:  'RESOURCES_SAVE_COMMUNITIES',
        START: 'RESOURCES_START',
        ERROR: 'RESOURCES_ERROR',
        RESET: 'RESOURCES_RESET',
        SUCCESS: 'RESOURCES_SUCCESS',
        SAVE_FILTERS: 'RESOURCES_SAVE_FILTERS',
        CLEAR_MESSAGES: 'RESOURCES_CLEAR_MESSAGES',
        SAVE_RESOURCE_DETAILS: 'RESOURCES_SAVE_RESOURCE_DETAILS',
        SAVE_COMMUNITY_DETAILS: 'RESOURCES_SAVE_COMMUNITY_DETAILS',
        CLEAR_RESOURCE_DETAILS: 'RESOURCES_CLEAR_RESOURCE_DETAILS',
    },

    CALENDAR: {
        SAVE:  'CALENDARS_SAVE',
        START: 'CALENDARS_START',
        ERROR: 'CALENDARS_ERROR',
        RESET: 'CALENDARS_RESET',
        SUCCESS: 'CALENDARS_SUCCESS',
        END_DETAIL: 'CALENDARS_END_DETAIL',
        START_DETAIL: 'CALENDARS_START_DETAIL',
        SAVE_FILTERS: 'CALENDARS_SAVE_FILTERS',
        START_SOURCES: 'CALENDARS_START_SOURCES',
        CLEAR_MESSAGES: 'CALENDARS_CLEAR_MESSAGES',
        SAVE_SYNC_ACCOUNT: 'CALENDARS_SAVE_SYNC_ACCOUNT',
        SAVE_SYNC_ACCOUNTS: 'CALENDARS_SAVE_SYNC_ACCOUNTS',
        SAVE_CALENDAR_SOURCES: 'CALENDARS_SAVE_CALENDAR_SOURCES',
        SAVE_CALENDAR_DETAILS: 'CALENDARS_SAVE_CALENDAR_DETAILS',
        CLEAR_CALENDAR_DETAILS: 'CALENDARS_CLEAR_CALENDAR_DETAILS',
        SAVE_CALENDAR_EVENT_DETAILS: 'CALENDARS_SAVE_CALENDAR_EVENT_DETAILS',
    },
    
    EVENT: {
        SAVE:  'EVENTS_SAVE',
        START: 'EVENTS_START',
        START_LIST_VIEW: 'EVENTS_START_LIST_VIEW',
        ERROR: 'EVENTS_ERROR',
        RESET: 'EVENTS_RESET',
        SUCCESS: 'EVENTS_SUCCESS',
        END_DETAIL: 'EVENTS_END_DETAIL',
        START_DETAIL: 'EVENTS_START_DETAIL',
        SAVE_FILTERS: 'EVENTS_SAVE_FILTERS',
        SAVE_STATUSES:  'EVENTS_SAVE_STATUSES',
        SAVE_LIST_VIEW_EVENTS:  'EVENTS_SAVE_LIST_VIEW_EVENTS',
        RESET_LIST_VIEW_EVENTS:  'EVENTS_RESET_LIST_VIEW_EVENTS',
        CLEAR_MESSAGES: 'EVENTS_CLEAR_MESSAGES',
        SAVE_EVENT_DETAILS: 'EVENTS_SAVE_EVENT_DETAILS',
        CLEAR_EVENT_DETAILS: 'EVENTS_CLEAR_EVENT_DETAILS',
    },

    CONTACT: {
        SAVE:  'CONTACTS_SAVE',
        START: 'CONTACTS_START',
        ERROR: 'CONTACTS_ERROR',
        RESET: 'CONTACTS_RESET',
        SUCCESS: 'CONTACTS_SUCCESS',
        SAVE_FILTERS: 'CONTACTS_SAVE_FILTERS',
        CLEAR_MESSAGES: 'CONTACTS_CLEAR_MESSAGES',
        SAVE_CONTACT_DETAILS: 'CONTACTS_SAVE_CONTACT_DETAILS',
        CLEAR_CONTACT_DETAILS: 'CONTACTS_CLEAR_CONTACT_DETAILS',
    },
    
    CONTACT_CATEGORY: {
        SAVE:  'CONTACT_CATEGORIES_SAVE',
        START: 'CONTACT_CATEGORIES_START',
        ERROR: 'CONTACT_CATEGORIES_ERROR',
        RESET: 'CONTACT_CATEGORIES_RESET',
        SUCCESS: 'CONTACT_CATEGORIES_SUCCESS',
        SAVE_FILTERS: 'CONTACT_CATEGORIES_SAVE_FILTERS',
        CLEAR_MESSAGES: 'CONTACT_CATEGORIES_CLEAR_MESSAGES',
        SAVE_CONTACT_CATEGORY_DETAILS: 'CONTACT_CATEGORIES_SAVE_CONTACT_CATEGORY_DETAILS',
        CLEAR_CONTACT_CATEGORY_DETAILS: 'CONTACT_CATEGORIES_CLEAR_CONTACT_CATEGORY_DETAILS',
    },
    
    TABLE: {
        START:  'TABLE_START',
        RESET:  'TABLE_RESET',
        CLEAR_ALL:  'TABLE_CLEAR_ALL',
    },
    
    TIERING: {
        SHOW:  'TIERING_SHOW',
        START: 'TIERING_START',
        RESET: 'TIERING_RESET',
        ERROR: 'TIERING_ERROR',
        MESSAGES: 'TIERING_MESSAGES',
        SHOW_MINI:  'TIERING_SHOW_MINI',
        SAVE_LIMITS: 'TIERING_SAVE_LIMITS',
        CLEAR_MESSAGES: 'TIERING_CLEAR_MESSAGES',
        SAVE_BUILDER_LIMITS: 'TIERING_SAVE_BUILDER_LIMITS',
    },
}

// Templates have different types, we just adding them here so we have a truth system :)
export const templateIcons = {
    url: 'url',
    file: 'file',
    fileGroup: 'file_group',
    text: 'text',
    form: 'form',
    vote: 'vote',
    date: 'date',
    image: 'image',
    album: 'album',
    video: 'video',
    vimeo: 'vimeo_video',
    youTube: 'youtube_video',
    event: 'event',
    notice: 'notice',
    iframe: 'web_frame',
    contact: 'contact',
    payment: 'payment',
    deadline: 'deadline',
    location: 'location',
    attachment: 'attachment',
    noticeAttachment: 'notice_attachment',
    progressBar: 'progress_bar',
    customTemplate: 'custom-template',
    iframeInteractive: 'web_frame_interacted',
}

// Templates have different types, we just adding them here so we have a truth system :)
export const timelineComponents = {
    file: 'file_downloaded',
    iframe: 'web_frame_interacted',
    location: 'location_interacted',
    formTypes: {
        textField: {id: 0, name: i18next.t('constants.index.form.textField'), post_name: "response_text_answered"},
        yesNo: {id: 1, name: i18next.t('constants.index.form.yesNo'), post_name: "response_boolean_answered"},
        multipleChoice: {id: 2, name: i18next.t('constants.index.form.multipleChoice'), post_name: "response_choice_answered"},
        imageUpload: {id: 3, name: i18next.t('constants.index.form.imageUpload'), post_name: "response_image_answered"},
        fileUpload: {id: 4, name: i18next.t('constants.index.form.fileUpload'), post_name: "response_file_answered"},
    },
    form: 'form',
    voteTypes: {
        standard: {id: 0, name: i18next.t('constants.index.vote.standard'), post_name: "standard_answered"},
        zaSGB: {id: 1, name: i18next.t('constants.index.vote.sgbZA'), post_name: "za_sgb_answered"},
    },
    votingStatuses: {
        standard: {id: 0, name: i18next.t('constants.index.vote.standard'), post_name: "standard"},
        zaSGB: {id: 1, name: i18next.t('constants.index.vote.sgbZA'), post_name: "za_sgb"},
    },
    vote: 'voting_campaign_voted',
    payment: 'payment_paid',
    paymentTypes: {
        fixed: {id: 0, name: i18next.t('timeline.payment-fixed'), post_name: 'fixed'},
        open: {id: 1, name: i18next.t('timeline.payment-open'), post_name: 'open'},
        itemised: {id: 2, name: i18next.t('timeline.payment-itemised'), post_name: 'itemised'},
        partial: {id: 3, name: i18next.t('timeline.payment-partial'), post_name: 'partial'},
    },
    youTube: 'youtube_video_watched',
    image: 'image_viewed',
    album: 'album_opened',
    url: 'url_opened'
}

export const builderConstants = {
    contentArea: 'column-4',
    templateArea: 'column-1',
    componentArea: 'column-2',
    messageEditArea: 'column-5',
}

// Form Type Ids for consistency
export const formTypes = {
    textField: {id: 0, name: i18next.t('constants.index.form.textField'), post_name: "response_text"},
    yesNo: {id: 1, name: i18next.t('constants.index.form.yesNo'), post_name: "response_boolean"},
    multipleChoice: {id: 2, name: i18next.t('constants.index.form.multipleChoice'), post_name: "response_choice"},
    imageUpload: {id: 3, name: i18next.t('constants.index.form.imageUpload'), post_name: "response_image"},
    fileUpload: {id: 4, name: i18next.t('constants.index.form.fileUpload'), post_name: "response_file"},
}

// Form Type Ids for consistency
export const votingTypes = {
    standard: {id: 0, name: i18next.t('constants.index.vote.standard'), post_name: "standard", visible: true},
    zaSGB: {id: 1, name: i18next.t('constants.index.vote.sgbZA'), post_name: "za_sgb", visible: false},
}

// Form Type Ids for consistency
export const paymentTypes = {
    open: {id: 0, value: 'open', name: i18next.t('messageBuilder.morphedTemplates.PaymentMorphed.types.open')},
    fixed: {id: 1, value: 'fixed', name: i18next.t('messageBuilder.morphedTemplates.PaymentMorphed.types.fixed')},
    itemised: {id: 2, value: 'itemised', name: i18next.t('messageBuilder.morphedTemplates.PaymentMorphed.types.itemised')},
    partial: {id: 3, value: 'partial', name: i18next.t('messageBuilder.morphedTemplates.PaymentMorphed.types.partial')},
}

// Max message builder title length
export const messageBuilder = {
    MAX_TITLE_LENGTH: 35,
    ADD_INDIVIDUALS_TAG_ID: "-900",
    TARGET_ALL_TAG_ID: "-901",
    MAX_FILE_SIZE: 50000000,
    
    NUM_IMAGES_TO_ALBUM: 4,
    MAX_IMAGES_IN_ALBUM: 1000,
    
    MAX_EVENT_TITLE_LENGTH: 60,
    MAX_NOTICE_TITLE_LENGTH: 40,

    // Markup Types
    MARKUP: {
        html: 'html',
        plain: 'plain',
        markdown: 'markdown',
        json: 'json',
    },

    BUILDER_CACHE_KEY: '__BUILDER_CACHE',
    TEMP_ID_PREFIX: '__TEMP',
    TEMP_ID_PREFIX_SEP: '__',

    SUPPORTED_IMAGE_MIMES: "image/jpeg,image/png,image/gif,image/heif,image/heic,image/heif-sequence,image/heic-sequence",

    STATUS: {
        DRAFT: 'draft',
        PUBLISHING: 'publishing',
        SCHEDULED: 'scheduled',
        PUBLISHED: 'published',
        ARCHIVED: 'archived',
    },

    COMPOSE_SOURCES: {
        members: "1",
        events: "2",
        defaults: "3",
    },

    INTEGRATIONS: {
        OPEN_AI: {
            simplify: 'integrations_openai_aw_simplify_enabled',
            elaborate: 'integrations_openai_aw_elaborate_enabled',
            summarise: 'integrations_openai_aw_summarize_enabled',
            changeTone: 'integrations_openai_aw_changeTone_enabled',
            improveWriting: 'integrations_openai_aw_improve_enabled',
            generate: 'integrations_openai_aw_generate_enabled',
            MODES: {
                GENERATE: 'generate',
                SIMPLIFY: 'simplify',
                ELABORATE: 'elaborate',
                SUMMARIZE: 'summarize',
                CHANGE_TONE: 'tone',
                IMPROVE: 'improve',
            },
            TONES: {
                FUNNY: 'funny',
                CASUAL: 'casual',
                FORMAL: 'formal',
                FRIENDLY: 'friendly',
                ENTHUSIASTIC: 'enthusiastic',
            },
            SUMMARIZE_METHODS: {
                TEXT: 'text',
                LIST: 'list',
            },
        }
    }
}

/**
 * Map a weekday string from Moment's weekdays
 * 
 * @param {Number} weekdayIndex 
 * @returns {String} 
 */
export const _weekdayIndexToString = (weekdayIndex) => {
    switch (weekdayIndex) {
        case 0: return "SU";
        case 1: return "MO";
        case 2: return "TU";
        case 3: return "WE";
        case 4: return "TH";
        case 5: return "FR";
        case 6: return "SA";
        default:
            throw new Error(`Unknown week index detected [${weekdayIndex}]`);
    }
}

/**
 * Map a weekday string from Moment's weekdays, to RRule weekday objects
 * 
 * @param {string} weekName 
 * @returns {Weekday} 
 */
export const _determineRRuleKey = (weekName) => {
    switch (weekName) {
        case "SU": return RRule.SU;
        case "MO": return RRule.MO;
        case "TU": return RRule.TU;
        case "WE": return RRule.WE;
        case "TH": return RRule.TH;
        case "FR": return RRule.FR;
        case "SA": return RRule.SA;
        default:
            throw new Error(`Unknown week index detected [${weekName}]`);
    }
}

/**
 * 
 * @typedef Strategy
 * @property {String} name
 * @property {String} icon
 * @property {String} displayName
 */
// Calendar Constants
export const calendarConstants = {
    DEFAULT_REMIND_MINUTES: 720,
    EVENT_REPEAT_OPTIONS: {
        NEVER: {
            'key': 'NEVER',
            'description': i18next.t('calendar.events.create.repeat.never')
        },
        CUSTOM: {
            'key': 'CUSTOM',
            'description': i18next.t('calendar.events.create.repeat.custom')
        },
        DAILY: {
            'key': 'DAILY',
            'description': i18next.t('calendar.events.create.repeat.daily')
        },
        WEEKLY: {
            'key': 'WEEKLY',
            'description': i18next.t('calendar.events.create.repeat.weekly')
        },
        MONTHLY: {
            'key': 'MONTHLY',
            'description': i18next.t('calendar.events.create.repeat.monthly')
        },
        ANNUALLY: {
            'key': 'ANNUALLY',
            'description': i18next.t('calendar.events.create.repeat.annually')
        },
        WEEK_DAY: {
            'key': 'WEEK_DAY',
            'description': i18next.t('calendar.events.create.repeat.weekDay')
        },
    },

    // Sync Account Types
    STRATEGIES: {
        d6: {
            name: 'RELAY_CALENDAR',
            displayName: 'd6',
            eventDisplayName: i18next.t('general.calendar.internal'),
            icon: require('../images/d6_logo_teal.svg')
        },
        google: {
            name: 'GOOGLE_CALENDAR',
            displayName: 'Google Calendar',
            eventDisplayName: i18next.t('general.imported'),
            icon: require('../images/icons/g-calendar.svg')
        },
        microsoft: {
            name: 'MICROSOFT_CALENDAR',
            displayName: 'Microsoft Calendar',
            eventDisplayName: i18next.t('general.imported'),
            icon: require('../images/icons/microsoft.svg')
        },
        ical: {
            name: 'ICAL_CALENDAR',
            displayName: 'iCal',
            eventDisplayName: i18next.t('general.imported'),
            icon: require('../images/icons/ical.png')
        }
    },

    VIEW_TYPES: {
        calendarView: {
            title: i18next.t('calendar.view.type.calendarview.title'),
            id: 1,
        },
        listView: {
            title: i18next.t('calendar.view.type.listview.title'),
            id: 2,
        },
    },

    STATUS: {
        SYNCING: 'syncing',
        SYNCED: 'synced',
    },

    REPEAT_FREQUENCY: {
        DAILY: {
            key: 'DAILY',
            rruleKey: RRule.DAILY,
            name: i18next.t('events.create.repeat.DAILY'),
            i18Key: 'events.create.repeat.DAILY',
        },
        WEEKLY: {
            key: 'WEEKLY',
            rruleKey: RRule.WEEKLY,
            name: i18next.t('events.create.repeat.WEEKLY'),
            i18Key: 'events.create.repeat.WEEKLY',
        },
        MONTHLY: {
            key: 'MONTHLY',
            rruleKey: RRule.MONTHLY,
            name: i18next.t('events.create.repeat.MONTHLY'),
            i18Key: 'events.create.repeat.MONTHLY',
        },
        YEARLY: {
            key: 'YEARLY',
            rruleKey: RRule.YEARLY,
            name: i18next.t('events.create.repeat.YEARLY'),
            i18Key: 'events.create.repeat.YEARLY',
        },
    },
    
    REPEAT_WEEKDAYS: map(weekdaysMin(), (weekday, index) => {
        const mapping = _weekdayIndexToString(index)
        const rruleWeekday = _determineRRuleKey(mapping)
        return {
            index: rruleWeekday?.getJsWeekday(),
            label: weekday,
            rruleKey: rruleWeekday,
        }
    }),

    REPEAT_MONTHLY_OPTIONS: {
        MONTHLY_ON_DATE: "MONTHLY_ON_DATE",
        MONTHLY_ON_WEEK_DAY: "MONTHLY_ON_WEEK_DAY",
    },
}

export const createMembers = {
    ADD_CHILDREN: -900,
    ADD_PARENTS: -901,
    ADD_CHANNELS: -902,
    ADD_CONTRIBUTORS: -903,
}

// Members Import Types
export const membersImportTypes = {
    CEMIS: 'CEMIS',
    SA_SAMS: 'SA-SAMS',
    STANDARD: 'D6-GENERIC',
    ADVANCED_LINKING: 'D6-ADVANCED',
    SA_SAMS_ADVANCED: 'SA-SAMS-ADVANCED',
    SA_SAMS_ADVANCED_CLASSES: 'SA-SAMS-ADVANCED-CLASSES',
}

// Component Preview Priorities
const PRIORITY = {
    "-1": {
        id: -1,
        val: 'none'
    },
    0: {
        id: 0,
        val: 'low'
    },
    1: {
        id: 1,
        val: 'medium'
    },
    2: {
        id: 2,
        val: 'high'
    },
    3: {
        id: 3,
        val: 'force'
    },
}

export const componentPriorities = {
    file: PRIORITY[1].id,
    text: PRIORITY[-1].id,
    form: PRIORITY[2].id, // High priority when not Answered
    date: PRIORITY[0].id,
    image: PRIORITY[0].id,
    album: PRIORITY[0].id,
    video: PRIORITY[0].id,
    vimeo: PRIORITY[0].id,
    youtube_video: PRIORITY[0].id,
    event: PRIORITY[0].id,
    web_frame: PRIORITY[-1].id,
    contact: PRIORITY[-1].id,
    payment: PRIORITY[2].id, // High priority when not Paid
    deadline: PRIORITY[-1].id,
    location: PRIORITY[0].id,
    attachment: PRIORITY[0].id,
    progress_bar: PRIORITY[-1].id,
}

export const relationshipConstants = {
    TARGET_RELATIONSHIP_DEFAULT: 'default',
    TARGET_RELATIONSHIP_CHILDREN_ONLY: 'children_only',
    TARGET_RELATIONSHIP_PARENTS_ONLY: 'parents_only',
    TARGET_RELATIONSHIP_ROOT_ONLY: 'root_only'
}

export const imageConversionTypes = {
    'png': 'image/png',
    'jpeg': 'image/jpeg',
}

export const unsupportedImageMimes = [
    "image/heif",
    "image/heic",
    "image/heif-sequence",
    "image/heic-sequence",
]

export const TIMERS = {
    DEFAULT_REFRESH_CALENDAR_STATUS: 60000,
    DEFAULT_REFRESH_CALENDAR_UPDATED_STATUS: 5000,
    DEFAULT_REFRESH_CALENDAR_WAIT_DELAY: 60000,
}