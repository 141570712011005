import i18next from 'i18next'
import { isEmpty } from 'lodash'
import React from 'react'
import { List } from 'semantic-ui-react'
import { messageBuilder } from '../../../../constants'
import { renderMarkup } from '../../../../utils/helper'
import BaseTemplate from './BaseTemplate'

const Text = ({ item, classNames = '' }) => {
    
    const renderedHtml = renderMarkup({
        initial: item.data.jsonContent,
        type: messageBuilder.MARKUP.json
    })

    const content = item?.data?.content ?? ""
    
    return (
        <BaseTemplate classNames={classNames} render={() => (
            <List.Content>
                <List.Description className="description-text">
                    <div dangerouslySetInnerHTML={{__html: !isEmpty(content.replace("<p></p>", "")) ? renderedHtml.markup : `<p>${i18next.t('messageBuilder.preview.templates.Text.textPlaceholder')}</p>`}} />
                </List.Description>
            </List.Content>
        )} />
    )
}

export default Text
