import { configureStore } from '@reduxjs/toolkit'
import history from "./history"

import { routerMiddleware } from 'connected-react-router'
import reduxLoggerMiddleware   from "redux-logger"

import logger from './reducers/enhancers/logger'
import createRootReducer from './reducers'

const createStore = (options) => configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware()
            .concat(logger)
            .concat(routerMiddleware(history))

        if (process.env.REACT_APP_NODE_ENV !== 'production') {
            middleware.push(reduxLoggerMiddleware)
        }
        
        return middleware
    },
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    ...options,
})
const store = createStore()

export default store