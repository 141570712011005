import React, { Fragment } from 'react'
import { formTypes } from '../../../constants'
import { BaseTemplate } from '../../message-builder/preview/templates'
import { Image, List } from 'semantic-ui-react'
import { map } from 'lodash'
import AdaptiveIcon from '../timeline/AdaptiveIcon'

const BaseItem = ({ item, typeName, render }) => {
	return (
		<div className="mg-bot-10">
			<p className="no-margin">
				<strong>
					{item.title} {typeName && `(${typeName})`}
				</strong>
			</p>
			{item.subtitle && (
				<span className="mg-bot-2 mg-top-2">{item.subtitle}</span>
			)}
			{render()}
		</div>
	)
}

const FormItemPreview = ({ item }) => {
	switch (item.type) {
		case 'hover_response_text': {
			return (
				<BaseItem
					item={item}
					typeName={formTypes.textField.name}
					render={() => (
						<div className="form-text-item">
							<span>{item.answer}</span>
						</div>
					)}
				/>
			)
		}
		case 'hover_response_boolean': {
			return (
				<BaseItem
					item={item}
					typeName={formTypes.yesNo.name}
					render={() => (
						<div className="form-bool-item">
							<span>{item.answer}</span>
						</div>
					)}
				/>
			)
		}
		case 'hover_vote': {
			return (
				<BaseItem
					item={item}
					render={() => (
						<div className="form-multiple-item">
							{map(item.answers, (answer, index) => (
								<li key={`choice-item-${index}`}>{answer}</li>
							))}
						</div>
					)}
				/>
			)
		}
		case 'hover_response_choice': {
			return (
				<BaseItem
					item={item}
					typeName={formTypes.multipleChoice.name}
					render={() => (
						<div className="form-multiple-item">
							{map(item.answers, (answer, index) => (
								<li key={`choice-item-${index}`}>{answer}</li>
							))}
						</div>
					)}
				/>
			)
		}
		case 'hover_response_image': {
			return (
				<BaseItem
					item={item}
					typeName={formTypes.imageUpload.name}
					render={() => (
						<div className="form-image-item">
							<span>{item.answer}</span>
							<a href={item.answer_url} className='no-decoration' rel="noopener noreferrer" target="_blank" download>
								<Image
									src={item.answer_thumbnail_url}
									size="tiny"
									rounded
								/>
							</a>
						</div>
					)}
				/>
			)
		}
		case 'hover_response_file': {
			return (
				<BaseItem
					item={item}
					typeName={formTypes.fileUpload.name}
					render={() => (
						<div className="form-file-item">
							<a href={item.answer_url} className='no-decoration' rel="noopener noreferrer" target="_blank" download>
								<AdaptiveIcon url={item.answer_url} />
							</a>
						</div>
					)}
				/>
			)
		}
		default:
			return null
	}
}

export default function FormItem({ hoverItem }) {
	return (
		<BaseTemplate
			render={() => (
				<Fragment>
					<List.Content>
						{map(hoverItem.items, (item, index) => (
							<FormItemPreview key={`item-${index}`} item={item} />
						))}
					</List.Content>
				</Fragment>
			)}
		/>
	)
}
