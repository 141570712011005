import { push } from 'connected-react-router'
import i18next from 'i18next'
import React, { Fragment, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Icon, Loader } from 'semantic-ui-react'
import { authActions } from '../../actions/authActions'
import LogoTeal from './LogoTeal'

export default function ErrorFallback(props) {
    
    const { error, resetErrorBoundary } = props
    const dispatch = useDispatch()
    const navigation = useSelector(state => state.navigation)

    // onMount/onUpdate
    useEffect(() => {
        // Add loading class to add image background
        document.body.classList.add('loading')

        return () => {
            document.body.classList.remove('loading')
        }

    }, [])

    const retryByRefresh = () => window.location.reload()

    return (
        <div className='ui middle aligned center aligned grid data-init-screen'>
            <Loader 
                inverted 
                active={true} 
                content={
                    <Fragment>
                        <div className='rive-loader error'>
                            <LogoTeal />
                        </div>
                        <div className='error-explanation'>
                            <p className='error-message'>{i18next.t("error.errorOccurredCrash")}</p>
                            {/* <pre className='full-error-explanation'>
                                <small>{error.message}</small>
                            </pre> */}
                            <Grid className='mg-top-10'>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Button className='primary' onClick={resetErrorBoundary}>{i18next.t('general.takeMeHome')}</Button>
                                        <Button className='primary' onClick={retryByRefresh}><Icon name='refresh' />{i18next.t('general.retry')}</Button>
                                        <Button className='primary' onClick={() => dispatch(authActions.doLogout())}><Icon name='log out' />{i18next.t('general.logout')}</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Fragment>
                } />
        </div>
    )
}