import i18next from 'i18next'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { List } from 'semantic-ui-react'
import { messageBuilder } from '../../../../constants'
import { findTextComponent, findTextComponentDescription, getTasksFromResponse, renderMarkup } from '../../../../utils/helper'
import BaseTemplate from './BaseTemplate'
import moment from 'moment'
import { noticeService } from '../../../../api'
import { RenderListView } from '../../../partials/PreviewModalView'
import AnimLoader from '../../../partials/AnimLoader'

const NoticeAttachment = ({ item, classNames = 'attachment-preview no-bg' }) => {
    const [data, setData] = useState(null)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        const noticeId = item.data.noticeId
        if (!noticeId) {
            return;
        }
        
        setLoading(true)

        noticeService.getNoticePreview({noticeId: noticeId})
            .then(response => {
                const content = response.data.app
                const tasks = getTasksFromResponse({response: content})
                const description = findTextComponentDescription(tasks)
                const textComponent = findTextComponent(tasks)

                const listItem = {
                    title: content.title,
                    pinItem: content.pin_item,
                    important: content.important,
                    icon: content.community_image,
                    image: content.community_image,
                    communityName: content.community_name,
                    description: description,
                    hasText: textComponent !== null,
                    createdAt: content.created_at ? moment(content.created_at) : moment()
                }

                setData({listItem, tasks})
            }).catch(error => {
                setData(null)
            }).finally(() => setLoading(false))
    }, [])
    
    if (!data || isLoading) {
        return (
            <BaseTemplate classNames={classNames} render={() => (
                <AnimLoader loading />
            )} />
        )
    }

    return (
        <BaseTemplate classNames={classNames} render={() => (
            <RenderListView
                contents={data.tasks}
                listItem={data.listItem}
                classNames='' />
        )} />
    )
}

export default NoticeAttachment
