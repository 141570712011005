import React, { useState, useEffect, useRef, Fragment } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid, Button, Icon, Image, Checkbox, Popup } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch, useSelector } from 'react-redux'
import Switch from 'react-switch'
import i18next from 'i18next'
import { getError, isPartiallyUploaded, setError } from '../../../utils/helper'
import ErrorLabel from '../../partials/ErrorLabel'
import { messageBuilder, templateIcons } from '../../../constants'
import { useDropzone } from 'react-dropzone'
import { uploadService } from '../../../api'
import AdaptiveIcon from '../../partials/timeline/AdaptiveIcon'
import { first, isNull, map, mapKeys, mapValues, omit, size, take, without, filter } from 'lodash'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { renderSizeInMB } from '../preview/templates/Attachment'
import classNames from 'classnames'

const FileThumb = (props) => {
    
    const {
        data,
        index,
        onRemove,
        onUploadError,
        onFileDecoded,
        onUploadSuccess,
    } = props

    const [state, setState] = useState({
        loading: false,
        thumb: data?.thumb ?? null,
        fileName: data?.fileName ?? "",
        error: null,
        fileId: data?.id ?? null,
        validationError: data?.error ?? null,
        retryCount: 0,
    })

    // @TODO: Only Images have preview-able thumbnails, we could potentially add a call to be able to 
    useEffect(() => {

        // if (!isPartiallyUploaded([state.fileId]) && !state.blob) {
        //     const handleGetFilePreview = async () => {
        //         const previewData = await uploadService.getFilePreview({fileId: state.fileId})
        //         const blob = new Blob([previewData.data])
                
        //         setState(prev => ({
        //             ...prev,
        //             thumb: URL.createObjectURL(blob),
        //         }))

        //         // @NOTE: at the point that the builder receives a revoked blob url, once we get a new blob preview url, we should now alert the builder about this change
        //         onUploadSuccess({
        //             id: data.id,
        //             fileData: {
        //                 ...data,
        //                 thumb: URL.createObjectURL(blob),
        //             },
        //         })
        //     }

        //     // When our thumb is one from our CDN we do not need to fetch any preview
        //     if (!state.thumb?.includes(`https://cdnrly`)) {
        //         handleGetFilePreview()
        //     }
        // }

        // Cleanup anything to avoid memory leaks
        return () => URL.revokeObjectURL(state.thumb)
    }, [])

    // When there are file updates, we will do basic checks to stop processing incase we do not have valid files etc
    useEffect(() => {

        if (!data.file) return;

        if (data.file?.url) return;

        if (!data.file?.blob) return;

        // No need to hit the API once we have a Blob AKA <The Current File> this is to avoid the component re-uploading data on mount and drag change
        if (state.thumb) return;

        setState(prev => ({
            ...prev,
            thumb: URL.createObjectURL(data.file.blob),
            loading: true,
        }))

        handleUpload(data.file.blob)

    }, [data.file.blob])


    const handleUpload = (file) => {
        
        // Start Uploading to the server
        uploadService.upload({file: file, type: 'file'}).then(response => {

            // Upload finished with success
            setState(prev => ({
                ...prev, 
                error: null,
                loading: false,
                fileId: response.id ?? data?.id, 
            }))

            onUploadSuccess({
                id: data.id,
                fileData: {...data}
            })
        }).catch(error => {

            onUploadError({
                id: data.id,
                fileData: {...data}
            })

            setState(prev => ({
                ...prev,
                loading: false,
                error: error?.data?.message || i18next.t("error.errorOccurred"),
            }))
        })
    }

    useEffect(() => {

        if (!data?.retryToken) return;
        if (!data?.file?.blob) return; // There is nothing we can do without the blob

        // When there is a signal to retry an upload, we need to upload the file again
        handleUpload(data.file.blob)

        // Increment the retry count
        setState(prev => ({ ...prev, loading: true, retryCount: prev.retryCount + 1 }))

    }, [data?.retryToken])

    useEffect(() => {
        
        if (!state.fileId) return;

        // File Upload is done
        onFileDecoded({
            fileData: {
                ...data,
                file: {
                    ...data.file,
                    id: state.fileId
                },
                id: state.fileId,
                thumb: state.thumb
            },
            index: index,
        })
    }, [state.fileId])

    const handleRemoveFile = () => onRemove({index: index, id: state.fileId})
    const handleRedo = () => {
        setState(prev => ({ ...prev, loading: true, retryCount: prev.retryCount + 1 }))
        handleUpload(data.file.blob)
    }

    const handleEdit = () => {/** @TODO: Future phase */}

    const handleOpen = () => {
        window.open(state.thumb, '_blank')
    }

    return (
        <div className={`ui image thumb-image-container ${state.loading ? 'loading' : ''} ${(state.error || state.validationError) ? 'error' : ''}`}>
            {/* {!state.loading && <Icon className='remove-image-icon' name="times circle" onClick={handleRemoveImage} />} */}
            {state.loading && <Icon className='loading-icon' name="spinner" loading />}
            {!state.loading && (
                <Button.Group basic size='small' className='file-action-buttons'>
                    <Button icon='trash' onClick={handleRemoveFile} />
                    {state.thumb && <Button icon='eye' onClick={handleOpen} />}
                    {/* <Button icon='redo' onClick={handleRedo} disabled={!(data?.originalId === undefined)} /> */}
                    {(state.error || state.validationError) && <Button icon='redo' onClick={handleRedo} />}
                </Button.Group>
            )}
            {!state.loading && (state.error || state.validationError) && (
                <Popup
                    basic
                    size='tiny'
                    className='morphed'
                    trigger={<Icon className='loading-icon error' name={classNames("warning", state.retryCount > 3 ? "circle" : "sign")} />}
                    content={state.retryCount > 3 ? i18next.t("error.errorOccurred.maxRetries") : (state.error ?? state.validationError)}
                />
            )}
            <div className="icon-card-container file-morphed-container w-full text-align-center">
                <AdaptiveIcon url={state.fileName} />
                <div className='file-details'>
                    <p className='no-margin f-name grey-text'>{state.fileName}</p>
                    <p className='no-margin f-size grey-text'>{renderSizeInMB(data)}</p>
                </div>
            </div>
        </div>
    )
}

export default function AttachmentMorphed({ item }) {

    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [validationMessages, setValidationMessages] = useState([])

    const [data, setData] = useState({
        files: item?.data?.files ?? {},
        originalId: item?.data?.originalId,
        resourceCategoryIds: item?.data?.resourceCategoryIds ?? [],
        _type: item?.data?._type ?? templateIcons.file,
    })

    const [settings, setSettings] = useState({
        isUpdate: (data.originalId !== undefined),
        isFileComponent: (data._type === templateIcons.file)
    })

    const {
        open,
        getRootProps,
        getInputProps,
        acceptedFiles,
        fileRejections,
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: (settings.isFileComponent && settings.isUpdate) ? 1 : messageBuilder.MAX_IMAGES_IN_ALBUM,
        multiple: (settings.isFileComponent && settings.isUpdate) === false,
        validator: (file) => {
            
            // Validate the File Size
            if (file.size > messageBuilder.MAX_FILE_SIZE) {
                return {
                    code: "file-too-big",
                    message: t('messageBuilder.morphedTemplates.AttachmentMorphed.maxFileError')
                }
            }
            
            // Validate the Mime Type and block .exe upload
            if ([
                "application/vnd.microsoft.portable-executable", 
                "application/x-msdownload"
              ].includes(file.type)) {
                return {
                    code: "file-not-supported",
                    message: t('messageBuilder.morphedTemplates.imageMorphed.unsupportedFileError')
                }
            }

            return null
        }
    })
    
    // Houses the item_ids that are currently being uploaded or being loaded
    // When an item is busy, its ID will remain in the list, but once done, the ID will be removed from the list
    const [busyItems, setBusyItems] = useState([])

    useEffect(() => {
        
        const storeData = map(data.files, (file) => {
            return omit(file, ['file.blob'])
        })
        
        dispatch(builderActions.saveTemplateData({ item, data: {
            ...data,
            files: mapKeys(storeData, i => i.id)
        }}))

        // When we have at least one failed item, we should show an error
        const failed = filter(storeData, (file) => file.hasError)
        if (failed.length > 0) {
            setValidationMessages(setError("imagePartiallyUploaded", i18next.t('requestObjects.PostCreateNoticeRequest.files.partialy-uploaded')))
        } else {
            setValidationMessages([])
        }

        return () => { }
    }, [data, dispatch])
    
    useEffect(() => {
        
        if (acceptedFiles.length === 0) return;

        const incoming = map(acceptedFiles, file => {
            
            const tempId = v4() + messageBuilder.TEMP_ID_PREFIX
            
            return {
                file: {
                    url: null,
                    id: tempId,
                    blob: file,
                },
                id: tempId,
                fileName: file.name,
                fileSize: file.size,
            }
        })

        const mappedIncoming = mapKeys(incoming, i => i.id)

        // When files are dropped here, they are going to be processed by the uploader, so we are going to add all files and their temp ids starting here
        setBusyItems(map(incoming, "id"))

        // When we are updating FileComponent, we should replace and only allow one File
        if (settings.isUpdate && settings.isFileComponent) {

            setData(prev => ({
                ...prev, 
                files: {
                    ...mappedIncoming
                }
            }))

            return;
        }

        setData(prev => ({...prev, files: {
            ...prev.files, // @TODO: Enable multiple file uploads on a single file component.
            ...mappedIncoming
        }}))
    }, [acceptedFiles])
    
    useEffect(() => {
        
        if (fileRejections.length === 0) {
            setValidationMessages([])
            return;
        }
        
        const rejection = first(fileRejections)
        const error = fileRejections.length > 1 ? t("requestObjects.PostCreateNoticeRequest.file.required.atLeastOne") : (rejection.errors.length > 0 ? rejection.errors[0].message : t("error.errorOccurred"))
        setValidationMessages(setError("file", error))
        
    }, [fileRejections])

    useEffect(() => {
        
        // With anything happening in the busy items, we will update the builder with the status
        // The busy indicator should only stop once the are no more items in the busyItems list
        dispatch(builderActions.busyIndicator({
            loading: (busyItems.length > 0),
            items: busyItems.length
        }))

    }, [busyItems])

    const onFileDecoded = ({fileData, index}) => {
        const _file = {...fileData}
        setData(prev => ({
            ...prev, 
            files: {
                ...prev.files,
                [index]: _file
            }
        }))
    }

    const onUploadSuccess = ({fileData, id}) => updateFileUploadStatus({fileData, id}, false)

    const onUploadError = ({fileData, id}) => updateFileUploadStatus({fileData, id}, true)

    const updateFileUploadStatus = ({fileData, id}, hasError = false) => {
        const _file = {...fileData, retryToken: null, hasError: hasError, loading: false}

        setData(prev => ({
            ...prev, 
            files: {
                ...prev.files,
                [id]: _file
            }
        }))

        setBusyItems(prev => without(prev, id)) // Remove an item once its done loaded
    }
    
    const onRemoveFile = ({index}) => {
        const _all = {...data.files}
        const _files = omit(_all, [index])
        setData(prev => ({...prev, files: _files}))
    }

    const handleCategoryChange = ({name, value, e}) => {
        setData(prev => ({ ...prev, [name]: value }))
    }

    const handleRedoAll = () => {
        // Get all the files that have failed to upload
        const _files = map(data.files, (file) => {

            if (file.id.includes(messageBuilder.TEMP_ID_PREFIX)) {
                return {
                    ...file,
                    retryToken: v4()
                }
            }

            return file
        })

        const mappedFiles = mapKeys(_files, i => i.id)
        
        // Update busy items
        setBusyItems(map(mappedFiles, "id").filter(id => id.includes(messageBuilder.TEMP_ID_PREFIX)))

        // Update the items
        setData(prev => ({ ...prev, files: mappedFiles }))
    }

    return (
        <BaseMorphed item={item} className={`${!getError("imagePartiallyUploaded", validationMessages).isValid || !getError("file", validationMessages).isValid || fileRejections.length > 0 ? 'has-errors' : ''}`} render={({ errors }) => (
            <Grid className="attachment-morphed">
                <Grid.Row>
                    <Grid.Column width={16}>
                        <div className='input-container theme-color'>
                            <div className='flex items-center w-full mg-bot-5' style={{justifyContent: 'space-between'}}>
                                <label className='label no-padding'>{i18next.t('messageBuilder.morphedTemplates.AttachmentMorphed.attachment')}</label>
                                {!getError('imagePartiallyUploaded', validationMessages).isValid && (
                                    <Button size='tiny' onClick={handleRedoAll}>
                                        <Icon name="repeat" />{t('messageBuilder.morphedTemplates.ImageMorphed.input.image.button.retry-failed-uploads')}
                                    </Button>
                                )}
                            </div>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} accept=".txt,.pdf,.docx,.doc,.xlsx,.xls,.pptx,.ptt,.png,.jpg,.csv" />
                                
                                {size(data.files) == 0 && (
                                    <Fragment>
                                        <p>{i18next.t('messageBuilder.morphedTemplates.AttachmentMorphed.file.selectFilePlaceholder')}</p>
                                        <Button 
                                            size="tiny"
                                            className="secondary" 
                                            onClick={open}>{i18next.t('messageBuilder.morphedTemplates.AttachmentMorphed.chooseFile')}</Button>
                                    </Fragment>
                                )}

                                <Image.Group size='tiny' className='w-100 no-padding'>
                                    {map(data.files, (file, i) => (
                                        <FileThumb 
                                            key={i}
                                            index={i}
                                            data={file}
                                            onRemove={onRemoveFile}
                                            onUploadError={onUploadError}
                                            onFileDecoded={onFileDecoded}
                                            onUploadSuccess={onUploadSuccess} />
                                    ))}

                                    {size(data.files) > 0 && (
                                        <div className="ui image mg-similar-morphed">
                                            <div className="ui image file-add no-margin" onClick={open}>
                                                <Icon name='plus' size='big' className='no-margin' />
                                            </div>
                                        </div>
                                    )}
                                </Image.Group>
                            </div>
                            
                            {/* {size(data.files) > 0 && (
                                <div className='files mt-4'>
                                    <h4 className='grey-text'>{i18next.t('messageBuilder.morphedTemplates.AttachmentMorphed.selectedFiles')}:</h4>
                                    <ul>
                                        {map(data.files, (file) => (
                                            <li key={file.id}>{file.fileName}</li>
                                        ))}
                                    </ul>
                                </div>
                            )} */}

                            <p className="label grey-text small-text"><strong>{i18next.t('messageBuilder.morphedTemplates.AttachmentMorphed.recommended')}</strong>: .txt, .pdf, .docx, .doc, .xlsx, .xls, .pptx, .ptt, .png, .jpg, .csv</p>
                        </div>
                        <ErrorLabel error={getError("file", errors)} />
                        <ErrorLabel error={getError("file", validationMessages)} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )}
        resourceCategory={{
            id: 'resourceCategoryIds',
            selected: data.resourceCategoryIds,
            onChange: handleCategoryChange,
        }} />
    )
}
