import { actions, constants } from '../constants'
import { size, map, find, merge, isEmpty } from 'lodash'
import moment from 'moment'
import { formatMobileNumber } from '../utils/helper'
import i18next from 'i18next'
import faker from 'faker'

const { USERS, AUTH_ERROR } = actions

const initialState = {
    loading: true,
    loadingFilters: true,
    users: [],
    filters: {},
    userDetails: null,
    inviteDetails: null,
    pagination: [],
    errors: null,
    messages: null,
    invites: {
        pagination: null,
        users: [],
        filters: {},
    },
    member: {
        users: [],
        memberDetails: null,
        pagination: [],
        filters: {}
    }
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case USERS.RESET: {
            return {
                ...state, 
                loading: false, 
                pagination: [], 
                users: [], 
                filters: {}, 
                errors: null,
                errorCode: null, 
                messages: null, 
                inviteDetails: null,
                invites: {
                    pagination: null,
                    users: []
                },
                member: {
                    users: [],
                    memberDetails: null,
                    pagination: [],
                    filters: {}
                }
            }
        }
        case USERS.START: {
            return {...state, loading: true}
        }
        case USERS.LOAD_FILTERS: {
            return {...state, loadingFilters: true}
        }
        case USERS.CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null, errorCode: null}
        }
        case AUTH_ERROR:
        case USERS.ERROR: {
            let error = action.payload.response
            const errorCode = error?.data?.code ?? null
            error = error?.data?.message ?? "error.errorOccurred"

            return { 
                ...state, 
                loading: false,
                loadingFilters: false, 
                serverError: !isEmpty(action?.payload?.response?.data?.message), 
                errors: error,
                errorCode: errorCode, 
                messages: null 
            }
        }
        case USERS.SUCCESS: {
            return {...state, loading: false, errors: null, messages: action.payload}
        }
        case USERS.SAVE_INVITE_DETAILS: {
            return {
                ...state, 
                loading: false, 
                errors: null,
                inviteDetails: action.payload
            }
        }
        case USERS.SAVE: {
            return {
                ...state, 
                loading: false, 
                users: action.payload.data,
                usersTransformed: map(action.payload.data, u => {
                    
                    let roles = map(u.channel_roles, r => {return {type: 'channels', color: 'color-primary-light', name: r.name, channels: map(r.channels, 'name')}})
                    let community = map(u.community_roles, r => {return {type: 'community', color: 'color-primary-light', name: r.name}})

                    return {
                        id: u.id,
                        full_name: `${u.first_name || ""} ${u.last_name || ""}`,
                        first_name: u.first_name,
                        last_name: u.last_name,
                        phone_number: formatMobileNumber(u.phone_number),
                        is_locked: u.is_locked,
                        locked_reason: u.locked_reason,
                        email: u.email,
                        roles: {roles, community},
                        actions: [
                            constants.MENU_ACTION_DELETE,
                        ]
                    }
                }), 
                pagination: action.payload.pagination
            }
        }
        case USERS.SAVE_MEMBERS: {
            
            return {
                ...state, 
                loading: false,
                member: {
                    ...state.member,
                    users: action.payload.data,
                    usersTransformed: map(action.payload.data, u => {
                        
                        return {
                            id: u.id,
                            full_name: `${u.first_name || ""} ${u.last_name || ""}`,
                            first_name: u.first_name,
                            last_name: u.last_name,
                            phone_number: formatMobileNumber(u.phone_number),
                            email: u.email,
                            id_number: u.id_number,
                            reference: u.unique_identifier,
                            last_modified: moment(u.updated_at).format("L LT"),
                            added_by: u.added_by,
                            has_app: u.has_app,
                            debtor_reference: u.debtor_reference,
                            app_last_seen_at: u.has_app ? moment(u.app_last_seen_at).format("L LT") : null,
                            is_locked: u.is_locked,
                            locked_reason: u.locked_reason,
                            is_member: (u.is_public_follower === false),
                            channels: {
                                data: u.channels
                            },
                            tags: {
                                data: u.tags
                            },
                            relationship: {
                                parents: u.parents ?? [],
                                children: u.children ?? []
                            },
                            actions: [
                                constants.MENU_ACTION_DELETE,
                            ]
                        }
                    }), 
                    pagination: action.payload.pagination
                }
            }
        }
        case USERS.SAVE_INVITES: {
            return {
                ...state, 
                loading: false, 
                invites: {
                    ...state.invites,
                    users: action.payload.data,
                    usersTransformed: map(action.payload.data, u => {
                        
                        let roles = map(u.channel_roles, r => {return {type: 'channels', color: 'color-primary-light', name: r.name, channels: map(r.channels, 'name')}})
                        let community = map(u.community_roles, r => {return {type: 'community', color: 'color-primary-light', name: r.name}})

                        return {
                            id: u.id,
                            first_name: u.first_name,
                            last_name: u.last_name,
                            phone_number: formatMobileNumber(u.phone_number),
                            email: u.email,
                            roles: {community, roles},
                            created_at: moment(u.created_at).format('L LT'),
                            is_locked: u.is_locked,
                            locked_reason: u.locked_reason,
                            actions: [
                                constants.MENU_ACTION_DELETE,
                                constants.MENU_ACTION_RESEND,
                            ]
                        }
                    }), 
                    pagination: action.payload.pagination
                }
            }
        }
        case USERS.SAVE_FILTERS: {
            return {
                ...state, 
                loading: false,
                loadingFilters: false, 
                filters: {
                    tChannelRoles: map(action.payload.channel_roles, r => {return {...r, channels: []}}),
                    channelRoles: map(action.payload.channel_roles, r => {return {key: r.id, value: r.id, text: r.name}}),
                    channels: map(action.payload.channels, r => {return {key: r.id, value: r.id, text: r.name}}),
                    communityRoles: map(action.payload.community_roles, r => {return {key: r.id, value: r.id, text: r.name}}),
                }, 
            }
        }
        case USERS.SAVE_INVITE_FILTERS: {
            return {
                ...state, 
                loading: false,
                loadingFilters: false, 
                invites: {
                    ...state.invites,
                    filters: {
                        channelRoles: map(action.payload.channel_roles, r => {return {key: r.id, value: r.id, text: r.name}}),
                        channels: map(action.payload.channels, r => {return {key: r.id, value: r.id, text: r.name}}),
                        communityRoles: map(action.payload.community_roles, r => {return {key: r.id, value: r.id, text: r.name}}),
                    },
                }
            }
        }
        case USERS.SAVE_MEMBER_FILTERS: {

            return {
                ...state, 
                loading: false,
                loadingFilters: false,
                member: {
                    ...state.member,
                    filters: {
                        parents: map(action.payload.persons, r => {return {key: r.id, value: r.id, text: r.name}}),
                        children: map(action.payload.persons, r => {return {key: r.id, value: r.id, text: r.name}}),
                        tChannelRoles: map(action.payload.channel_roles, r => {return {...r, channels: []}}),
                        channelRoles: map(action.payload.channel_roles, r => {return {key: r.id, value: r.id, text: r.name}}),
                        channels: map(action.payload.channels, r => {return {key: r.id, value: r.id, text: r.name}}),
                        oChannels: action.payload.channels,
                        tags: map(action.payload.tags, r => {return {key: r.id, value: r.id, text: r.name}}),
                        platforms: map(action.payload.preference_platforms, r => {return {key: r.platform, value: r.platform, text: r.name}}),
                        communityRoles: map(action.payload.community_roles, r => {return {key: r.id, value: r.id, text: r.name}}),
                        appOptions: [
                            {
                                key: -1,
                                value: -1,
                                text: i18next.t("manageMembers.filters.showAll")
                            },
                            {
                                key: 0,
                                value: 0,
                                text: i18next.t("manageMembers.filters.no")
                            },
                            {
                                key: 1,
                                value: 1,
                                text: i18next.t("manageMembers.filters.yes")
                            },
                        ],
                        followerOptions: [
                            {
                                key: -1,
                                value: -1,
                                text: i18next.t("manageMembers.filters.showAll")
                            },
                            {
                                key: 0,
                                value: 0,
                                text: i18next.t("manageMembers.filters.membersOnly")
                            },
                            {
                                key: 1,
                                value: 1,
                                text: i18next.t("manageMembers.filters.noMembers")
                            },
                        ],
                    }, 
                }
            }
        }
        case USERS.SAVE_USER_DETAILS: {
            return {
                ...state, 
                loading: false,
                userDetails: {
                    ...action.payload,
                    channel_roles: map(action.payload.channel_roles, r =>  {
                        return {
                            ...r,
                            channels: map(r.channels, 'id'),
                        }
                    }),
                    community_roles: map(action.payload.community_roles, 'id')
                }, 
            }
        }
        case USERS.SAVE_MEMBER_DETAILS: {
            return {
                ...state, 
                loading: false,
                member: {
                    ...state.member,
                    memberDetails: {
                        ...action.payload,
                        channels: map(action.payload.channels, 'id'),
                        tags: map(action.payload.tags, 'id'),
                    },
                }
            }
        }
        case USERS.CLEAR_USER_DETAILS: {
            return {
                ...state, 
                loading: false,
                userDetails: null, 
            }
        }
        case USERS.CLEAR_MEMBER_DETAILS: {
            return {
                ...state, 
                loading: false,
                member: {
                    ...state.member,
                    memberDetails: null, 
                }
            }
        }
        default: {}
    }

    return state
}