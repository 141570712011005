import React, {Component, useState} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {filterBy} from './utils'
import Filter from './Filter'
import List from './List'
import { Button, Dimmer, Dropdown, Grid, Icon, Input, Loader, Segment } from 'semantic-ui-react'
import { difference, map, size } from 'lodash'
import i18next from 'i18next'

const propTypes = {
	availableFooter: PropTypes.node,
	availableHeader: PropTypes.node,
	className: PropTypes.string,
	clearFilterText: PropTypes.string,
	clearable: PropTypes.bool,
	deselectAllText: PropTypes.string,
	disabled: PropTypes.bool,
	filterBy: PropTypes.func,
	filterComponent: PropTypes.func,
	highlightAvailable: PropTypes.array,
	highlightSelected: PropTypes.array,
	labelKey: PropTypes.string,
	limit: PropTypes.number,
	onChange: PropTypes.func,
	onSelectAll: PropTypes.func,
	onClearAll: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	searchable: PropTypes.bool,
	selectAllText: PropTypes.string,
	selectedFooter: PropTypes.node,
	selectedHeader: PropTypes.node,
	showControls: PropTypes.bool,
	value: PropTypes.array,
	valueOptions: PropTypes.array,
	valueKey: PropTypes.string,
	tags: PropTypes.array,
	onChangeTags: PropTypes.func,
	isLoading: PropTypes.bool,
}

const defaultProps = {
	availableFooter: null,
	availableHeader: null,
	className: null,
	clearFilterText: i18next.t('partials.multiselect.index.clearFilterText'),
	clearable: true,
	deselectAllText: i18next.t('partials.multiselect.index.deselectAllText'),
	disabled: false,
	filterBy: filterBy,
	filterComponent: null,
	highlightAvailable: [],
	highlightSelected: [],
	labelKey: 'label',
	limit: undefined,
	onChange: () => {},
	onSelectAll: () => {},
	onClearAll: () => {},
	options: [],
	placeholder: '',
	searchable: false,
	selectAllText: i18next.t('partials.multiselect.index.button.selectAll'),
	selectedFooter: null,
	selectedHeader: null,
	showControls: false,
	value: [],
	valueOptions: [],
	valueKey: 'value',
	tags: [],
	onChangeTags: () => {},
	isLoading: false,
}

const ControlButtons = (props) => {
	
	const {
		hasSelection,
		onClearAll,
		onSelectAll,
	} = props

	return (
		<div className="control-button">
			<Button 
				onClick={onSelectAll}>
				<span className="material-icons">done_all</span>{i18next.t('partials.multiselect.index.button.selectAll')}
			</Button>
			<Button 
				disabled={!hasSelection}
				className="clear-selection"
				onClick={onClearAll}>
				<span className="material-icons">close</span>{i18next.t('partials.multiselect.index.button.clearSelection')}
			</Button>
		</div>
	)
}

const MultiSelect = (props) => {

    const [state, setState] = useState({
        filterAvailable: '',
        filterSelected: ''
    })

	const handleClickAvailable = (value) => {
        props.onChange(value, "highlightAvailable")
	}

	const handleClickSelected = (value) => {
		props.onChange(value, "highlightSelected")
	}
	
	const handleClickSelectAll = (args) => {
        props.onSelectAll(args)
	}

	const handleClickDeselectAll = (args) => {
		props.onClearAll(args)
	}

	const handleAddSelected = () => {
		
		// Grab the currently highlighted from the "Left" side
		const highlighted = props.highlightAvailable
		const currentSelected = props.value

		// Add the new kids to the "Right" selection
		props.onChange(
			currentSelected.concat(highlighted), 
			"highlightAvailable", 
			true
		)
	}

	const handleRemoveSelected = (type) => {
		
		// Grab currently highlighted from the "Right" side 
		const highlighted = props.highlightSelected
		const currentSelected = props.value

		// remove the highlighted from the  "Right" side and add them back to the Left
		props.onChange(
			difference(currentSelected, highlighted), 
			"highlightSelected", 
			true
		)
	}

	const filterAvailable = () => {
		
		const {
			filterBy,
			highlightAvailable,
			labelKey,
			limit,
			options,
			valueOptions,
			value,
			valueKey,
			searchable
		} = props

		const _values = map(valueOptions, "value")
		
		const filtered = options.reduce((acc, option) => {
            
            if (_values.indexOf(option[valueKey]) === -1) {
				acc.push(option)
			}

			return acc
		}, [])

		let limited = filtered
        
        if (_values.length >= limit) {
			limited = filtered.map(option => {
				return Object.assign({}, option, {disabled: true})
			})
		}

		if (highlightAvailable && highlightAvailable.length > 0) {
			limited = limited.map(option => {
				if (highlightAvailable.indexOf(option[valueKey]) > -1) {
					return Object.assign({}, option, {highlighted: true})
				}

				return option
			})
		}

		if (!searchable) {
			return limited
		}

		const {
			filterAvailable: filter
		} = state
        
        if (filter) {
			return limited.filter(option => filterBy(option, filter, labelKey))
		}

		return limited
	}

	const filterActive = () => {
        
        const {
			filterBy,
			labelKey,
            options,
			value,
			valueOptions,
			valueKey,
            highlightSelected,
		} = props
        
        const filtered = [...valueOptions]

		if (!props.searchable) {
			return filtered
		}

        const {filterSelected: filter} = state
        
        let limited = filtered

        if (highlightSelected && highlightSelected.length > 0) {
			limited = limited.map(option => {
				if (highlightSelected.indexOf(option[valueKey]) > -1) {
					return Object.assign({}, option, {highlighted: true})
				}

				return option
			})
        }
        
		if (filter) {
			return limited.filter(option => filterBy(option, filter, labelKey))
		}

		return limited
	}

	const handleChangeFilterAvailable = (filterAvailable) => {
		setState(prev => ({
			...prev,
			filterAvailable
		}))
	}

	const handleChangeFilterSelected = (filterSelected) => {
		setState(prev => ({
			...prev,
			filterSelected
		}))
	}

	const renderFilter = (value, onChange) => {
		const {
			clearFilterText,
			clearable,
			disabled,
			filterComponent,
			placeholder
		} = props

		if (!filterComponent) {
			return (
				<Filter
					value={value}
					clearFilterText={clearFilterText}
					clearable={clearable}
					disabled={disabled}
					placeholder={placeholder}
					onChange={onChange}
				/>
			)
		}

		return React.createElement(filterComponent, {
			clearFilterText,
			clearable,
			disabled,
			onChange,
			placeholder,
			value
		})
	}

    const {
        className,
        deselectAllText,
        disabled,
        labelKey,
        searchable,
        selectAllText,
		valueKey,
		tags,
		isLoading,
		onChangeTags
    } = props


    return (
        <Grid className={classNames('msts', disabled && 'msts_disabled', className)}>
            <Grid.Row columns="3">
                <Grid.Column width="7" className="vertical-align">
                    <Segment className="no-padding w-100">
                        <div className="input-container theme-color">
                            <label className='label' htmlFor="tags">{i18next.t('partials.multiselect.index.tags')}</label>
                                <Dropdown
                                    className='theme-color'
                                    placeholder={i18next.t('partials.multiselect.index.tags')}
                                    fluid
                                    search
                                    selection
                                    multiple
                                    id="tags"
                                    name="tagIds"
                                    onChange={onChangeTags}
                                    options={tags} />
                        </div>
                        <div className="input-container theme-color">
                            {searchable ? (
                                renderFilter(state.filterAvailable, handleChangeFilterAvailable)
                            ) : null}
                        </div>
						<ControlButtons
							{...props}
							onClearAll={handleClickDeselectAll.bind(this, {type: "highlightAvailable", options: []})}
							onSelectAll={handleClickSelectAll.bind(this, {type: "highlightAvailable", options: filterAvailable()})}
							hasSelection={props.highlightAvailable.length > 0} />
                        <div className="contents-container">
							<Dimmer active={isLoading} inverted>
								<Loader inverted content='Loading' />
							</Dimmer>
                            <List
                                options={filterAvailable()}
                                disabled={disabled}
                                labelKey={labelKey}
                                valueKey={valueKey}
                                onClick={handleClickAvailable} />
                        </div>
                    </Segment>
					<label className="label-container grey-text">
						{props.highlightAvailable.length}/{size(filterAvailable())}
					</label>
                </Grid.Column>
                <Grid.Column width="2" className="vertical-align">
                    <div className="msts__side msts__side_controls w-100">
						<Button 
                            title={selectAllText}
							className="button icon-only-arrow"
							onClick={handleAddSelected}
							disabled={!(props.highlightAvailable.length > 0)}
                            icon={<span className="material-icons">arrow_forward</span>}/>

                        <Button 
                            title={deselectAllText}
							className="button icon-only-arrow"
							onClick={handleRemoveSelected}
							disabled={!(props.highlightSelected.length > 0)}
                            icon={<span className="material-icons">arrow_back</span>} />
                    </div>
                </Grid.Column>
                <Grid.Column width="7" className="vertical-align">
                    <Segment className="no-padding equalize-height w-100">
                        <div className="input-container theme-color">
                            {searchable ? (
                                renderFilter(state.filterSelected, handleChangeFilterSelected)
                            ) : null}
                        </div>

						<ControlButtons
							{...props}
							onClearAll={handleClickDeselectAll.bind(this, {type: "highlightSelected", options: []})}
							onSelectAll={handleClickSelectAll.bind(this, {type: "highlightSelected", options: filterActive()})}
							hasSelection={props.highlightSelected.length > 0} />

                        <div className="contents-container">
                            <List
                                options={filterActive()}
                                disabled={disabled}
                                labelKey={labelKey}
                                valueKey={valueKey}
                                onClick={handleClickSelected} />
                        </div>
                    </Segment>
					<label className="label-container grey-text">
						{props.highlightSelected.length}/{size(filterActive())}
					</label>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

MultiSelect.propTypes = propTypes
MultiSelect.defaultProps = defaultProps

export default MultiSelect