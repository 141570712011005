import React from 'react'
import { List } from 'semantic-ui-react'
import { formTypes } from '../../../../constants'
import BaseTemplate from './BaseTemplate'

import { 
    ImageUploadQuestionPreview, 
    TextFieldQuestionPreview, 
    YesNoQuestionPreview, 
    MultipleChoiceQuestionPreview,
    FileUploadQuestionPreview,
} from './formTypes'

// Depending on the type of form, we need to render the relevant form type
function DynamicForm({item}) {
    
    switch (item.data.formType) {
        case formTypes.textField.post_name:
            return <TextFieldQuestionPreview 
                item={item} />
        case formTypes.yesNo.post_name:
            return <YesNoQuestionPreview
                item={item} />
        case formTypes.multipleChoice.post_name:
            return <MultipleChoiceQuestionPreview
                item={item} />
        case formTypes.imageUpload.post_name:
            return <ImageUploadQuestionPreview
                item={item} />
        case formTypes.fileUpload.post_name:
            return <FileUploadQuestionPreview
                item={item} />
    
        default:
            return <span>Unknown form item</span>;
    }
}

const Form = ({ item}) => {
    return (
        <BaseTemplate render={() => (
            <List.Content>
                <DynamicForm item={item} />
            </List.Content>
        )} />
    )
}

export default Form
