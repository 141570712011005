import React, { useState, Fragment, useEffect } from 'react'

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import { useDispatch } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import i18next from 'i18next'
import { RichTextCleaner } from './RichTextCleaner'
import { isLngSupported, convertMarkup, editorHotFix } from '../../../utils/helper'
import { CustomEmojiPicker } from './CustomEmojiPicker'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { messageBuilder } from '../../../constants'
import { draftToMarkdown } from 'markdown-draft-js'

export const TypeDropdown = (props) => {
    
    const {
        selected,
        onSelectionChange
    } = props

    const options = [
        {key: messageBuilder.MARKUP.html, value: messageBuilder.MARKUP.html, text: 'HTML'},
        {key: messageBuilder.MARKUP.markdown, value: messageBuilder.MARKUP.markdown, text: 'Markdown'},
        // {key: 'plain-text', value: 'plain-text', text: 'Plain Text'},
    ]

    const _default = find(options, {value: selected})

    const [state, setState] = useState(_default ?? options[0])

    const _handleChange = (e, data) => {
        const _selected = find(data.options, {value: data.value})
        setState(_selected)
        
        if (onSelectionChange) {
            onSelectionChange(_selected.value)
        }
    }

    return (
        <div className='rdw-type-wrapper' aria-label='rdw-type-wrapper'>
            <div className='rdw-option-wrapper'>
                <Dropdown
                    text={state?.text ?? i18next.t('components.text.typesDropdown.placeholder')}
                    options={options}
                    value={state.value}
                    onChange={_handleChange} />
            </div>
        </div>
    )
}

export default function RichTextEditor({ content, jsonContent, onChange, label, placeholder }) {
    
    const defaultContentState = {
        "blocks": [
            {
                "key": "637gr",
                "text": "",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
            }
        ],
        "entityMap": {}
    }

    const [data, setData] = useState({
        content: content ?? "",
        jsonContent: jsonContent ?? defaultContentState,
        mediaType:  messageBuilder.MARKUP.markdown
    })

    const { i18n } = useTranslation()

    const checkLngSupport = isLngSupported(i18n.language)
    const isSupported = checkLngSupport.isValid
    const defaultLng = checkLngSupport.defaultLng

    const useAbleLocale = isSupported ? i18next.language : defaultLng

    const contentBlock = convertFromRaw(jsonContent ?? defaultContentState)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentBlock))

    const onEditorChange = _editorState => {

        const _newState = editorHotFix(_editorState)
        const content = convertToRaw(_newState.getCurrentContent())

        const markdown = draftToMarkdown(content, {
            remarkablePreset: 'commonmark',
            preserveNewlines: true,
            remarkableOptions: {
                breaks: true
            }
        })

        setEditorState(_editorState)

        setData((prevData) => ({
            ...prevData,
            content: convertMarkup({
                type: prevData.mediaType,
                rawContents: content,
                convertedMD: markdown
            }),
            jsonContent: content,
        }))
    }

    const dispatch = useDispatch()
    
    useEffect(() => {
        // Trigger manual update of render type
        // _handleOptionChange(data.mediaType)
        onEditorChange(editorState)
    }, [])
    
    useEffect(() => {
        
        // Send Back updates to our callback
        if (onChange) {
            onChange(data.content, data.jsonContent)
        }

    }, [data.content])
    
    return (
        <Fragment>
            {label && <p className='label'>{label}</p>}
            <Editor
                spellCheck
                stripPastedStyles
                editorState={editorState}
                onEditorStateChange={onEditorChange}
                placeholder={data.content ? "" : placeholder}
                toolbarCustomButtons={[
                    <RichTextCleaner key={0} />, 
                    <CustomEmojiPicker key={1} />, 
                    // <TypeDropdown selected={data.mediaType} onSelectionChange={_handleOptionChange} />
                ].filter(Boolean)}
                localization={{
                    locale: useAbleLocale,
                    translations: i18next.getResource(useAbleLocale, "editor")
                }}
                // @TODO: Translate editor for all languages
                toolbar={{
                    options: ['blockType', 'list', 'inline', 'link', 'history'],
                    inline: { inDropdown: false, options: ['bold', 'italic', 'strikethrough'] },
                    // inline: { inDropdown: false, options: ['bold', 'italic', 'strikethrough', 'monospace', 'superscript', 'subscript'] },
                    list: { inDropdown: true },
                    link: { inDropdown: true },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    },
                }}
                plugins={[]}
            />
        </Fragment>
    )
}