import moment from 'moment'
import React, { Fragment } from 'react'
import BaseIconCard from './BaseIconCard'
import BaseTemplate from './BaseTemplate'

const Deadline = ({ item }) => {
    
    const formatDate = (date) => {
        
        if (!moment(date).isValid()) {
            return date
        }

        return moment(date).format("ddd, D MMM 'YY")
    }

    return (
        <BaseTemplate classNames="no-padding no-auto-height icon-card-container" render={() => (
            <BaseIconCard
                icon="stopwatch"
                content={() => (
                    <Fragment>
                        <p className="no-margin deadline black-text">{ formatDate(item.data.date) } <strong>{ item.data.time }</strong></p>
                    </Fragment>
                )} />
        )} />
    )
}

export default Deadline
