import i18next from "i18next"
import { pickBy, identity, flatten, map, find, mapKeys } from "lodash"
import { filterNulls, mailValidator } from "../../utils/helper"

class UpdateUserRequest {

    constructor({
        email,
        lastName,
        firstName, 
        channelIds,
        phoneNumber,
        communityId,
        channelRoles,
        channelRoleIds,
        communityRoleIds,

    }) {
        this.email = email
        this.lastName = lastName
        this.firstName = firstName
        this.channelIds = channelIds
        this.phoneNumber = phoneNumber
        this.communityId = communityId
        this.channelRoles = channelRoles
        this.channelRoleIds = channelRoleIds
        this.communityRoleIds = communityRoleIds
    }


    getPayload = () => {
        
        let payload = {
            "email": this.email,
            "last_name": this.lastName,
            "first_name": this.firstName,
            "phone_number": this.phoneNumber,
            "community_id": this.communityId,
            "channel_roles": this.getInverseRoleChannelSelection(),
            "community_roles": this.communityRoleIds,
        }

        return filterNulls(payload)
    }

    getInverseRoleChannelSelection = () => {
        
        /**
         *  1. Get the selected channels from the selected channel roles, this will return a list
         *  2. Create new attributes for ease of access [role, channels]
         *  3. Upon creation of the new attributes, create the channels with a reference to the  original channel role that was selected on that channel
         *  4. Finally, create a one unified list from the from the x number of channel roles
         * 
         * */
        var channels = flatten(map(map(this.channelRoles, c => {return {role: c.id, channels: map(c.channels, cr => {return {role: c.id, channel: cr}})}}), "channels"))
        
        const _selected = []

        for (const x in channels) {
            const channel = {
                id: channels[x].channel,
                role: channels[x].role
            }

            // Check that the channel is already in the selected list
            const _channel = find(_selected, {channel_id: channel.id})
            if (_channel) {
                
                // When we find that the channel exists, we will append the role into the current list
                _channel.roles = _channel.roles.concat(channel.role)
            } else {
                
                // When there is no existing channel, we create a new
                _selected.push({
                    channel_id: channels[x].channel,
                    roles: [channels[x].role]
                })
            }
        }

        return _selected
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
     validator = () => {
        
        const errors = []
        
        // if (!this.email || !mailValidator.test(this.email)) {
        //     errors.push({
        //         field: "email",
        //         isValid: false,
        //         message:!this.email ? i18next.t('requestObjects.CreateMemberRequest.requiredEmail') : i18next.t('requestObjects.CreateMemberRequest.invalidEmail'),
        //     })
        // }
        
        // if (!this.firstName) {
        //     errors.push({
        //         field: 'firstName',
        //         isValid: false,
        //         message: i18next.t('requestObjects.CreateMemberRequest.requiredFirstName')
        //     })
        // }
        
        // if (!this.lastName) {
        //     errors.push({
        //         field: 'lastName',
        //         isValid: false,
        //         message: i18next.t('requestObjects.CreateMemberRequest.requiredLastName')
        //     })
        // }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }
    
}

export default UpdateUserRequest