import AuthService from "./AuthService"
import Axios from "axios"
import Config from "../config"

import { constants } from '../constants'
import { isArray, isEmpty, map } from "lodash"
import { endpoints } from "../api/endpoints"
import store from "../store"
import { authActions } from "../actions/authActions"
import { UpdateCommunityRequest, UpdateProfileRequest, UpdateUserRequest } from "../api/requestObjects"
import Bugsnag from "@bugsnag/browser"
import { push } from "connected-react-router"
import routes from "../routes/route-constants"

const { STORAGE_ACCESS_TOKEN } = constants

// We only need one instance of AuthService since we have classes that extend the ApiService
const authService = new AuthService()

class ApiService {

    constructor() {

        this.authService = authService
        this.API = Axios
        this.baseUrl = Config.baseUrl
        this.endpoints = endpoints
        this.retry = 0
        this.maxRetryCount = 2
        this._setupInterceptors()
    }

    /**
     * Configure Request and Response Interceptors
     * 
     */
    _setupInterceptors = () => {

        // Setup  Request interceptor to embed token
        this.API.interceptors.request.use(config => {

            // Embed token to requests
            const accessToken = localStorage.getItem(STORAGE_ACCESS_TOKEN)
            const locale = localStorage.getItem("i18nextLng") ?? 'en'

            if (accessToken) {
                config.headers['Accept'] = 'application/json'
                config.headers['Authorization'] = `Bearer ${accessToken}`
            }

            config.headers['Accept-Language'] = `${locale};q=0.7, *;q=0.5`

            return config
        }, error => {
            // Do Nothing
            return error
        })

        // Setup response interceptor
        this.API.interceptors.response.use(config => {

            // Just freshening up the token
            if (config.headers && config.headers.Authorization) {
                localStorage.setItem(constants.STORAGE_ACCESS_TOKEN, config.headers.Authorization)
            }

            return config
        }, error => {

            return new Promise((resolve, reject) => {

                // Check if the message has something to do with the User
                if (error.response.status === 401 && error.response.data.message === "User not found") {
                    return reject(error)
                } else if (error.response.status === 401 && [
                    'token_expired',
                    'Token has expired'
                ].includes(error.response.data.message)) {
                    // Handle error requests that need Authorization

                    // TODO: Remove this later, for debugging logout issue from Safari
                    Bugsnag.notify({
                        name: "Authentication Error",
                        errorMessage: JSON.stringify(error.response),
                    })

                    // Need to refresh token
                    this.renewToken().then(renewedUser => {

                        //Save Token
                        this._saveToken(renewedUser.access_token)

                        // Replay  failed request
                        this.API(error.response.config)
                            .then(response => resolve(response))
                            .catch(_error => reject(_error))

                    }).catch(e => {

                        // TODO: Remove this later, for debugging logout issue from Safari
                        Bugsnag.notify({
                            name: "Token Refresh Error",
                            errorMessage: JSON.stringify(e.response),
                        })

                        // When we were unable to refresh token, we should do a full login
                        store.dispatch(authActions.finishInitialise())
                        store.dispatch(authActions.doLogout())
                    })

                } else if (error.response.status === 403 && [
                    'Required scopes not found.',
                ].includes(error.response.data.message)) {

                    // We need to check if we havent already retried this request
                    if (this.retry > this.maxRetryCount) {
                        store.dispatch(push(routes.private.NOT_ALLOWED))
                        store.dispatch(authActions.error(error))
                        reject({ response: { data: { message: 'NOT ALLOWED.' } } })

                        // Clear retry-attempts
                        this.retry = 0

                        return reject(error);
                    }

                    // The user has reached here because the need to refresh new scopes
                    this.renewToken().then(renewedUser => {

                        //Save Token
                        this._saveToken(renewedUser.access_token)

                        // Replay  failed request
                        this.API(error.response.config)
                            .then(response => resolve(response))
                            .catch(_error => reject(_error))

                    }).catch(e => {

                        // When we were unable to refresh token, we should do a full login
                        store.dispatch(authActions.finishInitialise())
                        store.dispatch(authActions.doLogout())
                    }).finally(_ => this.retry++)

                } else {

                    if (error?.response?.status === 401 && ['token_invalid'].includes(error?.response?.data?.message)) {
                        store.dispatch(push(routes.private.LOGOUT_REDIRECT))
                        store.dispatch(authActions.error(error))
                        return reject(error)
                    }

                    // When a user is not authorized to view this resource, show block screen
                    if (error?.response?.status === 403) {
                        store.dispatch(push(routes.private.NOT_ALLOWED))
                        store.dispatch(authActions.error(error))
                        reject({ response: { data: { message: 'NOT ALLOWED.' } } })
                        return;
                    }

                    // Return original error
                    return reject(error)
                }
            })
        })
    }

    /**
     * Get the instance of the UserManager
     * 
     * @returns {UserManager}
     */
    getManager = () => this.authService.getManager()

    /**
     * Get user from SSO
     */
    getUser = () => this.authService.getUser()

    /**
     * Logout
     */
    logout = () => this.authService.logout()

    /**
     * Login
     */
    login = () => this.authService.login()

    /**
     * Clean UserManage on Logout
     */
    cleanLogout = () => this.authService.completeLogout()

    /**
     * Renew token
     */
    renewToken = () => this.authService.renewToken()

    /**
     * Save access token to localStorage for later usage
     * 
     * @param {String} token 
     */
    _saveToken = token => localStorage.setItem(STORAGE_ACCESS_TOKEN, token)

    /**
     * Fetch user profile
     * 
     * @returns {Promise}
     */
    getMeProfile = (params) => {
        const { GET_PROFILE } = this.endpoints

        const payload = {
            version: 2,
        }

        if (params.communityId) {
            payload['community_id'] =  params.communityId
        }

        return this.API.get(this.getEndpoint({ 
            path: GET_PROFILE,
            params: payload
        }))
    }

    /**
     * Fetch community data
     * 
     * @returns {Promise}
     */
    getCommunity = (params) => {

        const { GET_COMMUNITY } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_COMMUNITY,
            data: params.communityId
        }))
    }

    /**
     * Post update community data
     * 
     * @returns {Promise}
     */
    postUpdateCommunity = (params) => {

        const { POST_UPDATE_COMMUNITY } = this.endpoints
        const updateRequest = new UpdateCommunityRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_UPDATE_COMMUNITY,
            data: updateRequest.id
        }), updateRequest.getPayload())
    }


    /**
     * Mock an api request using {Mockoon}
     * 
     * @param {JSON} params
     * @returns {import("axios").AxiosResponse}
     */
    mock = async (params) => {
        return this.API.get("http://localhost:3001/api/v1/mock-response")
    }
    
    /**
     * Mock a file api request using {Mockoon}
     * 
     * @param {JSON} params 
     * @returns {import("axios").AxiosResponse}
     */
    mockFile = async (params) => {
        return this.API({
            responseType: "blob",
            method: "GET",
            data: params,
            url: "http://localhost:3001/api/v1/mock-response"
        })
    }

    /**
     * Fetch billing
     * 
     * @returns {Promise}
     */
    getBilling = () => {
        const { GET_BILLING } = this.endpoints

        return this.getFakeResponse({
            "id": "92f5171e-938f-4dde-8b4a-c0aadb1fe364",
            "card_number": "Visa **** *** **** 892",
            "expires_at": "03/2022",
            "last_payment_date": "1 March '22",
            "last_payment_amount": "For R1,208.00",
            "next_payment_date": "1 April '22",
            "saas_fee": "R 130.00",
            "sms_costs": "R693.23",
            "payment_plan_name": "Basic Plan",
            "payment_plan_amount": "R549",
            "payment_plan_due": "Next payment is scheduled for Jul 22,2021.",
            "transactions": [
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
            ]
        })
    }

    /**
     * Get the SSO profile details
     * 
     * @return {Promise}
     */
    registerNewUpdate = () => {

        const { DO_LOGIN_OR_REGISTER } = this.endpoints

        return this.API.post(this.getEndpoint({ path: DO_LOGIN_OR_REGISTER }))
    }

    /**
     * Update profile email
     * 
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postUpdateEmail = (params) => {

        const { PUT_UPDATE_SSO_EMAIL } = this.endpoints
        const request = new UpdateProfileRequest(params)

        return this.API.post(this.getSSOEndpoint({
            path: PUT_UPDATE_SSO_EMAIL
        }), request.getEmailUpdatePayload())
    }

    /**
     * Update profile phone number
     * 
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postUpdatePhone = (params) => {

        const { PUT_UPDATE_SSO_PHONE } = this.endpoints
        const request = new UpdateProfileRequest(params)

        return this.API.post(this.getSSOEndpoint({
            path: PUT_UPDATE_SSO_PHONE
        }), request.getPhoneUpdatePayload())
    }

    /**
     * Post OTP to update profile phone number
     * 
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postValidatePhone = (params) => {

        const { POST_VERIFY_PHONE } = this.endpoints
        const request = new UpdateProfileRequest(params)

        return this.API.post(this.getSSOEndpoint({
            path: POST_VERIFY_PHONE
        }), request.getPhoneValidatePayload())
    }

    /**
     * Post OTP to update profile email address
     * 
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postValidateEmail = (params) => {

        const { POST_VERIFY_EMAIL } = this.endpoints
        const request = new UpdateProfileRequest(params)

        return this.API.post(this.getSSOEndpoint({
            path: POST_VERIFY_EMAIL
        }), request.getEmailValidatePayload())
    }

    /**
     * Post update profile details
     * 
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postUpdateProfile = (params) => {

        const { PUT_UPDATE_SSO_PROFILE } = this.endpoints
        const request = new UpdateProfileRequest(params)

        return this.API.put(this.getSSOEndpoint({
            path: PUT_UPDATE_SSO_PROFILE
        }), request.getPayload())
    }

    /**
     * Fetch user profile
     * 
     * @returns {Promise}
     */
    registerOrLogin = async (params) => {
        const { DO_LOGIN_OR_REGISTER } = this.endpoints

        const response = await this.API.post(this.getEndpoint({ path: DO_LOGIN_OR_REGISTER }))

        // Is the user Registered?
        if (response.data && response.data.registered) {
            // return apiService.getMeProfile()
        }

        // User not registered, show more details
        if (response.data && !response.data.registered) {
            // return Promise.reject({response: {data: {message: "no_link"}}})
        }

        // User not registered, show more details
        if (response.data && response.data.details_required) {
            return Promise.reject({ response: { data: { message: "details_required" } } })
        }

        return await this.getMeProfile(params)
    }

    /**
     * Fake an API response by returning the JSON data  via a Promise
     * 
     * @param {JSON} jsonData 
     */
    getFakeResponse = (jsonData) => {
        return Promise.resolve({ data: jsonData })
    }

    /**
     * API Error response by returning the JSON data  via a Promise
     * 
     * @param {JSON} jsonData 
     */
    getErrorResponse = (jsonData) => {
        return Promise.reject({ response: { data: jsonData } })
    }

    /**
     * Return API endpoint with given path
     * 
     * @return 	{String}
     */
    getEndpoint({ path, data, version = "v1", params }) {

        var url = ""

        if (data) {

            if (isArray(data)) {
                url = (this.baseUrl + "/" + version).concat(path.replace("%s", data[0]).replace("%ss", data[1]))
            } else {
                url = (this.baseUrl + "/" + version).concat(path.replace("%s", data))
            }
        } else {
            url = (this.baseUrl + "/" + version).concat(path)
        }

        if (!isEmpty(params)) {

            let _url = url.concat("?")
            let _params = ""

            map(params, (value, key) => _params = _params.concat(key + "=" + value).concat("&"))

            _params = _params.substring(0, _params.lastIndexOf("&"))

            return _url.concat(_params)
        }

        return url
    }

    /**
     * Return API endpoint with given path
     * 
     * @return 	{String}
     */
    getSSOEndpoint({ path }) {
        return this.authService.ssoBaseUrl().concat(path)
    }

    /**
     * Upload the given file into Relay Uploads
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
     mock = async (params) => {
        return this.API.get("http://localhost:3001/api/v1/mock")
    }
}

export default ApiService