import { identity, pickBy } from "lodash"
import { dropEmptyKeys, filterNulls } from "../../utils/helper"

class GetAppDownloadInvitesBreakdownByFiltersRequest {

    constructor({
        sort, 
        page,
        search,
        tagIds,
        hasApp,
        channelIds,
        isFollower,
        communityId,
        strategy,
        includeSMS,
        includeEmail,
        includeWhatsApp,
    }) {
        this.page = page
        this.sort = sort
        this.tagIds = tagIds
        this.hasApp = hasApp
        this.search = search
        this.isFollower = isFollower
        this.channelIds = channelIds
        this.communityId = communityId
        this.includeSMS = includeSMS
        this.strategy = strategy
        this.includeEmail = includeEmail
        this.includeWhatsApp = includeWhatsApp
    }

    getPayload = () => {
        
        const payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "community_id": this.communityId,
            "tag_ids": [].concat(this.tagIds).join(","),
            "channel_ids": [].concat(this.channelIds).join(","),
            "strategy": this.strategy,
            "include_sms": this.includeSMS,
            "include_email": this.includeEmail,
            "include_whatsapp": this.includeWhatsApp,
        }

        if (this.hasApp !== -1) {
            payload["has_app"] = this.hasApp
        }
        
        if (this.isFollower !== -1) {
            payload["is_public_follower"] = this.isFollower
        }

        return dropEmptyKeys(filterNulls(payload))
    }
    
}

export default GetAppDownloadInvitesBreakdownByFiltersRequest