import i18next from "i18next"
import { pickBy, identity, mapKeys } from "lodash"

/**
 * 
 * @typedef Request
 * @property {String} name
 * @property {String} authCode
 * @property {String} communityId
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostCreateSyncAccountRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        name,
        authCode,
        communityId,
    }) {
        this.name = name
        this.authCode = authCode
        this.communityId = communityId
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        let payload = {
            // "name": this.name,
            "auth_code": this.authCode,
            "community_id": this.communityId,
        }

        return pickBy(payload, identity)
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        // if (!this.name) {
        //     errors.push({
        //         field: "name",
        //         isValid: false,
        //         message: i18next.t("calendar.accounts.requiredName")
        //     })
        // }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostCreateSyncAccountRequest