import React, { Fragment, useEffect, useMemo, useState } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid, Dropdown, Popup, Checkbox, TextArea, Label, Image, Flag } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'

import moment from 'moment'
import SearchLocationInput from '../../../utils/SearchLocationInput'
import i18next from 'i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError, isLngSupported, renderMarkup, transformDataToSelectedEvent } from '../../../utils/helper'
import ReactDatePicker from 'react-datepicker'

import "react-datepicker/dist/react-datepicker.css"
import CustomDateInput from '../../partials/CustomDateInput'
import { useTranslation } from 'react-i18next'
import { calendarConstants, messageBuilder } from '../../../constants'
import { differenceBy, isEmpty, isFunction, isNil, map, mapKeys, size, take } from 'lodash'
import DebouncedSearchInput from '../../partials/DebouncedSearchInput'
import { eventService } from '../../../api'
import ConfirmModal from '../../partials/ConfirmModal'
import EventSearchDropdown from '../../partials/EventSearchDropdown'
import WarnTitleLimitReached from '../../partials/WarnTitleLimitReached'
import StatefulDebouncedSearchInput from '../../partials/StatefulDebouncedSearchInput'
import EventNotesRichTextEditor from './EventNotesRichTextEditor'


const CustomDropDownItem = (props) => {
    
    const { item } = props

    return (
        <div className='text'>
            <div className='seg seg-1'>
                <span className='title'>{item.title}</span>
                <span className='location'>{`<${!isEmpty(item.location) ? item.location : i18next.t('calendar.search.results.noLocation')}>`}</span>
            </div>
            <div className='seg seg-2'>
                <span className='day-container'>{item.day}</span>
                <span>{item.start_end_times}</span>
            </div>
        </div>
    )
}

const RenderExistingEvent = (props) => {
    const {
        item,
        data,
        errors,
        dispatch,
        communityId,
        onSearchChange,
        selectedEvents,
        onExistingChange,
    } = props

    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState(data.title)
    const [events, setEvents] = useState([])
    const [menuOpen, setMenuOpen] = useState(false)
    
    const [confirm, setConfirm] = useState({
        showModal: false,
        showConfirm: true,
        onConfirm: () => {},
        onClose: () => {},
        message: "",
        header: "",
    })

    // When arrive here from a new event, we should show the modal to confirm groups if any
    useEffect(() => {
        
        const _data = data?.selectedEvent?._click_meta
        const isFromNewEvent = data?.isFromNewEvent

        if (isFromNewEvent && _data) {
            handleConfirmAddRecipient(_data)
        }

    }, [])
    
    useEffect(() => {
        
        const payload = {
            search: searchTerm,
            perPage: 100,
            sort: '-start_date',
            communityId,
            channelIds: []
        }
        
        handleSearchEvents(payload)

    }, [searchTerm])
    
    const handleSearchChange = (value) => {
        setSearchTerm(value)
        onSearchChange(value)

        if (isEmpty(value)) {
            onExistingChange(null)
        }
    }

    const handleSearchEvents = (payload) => {
        
        setLoading(true)

        eventService.searchBuilderEvents(payload)
            .then(response => {
                let data = response?.data?.data ?? []
                data = map(data, c => {

                    return transformDataToSelectedEvent({
                        id: c.id,
                        title: c.title,
                        source: c.source,
                        endDate: c.end_date,
                        location: c.location,
                        startDate: c.start_date,
                        className: 'search-result-item',
                        text: (<CustomDropDownItem item={c} />)
                    }, c)
                })
                setEvents(data)
            })
            .catch(e => setEvents([]))
            .finally(() => setLoading(false))
    }

    const handleEventClick = (selected) => {
        
        const _data = selected._click_meta
        setMenuOpen(false)
        onExistingChange(selected)
        // Add Groups or not
        handleConfirmAddRecipient(_data)
    }

    const handleCloseConfirm = () => {
        setConfirm({
            showModal: false,
            onConfirm: () => {},
            onClose: () => {},
            message: "",
            header: "",
        })
    }
    
    const handleConfirmAddRecipient = (selectedEvent) => {
        
        // Often there will be events that have no channels or persons
        if (size(selectedEvent.channels) === 0 && size(selectedEvent.persons) === 0 && !selectedEvent.targets_everyone) {
            return;
        }

        const persons = map([...selectedEvent.persons], u => {
            const name = `${u.first_name} ${u.last_name}` ?? u.full_name
            return {
                name: !isEmpty(name.trim()) ? name : i18next.t("general.noName")
            }
        })

        const all = [...selectedEvent.channels]
        let content = ''
        const _limit = 10

        if (size(all) > _limit) {
            content = [].concat(map(differenceBy(all, take(all, _limit), "name"), "name")).join(", ")
        }

        setConfirm({
            showModal: true,
            showConfirm: true,
            onConfirm: () => handleAddNewRecipients(selectedEvent),
            onClose: () => handleCloseConfirm(),
            message: (
                <div className='labels-container event-detail align items-center'>
                    <p className='no-margin'>{i18next.t('event.component.addgroups.confirm.message')}</p>
                    
                    {selectedEvent.targets_everyone && (
                        <Label key={`item-everyone`} as='a' className='secondary'>
                            {i18next.t("messageBuilder.recipients.targetAll")}
                        </Label>
                    )}
                    
                    {persons.length > 0 && (
                        <Popup 
                            basic
                            content={persons.map(c => c.name).join(", ")}
                            trigger={
                                <Label key={`item-persons`} as='a' className='secondary'>
                                   {`${i18next.t("modal.addUsers.button.addIndividuals")} (${persons.length})`}
                                </Label>
                            }/>
                    )}
                    
                    {map(take(all, _limit), (channel, index) => (
                        <Label key={`item-channels-${index}`} as='a' className='color-primary-light'>
                            {channel.name}
                        </Label>
                    ))}
                    
                    {(size(all)-_limit > 0) && (
                        <Popup 
                            basic
                            content={content} 
                            trigger={
                                <Label as='a' className='color-primary-light'>
                                    <strong>+{size(all)-_limit}</strong>
                                </Label>
                            }/>
                    )}

                    <p className='no-margin'>{i18next.t('event.component.addgroups.confirm.message-ending')}</p>
                </div>
            ),
            header: i18next.t('event.component.addgroups.confirm.header'),
        })
    }

    const handleAddNewRecipients = (selectedEvent) => {
        
        handleCloseConfirm()
        
        // Save the flag to merge_channels if the user confirmed to add new channels
        dispatch(builderActions.saveTemplateData({
            item, 
            data: {
                ...item.data,
                merge_channels: true,
            }
        }))

        // Add the new channels to the builder
        dispatch(builderActions.saveRecipients({
            users: map(selectedEvent.persons, "id"),
            channels: map(selectedEvent.channels, "id"),
            targetEveryone: selectedEvent.targets_everyone,
        }))
    }

    return (
        <Grid.Row>
            <Grid.Column>
                <EventSearchDropdown
                    open={menuOpen}
                    openOnFocus
                    className='search-dropdown'
                    search
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                    icon={null}
                    searchInput={
                        <Dropdown.Menu open className='relative-important z-depth-0'>
                            <Dropdown.Item onClick={() => onExistingChange(null)} className='search-result-item' image={data?.selectedEvent.image} text={
                                <CustomDropDownItem item={data?.selectedEvent} />
                            } />
                        </Dropdown.Menu>
                    } />
                <ErrorLabel error={getError("requiredEvent", errors)} />
            </Grid.Column>
        </Grid.Row>
    )
}

const RenderNewEvent = (props) => {
    
    const {
        data,
        minTime,
        maxTime,
        errors,
        communityId,
        minStartTime,
        repeatOptions,
        onSearchChange,
        selectedEvents,
        onExistingChange,
        handleInputChange,
        handleDescriptionChange,
        handleDateTimeChange,
        handleRepeatChange,
        handleSwitchChange,
        handleLocationChange,
    } = props

    const community = useSelector(state => state.navigation.community)

    const [loading, setLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState(data.title)
    const [isFocused, setFocus] = useState(false)
    const [events, setEvents] = useState([])

    useEffect(() => {
        
        const payload = {
            search: searchTerm,
            perPage: 100,
            sort: '-start_date',
            communityId,
            channelIds: []
        }
        
        handleSearchEvents(payload)

    }, [searchTerm])

    const handleSearchChange = (value) => {
        setSearchTerm(value)
        onSearchChange(value)
    }

    const handleSearchEvents = (payload) => {
        
        setLoading(true)

        eventService.searchBuilderEvents(payload)
            .then(response => {
                let data = response?.data?.data ?? []
                data = map(data, c => {

                    return transformDataToSelectedEvent({
                        id: c.id,
                        title: c.title,
                        source: c.source,
                        endDate: c.end_date,
                        location: c.location,
                        startDate: c.start_date,
                    }, c)
                })
                setEvents(data)
            })
            .catch(e => setEvents([]))
            .finally(() => setLoading(false))
    }

    const handleEventClick = (selected) => {
        
        onExistingChange(selected, true)
    }

    const handleContentChange = (value, jsonContent, markupType) => {
        handleDescriptionChange(value, jsonContent, markupType)
	}

    return (
        <Fragment>

            <Grid.Row className='no-pad-bot'>
                {/* <Grid.Column>
                    <div className="input-container theme-color">
                        <label className='label'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.eventTitle')}</label>
                        <Input
                            name="title"
                            value={data.title}
                            onChange={handleInputChange}
                            autoComplete="off"
                            placeholder={i18next.t('messageBuilder.morphedTemplates.EventMorphed.eventTitle')} />
                    </div>
                    <ErrorLabel error={getError("title", errors)} />
                </Grid.Column> */}

                <Grid.Column className='event-title-container'>
                    <EventSearchDropdown
                        openOnFocus
                        className='search-dropdown'
                        search
                        icon={null}
                        // searchQuery={searchTerm ?? ""}
                        // onFocus={() => {
                        //     handleSearchChange('')
                        //     setFocus(true)
                        // }}
                        searchInput={
                            <StatefulDebouncedSearchInput
                                id="name-input" 
                                name="searchTerm" 
                                loading={loading}
                                autoFocus
                                defaultValue={searchTerm}
                                title={i18next.t('messageBuilder.morphedTemplates.EventMorphed.exitsting.eventTitle')}
                                placeholder={i18next.t('DebouncedSearchInput.calendar.placeholder')}
                                eventSearch
                                autoSelect
                                ignoreEncoding
                                limit={isNil(data.originalId) ? messageBuilder.MAX_EVENT_TITLE_LENGTH : null}
                                onChange={(e, {value}) => handleSearchChange(value) } />
                        }>
                        <Dropdown.Menu>
                            <Dropdown.Menu scrolling>
                                {events.map((item) => (
                                    <Dropdown.Item
                                        disabled={!isNil(selectedEvents[item.id])}  
                                        onClick={() => handleEventClick(item)} 
                                        className='search-result-item' 
                                        key={item.id} 
                                        image={item.image} 
                                        text={
                                            <CustomDropDownItem item={item} />
                                        } />
                                ))}
                                {(events.length === 0 && !isEmpty(searchTerm) && !loading) ? (
                                    <Dropdown.Item key={'events-empty-key'} className='search-result-item' text={
                                        <div className='no-results'>
                                            <div className='seg seg-1'>
                                                <span className='title'>{i18next.t('calendar.events.component.search.no-results')}</span>
                                            </div>
                                            <div className='seg seg-2'>
                                                
                                            </div>
                                        </div>
                                    } />
                                ) : null }
                    
                            </Dropdown.Menu>
                        </Dropdown.Menu>
                    </EventSearchDropdown>
                    {/* On create new component, we can block the user from entering title if they reach max, but for existing components on update that might have longer, we just warn them and still allow them */}
                    <WarnTitleLimitReached
                        limit={messageBuilder.MAX_EVENT_TITLE_LENGTH} 
                        value={searchTerm}
                        showWarning={!isNil(data.originalId)}
                        message={i18next.t('messageBuilder.morphedTemplates.EventMorphed.maxChars', {
                            limit: messageBuilder.MAX_EVENT_TITLE_LENGTH
                        })} />
                    <ErrorLabel error={getError("title", errors)} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className='no-pad-bot'>
                <Grid.Column>
                    <EventNotesRichTextEditor
                        label={i18next.t('messageBuilder.morphedTemplates.EventMorphed.description')}
                        mediaType={data.mediaType}
                        content={data.content}
                        jsonContent={data.jsonContent}
                        placeholder={i18next.t("components.morphed.text.placeholder")}
                        onChange={handleContentChange} />
                    <ErrorLabel error={getError("description", errors)} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className='no-pad-bot'>
                <Grid.Column width={8}>
                    <div className="input-container theme-color">
                        <label className='label'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.startDate')}</label>
                        <ReactDatePicker
                            className="date-picker"
                            selected={data.startDate}
                            onChange={date => handleDateTimeChange({ name: "startDate", date: date })}
                            dateFormat="yyyy/MM/dd"
                            customInput={<CustomDateInput icon="calendar" />} />
                    </div>
                </Grid.Column>
                {!data.allDay && (
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.startTime')}</label>
                            <ReactDatePicker
                                popperClassName="date-picker time-only"
                                selected={data.startDate}
                                onChange={date => handleDateTimeChange({ name: "startDate", date: date })}
                                showTimeSelect
                                dateFormat="hh:mm a"
                                timeIntervals={15}
                                minTime={minStartTime}
                                maxTime={maxTime}
                                customInput={<CustomDateInput icon="time" />} />
                        </div>
                    </Grid.Column>
                )}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <div className="input-container theme-color">
                        <label className='label'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.endDate')}</label>
                        <ReactDatePicker
                            className="date-picker"
                            selected={data.endDate}
                            onChange={date => handleDateTimeChange({ name: "endDate", date: date })}
                            dateFormat="yyyy/MM/dd"
                            minDate={data.startDate}
                            customInput={<CustomDateInput icon="calendar" />} />
                    </div>
                    <ErrorLabel error={getError("endDate", errors)} />
                </Grid.Column>
                {!data.allDay && (
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.endTime')}</label>
                            <ReactDatePicker
                                popperClassName="date-picker time-only"
                                selected={data.endDate}
                                onChange={date => handleDateTimeChange({ name: "endDate", date: date })}
                                showTimeSelect
                                dateFormat="hh:mm a"
                                timeIntervals={15}
                                minTime={minTime} // We need to exempt this min time when the dates are not the same date
                                maxTime={maxTime}
                                customInput={<CustomDateInput icon="time" />} />
                        </div>
                    </Grid.Column>
                )}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <div className='vertical-align custom-switch no-justification'>
                        <Checkbox
                            id={"remind"}
                            checked={data.remind}
                            onChange={e => handleSwitchChange({ name: "remind" })} />
                        <label htmlFor='remind' className='caption primary-text'>{i18next.t('event.input.remind.placeholder')}</label>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <div className="input-container theme-color">
                        <label className='label' htmlFor="name-input">{i18next.t('event.input.repeat.placeholder')}</label>
                        <Dropdown
                            fluid
                            selection
                            onChange={handleRepeatChange}
                            placeholder={i18next.t('event.input.repeat.placeholder')}
                            value={data.repeat}
                            options={repeatOptions} />
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column className="w-50">
                    <div className='vertical-align custom-switch no-justification'>
                        <span className='caption'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.allDay')}</span>
                        <Checkbox
                            checked={data.allDay}
                            onChange={e => handleSwitchChange({ name: "allDay" })} />
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <SearchLocationInput
                        placeholder={i18next.t('messageBuilder.morphedTemplates.EventMorphed.searchLocation')}
                        name="location"
                        defaultValue={data.location}
                        onChange={handleLocationChange} />
                </Grid.Column>
            </Grid.Row>
            {community.can_view_calendars && (
                <Grid.Row>
                    <Grid.Column className="w-50">
                        <div className='vertical-align custom-switch no-justification'>
                            <Checkbox
                                checked={data.addToCalendar}
                                onChange={e => handleSwitchChange({ name: "addToCalendar" })} />
                            <span className='caption'>{i18next.t('messageBuilder.morphedTemplates.EventMorphed.addToCalendar')}</span>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            )}
        </Fragment>
    )
}

export default function EventMorphed({item}) {
    
    const defaultContentState = {
        "blocks": [
            {
                "key": "637gr",
                "text": "",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
            }
        ],
        "entityMap": {}
    }

    const navigation = useSelector(state => state.navigation)
    const builder = useSelector(state => state.builder.contentTemplates)

    const startDate = (item.data && item.data.startDate) ? moment(`${item.data.startDate}T${item.data.startTime}`) : moment()
    const endDate = (item.data && item.data.endDate) ? moment(`${item.data.endDate}T${item.data.endTime}`) : moment()
    const maxTime = moment().endOf('day').toDate()

    const options = map(calendarConstants.EVENT_REPEAT_OPTIONS, r => {return {key: r.key, value: r.key, text: r.description}})
    const [repeatOptions, setOptions] = useState(options)

    const [data, setData] = useState({
        remind: false,
        repeat: options[0].key,
        title: item.data ? item.data.title : "",
        remindMinutes: calendarConstants.DEFAULT_REMIND_MINUTES,
        startDate: startDate.isValid() ? new Date(startDate.toISOString()) : new Date(),
        endDate: endDate.isValid() ? new Date(endDate.toISOString()) : new Date(),
        startTime: startDate.isValid() ? new Date(startDate.toISOString()) : new Date(),
        endTime: endDate.isValid() ? new Date(endDate.add(1, 'minute').toISOString()) : new Date(),
        allDay: item.data ? item.data.allDay : false,
        addToCalendar: item.data ? item.data.addToCalendar : true,
        location: item?.data?.location ?? "",
        originalPlace: item.data ? item.data.originalPlace : null,
        content: item.data ? item.data.content : "",
        jsonContent: item.data ? item.data.jsonContent : defaultContentState,
        originalId: item?.data?.originalId,
        eventId: item?.data?.eventId,
        merge_channels: item?.data?.merge_channels ?? true,
        selectedEvent: item?.data?.selectedEvent,
        type: isEmpty(item?.data?.eventId) ? 0 : 1,
        searchTerm: item?.data?.searchTerm,
        mediaType:  item?.data?.mediaType ?? messageBuilder.MARKUP.plain
    })

    const [minTime, setMinTime] = useState(data.startTime)
    const [minStartTime, setMinStartTime] = useState(new Date())

    const [types] = useState([
        {
            key: 0,
            value: 0,
            text: i18next.t('messageBuilder.morphedTemplates.EventMorphed.newEvent')
        },
        {
            key: 1,
            value: 1,
            text: i18next.t('messageBuilder.morphedTemplates.EventMorphed.existingEvent')
        }
    ])

    const dispatch = useDispatch()

    const { i18n } = useTranslation()
    const checkLngSupport = isLngSupported(i18n.language)
    const isSupported = checkLngSupport.isValid
    const defaultLng = checkLngSupport.defaultLng
    const useAbleLocale = isSupported ? i18next.language : defaultLng

    useEffect(() => {
        const isPast = moment(data.startDate).isBefore(moment())
        const startDate = moment(data.startDate).toDate()
        const isSameDay = moment(startDate).isSame(moment(data.endDate), "day")
        const _minTime = isSameDay ? moment(startDate).toDate() : moment(startDate).startOf("day").toDate()
        const _minStartTime = moment(startDate).isSame(moment(), "day") ? moment().toDate() : moment(startDate).startOf("day").toDate()

        setMinTime(_minTime)
        setMinStartTime(_minStartTime)

        let _selectedEvent = data.selectedEvent ?? null

        if (item?.data?.eventId && isNil(data.selectedEvent)) {

            _selectedEvent = transformDataToSelectedEvent({
                ...data,
                id: data.eventId,
            })
        }

        dispatch(builderActions.saveTemplateData({
            item, 
            data: {
                ...data,
                eventId: _selectedEvent?.id ?? null,
                selectedEvent: _selectedEvent ?? null,
                startDate: moment(startDate).format("yyyy-MM-DD"),
                startTime: moment(startDate).format("HH:mm"),
                endDate: moment(data.endDate).format("yyyy-MM-DD"),
                endTime: moment(data.endDate).format("HH:mm"),
                previewText: moment(startDate).format("D MMM 'YY H:mm"),
                previewStartDate: moment(startDate).format("D MMM 'YY"),
                previewStartTime: moment(startDate).format("H:mm"),
                previewEndDate: moment(data.endDate).format("D MMM 'YY"),
                previewEndTime: moment(data.endDate).format("H:mm"),
            }
        }))

        return () => {}
    }, [data, dispatch])
    
    
    // 1. When the start date gets updated, and its ahead of the end date, we should offset the end date time by a minute
    useEffect(() => {

        const isSameDay = moment(data.startDate).isSame(moment(data.endDate), "day")
        const isAfter = moment(data.startDate).isSameOrAfter(moment(data.endDate))

        const _minTime = isSameDay ? moment(data.startDate).toDate() : moment(data.startDate).startOf("day").toDate()
        setMinTime(_minTime)

        if (isAfter) {
            setData((prevData) => ({
                ...prevData,
                endDate: moment(data.startDate).add(1, 'minute').toDate(),
            }))
        }

        // When the are changes to the start date, we should update the repeat options to replace the remind date
        const _day = moment(data.startDate).format("dddd")
        const _options = map(calendarConstants.EVENT_REPEAT_OPTIONS, r => {return {key: r.key, value: r.key, text: r.description.replace("{DAY}", _day)}})
        setOptions(_options)

    }, [data.startDate])

    // 2. When the AllDay boolean gets toggled we need to make sure we have the correct default times
    useEffect(() => {

        // Check that the current date-time is not in the past
        const isPast = moment(data.startDate).isBefore(moment())
        const startDate = moment(data.startDate)
        const isSameDay = startDate.isSame(moment(data.endDate), "day")
        const isAfter = startDate.isSameOrAfter(moment(data.endDate))

        const _minTime = isSameDay ? startDate.toDate() : startDate.clone().startOf("day").toDate()
        setMinTime(_minTime)

        setData((prevData) => ({
            ...prevData,
            startDate: startDate.toDate(),
            endDate: isAfter ? startDate.clone().add(1, 'minute').toDate() : prevData.endDate,
        }))

    }, [data.allDay])

    
    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }
    
    const onSearchChange = (value) => {
        setData(prev => ({...prev, searchTerm: value}))
    }
    
    const onNewEventSearchChange = (value) => {
        setData(prev => ({...prev, title: value}))
    }

    const handleChange = (e, {value}) => {
        setData(prev => ({
            ...prev,
            type: value,
            title: isEmpty(prev.title) ? prev.searchTerm : prev.title
        }))
    }
    
    const handleRepeatChange = (e, data) => {
        setData(prev => ({
            ...prev,
            repeat: data.value,
        }))
    }
    
    const handleSwitchChange = ({name}) => {
        setData({...data, [name]: !data[name]})
    }

    const handleLocationChange = (placeDetails) => {
        
        const { address, location, original } = placeDetails

        setData((prevData) => ({
            ...prevData,
            location: address,
            // previewText: address,
            latitude: location.lat,
            longitude: location.lng,
            originalPlace: {},
            // originalPlace: original,
        }))
    }
    
    const handleDescriptionChange = (content, jsonContent, mediaType) => {
        
        setData((prevData) => ({
            ...prevData,
            mediaType: mediaType,
            content: content,
            jsonContent: jsonContent,
        }))
    }

    const handleDateTimeChange = ({name, date}) => {

        setData((prevData) => ({
            ...prevData,
            [name]: date,
        }))
    }
    
    const onExistingChange = (selected, fromNew = false) => {

        if (!selected) {
            handleReset()
            return
        }

        const _data = selected._click_meta
        const startDate = moment(_data.start_date).toDate()
        const endDate = moment(_data.end_date).toDate()

        setData((prevData) => ({
            ...prevData,
            endDate: endDate,
            type: fromNew ? types[1].value : prevData.type,
            isFromNewEvent: fromNew,
            title: _data.title,
            eventId: selected.id,
            startDate: startDate,
            selectedEvent: selected,
            location: _data.location,
        }))
    }

    const handleReset = () => {
        setData((prevData) => ({
            ...prevData,
            title: "",
            type: types[0].value,
            location: "",
            eventId: null,
            selectedEvent: null,
            endDate: moment().toDate(),
            startDate: moment().toDate(),
        }))
    }

    const alreadySelectedEvents = mapKeys(map(builder?.tasks ?? {}, c => c?.data?.selectedEvent?.id).filter(c => !isNil(c)))

    return (
        <BaseMorphed onReset={handleReset} resetDisabled={!isNil(data.originalId)} item={item} render={({errors}) => (
            <Grid>
                {/* {isNil(data.originalId) && (
                    <Grid.Row className='no-pad-bot'>
                        <Grid.Column width={5}>
                            <Dropdown 
                                placeholder={i18next.t('messageBuilder.morphedTemplates.EventMorphed.eventType')}
                                selection
                                onChange={handleChange}
                                value={data.type}
                                options={types} />
                        </Grid.Column>
                    </Grid.Row>
                )} */}

                {(data.type === 1) ? (
                    <RenderExistingEvent 
                        item={item}
                        data={data}
                        errors={errors}
                        minTime={minTime}
                        maxTime={maxTime}
                        dispatch={dispatch}
                        selectedEvents={alreadySelectedEvents}
                        onSearchChange={onSearchChange}
                        onExistingChange={onExistingChange}
                        communityId={navigation.communityId}
                        handleInputChange={handleInputChange}
                        handleRepeatChange={handleRepeatChange}
                        handleSwitchChange={handleSwitchChange}
                        handleDateTimeChange={handleDateTimeChange}
                        handleLocationChange={handleLocationChange}
                        handleDescriptionChange={handleDescriptionChange} />
                )
                    : (
                        <RenderNewEvent
                            data={data}
                            minTime={minTime}
                            maxTime={maxTime}
                            errors={errors}
                            dispatch={dispatch}
                            selectedEvents={alreadySelectedEvents}
                            minStartTime={minStartTime}
                            repeatOptions={repeatOptions}
                            onExistingChange={onExistingChange}
                            communityId={navigation.communityId}
                            handleInputChange={handleInputChange}
                            onSearchChange={onNewEventSearchChange}
                            handleDateTimeChange={handleDateTimeChange}
                            handleRepeatChange={handleRepeatChange}
                            handleSwitchChange={handleSwitchChange}
                            handleLocationChange={handleLocationChange}
                            handleDescriptionChange={handleDescriptionChange} />
                )}
            </Grid>
        )} />
    )
}
