export const endpoints = {

    //Login
    DO_LOGIN: "/login",
    
    DO_LOGIN_REFRESH: "/login/renew",
    
    // User Profile
    GET_PROFILE: "/me",
    POST_PROFILE: "/me",
    
    // Identity SSO
    GET_SSO_PROFILE: "/me",
    PUT_UPDATE_SSO_PROFILE: "/me",
    PUT_UPDATE_SSO_PHONE: "/me/phone_number",
    POST_VERIFY_PHONE: "/me/phone_number/verify",
    PUT_UPDATE_SSO_EMAIL: "/me/email",
    POST_VERIFY_EMAIL: "/me/email/verify",
    
    // Settings
    GET_COMMUNITY: '/communities/%s/settings',
    POST_UPDATE_COMMUNITY: '/communities/%s/settings',
    GET_BILLING: '/billing/%s',
    
    DO_LOGIN_OR_REGISTER: "/register",
    
    // MESSAGE BUILDER
    GET_NOTICE: "/notices/%s",
    GET_NOTICES: "/notices",
    POST_NOTICE: "/notices",
    PUT_UPDATE_NOTICE: "/notices/%s",
    GET_NOTICE_GRAPHS: "/notices/%s/analytics/graphs",
    POST_ARCHIVE_NOTICE: "/notices/%s/archive",
    GET_EDIT_NOTICE: "/notices/builder/%s/structure",
    GET_NOTICE_PREVIEW: "/notices/%s/preview",
    GET_LIVE_NOTICE_PREVIEW: "/notices/previews",
    GET_NOTICE_FILTERS: "/notices/builder/persons/filters",
    GET_NOTICES_FILTERS: "/notices/filters",
    GET_TARGET_PERSONS: "/notices/builder/persons/list",
    GET_PARENT_CHILD_PERSONS: "/persons/create/parent-child",
    GET_TARGET_PERSONS_BY_FILTERS: "/notices/builder/persons/all/action/list",
    POST_RESEND_MESSAGE: "/notices/%s/resend",
    POST_PUBLISH_MESSAGE: "/notices/%s/publish",
    POST_SCHEDULE_MESSAGE: "/notices/%s/schedule",
    POST_SCHEDULE_SEND_NOW_MESSAGE: "/notices/%s/scheduled/publish",
    POST_CANCEL_SCHEDULE_MESSAGE: "/notices/%s/schedule",
    GET_NOTICE_RECIPIENTS: "/notices/%s/recipients",
    GET_NOTICE_RECIPIENT_FILTERS: "/notices/%s/recipients/filters",
    GET_NOTICE_BUILDER_CHANNELS: "/notices/builder/channels",
    GET_NOTICE_PUBLISH_BREAKDOWN: "/notices/%s/publish/breakdown",
    GET_NOTICE_BUILDER_RESOURCE_GROUPS: "/notices/builder/resource-categories",
    POST_NOTICE_REMIND_BREAKDOWN: "/notices/%s/recipients/remind/breakdown",
    POST_NOTICE_REMIND_BREAKDOWN_BY_FILTERS: "/notices/%s/recipients/all/action/remind/breakdown",
    GET_WEBSITE_META: "/notices/builder/url-metadata",
    GET_NOTICE_RECIPIENT_TIMELINE: "/notices/recipients/%s",
    GET_NOTICE_PUBLISH_PERMISSION: "/notices/builder/publishable",
    POST_NOTICE_REMIND_RECIPIENTS: "/notices/%s/recipients/remind/remind",
    POST_NOTICE_REMIND_RECIPIENTS_BY_FILTERS: "/notices/%s/recipients/all/action/remind/remind",
    POST_NOTICE_DOWNLOAD_RECIPIENTS_REPORT: "/notices/%s/recipients/export",
    POST_NOTICE_DOWNLOAD_RECIPIENTS_REPORT_BY_FILTERS: "/notices/%s/recipients/all/action/export",
    POST_NOTICE_DOWNLOAD_VOTES: "/notices/%s/voting/campaign/results/export",
    POST_NOTICE_PUBLISH_RESULTS: "/notices/%s/voting/campaign/results/visibility",
    GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS: "/notices/%s/publish/breakdown/excluded",
    GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS_BY_FILTERS: "/notices/%s/recipients/all/action/remind/breakdown/excluded",
    GET_RECIPIENT_MANUAL_PAYMENTS: "/notices/recipients/%s/manual-payment",
    
    // Contributors approval
    GET_NOTIFIABLE_APPROVERS: "/notices/%s/approvers",
    POST_NOTIFY_APPROVERS: "/notices/%s/notify-approvers",

    GET_TEMPLATES: "/notices/builder/templates",
    POST_TEMPLATE: "/notices/builder/templates",
    POST_DELETE_TEMPLATE: "/notices/builder/templates/%s",
    POST_TEMPLATE_WITH_CONTENT: "/notices/builder/templates/with-content",
    POST_UPDATE_TEMPLATE_WITH_CONTENT: "/notices/builder/templates/%s",
    
    // User Management
    GET_USERS: "/users",
    GET_USER: "/users/%s",
    GET_USER_FILTERS: "/users/filters",
    GET_USER_INVITES: "/users/invites",
    GET_USER_INVITE_FILTERS: "/users/invites/filters",
    POST_USER_INVITE: "/users/invites",
    GET_INVITE_DETAILS: "/users/invites/token/%s",
    POST_ACCEPT_INVITE: "/users/invites/token/%s/accept",
    DELETE_INVITE: "/users/invites/%s",
    PUT_USER_UPDATE: "/users/%s",
    POST_USER_DETACH: "/users/%s/detach",
    POST_IMPORT_MEMBERS: "/communities/%s/import",
    POST_RESEND_USER_INVITE: "/users/invites/%s/resend",
    
    GET_MEMBERS: "/persons",
    GET_MEMBER: "/persons/%s",
    DELETE_MEMBER: "/persons/%s",
    PUT_MEMBER_UPDATE: "/persons/%s",
    POST_MEMBER_CREATE: "/persons",
    GET_MEMBER_FILTERS: "/persons/filters",
    GET_MEMBER_HISTORY: "/persons/%s/audit-logs",
    POST_MEMBER_CHECK_UNIQUENESS: "/persons/check-uniqueness",
    
    // Channel Management
    GET_CHANNELS: "/channels",
    GET_CHANNEL: "/channels/%s",
    DELETE_CHANNEL: "/channels/%s",
    POST_CHANNEL_CREATE: "/channels",
    GET_CHANNEL_FILTERS: "/channels/filters",
    PUT_CHANNEL_UPDATE: "/channels/%s",
    GET_CHANNEL_PERSONS: "/channels/create/persons",
    GET_CHANNEL_MEMBER: "/channels/%s/persons/%ss",
    GET_CHANNEL_PERSONS_FILTERS: "/channels/create/persons/filters",
    
    GET_CHANNEL_MEMBERS: "/channels/%s/persons",
    POST_CHANNEL_ATTACH_MEMBERS: "/channels/%s/persons/attach",
    POST_CHANNEL_DETACH_MEMBERS: "/channels/%s/persons/detach",
    GET_CHANNEL_ATTACHABLE_MEMBERS: "/channels/%s/attach/attachable-persons",
    GET_CHANNEL_ATTACHABLE_MEMBERS_FILTERS: "/channels/%s/attach/attachable-persons/filters",

    // Contacts
    GET_CONTACTS: "/contacts",
    GET_CONTACT: "/contacts/%s",
    POST_CREATE_CONTACT: "/contacts",
    PUT_UPDATE_CONTACT: "/contacts/%s",
    DELETE_CONTACT: "/contacts/%s",
    
    // Contact Form Categories
    GET_CONTACT_FORM_CATEGORIES: "/contacts/form/categories",
    GET_CONTACT_FORMS_CATEGORY: "/contacts/form/categories/%s",
    POST_CREATE_CONTACT_FORM_CATEGORY: "/contacts/form/categories",
    PUT_UPDATE_CONTACT_FORM_CATEGORY: "/contacts/form/categories/%s",
    DELETE_CONTACT_FORM_CATEGORY: "/contacts/form/categories/%s",

    // Delete Many
    POST_ARCHIVE_MULTI_NOTICES: "/notices/archive",
    POST_DELETE_MULTI_INVITES: "/users/invites",
    POST_DELETE_MULTI_MEMBERS: "/persons",
    
    // Actions By Filters
    GET_MULTI_MEMBERS_VIA_FILTERS: "/notices/builder/persons/all/action/list",
    GET_MEMBERS_VIA_FILTERS: "/notices/builder/persons/all/action/list",
    POST_DELETE_MULTI_MEMBERS_VIA_FILTERS: "/persons/all/action/delete",
    POST_INVITE_MULTI_MEMBERS_VIA_FILTERS: "/persons/all/action/invite",
    POST_PROMOTE_DEMOTE_MULTI_MEMBERS_VIA_FILTERS: "/persons/all/action/promote-demote",
    
    POST_DELETE_MULTI_CHANNELS: "/channels",
    POST_DELETE_MULTI_SYS_USERS: "/users/detach",

    // Calendars
    GET_CALENDARS: "/calendar-feeds",
    GET_CALENDAR: "/calendar-feeds/%s",
    GET_CALENDAR_FILTERS: "/calendar-feeds/filters/channels",
    POST_CREATE_CALENDAR: "/calendar-feeds",
    POST_LINK_CALENDAR_FEED: "/calendar-feeds/link",
    POST_LINK_CALENDAR_ICAL_FEED: "/calendar-feeds/link/ical",
    POST_CHECK_ICAL_FEED_EXISTS: "/calendar-feeds/sync/accounts/exists",
    PUT_UPDATE_CALENDAR: "/calendar-feeds/%s",
    POST_CALENDAR_RESYNC: "/calendar-feeds/%s/sync",
    POST_SYNC_ALL: "/calendar-feeds/sync",
    POST_GET_REMOVE_CHANNELS_CONFIRMATION: "/calendar-feeds/%s/events/channels",

    DELETE_CALENDAR: "/calendar-feeds/%s",
    UNLINK_CALENDAR: "/calendar-feeds/%s/unlink",
    DELETE_CALENDAR_ACCOUNT: "/calendar-feeds/sync/accounts/%s",
    GET_CALENDAR_STATUS: "/communities/%s/calendars/updated",
    POST_CALENDARS_GOOGLE_AUTH: "/calendar-feeds/sync/account/google/authorisation",
    POST_CALENDARS_MICROSOFT_AUTH: "/calendar-feeds/sync/account/microsoft/authorisation",
    
    GET_CALENDAR_SOURCES: "/calendar-feeds/sync/accounts/%s/sources",
    POST_CREATE_GOOGLE_SYNC_ACCOUNT: "/calendar-feeds/sync/account/google",
    POST_CREATE_MICROSOFT_SYNC_ACCOUNT: "/calendar-feeds/sync/account/microsoft",
    GET_CALENDAR_SYNC_ACCOUNTS: "/calendar-feeds/sync/accounts",
    POST_RE_AUTHORISE_CALENDAR_SYNC_ACCOUNT: "/calendar-feeds/sync/account/%s/reauth",
    
    POST_CREATE_EVENT: "/events",
    PUT_UPDATE_EVENT: "/events/%s",
    DELETE_EVENT: "/events/%s",
    GET_EVENT: "/events/%s",
    GET_CALENDAR_EVENTS: "/calendar-feeds/%s/events",
    GET_EVENTS: "/events",
    GET_EVENTS_RANGE: "/events/range",
    GET_EVENT_TARGET_PERSONS: "/calendar-feeds/filters/persons",
    POST_SEARCH_EVENTS: "/events/search",
    POST_BUILDER_SEARCH_EVENTS: "/notices/builder/events",
    GET_CALENDAR_STATUSES: "/communities/%s/calendars/status",
    POST_PROMOTE_OR_DEMOTE_MEMBER: "/persons/promote-demote",
    POST_EXPORT_MULTI_MEMBERS: "/persons/all/action/export",
    POST_EXPORT_MEMBERS: "/persons/export",
    
    // Invite To App
    POST_INVITE_MEMBERS_TO_APP: "/persons/%s/app-download-invites/send",
    POST_REMIND_INVITE_MEMBERS_TO_APP: "/persons/%s/app-download-invites/send-reminder",
    GET_APP_DOWNLOAD_BREAKDOWN: "/persons/app-download-invites/breakdown",
    GET_APP_DOWNLOAD_BREAKDOWN_VIA_FILTERS: "/persons/all/action/app-download-invites/breakdown",
    POST_APP_INVITE_MULTI_MEMBERS: "/persons/app-download-invites/send",
    POST_APP_INVITE_MULTI_MEMBERS_BY_FILTERS: "/persons/all/action/app-download-invites/send",

    // Upload Files to Relay Uploads
    UPLOAD_FILE: '/files',
    GET_FILE_PREVIEW: '/files/%s/preview',

    // Tiering
    GET_MEMBER_PLAN_LIMITS: "/persons/tiering/limits",
    GET_CHANNEL_PLAN_LIMITS: "/channels/tiering/limits",
    GET_IMPORT_PLAN_LIMITS: "/communities/%s/import/tiering/limits",
    GET_USERS_PLAN_LIMITS: "/users/tiering/limits",
    GET_BUILDER_COMPONENTS: "/notices/builder/components",
    GET_BUILDER_PLAN_LIMITS: "/notices/builder/tiering/limits",
    GET_TEMPLATE_PLAN_LIMITS: "/notices/builder/templates/tiering/limits",
    POST_REQUEST_ADD_ON: "/communities/%s/settings/tiering/request-add-on",
    POST_REQUEST_PLAN_UPGRADE: "/communities/%s/settings/tiering/request-upgrade",
    
    // Assisted Writing
    POST_REQUEST_WRITING_ASSISTANCE: "/integrations/openai/aw/%s",
    POST_REQUEST_WRITING_ASSISTANCE_FEEDBACK: "/integrations/openai/aw/feedback",
    
    // Resource Categories
    GET_RESOURCE_CATEGORIES: "/resource-categories",
    POST_CREATE_RESOURCE_CATEGORY: "/resource-categories",
    PUT_UPDATE_RESOURCE_CATEGORY: "/resource-categories/%s",
    DELETE_RESOURCE_CATEGORY: "/resource-categories/%s",
}