import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import classNames from 'classnames'

const propTypes = {
	limit: PropTypes.number.isRequired,
	value: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	showWarning: PropTypes.bool.isRequired
}

const defaultProps = {
	limit: 0,
	value: ''
}

const WarnTitleLimitReached = ({ limit, value, message, showWarning }) => {
    if (showWarning && value.length + 1 > limit) {
        return (
            <p className="label error-text">{i18next.t('message-builder.component.warning.title-limit-reached.label', {limit})}</p>
        )
    }

    return (
        <p className={classNames('label', (value.length < limit) ? 'grey-text' : 'error-text')}>{message} ({value.length}/{limit})</p>
    )
}

WarnTitleLimitReached.propTypes = propTypes
WarnTitleLimitReached.defaultProps = defaultProps

export default WarnTitleLimitReached
