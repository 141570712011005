import { identity, pickBy, size } from "lodash"
import { dropEmptyKeys } from "../../utils/helper"
import ListNoticeRecipientsRequest from "./ListNoticeRecipientsRequest"

class GetNoticeRecipientsByFiltersRequest extends ListNoticeRecipientsRequest {

    getPayload = () => {
        
        const payload = {
            "sort": this.sort,
            "search": this.search,
            "channel_ids": size(this.channels) > 0 ? this.channels : null,
            "group_by": this.relationship,
            "interactions": size(this.interactions) > 0 ? this.interactions : null,
        }

        return pickBy(payload, identity)
    }
    
    getExportBySelectionPayload = (exportParams) => {
        
        const payload = {
            format: exportParams.format,
        }

        if (exportParams.recipient_ids) {
            payload['recipient_ids'] = exportParams.recipient_ids
        }

        return dropEmptyKeys(payload)
    }

    getExportByFiltersPayload = (exportParams) => {
        
        const payload = {
            ...this.getPayload(),
            format: exportParams.format,
        }
        
        return dropEmptyKeys(payload)
    }
    
}

export default GetNoticeRecipientsByFiltersRequest