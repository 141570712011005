import React, { Fragment, useEffect, useState } from 'react'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar'

import { Trans, useTranslation } from 'react-i18next'

import { Input, Grid, Button, Icon, Dimmer, Loader, Popup } from 'semantic-ui-react'
import { Template } from '../message-builder/templates'
import { Droppable } from 'react-beautiful-dnd'
import TaskIcon from '../message-builder/TaskIcon'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../actions/builderActions'
import { filter, map, size } from 'lodash'
import ConfirmModal from './ConfirmModal'

const BuilderSideNavigation = ({ collapsed, toggled, mainCollapse, handleCollapsedChange }) => {
  
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const builder = useSelector(state => state.builder)
  const navigation = useSelector(state => state.navigation)

  const [searchTerm, setSearchTerm] = useState("")
  const [canSearch, setCanSearch] = useState(false)

  const [confirm, setConfirm] = useState({
    showModal: false,
    onConfirm: () => {},
    onClose: () => {},
    message: "",
    header: "",
    confirmText: "",
    cancelText: "",
  })

  // Cleanup
  useEffect(() => {
    return () => {
        dispatch(builderActions.clearMessages())
    }
  }, [])

  // Refresh templates upon builder refreshData flag
  useEffect(() => {
    
    if (!builder.messages) return;

    if (builder.messages.refreshData) {
      dispatch(builderActions.startTemplates())
      dispatch(builderActions.fetchTemplates({
        communityId: navigation.communityId
      }))
    }

  }, [builder.messages])

  const handleDeleteTemplate = (task) => {
    handleCloseConfirm()
    dispatch(builderActions.startTemplates())
    dispatch(builderActions.postDeleteTemplate({
      templateId: task.id
    }))
  }

  const confirmDeleteTemplate = (e, task) => {
    e.stopPropagation()

    setConfirm({
      showModal: true,
      onConfirm: () => handleDeleteTemplate(task),
      onClose: () => handleCloseConfirm(),
      message: (
        <Trans
          i18nKey="modal.builder.sidenavigation.message"
          components={{
            strong: <strong/>
          }}
          values={{
            name: task.name
          }}
          />
      ),
      header: t('modal.builder.sidenavigation.header'),
      cancelText: t('modal.builder.sidenavigation.cancelText'),
      confirmText: t('modal.builder.sidenavigation.confirmText'),
    })
  }

  const handleCloseConfirm = () => setConfirm({})
  
  const handleSearchChange = (e, {value}) => {
    setSearchTerm(value)
  }

  const handleClickDrop = ({item, index}) => {
    
    dispatch(builderActions.composeComponentDynamic({
      id: item.id,
      index: index,
    }))
  }

  return (
    <Fragment>
      <div className={`sidebar-floating-btn-wrapper ${(!collapsed) ? 'bsn-opened' : 'bsn-collapsed'} ${!mainCollapse ? 'sn-opened' : 'sn-collapsed'}`}>
        <Button
          onClick={() => handleCollapsedChange(!collapsed)}
          icon="configure">
        </Button>
      </div>
      <ProSidebar
        className="secondary-sidebar z-index-1"
        collapsed={collapsed}>

        <SidebarContent>
          <Menu className="hide-on-collapse no-padding">
            
            <ConfirmModal 
                header={confirm.header}
                open={confirm.showModal}
                onClose={confirm.onClose}
                message={confirm.message}
                onConfirm={confirm.onConfirm}
                confirmText={confirm.confirmText}/>

            <Dimmer active={builder.loadingTemplates} inverted>
              <Loader inverted content={t("general.loading")} />
            </Dimmer>

            {builder.templates.columnOrder.map((columnId) => {

              const column = builder.templates.columns[columnId]
              let oTasks = map(column.taskIds, taskId => builder.templates.tasks[taskId])
              
              let filtered = filter(oTasks, o => {
                return o.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
              })

              oTasks = (!filtered) ? oTasks : filtered

              let uTasks = []
              let cpTasks = []
              let cpTasksLastIndex = 0

              if (column.id === 'column-1') {
                uTasks = filter(oTasks, {isUserTemplate: true})
                cpTasks = filter(oTasks, {isUserTemplate: false})

                cpTasksLastIndex = size(cpTasks)
              } else {
                cpTasks = oTasks
              }

              if (['column-1', 'column-4'].includes(column.id)) {
                return <Fragment />
              }

              return (
                <SubMenu
                  key={columnId}
                  title={
                    <h4 className="side-menu-col-name">
                      { column.title }
                      <Popup
                          basic
                          className="builder-side-nav-popup"
                          trigger={
                              <Icon className="info question circle"/>
                          }
                          content={column.description}
                        />
                    </h4>
                  }
                  className="no-hover"
                  defaultOpen={true}
                  popperarrow={false}
                  >

                  <Grid className='templates'>
                    <Droppable
                      droppableId={column.id}>
                      {(provided, snapshot) => (
                        <div 
                          className={`row ${snapshot.isDraggingOver ? 'incoming-drop' : ''}`} 
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          
                          {/* Only Show Community and Public templates */}
                          {cpTasks.map((task, index) => (
                              <Grid.Column key={index} index={index} width={16}>
                                <Template 
                                  item={task} 
                                  index={index}
                                  onClick={handleClickDrop}
                                  disabled={task.disabled || false}
                                  icon={ <TaskIcon task={task} /> } />
                              </Grid.Column>
                          ))}
                          {provided.placeholder}

                          {/* Show User Templates */}
                          {(uTasks.length > 0 && column.id === 'column-1') && (
                            <Fragment>
                              <Grid.Row className="w-100" style={{paddingBottom: 10, paddingTop: 15}}>
                                <p className='text-with-line'><span>{t('secondaryNav.myTemplates')}</span></p>
                              </Grid.Row>
                              
                              {uTasks.map((task, index) => (
                                  <Grid.Column key={cpTasksLastIndex + index} index={cpTasksLastIndex + index} width={16}>
                                    <Template 
                                      item={task} 
                                      disabled={task.disabled || false}
                                      index={cpTasksLastIndex + index}
                                      icon={ <TaskIcon task={task} /> }
                                      className="generic"
                                      onClick={handleClickDrop}
                                      render={() => <Icon onClick={(e) => confirmDeleteTemplate(e, task)} className="trash delete-icon"/>} />
                                  </Grid.Column>
                              ))}
                            </Fragment>
                          )}
                        </div>
                      )}
                    </Droppable>
                  </Grid>
                </SubMenu>
              )
            })}
          </Menu>
          
        </SidebarContent>

        {/* <SidebarFooter>
          
        </SidebarFooter> */}
      </ProSidebar>
    </Fragment>
  )
}

export default BuilderSideNavigation
