import { size } from "lodash"
import { filterNulls } from "../../utils/helper"
import CreateChannelRequest from "./CreateChannelRequest"

class UpdateChannelRequest extends CreateChannelRequest {
    
    getPayload = () => {
        
        const payload = {
            "name": this.name,
            "can_leave": this.canLeave,
            "is_hidden": this.isHidden,
            "invite_only": this.inviteOnly,
            "community_id": this.communityId,
            "is_default": this.isDefault,
            "tag_ids": [],
        }

        // 1. When "Everyone" is selected, we send through the flag
        if (this.whoCanJoin === "anyone") {
            payload['attach_all_persons_in_community'] = true
        }
        
        // 2. When "Tags" is selected, we send through the tag ids
        if (size(this.tagIds) > 0 && this.whoCanJoin === "tags") {
            
            // 2.1 When "Private" is also selected here, we send the tag ids
            if (this.inviteOnly) {
                payload["tag_ids"] = this.tagIds
            }

            if (!this.inviteOnly) {
                payload['attach_persons_with_tag_ids'] = this.tagIds
            }
            
        } else {
            payload["tag_ids"] = []
        }

        return filterNulls(payload)
    }
}

export default UpdateChannelRequest