import React, { Fragment, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { Button, Checkbox, Divider, Dropdown, Grid, Header, Icon, Input, Loader, Modal, Search, Segment } from 'semantic-ui-react'
import MultiSelect from './multiselect'
import { difference, filter, find, has, isEmpty, isUndefined, map, mapKeys, omitBy, size } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import i18next from 'i18next'
import routes from '../../routes/route-constants'
import { constants, createMembers } from '../../constants'
import PaginationContainer from './PaginationContainer'
import DebouncedSearchInput from './DebouncedSearchInput'
import { v4 } from 'uuid'
import SuperTPagination from './SuperTPagination'
import SuperTable from './SuperTable'
import VirtualisedDropdown from './VirtualisedDropdown'
import TableSelectionIndicator from './TableSelectionIndicator'
import { classNames, formatMobileNumber } from '../../utils/helper'
import { tableActions } from '../../actions/tableActions'
import moment from 'moment'

const  propTypes = { 
    isOpen: PropTypes.bool, 
    trigger: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.node]), 
    data: PropTypes.object, 
    tags: PropTypes.array, 
    disableChannels: PropTypes.bool, 
    tempOptions: PropTypes.array, 
    currentSelection: PropTypes.array, 
    existingSelection: PropTypes.array, 
    title: PropTypes.string,
    description: PropTypes.string,
    closeCaption: PropTypes.string,
    
    onClose: PropTypes.func,
    onAddUsers: PropTypes.func,
    fetchUsers: PropTypes.func, 
}

const defaultProps = {
    isOpen: false, 
    trigger: null, 
    data: {}, 
    tags: [], 
    tempOptions: [], 
    currentSelection: [],
    existingSelection: [], 
    title: i18next.t('general.multiselect.title'),
    closeCaption: i18next.t('general.multiselect.closeCaption'),
    description: i18next.t('general.multiselect.description'),
    
    onClose: () => {},
    onAddUsers: () => {}, 
    fetchUsers: () => {}, 
}

const AddUsersModal = (props) => {
    
    const { 
        isOpen, 
        trigger, 
        data, 
        tags, 
        onAddUsers, 
        fetchUsers,
        onClose, 
        currentSelection,
        existingSelection, 
        tempOptions, 
        title,
        source,
        description,
        disableChannels,
        closeCaption
    } = props
    
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const [open, setOpen] = useState(isOpen)
    const [loading, setLoading]  = useState(false)
    const [isMounted, setIsMounted]  = useState(false)
    const [showSelectedOnly, setShowSelectedOnly]  = useState(false)
    
    const [pager, setPager] = useState({
        pagination: {}
    })
    const [tableId] = useState(v4())
    const [filterPayload, setFilterPayload] =  useState({
        page: 1,
        sort: "",
        tags: [],
        search: "",
        channelIds: [],
        personIds: null,
        perPage: constants.PAGINATION_DEFAULTS[0],
        showSelectedOnly: null,
        ...data,
    })

    // Table config
    const headers = [
        {
            name: constants.TABLE_KEY_CHECKBOX,  
            key: {
                name: constants.TABLE_KEY_CHECKBOX,
                type: constants.TABLE_KEY_CHECKBOX,
            }
        },
        {
            name: t('communityMembers.ManageMembers.table.header.firstName'), 
            sortable: true, 
            key: {
                name: "first_name"
            }
        }, 
        {
            name: t('communityMembers.ManageMembers.table.header.lastName'), 
            sortable: true, 
            key: {
                name: "last_name"
            }
        },
        {
            id: 'relationship',
            name: t('notices.detail.filters.relationship'), 
            align: "center",
            classNames: 'relationship',
            key: {
                name: "relationship",
                classNames: 'relationship',
                type: constants.TABLE_KEY_PARENT_LINK_ICON
            }, 
        },
        {
            name: t('communityMembers.ManageMembers.table.header.channels'), 
            align: "center",
            classNames: 'channels',
            key: {
                name: "channels",
                classNames: 'channels',
                type: constants.TABLE_KEY_HOVERABLE_COUNT
            }, 
        },
        {
            name: t('communityMembers.ManageMembers.table.header.member'), 
            align: "center",
            key: {
                classNames: 'is_member',
                name: "is_member",
                icon: 'done',
                type: constants.TABLE_KEY_CUSTOM_ICON,
            }, 
        },
        {
            name: t('communityMembers.ManageMembers.table.header.hasApp'), 
            align: "center",
            classNames: 'has-app',
            key: {
                name: "has_app",
                classNames: 'has-app',
                icon: 'smartphone',
                active: '',
                message: t('manageMembers.table.headers.appDescription'),
                type: constants.TABLE_KEY_CUSTOM_ICON
            }, 
        },
        {
            name: t('communityMembers.ManageMembers.table.header.phoneNumber'), 
            sortable: true, 
            key: {
                name: "phone_number"
            }
        }, 
        {
            name: t('communityMembers.ManageMembers.table.header.email'), 
            sortable: true, 
            key: {
                name: "email"
            }
        }, 
        {
            name: "",
            key: {
                classNames: 'actions',
                name: constants.TABLE_KEY_ACTIONS,
                type: constants.TABLE_KEY_ACTIONS
            },
        }
    ]

    const [selection, setSelection] =  useState({
        options: [],
        value: [],
        valueOptions: [],
        currentSelection: [],
    })

    const handleAddUsers = () => {
        
        // Close Modal
        handleCloseModal()

        // Post Back Selected Users
        onAddUsers({
            selection: selection.value,
            options: selection.options,
            // tempOptions: selection.valueOptions,
            tempOptions: selection.value.map(key => mapKeys(selection.options, "value")[key])
        })
    }
    
    useEffect(() => {

        // Do nothing when the Modal is not mounted
        if (!isMounted) return;

        if (has(filterPayload, 'communityId') || has(filterPayload, 'channelId')) {
            fetchAttachableMembers(filterPayload)
        }

        return () => {
            setSelection(prev => ({
                ...prev,
                value: [],
                valueOptions: [],
            }))
        }
    }, [filterPayload])
    
    useEffect(() => {
        setFilterPayload(prev => ({
            ...prev,
            page: 1, // We should start from the first page incase there was more any navigation to other pages on the table paginator.
            personIds: showSelectedOnly ? selection.value.concat(existingSelection ?? []) : null // We are going to show all the selected members, this may include the {existingSelection} from channels.
        }))
    }, [showSelectedOnly])

    const fetchAttachableMembers = (payload) => {
        
        setLoading(true)
        // We will use an unconventional way to fetch the data, 
        // so that we can fetch the data accordingly
        fetchUsers({
            ...payload,
            source,
            tagIds: payload.tags ?? payload.tagIds
        })
        .then(response => {

            let _selections = selection.valueOptions
            
            const _options = map(response.data?.data ?? [], u => {
                
                const reasons = {
                    disabled: undefined,
                    disabled_reason: undefined
                }

                if (source === createMembers.ADD_CHILDREN) {
                    reasons.disabled = !u.eligible_as_child
                    reasons.disabled_reason = u.eligible_as_child_reason
                } else if (source === createMembers.ADD_PARENTS) {
                    reasons.disabled = !u.eligible_as_parent
                    reasons.disabled_reason = u.eligible_as_parent_reason
                } else if (source === createMembers.ADD_CHANNELS) {
                    reasons.disabled = u.is_locked
                    reasons.disabled_reason = u.locked_reason

                    // Check that the member is not already part of the existing results, if they are we should disable the option to interact with the member.
                    if (existingSelection && existingSelection.includes(u.id)) {
                        reasons.disabled = true
                        reasons.disabled_reason = t('modals.addMembersModal.existingSelection')
                        reasons.disabled_icon_name = 'user'
                    }
                }

                return {
                    value: u.id,
                    disabled: reasons.disabled,
                    disabled_reason: reasons.disabled_reason,
                    id: u.id,
                    full_name: [u.first_name || "", u.last_name || ""].join(' '),
                    first_name: (u.name ?? u.full_name ?? u.first_name) || "",
                    last_name: u.last_name,
                    phone_number: formatMobileNumber(u.phone_number),
                    email: u.email,
                    id_number: u.id_number,
                    reference: u.unique_identifier,
                    added_by: u.added_by,
                    has_app: u.has_app,
                    app_last_seen_at: u.has_app ? moment(u.app_last_seen_at).format("L LT") : null,
                    is_locked: u.is_locked,
                    locked_reason: u.locked_reason,
                    disabled_icon_name: reasons.disabled_icon_name,
                    is_member: (u.is_public_follower === false),
                    channels: {
                        data: u.channels
                    },
                    tags: {
                        data: u.tags
                    },
                    relationship: {
                        parents: u.parents ?? [],
                        children: u.children ?? []
                    },
                    actions: []
                }
            })

            if (selection.valueOptions.length === 0) {
                if (size(tempOptions) === 0) {
                    _selections = currentSelection.map(key => mapKeys(_options, "value")[key])
                } else {
                    _selections = currentSelection.map(key => mapKeys(tempOptions, "value")[key])
                }
            } 
            
            setPager(prev => ({
                ...prev,
                pagination: response.data?.pagination
            }))

            setSelection(prev => ({
                ...prev,
                valueOptions: _selections,
                value: map(_selections, "value"),
                options: _options,
                currentSelection,
            }))
        }).finally(_ => setLoading(false))
    }
    
    const handleCloseModal = () => {
        setOpen(false)
        onClose()
    }
    
    const onModalUnmount = () => {
        
        // Reset the selection values
        setSelection(prev => ({
            ...prev,
            value: [],
            valueOptions: [],
        }))

        setIsMounted(false)
    }
    
    const onModalMount = () => {
        
        // Reset the selection values
        setFilterPayload({
            ...filterPayload,
            search: ""
        })

        setIsMounted(true)
    }

    const handleOpenZohoSales = () => {
        window.open(routes.public.WEB_FORM, "_blank")
    }

    const handlePageChange = (page) => {
        setFilterPayload({...filterPayload, page: page})
    }
    
    const handlePaginationIntervalChange = (_perPage) => {
        const perPage = find(constants.PAGINATION_DEFAULTS, {value: _perPage})
        setFilterPayload({...filterPayload, perPage: perPage})
    }
    
    const handleSearchChange = (value) => {
        setFilterPayload({...filterPayload, search: value, page: 1})
    }

    const handleChannelIdsChange = (_, {value}) => {
        setFilterPayload({...filterPayload, channelIds: value, page: 1})
    }

    const handleCheckUpdate = (records, extras) => {
        setSelection(prev => ({...prev, value: records}))
    }

    const handleTableAllCheck = (hasSelections) =>  {
        console.log({all: hasSelections})
    }
    
    const handleOnClearAll = () =>  {
        handleCheckUpdate([], [])
        dispatch(tableActions.clearSelection({
            tableId: tableId,
        }))
    }
    
    const handleCheckChanged = ({name}) => {
        setFilterPayload(prev => ({...prev, [name]: !prev[name]}))
    }

    return (
        <Modal
            size="fullscreen"
            open={isOpen}
            trigger={trigger}
            onClose={handleCloseModal}
            onUnmount={onModalUnmount}
            onMount={onModalMount}
            onOpen={() => setOpen(true)}>

            <Modal.Content className="add-users-modal">
                <Grid className='mg-auto'>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <PaginationContainer
                    sectionThree={
                        <Grid.Column width={8}>
                            <DebouncedSearchInput 
                                placeholder={i18next.t('general.searchPlaceholder')}
                                name="search" 
                                loading={loading}
                                defaultValue={filterPayload.search}
                                onChange={ handleSearchChange } />
                        </Grid.Column>
                    }
                    sectionFour={
                        <Grid.Column width={8}>
                            <div className="input-container theme-color">
                                <label className='label' htmlFor="channel">{t('communityMembers.ManageMembers.table.header.channels')}</label>
                                <VirtualisedDropdown
                                    className='theme-color'
                                    placeholder={t('communityMembers.ManageMembers.table.header.channels')}
                                    fluid
                                    search
                                    selection
                                    multiple
                                    id="channel"
                                    lazyLoad
                                    disabled={disableChannels}
                                    name="channelIds"
                                    onChange={handleChannelIdsChange}
                                    defaultValue={ filterPayload.channelIds }
                                    options={data.channels ?? []} />
                            </div>
                        </Grid.Column>
                    }
                    sectionFive={
                        <>
                            {selection.value.length > 0 && (
                                <TableSelectionIndicator
                                    classNames='large'
                                    items={selection.value.length}
                                    label={t("table.selection.individuals.label")}
                                    labelPlural={t("table.selection.individuals.labelPlural")}
                                    onClear={handleOnClearAll} />
                            )}
                            {selection.value.length > 0 && (
                                <Checkbox
                                    className='filter-by-selected'
                                    checked={showSelectedOnly} 
                                    onChange={e => setShowSelectedOnly((prev) => !prev)} 
                                    label={t('table.selection.individuals.onlyShowSelected')} />
                            )}
                        </>
                    }
                    sectionSix={
                        <SuperTPagination
                            spacer
                            totalCount={ pager.pagination?.total || 0 }
                            pageItemsCount={ pager.pagination?.count || 0 }
                            totalPages={ pager.pagination?.total_pages || 0 }
                            currentPage={ pager.pagination?.current_page|| 0 }
                            onPageChange={ handlePageChange }
                            onPaginationIntervalChange={ handlePaginationIntervalChange }
                            perPageMenu={constants.PAGINATION_DEFAULTS}
                            defaultValue={ filterPayload.perPage.value } />
                    } />
                
                <Grid className='mg-auto'>
                    <Grid.Row className='no-padding'>
                        <Grid.Column>
                            <div className="table-container add-individuals">
                                <SuperTable
                                    id={tableId}
                                    classNames={['super-t__members']}
                                    loading={ loading }
                                    headers={ headers }
                                    keys={ map(headers, "key") }
                                    records={ selection.options }
                                    onRecordClick={() => {}}
                                    selection={ currentSelection }
                                    noRecordsMessage={i18next.t("communityMembers.ManageMembers.table.noRecordsMessage")}
                                    onCheckUpdate={handleCheckUpdate}
                                    onAllCheck={handleTableAllCheck}
                                    actionFunctions={{}} />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions className="no-border">
                <Button className="secondary" onClick={handleCloseModal}>{closeCaption}</Button>
                {selection.value.length > 0 && (
                    <Button className="primary" onClick={handleAddUsers}>
                        {t('modal.addUsers.actions.add', {count: selection.value.length})}
                    </Button>
                )} 
                {(selection.value.length === 0) && (
                    <Button className="primary" onClick={handleAddUsers}>
                        {t('modal.addUsers.actions.updateSelection',)}
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    )
}

AddUsersModal.propTypes = propTypes
AddUsersModal.defaultProps = defaultProps

export default AddUsersModal
