import { 
    createAction
} from 'redux-actions'

import { actions }      from '../constants'
import { noticeService } from '../api'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'
import { PostCreateNoticeRequest } from '../api/requestObjects'
import { downloadFileFromBlob, showNotification } from '../utils/helper'
import i18next from 'i18next'

const { NOTICE } = actions

export const noticesActions = {

    /**
     * Start loading
     */
    start: createAction(NOTICE.START),
    
    /**
     * Show error messages
     */
    error: createAction(NOTICE.ERROR),

    /**
     * Show success messages
     */
    success: createAction(NOTICE.SUCCESS),

    /**
     * reset data
     */
    reset: createAction(NOTICE.RESET),
    
    /**
     * Save notices
     */
    saveNotices: createAction(NOTICE.SAVE),
    
    /**
     * Save notice details
     */
    saveNotice: createAction(NOTICE.SAVE_NOTICE_DETAILS),
    
    /**
     * Save notice recipients' timeline details
     */
     saveNoticeRecipientTimeline: createAction(NOTICE.SAVE_NOTICE_RECIPIENT_DETAILS),
    
     /**
     * Save the notice recipients' manual payments
     */
     saveNoticeManualPayments: createAction(NOTICE.SAVE_NOTICE_MANUAL_PAYMENTS),
    
    /**
     * Save notice graph details
     */
    saveNoticeGraphs: createAction(NOTICE.SAVE_NOTICE_GRAPH_DETAILS),

    /**
     * Clean notice details
     */
    cleanNoticeDetails: createAction(NOTICE.CLEAR_NOTICE_DETAILS),
    
    /**
     * Clear errors and messages
     */
    clearMessages: createAction(NOTICE.CLEAR_MESSAGES),

    /**
     * Save notices' filters
     */
    saveFilters: createAction(NOTICE.SAVE_FILTERS),
    
    /**
     * Loader for partial data 
     */
    startRecipientLoad: createAction(NOTICE.START_RECIPIENT_LOAD),
    
    /**
     * Loader for partial graphs data 
     */
    startGraphsLoad: createAction(NOTICE.START_GRAPHS_LOAD),
    
    /**
     * Save notice recipients
     */
    saveRecipients: createAction(NOTICE.SAVE_NOTICE_DETAILS_RECIPIENTS),
    
    /**
     * Save notice attachable recipients
     */
    saveAttachableRecipients: createAction(NOTICE.SAVE_NOTICE_DETAILS_RECIPIENTS),
    
    /**
     * Save notice attachable member filters
     */
    saveRecipientsFilters: createAction(NOTICE.SAVE_NOTICE_DETAILS_RECIPIENTS_FILTERS),
    
    /**
     * Fetch notices from API
     * 
     * @param {JSON} params 
     */
    fetchNotices (params) {
        return (dispatch, getState) => noticeService.getNotices(params)
            .then(response => {
                dispatch(this.saveNotices(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch notice from API
     * 
     * @param {JSON} params 
     */
    fetchNotice (params) {
        return (dispatch, getState) => noticeService.getNotice(params)
            .then(response => {
                dispatch(this.saveNotice(response.data))
                dispatch(this.fetchNoticeAnalyticsGraphs(params))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch notice recipient timeline from API
     * 
     * @param {JSON} params 
     */
    fetchNoticeRecipientTimeline (params) {
        return (dispatch, getState) => noticeService.getNoticeRecipientTimeline(params)
            .then(response => {
                dispatch(this.saveNoticeRecipientTimeline(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch notice manual payments from API
     * 
     * @param {JSON} params 
     */
    fetchNoticeManualPayments (params) {
        return (dispatch, getState) => noticeService.getNoticeManualPayments(params)
            .then(response => {
                dispatch(this.saveNoticeManualPayments(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post create manual payment
     * 
     * @param {JSON} params
     */
    postCreateManualPayment (params) {
        return (dispatch, getState) => noticeService.postCreateNoticeManualPayments(params)
            .then(response => {
                dispatch(this.success({
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch notice graphs from API
     * 
     * @param {JSON} params 
     */
    fetchNoticeAnalyticsGraphs (params) {
        return (dispatch, getState) => noticeService.getNoticeAnalyticsGraphs(params)
            .then(response => {
                dispatch(this.saveNoticeGraphs(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch notice recipients from API
     * 
     * @param {JSON} params 
     */
    fetchNoticeRecipients (params) {
        return (dispatch, getState) => noticeService.getNoticeRecipients(params)
            .then(response => {
                dispatch(this.saveRecipients(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post resend message to recipients
     * 
     * @param {JSON} params 
     */
    postResendToRecipients (params) {
        return (dispatch, getState) => noticeService.postResendToRecipients(params)
            .then(response => {

                dispatch(this.success({
                    header: 'noticesActions.resend.success.header',
                    content: 'noticesActions.resend.success.message',
                    refreshData: true,
                    hideMain: true
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post publish message
     * 
     * @param {JSON} params 
     */
    postPublishNotice (params) {
        
        return (dispatch, getState) => {
            dispatch(this.success({
                header: 'noticesActions.publish.success.header',
                content: 'noticesActions.publish.success.message',
                isPublishing: true
            }))

            const community = getState().navigation.community
            
            // We will only catch errors and return those to the UI, otherwise we are not going to wait
            noticeService.postPublishNotice(params)
                .then(response => {
                    showNotification({
                        icon: community.image,
                        title: i18next.t('noticesActions.publishNotice.success.header'),
                        body: i18next.t('noticesActions.publishNotice.success.body'),
                        data: {
                            id: response.id,
                            communityId: community.id,
                        }
                    })
                })
                .catch(error => {
                    // @TODO: We are not going to report on any errors that may have occurred during this time, since the most likely errors will be a timeout
                    // showNotification({
                    //     icon: community.image,
                    //     title: i18next.t("modal.errorOccurred"),
                    //     body: i18next.t('error.errorOccurred'),
                    // })
                })
        }
    },
    
    /**
     * Post schedule a message
     * 
     * @param {JSON} params 
     */
    postScheduleNotice (params) {
        
        return (dispatch, getState) => noticeService.postScheduleNotice(params)
            .then(response => {

                dispatch(this.success({
                    header: 'modal.confirm.schedule.header',
                    // content: 'modal.confirm.schedule.header',
                    isScheduled: true,
                    date: params.scheduledPublishAt,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post cancel scheduled message
     * 
     * @param {{noticeId: string}} params 
     */
    postCancelScheduledNotice (params) {
        
        return (dispatch, getState) => noticeService.postDeleteNoticeSchedule(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'modal.confirm.schedule.canceled.header',
                    // content: 'modal.confirm.schedule.canceled.message',
                    refreshData: true,
                    navigateMain: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
   
    /**
     * Post send scheduled message now
     * 
     * @param {{noticeId: string}} params 
     */
    postScheduleSendNowNotice (params) {
        
        return (dispatch, getState) => noticeService.postScheduleSendNowNotice(params)
            .then(response => {
                dispatch(this.success({
                    header: 'noticesActions.publish.success.header',
                    content: 'noticesActions.publish.success.message',
                    isPublishing: true
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post Download Message report
     * 
     * @param {JSON} params 
     */
    postDownloadReport (params) {
        return (dispatch, getState) => noticeService.postDownloadReportByFilters(params)
            .then(response => {

                dispatch(this.success({
                    header: 'noticesActions.downloadReport.success.header',
                    content: 'noticesActions.downloadReport.success.message',
                }))

                downloadFileFromBlob(response, params.filename)
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post Download Message report
     * 
     * @param {JSON} params 
     */
    postDownloadVotes (params) {
        return (dispatch, getState) => noticeService.postDownloadVotes(params)
            .then(response => {

                dispatch(this.success({
                    header: 'noticesActions.downloadVotes.success.header',
                    content: 'noticesActions.downloadVotes.success.message',
                    refreshData: true,
                }))

                downloadFileFromBlob(response, params.filename)
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * 
     * 
     * @param {JSON} params 
     */
    postPublishResults (params) {
        return (dispatch, getState) => noticeService.postPublishResults(params)
            .then(response => {
                
                window.location.reload()
                // dispatch(this.success({
                //     header: 'noticesActions.downloadVotes.success.header',
                //     content: 'noticesActions.downloadVotes.success.message',
                //     refreshData: true,
                // }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch notice recipients
     * 
     * @param {JSON} params 
     */
    fetchRecipientsFilters (params) {
        return (dispatch, getState) => noticeService.getRecipientsFilters(params)
            .then(response => {
                dispatch(this.saveRecipientsFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch notice recipients filters
     * 
     * @param {JSON} params 
     */
    fetchNoticeRecipientsFilters (params) {
        return (dispatch, getState) => noticeService.getNoticeRecipientsFilters(params)
            .then(response => {
                dispatch(this.saveRecipientsFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post create new notice
     * 
     * @param {PostCreateNoticeRequest} params 
     */
    postCreateNotice (params) {
        return (dispatch, getState) => noticeService.postSaveNotice(params)
            .then(response => {
                dispatch(this.success({
                    header: 'noticesActions.createNotice.success.header',
                    content: 'noticesActions.createNotice.success.message',
                }))
                dispatch(push(
                    routes.private.MANAGE_NOTICE
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post send remind notice to recipients
     * 
     * @param {JSON} params 
     */
    postRemindNotice (params) {
        
        return (dispatch, getState) => noticeService.postRemindNotice(params)
            .then(response => {
                dispatch(this.success({
                    header: 'noticesActions.notice.remind.success.header',
                    content: 'noticesActions.notice.remind.success.message',
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post delete notice
     * 
     * @param {JSON} params 
     */
    postDeleteNotice (params) {
        return (dispatch, getState) => noticeService.postDeleteNotice(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Message Deleted',
                    // content: 'Message was deleted successfully',
                    refreshData: true,
                    navigateMain: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post delete multiple notices
     * 
     * @param {JSON} params 
     */
    postDeleteMultipleNotices (params) {
        return (dispatch, getState) => noticeService.postDeleteMultiNotice(params)
            .then(response => {
                dispatch(this.success({
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch filters from API
     * 
     * @param {*} param 
     */
    fetchFilters ({communityId}) {
        return (dispatch, getState) => noticeService.getFilters({communityId})
            .then(response => {
                dispatch(this.saveFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Send reminders to the given contributors
     * 
     * @param {*} param 
     */
    postSendContributorReminders (params) {
        return (dispatch, getState) => noticeService.postSendContributorReminders(params)
            .then(response => {
                dispatch(this.success({
                    header: 'builderActions.publish.remind-contributor.success.header',
                    content: 'builderActions.publish.remind-contributor.success.message',
                    info: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
} 