import {
    ListContactsRequest,
    ListContactCategoriesRequest,
    UpdateProfileRequest,
    PostCreateContactRequest,
    PostCreateContactCategoryRequest
} from "../api/requestObjects"

import ApiService from "./ApiService"

class SettingsService extends ApiService {


    /**
     * Create a new contact
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateContact = (params) => {

        const { POST_CREATE_CONTACT } = this.endpoints
        const request = new PostCreateContactRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CREATE_CONTACT,
        }), request.getPayload())
    }

    /**
     * Update an existing contact
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    putUpdateContact = (params) => {

        const { PUT_UPDATE_CONTACT } = this.endpoints
        const request = new PostCreateContactRequest(params)

        return this.API.put(this.getEndpoint({
            path: PUT_UPDATE_CONTACT,
            data: params.contactId
        }), request.getPayload())
    }

    /**
     * Fetch contacts
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getContacts = (params) => {

        const { GET_CONTACTS } = this.endpoints
        const request = new ListContactsRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CONTACTS,
            params: request.getPayload()
        }))
    }

    /**
     * Fetch a specific contact by its unique ID
     * 
     * @param {Object} params
     * @param {String} params.contactId
     * @returns {Promise}
     */
    getContact = (params) => {

        const { GET_CONTACT } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_CONTACT,
            data: params.contactId,
        }))
    }

    /**
     * Delete a specific contact by its unique ID
     * 
     * @param {Object} params
     * @param {String} params.contactId
     * @returns {Promise}
     */
    postDeleteContact = (params) => {

        const { DELETE_CONTACT } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: DELETE_CONTACT,
            data: params.contactId,
        }))
    }

    /**
     * Create a new contact form category
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
     postCreateContactCategory = (params) => {

        const { POST_CREATE_CONTACT_FORM_CATEGORY } = this.endpoints
        const request = new PostCreateContactCategoryRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CREATE_CONTACT_FORM_CATEGORY,
        }), request.getPayload())
    }

    /**
     * Update an existing contact
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    putUpdateContactCategory = (params) => {

        const { PUT_UPDATE_CONTACT_FORM_CATEGORY } = this.endpoints
        const request = new PostCreateContactCategoryRequest(params)

        return this.API.put(this.getEndpoint({
            path: PUT_UPDATE_CONTACT_FORM_CATEGORY,
            data: params.categoryId
        }), request.getPayload())
    }

    /**
     * Fetch contact form categories
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getContactCategories = (params) => {

        const { GET_CONTACT_FORM_CATEGORIES } = this.endpoints
        const request = new ListContactCategoriesRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CONTACT_FORM_CATEGORIES,
            params: request.getPayload()
        }))
    }

    /**
     * Delete a specific contact form category by its unique ID
     * 
     * @param {Object} params
     * @param {String} params.categoryId
     * @returns {Promise}
     */
    postDeleteContactCategory = (params) => {

        const { DELETE_CONTACT_FORM_CATEGORY } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: DELETE_CONTACT_FORM_CATEGORY,
            data: params.categoryId,
        }))
    }
    
    /**
     * Fetch a specific contact form category by its unique ID
     * 
     * @param {Object} params
     * @param {String} params.categoryId
     * @returns {Promise}
     */
    getContactCategory = (params) => {

        const { GET_CONTACT_FORMS_CATEGORY } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_CONTACT_FORMS_CATEGORY,
            data: params.categoryId,
        }))
    }

    /**
     * Fetch account
     * 
     * @returns {Promise}
     */
    getAccount = () => {
        const { GET_ACCOUNT } = this.endpoints

        return this.getFakeResponse({
            "id": "92f5171e-938f-4dde-8b4a-c0aadb1fe364",
            "email": "finance@phsg.org.za",
            "phone_number": "0123456789",
            "physical_address": "123 Albyn Street, Pretoria 0001, South Africa",
            "language": "English",
            "contact_name": "Eliza Taylor",
            "contact_email": "e.taylor@phsg.org.za",
            "contact_phone_number": "0123456789",
            "logo_ur": "https://cdnrly.d6.co.za/cm/92f6f192-4316-4ee0-93cd-c4f1fbc3d254/6050928608497/20210316_81af932b40a887164979567b4e9ab950.jpg",
            "cover_url": "https://cdnrly.d6.co.za/cm/92f6f192-4316-4ee0-93cd-c4f1fbc3d254/6050928608497/20210316_81af932b40a887164979567b4e9ab950.jpg",
        })
        // return this.API.get(this.getEndpoint({path: GET_CONTACTS}))
    }

    /**
     * Fetch billing
     * 
     * @returns {Promise}
     */
    getBilling = () => {
        const { GET_BILLING } = this.endpoints

        return this.getFakeResponse({
            "id": "92f5171e-938f-4dde-8b4a-c0aadb1fe364",
            "card_number": "Visa **** *** **** 892",
            "expires_at": "03/2022",
            "last_payment_date": "1 March '22",
            "last_payment_amount": "For R1,208.00",
            "next_payment_date": "1 April '22",
            "saas_fee": "R 130.00",
            "sms_costs": "R693.23",
            "payment_plan_name": "Basic Plan",
            "payment_plan_amount": "R549",
            "payment_plan_due": "Next payment is scheduled for Jul 22,2021.",
            "transactions": [
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
                {
                    "date": "03/17/2021 10:34 AM",
                    "name": "Bulk SMS",
                    "amount": "R 20"
                },
            ]
        })
        // return this.API.get(this.getEndpoint({path: GET_CONTACTS}))
    }

    /**
     * Post update profile details
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postUpdateProfile = (params) => {

        const { POST_PROFILE } = this.endpoints
        const request = new UpdateProfileRequest(params)
        const validator = request.validator()

        if (!validator.isValid) {
            return this.getErrorResponse({
                message: validator.messages
            })
        }

        return this.API.post(this.getEndpoint({
            path: POST_PROFILE
        }), request.getPayload())
    }
}

export default SettingsService