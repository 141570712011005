import ApiService from "../services/ApiService"
import UploadService from "../services/UploadService"
import UserApiService from "../services/UserApiService"
import TieringService from "../services/TieringService"
import SettingsService from "../services/SettingsService"
import FakerApiService from "../services/FakerApiService"
import EventApiService from "../services/EventApiService"
import CalendarService from "../services/CalendarApiService"
import NoticeApiService from "../services/NoticeApiService"
import ChannelApiService from "../services/ChannelApiService"
import ResourceApiService from "../services/ResourceApiService"
import AssistedWritingService from "../services/AssistedWritingService"

const apiService = new ApiService()
const userService = new UserApiService()
const uploadService = new UploadService()
const fakerService = new FakerApiService()
const eventService = new EventApiService()
const tieringService = new TieringService()
const noticeService = new NoticeApiService()
const settingService = new SettingsService()
const calendarService = new CalendarService()
const channelService = new ChannelApiService()
const resourceService = new ResourceApiService()
const assistedWritingService = new AssistedWritingService()

export {
	apiService,
	userService,
	fakerService,
	eventService,
	uploadService,
	noticeService,
	tieringService,
	settingService,
	channelService,
	resourceService,
	calendarService,
	assistedWritingService,
}