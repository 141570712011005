import React, {useEffect, useMemo, useRef, useState} from 'react'

import { EditorState, ContentState, CharacterMetadata, Modifier, SelectionState, ContentBlock } from 'draft-js'
import { useTranslation } from 'react-i18next'
import { Dropdown, Icon, Label } from 'semantic-ui-react'
import { MdHearing, MdPsychology } from 'react-icons/md'
import { BsAlphabet } from 'react-icons/bs'
import { FaExpand, FaList, FaMagic } from 'react-icons/fa'
import { messageBuilder } from '../../../../constants'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { getSelectionText } from 'draftjs-utils'

const MenuDropdownOption = (props) => {
    
    const {handleOnClick, itemKey, title, description, icon, enabled } = props

    return (
        <Dropdown.Item
            title={title} 
            disabled={!enabled}
            onClick={() => handleOnClick(itemKey)}>
            <span className='row-1'>
                {icon}
                <span className='title'>{title}</span>
            </span>
            <span className='opt-description'>
                <span className='p-2' />
                {description}
            </span>
        </Dropdown.Item>
    )
}

const ButtonIcon = () => {
    return (
        <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" height={15} width={15} fill='white' viewBox="0 0 90.24 91.01"><g id="Layer_1-2"><path d="m85.6,34.68c.91-3.37,1.75-10.15-3.51-16.58-3.35-4.1-7.81-5.39-10.86-5.77-1.09-4.88-4.58-11.06-12.95-12.15-6.36-.82-10.59,1.36-13.16,4.03C42.55,1.54,38.32-.65,31.96.18c-8.37,1.08-11.86,7.27-12.95,12.15-3.05.38-7.51,1.68-10.86,5.77-5.26,6.43-4.42,13.21-3.51,16.58C1.48,38.86-.93,43.5.35,50.98c.96,5.64,4.66,9.41,7.28,11.44-.85,2.93-1.29,7.62,1.62,13.06,3.72,6.95,10.33,8.16,14.17,8.22,2.32,4.8,6.68,7.32,12.76,7.32,3.92,0,6.92-1.91,8.94-3.82,2.02,1.91,5.03,3.82,8.94,3.82,6.08,0,10.44-2.52,12.76-7.32,3.84-.06,10.46-1.27,14.17-8.22,2.91-5.44,2.47-10.13,1.62-13.06,2.62-2.03,6.32-5.8,7.28-11.44,1.27-7.48-1.14-12.12-4.3-16.3Zm-49.42,49.34c-3.99,0-6.03-1.43-7.05-4.92l-.89-2.97-3.06.49c-.28.04-6.84,1.04-9.77-4.43-2.83-5.29-.61-9.06-.54-9.17l1.11-1.7,9.56-9.56c.71.2,1.46.31,2.23.31,4.58,0,8.3-3.72,8.3-8.3s-3.72-8.3-8.3-8.3-8.3,3.72-8.3,8.3c0,1.22.27,2.37.74,3.42l-9.07,9.07c-1.56-1.37-3.4-3.54-3.9-6.45-.95-5.56.99-8.46,4.04-12.26l1.32-1.69-.86-1.95c-.11-.24-2.57-6.01,1.82-11.38,3.06-3.75,7.99-3.36,8.17-3.34l3.79.37.07-3.81c.02-.79.35-7.74,7.25-8.63,6.61-.85,8.26,3.21,8.51,3.99l.25.89v68.64c-.85,1.15-2.82,3.38-5.44,3.38Zm46.82-34.21c-.9,5.29-6.25,8.15-6.29,8.17l-3.5,1.72,2.13,3.26c.1.16,2.31,3.93-.51,9.22-2.92,5.47-9.49,4.48-9.74,4.44l-3.09-.54-.87,3.02c-1.01,3.5-3.06,4.93-7.05,4.93-2.63,0-4.6-2.23-5.44-3.37V11.99l.24-.84c.26-.82,1.92-4.89,8.53-4.04,6.9.89,7.23,7.84,7.25,8.61l.05,3.8,3.79-.33c.21-.02,5.13-.41,8.2,3.34.72.88,1.24,1.78,1.64,2.65l-11.15,11.15c-1.11-.55-2.36-.87-3.69-.87-4.58,0-8.3,3.72-8.3,8.3s3.72,8.3,8.3,8.3,8.3-3.72,8.3-8.3c0-.66-.09-1.3-.23-1.92l6.1-6.1-.05.11,1.36,1.69c3.05,3.79,4.99,6.69,4.04,12.26Z"/></g></svg>
    )
}

export const AssistedWriting = (props) => {
    
    const ref = useRef()
    const community = useSelector(state => state.navigation.community)
    const profile = useSelector(state => state.auth.profile)
    
    const {t} = useTranslation()

    const onSelect = (text) => {

        const { selection } = hasSelectedText()
        
        if (selection) {
            updateText(text)
            return
        }

        replaceText(text)
    }

    const replaceText = (text) => {
        const { editorState, onChange } = props
        // @TODO: Handle response better when there is special markup like HTML or Markdown, since at the moment this caters only for Plain text
        const newContent = ContentState.createFromText(text)
        onChange(EditorState.push(editorState, newContent, 'insert-characters'))
    }
    
    const updateText = (text) => {
        const { editorState, onChange } = props
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
            editorState.getCurrentInlineStyle(),
        )
        onChange(EditorState.push(editorState, contentState, 'insert-characters'))
    }

    /**
     * @typedef SelectionResult
     * @property {Boolean} selection
     * @property {String} text
     * @returns {SelectionResult}
     */
    function hasSelectedText() {
        const { editorState } = props

        const selectedText = getSelectionText(editorState)
        
        // We need to check first what the selected text is, and then only if there is no specific selection that we can use the entire content of th TextComponent
        if (!isEmpty(selectedText)) {
            return {
                selection: true,
                text: selectedText
            }
        }

        return {
            selection: false,
            text: editorState.getCurrentContent().getPlainText()
        }
    }

    const onFinishCallback = (assistedText) => {
        onSelect(assistedText)
    }

    const handleOnClick = (item) => {
        if (props.onOptionClick) {
            const { text } = hasSelectedText()
            props.onOptionClick(item, text, onFinishCallback)
        }
    }

    const hasText = useMemo(() => hasSelectedText().text.length > 0, [props.editorState])

    return (
        <div 
            ref={ref}
            className={`rdw-ai-assist-wrapper`}
            aria-label="rdw-ai-assist-control">
            <div
                className="rdw-ai-assist-options-wrapper"
                onClick={() => {}}>
                <Dropdown
                    trigger={
                        <span>
                            <ButtonIcon />
                            {t('MessageBuilder.ai.button.caption')}
                            {profile.open_ai_feature_is_new && <Label className='secondary' size='mini'>{t('MessageBuilder.ai.button.new.caption')}</Label>}
                        </span>
                    }
                    floating
                    button
                    icon=''
                    disabled={!hasText}
                    className='right icon tiny primary solid no-margin'>
                    <Dropdown.Menu className='custom-ai-dropdown__menu'>
                        <MenuDropdownOption
                            title={t('MessageBuilder.ai.help-me-write')}
                            handleOnClick={handleOnClick}
                            description={t('MessageBuilder.ai.help-me-write.description')}
                            enabled={!!community.integrations_openai_aw_generate_enabled}
                            itemKey={messageBuilder.INTEGRATIONS.OPEN_AI.MODES.GENERATE}
                            icon={<MdPsychology className='icon primary-text simplify'/>}/>
                        <MenuDropdownOption
                            title={t('MessageBuilder.ai.simplify')}
                            handleOnClick={handleOnClick}
                            description={t('MessageBuilder.ai.simplify.description')}
                            enabled={!!community.integrations_openai_aw_simplify_enabled}
                            itemKey={messageBuilder.INTEGRATIONS.OPEN_AI.MODES.SIMPLIFY}
                            icon={<BsAlphabet className='icon primary-text simplify'/>}/>
                        <MenuDropdownOption
                            title={t('MessageBuilder.ai.expand')}
                            handleOnClick={handleOnClick}
                            description={t('MessageBuilder.ai.expand.description')}
                            enabled={!!community.integrations_openai_aw_elaborate_enabled}
                            itemKey={messageBuilder.INTEGRATIONS.OPEN_AI.MODES.ELABORATE}
                            icon={<FaExpand className='icon primary-text'/>}/>
                        <MenuDropdownOption
                            title={t('MessageBuilder.ai.summarize')}
                            handleOnClick={handleOnClick}
                            description={t('MessageBuilder.ai.summarize.description')}
                            enabled={!!community.integrations_openai_aw_summarize_enabled}
                            itemKey={messageBuilder.INTEGRATIONS.OPEN_AI.MODES.SUMMARIZE}
                            icon={<FaList className='icon primary-text'/>}/>
                        <MenuDropdownOption
                            title={t('MessageBuilder.ai.change-tone')}
                            handleOnClick={handleOnClick}
                            description={t('MessageBuilder.ai.change-tone.description')}
                            enabled={!!community.integrations_openai_aw_changeTone_enabled}
                            itemKey={messageBuilder.INTEGRATIONS.OPEN_AI.MODES.CHANGE_TONE}
                            icon={<MdHearing className='icon primary-text'/>}/>
                        <MenuDropdownOption
                            title={t('MessageBuilder.ai.fix-spelling-and-grammar')}
                            handleOnClick={handleOnClick}
                            description={t('MessageBuilder.ai.fix-spelling-and-grammar.description')}
                            enabled={!!community.integrations_openai_aw_improve_enabled}
                            itemKey={messageBuilder.INTEGRATIONS.OPEN_AI.MODES.IMPROVE}
                            icon={<FaMagic className='icon primary-text'/>}/>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    )
}