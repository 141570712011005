import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from '../actions/authActions'
import routes from '../routes/route-constants'
import { matchLink } from '../utils/helper'
const Login = (props) => {

  const dispatch = useDispatch()

  const handleLogin = () => {
    dispatch(authActions.start())
    
    if (props.location && props.location.state && props.location.state.from) {
      
      const match = matchLink({
        pathname: props.location.state.from,
        patterns: [routes.public.LOGIN, routes.private.HOME, routes.public.LOGOUT_REDIRECT]
      })

      if (!match) {
        dispatch(authActions.saveReturnUrl(props.location.state.from))
      }
    }

    dispatch(authActions.doLogin())
  }

  // Add the redirect URL to the address bar
  useEffect(() => {

    // Add login class to add image background
    document.body.classList.add('login')

    handleLogin()
  }, [])

  return (
    <div>
      <h1>...</h1>
    </div>
  )
}

export default Login