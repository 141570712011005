import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { string, func, number, bool, shape, node, object } from 'prop-types';

import formatCurrency, { getISO4217byCode, toUnit } from './format-currency';

const defaultConfig = {
  locale: 'en-US',
  reversal: false,
  formats: {
    number: {
      USD: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: null,
        maximumFractionDigits: null,
      },
    },
  },
};

const IntlCurrencyInput = ({
  component: InputComponent,
  value,
  defaultValue,
  config,
  currency,
  max,
  autoFocus,
  autoSelect,
  autoReset,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  currencyOverride,
  ...otherProps
}) => {
  const inputRef = useCallback(node => {
    const isActive = node === document.activeElement;

    if (node && autoFocus && !isActive) {
      node.focus();
    }
  }, [autoFocus]);

  const [maskedValue, setMaskedValue] = useState('0');

  // Get the currency object
  const iso4217currency = getISO4217byCode(currency);

  // to prevent a malformed config object
  const safeConfig = useMemo(() => () => {

    config.formats.number[currency]["minimumFractionDigits"] = parseInt(iso4217currency.D);
    config.formats.number[currency]["maximumFractionDigits"] = parseInt(iso4217currency.D);
    
    const finalConfig = {
      ...defaultConfig,
      ...config,
    };

    return finalConfig;
  }, [defaultConfig, config]);

  const clean = (number) => {
    const { reversal } = safeConfig();

    if (typeof number === 'number') {
      return number;
    }

    const numberString = number.toString();
    const cleanedNumber = numberString.replace(/[^0-9]/g, '');

    if (reversal) {
      return Number(`-${cleanedNumber}`);
    }

    // strips everything that is not a number (positive or negative)
    return Number(cleanedNumber);
  };

  const normalizeValue = number => {
    const { formats: { number: { [currency]: { maximumFractionDigits } } } } = safeConfig();
    let safeNumber = number;

    if (typeof number === 'string') {
      safeNumber = clean(number);

      if (safeNumber % 1 !== 0) {
        safeNumber = safeNumber.toFixed(maximumFractionDigits);
      }

    } else {
      // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
      safeNumber = Number.isInteger(number) ? Number(number) * (10 ** maximumFractionDigits) : number.toFixed(maximumFractionDigits);
    }

    // divide it by 10 power the maximum fraction digits.
    return clean(safeNumber) / (10 ** maximumFractionDigits);
  };

  const calculateValues = (inputFieldValue) => {
    const { reversal } = safeConfig();
    const value = normalizeValue(inputFieldValue);
    const maskedValue = formatCurrency(value, safeConfig(), currency);
    const minorUnits = toUnit(value, currency)

    const negateValue = (inputAmount) => {
      
      if (!reversal) {
        return String(inputAmount).replace('-', '') // @NOTE: We can safely clean away the negative symbol for non-reversals.
      }

      if (inputAmount.includes('-')) {
        return inputAmount
      }

      return ("-").concat(inputAmount) // @NOTE: Append the Negative on the mask, this is so the negative cannot be deleted.
    }

    // FIX: ZAR -> R 
    // We can overwrite the config only for the display and we can now take the current currency
    // and grab use this as the key to map which value from [currencyOverride] we should use as replacer
    // currencyOverride = {"currency":"replacement value"}, EG: {"USD":"MLSD"}, {"ZAR":"R"}    

    if (currencyOverride) {
      const ovCurrency = currency;
      const ovValue = currencyOverride[currency]

      return [value, negateValue(maskedValue.replace(ovCurrency, ovValue)), minorUnits];
    }

    return [value, negateValue(maskedValue), minorUnits];
  };

  const updateValues = (value) => {
    const [calculatedValue, calculatedMaskedValue, minorUnits] = calculateValues(value);

    if (!max || calculatedValue <= max) {
      setMaskedValue(calculatedMaskedValue);

      return [calculatedValue, calculatedMaskedValue, minorUnits];
    } else {
      return [normalizeValue(maskedValue), maskedValue, minorUnits];
    }
  };

  const handleChange = (event) => {
    event.preventDefault();

    const [value, maskedValue, minorUnits] = updateValues(event.target.value);

    if (maskedValue) {
      onChange(event, value, maskedValue, minorUnits);
    }
  };

  const handleBlur = (event) => {
    const [value, maskedValue, minorUnits] = updateValues(event.target.value);

    if (autoReset) {
      calculateValues(0);
    }

    if (maskedValue) {
      onBlur(event, value, maskedValue, minorUnits);
    }
  };

  const handleFocus = (event) => {
    if (autoSelect) {
      event.target.select();
    }

    const [value, maskedValue, minorUnits] = updateValues(event.target.value);

    if (maskedValue) {
      onFocus(event, value, maskedValue, minorUnits);
    }
  };

  const handleKeyUp = event => onKeyPress(event, event.key, event.keyCode);

  useEffect(() => {
    const currentValue = value ?? defaultValue ?? 0;
    const [, maskedValue,] = calculateValues(currentValue);

    setMaskedValue(maskedValue);
  }, [currency, value, defaultValue, config]);

  return (
    <InputComponent
      {...otherProps}
      ref={inputRef}
      value={maskedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyUp={handleKeyUp}
    />
  );
};

IntlCurrencyInput.propTypes = {
  defaultValue: number,
  value: number,
  max: number,
  component: node.isRequired,
  currency: string.isRequired,
  config: shape().isRequired,
  currencyOverride: object,
  autoFocus: bool.isRequired,
  autoSelect: bool.isRequired,
  autoReset: bool.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onKeyPress: func.isRequired,
};

IntlCurrencyInput.defaultProps = {
  component: 'input',
  currency: 'USD',
  value: 0,
  config: defaultConfig,
  currencyOverride: null,
  autoFocus: false,
  autoSelect: false,
  autoReset: false,
  onChange: f => f,
  onBlur: f => f,
  onFocus: f => f,
  onKeyPress: f => f,
};

export default IntlCurrencyInput;
