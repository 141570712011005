import { actions, constants } from '../constants'
import { map, orderBy } from 'lodash'
const { CONTACT_CATEGORY } = actions

const initialState = {
    categories: [],
    loading: false,
    detail: {},
    filters: {},
    pagination: {},
    error: null,
    messages: null,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case CONTACT_CATEGORY.RESET: {
            return {
                ...state, 
                categories: [],
                loading: false,
                detail: {},
                filters: {},
                pagination: {},
                error: null,
                messages: null,
            }
        }
        case CONTACT_CATEGORY.START: {
            return {...state, loading: true}
        }
        case CONTACT_CATEGORY.CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null}
        }
        case CONTACT_CATEGORY.ERROR: {
            let error = action.payload.response || {}

            error = error.data ? error.data.message : "error.errorOccurred"
            
            return {
                ...state, 
                loading: false, 
                errors: error, 
                messages: null, 
                detail: {
                    ...state.detail, 
                    loading: false
                }
            }
        }
        case CONTACT_CATEGORY.SUCCESS: {
            return {
                ...state, 
                loading: false, 
                errors: null, 
                messages: action.payload,
                detail: {
                    ...state.detail, 
                    loading: false
                }
            }
        }
        case CONTACT_CATEGORY.SAVE: {
            
            return {
                ...state, 
                loading: false, 
                categories: action.payload.data,
                categoriesTransformed: map(action.payload.data, c => {
                    return {
                        ...c,
                        actions: [
                            constants.MENU_ACTION_DELETE,
                        ]
                    }
                }), 
                pagination: action.payload.pagination
            }
        }
        case CONTACT_CATEGORY.SAVE_CONTACT_CATEGORY_DETAILS: {
            
            const detail = action.payload

            return {
                ...state, 
                loading: false,
                detail: {
                    ...state.detail,
                    ...detail,
                },
            }
        }
        case CONTACT_CATEGORY.SAVE_FILTERS: {
            return {
                ...state, 
                loading: false, 
                filters: {},
            }
        }
        case CONTACT_CATEGORY.CLEAR_CONTACT_CATEGORY_DETAILS: {
            return {
                ...state, 
                loading: false,
                detail: null, 
            }
        }
        default: {}
    }

    return state
}