import React from 'react'
import { List } from 'semantic-ui-react'

const BaseTemplate = ({ render, classNames }) => {
    return (
        <List.Item className={`preview-list-item ${classNames ? classNames : ''}`}>
            {render()}
        </List.Item>
    )
}

export default BaseTemplate
