import React from 'react'
import ReactDOM from 'react-dom/client'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { datadogRum } from '@datadog/browser-rum'

import { Provider } from 'react-redux'
import store from './store'
import { ConnectedRouter } from 'connected-react-router'

import * as serviceWorker from './serviceWorker'

import './i18n'

import history from './history'
import MainLayout from './layouts/MainLayout'
import Config from './config'
import { version } from "../package.json"
import Clarity from '@microsoft/clarity'

Bugsnag.start({
  apiKey: Config.bugsnagApiKey,
  plugins: [new BugsnagPluginReact()],
  releaseStage: Config.env,
  enabledReleaseStages: ['production', 'staging'],
  redactedKeys: [
    'headers',
    'access_token', // exact match: "access_token"
    /^password$/i,  // case-insensitive: "password", "PASSWORD", "PaSsWoRd"
    /^cc_/          // prefix match: "cc_number" "cc_cvv" "cc_expiry"
  ]
})

datadogRum.init({
    applicationId: Config.datadog.appId,
    clientToken: Config.datadog.clientToken,
    site: 'datadoghq.com',
    service: 'relay-portal',
    env: Config.env,
    version: version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: Config.clarity.projectId ? 0 : 20, // Disable Datadog session replays when Clarity is enabled.
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
})

if (Config.clarity.projectId) {
  Clarity.init(Config.clarity.projectId)
}

const ErrorBoundary = Bugsnag
  .getPlugin('react')
  .createErrorBoundary(React)

const rootElement = ReactDOM.createRoot(document.getElementById('root'))
rootElement.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ConnectedRouter history={history}>
          {/* @TODO: Disable strict mode, because when we redirect to to create builder data and the component gets unmounted by strict-mode we loose the data we initially redirected with */}
          {/* <React.StrictMode></React.StrictMode>  */}
          <MainLayout />
      </ConnectedRouter>
    </Provider>
  </ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
