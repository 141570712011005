import { actions, constants, relationshipConstants } from '../constants'
import { size, map, find, pickBy, identity, first, pick, sortBy, orderBy } from 'lodash'
import Config from '../config'

const { NAVIGATION, AUTH_SAVE_PROFILE } = actions

const initialState = {
    collapsed: false,
    mdCollapsed: false, // Used for when the menu is completely hidden
    community: null,
    communities: [],
    decoratedCommunities: [],
    defaults: null,
    // locale: localStorage.getItem("i18nextLng") || "en"
}

const transformData = (c) => {
    return {
        ...c,
        payment_currency_iso_4217: c.payment_currency_iso_4217 || "ZAR",
        can_view_settings: c.can_view_settings,
        can_target_everyone: c.can_target_everyone ?? false,
        portal_accessible: c.portal_accessible ?? undefined,
        default_country_calling_code: c.default_country_calling_code || 27,
        is_app_checked: c.notice_platform_distribution_default_app_selected  ?? false,
        is_email_checked: c.notice_platform_distribution_default_email_selected  ?? false,
        is_sms_checked: c.notice_platform_distribution_default_sms_selected  ?? false,
        is_whatsapp_checked: c.notice_platform_distribution_default_whatsapp_selected  ?? false,
        is_whatsapp_visible: c.integrations_whatsapp_publish_platform_visible ?? false,
        publish_relationship: find(c?.notice_distribution_relationships, {selected: true})?.id ?? first(c.notice_distribution_relationships)?.id,
        
        // @TODO: Enable some feature only for Affies
        // Afrikaanse Hoër Seunskool Test Community - 978bea2a-879d-4766-a9ca-5c15398b0323
        // Afrikaanse Hoër Seunskool - 96457050-bbd4-4a0c-ab0e-f68ff3e4ccb9
        is_affies: Config.isStaging || Config.isLocal || ["978bea2a-879d-4766-a9ca-5c15398b0323", "96457050-bbd4-4a0c-ab0e-f68ff3e4ccb9"].includes(c.id)
    }
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case NAVIGATION.SHOW_SIDEBAR: {
            return {...state, collapsed: false}
        }
        case NAVIGATION.HIDE_SIDEBAR: {
            return {...state, collapsed: true}
        }
        case NAVIGATION.SHOW_BUILDER_SIDEBAR: {
            return {...state, showBuilderSidebar: true}
        }
        case NAVIGATION.HIDE_BUILDER_SIDEBAR: {
            return {...state, showBuilderSidebar: false}
        }
        case NAVIGATION.TOGGLE_BUILDER_SIDEBAR_VISIBILITY: {
            return {...state, collapsedBuilderSidebar: action.payload}
        }
        case NAVIGATION.TOGGLE_SIDEBAR_VISIBILITY: {
            return {...state, collapsed: action.payload, mdCollapsed: false}
        }
        case NAVIGATION.TOGGLE_MD_SIDEBAR_VISIBILITY: {
            return {...state, collapsed: false, mdCollapsed: action.payload}
        }
        case NAVIGATION.TOGGLE_ACTIVE_LOCALE: {
            return {...state, locale: action.payload}
        }
        case AUTH_SAVE_PROFILE: {
            
            const selectedCommunity = action.payload.current_community

            const _communities = map(action.payload.other_communities, c => {
                if (c.id === selectedCommunity.id) {
                    return {
                        order: 1,
                        ...selectedCommunity
                    }
                }
                return {
                    ...c,
                    order: 0
                }
            })
            
            const communities = map(_communities, c => {
                return transformData(c)
            })

            const decoratedCommunities =  map(orderBy(communities, ['order', 'name'], ['desc', 'asc']), c => {
                return {
                    key: c.id, 
                    value: c.id, 
                    text: c.name, 
                    image: {
                        avatar: true, 
                        src: c.image
                    }
                }
            })

            const defaultCommunityId = localStorage.getItem(constants.STORAGE_SELECTED_COMMUNITY)
            const noCommunitySelectedDefault = selectedCommunity ?? null

            let _community = noCommunitySelectedDefault ?? {}
            _community = transformData({
                ..._community,
                is_app_checked: _community?.notice_platform_distribution_default_app_selected  ?? false,
                is_sms_checked: _community?.notice_platform_distribution_default_sms_selected  ?? false,
                is_email_checked: _community?.notice_platform_distribution_default_email_selected  ?? false,
                is_whatsapp_checked: _community?.notice_platform_distribution_default_whatsapp_selected  ?? false,
                is_whatsapp_visible: _community?.notice_platform_distribution_whatsapp_visible  ?? false,
                publish_relationship: find(_community?.notice_distribution_relationships, {selected: true})?.id ?? first(_community?.notice_distribution_relationships)?.id,
            })

            // @TODO: Remember the default selected community
            // if (!["null", null].includes(defaultCommunityId)) {
            //     _community = find(communities, {id: defaultCommunityId})

            //     // When for some reason teh saved ID is not in the list the user has on login
            //     // ??NOTE: this can happen if the initial person logged out, and the second person logged in but with a set of new data
            //     if (!_community) {
            //         _community = noCommunitySelectedDefault
            //     }
            // }
            
            // Cache selected community to  LocalStorage for convenience
            localStorage.setItem(constants.STORAGE_SELECTED_COMMUNITY, _community ? _community.id : null)

            return {
                ...state,
                communities: communities,
                communityId: _community ? _community.id : null,
                community: _community ? _community : null,
                decoratedCommunities,
            }
        }
        case NAVIGATION.SELECT_COMMUNITY: {

            if (!action.payload) {
                return {...state}
            }

            const communityId = action.payload
            const community = find(state.communities, {id: communityId})
            
            // Cache selected community to  LocalStorage for convenience
            localStorage.setItem(constants.STORAGE_SELECTED_COMMUNITY, communityId)
            
            return {...state, communityId, community}
        }
        case NAVIGATION.SAVE_TO_HASH_ROUTER: {

            return {
                ...state
            }
        }
        default: {}
    }

    return state
}