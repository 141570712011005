import i18next from "i18next"
import { pickBy, identity, mapKeys, map, isEmpty, isNil, size } from "lodash"
import { membersImportTypes } from "../../constants"
import { filterNulls } from "../../utils/helper"

/**
 * @typedef Request
 * @property {String} communityId
 * @property {String} file
 * @property {String} type
 * @property {String} mode
 * @property {String[]} fields
 * @property {Boolean} shouldNotify
 * @property {Boolean} shouldUpdate
 * @property {Boolean} shouldDelete
 * @property {Boolean} addToGroups
 * @property {Boolean} shouldNotifyDelete
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */

class ImportMembersRequest {

  /**
   * 
   * @param {Request} request
   */
  constructor({
    file,
    type,
    mode,
    fields,
    communityId,
    shouldNotify,
    shouldUpdate,
    shouldDelete,
    addToGroups,
    shouldNotifyDelete,
  }) {
    this.file = file
    this.fields = fields
    this.type = type
    this.mode = mode
    this.communityId = communityId
    this.shouldNotify = shouldNotify
    this.shouldUpdate = shouldUpdate
    this.shouldDelete = shouldDelete
    this.addToGroups = addToGroups
    this.shouldNotifyDelete = shouldNotifyDelete
  }

  /**
   * Post data ready for usage by the API
   * 
   * @returns {Request}
   */
  getPayload = () => {

    const payload = {
      "file": this.file,
      "type": this.type,
      "mode": this.mode,
      // "community_id": this.communityId,
      "notify_on_subscription_changes": this.shouldNotify
    }

    if ([membersImportTypes.STANDARD, membersImportTypes.ADVANCED_LINKING].includes(payload.type)) {
      payload['identifiers'] = this.fields
    }

    if (!isNil(this.shouldDelete)) {
      payload["delete_excluded_members"] = this.shouldDelete
    }
    
    if (!isNil(this.addToGroups)) {
      payload["add_to_groups"] = this.addToGroups
    }
    
    if (!isNil(this.shouldNotifyDelete)) {
      payload["notify_community_subscription_removed_via_app"] = this.shouldNotifyDelete
    }
    
    payload['data_mode'] = this.shouldUpdate ? 'DATA-MODE-UPDATE' : 'DATA-MODE-CREATE'

    // We need to create FormData since the request has to be a multipart/form-data
    const formData = new FormData()

    // Add our request payload to the form-data
    map(payload, (value, key) => formData.append(key, value))

    return formData
  }

  /**
 * Determine error messages from the given data if any
 * 
 * @returns {ValidationError}
 */
  validator = () => {
    const errors = []

    if (!this.type) {
      errors.push({
        field: "type",
        isValid: false,
        message: i18next.t('requests.members.import.requiredType')
      })
    }
    
    if ([
      membersImportTypes.STANDARD, 
      membersImportTypes.ADVANCED_LINKING
    ].includes(this.type) && isEmpty(this.fields)) {
      
      errors.push({
        field: "fields",
        isValid: false,
        message: i18next.t('requests.members.import.requiredField')
      })
    }
    
    if ([
      membersImportTypes.STANDARD, 
      membersImportTypes.ADVANCED_LINKING
    ].includes(this.type) && size(this.fields) > 3) {
      
      errors.push({
        field: "fields",
        isValid: false,
        message: i18next.t('requests.members.import.maxReached')
      })
    }
    
    if (!this.file) {
      errors.push({
        field: "file",
        isValid: false,
        message: i18next.t('requests.members.import.requiredFile')
      })
    }

    return {
      isValid: (errors.length === 0),
      messages: mapKeys(errors, error => error.field)
    }
  }

}

export default ImportMembersRequest