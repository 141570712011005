import React, { useEffect, useState, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Grid, Breadcrumb, Form, Message, Checkbox, Button } from 'semantic-ui-react'
import { filter, isEmpty, isNil, map, size } from 'lodash'
import { push } from 'connected-react-router'
import routes from '../../../routes/route-constants'
import { Trans, useTranslation } from 'react-i18next'
import { appendCommunity, getError, getParameterByName, renderMarkup, weekOfMonth } from '../../../utils/helper'
import Role from '../../partials/Role'
import FormMessages from '../../partials/FormMessages'
import { PostUpdateEventRequest } from '../../../api/requestObjects'
import PageSection from '../../partials/PageSection'
import { _determineRRuleKey, _weekdayIndexToString, calendarConstants, constants, messageBuilder } from '../../../constants'
import { calendarsActions } from '../../../actions/calendarsActions'
import moment from 'moment'
import AnimLoader from '../../partials/AnimLoader'
import { eventsActions } from '../../../actions/eventsActions'
import ConfirmModal from '../../partials/ConfirmModal'
import { CalendarDetailContents } from '../CreateEventModal'
import AddUsersModal from '../../partials/AddUsersModal'
import { eventService } from '../../../api'
import { ListView } from '../../message-builder/preview'
import CustomRRulesModal from '../../partials/CustomRRulesModal'
import { RRule, rrulestr } from 'rrule'
import i18next from 'i18next'
import PostEventRecurringRulesRequest from '../../../api/requestObjects/PostEventRecurringRulesRequest'
import { rruleToText } from '../../../i18n/RRuleToText'
import { usersActions } from '../../../actions/usersActions'
import { v4 } from 'uuid'

export const RenderNormal = (props) => {
    
    const { data, isPreview } = props
    
    const format = 'yyyy-MM-DD'
    const isSameDay = moment(data.startDate, format).isSame(moment(data.endDate, format))    
    
    return (
        <Grid.Row className="no-margin">
            <div className='no-grow'>
                <p className="no-margin black-text">{data.previewStartDate}</p>
                <h2 className="no-margin primary-color-text"><strong>{data.previewStartTime}</strong></h2>
            </div>
            <div className="grow-sm">
                {isPreview || isSameDay && <p className="no-margin black-text">&nbsp;&nbsp;</p>}
                <h1 className="no-margin primary-color-text left-align">-</h1>
            </div>
            <div  className='no-grow'>
                {!isSameDay && (<p className="no-margin black-text">{data.previewEndDate}</p>)}
                {isSameDay && (<p className="no-margin primary-color-text">&nbsp;&nbsp;</p>)}
                <h2 className="no-margin primary-color-text"><strong>{data.previewEndTime}</strong></h2>
            </div>
        </Grid.Row>
    )
}

export const RenderAllDay = (props) => {
    
    const { data } = props

    const format = 'yyyy-MM-DD'
    const isSameDay = moment(data.startDate, format).isSame(moment(data.endDate, format))    

    return (
        <Grid.Row className="no-margin rep__all-day">
            <div className='no-grow'>
                <h2 className="no-margin primary-color-text"><strong>{data.previewStartDate}</strong></h2>
            </div>
            {!isSameDay && (
                <Fragment>
                    <div className="grow-sm">
                        <h1 className="no-margin primary-color-text left-align">-</h1>
                    </div>
                    <div  className='no-grow'>
                        <h2 className="no-margin primary-color-text"><strong>{data.previewEndDate}</strong></h2>
                    </div>
                </Fragment>
            )}
        </Grid.Row>
    )
}

export default function EventDetail(props) {
    
    const {
        router,
    } = props

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const navigation = useSelector(state => state.navigation)
    const eventManagement = useSelector(state => state.events)
    const calendarManagement = useSelector(state => state.calendars)
    const personFilterChannels = useSelector(state => state.users?.member?.filters?.channels)
    
    const options = map(calendarConstants.EVENT_REPEAT_OPTIONS, r => {
        return {
            key: r.key, 
            value: r.key, 
            text: r.description,
            onClick: (r.key === calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key) ? handleShowCustomRepeat : null
        }
    })
        // options = filter(options, o => {return o.key !== calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key})

    const [repeatOptions, setOptions] = useState(options)
    const [validationMessages, setValidationMessages] = useState([])
    
    const _initRecipients = (payload) => {
        
        // Nothing parsed, we do nothing
        if (!payload) {
            return {
                users: [],
                channels: [],
            }
        }

        // Initialize the channels if there are any
        let _channels = payload.channels
        let _users = payload.persons
        const _isTargetingAll = payload.targets_everyone

        _channels = map(_channels, "id")
        _users = map(_users, "id")

        if (_users.length > 0) {
            // We need to add the infamous channelId=-900 AKA "Add Individuals" tag
            _channels = _channels.concat([messageBuilder.ADD_INDIVIDUALS_TAG_ID])
        }
        
        if (_isTargetingAll) {
            // We need to add the infamous channelId=-901 AKA "Target Everyone" tag
            _channels = _channels.concat([messageBuilder.TARGET_ALL_TAG_ID])
        }

        return {
            channels: _channels,
            users: _users,
        }
    }

    const [confirm, setConfirm] = useState({
        showModal: false,
        onConfirm: () => { },
        onClose: () => { },
        message: "",
        header: "",
    })
    
    const [customRRuleModal, setCustomRRuleModal] = useState({
        showModal: false,
        onConfirm: (d) => handleConfirmCustomRules(d),
        onClose: () => handleCloseCustomRules(),
        header: t('event.create.repeat.custom.modal.header'),
    })
    
    const [eventMessageSample] = useState([
        {
            "id": "1b0a061c-780b-427c-8a2a-1bcaa1702c92",
            "order": 2,
            "type": "event",
            "name": "Event",
            "info": null,
            "isNew": true,
            "misBehave": true,
            "errors": {},
            "data": {
                "previewText": moment().format("D MMM 'YY")
            }
        },
        {
            "id": "1b0a061c-780b-427c-8a2a-1bcaa1702d98",
            "order": 2,
            "type": "event",
            "name": "Event",
            "info": null,
            "isNew": true,
            "misBehave": true,
            "errors": {},
            "data": {}
        },
    ])

    const [data, setData] = useState({
        title: "",
        notes: "",
        remind: false,
        repeat: repeatOptions[0].key,
        allDay: false,
        location: "",
        personIds: [],
        startDate: new Date(moment().toISOString()),
        endDate: new Date(moment().toISOString()),
        startTime: new Date(moment().toISOString()),
        endTime: new Date(moment().add(1, 'hour').toISOString()),
        channelIds: [],
        remindMinutes: 0,
        users: [],
        channels: [],
        communityId: navigation.communityId ?? router.match.params.communityId,
        calendarId: router.match.params.calendarId,
        id: router.match.params.id,
        deleteMethod: 'all',
        customRRules: {},
        rulesFormatted: null,
        resetKey: v4(),
    })

    const [minTime, setMinTime] = useState(data.startTime)
    const [minStartTime, setMinStartTime] = useState(new Date())

    const [showNotifyConfirm, setShowNotifyConfirm] = useState(false)
    const [shouldNotify, setShouldNotify] = useState(true)

    // Table config
    const actionFunctions = {
        [constants.MENU_ACTION_VIEW]: (record) => {
            if (record.id) {
                handleShowMessage(record.id)
            }
        }
    }

    // Cleanup
    useEffect(() => {

        dispatch(eventsActions.fetchEventFilters({
            communityId: navigation.communityId,
        }))

        // Load member filters to use later for event creation
        dispatch(usersActions.startFilters())
        dispatch(
            usersActions.fetchMemberFilters({
                communityId: navigation.communityId
            })
        )

        return () => {
            // dispatch(eventsActions.clearMessages())
            // dispatch(calendarsActions.clearMessages())
            dispatch(eventsActions.cleanEventDetails())
            dispatch(calendarsActions.cleanCalendarDetails())
        }
    }, [])

    // Setup this page for new calendar creation
    useEffect(() => {

        if (!navigation.communityId) return;
        
        // Fetch the parent calendar
        dispatch(calendarsActions.start())
        dispatch(eventsActions.start())
        
        dispatch(eventsActions.fetchEvent({
            eventId: data.id,
            communityId: data.communityId
        }))

        dispatch(calendarsActions.fetchCalendar({
            calendarId: data.calendarId,
            communityId: data.communityId
        }))

    }, [navigation])

    // Refresh data upon builder refreshData flag
    useEffect(() => {
        
        if (!eventManagement.messages) return;

        if (eventManagement.messages.navigateMain) {
            // const pageSource = router.location.query.source
            const pageSource = getParameterByName("source", window.location.href, 'String')
            const filters = router.location?.state?.prevUrl ?? ''
            
            if (pageSource === 'CALENDAR') {
                dispatch(push(appendCommunity(data.communityId, routes.private.MANAGE_EVENTS).concat(filters)))
                return;
            }
            
            if (pageSource === 'CALENDAR_DETAIL') {
                dispatch(push(routes.private.CALENDAR_DETAIL
                    .replace(":id", data.calendarId)
                    .replace(':communityId', data.communityId)
                    .concat(filters)
                ))
                return;
            }
        }

        if (eventManagement.messages.refreshData) {
            dispatch(eventsActions.start())
            dispatch(eventsActions.fetchEvent({
                eventId: data.id,
                communityId: data.communityId
            }))
        }

    }, [eventManagement?.messages])

    const _determineEnds = (payload) => {

        if (payload.until) {
            return 'ends_on'
        }
        
        if (payload.count > 0) {
            return 'ends_after'
        }

        return 'never'
    }

    // When we get the detail, update local state
    useEffect(() => {

        if (!eventManagement.detail) return;
        
        const recipients = _initRecipients(eventManagement.detail)
        
        const rules = eventManagement.detail.custom_repeat_rule
        if (rules) {
            const rrules = new PostEventRecurringRulesRequest({
                days: map(rules?.days ?? []).map(d => _determineRRuleKey(d)?.getJsWeekday()),
                interval: rules?.interval ?? 1,
                doesEnd: _determineEnds(rules),
                until: rules?.until,
                afterOccurrences: rules?.count ?? 10,
                frequency: rules?.frequency ?? calendarConstants.REPEAT_FREQUENCY.WEEKLY.key,
                endsOnDate: moment(rules?.until).isValid() ? moment(rules?.until).toDate() : null,
                monthlyOn: rules?.monthly_on ?? calendarConstants.REPEAT_MONTHLY_OPTIONS.MONTHLY_ON_DATE,
            })

            handleConfirmCustomRules(rrules.getPayload(), eventManagement.detail?.recurrence_rules)
        }

        const mediaType = eventManagement.detail.description_media_type ?? messageBuilder.MARKUP.plain
        const renderedContent = renderMarkup({
            type: mediaType,
            initial: eventManagement.detail.notes ?? '',
        })

        setData(prev => ({
            ...prev,
            personIds: recipients.users,
            channelIds: recipients.channels,
            notes: renderedContent.markdown ?? "",
            title: eventManagement.detail.title,
            source: eventManagement.detail.source,
            repeat: eventManagement.detail.repeat,
            allDay: eventManagement.detail.all_day,
            persons: eventManagement.detail.persons,
            notices: eventManagement.detail.notices,
            remind: eventManagement.detail.has_reminder ?? false,
            endDate: new Date(eventManagement.detail.all_day ? eventManagement.detail.all_day_end_date ?? eventManagement.detail.end_date : eventManagement.detail.end_date),
            channels: eventManagement.detail.channels,
            location: eventManagement.detail.location ?? "",
            startDate: new Date(eventManagement.detail.all_day ? eventManagement.detail.all_day_start_date ?? eventManagement.detail.start_date : eventManagement.detail.start_date),
            calendarId: eventManagement.detail.calendar_feed_id,
            endTime: moment(eventManagement.detail.end_date).toDate(),
            startTime: moment(eventManagement.detail.start_date).toDate(),
            isRepeating: eventManagement.detail.is_repeating,
            content: renderedContent.markdown,
            jsonContent: renderedContent.rawContents,
            mediaType: mediaType,
            resetKey: v4()
        }))

        setSelectedUsers(prev => ({
            ...prev,
            selected: recipients.users
        }))

    }, [eventManagement.detail])
    
    const [showAddUsers, setShowAddUsers] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState({
        selected: [],
        users: [],
        tempOptions: [],
    })

    // When we wish to remind or not, we can update the remind minutes
     useEffect(() => {
        setData(prev => ({ 
            ...prev, 
            remindMinutes: prev.remind ? calendarConstants.DEFAULT_REMIND_MINUTES : 0
        }))
    }, [data.remind])
    
    // When we wish to remind or not, we can update the remind minutes
     useEffect(() => {
        
        const _day = moment(data.startDate).format("dddd")
        const _options = map(options, r => {return {...r, text: r.text.replace("{DAY}", _day)}})
        setOptions(_options)

    }, [data.startDate])

    useEffect(() => {
        
        const startDate = moment(data.startDate).toDate()
        const isSameDay = moment(startDate).isSame(moment(data.endDate), "day")
        const _minTime = isSameDay ? moment(startDate).toDate() : moment(startDate).startOf("day").toDate()
        const _minStartTime = moment(startDate).isSame(moment(), "day") ? moment().toDate() : moment(startDate).startOf("day").toDate()

        setMinTime(_minTime)
        setMinStartTime(_minStartTime)

        const isDateAfter = moment(data.startDate).isSameOrAfter(moment(data.endDate))
        const isTimeAfter = moment(data.startTime).isSameOrAfter(moment(data.endTime))
        
        if (isDateAfter || isTimeAfter) {
            setData((prevData) => ({
                ...prevData,
                endDate: moment(data.startDate).add(1, 'hour').toDate(),
                endTime: moment(data.startTime).add(1, 'hour').toDate(),
            }))
        }

        // The start date of an event cannot be higher than the endsOnDate of a custom RRule
        if (!isEmpty(data.customRRules)) {
            const exceedsRRuleDate = moment(data.startDate).isSameOrAfter(moment(data.customRRules?.endsOnDate))
            if (exceedsRRuleDate) {
                setData((prevData) => ({
                    ...prevData,
                    customRRules: {},
                    rulesFormatted: null
                }))
            }
        }

    }, [data.startDate, data.startTime, data.allDay])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }
    
    const handleChangeDeleteMethod = (e, { value })=> {
        setData(prev => ({...prev, deleteMethod: value}))
    }

    const handleDateTimeChange = ({name, date}) => {

        setData((prevData) => ({
            ...prevData,
            [name]: date,
        }))
    }

    const handleChange = (e, {value}) => {
        setData({
            ...data,
            type: value
        })
    }
    
    const handleSwitchChange = ({name}) => {
        setData({...data, [name]: !data[name]})
    }

    const handleLocationChange = (placeDetails) => {
        
        const { address, location, original } = placeDetails

        setData((prevData) => ({
            ...prevData,
            location: address,
            previewText: address,
            latitude: location.lat,
            longitude: location.lng,
            originalPlace: {},
            // originalPlace: original,
        }))
    }

    const handleRepeatChange = (e, data) => {
        setData(prev => ({
            ...prev,
            repeat: data.value,
            customRRules: (data.value !== calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key) ? {} : prev.customRRules, // Reset the custom rules payload when we change options
            rulesFormatted: (data.value !== calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key) ? null : prev.rulesFormatted // Reset the custom rules payload when we change options
        }))
    }

    /**
     * Validate and post data
     * 
     */
    const handleSaveDetails = (notify = false) => {

        setShowNotifyConfirm(false)

        const payload = {
            ...data,
            eventId: data.id,
            personIds: selectedUsers.selected,
            endTime: moment(data.endTime).format("HH:mm"),
            startTime: moment(data.startTime).format("HH:mm"),
            endDate: moment(data.endDate).format("yyyy-MM-DD"),
            startDate: moment(data.startDate).format("yyyy-MM-DD"),
            channelIds: data.channelIds.filter(x => x !== messageBuilder.ADD_INDIVIDUALS_TAG_ID),
            notify: notify,
            notes: data.content,
        }

        const request = new PostUpdateEventRequest(payload)

        const validator = request.validator()

        if (!validator.isValid) {
            setValidationMessages(validator.messages)
            return
        }

        setValidationMessages([])
        dispatch(eventsActions.start())
        dispatch(calendarsActions.putUpdateCalendarEvent(payload))
    }

    const _handleRecipientsClick = () => {
        setShowAddUsers(true)
    }

    const _handleLabelClick = (channelId) => {
        
        // Clicked on Target All, so we can ignore things
        if (channelId === messageBuilder.TARGET_ALL_TAG_ID) return;

        // Clicked on Add Individuals
        if (channelId === messageBuilder.ADD_INDIVIDUALS_TAG_ID) {
            setShowAddUsers(true)
            return;
        }
    }

    const _handleAllTargetsClick = (_, {value}) => {
        
        // Remove all selections on recipients list
        const selections = [messageBuilder.TARGET_ALL_TAG_ID]
        
        setData(prev => ({
            ...prev,
            channelIds: selections
        }))

        // We also need to clear out any direct recipients
        setSelectedUsers(prev => ({
            ...prev,
            selected: [],
        }))
    }

    const handleAddUsers = ({ selection, options, tempOptions }) => {
        setSelectedUsers(prev => ({
            ...prev,
            selected: selection,
            tempOptions: tempOptions,
        }))

        // Once users are added, we should now add the "Add Individuals" tag on the list, if it has not already been added
        const hasIndividuals = (data.channelIds.indexOf(messageBuilder.ADD_INDIVIDUALS_TAG_ID) !== -1)

        // When no selection was made, we should remove the "Add Individuals" tag
        if (size(selection) === 0) {
            setData(prev => ({
                ...prev,
                channelIds: filter(data.channelIds, c => c !== messageBuilder.ADD_INDIVIDUALS_TAG_ID)
            }))
            return
        }

        if (!hasIndividuals) {
            setData(prev => ({
                ...prev,
                channelIds: filter(data.channelIds, c => c !== messageBuilder.TARGET_ALL_TAG_ID).concat([messageBuilder.ADD_INDIVIDUALS_TAG_ID])
            }))
        }
    }

    const handleChannelChange = ({name, value, event}) => {
        
        if (event?.currentTarget?.classList?.contains('special-actions')) {
            return;
        }

        let selections = value

        // When the current selection has our "-901" id already, we should remove it and select everything else that was already selected
        if (value.indexOf(messageBuilder.TARGET_ALL_TAG_ID) > -1) {
            selections = filter(value, c => c !== messageBuilder.TARGET_ALL_TAG_ID)
        }

        setData(prev => ({
            ...prev,
            [name]: selections
        }))

        // When the detected changes find out that "Add Individuals" is not longer in the list
        // we should also clear the selected individuals
        if (value.indexOf(messageBuilder.ADD_INDIVIDUALS_TAG_ID) === -1) {
            setSelectedUsers({
                selected: [],
                tempOptions: [],
            })
        }
    }

    const handleShowMessage = (noticeId) => {
        dispatch(push(routes.private.NOTICE_DETAIL
            .replace(":id", noticeId)
            .replace(':communityId', data.communityId)
        ))
    }
    
    const handleSendMessage = () => {
        // @TODO: Send message
    }
    
    const handleCancel = () => {
        const filters = router.location?.state?.prevUrl ?? ''
        dispatch(push(appendCommunity(data.communityId, routes.private.MANAGE_EVENTS).concat(filters)))
    }
    
    const handleShowEdit = () => {
        dispatch(push(routes.private.CALENDAR_EVENT_EDIT
            .replace(":id", data.id)
            .replace(":calendarId", data.calendarId)
            .replace(':communityId', data.communityId)
        ))
    }
    
    const handleConfirmDelete = () => {
        setConfirm({
            showModal: true,
            showConfirm: true,
            onConfirm: (d) => handleDelete(d),
            onClose: () => handleCloseConfirm(),
            message: (
                <Trans
                    i18nKey="eventDetail.modal.delete.confirm.message"
                    values={{
                        name: data.title
                    }}
                    components={{
                        p: <p />,
                        strong: <strong className='red-text' />
                    }} />
            ),
            header: t('eventDetail.modal.delete.confirm.header', {name: data.title}),
            confirmText: t('event.detail.delete.confirmText'),
        })
    }
    
    function handleShowCustomRepeat () {
        setCustomRRuleModal(prev => ({
            ...prev,
            showModal: true,
        }))
    }

    /**
     * 
     * @param {import('../../../api/requestObjects/PostEventRecurringRulesRequest').Request} _data 
     */
    const handleConfirmCustomRules = (_data, rruleObj = null) => {
        
        const startTime = moment(data.startTime).format("HH:mm")
        const startDate = moment(data.startDate).format("yyyy-MM-DD")
        const _startDate = moment(`${startDate}T${data.allDay ? '00:00:00.000Z' : startTime}`)

        const rrDayIndex = moment(data.startDate).day()
        const weekOfMonthValue = weekOfMonth(moment(data.startDate))
        const rrDay = _determineRRuleKey(_weekdayIndexToString(rrDayIndex))

        const rule = new RRule({
            freq: calendarConstants.REPEAT_FREQUENCY[_data.frequency].rruleKey,
            interval: _data.interval,
            byweekday: (_data.monthlyOn === calendarConstants.REPEAT_MONTHLY_OPTIONS.MONTHLY_ON_WEEK_DAY) ? [rrDay.nth(weekOfMonthValue)] : _data._days,
            dtstart: _startDate.isValid() ? _startDate.toDate() : null,
            until: !isNil(_data.until) ? moment(_data.until).toDate() : null,
        })


        let ruleText = rruleToText({strRule: rule.toString()})
        
        if (!isNil(rruleObj)) {
            ruleText = rruleToText({strRule: map(rruleObj).join("\n")})
        }
        
        setData(prev => ({
            ...prev,
            customRRules: _data,
            rulesFormatted: ruleText,
            // rulesFormatted: rule.toText() // @TODO: Localize to the current language "i18next.language"
        }))

        handleCloseCustomRules()
    }
    
    const handleCloseCustomRules = () => {
        setCustomRRuleModal(prev => ({
            ...prev,
            showModal: false,
        }))
    }
    
    const handleCloseConfirm = () => {
        setConfirm({
            showModal: false,
            onConfirm: () => { },
            onClose: () => { },
            message: "",
            header: "",
        })

        setShouldNotify(true)
    }

    const handleDelete = (_data) => {

        handleCloseConfirm()

        const payload = {
            eventId: _data.id,
            instance_only: false,
            instances_after: false,
            send_notification: _data.shouldNotify
        }

        if (_data.deleteMethod == 'current') {
            payload['instance_only'] = true
        }
        
        if (_data.deleteMethod == 'current_and_following') {
            payload['instance_only'] = true
            payload['instances_after'] = true
        }
        
        if (_data.deleteMethod == 'all') {
            payload['instance_only'] = false
            payload['instances_after'] = false
        }

        dispatch(eventsActions.start())
        dispatch(eventsActions.postDeleteEvent(payload))
    }

    const handleDismissModal = () => {
        setShowNotifyConfirm(false)
    }
    
    const handleNotifyClose = () => {
        handleSaveDetails(false)
    }

    const handleConfirmNotify = () => {
        handleSaveDetails(true)
    }

    const handleShowNotifyConfirm = () => {
        setShowNotifyConfirm(true)
    }

    const handleDescriptionChange = (content, jsonContent, mediaType) => {
        
        setData((prevData) => ({
            ...prevData,
            mediaType: mediaType,
            content: content,
            jsonContent: jsonContent,
        }))
    }

    return (

        <div className="page no-padding">

            <div className="crumbs-container">
                <Breadcrumb>
                    <Breadcrumb.Section>
                        <Link to={appendCommunity(navigation.communityId, routes.private.MANAGE_EVENTS)}>{t('sidebar.calendar')}</Link>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right chevron' />
                    <Breadcrumb.Section active>{`${eventManagement?.detail?.title || t('calendars.ManageCalendars.calendars.createEvent')}`}</Breadcrumb.Section>
                </Breadcrumb>
            </div>

            <ConfirmModal 
                header={confirm.header}
                open={confirm.showModal}
                onClose={confirm.onClose}
                message={confirm.message}
                onConfirm={confirm.onConfirm}
                confirmClassName="primary danger"
                withContent
                data={{
                    ...data,
                    shouldNotify,
                }}
                render={({message, data: _data}) => (
                    <div>
                        <div>{message}</div>
                        {_data.isRepeating && (
                            <Form.Field className='event-delete-methods-container'>
                                <Form.Radio onChange={handleChangeDeleteMethod} name='deleteMethod' checked={_data.deleteMethod === 'current'} value='current' label={t("eventDetail.modal.delete.onlyThisEvent")} />
                                <Form.Radio onChange={handleChangeDeleteMethod} name='deleteMethod' checked={_data.deleteMethod === 'current_and_following'} value='current_and_following' label={t("eventDetail.modal.delete.thisEventAndAllAfter")} />
                                <Form.Radio onChange={handleChangeDeleteMethod} name='deleteMethod' checked={_data.deleteMethod === 'all'} value='all' label={t("eventDetail.modal.delete.allEvents")} />
                            </Form.Field>
                        )}
                        <div className='mg-top-15'>
                            <Checkbox
                                checked={_data.shouldNotify}
                                onChange={() => setShouldNotify(!_data.shouldNotify)}
                                label={t('calendar.events.notifyUsers')} />
                        </div>
                    </div>
                )} />
            
            {customRRuleModal.showModal && (
                <CustomRRulesModal 
                    message={null}
                    open={customRRuleModal.showModal}
                    onClose={customRRuleModal.onClose}
                    onDone={customRRuleModal.onConfirm}
                    selected={data.customRRules}
                    startDate={data.startDate} />
            )}
            
            <ConfirmModal 
                header={t('calendars.events.notifyUsers.header')}
                open={showNotifyConfirm}
                onClose={handleNotifyClose}
                message={t('calendars.events.notifyUsers.message')}
                onConfirm={handleConfirmNotify}
                confirmText={t('notice.details.yes')}
                cancelText={t('notice.details.no')}
                dismissible={false}
                showCancel={false}
                showConfirm={true}
                overrideConfirm={({ message, errors, data }) => (
                    <Fragment>
                        <Button className='secondary' onClick={handleDismissModal}>
                        {t('notice.details.back-to-editing')}
                        </Button>
                        <Button className='secondary' onClick={handleNotifyClose}>
                        {t('notice.details.no')}
                        </Button>
                        <Button className='primary' onClick={handleConfirmNotify}>
                        {t('notice.details.yes')}
                        </Button>
                    </Fragment>
                )}
                />

            {/* Add Persons Modal */}
            {showAddUsers && (
                <AddUsersModal
                    isOpen={showAddUsers}
                    tags={[]}
                    data={{
                        communityId: data.communityId,
                        channels: personFilterChannels ?? []
                    }}
                    onClose={() => setShowAddUsers(false)}
                    title={t("modal.addUsers.title")}
                    description={t("modal.addUsers.description")}
                    currentSelection={selectedUsers.selected}
                    tempOptions={selectedUsers.tempOptions ?? []}
                    onAddUsers={handleAddUsers}
                    fetchUsers={eventService.getTargetRecipients}/>
            )}
                
            <PageSection
                classNames={['relative', 'event-detail']}
                content={
                    <Form error={!isEmpty(calendarManagement.errors) || !isEmpty(eventManagement.errors) || !isEmpty(validationMessages)} success={!isEmpty(calendarManagement.messages) || !isEmpty(eventManagement.messages)}>
                        
                        {!isEmpty(calendarManagement.errors) && (calendarManagement.errors || calendarManagement.messages) && (
                            <FormMessages
                                onClose={() => dispatch(calendarsActions.clearMessages())}
                                errors={calendarManagement.errors}
                                isServerError={calendarManagement.serverError}
                                messages={calendarManagement.messages} />
                        )}
                        
                        {isEmpty(calendarManagement.errors) && (eventManagement.errors || eventManagement.messages) && (
                            <FormMessages
                                onClose={() => dispatch(eventsActions.clearMessages())}
                                errors={eventManagement.errors}
                                isServerError={eventManagement.serverError}
                                messages={eventManagement.messages} />
                        )}

                        <Fragment>
                            
                            <AnimLoader loading={eventManagement.loading} />

                            <Grid divided>
                                <Grid.Row columns={2} className='collapse-on-small-down'>
                                    <Grid.Column></Grid.Column>
                                    <Grid.Column floated='right' className='z-depth-0'>
                                        <Button 
                                            size="small"
                                            floated='right'
                                            onClick={handleConfirmDelete} 
                                            disabled={eventManagement?.detail?.isCustomRepeat ?? false}
                                            className='secondary danger'>
                                            {t('calendarDetail.actions.delete')}
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={2} className='collapse-on-small-down'>
                                    <Grid.Column>
                                        <CalendarDetailContents
                                            t={t}
                                            data={data}
                                            channels={null}
                                            minTime={minTime}
                                            minStartTime={minStartTime}
                                            repeatOptions={repeatOptions}
                                            selectedUsers={selectedUsers}
                                            handleInputChange={handleInputChange}
                                            _handleLabelClick={_handleLabelClick}
                                            handleSwitchChange={handleSwitchChange}
                                            handleRepeatChange={handleRepeatChange}
                                            validationMessages={validationMessages}
                                            handleChannelChange={handleChannelChange}
                                            handleDateTimeChange={handleDateTimeChange}
                                            handleLocationChange={handleLocationChange}
                                            handleShowCustomRepeat={handleShowCustomRepeat}
                                            _handleRecipientsClick={_handleRecipientsClick}
                                            handleDescriptionChange={handleDescriptionChange} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Grid.Row>
                                            <Form.Field>
                                                <Role
                                                    role={{
                                                        id: "channelIds",
                                                        name: t('calendar.event.detail.audience.label')
                                                    }}
                                                    virtualised
                                                    placeholder={t('calendar.input.channels.placeholder')}
                                                    selected={data.channelIds}
                                                    onChange={handleChannelChange}
                                                    options={[
                                                        navigation?.community?.can_target_everyone ? {
                                                            onClick: _handleAllTargetsClick,
                                                            key: messageBuilder.TARGET_ALL_TAG_ID, 
                                                            value: messageBuilder.TARGET_ALL_TAG_ID, 
                                                            text: t("messageBuilder.recipients.targetAll"),
                                                            className: "special-actions",
                                                        } : false,
                                                        {
                                                            onClick: _handleRecipientsClick,
                                                            className: "special-actions",
                                                            key: messageBuilder.ADD_INDIVIDUALS_TAG_ID, 
                                                            value: messageBuilder.ADD_INDIVIDUALS_TAG_ID, 
                                                            text: t("modal.addUsers.button.addIndividuals") + (selectedUsers.selected.length > 0 ? `  (${selectedUsers.selected.length})` : "")
                                                        }
                                                    ].concat(eventManagement?.filters?.channels ?? []).filter(Boolean)}
                                                    error={getError("channelIds", validationMessages).message}
                                                    onLabelClick={(_, data) => _handleLabelClick(data.value)} />
                                            </Form.Field>    
                                        </Grid.Row>
                                        {!isEmpty(eventManagement?.detail?.notices) ? (
                                            <Grid.Row columns='equal' className='mg-top-15'>
                                                <Grid.Column className='no-select'>
                                                    <label className='label'>{t('calendar.event.edit.notices')}</label>
                                                    <div className="notices-container">
                                                        <ListView 
                                                            classNames='mg-top-1'
                                                            items={map(eventManagement.detail.notices, c => {
                                                                return {
                                                                    image: navigation.community.image,
                                                                    title: c.title,
                                                                    createdAt: moment(c.created_at),
                                                                    pinItem: false,
                                                                    important: false,
                                                                    description: eventManagement.detail.notes ?? c.title,
                                                                    hasText: true
                                                                }
                                                            })}
                                                            contents={eventMessageSample}/>
                                                    </div>
                                                </Grid.Column>
                                            </Grid.Row>
                                        ) : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    </Form>
                }
                actions={eventManagement.loading ? null : {
                    // custom: eventManagement?.detail?.isCustomRepeat ? null : {
                    //     floated: 'left',
                    //     caption: t('calendarDetail.actions.delete'),
                    //     onClick: handleConfirmDelete,
                    //     className: 'secondary danger',
                    //     disabled: eventManagement?.detail?.isCustomRepeat
                    // },
                    cancel: {
                        caption: t('general.cancel'),
                        onClick: handleCancel,
                        className: 'secondary'
                    },
                    primary: {
                        caption: t('calendarDetail.actions.saveChanges'),
                        onClick: handleShowNotifyConfirm
                    },
                }}
            />
        </div>
    )
}
