import React, { Fragment, useEffect, useState } from 'react'
import { createEventId } from '../../constants/initial-data'
import { Button, Checkbox, Dropdown, Form, Grid, Input, Label, Message, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import SearchLocationInput from '../../utils/SearchLocationInput'
import CustomDateInput from '../partials/CustomDateInput'
import ReactDatePicker from 'react-datepicker'
import { getError, weekOfMonth } from '../../utils/helper'
import { _determineRRuleKey, _weekdayIndexToString, calendarConstants, messageBuilder } from '../../constants'
import { filter, isEmpty, isNil, isNull, map, size } from 'lodash'
import { PostCreateEventRequest } from '../../api/requestObjects'
import ErrorLabel from '../partials/ErrorLabel'
import Role from '../partials/Role'
import AddUsersModal from '../partials/AddUsersModal'
import { eventService } from '../../api'
import { calendarsActions } from '../../actions/calendarsActions'
import { useSelector } from 'react-redux'
import FormMessages from '../partials/FormMessages'
import AnimLoader from '../partials/AnimLoader'
import CustomRRulesModal from '../partials/CustomRRulesModal'
import { RRule, rrulestr } from 'rrule'
import { rruleToText } from '../../i18n/RRuleToText'
import { usersActions } from '../../actions/usersActions'
import WarnTitleLimitReached from '../partials/WarnTitleLimitReached'
import EventNotesRichTextEditor from '../message-builder/morphedTemplates/EventNotesRichTextEditor'
import { v4 } from 'uuid'

const CreateEventModal = (props) => {
    
    const {t} = useTranslation()

    const {
        open,
        onClose,
        dispatch,
        communityId,
        channels = [],
        data: item,
    } = props

    const calendarManagement = useSelector(state => state.calendars)
    const personFilterChannels = useSelector(state => state.users?.member?.filters?.channels)

    const options = map(calendarConstants.EVENT_REPEAT_OPTIONS, r => {
        return {
            key: r.key, 
            value: r.key, 
            text: r.description,
            onClick: (r.key === calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key) ? handleShowCustomRepeat : null
        }
    })

    const [repeatOptions, setOptions] = useState(options)
    const [validationMessages, setValidationMessages] = useState([])
    
    const startDate = item.start ? moment(item.start) : moment()
    const endDate = item.end ? moment(item.end) : moment()

    const defaultContentState = {
        "blocks": [
            {
                "key": "637gr",
                "text": "",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
            }
        ],
        "entityMap": {}
    }

    const [data, setData] = useState({
        title: "",
        notes: "",
        remind: false,
        repeat: repeatOptions[0].key,
        allDay: false,
        location: "",
        personIds: [],
        startDate: new Date(startDate.toISOString()),
        endDate: new Date(endDate.toISOString()),
        startTime: new Date(startDate.toISOString()),
        endTime: new Date(endDate.toISOString()),
        channelIds: [],
        remindMinutes: 0,
        communityId: communityId,
        customRRules: {},
        rulesFormatted: null,
        content: "",
        mediaType: messageBuilder.MARKUP.plain,
        jsonContent: defaultContentState,
        resetKey: v4(),
    })

    const [minTime, setMinTime] = useState(data.startTime)
    const [minStartTime, setMinStartTime] = useState(new Date())

    const [showAddUsers, setShowAddUsers] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState({
        selected: [],
        users: [],
        tempOptions: [],
    })

    const [customRRuleModal, setCustomRRuleModal] = useState({
        showModal: false,
        onConfirm: (d) => handleConfirmCustomRules(d),
        onClose: () => handleCloseCustomRules(),
        header: t('event.create.repeat.custom.modal.header'),
    })

     // On Cleanup, we can remove any error messages that were created by this window
     useEffect(() => {
        // Load member filters to use later for event creation
        dispatch(usersActions.startFilters())
        dispatch(
            usersActions.fetchMemberFilters({
                communityId: communityId
            })
        )

        return () => {
            dispatch(calendarsActions.clearMessages())
        }
    }, [])
    
    // When we wish to remind or not, we can update the remind minutes
     useEffect(() => {
        setData(prev => ({ 
            ...prev, 
            remindMinutes: prev.remind ? calendarConstants.DEFAULT_REMIND_MINUTES : 0
        }))
    }, [data.remind])
    
    // When we wish to remind or not, we can update the remind minutes
     useEffect(() => {
        
        const _day = moment(data.startDate).format("dddd")
        const _options = map(options, r => {return {...r, text: r.text.replace("{DAY}", _day)}})
        
        setOptions(_options)

        const startDate = moment(data.startDate).toDate()
        const isSameDay = moment(startDate).isSame(moment(data.endDate), "day")
        const _minTime = isSameDay ? moment(startDate).toDate() : moment(startDate).startOf("day").toDate()
        const _minStartTime = moment(startDate).isSame(moment(), "day") ? moment().toDate() : moment(startDate).startOf("day").toDate()

        setMinTime(_minTime)
        setMinStartTime(_minStartTime)

        const isDateAfter = moment(data.startDate).isSameOrAfter(moment(data.endDate))
        const isTimeAfter = moment(data.startTime).isSameOrAfter(moment(data.endTime))

        if (isDateAfter || isTimeAfter) {
            setData((prevData) => ({
                ...prevData,
                endDate: moment(data.startDate).add(1, 'hour').toDate(),
                endTime: moment(data.startTime).add(1, 'hour').toDate(),
            }))
        }

        // The start date of an event cannot be higher than the endsOnDate of a custom RRule
        if (!isEmpty(data.customRRules)) {
            const exceedsRRuleDate = moment(data.startDate).isSameOrAfter(moment(data.customRRules.until))
            
            // We will only reset inf
            if (exceedsRRuleDate) {
                setData((prevData) => ({
                    ...prevData,
                    customRRules: {},
                    rulesFormatted: null
                }))
            }
        }

    }, [data.startDate, data.startTime])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }

    const handleDateTimeChange = ({name, date}) => {

        setData((prevData) => ({
            ...prevData,
            [name]: date,
        }))
    }

    const handleChange = (e, {value}) => {
        setData({
            ...data,
            type: value
        })
    }
    
    const handleSwitchChange = ({name}) => {
        setData({...data, [name]: !data[name]})
    }

    const handleLocationChange = (placeDetails) => {
        
        const { address, location, original } = placeDetails

        setData((prevData) => ({
            ...prevData,
            location: address,
            previewText: address,
            latitude: location.lat,
            longitude: location.lng,
            originalPlace: {},
            // originalPlace: original,
        }))
    }

    const handleRepeatChange = (e, data) => {
        setData(prev => ({
            ...prev,
            repeat: data.value,
            customRRules: (data.value !== calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key) ? {} : prev.customRRules, // Reset the custom rules payload when we change options
            rulesFormatted: (data.value !== calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key) ? null : prev.rulesFormatted // Reset the custom rules payload when we change options
        }))
    }

    function handleShowCustomRepeat () {
        setCustomRRuleModal(prev => ({
            ...prev,
            showModal: true,
        }))
    }

    /**
     * 
     * @param {import('../../api/requestObjects/PostEventRecurringRulesRequest').Request} _data 
     */
    const handleConfirmCustomRules = (_data) => {
        
        const startTime = moment(data.startTime).format("HH:mm")
        const startDate = moment(data.startDate).format("yyyy-MM-DD")
        const _startDate = moment(`${startDate}T${data.allDay ? '00:00:00.000Z' : startTime}`)

        const rrDayIndex = moment(data.startDate).day()
        const weekOfMonthValue = weekOfMonth(moment(data.startDate))
        const rrDay = _determineRRuleKey(_weekdayIndexToString(rrDayIndex))

        const rule = new RRule({
            freq: calendarConstants.REPEAT_FREQUENCY[_data.frequency].rruleKey,
            interval: _data.interval,
            byweekday: (_data.monthlyOn === calendarConstants.REPEAT_MONTHLY_OPTIONS.MONTHLY_ON_WEEK_DAY) ? [rrDay.nth(weekOfMonthValue)] : _data._days,
            dtstart: _startDate.isValid() ? _startDate.toDate() : null,
            until: !isNil(_data.until) ? moment(_data.until).toDate() : null,
            count: _data.afterOccurrences
        })

        setData(prev => ({
            ...prev,
            customRRules: _data,
            rulesFormatted: rruleToText({strRule: rule.toString()})
            // rulesFormatted: rule.toText() // @TODO: Localize to the current language "i18next.language" 
        }))

        handleCloseCustomRules()
    }
    
    const handleCloseCustomRules = () => {
        setCustomRRuleModal(prev => ({
            ...prev,
            showModal: false,
        }))
    }

    /**
     * Validate and post data
     * 
     */
    const handleSaveDetails = ({sendNotification = false}) => {

        const payload = {
            ...data,
            sendNotification,
            personIds: selectedUsers.selected,
            startDate: moment(data.startDate).format("yyyy-MM-DD"),
            startTime: moment(data.startTime).format("HH:mm"),
            endDate: moment(data.endDate).format("yyyy-MM-DD"),
            endTime: moment(data.endTime).format("HH:mm"),
            channelIds: data.channelIds.filter(x => x !== messageBuilder.ADD_INDIVIDUALS_TAG_ID),
            notes: data.content
        }

        const request = new PostCreateEventRequest(payload)

        const validator = request.validator()

        if (!validator.isValid) {
            setValidationMessages(validator.messages)
            return
        }

        setValidationMessages([])
        dispatch(calendarsActions.start())
        dispatch(calendarsActions.postCreateCalendarEvent(payload))
    }

    const _handleRecipientsClick = (_, {value}) => {
        setShowAddUsers(true)
    }

    const _handleAllTargetsClick = (_, {value}) => {
        
        // Remove all selections on recipients list
        const selections = [messageBuilder.TARGET_ALL_TAG_ID]
        
        setData(prev => ({
            ...prev,
            channelIds: selections
        }))

        // We also need to clear out any direct recipients
        setSelectedUsers({
            selected: []
        })
    }

    const _handleLabelClick = (channelId) => {
        
        // Clicked on Target All, so we can ignore things
        if (channelId === messageBuilder.TARGET_ALL_TAG_ID) return;

        // Clicked on Add Individuals
        if (channelId === messageBuilder.ADD_INDIVIDUALS_TAG_ID) {
            setShowAddUsers(true)
            return;
        }
    }

    const handleAddUsers = ({ selection, options, tempOptions }) => {
        setSelectedUsers(prev => ({
            ...prev,
            selected: selection,
            tempOptions: tempOptions,
        }))

        // Once users are added, we should now add the "Add Individuals" tag on the list, if it has not already been added
        const hasIndividuals = (data.channelIds.indexOf(messageBuilder.ADD_INDIVIDUALS_TAG_ID) !== -1)

        // When no selection was made, we should remove the "Add Individuals" tag
        if (size(selection) === 0) {
            setData(prev => ({
                ...prev,
                channelIds: filter(data.channelIds, c => c !== messageBuilder.ADD_INDIVIDUALS_TAG_ID)
            }))
            return
        }

        if (!hasIndividuals) {
            setData(prev => ({
                ...prev,
                channelIds: data.channelIds.concat([messageBuilder.ADD_INDIVIDUALS_TAG_ID])
            }))
        }
    }

    const handleChannelChange = ({name, value, event}) => {
        
        if (event?.currentTarget?.classList?.contains('special-actions')) {
            return;
        }

        let selections = value

        // When the current selection has our "-901" id already, we should remove it and select everything else that was already selected
        if (value.indexOf(messageBuilder.TARGET_ALL_TAG_ID) > -1) {
            selections = filter(value, c => c !== messageBuilder.TARGET_ALL_TAG_ID)
        }

        setData(prev => ({
            ...prev,
            [name]: selections
        }))

        // When the detected changes find out that "Add Individuals" is not longer in the list
        // we should also clear the selected individuals
        if (value.indexOf(messageBuilder.ADD_INDIVIDUALS_TAG_ID) === -1) {
            setSelectedUsers({
                selected: [],
                tempOptions: [],
            })
        }
    }

    const handleDescriptionChange = (content, jsonContent, mediaType) => {
        
        setData((prevData) => ({
            ...prevData,
            mediaType: mediaType,
            content: content,
            jsonContent: jsonContent,
        }))
    }

    return (
        <Fragment>
            <Modal
                size="tiny"
                open={ open }
                onClose={ onClose }
                closeOnDimmerClick={false}
                className='create-event-modal'>
            {/* <Modal.Header className="br-2 primary-color white-text">{ data.title || t('calendar.Calendar.createEvent') }</Modal.Header> */}
            <Modal.Content>
                <Form error={(validationMessages.length > 0) || !isEmpty(calendarManagement.errors)}>
                    
                    {(calendarManagement.errors || calendarManagement.messages) && (
                        <FormMessages
                            onClose={() => dispatch(calendarsActions.clearMessages())}
                            errors={calendarManagement.errors}
                            isServerError={calendarManagement.serverError}
                            messages={calendarManagement.messages} />
                    )}

                    <AnimLoader loading={calendarManagement.loading} />

                    <CalendarDetailContents
                        t={t}
                        data={data}
                        channels={channels}
                        minTime={minTime}
                        minStartTime={minStartTime}
                        repeatOptions={repeatOptions}
                        selectedUsers={selectedUsers}
                        handleInputChange={handleInputChange}
                        _handleLabelClick={_handleLabelClick}
                        handleSwitchChange={handleSwitchChange}
                        handleRepeatChange={handleRepeatChange}
                        validationMessages={validationMessages}
                        handleChannelChange={handleChannelChange}
                        handleDateTimeChange={handleDateTimeChange}
                        handleLocationChange={handleLocationChange}
                        _handleRecipientsClick={_handleRecipientsClick}
                        _handleAllTargetsClick={_handleAllTargetsClick}
                        handleDescriptionChange={handleDescriptionChange} />
                </Form>
            </Modal.Content>
            <Modal.Actions className="no-border">
                <Button className="tertiary" disabled={calendarManagement.loading} onClick={onClose}>
                    { t("modal.cancelText") }
                </Button>
                <Dropdown
                    text={t("calendar.create.event.modal.action.createEvent")}
                    button
                    floating
                    disabled={calendarManagement.loading}
                    className='right labeled icon primary small send-message-button'>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            icon="save"
                            text={t("calendar.create.event.modal.action.createEvent")}
                            onClick={() => handleSaveDetails({sendNotification: false})} />
                        <Dropdown.Item
                            icon="paper plane"
                            text={t("calendar.create.event.modal.action.createAndSendEvent")}
                            onClick={() => handleSaveDetails({sendNotification: true})} />
                    </Dropdown.Menu>
                </Dropdown>
            </Modal.Actions>
        </Modal>

        {/* Add Persons Modal */}
        <AddUsersModal
            isOpen={showAddUsers}
            tags={[]}
            data={{
                communityId,
                channels: personFilterChannels ?? []
            }}
            onClose={() => setShowAddUsers(false)}
            title={t("modal.addUsers.title")}
            description={t("modal.addUsers.description")}
            currentSelection={selectedUsers.selected}
            tempOptions={selectedUsers.tempOptions ?? []}
            onAddUsers={handleAddUsers}
            fetchUsers={eventService.getTargetRecipients}/>

        {customRRuleModal.showModal && (
            <CustomRRulesModal 
                message={null}
                open={customRRuleModal.showModal}
                onClose={customRRuleModal.onClose}
                onDone={customRRuleModal.onConfirm}
                selected={data.customRRules}
                startDate={data.startDate} />
        )}
    </Fragment>
    )
}

export const CalendarDetailContents = (props) => {
    
    const {
        t,
        data,
        channels,
        minTime,
        minStartTime,
        repeatOptions,
        selectedUsers,
        handleInputChange,
        _handleLabelClick,
        handleSwitchChange,
        handleRepeatChange,
        validationMessages,
        handleLocationChange,
        handleChannelChange,
        handleDateTimeChange,
        handleShowCustomRepeat,
        _handleRecipientsClick,
        _handleAllTargetsClick,
        handleDescriptionChange,
    } = props
    const navigation = useSelector(state => state.navigation)
    const maxTime = moment().endOf('day').toDate()

    const handleTitleChange = (e, data) => {
        const value = e.target.value
        if (value.length > messageBuilder.MAX_EVENT_TITLE_LENGTH) {
            // When we are getting the value from a paste event we are going to truncate the paste if it is too long
            if (e?.nativeEvent?.inputType === 'insertFromPaste') {
                handleInputChange({
                    target: { 
                        name: 'title',
                        type: 'text',
                        value: data.value.substring(0, messageBuilder.MAX_EVENT_TITLE_LENGTH)
                    }
                })
            }
            return
        }

        handleInputChange(e)
    }

    const handleContentChange = (value, jsonContent, markupType) => {
        handleDescriptionChange(value, jsonContent, markupType)
	}

    return (
        <Grid className='collapse-on-small-down'>
            <Grid.Row columns="equal">
                <Grid.Column className='event-title-container'>
                    <Form.Field
                        required
                        error={!getError("title", validationMessages).isValid || data.title.length > messageBuilder.MAX_EVENT_TITLE_LENGTH}
                        className="input-container theme-color">
                        <label className='label'>{t('messageBuilder.morphedTemplates.EventMorphed.eventTitle')}</label>
                        <Input 
                            name="title"
                            value={data.title} 
                            onChange={handleTitleChange}
                            autoComplete="off" 
                            placeholder={t('messageBuilder.morphedTemplates.EventMorphed.eventTitle')} />
                        
                        <WarnTitleLimitReached
                            limit={messageBuilder.MAX_EVENT_TITLE_LENGTH} 
                            value={data.title}
                            showWarning={false}
                            message={t('messageBuilder.morphedTemplates.EventMorphed.maxChars', {
                                limit: messageBuilder.MAX_EVENT_TITLE_LENGTH
                            })} />

                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column width={5} verticalAlign='middle'>
                    <Checkbox
                        checked={data.allDay}
                        label={t('calendar.events.create.allDay')}
                        onChange={e => handleSwitchChange({ name: "allDay" })} />
                </Grid.Column>
            </Grid.Row>
            {data.rulesFormatted && (
                <Grid.Row columns="equal">
                    <Grid.Column verticalAlign='middle'>
                        <Form.Field
                            required
                            error={!getError("repeatRules", validationMessages).isValid}>
                            <Message>
                                {data.rulesFormatted}
                            </Message>
                            <ErrorLabel className='ui pointing above prompt label w-fit-content' error={getError("repeatRules", validationMessages)}  />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            )}
            <Grid.Row columns="equal">
                <Grid.Column>
                    <Form.Field
                        required
                        error={!getError("startDate", validationMessages).isValid}
                        className="input-container theme-color">
                            <label className='label'>{t('calendar.create.starts')}</label>
                            <ReactDatePicker 
                                className="date-picker"
                                selected={data.startDate}
                                onChange={date => handleDateTimeChange({name: "startDate", date: date})}
                                dateFormat="yyyy/MM/dd"
                                customInput={<CustomDateInput icon="calendar" />} />
                        <ErrorLabel className='ui pointing above prompt label w-fit-content' error={getError("startDate", validationMessages)}  />
                    </Form.Field>
                </Grid.Column>
                {!data.allDay ? (
                    <Fragment>
                        <Grid.Column className='no-margin no-padding' width={1} verticalAlign='bottom' textAlign='center'>
                            <p className='mg-bot-7'><span>{t("general.separator.at")}</span></p>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field
                                className="input-container theme-color">
                                <label className='label'>&nbsp;</label>
                                <ReactDatePicker 
                                    popperClassName="date-picker time-only"
                                    selected={data.startTime}
                                    onChange={date => handleDateTimeChange({name: "startTime", date: date})}
                                    showTimeSelect
                                    dateFormat="hh:mm a"
                                    timeIntervals={15}
                                    // minTime={minStartTime}
                                    // maxTime={maxTime}
                                    customInput={<CustomDateInput icon="time" />} />
                            </Form.Field>
                        </Grid.Column>
                    </Fragment>
                ) : null}
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column>
                    <Form.Field
                        required
                        className="input-container theme-color">
                        <label className='label'>{t('calendar.create.ends')}</label>
                        <ReactDatePicker 
                            className="date-picker"
                            selected={data.endDate}
                            onChange={date => handleDateTimeChange({name: "endDate", date: date})}
                            dateFormat="yyyy/MM/dd"
                            minDate={data.startDate}
                            customInput={<CustomDateInput icon="calendar" />} />
                    </Form.Field>
                </Grid.Column>
                {!data.allDay ? (
                    <Fragment>
                        <Grid.Column className='no-margin no-padding' width={1} verticalAlign='bottom' textAlign='center'>
                            <p className='mg-bot-7'><span>{t("general.separator.at")}</span></p>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Form.Field className="input-container theme-color">
                                <label className='label'>&nbsp;</label>
                                <ReactDatePicker 
                                    selected={data.endTime}
                                    onChange={date => handleDateTimeChange({name: "endTime", date: date})}
                                    showTimeSelect
                                    dateFormat="hh:mm a"
                                    timeIntervals={15}
                                    maxTime={maxTime}
                                    minTime={data.startTime}
                                    popperClassName="date-picker time-only"
                                    customInput={<CustomDateInput icon="time" />} />
                            </Form.Field>
                        </Grid.Column>
                    </Fragment>
                ) : null }
            </Grid.Row>
            <Grid.Row columns="equal">
                <Grid.Column>
                    <Form.Field
                        error={!getError("repeat", validationMessages).isValid}
                        className="input-container theme-color">
                        <label className='label'>{t('event.input.repeat.placeholder')}</label>
                        <Dropdown
                            fluid
                            selection
                            onChange={handleRepeatChange}
                            placeholder={t('event.input.repeat.placeholder')}
                            value={data.repeat}
                            options={repeatOptions} />
                            <ErrorLabel className='ui pointing above prompt label w-fit-content' error={getError("repeat", validationMessages)}  />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <SearchLocationInput
                        title={t("calendar.create.addLocation")}
                        placeholder={t('messageBuilder.morphedTemplates.EventMorphed.searchLocation')}
                        name="location"
                        defaultValue={data.location}
                        onChange={handleLocationChange} />
                </Grid.Column>
            </Grid.Row>
            
            {!isNull(channels) ? (
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <Form.Field>
                            <Role
                                role={{
                                    id: "channelIds",
                                    name: t('calendar.create.audience.label')
                                }}
                                virtualised
                                placeholder={t('calendar.input.channels.placeholder')}
                                selected={data.channelIds}
                                onChange={handleChannelChange}
                                options={[
                                    navigation?.community?.can_target_everyone ? { 
                                        onClick: _handleAllTargetsClick,
                                        key: messageBuilder.TARGET_ALL_TAG_ID, 
                                        value: messageBuilder.TARGET_ALL_TAG_ID, 
                                        text: t("messageBuilder.recipients.targetAll"),
                                        className: "special-actions",
                                    } : false,
                                    {
                                        onClick: _handleRecipientsClick,
                                        className: "special-actions",
                                        key: messageBuilder.ADD_INDIVIDUALS_TAG_ID, 
                                        value: messageBuilder.ADD_INDIVIDUALS_TAG_ID, 
                                        text: t("modal.addUsers.button.addIndividuals") + (selectedUsers.selected.length > 0 ? `  (${selectedUsers.selected.length})` : "")
                                    }
                                ].concat(channels).filter(Boolean)}
                                error={getError("channelIds", validationMessages).message}
                                onLabelClick={(_, data) => _handleLabelClick(data.value)} />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            ): null}

            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Form.Field className='transformed-content-container event-description'>
                        <EventNotesRichTextEditor
                            label={t("calendar.create.notes.placeholder")}
                            key={data.resetKey}
                            mediaType={data.mediaType}
                            content={data.content}
                            jsonContent={data.jsonContent}
                            placeholder={t("calendar.create.notes.placeholder")}
                            onChange={handleContentChange} />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <Checkbox
                        checked={data.remind}
                        label={t('calendar.events.create.reminder')}
                        onChange={e => handleSwitchChange({ name: "remind" })} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default CreateEventModal