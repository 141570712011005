import React, { useState, useEffect } from 'react'
import MasterLayout from "./MasterLayout"
import MasterAuthLayout from "./MasterAuthLayout"

import '../styles/general.scss'
import { useSelector } from 'react-redux'
import DataInitialLayout from '../views/DataInitialLayout'
import SuspenseFallback from '../views/SuspenseFallback'
import DefaultsContext from '../context/DefaultsContext'
import { navigationActions } from '../actions/navigationActions'
import { isEmpty, omit } from 'lodash'
import { appendCommunity, isLocaleRtl } from '../utils/helper'
import { useTranslation } from 'react-i18next'
import { Route, Switch, matchPath } from 'react-router'
import routes from '../routes/route-constants'
import LoginRedirect from '../views/LoginRedirect'
import Config from '../config'
 

const ThemeSemanticUI = React.lazy(() => import('./ThemeSemanticUI'))
const ThemeRTLSemanticUI = React.lazy(() => import('./ThemeRTLSemanticUI'))

function MainLayout() {

    const auth = useSelector(state => state.auth)
    const router = useSelector(state => state.router)
    const navigation = useSelector(state => state.navigation)
    const ssoUser = useSelector(state => state.auth.ssoUser)
    const [defaults, setDefaults] = useState()
    const { i18n } = useTranslation()

    const [isRTL] = useState(isLocaleRtl(i18n.language).valid)
    const [localeDir] = useState(isLocaleRtl(i18n.language).dir)

    const updateURLBar = ({payload, homeUrl}) => {
    
        const perPage = payload.perPage ? payload.perPage.value : null
        
        navigationActions.saveToHashRouter({
            url: appendCommunity(navigation.communityId, homeUrl),
            data: {
                ...payload,
                perPage,
            }
        })
    }

    const initDefaults = (_defaults) => {

        setDefaults(_defaults)
    }

    useEffect(() => {
        // Language presets
        document.documentElement.dir = localeDir
        document.documentElement.lang = i18n.language
        
        if (!Config.isProd) {
            const title = 'Communicator '
            
            if (Config.isLocal) {
                document.title = title.concat('(Local)')
            } else if (Config.isStaging) {
                document.title = title.concat('(Staging)')
            }
        }
    }, [])
    
    useEffect(() => {

        if (!defaults) return;

        updateURLBar({
            payload: omit(defaults, ['homeUrl']),
            homeUrl: defaults.homeUrl
        })
    }, [defaults])
    

    // If we are coming from signin, we should show the full page
    const match = matchPath(router.location.pathname, {
        exact: true,
        path: [routes.public.LOGIN_REDIRECT]
    })
    
    if (match) {
        
        return (
            <Switch>
                <Route
                    path={routes.public.LOGIN_REDIRECT}
                    exact={true}
                    component={LoginRedirect} />
            </Switch>
        )
    }

    // Initially we begin by loading, and while we load, we need to determine what view to render
    if (auth.initialising)  {
        return <DataInitialLayout />
    }

    if (!isEmpty(ssoUser)) {
        return (
            <DefaultsContext.Provider 
                value={{
                    cacheToURL: updateURLBar,
                    defaults: defaults,
                    initDefaults: initDefaults
                }}>
                
                <React.Suspense fallback={<SuspenseFallback />}>
                    {isRTL && <ThemeRTLSemanticUI />}
                    {!isRTL && <ThemeSemanticUI />}
                </React.Suspense>
                <MasterAuthLayout/>
            </DefaultsContext.Provider>
        )
    }

    return <MasterLayout />
}

export default MainLayout