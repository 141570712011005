import React from 'react'
import Routes from '../routes/Routes'

import { version } from "../../package.json"
import { Fragment } from 'react'

function MasterLayout() {
  
  return (
    <Fragment>
      <h1 className="version-container">
        <span className='ui label primary white-text'>{`V ${version}`}</span>
      </h1>
      <div 
        className={`ui middle aligned center aligned grid no-auth`}>
        <Routes />
      </div>
    </Fragment>
  )
}

export default MasterLayout
