import React from 'react'
import { List } from 'semantic-ui-react'
import { ListItem } from '.'

export const ListView = ({items, contents, render, classNames = ''}) => {
    return (
        <div className={`list-view ${classNames}`}>
            <List className="preview-list">
                {items.map((item, index) => (
                    <ListItem 
                        key={index} 
                        item={item}
                        contents={contents} />
                ))}
            </List>
        </div>
    )
}


export default ListView
