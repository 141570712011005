import i18next from "i18next"
import { omit, pick, size } from "lodash"
import moment from "moment"
import { v4 } from "uuid"
import { 
    PostSaveTemplateRequest, 
    GetPublishBreakdownRequest, 
    PostCreateNoticeRequest,
    GetNoticeFiltersRequest, 
    ListNoticesRequest,
    PostResendMessageRequest,
    ListNoticeRecipientsRequest, 
    ListTargetPersonsRequest,
    PostRemindNoticeRequest,
    GetRemindBreakdownRequest,
    PostPublishBreakdownRequest,
    ListTargetPersonsByFiltersRequest,
    GetNoticeRecipientsByFiltersRequest,
    ListAttachableMembersRequest
} from "../api/requestObjects"
import { messageBuilder, templateIcons } from "../constants"
import ApiService from "./ApiService"
import { EXPORT_BY_FILTERS, EXPORT_BY_SELECTION } from "../api/requestObjects/ExportMembersRequest"

class NoticeApiService extends ApiService {
    
    /**
     * Fetch specific notice
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNotice = (params) => {

        const { GET_NOTICE } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_NOTICE, 
            data: params.noticeId
        }))
    }
    
    /**
     * Fetch specific notice recipient timeline
     * 
     * @param {JSON} params
     * @return {Promise}
     */
     getNoticeRecipientTimeline = (params) => {

        const { GET_NOTICE_RECIPIENT_TIMELINE } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_RECIPIENT_TIMELINE, 
            data: params.userId,
        }))
    }

    /**
     * Get the website meta data for a given url
     * 
     * @param {JSON} payload
     * @return {Object}
     */
     getWebsiteMeta = (payload) => {

        const { GET_WEBSITE_META } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: GET_WEBSITE_META, 
        }), payload)
    }
    
    /**
     * Fetch manual payments from API
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNoticeManualPayments = (params) => {
        const { GET_RECIPIENT_MANUAL_PAYMENTS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_RECIPIENT_MANUAL_PAYMENTS, 
            data: params.userId
        }))
    }

    /**
     * Post create manual payments
     * 
     * @param {JSON} params 
     * @returns {Promise}
     */
    postCreateNoticeManualPayments = (payload) => {
        const { GET_RECIPIENT_MANUAL_PAYMENTS: POST_RECIPIENT_MANUAL_PAYMENTS } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_RECIPIENT_MANUAL_PAYMENTS,
            data: payload.userId
        }), omit(payload, ['userId']))
    }

    /**
     * Fetch specific notice analytics
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNoticeAnalyticsGraphs = (params) => {

        const { GET_NOTICE_GRAPHS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_GRAPHS, 
            data: params.noticeId
        }))
    }

    /**
     * Fetch templates
     * 
     * @param {JSON} params
     * 
     * @returns {Promise}
     */
    getTemplates = ({communityId}) => {
        const { GET_TEMPLATES } = this.endpoints
        
        return this.API.get(this.getEndpoint({
            path: GET_TEMPLATES,
            params: {
                community_id: communityId,
                with_content: 1,
            }
        }))
    }
    
    /**
     * Fetch Filter Channels
     * 
     * @param {JSON} params
     * 
     * @returns {Promise}
     */
    getChannels = ({communityId}) => {
        const { GET_NOTICE_FILTERS } = this.endpoints
        
        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_FILTERS,
            params: {
                community_id: communityId
            }
        }))
    }

    /**
     * Fetch Message Channels
     * 
     * @param {JSON} params
     * 
     * @returns {Promise}
     */
    getNoticeChannels = ({communityId}) => {
        const { GET_NOTICE_BUILDER_CHANNELS } = this.endpoints
        
        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_BUILDER_CHANNELS,
            params: {
                community_id: communityId
            }
        }))
    }

    /**
     * Fetch Resource Groups
     * 
     * @param {JSON} params
     * 
     * @returns {Promise}
     */
    getNoticeResourceGroups = ({communityId}) => {
        const { GET_NOTICE_BUILDER_RESOURCE_GROUPS } = this.endpoints
        
        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_BUILDER_RESOURCE_GROUPS,
            params: {
                community_id: communityId
            }
        }))
    }

    /**
     * Save template
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postSaveTemplate = (params) => {
        
        const { POST_TEMPLATE } = this.endpoints
        let templateRequest = new PostSaveTemplateRequest(params)

        // NOTE: Validation was performed from the higher level Component, which in this case is Modal
        return this.API.post(this.getEndpoint({
            path: POST_TEMPLATE,
            params: {
                community_id: templateRequest.communityId
            },
        }),templateRequest.getPayload())
    }
    
    /**
     * Post Create Message
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postSaveTemplateWithContent = (params, builderPayload) => {
    
        const { POST_TEMPLATE_WITH_CONTENT } = this.endpoints
        const templateRequest = new PostSaveTemplateRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_TEMPLATE_WITH_CONTENT,
        }), templateRequest.getPayload(builderPayload))
    }
    
    /**
     * Post Update Message
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postUpdateTemplateWithContent = (params, builderPayload) => {
    
        const { POST_UPDATE_TEMPLATE_WITH_CONTENT } = this.endpoints
        const templateRequest = new PostSaveTemplateRequest(params)

        return this.API.put(this.getEndpoint({
            path: POST_UPDATE_TEMPLATE_WITH_CONTENT,
            data: params.templateId,
        }), templateRequest.getPayload(builderPayload))
    }
    
    /**
     * Fetch Publish Breakdown
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getNoticePublishBreakdown = (params) => {
        
        const { GET_NOTICE_PUBLISH_BREAKDOWN } = this.endpoints
        let publishRequest = new GetPublishBreakdownRequest(params)

        return this.API.post(this.getEndpoint({
            path: GET_NOTICE_PUBLISH_BREAKDOWN,
            data: publishRequest.noticeId
        }), publishRequest.getPayload())
    }

    /**
     * Fetch Publish Breakdown Excluded Members
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getNoticePublishBreakdownExcludedMembers = (params) => {
        
        const { GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS, GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS_BY_FILTERS } = this.endpoints
        const publishRequest = new GetPublishBreakdownRequest(params)

        if (params.selectionType === EXPORT_BY_SELECTION) {
            return this.API.post(this.getEndpoint({
                path: GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS,
                data: publishRequest.noticeId
            }), publishRequest.getExcludePayload(params))
        }
        

        const remindRequest = new GetRemindBreakdownRequest(params)
        const requestP = new GetNoticeRecipientsByFiltersRequest(params.filters)

        return this.API.post(this.getEndpoint({
            version: "v2",
            path: GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS_BY_FILTERS,
            data: publishRequest.noticeId
        }), remindRequest.getPayloadByFilters({
            filters: omit(requestP.getPayload(), ['page', 'per_page']), //? @NOTE: We exclude "page" and "per_page" because these keys have the potential to collide with the existing call's filter args
            selectionType: params.selectionType,
            data: remindRequest.getExcludePayload(params)
        }))
    }
    
    /**
     * Download Publish Breakdown Excluded Members
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    exportNoticePublishBreakdownExcludedMembers = (params) => {
        
        const { GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS, GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS_BY_FILTERS } = this.endpoints
        const publishRequest = new GetPublishBreakdownRequest(params)

        if (params.selectionType === EXPORT_BY_SELECTION) {

            return this.API({
                responseType: "blob",
                method: "POST",
                data: publishRequest.getExcludeDownloadPayload(params),
                url: this.getEndpoint({
                    path: GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS,
                    data: publishRequest.noticeId
                })
            })
        }
        
        const remindRequest = new GetRemindBreakdownRequest(params)
        const requestP = new GetNoticeRecipientsByFiltersRequest(params.filters)

        return this.API({
            responseType: "blob",
            method: "POST",
            data: remindRequest.getPayloadByFilters({
                filters: omit(requestP.getPayload(), ['page', 'per_page']), //? @NOTE: We exclude "page" and "per_page" because these keys have the potential to collide with the existing call's filter args
                selectionType: params.selectionType,
                data: remindRequest.getExcludeDownloadPayload(params)
            }),
            url: this.getEndpoint({
                version: "v2",
                path: GET_NOTICE_PUBLISH_BREAKDOWN_EXCLUDED_MEMBERS_BY_FILTERS,
                data: publishRequest.noticeId
            })
        })
    }

    /**
     * Publish Message
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postPublishNotice = (params) => {
        
        const { POST_PUBLISH_MESSAGE } = this.endpoints
        
        const publishRequest = new PostPublishBreakdownRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_PUBLISH_MESSAGE,
            data: publishRequest.noticeId
        }), publishRequest.getPayload())
    }

    /**
     * Schedule a Message
     * 
     * @param {{[key: string]: string}} params
     * @returns {Promise}
     */
    postScheduleNotice = (params) => {
        
        const { POST_SCHEDULE_MESSAGE } = this.endpoints
        
        const publishRequest = new PostPublishBreakdownRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_SCHEDULE_MESSAGE,
            data: publishRequest.noticeId
        }), publishRequest.getPayload())
    }

    /**
     * Send a scheduled message immediately.
     * 
     * @param {{noticeId: string}} params
     * @returns {Promise}
     */
    postScheduleSendNowNotice = ({ noticeId }) => {
        
        const { POST_SCHEDULE_SEND_NOW_MESSAGE } = this.endpoints

        return this.API.put(this.getEndpoint({
            path: POST_SCHEDULE_SEND_NOW_MESSAGE,
            data: noticeId
        }))
    }

    /**
     * Delete Schedule from a Message
     * 
     * @param {{noticeId: string}} params
     * @returns {Promise}
     */
    postDeleteNoticeSchedule = ({ noticeId }) => {
        
        const { POST_CANCEL_SCHEDULE_MESSAGE } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: POST_CANCEL_SCHEDULE_MESSAGE,
            data: noticeId
        }))
    }

    /**
     * Check if the message can be published using the selected channels and recipients
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getPublishPermission = (params) => {
        
        const { GET_NOTICE_PUBLISH_PERMISSION } = this.endpoints
        let noticeRequest = new PostCreateNoticeRequest(params)

        return this.API.post(this.getEndpoint({
            path: GET_NOTICE_PUBLISH_PERMISSION,
        }), pick(noticeRequest.getPayload(), [
            'person_ids',
            'channel_ids',
            'community_id',
        ]))
    }

    /**
     * Post Create Message
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postSaveNotice = (params) => {
        
        const { POST_NOTICE } = this.endpoints
        const createNoticeRequest = new PostCreateNoticeRequest(params)

        if (params.created_from_template) {
            return this.API.post(this.getEndpoint({
                path: POST_NOTICE,
            }), createNoticeRequest.getSaveTemplateAsNewPayload(params.originals))
        }

        return this.API.post(this.getEndpoint({
            path: POST_NOTICE,
        }), createNoticeRequest.getPayload())
    }
    
    /**
     * Put Update Message
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
     putUpdateNotice = (params) => {
        
        const { PUT_UPDATE_NOTICE } = this.endpoints
        let createNoticeRequest = new PostCreateNoticeRequest(params)

        return this.API.put(this.getEndpoint({
            path: PUT_UPDATE_NOTICE,
            data: createNoticeRequest.noticeId
        }), createNoticeRequest.getUpdatePayload(params.originals))
    }

    /**
     * Post Create Message
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postRemindNotice = (params) => {
        
        const { POST_NOTICE_REMIND_RECIPIENTS, POST_NOTICE_REMIND_RECIPIENTS_BY_FILTERS } = this.endpoints
        const request = new PostRemindNoticeRequest(params)

        if (params.selectionType === EXPORT_BY_SELECTION) {
            return this.API.post(this.getEndpoint({
                path: POST_NOTICE_REMIND_RECIPIENTS,
                data: request.noticeId,
            }), request.getPayload())
        }
        
        const requestP = new GetNoticeRecipientsByFiltersRequest(params.filters)

        return this.API.post(this.getEndpoint({
            version: "v2",
            path: POST_NOTICE_REMIND_RECIPIENTS_BY_FILTERS,
            data: request.noticeId,
        }), request.getPayloadByFilters({
            filters: requestP.getPayload(),
            selectionType: params.selectionType,
        }))
    }
    
    /**
     * Fetch Remind Message Breakdown
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getRemindNoticeBreakdown = (params) => {
        
        const { POST_NOTICE_REMIND_BREAKDOWN, POST_NOTICE_REMIND_BREAKDOWN_BY_FILTERS } = this.endpoints
        const request = new GetRemindBreakdownRequest(params)

        if (params.selectionType === EXPORT_BY_SELECTION) {
            
            return this.API.post(this.getEndpoint({
                path: POST_NOTICE_REMIND_BREAKDOWN,
                data: request.noticeId,
            }), request.getPayload())
        }
        
        const requestP = new GetNoticeRecipientsByFiltersRequest(params.filters)
        return this.API.post(this.getEndpoint({
            version: "v2",
            data: request.noticeId,
            path: POST_NOTICE_REMIND_BREAKDOWN_BY_FILTERS,
        }), request.getPayloadByFilters({
            filters: requestP.getPayload(), 
            selectionType: params.selectionType
        }))
    }

    /**
     * Delete template
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    deleteTemplate = ({templateId}) => {
        
        const { POST_DELETE_TEMPLATE } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: POST_DELETE_TEMPLATE,
            data: templateId,
        }))
    }

    /**
     * Fetch list of notices
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNotices = (params) => {

        const { GET_NOTICES } = this.endpoints
        const payload = new ListNoticesRequest(params)

        return this.API(this.getEndpoint({
            path: GET_NOTICES,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch filters for notice management screen
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getFilters = ({ communityId }) => {

        const { GET_NOTICES_FILTERS } = this.endpoints
        let payload = new GetNoticeFiltersRequest({ communityId: communityId })

        return this.API(this.getEndpoint({
            path: GET_NOTICES_FILTERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch filters for a specific notice
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNoticeRecipients = (params) => {

        const { GET_NOTICE_RECIPIENTS } = this.endpoints
        const payload = new ListNoticeRecipientsRequest(params)

        return this.API(this.getEndpoint({
            version: "v2",
            path: GET_NOTICE_RECIPIENTS,
            data: payload.noticeId,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch filters for a notice
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNoticeRecipientsFilters = ({ noticeId }) => {

        const { GET_NOTICE_RECIPIENT_FILTERS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_RECIPIENT_FILTERS,
            data: noticeId
        }))
    }
    
    /**
     * Fetch filters for a notice
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getRecipientsFilters = ({ communityId }) => {

        const { GET_NOTICE_FILTERS } = this.endpoints
        let payload = new GetNoticeFiltersRequest({ communityId: communityId })

        return this.API(this.getEndpoint({
            path: GET_NOTICE_FILTERS,
            params: payload.getPayload()
        }))
    }
    
    /**
     * Fetch target-able recipients
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getTargetRecipients = (params) => {

        const { GET_TARGET_PERSONS } = this.endpoints
        let payload = new ListTargetPersonsRequest(params)

        return this.API(this.getEndpoint({
            path: GET_TARGET_PERSONS,
            params: payload.getPayload()
        }))
    }
    
    /**
     * Send reminders to the given contributors
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postSendContributorReminders = (params) => {

        const { POST_NOTIFY_APPROVERS } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_NOTIFY_APPROVERS,
            data: params.noticeId,
            params: {
                community_id: params.communityId,
            }
        }), {
            user_ids: params.personIds
        })
    }

    /**
     * Fetch system users who should be able to approve a notice
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getApprovers = (params) => {

        const { GET_NOTIFIABLE_APPROVERS } = this.endpoints
        const payload = new ListAttachableMembersRequest(params)

        return this.API(this.getEndpoint({
            path: GET_NOTIFIABLE_APPROVERS,
            data: params.noticeId,
            params: payload.getPayload()
        }))
    }
    
    /**
     * Fetch target-able recipients/persons by filters
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getTargetRecipientsByFilters = (params) => {

        const { GET_TARGET_PERSONS_BY_FILTERS } = this.endpoints
        const payload = new ListTargetPersonsByFiltersRequest(params)

        return this.API(this.getEndpoint({
            path: GET_TARGET_PERSONS_BY_FILTERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Resend specific message to selected recipients
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postResendToRecipients = (params) => {

        return this.getFakeResponse({data: {success: "Message sent"}})
    }

    /**
     * Export members by selection or by filters
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDownloadReportByFilters = (params) => {

        const { POST_NOTICE_DOWNLOAD_RECIPIENTS_REPORT, POST_NOTICE_DOWNLOAD_RECIPIENTS_REPORT_BY_FILTERS } = this.endpoints
        const request = new GetNoticeRecipientsByFiltersRequest(params.filters)

        const extraParams = params.payload

        // Export selected person ids
        if (params.exportType === EXPORT_BY_SELECTION) {

            return this.API({
                responseType: "blob",
                method: "POST",
                data: request.getExportBySelectionPayload(extraParams),
                url: this.getEndpoint({
                    path: POST_NOTICE_DOWNLOAD_RECIPIENTS_REPORT,
                    data: params.noticeId
                })
            })
        }

        return this.API({
            responseType: "blob",
            method: "POST",
            data: request.getExportByFiltersPayload(extraParams),
            url: this.getEndpoint({
                version: "v2",
                path: POST_NOTICE_DOWNLOAD_RECIPIENTS_REPORT_BY_FILTERS,
                data: params.noticeId
            })
        })
    }
    
    /**
     * Download report
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDownloadVotes = (params) => {
        
        const { POST_NOTICE_DOWNLOAD_VOTES }  = this.endpoints
        
        const payload = {
            format: params.format,
            component_id: params.componentId
        }

        return this.API({
            responseType: "blob",
            method: "POST",
            data: payload,
            url: this.getEndpoint({
                path: POST_NOTICE_DOWNLOAD_VOTES,
                data: params.noticeId
            })
        })
    }

    /**
     * Delete Message
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDeleteNotice = ({ noticeId }) => {

        const { POST_ARCHIVE_NOTICE } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_ARCHIVE_NOTICE,
            data: noticeId
        }))
    }

    /**
     * 
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postPublishResults = (params) => {

        const { POST_NOTICE_PUBLISH_RESULTS } = this.endpoints
        const payload = {
            component_id: params.componentId,
            results_visible_at: params.results_visible_at,
        }

        return this.API.put(this.getEndpoint({
            path: POST_NOTICE_PUBLISH_RESULTS,
            data: params.noticeId
        }), payload)
    }

    /**
     * Delete Multiple Messages
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postDeleteMultiNotice = (payload) => {

        const { POST_ARCHIVE_MULTI_NOTICES } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_ARCHIVE_MULTI_NOTICES,
        }), payload)
    }
    
    /**
     * Fetch Preview Data
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getNoticePreview = ({ noticeId }) => {

        const { GET_NOTICE_PREVIEW } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_NOTICE_PREVIEW,
            data: noticeId
        }))
    }

    /**
     * Fetch Preview Data for a Notice that has yet to be created.
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getLiveNoticePreview = (params) => {

        const { GET_LIVE_NOTICE_PREVIEW } = this.endpoints

        const previewNoticeRequest = new PostCreateNoticeRequest(params)

        if (params.isExistingTemplate) {
            const originals = params.originals.templates ?? []
            return this.API.post(this.getEndpoint({
                path: GET_LIVE_NOTICE_PREVIEW,
            }), previewNoticeRequest.getSaveTemplateAsNewPayload(originals))
        }
        
        if (params.isExistingNotice) {
            const originals = params.originals.notices ?? []
            return this.API.post(this.getEndpoint({
                path: GET_LIVE_NOTICE_PREVIEW,
            }), previewNoticeRequest.getUpdatePayload(originals))
        }

        return this.API.post(this.getEndpoint({
            path: GET_LIVE_NOTICE_PREVIEW,
        }), previewNoticeRequest.getPayload())
    }
    
    /**
     * Fetch Preview Data
     * 
     * @param {JSON} params
     * @return {Promise}
     */
     getEditableNotice = ({ noticeId }) => {

        const { GET_EDIT_NOTICE } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_EDIT_NOTICE,
            data: noticeId
        }))
    }
    
    /**
     * Fetch custom dynamically created notice data to prefill the builder
     * 
     * @param {JSON} params
     * @return {Promise}
     */
     getCustomNotice = (params) => {

        return this.getFakeResponse({
            community_id: params.community.id,
            community_name: params.community.name,
            community_image: params.community.image,
            channels: params.channels,
            users: params.users,
            persons: params.persons,
            title: params.title ?? "",
            important: false,
            target_everyone: params.target_everyone,
            components: params.components ?? [],
            is_custom: true,
        })
    }
}

export default NoticeApiService