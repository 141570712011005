import { identity, isEmpty, pickBy } from "lodash"

class InviteMemberRequest {

    constructor({
        communityId,
        personId,
        sendViaEmail,
        sendViaSms,
        isReminder = false,
    }) {
        this.personId = personId
        this.isReminder = isReminder
        this.sendViaSms = sendViaSms
        this.communityId = communityId
        this.sendViaEmail = sendViaEmail
    }


    getPayload = () => {
        
        const payload = {}

        if (this.sendViaEmail) {
            payload["send_via_email"] = this.sendViaEmail
        }

        if (this.sendViaSms) {
            payload["send_via_sms"] = this.sendViaSms
        }

        return payload
    }
    
}

export default InviteMemberRequest