import React, { useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Button, Popup } from 'semantic-ui-react'

import { FaCrown, FaLock } from 'react-icons/fa'

import { 
  TextMorphed, 
  PaymentMorphed, 
  WebFrameMorphed, 
  ContactMorphed, 
  ProgressMorphed, 
  LocationMorphed, 
  DeadlineMorphed,
  FormMorphed,
  AttachmentMorphed,
  ImageMorphed,
  VideoMorphed,
  EventMorphed,
  DateMorphed
} from '../morphedTemplates'

import { templateIcons } from '../../../constants'
import i18next from 'i18next'
import VoteMorphed from '../morphedTemplates/VoteMorphed'
import { Trans } from 'react-i18next'
import URLMorphed from '../morphedTemplates/URLMorphed'

export const templateInfo = {
  [templateIcons.text]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.text.description"
          components={{
            strong: <strong/>
          }}/>
      </p>
    ) 
  },
  [templateIcons.file]: { 
    info: (
      <div className="no-margin">
        <Trans 
          i18nKey="components.file.description"
          components={{
            strong: <strong/>,
            p: <p className="no-margin only-on-morph" />
          }}/>
      </div>
    )
  },
  [templateIcons.event]: { 
    info: (
      <div className="no-margin">
        <Trans 
          i18nKey="components.event.description"
          components={{
            strong: <strong/>,
            p: <p className="no-margin only-on-morph" />
          }}/>
      </div>
    )
  },
  [templateIcons.form]: { 
    info: (
      <div>
          <div className="no-margin">
            <Trans
              i18nKey="components.form.description"
              components={{
                strong: <strong/>,
                p: <p className="only-on-morph"/>,
              }}/>
          </div>
          <ul className="only-on-morph">
              <li key={31}><strong>{i18next.t("constants.index.form.textField")}:</strong> {i18next.t("components.form.text.description")}</li>
              <li key={32}><strong>{i18next.t("constants.index.form.yesNo")}:</strong> {i18next.t("components.form.yesNo.description")}</li>
              <li key={33}><strong>{i18next.t("constants.index.form.multipleChoice")}:</strong> {i18next.t("components.form.multi.description")}</li>
              <li key={34}><strong>{i18next.t("constants.index.form.imageUpload")}:</strong> {i18next.t("components.form.image.description")}</li>
              <li key={35}><strong>{i18next.t("constants.index.form.fileUpload")}:</strong> {i18next.t("components.form.file.description")}</li>
          </ul>
      </div>
    ) 
  },
  [templateIcons.vote]: { 
    info: (
      <div>
          <div className="no-margin">
            <Trans
              i18nKey="components.vote.description"
              components={{
                strong: <strong/>,
                p: <p className="only-on-morph"/>,
              }}/>
          </div>
          <ul className="only-on-morph">
              <li key={36}><strong>{i18next.t("constants.index.vote.standard")}:</strong> {i18next.t("components.form.text.description")}</li>
              <li key={37}><strong>{i18next.t("constants.index.vote.sgbZA")}:</strong> {i18next.t("components.form.yesNo.description")}</li>
          </ul>
      </div>
    )
  },
  // General
  [templateIcons.image]: { 
    info: (
      <div className="no-margin">
        <Trans 
          i18nKey="components.image.description"
          components={{
            strong: <strong/>,
            p: <p className="no-margin hide" /> //hide information about Albums since functionality is pending
          }}/>
      </div>
    )
  },
  [templateIcons.youTube]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.youtube.description"
          components={{
            strong: <strong/>
          }}/>
      </p>
    )
  },
  // Payment
  [templateIcons.payment]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.payment.description"
          components={{
            strong: <strong/>
          }} />
      </p>
    ),
  },
  [templateIcons.contact]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.contact.description"
          components={{
            strong: <strong/>
          }}/>
      </p>
    )
  },
  [templateIcons.location]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.location.description"
          components={{
            strong: <strong/>
          }} />
      </p>
    ) 
  },
  [templateIcons.progressBar]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.progressbar.description"
          components={{
            strong: <strong />
          }} />
      </p>
    )
  },
  [templateIcons.iframe]: { 
    info: (
      <p className="no-margin">
        <Trans 
          i18nKey="components.webframe.description"
          components={{
            strong: <strong/>
          }}/>
      </p>
    )
  },
  [templateIcons.url]: { 
    info: (
      <p className="no-margin">
        <Trans
          i18nKey="components.url.description"
          components={{
            strong: <strong/>
          }}/>
      </p>
    )
  },
}

function MorphTemplate({ item }) {

    switch (item.type) {
        case templateIcons.notice: {
          return <Button>{item.type}</Button>
        }
        case templateIcons.event: {
          return <EventMorphed item={item} />
        }
        case templateIcons.payment: {
          return <PaymentMorphed item={item} />
        }
        case templateIcons.text: {
          return <TextMorphed item={item}/>
        }
        case templateIcons.file:
        case templateIcons.attachment: {
          return <AttachmentMorphed  item={item}/>
        }
        case templateIcons.video:
        case templateIcons.vimeo:
        case templateIcons.youTube: {
          return <VideoMorphed item={item} />
        }
        case templateIcons.album:
        case templateIcons.image: {
          return <ImageMorphed item={item} />
        }
        case templateIcons.location: {
          return <LocationMorphed item={item} />
        }
        case templateIcons.url: {
          return <URLMorphed item={item} />
        }
        case templateIcons.deadline: {
          return <DeadlineMorphed item={item} />
        }
        case templateIcons.form: {
          return <FormMorphed item={item} />
        }
        case templateIcons.vote: {
          return <VoteMorphed item={item} />
        }
        case templateIcons.iframe: {
          return <WebFrameMorphed item={item} />
        }
        case templateIcons.contact: {
          return <ContactMorphed item={item} />
        }
        case templateIcons.progressBar: {
          return <ProgressMorphed item={item} />
        }
        case templateIcons.date: {
          return <DateMorphed item={item} />
        }
        case templateIcons.customTemplate: {
          return <Button>{item.name}</Button>
        }
        default:
          return <Button>{item.type}</Button>
    }
}

function PopupTemplateContent({ item }) {
  
  if (item?.tiering?.upgrade_required) {
    return <div>{templateInfo[item.type].info}<hr /><small>{i18next.t('general.plan.upgrade')}</small></div>
  }

  if (item?.tiering?.add_on_required) {
    return (
      <div>
        {templateInfo[item.type].info}
        <hr />
        <div className='flex items-center'>
          <small><Trans i18nKey="general.plan.addon.description" components={{strong: <strong/>}} /></small>
        </div>
      </div>
    )
  }

  return templateInfo[item.type].info
}

export default function Template({ className, icon, item, index, misBehave, render, disabled, onClick}) {

    const handleTemplateClick = (result) => {
      (onClick) && onClick({result, item, index})
    }
    
    const classNames = useMemo(() => [
      `${!misBehave ? 'template': ''}`,
      `${!item.isFavourite ? 'generic': ''}`,
      `${disabled ? 't-disabled': ''}`,
      `${className || ''}`,
      `${!misBehave && item?.tiering?.upgrade_required ? 't-upgrade-required' : ''}`,
      `${!misBehave && item?.tiering?.add_on_required ? 't-addon-required' : ''}`
    ], [misBehave, item.isFavourite, disabled, className, item?.tiering?.upgrade_required, item?.tiering?.upgrade_required])

    return (
        <Draggable
            key={item.id}
            index={index}
            draggableId={item.id}
            isDragDisabled={disabled}>
        
            {(provided, snapshot) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className={classNames.concat(`${snapshot.isDragging ? 'is-dragging dragg-'.concat(item.id) : ''}`).join(" ")}
                    key={item.id}
                    onClick={() => !misBehave && handleTemplateClick(provided)}>

                    {misBehave && <MorphTemplate item={ item } />}
                    
                    {(!misBehave && templateInfo[item.type].info) &&(
                        <Popup
                          basic
                          className="template-info-popup"
                          trigger={
                            <span data-test-component-button={item.type} className="template-info">
                                {render && render()}
                                {icon && <span className='icon'>{ icon }</span>}
                                <span className='name'>{ item.name }</span>
                                {item?.tiering?.upgrade_required && <FaCrown className='crown-icon'/> }
                                {item?.tiering?.add_on_required && <FaLock className='crown-icon'/> }
                            </span>
                          }
                          content={<PopupTemplateContent item={ item } />}
                        />
                    )}
                    
                    {(!misBehave && !templateInfo[item.type].info) &&(
                        <span data-test-component-button={item.type} className="template-info">
                            {render && render()}
                            {icon && <span className='icon'>{ icon }</span>}
                            <span className='name'>{ item.name }</span>
                        </span>
                    )}
                </div>
            )}
        </Draggable>
    )
}
