import { AssistedWritingRequest } from "../api/requestObjects"
import ApiService from "./ApiService"

class AssistedWritingService extends ApiService {

    /**
     * Request message composition assistance
     * 
     * @returns {Promise}
     */
    postRequestAssistance = (params) => {

        const { POST_REQUEST_WRITING_ASSISTANCE } = this.endpoints
        const request = new AssistedWritingRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_REQUEST_WRITING_ASSISTANCE,
            data: request.mode,
        }), request.getPayload())
    }
    
    /**
     * LOG dislike/retry response
     * 
     * @returns {Promise}
     */
    logRetry = (payload) => {

        const { POST_REQUEST_WRITING_ASSISTANCE_FEEDBACK } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_REQUEST_WRITING_ASSISTANCE_FEEDBACK,
        }), payload)
    }

    /**
     * [Fake/Test]
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    mock = async (params) => {
        const request = new AssistedWritingRequest(params)
        return this.API.post(`http://localhost:3001/api/v1/mock-open-ai/${request.mode}`, 
            request.getPayload()
        )
    }
}

export default AssistedWritingService