import { 
    createAction
} from 'redux-actions'

import { actions }      from '../constants'
import { tieringService } from '../api'

const { TIERING } = actions

export const tieringActions = {

    /**
     * Load
     */
    start: createAction(TIERING.START),
    
    /**
     * Show tiering modal
     */
    modal: createAction(TIERING.SHOW),
    
    /**
     * Show upgrade required modal
     */
    modalMini: createAction(TIERING.SHOW_MINI),
    
    /**
     * Show messages
     */
    message: createAction(TIERING.MESSAGES),

    /**
     * save limits
     */
    saveLimits: createAction(TIERING.SAVE_LIMITS),
    
    /**
     * save builder limits
     */
    saveBuilderLimits: createAction(TIERING.SAVE_BUILDER_LIMITS),

    /**
     * Show error messages
     */
    error: createAction(TIERING.ERROR),

    /**
     * reset data
     */
    reset: createAction(TIERING.RESET),
    
    /**
     * reset messages data
     */
    clearMessages: createAction(TIERING.CLEAR_MESSAGES),
    
    doPostRequestAddOn (params) {
        return (dispatch) => tieringService.postRequestAddOn(params)
            .then(response => {
                dispatch(this.message({
                    header: 'tiering.message.success.header',
                    content: 'tiering.message.success.content',
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    doPostRequestPlan (params) {
        return (dispatch) => tieringService.postRequestPlan(params)
            .then(response => {
                // dispatch(this.message(response.data))
                dispatch(this.message({
                    header: 'tiering.message.success.header',
                    content: 'tiering.message.success.content',
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    getPersonPlanLimits (params) {
        return (dispatch) => tieringService.getPersonPlanLimits(params)
            .then(response => {
                dispatch(this.saveLimits(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    getChannelPlanLimits (params) {
        return (dispatch) => tieringService.getChannelPlanLimits(params)
            .then(response => {
                dispatch(this.saveLimits(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    getImportPlanLimits (params) {
        return (dispatch) => tieringService.getImportPlanLimits(params)
            .then(response => {
                dispatch(this.saveLimits(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    getUsersPlanLimits (params) {
        return (dispatch) => tieringService.getUsersPlanLimits(params)
            .then(response => {
                dispatch(this.saveLimits(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    getBuilderPlanLimits (params) {
        return (dispatch) => tieringService.getBuilderPlanLimits(params)
            .then(response => {
                dispatch(this.saveBuilderLimits(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    getTemplatePlanLimits (params) {
        return (dispatch) => tieringService.getTemplatePlanLimits(params)
            .then(response => {
                dispatch(this.saveLimits(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
} 