import ApiService from "./ApiService"
import {
  ListChannelsRequest,
  GetChannelFiltersRequest,
  UpdateChannelRequest,
  CreateChannelRequest,
  ListChannelMembersRequest,
  ListAttachableMembersRequest,
  PostAttachMembersRequest,

  // We are using the same request body as in attach but with an Alias
  PostAttachMembersRequest as PostDetachMembersRequest,
} from "../api/requestObjects"
import { size } from "lodash"

class ChannelApiService extends ApiService {

  /**
   * Fetch filters  for channel management screen
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getFilters = ({ communityId }) => {

    const { GET_CHANNEL_FILTERS } = this.endpoints
    let payload = new GetChannelFiltersRequest({ communityId: communityId })

    return this.API(this.getEndpoint({
      path: GET_CHANNEL_FILTERS,
      params: payload.getPayload()
    }))
  }

  /**
   * Fetch list of channels
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getChannels = (params) => {

    const { GET_CHANNELS } = this.endpoints
    const payload = new ListChannelsRequest(params)

    return this.API(this.getEndpoint({
      path: GET_CHANNELS,
      params: payload.getPayload()
    }))
  }

  /**
   * Post update channel details
   * 
   * data validated from UI
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postUpdateChannel = (params) => {

    const { PUT_CHANNEL_UPDATE } = this.endpoints
    const payload = new UpdateChannelRequest(params)

    return this.API.put(this.getEndpoint({
      path: PUT_CHANNEL_UPDATE,
      data: params.channelId,
    }), payload.getPayload())
  }

  /**
   * Post add attachable persons for channel
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postAttachableMembers = (params) => {

    const { POST_CHANNEL_ATTACH_MEMBERS } = this.endpoints
    const payload = new PostAttachMembersRequest(params)

    return this.API.post(this.getEndpoint({
      path: POST_CHANNEL_ATTACH_MEMBERS,
      data: params.channelId,
    }), payload.getPayload())
  }

  /**
   * Post create channel
   * 
   * data validated from UI
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postCreateChannel = (params) => {

    const { POST_CHANNEL_CREATE } = this.endpoints
    const payload = new CreateChannelRequest(params)

    return this.API.post(this.getEndpoint({
      path: POST_CHANNEL_CREATE,
    }), payload.getPayload())
  }

  /**
   * Fetch specific channel details
   * 
   * @param {JSON} {channelID}
   * @return {Promise}
   */
  getChannel = ({ channelId, communityId }) => {

    const { GET_CHANNEL } = this.endpoints

    return this.API(this.getEndpoint({
      path: GET_CHANNEL,
      data: channelId,
      params: {
        community_id: communityId
      }
    }))
  }

  /**
   * Fetch specific channel members
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getChannelMembers = (params) => {

    const { GET_CHANNEL_MEMBERS } = this.endpoints
    const payload = new ListChannelMembersRequest(params)

    return this.API(this.getEndpoint({
      path: GET_CHANNEL_MEMBERS,
      data: payload.channelId,
      params: payload.getPayload()
    }))
  }

  /**
   * Fetch specific member details
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getMember = ({ userId, channelId }) => {

    const { GET_CHANNEL_MEMBER } = this.endpoints

    return this.API(this.getEndpoint({
        path: GET_CHANNEL_MEMBER,
        data: [channelId, userId]
    }))
  }

  /**
   * Fetch specific channel attachable members
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getAttachableMembers = (params) => {

    const { GET_MEMBERS } = this.endpoints
    const payload = new ListAttachableMembersRequest(params)

    return this.API(this.getEndpoint({
      path: GET_MEMBERS,
      data: payload.channelId,
      params: payload.getPayload()
    }))
  }

  /**
   * Fetch specific channel attachable members filters
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getAttachableMembersFilters = ({ channelId }) => {

    const { GET_CHANNEL_ATTACHABLE_MEMBERS_FILTERS } = this.endpoints

    return this.API(this.getEndpoint({
      path: GET_CHANNEL_ATTACHABLE_MEMBERS_FILTERS,
      data: channelId,
    }))
  }

  /**
   * Detach members from a specific channel
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postDetachMembers = (params) => {

    const { POST_CHANNEL_DETACH_MEMBERS } = this.endpoints
    const payload = new PostDetachMembersRequest(params)

    return this.API.post(this.getEndpoint({
      path: POST_CHANNEL_DETACH_MEMBERS,
      data: payload.channelId,
    }), payload.getPayload())
  }

  /**
   * Post delete channel
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  postDeleteChannel = (params) => {

    const { DELETE_CHANNEL } = this.endpoints

    return this.API.delete(this.getEndpoint({
      path: DELETE_CHANNEL,
      data: params.channelId
    }))
  }

  /**
   * Delete Multiple Channels
   * 
   * @param {JSON} payload
   * @return {Promise}
   */
  postDeleteMultiChannels = (payload) => {

    const { POST_DELETE_MULTI_CHANNELS } = this.endpoints

    return this.API.delete(this.getEndpoint({
      path: POST_DELETE_MULTI_CHANNELS,
    }), {data: payload})
  }

  /**
   * Fetch channel persons
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getChannelPersons = (params) => {

    const { GET_CHANNEL_PERSONS } = this.endpoints

    let payload = {
      community_id: params.communityId,
    }

    if (size(params.tags) > 0) {
      payload["tag_ids"] = params.tags
    }

    return this.API.get(this.getEndpoint({
      path: GET_CHANNEL_PERSONS,
      params: payload
    }))
  }

  /**
   * Fetch channel persons filters
   * 
   * @param {JSON} params
   * @return {Promise}
   */
  getChannelPersonsTags = (params) => {

    const { GET_CHANNEL_PERSONS_FILTERS } = this.endpoints

    return this.API.get(this.getEndpoint({
      path: GET_CHANNEL_PERSONS_FILTERS,
      params: {
        community_id: params.communityId
      }
    }))
  }
}

export default ChannelApiService