import React from 'react'
import logo from '../images/d6_logo_white.svg'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../actions/authActions'
import { Button, Dimmer, Grid, Label, Loader, Popup } from 'semantic-ui-react'
import { useEffect } from 'react'
import { usersActions } from '../actions/usersActions'
import { concat, differenceBy, map, size, take } from 'lodash'
import { Fragment } from 'react'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'
import { useParams } from 'react-router'
import Messages from './partials/Messages'

const AcceptInvite = () => {

  const auth = useSelector(state => state.auth)
  const navigation = useSelector(state => state.navigation)
  const ssoUser = useSelector(state => state.auth.ssoUser)
  const users = useSelector(state => state.users)
  const params = useParams()

  const dispatch = useDispatch()

  // Get Invite Details
  useEffect(() => {

    if (!params.token || !auth.profile) return;

    document.body.classList.add('invite')

    dispatch(usersActions.start())
    dispatch(usersActions.fetchInvitationDetails({
      token: params.token
    }))

    return () => {
      document.body.classList.remove('invite')
      dispatch(usersActions.clearMessages())
    }
  }, [auth.profile])
  
  const handleAcceptClick = () => {
    dispatch(usersActions.start())
    dispatch(usersActions.postAcceptInvite({
      inviteId: params.token,
      communityId: users.inviteDetails.community_id,
    }))
  }
  
  const handleIgnore = () => {
    
    if (!navigation.community) {
      dispatch(push(routes.private.NO_LINK))
      return;
    }
    
    dispatch(push(routes.private.HOME))
  }
  
  const handleNotMeClick = () => {
    const redirectUrl = routes.private.INVITE.replace(':token', params.token)
    dispatch(authActions.doLogout(redirectUrl))
  }

  const renderChannelRoles = () => {
    let all = map(users.inviteDetails.channel_roles, c => { return {name: c}})
    let content = ''
    let _limit = 2

    if (size(all) > _limit) {
        content = [].concat(map(differenceBy(all, take(all, _limit), "name"), "name")).join(", ")
    }

    if (size(all) === 0) {
      return <Fragment />
    }

    return (
      <Fragment>
        <p><strong>Channel Roles</strong></p>
        <div className='flex gap-1 dir-column'>
          {map(take(all, _limit), (_role, ri) => {
              return (
                <Label key={ri} as='a' className='color-primary mg-bot-1'>
                  <strong>{_role.name}</strong>
                </Label>
              )
          })}
        </div>
        
        {(size(all)-_limit > 0) && (
          <Popup 
            basic
            content={content} 
            trigger={
                <Label as='a' className='color-primary'>
                    <small className="primary-color-text"><strong>+{size(all)-_limit}</strong></small>
                </Label>
            }/>
          )}

      </Fragment>
    )
  }

  const renderCommunityRoles = () => {

    if (size(users.inviteDetails.community_roles) ===  0) {
      return <Fragment />
    }

    return (
      <Fragment>
        <p><strong>Community Roles</strong></p>
        <div className='flex gap-1 dir-column'>
          {map(users.inviteDetails.community_roles, (role, i) => (
            <Label key={i} as='span' className='color-primary mg-bot-1'>{ role }</Label>
          ))}
        </div>
      </Fragment>
    )
  }

  const handleLogout = () => {
    dispatch(authActions.start())
    dispatch(authActions.doLogout())
  }

  const renderLogo = () => {
    return (users.inviteDetails && users.inviteDetails.community_image) || logo
  }

  const renderDetails = () => {
    
    if (users.loading) {
      return (
          <Loader active={users.loading} content='Loading ...'/>
      )
    }
    
    if (!users.inviteDetails) {
      return (
        <div className="w-100 pad-bot-15 pad-top-15">
          <div className="field">
            <h2 className="welcome-header">
                Hello{ssoUser && ssoUser.profile && `, ${ssoUser.profile.name}`}
            </h2>

            <p className="description-text">Welcome to d6 Communicator, we were unable to locate your account in our system.</p>
            <p className="description-text">For more information regarding account registration, please contact <a href="mailto:support@d6.co.za">support@d6.co.za</a></p>
            <br/>
          </div>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Button 
                  onClick={handleLogout}
                  loading={auth.loading}
                  disabled={auth.loading}
                  className="ui fluid large primary-color submit button">
                    Logout
                </Button>
              </Grid.Column>
              <Grid.Column width={8}>
                <Button 
                  onClick={handleIgnore}
                  loading={auth.loading}
                  disabled={auth.loading}
                  className="ui fluid large grey submit button">
                    Home
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )
    }

    return (
      <div className="w-100 pad-bot-15 pad-top-15">
        <div className="field">
          <h2 className="welcome-header">
              Hello, {auth.profile && auth.profile.first_name} <small style={{fontSize: 11}}><a href="#/" onClick={handleNotMeClick} title="not you?">not you?</a></small>
          </h2>

          <p className="description-text">Welcome to d6 Communicator, you have been invited to join the <strong>{users.inviteDetails.community_name}</strong> community.</p>
          <div className="roles-container description-text">
            {renderCommunityRoles()}
          </div>
          <br/>
          <div className="roles-container description-text">
            {renderChannelRoles()}
          </div>
          <br/>
        </div>
        <Grid>
          <Grid.Row className='gap-15'>
            <Grid.Column width={16}>
              <Button
                onClick={handleAcceptClick}
                loading={users.loading}
                disabled={users.loading}
                className="ui fluid primary-color submit button">
                  Accept Invite
              </Button>
            </Grid.Column>
            <Grid.Column width={16}>
              <Button
                onClick={handleIgnore}
                loading={users.loading}
                disabled={users.loading}
                className="ui fluid grey submit button">
                  Ignore Invite
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  return (
    <div className="card-layout column invitation">
      <div className="ui large form">
        <div className="ui segment custom">
          <div className="ui vertically divided grid split-container">
            <div className="two column row no-padding">

              <div className="column left-side">
                <img src={ renderLogo() } alt="" className="image" />
              </div>
              {users.errors && (
                <Messages 
                  onClose={() => dispatch(usersActions.clearMessages())}
                  errors={users.errors}/>
              )}
              <div className="column right-side">
                {renderDetails()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AcceptInvite