import { identity, isString, map, pick } from "lodash"
import { dropEmptyKeys, filterNulls } from "../../utils/helper"
import { EXPORT_BY_FILTERS } from "./ExportMembersRequest"

class GetRemindBreakdownRequest {
    
    constructor({
        strategy,
        platforms,
        noticeId,
        communityId,
        sendPush,
        pinItem,
        users,
        relationship
    }) {
        this.users = users
        this.pinItem = pinItem
        this.sendPush = sendPush
        this.strategy = strategy
        this.noticeId = noticeId
        this.platforms = platforms
        this.communityId = communityId 
        this.relationship = relationship
    }

    isValid = () => {
        return true
    }

    getSelectedPlatform = () => {
        const _platforms = map(this.platforms, (o, i) => (o.selected === false) ? null : i)
        return _platforms.filter(isString) 
    }

    getPayload = () => {
        
        let payload = {
            "strategy": this.strategy,
            "recipient_ids": this.users,
            "relationship": this.relationship,
            "platforms": this.getSelectedPlatform(),
        }

        // When App is un-checked we should also remove the "pin_notice" and "send_push_notification"
        if (payload.platforms.includes("app")) {
            payload = {
                ...payload,
                "pin_notice": this.pinItem,
                "send_push_notification": this.sendPush,
            }
        }

        // When the users unticks the app, we should soft target to app
        payload['soft_target_platform_app'] = !payload.platforms.includes("app")
        if (payload['soft_target_platform_app']) {
            payload['send_push_notification'] = this.sendPush
        }

        return filterNulls(payload)
    }

    getExcludePayload = (params) => {
        
        const payload = {
            ...this.getPayload(),
            ...pick(params, ['sort', 'per_page', 'page'])
        }

        return filterNulls(payload)
    }
    
    getExcludeDownloadPayload = (params) => {
        
        const payload = {
            ...this.getExcludePayload(params),
            export: true,
        }

        return payload
    }
    
    getPayloadByFilters = ({filters, selectionType, data}) => {
        
        let payload = {
            ...filters,
        }

        if (data) {
            payload = {
                ...payload,
                ...data,
            }
        } else {
            payload = {
                ...payload,
                ...this.getPayload(),
            }
        }

        if (selectionType === EXPORT_BY_FILTERS) {
            payload['recipient_ids'] = null
        }

        return dropEmptyKeys(filterNulls(payload))
    }

}

export default GetRemindBreakdownRequest