import React from 'react'
import moment from "moment"
import logo from "../images/d6_logo_teal.svg"
import { Icon } from 'semantic-ui-react'
import { templateIcons } from '.'
import i18next from '../i18n'
import { Trans } from 'react-i18next'

const initialData = {
  tasks: {
    'task-5': { 
      id: 'task-5',
      order: 0,
      type: templateIcons.text, 
      name: i18next.t('components.text'), 
      // info: (
      //   <p className="no-margin">
      //     <Trans 
      //       i18nKey="components.text.description"
      //       components={{
      //         strong: <strong/>
      //       }}/>
      //   </p>
      // ) 
    },
    'task-3': { 
      id: 'task-3',
      order: 1,
      type: templateIcons.file, 
      name: i18next.t('components.file'), 
      // info: (
      //   <div className="no-margin">
      //     <Trans 
      //       i18nKey="components.file.description"
      //       components={{
      //         strong: <strong/>,
      //         p: <p className="no-margin only-on-morph" />
      //       }}/>
      //   </div>
      // )
    },
    'task-2': { 
      id: 'task-2',
      order: 2, 
      type: templateIcons.event, 
      name: i18next.t('components.event'), 
      // info: (
      //   <div className="no-margin">
      //     <Trans 
      //       i18nKey="components.event.description"
      //       components={{
      //         strong: <strong/>,
      //         p: <p className="no-margin only-on-morph" />
      //       }}/>
      //   </div>
      // )
    },
    'task-15': { 
      id: 'task-15',
      order: 3, 
      type: templateIcons.form, 
      name: i18next.t('components.form'), 
      // info: (
      //   <div>
      //       <div className="no-margin">
      //         <Trans
      //           i18nKey="components.form.description"
      //           components={{
      //             strong: <strong/>,
      //             p: <p className="only-on-morph"/>,
      //           }}/>
      //       </div>
      //       <ul className="only-on-morph">
      //           <li key={31}><strong>{i18next.t("constants.index.form.textField")}:</strong> {i18next.t("components.form.text.description")}</li>
      //           <li key={32}><strong>{i18next.t("constants.index.form.yesNo")}:</strong> {i18next.t("components.form.yesNo.description")}</li>
      //           <li key={33}><strong>{i18next.t("constants.index.form.multipleChoice")}:</strong> {i18next.t("components.form.multi.description")}</li>
      //           <li key={34}><strong>{i18next.t("constants.index.form.imageUpload")}:</strong> {i18next.t("components.form.image.description")}</li>
      //           <li key={35}><strong>{i18next.t("constants.index.form.fileUpload")}:</strong> {i18next.t("components.form.file.description")}</li>
      //       </ul>
      //   </div>
      // ) 
    },
    'task-20': { 
      id: 'task-20',
      order: 3, 
      type: templateIcons.vote, 
      name: i18next.t('components.vote'), 
      // info: (
      //   <div>
      //       <div className="no-margin">
      //         <Trans
      //           i18nKey="components.vote.description"
      //           components={{
      //             strong: <strong/>,
      //             p: <p className="only-on-morph"/>,
      //           }}/>
      //       </div>
      //       <ul className="only-on-morph">
      //           <li key={36}><strong>{i18next.t("constants.index.vote.standard")}:</strong> {i18next.t("components.form.text.description")}</li>
      //           <li key={37}><strong>{i18next.t("constants.index.vote.sgbZA")}:</strong> {i18next.t("components.form.yesNo.description")}</li>
      //       </ul>
      //   </div>
      // )
    },
    // General
    'task-7': { 
      id: 'task-7', 
      order: 4,
      type: templateIcons.image, 
      name: i18next.t('components.image'),
      // info: (
      //   <div className="no-margin">
      //     <Trans 
      //       i18nKey="components.image.description"
      //       components={{
      //         strong: <strong/>,
      //         p: <p className="no-margin hide" /> //hide information about Albums since functionality is pending
      //       }}/>
      //   </div>
      // )
    },
    'task-81': { 
      id: 'task-81',
      order: 5, 
      type: templateIcons.youTube, 
      name: i18next.t('components.youtube'), 
      // disabled: true,
      // info: (
      //   <p className="no-margin">
      //     <Trans 
      //       i18nKey="components.youtube.description"
      //       components={{
      //         strong: <strong/>
      //       }}/>
      //   </p>
      // )
    },
    // Payment
    'task-11': { 
      id: 'task-11',
      order: 6, 
      type: templateIcons.payment, 
      name: i18next.t('components.payment'),
      // info: (
      //   <p className="no-margin">
      //     <Trans 
      //       i18nKey="components.payment.description"
      //       components={{
      //         strong: <strong/>
      //       }} />
      //   </p>
      // ),
    },
    'task-13': { 
      id: 'task-13',
      order: 5, 
      type: templateIcons.contact, 
      name: i18next.t('components.contact'),
      // info: (
      //   <p className="no-margin">
      //     <Trans 
      //       i18nKey="components.contact.description"
      //       components={{
      //         strong: <strong/>
      //       }}/>
      //   </p>
      // )
    },
    // 'task-82': { 
    //   id: 'task-82', 
    //   type: templateIcons.vimeo, 
    //   name: 'Vimeo', 
    //   disabled: true,
    //   info: (<p className="no-margin">Use the <strong>Vimeo</strong> component to add a Vimeo video to your message.</p>)  
    // },
    // 'task-9': { id: 'task-9', type: templateIcons.deadline, name: 'Deadline', disabled: true },
    'task-10': { 
      id: 'task-10',
      order: 8, 
      type: templateIcons.location, 
      name: i18next.t('components.location'), 
      // info: (
      //   <p className="no-margin">
      //     <Trans 
      //       i18nKey="components.location.description"
      //       components={{
      //         strong: <strong/>
      //       }} />
      //   </p>
      // ) 
    },
    'task-17': { 
      id: 'task-17',
      order: 8,
      type: templateIcons.url, 
      name: i18next.t('components.url'),
    },
    // 'task-14': { 
    //   id: 'task-14',
    //   order: 9, 
    //   type: templateIcons.progressBar, 
    //   name: i18next.t('components.progressbar'), 
    //   info: (
    //     <p className="no-margin">
    //       <Trans 
    //         i18nKey="components.progressbar.description"
    //         components={{
    //           strong: <strong />
    //         }} />
    //     </p>
    //   )
    // },
    // 'task-12': { 
    //   id: 'task-12',
    //   order: 10,
    //   type: templateIcons.iframe, 
    //   name: i18next.t('components.webframe'), 
    //   info: (
    //     <p className="no-margin">
    //       <Trans 
    //         i18nKey="components.webframe.description"
    //         components={{
    //           strong: <strong/>
    //         }}/>
    //     </p>
    //   )
    // },
    // 'task-16': { id: 'task-16', type: templateIcons.date, name: 'Date & Time', disabled: true },
    
    // Saved Template
    // 'custom-task-14': { id: 'custom-task-14', type: 'custom-template', name: 'My Template', taskIds: ['task-5', 'task-6', 'task-7', 'task-8', 'task-9', 'task-10', 'task-15']},

  },
  columns: {
    'column-1': {
      id: 'column-1',
      // icon: <Icon name="configure" />,
      title: i18next.t("components.columns.template.title"),
      description: i18next.t("components.columns.template.description"),
      taskIds: []//['task-1', 'task-2', 'task-3', 'task-4', 'custom-task-14']
    },
    'column-2': {
      id: 'column-2',
      // icon: <Icon name="group" />,
      title: i18next.t("components.columns.component.title"),
      description: i18next.t("components.columns.component.description"),
      taskIds: [],//['task-5', 'task-6', 'task-7', 'task-8', 'task-9', 'task-10', 'task-15', 'task-11', 'task-12', 'task-13', 'task-14']
    },
    'column-4': {
      id: 'column-4',
      title: 'Content',
      description: "This is the container for all tasks that are being edited on builder area",
      taskIds: []
    },
    'column-5': {
      id: 'column-5',
      title: 'Edit Message',
      description: "This will be a container for all the components that are coming from the notice detail to be edited",
      taskIds: []
    }
  },

  // Facilitate reordering of the columns
  columnOrder: ['column-1', 'column-2']
}

const activeData = {
  tasks: { },
  columns: {
    'column-4': {
      id: 'column-4',
      title: 'Content',
      taskIds: []
    }
  },
}

const resetData = {
  content: {
    tasks: {},
    columns: {
      'column-4': {
        id: 'column-4',
        title: 'Content',
        taskIds: []
      }
    },
  },
  preview: {
    listItem: {
        icon: logo,
        title: null,
        description: "An amazing description",
        communityName: "Community Name",
        createdAt: moment().toISOString(),
    },
    messageView: {
        title: null,
        items: {}
    }
}
}

const templates = {
  favourites: {
    items: {
      'fav-1': { id: 'fav-1', type: 'notice', parent: 'favourite'},
      'fav-2': { id: 'fav-2', type: 'event',  parent: 'favourite'},
      'fav-3': { id: 'fav-3', type: 'file',   parent: 'favourite'},
      'fav-4': { id: 'fav-4', type: 'payment', parent: 'favourite'}
    }
  },
  general: [],
  payment: [],
  advanced: [],
  integrations: [],
}

const chartColors = {
  white: '#ffffff',
  deepBlue: '#00466c',
  orange: '#ff9800',
  blue: '#b9d4df',
  grey: '#e0e0e0',
  teal: '#00acac',
  purple: '#a60064',
  deepOrange: '#ff5230',
}

const colorPalette = [
  "#2A7EE2",
  "#95B2C0",
  "#EDAC02",
  "#1A759F",
  "#168AAD",
  "#34A0A4",
  "#52B69A",
  "#76C893",
  "#99D98C",
]

const chartData = {
  noticeBreakDown: {
    title: "TOTAL ENGAGEMENT",
    datasets: [
        {
            label: "Read",
            displayValue: "30%",
            percentage: 15,
            color: chartColors.white,
        },
        {
            label: "Unread",
            displayValue: "70%",
            percentage: 70,
            color: chartColors.blue,
        }
    ],
    totals: {
      label: "Total users sent to:",
      displayValue: 5224
    },
    showLegend: true
  },
  channelBreakDown: {
    title: "CHANNEL DISTRIBUTION",
    datasets: [
        {
            label: "App",
            displayValue: "80%",
            percentage: 80,
            color: chartColors.white,
        },
        {
            label: "SMS",
            displayValue: "15%",
            percentage: 15,
            color: chartColors.deepBlue,
        },
        {
            label: "Email",
            displayValue: "5%",
            percentage: 5,
            color: chartColors.orange,
        }
    ],
    showLegend: true
  },
  paymentSummary: {
    title: "PAYMENT SUMMARY",
    totals: {
      label: "Total paid:",
      displayValue: "R4600.00"
    },
    datasets: [
        {
            label: "Users paid:",
            displayValue: "23",
            percentage: 23,
            color: chartColors.deepBlue,
        },
        {
            label: "Users unpaid:",
            displayValue: "78",
            percentage: 78,
            color: chartColors.white,
        }
    ],
    // showTopLegend: true,
    showLegend: true,
  },
  fixed: {
    title: "Would you participate in the talent show?",
    label: "FIXED",
    totals: {
      label: "Total paid",
      displayValue: "R2270.00"
    },
    datasets: [
        {
            label: "Users paid:",
            displayValue: "14",
            percentage: 14,
            color: chartColors.deepBlue,
        },
        {
            label: "Users unpaid:",
            displayValue: "26",
            percentage: 26,
            color: chartColors.grey,
        }
    ],
    // showTopLegend: true,
    showLegend: true,
  },
  itemised: {
    label: "ITEMISED",
    totals: {
      label: "Total paid",
      displayValue: "R1955.00"
    },
    datasets: [
      {
        label: "Item #1",
        displayValue: "43",
        percentage: 43,
        color: chartColors.deepBlue,
      },
      {
        label: "Phoebe King",
        displayValue: "12",
        percentage: 12,
        color: chartColors.orange,
      },
      {
        label: "Nancy Stevenson",
        displayValue: "25",
        percentage: 25,
        color: chartColors.deepOrange,
      },
      {
        label: "Charlotte Ortega",
        displayValue: "20",
        percentage: 20,
        color: '#82d300',
      },
    ],
    // showTopLegend: true,
    showLegend: true,
  },
  open: {
    label: "OPEN",
    title: "Would you participate in the talent show?",
    totals: {
      label: "Total paid",
      displayValue: "R425.00"
    },
    data: {
        label: "User donations",
        displayValue: "2",
        percentage: 2,
        color: '#00acac',
    }
  },
  formResponses: {
    title: "TOTAL RESPONSE",
    totals: {
      label: "Total forms sent:",
      displayValue: 652
    },
    datasets: [
        {
            label: "Yes",
            displayValue: "25",
            percentage: 25,
            color: chartColors.deepOrange,
        },
        {
            label: "No",
            displayValue: "30",
            percentage: 30,
            color: chartColors.orange,
        },
        {
            label: "Maybe",
            displayValue: "50",
            percentage: 50,
            color: chartColors.white,
        }
    ],
    showTopLegend: false,
  },
  formItem: {
    title: "Would you participate in the talent show?",
    label: "Yes/No",
    datasets: [
        {
            label: "Yes",
            displayValue: "82",
            percentage: 82,
            color: chartColors.grey,
        },
        {
            label: "No",
            displayValue: "18",
            percentage: 18,
            color: chartColors.deepBlue,
        }
    ],
  },
  formItemTwo: {
    title: "Which of these teachers would you like to see in the show?",
    label: "Multiple Choice",
    datasets: [
        {
            label: "Micheal Schwartz",
            displayValue: "43",
            percentage: 43,
            color: chartColors.deepOrange,
        },
        {
            label: "Phoebe King",
            displayValue: "12",
            percentage: 12,
            color: chartColors.teal,
        },
        {
            label: "Nancy Stevenson",
            displayValue: "25",
            percentage: 25,
            color: chartColors.deepBlue,
        },
        {
            label: "Charlotte Ortega",
            displayValue: "20",
            percentage: 20,
            color: chartColors.orange,
        },
    ],
  },
  formItem3: {
    title: "Please upload photo of your child",
    label: "IMAGE UPLOAD",
    data: {
      label: "Total uploads",
      displayValue: 121,
      color: '#00acac',
    },
  },
  formItem4: {
    title: "Please upload your receipt of your child",
    label: "FILE UPLOAD",
    data: {
      label: "Total uploads",
      displayValue: 87
    },
  },
  formItem5: {
    title: "TOTAL VIEWS",
    totals: {
      label: "Total users sent to:",
      displayValue: 423
    },
    datasets: [
        {
            label: "New",
            displayValue: "15",
            percentage: 15,
            color: chartColors.white,
        },
        {
            label: "Existing",
            displayValue: "85",
            percentage: 85,
            color: chartColors.deepBlue,
        }
    ],
  },
  formItem6: {
    title: "VIEWS / DOWNLOADS",
    totals: {
      label: "Total users sent to:",
      displayValue: 244
    },
    datasets: [
        {
            label: "New",
            displayValue: "15",
            percentage: 15,
            color: chartColors.deepOrange,
        },
        {
            label: "Existing",
            displayValue: "25",
            percentage: 25,
            color: chartColors.deepBlue,
        },
        {
            label: "Remaining",
            displayValue: "80",
            percentage: 80,
            color: chartColors.white,
        }
    ],
  },
  formItem7: {
    title: "TOTAL VIEWS",
    totals: {
      label: "Total users sent to:",
      displayValue: 125
    },
    datasets: [
        {
            label: "New",
            displayValue: "45",
            percentage: 45,
            color: chartColors.white,
        },
        {
            label: "Remaining",
            displayValue: "55",
            percentage: 55,
            color: chartColors.teal,
        }
    ],
  },
}

export function createEventId() {
  return String(eventGuid++)
}

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  },
  {
    title: 'All Day Event',
    start: '2021-04-01',
  },
  {
    title: 'Long Event',
    start: '2021-04-07',
    end: '2021-04-10'
  },
  {
    id: 999,
    title: 'Repeating Event',
    start: '2022-03-09T16:00:00'
  },
  {
    id: 999,
    title: 'Repeating Event',
    start: '2022-03-16T16:00:00'
  },
  {
    title: 'Conference',
    start: '2021-04-11',
    end: '2021-04-13'
  },
  {
    title: 'Meeting',
    start: '2021-04-12T10:30:00',
    end: '2021-04-12T12:30:00'
  },
  {
    title: 'Lunch',
    start: '2021-04-12T12:00:00'
  },
  {
    title: 'Meeting',
    start: '2021-04-12T14:30:00'
  },
  {
    title: 'Happy Hour',
    start: '2021-04-12T17:30:00'
  },
  {
    title: 'Dinner',
    start: '2021-04-12T20:00:00'
  },
  {
    title: 'Birthday Party',
    start: '2021-04-13T07:00:00'
  }
]

export {
  initialData,
  activeData,
  resetData,
  templates,
  chartData,
  chartColors,
  colorPalette,
}
