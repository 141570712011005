import i18next from "i18next"
import { pickBy, identity, mapKeys, filter } from "lodash"
import { filterNulls, mailValidator, phoneValidator } from "../../utils/helper"

/**
 * @typedef Request
 * @property {String} communityId
 * @property {String} prefix
 * @property {String} firstName
 * @property {String} middleName
 * @property {String} lastName
 * @property {String} title
 * @property {String} birthday
 * @property {String} org
 * @property {Array} homeTelephones
 * @property {Array} workTelephones
 * @property {Array} homeEmails
 * @property {Array} workEmails
 * @property {String} note
 * @property {String} facebookUrl
 * @property {String} twitterUrl
 * @property {Number} order
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostCreateContactRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        communityId,
        prefix,
        firstName,
        middleName,
        lastName,
        title,
        birthday,
        org,
        homeTelephones,
        workTelephones,
        homeEmails,
        workEmails,
        note,
        facebookUrl,
        twitterUrl,
        order,
    }) {
        this.communityId = communityId
        this.prefix = prefix
        this.firstName = firstName
        this.middleName = middleName
        this.lastName = lastName
        this.title = title
        this.birthday = birthday
        this.org = org
        this.homeTelephones = homeTelephones
        this.workTelephones = workTelephones
        this.homeEmails = homeEmails
        this.workEmails = workEmails
        this.note = note
        this.facebookUrl = facebookUrl
        this.twitterUrl = twitterUrl
        this.order = order
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        const payload = {
            "community_id": this.communityId,
            "prefix": this.prefix,
            "first_name": this.firstName,
            "middle_name": this.middleName,
            "last_name": this.lastName,
            "title": this.title,
            "birthday": this.birthday,
            "org": this.org,
            "home_telephones": this.homeTelephones,
            "work_telephones": this.workTelephones,
            "home_emails": this.homeEmails,
            "work_emails": this.workEmails,
            "note": this.note,
            "facebook_url": this.facebookUrl,
            "twitter_url": this.twitterUrl,
            "order": this.order
        }

        return filterNulls(payload)
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {
        const errors = []

        if (!this.firstName) {
            errors.push({
                field: "firstName",
                isValid: false,
                message: i18next.t("requests.contacts.form.requiredFirstName")
            })
        }

        if (!this.lastName) {
            errors.push({
                field: "lastName",
                isValid: false,
                message: i18next.t("requests.contacts.form.requiredLastName")
            })
        }

        // Validate Emails
        if (this.workEmails.length > 0) {

            // Check that there is not at least one invalid email
            const hasErrors = filter(this.workEmails, email => !mailValidator.test(email))

            if (hasErrors.length > 0) {
                errors.push({
                    field: "workEmails",
                    isValid: false,
                    message: i18next.t("requests.contacts.form.invalidEmails")
                })
            }
        }
        
        if (this.homeEmails.length > 0) {
            
            // Check that there is not at least one invalid email
            const hasErrors = filter(this.homeEmails, email => !mailValidator.test(email))
            
            if (hasErrors.length > 0) {
                errors.push({
                    field: "homeEmails",
                    isValid: false,
                    message: i18next.t("requests.contacts.form.invalidEmails")
                })
            }
        }
        
        // Validate Phones
        if (this.workTelephones.length > 0) {
            
            // Check that there is not at least one invalid phone number
            const hasErrors = filter(this.workTelephones, phone => !phoneValidator(phone))

            if (hasErrors.length > 0) {
                errors.push({
                    field: "workPhones",
                    isValid: false,
                    message: i18next.t("requests.contacts.form.invalidPhones")
                })
            }
        }
        
        if (this.homeTelephones.length > 0) {
            
            // Check that there is not at least one invalid phone number
            const hasErrors = filter(this.homeTelephones, phone => !phoneValidator(phone))

            if (hasErrors.length > 0) {
                errors.push({
                    field: "homePhones",
                    isValid: false,
                    message: i18next.t("requests.contacts.form.invalidPhones")
                })
            }
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostCreateContactRequest