import React, { Component, Fragment, useMemo, useState } from "react"
import { Dropdown, Grid, Icon, Segment, Input, Button } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'
import { map } from "lodash"
import PropTypes from 'prop-types'
import { constants } from "../../constants"
import PagerDescriptor from "./PagerDescriptor"
import { t } from "i18next"

const propTypes = {
    perPageMenu: PropTypes.array,
    defaultValue: PropTypes.number,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPaginationIntervalChange: PropTypes.func,
    onPageChange: PropTypes.func,
    spacer: PropTypes.bool,
    totalCount: PropTypes.number,
    pageItemsCount: PropTypes.number,
}

const defaultProps = {
    perPageMenu: constants.PAGINATION_DEFAULTS,
    defaultValue: 25,
    totalPages: 0,
    currentPage: 0,
    spacer: false,
    totalCount: 0,
    pageItemsCount: 0,

    onPaginationIntervalChange: () => { },
    onPageChange: () => { },
}

const SuperTPagination = (props) => {

    const {
        perPageMenu,
        defaultValue,
        totalPages,
        currentPage,
        onPaginationIntervalChange,
        onPageChange,
        spacer,
        totalCount,
        pageItemsCount,
        defaultValue: perPage
    } = props

    const [state, setState] = useState({
        defaultValue: defaultValue,
    })

    const paginationIntervalChange = (e, { value }) => {
        onPaginationIntervalChange(value)
        setState({
            ...state,
            defaultValue: value
        })
    }

    const onIncrementPage = () => {
        onPageChange(currentPage + 1)
    }

    const onDecrementPage = () => {
        onPageChange(currentPage - 1)
    }
    
    const handleRefresh = () => {
        onPageChange(currentPage)
    }

    return (
        <div className="pagination">
            <div className="input-container">
                {/* <label className='label' htmlFor="name-input">&nbsp;</label> */}
                {spacer && <label className='label'>&nbsp;</label>}
                <Grid>
                    <Grid.Row columns={2}>
                        <div className="w-100 vertical-align">
                            <div className="page-nav">
                                <Button basic size='tiny' icon="redo" title={t('pagination.super.table.reload')} onClick={handleRefresh} />
                                <div className="primary-input">
                                    <Dropdown
                                        text=" "
                                        icon={
                                            <Fragment>
                                                <Trans
                                                    i18nKey="pagination.super.table.per.pager"
                                                    components={{
                                                        b: <b className="primary-color-text" />
                                                    }}
                                                    values={{
                                                        perPage: state.defaultValue
                                                    }} />
                                                <Icon name='dropdown'/>
                                            </Fragment>
                                        }
                                        onChange={paginationIntervalChange.bind(this)}
                                        inline
                                        options={perPageMenu}
                                        defaultValue={defaultValue ? defaultValue : perPageMenu[0].value} />
                                </div>
                                <div className="pagers">
                                    <PagerDescriptor
                                        perPage={perPage}
                                        totalPages={totalPages}
                                        totalCount={totalCount}
                                        currentPage={currentPage} />
                                    &nbsp;&nbsp;
                                    <Icon
                                        name='left chevron'
                                        className={`${(currentPage > 1) ? '' : 'disabled'}`}
                                        onClick={onDecrementPage.bind(this)} />
                                    &nbsp;&nbsp;
                                    <Icon 
                                        name='right chevron'
                                        className={`${(currentPage < totalPages) ? '' : 'disabled'}`}
                                        onClick={onIncrementPage.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </Grid.Row>
                </Grid>
            </div>
        </div>
    )

}

SuperTPagination.propTypes = propTypes
SuperTPagination.defaultProps = defaultProps

export default SuperTPagination