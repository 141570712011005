import { 
    createAction
} from 'redux-actions'

import { actions as types, constants }      from '../constants'
import { apiService, uploadService } from '../api'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'
import { appendCommunity, matchLink } from '../utils/helper'
import { delay } from 'lodash'
import i18next from 'i18next'

const { 
    AUTH_START, 
    AUTH_END_LOADING, 
    AUTH_ERROR, 
    AUTH_ERROR_UPDATE, 
    AUTH_SAVE_PROFILE, 
    AUTH_SAVE_SSO_USER, 
    AUTH_FINISH_INIT, 
    AUTH_SAVE_RETURN_URL, 
    AUTH_CLEAR_MESSAGES, 
    AUTH_SUCCESS,
    AUTH_SAVE_CONTACTS,
    AUTH_SAVE_ACCOUNT,
    AUTH_SAVE_BILLING,
    AUTH_CLEAR_SETTINGS
} = types

export const authActions = {

    /**
     * Initialize options
     */
    start: createAction(AUTH_START),
    error: createAction(AUTH_ERROR),
    stop: createAction(AUTH_END_LOADING),
    errorUpdate: createAction(AUTH_ERROR_UPDATE),
    saveProfile: createAction(AUTH_SAVE_PROFILE),
    saveSSOUser: createAction(AUTH_SAVE_SSO_USER),
    saveReturnUrl: createAction(AUTH_SAVE_RETURN_URL),
    finishInitialise: createAction(AUTH_FINISH_INIT),
    saveContacts: createAction(AUTH_SAVE_CONTACTS),
    saveAccount: createAction(AUTH_SAVE_ACCOUNT),
    saveBilling: createAction(AUTH_SAVE_BILLING),

    /**
     * Show success messages
     */
    success: createAction(AUTH_SUCCESS),

    /**
     * Clear errors and messages
     */
    clearMessages: createAction(AUTH_CLEAR_MESSAGES),
    
    /**
     * Clear settings
     */
    clearSettings: createAction(AUTH_CLEAR_SETTINGS),

    doGetUser () {
        return apiService.getUser()
    },
    
    /**
     * Update user profile details
     * 
     * @param {*} params 
     * @returns 
     */
    postUpdateProfile (params) {
        return (dispatch) => apiService.postUpdateProfile(params)
            .then( _ => apiService.registerNewUpdate())
            .then( _ => {
                dispatch(this.success({
                    header: 'authActions.updateProfile.success.header',
                    content: 'authActions.updateProfile.success.message',
                    refreshData: true
                }))
                // window.location.href = appendCommunity(
                //     params.communityId,
                //     routes.private.UPDATE_DETAILS
                // )

            }).catch(error => {
                dispatch(this.finishInitialise())
                dispatch(this.errorUpdate(error))
            })
    },
    
    postUpdatePhone (params) {
        return (dispatch) => apiService.postUpdatePhone(params)
            .then( _ => {
                // Navigate to show OTP page
                dispatch(this.success({
                    backLink: appendCommunity(
                        params.communityId,
                        routes.private.USER_DETAIL_UPDATE_PHONE
                    ),
                    backTitle: i18next.t('ProfileDetails.edit.phone.pageTitle'),
                    activeTitle: i18next.t('ProfileDetails.page.otp.caption'),
                    emailOrPhone: params.phoneNumber,
                    source: "phone"
                }))
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.USER_DETAIL_UPDATE_PHONE_OTP
                )))
            }).catch(error => {
                dispatch(this.errorUpdate(error))
            })
    },
    
    postUpdateEmail (params) {
        return (dispatch) => apiService.postUpdateEmail(params)
            .then( _ => {
                // Navigate to show OTP page
                dispatch(this.success({
                    backLink: appendCommunity(
                        params.communityId,
                        routes.private.USER_DETAIL_UPDATE_EMAIL
                    ),
                    backTitle: i18next.t('ProfileDetails.updateEmail.caption'),
                    activeTitle: i18next.t('ProfileDetails.page.otp.caption'),
                    emailOrPhone: params.email,
                    source: "email"
                }))
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.USER_DETAIL_UPDATE_EMAIL_OTP
                )))
            }).catch(error => {
                dispatch(this.errorUpdate(error))
            })
    },
    
    postValidateEmail (params) {
        return (dispatch) => apiService.postValidateEmail(params)
            .then( _ => apiService.registerNewUpdate())
            .then( response => {

                dispatch(this.success({
                    header: 'authActions.updateEmail.success.header',
                    content: 'authActions.updateEmail.success.message',
                }))
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.UPDATE_DETAILS
                )))
            }).catch(error => {
                dispatch(this.errorUpdate(error))
            })
    },
    
    postValidatePhone (params) {
        return (dispatch) => apiService.postValidatePhone(params)
            .then( _ => apiService.registerNewUpdate())
            .then( response => {

                dispatch(this.success({
                    header: 'authActions.updatePhone.success.header',
                    content: 'authActions.updatePhone.success.message',
                }))
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.UPDATE_DETAILS
                )))
            }).catch(error => {
                dispatch(this.errorUpdate(error))
            })
    },

    doGetSSOUser (params) {
        return (dispatch, getState) => apiService.getUser()
            .then(user => {
                dispatch(this.saveSSOUser(JSON.parse(user?.toStorageString() ?? '{}')))

                if (user) {
                    // Save token
                    apiService._saveToken(user.access_token)
                    dispatch(this.doGetProfile(params))
                } else {
                    // Lets check here if the user is fully logged out
                    apiService.getMeProfile(params).then(response => {
                        dispatch(this.saveProfile(response.data))
                    }).catch(() => dispatch(this.finishInitialise()))
                }
            })
            .catch(error => {
                dispatch(this.finishInitialise())
                dispatch(this.error(error))
            })
    },

    doLogout (returnUrl = "") {

        // Clean Upload Service
        uploadService.logout()

        return (dispatch, getState) => apiService.logout()
            .then(() => {
                dispatch(this.clearLogins())
                
                if (returnUrl) {
                    dispatch(authActions.saveReturnUrl(returnUrl))
                }
            })
            .catch((e) => {
                dispatch(this.clearLogins())
            })
    },
    
    doLogin () {
        return (dispatch, getState) => apiService.login()
            .catch(e => {})
    },
    
    doRenewToken () {
        return (dispatch, getState) => apiService.renewToken()
    },
    
    doGetProfile (params) {
        return (dispatch, getState) => apiService.registerOrLogin(params)
            .then(response => {
                
                // Show no link when there are no linked communities
                if ([].concat(response.data.other_communities).length === 0 && 
                    !matchLink({pathname: window.location.pathname, patterns: [
                        routes.private.INVITE
                    ]})) {
                    dispatch(push(routes.private.NO_LINK))
                }

                dispatch(this.saveProfile(response.data))
            })
            .catch(error => {
                dispatch(this.finishInitialise())

                // When the user needs to update their login details
                if (error.response && error.response.data && [error.response.data.message].includes("details_required")) {
                    dispatch(push(routes.private.UPDATE_DETAILS_NO_COMM))
                } else {
                    dispatch(this.error(error))
                }
            })
    },
    
    doGetMeProfile (params) {
        return (dispatch, getState) => apiService.getMeProfile(params)
            .then(response => {
                dispatch(this.saveProfile(response.data))
            })
            .catch(error => {
                dispatch(this.finishInitialise())
                dispatch(this.error(error))
            })
    },

    clearLogins () {
        return (dispatch, getState) => {
            apiService.cleanLogout()
            
            // Keep the chosen language
            const lng = localStorage.i18nextLng || 'en'
            
            // Keep the selected community
            const communityId = localStorage.getItem(constants.STORAGE_SELECTED_COMMUNITY)
            
            localStorage.clear()

            localStorage.setItem('i18nextLng', lng)
            localStorage.setItem(constants.STORAGE_SELECTED_COMMUNITY, communityId)
        }
    },
    
    /**
     * Fetch contacts for this authenticated user
     * 
     * @param {JSON} params 
     */
    fetchContacts (params) {
        return (dispatch, getState) => apiService.getContacts(params)
            .then(response => {
                dispatch(this.saveContacts(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch community for this authenticated user
     * 
     * @param {JSON} params 
     */
    getCommunity (params) {
        return (dispatch, getState) => apiService.getCommunity(params)
            .then(response => {
                dispatch(this.saveAccount(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Update the community data 
     * 
     * @param {JSON} params 
     */
    postUpdateCommunity (params) {
        return (dispatch, getState) => apiService.postUpdateCommunity(params)
            .then(response => {
                dispatch(this.success({
                    header: 'authActions.updateCommunity.success.header',
                    content: 'authActions.updateCommunity.success.message',
                    refreshData: true
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
   
    /**
     * Fetch billing for this authenticated user
     * 
     * @param {JSON} params 
     */
    fetchBilling (params) {
        return (dispatch, getState) => apiService.getBilling(params)
            .then(response => {
                dispatch(this.saveBilling(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
} 