/**
 * This is where we are going to include all of our reducers and combine them so that everything is nice and neat
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import auth from './authReducer'
import table from './tableReducer'
import users from './usersReducer'
import events from './eventsReducer'
import notices from './noticesReducer'
import tiering from './tieringReducer'
import builder from './builderReducer'
import channels from './channelsReducer'
import calendars from './calendarsReducer'
import contacts from './contactsReducer'
import resources from './resourcesReducer'
import navigation from './navigationReducer'
import contactCategories from './contactCategoriesReducer'

const createRootReducer = (history) => combineReducers({
    auth,
    table,
    users,
    events,
    notices,
    tiering,
    builder,
    channels,
    calendars,
    contacts,
    resources,
    navigation,
    contactCategories,
    router: connectRouter(history),
})

export default createRootReducer