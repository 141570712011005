import React, { Fragment, useEffect, useState } from 'react'
import logo from '../images/d6_logo_white.svg'
import logoAnim from '../images/d6_logo.riv'

import { apiService } from '../api'
import { Button, Grid, Loader } from 'semantic-ui-react'
import { UserManager } from 'oidc-client'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { authActions } from '../actions/authActions'
import LogoTeal from './partials/LogoTeal'

const LoginRedirect = () => {

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [date, setDate] = useState(new Date().toLocaleDateString('en', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  }))
  const [errorType, setErrorType] = useState(null)

  // Add the redirect URL to the address bar
  useEffect(() => {

    // Add loading class to add image background
    document.body.classList.add('loading')

    const userManager = apiService.getManager()
    userManager.signinRedirectCallback()
      .then(() => {

        if (localStorage.getItem("AUTH_SAVE_RETURN_URL")) {
          window.location = localStorage.getItem("AUTH_SAVE_RETURN_URL")
          // Once Used, clear the url
          localStorage.removeItem("AUTH_SAVE_RETURN_URL")
        } else {
          window.location = "/";
        }

      }).catch(e => {

        if (e.message && (e.message.includes('iat is in the future') || e.message.includes('iat is in the past') || e.message.includes('exp is in the future') || e.message.includes('exp is in the past'))) {
          setErrorType(2)
        } else {
          setErrorType(1)
        }
        console.error(e)
      })

    return () => {
      document.body.classList.remove('loading')
    }
  }, [])

  return (
    <div className='ui middle aligned center aligned grid data-init-screen'>
      <Loader
        inverted
        active={true}
        content={
          <Fragment>
            <div className='rive-loader error'>
              <LogoTeal />
            </div>
            <div className='error-explanation'>
              {errorType === 1 && <p className='error-message'>{t("error.errorOccurredCrash")}</p>}
              {errorType === 2 && (
                <div id="error-container" className="s-callback">
                  <div id="error-container-message" className="error-message">
                      <div className="zone-1">
                          <p>There is an issue with the date and time settings of your device. We detected your date and time to be: <strong>{date}</strong>.</p>
                      </div>
                      <div className="zone-2">
                          <p className='no-margin'>If these settings are incorrect:</p>
                          <ol>
                              <li>Update your date and time settings</li>
                              <li>Click the logout button</li>
                              <li>Open https://portal.d6.co.za in a new tab to login</li>
                              <li>If the issue still persists, contact <a href="mailto:support@d6.co.za">support@d6.co.za</a></li>
                          </ol>
                      </div>
                  </div>
                </div>
              )}
              {errorType !== null && (
                <Grid className='mg-top-10'>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Button className='primary' onClick={() => dispatch(authActions.doLogout())}>{t('general.logout')}</Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </div>
          </Fragment>
        } />
    </div>
  )
}

export default LoginRedirect