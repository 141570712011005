import { push } from 'connected-react-router'
import React, { Fragment, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import i18next from '../i18n'
import homePlaceholder from '../images/home_placeholder.png'
import routes from '../routes/route-constants'
import { appendCommunity } from '../utils/helper'

export default function Blocked() {
    
    const navigation = useSelector(state => state.navigation)
    const dispatch = useDispatch()

    // Navigate to default home if we are trying to manually navigate here on a community that is not blocked
    useEffect(() => {
        
        if (navigation.community && navigation.community.portal_accessible) {
            dispatch(push(
                appendCommunity(
                    navigation.communityId,
                    routes.private.HOME_COMM
                )
            ))
        }
    }, [navigation.communityId])
    
    useEffect(() => {
        // We need to add a blocked class to the body so we can customize the look and feel
        document.body.classList.add('police-tape')
        
        return () => {
            document.body.classList.remove('police-tape')
        }
    }, [])

    const handleOpenZohoSales = () => {
        window.open(routes.public.WEB_FORM, "_blank")
    }
    
    const _renderPoliceTape = () => {
        return (
            <Fragment>
                <div className='coming-soon vertical-align' style={{backgroundImage: `url(${homePlaceholder})`}}>
                    {/* <h1 className="page-caption">Coming Soon</h1> */}
                </div>

                <h1 className="police-tape police-tape--1">
                    <Trans
                        i18nKey="blocker.contactAdmin"
                        components={{
                            a: <a onClick={handleOpenZohoSales} className='help-link' />
                        }} />
                </h1>
                <h1 className="police-tape police-tape--2">{i18next.t("blocker.contactAdmin.blocked")}</h1>
            </Fragment>
        )
    }

    return (
        _renderPoliceTape()
    )
}
