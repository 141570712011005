import { isEmpty } from "lodash"

class DetachUserRequest {
    
    constructor({
        communityId
    }) {
        this.communityId = communityId 
    }

    getCommunityId = () => this.communityId

    isValid = () => {
        return !isEmpty(this.getCommunityId())
    }

    getPayload = () => {
        return {
            "community_id": this.getCommunityId(),
        }
    }

}

export default DetachUserRequest