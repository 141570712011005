import { actions, calendarConstants, constants } from '../constants'
import { every, find, first, map, orderBy, size, take } from 'lodash'

import { formatEventDates, getCalendarStrategy, getCalendarStrategyByName, toTitleCase } from '../utils/helper'

import moment from "moment"

import i18next from 'i18next'

const { EVENT } = actions

const initialState = {
    events: [],
    statusesLatest: null,
    statusesTransformed: [],
    loading: false,
    detail: null,
    filters: {},
    pagination: {},
    error: null,
    messages: null,
    listViewLoading: false,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case EVENT.RESET: {
            return {
                ...state,
                events: [],
                loading: false,
                listViewLoading: false,
                detail: null,
                filters: {},
                pagination: {},
                error: null,
                eCode: null,
                messages: null,
            }
        }
        case EVENT.START: {
            return { ...state, loading: true }
        }
        case EVENT.START_LIST_VIEW: {
            return { ...state, listViewLoading: true }
        }
        case EVENT.CLEAR_MESSAGES: {
            return { ...state, messages: null, errors: null, errorCode: null }
        }
        case EVENT.RESET_LIST_VIEW_EVENTS: {
            return { 
                ...state, 
                listViewLoading: false, 
                eventsDropDownTransformed: null, 
                eventsListViewTransformed: null,
            }
        }
        case EVENT.ERROR: {
            let error = action.payload.response || {}
            const errorCode = error?.data?.code ?? null

            if (navigator.onLine) {
                error = error.data?.message ?? error.data ?? "error.errorOccurred"
            } else {
                error = "general.errors.network.message"
            }

            return {
                ...state,
                loading: false,
                listViewLoading: false,
                errors: error,
                errorCode: errorCode,
                messages: null,
            }
        }
        case EVENT.SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                messages: action.payload,
            }
        }
        case EVENT.SAVE: {

            return {
                ...state,
                loading: false,
                events: action.payload.data,
                eventsTransformed: map(action.payload.data, c => {

                    const isAllDayMultiDay = c.all_day && (moment(c.end_date).diff(moment(c.start_date), 'days') > 1)
                    
                    /**
                     * @link https://fullcalendar.io/docs/event-parsing
                     * 
                     * Note: This value is exclusive. For example, an event with the end of 2018-09-03 will appear to span through 2018-09-02 but end before the start of 2018-09-03. See how events are are parsed from a plain object for further details.
                     */
                    const fcEndDate = isAllDayMultiDay ? moment(c.all_day_end_date).add(1, 'day').format() : c.end_date
                    
                    return {
                        id: c.parent_id ?? c.id, // Here we are trying to tell the Calendar to group events which are recurring by letting a child recurring event reference its parent
                        title: c.title,
                        end: fcEndDate,
                        allDay: c.all_day,
                        _calendar_feed_id: c.calendar_feed_id,
                        start: c.all_day ? c.all_day_start_date : c.start_date,
                        _click_meta: {
                            id: c.id,
                            title: c.title,
                            parent_id: c.parent_id,
                            read_only: c.is_readonly,
                            extendedProps: {
                                _calendar_feed_id: c.calendar_feed_id
                            }
                        }
                    }
                }),
                eventsListViewTransformed: map(action.payload.data, c => {
                    
                    return {
                        id: c.id,
                        name: c.title,
                        start_date: moment(c.start_date).format('DD MMM Y'),
                        start_time: moment(c.start_date).format('h:mm'),
                        is_multiday: c.all_day,
                        is_repeat: c.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                        isRepeating: c.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                        source: c.source,
                        channels: {
                            data: map(c.channels, 'name')
                        },
                        is_locked: c.is_locked,
                        locked_reason: c.locked_reason,
                        _click_meta: {
                            id: c.id,
                            title: c.title, 
                            end: c.end_date,
                            start: c.start_date,
                            read_only: c.is_readonly,
                            extendedProps: {
                                _calendar_feed_id: c.calendar_feed_id
                            }
                        }
                    }
                }),
                pagination: action.payload.pagination
            }
        }
        case EVENT.SAVE_LIST_VIEW_EVENTS: {

            return {
                ...state,
                listViewLoading: false,
                totalSearchResults: action.payload?.pagination?.total ?? 0,
                eventsDropDownTransformed: map(take(action.payload.data, 5), c => {
                    
                    let mergedTimes = moment(c.start_date).format('LT')
            
                    if (c.end_date) {
                        mergedTimes = mergedTimes.concat(` - ${moment(c.end_date).format('LT')}`)
                    }

                    return {
                        id: c.id,
                        title: c.title,
                        location: c.location,
                        day: moment(c.start_date).format('dddd MMM DD'),
                        start_end_times: mergedTimes,
                        image: {
                            src: getCalendarStrategyByName(c.source).icon
                        },
                        description: c.notes,
                        is_locked: c.is_locked,
                        locked_reason: c.locked_reason,
                        _click_meta: {
                            id: c.id,
                            title: c.title, 
                            end: c.end_date,
                            start: c.start_date,
                            read_only: c.is_readonly,
                            extendedProps: {
                                _calendar_feed_id: c.calendar_feed_id
                            }
                        }
                    }
                }),
                eventsListViewTransformed: map(action.payload.data, c => {
                    
                    return {
                        id: c.id,
                        name: c.title,
                        start_date: moment(c.start_date).format('DD MMM Y'),
                        start_time: moment(c.start_date).format('h:mm'),
                        is_multiday: c.all_day,
                        is_repeat: c.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                        isRepeating: c.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                        source: c.source,
                        channels: {
                            data: map(c.channels, 'name')
                        },
                        is_locked: c.is_locked,
                        locked_reason: c.locked_reason,
                        _click_meta: {
                            id: c.id,
                            title: c.title, 
                            end: c.end_date,
                            start: c.start_date,
                            read_only: c.is_readonly,
                            extendedProps: {
                                _calendar_feed_id: c.calendar_feed_id
                            }
                        },
                        actions: [
                            constants.MENU_ACTION_DELETE,
                        ]
                    }
                }),
                pagination: action.payload.pagination
            }
        }
        case EVENT.SAVE_STATUSES: {

            const syncInProgress = find(action.payload, {is_syncing: true})
            const transformed =  map(action.payload, c => {
                return {
                    ...c,
                    // When there is a sync in progress, we should keep refreshing statuses on behalf of the users every 1minute
                    refresh_interval: syncInProgress ? 6000 : null, 
                    is_syncing_all: syncInProgress ?? false,
                    synced_at_display: c.synced_at ? moment(c.synced_at).format('L LT') : null,
                    sync_failed_at: c.sync_failed_at ? moment(c.sync_failed_at).format('L LT') : null,
                }
            })

            return {
                ...state,
                loading: false,
                statusesLatest: first(orderBy(transformed, ['synced_at'], 'asc')),
                statusesTransformed: transformed,
            }
        }
        case EVENT.SAVE_EVENT_DETAILS: {
            
            const _day = moment(action.payload.start_date).format("dddd")
            const repeatTextSlim = calendarConstants.EVENT_REPEAT_OPTIONS[action.payload.repeat] ?? calendarConstants.EVENT_REPEAT_OPTIONS.NEVER

            return {
                ...state,
                loading: false,
                detail: {
                    ...action.payload,
                    has_reminder: (action.payload.remind_minutes > 0),
                    is_repeating: action.payload.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                    isCustomRepeat: action.payload.repeat === calendarConstants.EVENT_REPEAT_OPTIONS.CUSTOM.key,
                    repeatText: toTitleCase(action.payload.recurrence_string ?? ""),
                    repeatTextSlim: repeatTextSlim.description.replace("{DAY}", _day),
                    source: getCalendarStrategy(action.payload?.sync_account.strategy ?? calendarConstants.STRATEGIES.d6.name),
                    channels: action.payload.channels,
                    persons: map(action.payload.persons, c => {
                        return {
                            ...c,
                            name: `${c.first_name} ${c.last_name}`
                        }
                    }),
                    formattedDateString: formatEventDates(moment(action.payload.start_date), action.payload.end_date ? moment(action.payload.end_date) : null, action.payload.all_day),
                    noticesTransformed: map(action.payload.notices, r => {
                        const channels = {color: 'color-primary-light', name: i18next.t("notices.manage.table.pills.channels_plural"), items: map(r.channels, 'name')}
                        const persons = {color: 'color-primary-light', name: i18next.t("notices.manage.table.pills.recipients_plural"), items: map(r.persons, 'name')}
                    
                        return {
                            id: r.id,
                            title: r.title,
                            author: r.sent_by,
                            channels: {channels, persons},
                            actions: [
                                constants.MENU_ACTION_VIEW
                            ]
                        }
                    }),
                },
            }
        }
        case EVENT.SAVE_FILTERS: {
            
            return {
                ...state, 
                loading: false, 
                filters: {
                    channels: map(action.payload.channels, r => {return {key: r.id, value: r.id, text: r.name}}),
                },
            }
        }
        case EVENT.CLEAR_EVENT_DETAILS: {
            return {
                ...state,
                loading: false,
                detail: null,
            }
        }
        default: { }
    }

    return state
}