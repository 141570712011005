import { dropEmptyKeys } from "../../utils/helper"

class ExportMembersRequest {
    
    constructor({
        sort, 
        page,
        search,
        tagIds,
        hasApp,
        format,
        channelIds,
        isFollower,
        communityId,
        exportFormat,
        personIds,
    }) {
        this.page = page
        this.sort = sort
        this.format = format
        this.tagIds = tagIds
        this.hasApp = hasApp
        this.search = search
        this.personIds = personIds
        this.isFollower = isFollower
        this.channelIds = channelIds
        this.communityId = communityId
        this.exportFormat = exportFormat
    }

    getExportBySelectionPayload = () => {
        
        const payload = {
            "community_id": this.communityId,
            "person_ids": this.personIds,
            "format": this.format,
            "type": this.exportFormat,
        }

        return dropEmptyKeys(payload)
    }

    getExportByFiltersPayload = () => {
        
        const payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "format": this.format,
            "community_id": this.communityId,
            "tag_ids": [].concat(this.tagIds).join(","),
            "channel_ids": [].concat(this.channelIds).join(","),
            "type": this.exportFormat,
        }

        if (this.hasApp !== -1) {
            payload["has_app"] = this.hasApp
        }
        
        if (this.isFollower !== -1) {
            payload["is_public_follower"] = this.isFollower
        }

        return dropEmptyKeys(payload)
    }
    
}

export const EXPORT_BY_SELECTION = 1
export const EXPORT_BY_FILTERS = 2
export default ExportMembersRequest