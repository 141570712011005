import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Input } from 'semantic-ui-react'
import { debounce, deburr } from 'lodash'
import i18next from 'i18next'

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    eventSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    interval: PropTypes.number,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    escapeSpecialCharacters: PropTypes.bool,

    // Functions
    onChange: PropTypes.func,
}

const defaultProps = {
    title: i18next.t("general.input.search"),
    name: "search",
    loading: false,
    escapeSpecialCharacters: true,
    interval: 1000,
    value: "",
    defaultValue: "",
    eventSearch: false,
    ignoreEncoding: false,
    placeholder: i18next.t("general.input.searchPlaceholder"),

    onChange: (value) => {}
}

const DebouncedSearchInput = props => {

    const {
        name,
        title,
        loading,
        interval,
        onChange,
        placeholder,
        defaultValue,
        eventSearch,
        ignoreEncoding,
        escapeSpecialCharacters,
        autoFocus,
        autoSelect,
    } = props

    let decodedDefaultValue = defaultValue
    
    const inputRef = useRef(null)

    try {
        decodedDefaultValue = decodeURIComponent(defaultValue ?? '')
    } catch (error) {
        console.log('URI Component not decodable: ' + defaultValue)
    }

    // const decodedDefaultValue = defaultValue
    const [state, setState] = useState(decodedDefaultValue)

    const handleSearchChange = (e) => {
        // const eventData = { id: e.id, target: e.target };
        delayedHandleChange(e);
    }

    const delayedHandleChange = debounce(jsEvent => {
        const target = jsEvent.target
        let value = target.value

        if (escapeSpecialCharacters && !ignoreEncoding) {
            value = encodeURIComponent(value)
        }

        setState(value)
        
        if (onChange) {
            
            // When Searching for calendar events we need the original JS Event so SemanticUI can be happy
            if (eventSearch) {
                onChange(jsEvent, {value})
                return;
            }

            onChange(value)
        }

    }, interval)

    const handleSelectionOnClick = () => {
        if (inputRef.current && autoSelect) {
          inputRef.current.select()
        }
    }

    useEffect(() => {
      if (inputRef.current && autoFocus) {
        inputRef.current.focus()
      }
    }, [autoFocus])
    
    return (
        <div className="input-container theme-color">
            <label className='label'>{title}</label>
            <Input 
                ref={inputRef}
                icon="search"
                placeholder={placeholder}
                name={name} 
                loading={loading}
                defaultValue={state}
                autoComplete="off"
                onClick={handleSelectionOnClick}
                onChange={ handleSearchChange } />
        </div>
    )
}

DebouncedSearchInput.propTypes = propTypes
DebouncedSearchInput.defaultProps = defaultProps

export default DebouncedSearchInput
