import i18next from "i18next"
import { pickBy, identity, mapKeys } from "lodash"
import { filterNulls, mailValidator } from "../../utils/helper"

/**
 * 
 * @typedef Request
 * @property {String} name
 * @property {Number|undefined} order
 * @property {String|undefined} email
 * @property {String|undefined} description
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostCreateContactCategoryRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        name,
        order,
        email,
        communityId,
        description,
    }) {
        this.name = name
        this.order = order
        this.email = email
        this.communityId = communityId
        this.description = description
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        let payload = {
            "name": this.name,
            "order": this.order,
            "email": this.email,
            "description": this.description,
            "community_id": this.communityId,
        }

        return filterNulls(payload)
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        if (!this.name) {
            errors.push({
                field: "name",
                isValid: false,
                message: i18next.t("requests.categories.form.requiredName")
            })
        }
        
        if (this.email) {
            
            if (!mailValidator.test(this.email)) {
                errors.push({
                    field: "email",
                    isValid: false,
                    message: i18next.t("requests.categories.form.invalidEmail")
                })
            }
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostCreateContactCategoryRequest