import { debounce, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from "react"
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete'
import i18next from 'i18next'
import Bugsnag from "@bugsnag/browser"

const propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.oneOf([null])]),
  title: PropTypes.string,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  interval: PropTypes.number,

  // Functions
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
}

const defaultProps = {
  defaultValue: "",
  title: i18next.t('SearchLocationInput.title'),
  name: "search",
  className: "",
  loading: false,
  interval: 1000,
  placeholder: i18next.t('general.input.searchPlaceholder'),
  onChange: null
}

// To keep our timer state
let timerInterval = null

const SearchLocationInput = (props) => {
  const {
      name,
      title,
      interval,
      onChange,
      className,
      placeholder,
      defaultValue,
  } = props
  
  const placesPickerRef = useRef(null)

  const [data, setData] = useState(defaultValue ?? "")
  const [loading, setLoading] = useState(null)
  
  useEffect(() => {
    // on Mount, we will initiate a timer which will reset the session token after every [n] minutes
    timerInterval = setInterval(() => {
      refresh()
    }, 10 * 60 * 1000)

    return () => {
      clearInterval(timerInterval)
      timerInterval = null
    }
  }, [])

  // On Mount
  useEffect(() => {
    
    // We should use the Geocoder to get the address meta-data from the address string
    const { value } = data

    if (isEmpty(value) && !isEmpty(defaultValue)) {
      setLoading(true) // Show progress indicator while geocoding the address
      _handleGeocodeAddress(defaultValue)
    }

  }, [defaultValue])


  // On select of place autocomplete selection, fire callbacks
  useEffect(() => {
    
    if (!data) return
    if (!data?.value) return

    const { place_id, init } = data.value

    if (onChange && !init) {

      // Investigate best way to achieve this incase the demand of the Location component stifles
      geocodeByPlaceId(place_id)
        .then(results => getLatLng(results[0]))
        .then(({lat, lng}) => onChange({
          address: data.label, 
          location: {lat, lng},
          original: data
        })).catch(error => {
          // TODO: Used for debugging location options not showing on search
          Bugsnag.notify({
            name: "GooglePlacesAutocomplete:geocodeByPlaceId",
            errorMessage: JSON.stringify(error),
          })
        })
    }

  }, [data])


  const _handleGeocodeAddress = (address) => {
    
    if (!address) return;

    geocodeByAddress(address)
      .then(results => {
        const { place_id } = results[0]
        return geocodeByPlaceId(place_id)
      })
      .then(results => Promise.resolve(Promise.all([results[0], getLatLng(results[0])])))
      .then(data => {
        const [results, location] = data
        const { lat, lng } = location
        const { formatted_address } = results
        
        const payload = {
          address: formatted_address, 
          location: {lat, lng},
          original: {
            label: formatted_address,
            value: {
              ...results,
              init: true,
              description: formatted_address
            },
          }
        }

        onChange(payload)
        setData(payload.original)
        setLoading(false)
      })
      .catch(error => {
        
        // TODO: Used for debugging location options not showing on search
        Bugsnag.notify({
          name: "GooglePlacesAutocomplete:geocodeByAddress",
          errorMessage: JSON.stringify(error),
        })

        setLoading(false)
      })
  }

  const handleLoadFailed = (error) => {

    // TODO: Used for debugging location options not showing on search
    Bugsnag.notify({
        name: "GooglePlacesAutocomplete:loadFailed",
        errorMessage: JSON.stringify(error),
    })
  }


  const refresh = () => {
    if (placesPickerRef && placesPickerRef.current) {
      placesPickerRef.current.refreshSessionToken()
    }
  }

  return (
    <div className={`${className} input-container theme-color place-picker`.concat(loading ? 'no-select' : '')}>
      <label className='label'>{loading ? i18next.t('general.loading') : title}</label>
      <GooglePlacesAutocomplete
        debounce={interval}
        selectProps={{
          value: data,
          placeholder,
          onChange: setData,
        }}
        ref={placesPickerRef}
        onLoadFailed={handleLoadFailed}
        withSessionToken={true} />
    </div>
  )
}

SearchLocationInput.propTypes = propTypes
SearchLocationInput.defaultProps = defaultProps

export default SearchLocationInput