import React, { useState, Fragment, useMemo } from 'react'
import { Grid, Checkbox, List, Image, Icon, Popup } from 'semantic-ui-react'
import Switch from 'react-switch'
import { first, map } from 'lodash'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

const CandidateItem = props => {
	const { option } = props

	const previewImage = first(map(option.files ?? []))?.thumb

	return (
		<div className="vote-component-item">
			<Checkbox />
			<div className="candidate-item">
				{previewImage && <Image avatar src={previewImage} />}
				<span className="candidate-name">
					<strong>{option?.title ?? ''}</strong>
				</span>
				<div className="candidate-info">
					<Icon className="info circle info-icon community-roles" />
				</div>
			</div>
		</div>
	)
}

export default function CandidatesPreview({ item }) {
	return (
		<Grid.Row>
			<Grid.Column>
				<div className="input-container theme-color">
                    <Trans
                        i18nKey='messageBuilder.components.vote.item.numVotes'
                        count={parseInt(item.data.minRequiredVotesPerPerson ?? '')}
                        values={{
                            min: item.data.minRequiredVotesPerPerson,
                            max: item.data.numAssignedVotesPerson
                        }}
						components={{
							p: <p className='mg-top-10 mg-bot-10' />
						}}
                        />
					<label className="mg-bot-10 black-text right-align">
						{item.data.shouldBeAnonymous && (
							<Fragment>
								<Icon name="user secret" />
								{t('messageBuilder.components.vote.item.personal-details-not-shared')}
							</Fragment>
						)}
						{!item.data.shouldBeAnonymous && (
							<Fragment>
								<Icon name="user" />
								{t(
									'messageBuilder.components.vote.item.personal-details-shared'
								)}
							</Fragment>
						)}
					</label>
					<Grid.Row>
						<Grid.Column className='vote-component'>
							{map(item.data.options, (option, index) => (
								<CandidateItem key={index} option={option} />
							))}
						</Grid.Column>
					</Grid.Row>
				</div>
			</Grid.Column>
		</Grid.Row>
	)
}
