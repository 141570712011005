import { isEmpty, isNil, omit } from "lodash"
import PostCreateEventRequest from "./PostCreateEventRequest"
import moment from "moment"
import { dropEmptyKeys } from "../../utils/helper"

class PostUpdateEventRequest extends PostCreateEventRequest {
    // Does completely nothing
    
    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
     getPayload = () => {

        const startDate = moment(`${this.startDate}T${this.allDay ? '00:00:00.000Z' : this.startTime}`)
        const endDate = moment(`${this.endDate}T${this.allDay ? '00:00:00.000Z' : this.endTime}`)

        const payload = {
            "title": this.title,
            "all_day": this.allDay ?? false,
            "end_date": endDate.toISOString(),
            "start_date": startDate.toISOString(),
            "community_id": this.communityId,
        }

        payload["channel_ids"] = this.channelIds
        payload["person_ids"] = this.personIds
        payload["repeat"] = this.repeat
        payload["notes"] = this.notes
        payload["location"] = this.location
        
        //?NOTE: Setting the remind minutes to zero for now, as the backend will help set this value automatically
        const hasRemind = (this.remindMinutes > 0)
        payload["send_reminder"] = hasRemind
        payload["remind_minutes"] = 0

        if (!isNil(this.notify)) {
            payload["send_notification"] = this.notify
        }

        if (!isEmpty(this.customRRules)) {
            const d = {
                ...this.customRRules,
                days: this.customRRules._raw_days,
                monthly_on: this.customRRules.monthlyOn,
            }

            payload["custom_repeat_rule"] = omit(d, ['_days', 'doesEnd', '_raw_days', 'monthlyOn'])
            payload["custom_repeat_rule"] = dropEmptyKeys(payload["custom_repeat_rule"])
        }

        return this.cleanPayload(payload)
    }
}

export default PostUpdateEventRequest