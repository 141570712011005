import {
    createAction
} from 'redux-actions'

import { actions } from '../constants'
import { calendarService, eventService } from '../api'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'

const { EVENT } = actions

export const eventsActions = {

    /**
     * Start loading
     */
    start: createAction(EVENT.START),
    
    /**
     * Start loading for list view
     */
    startListView: createAction(EVENT.START_LIST_VIEW),
    
    /**
     * Show error messages
     */
    error: createAction(EVENT.ERROR),

    /**
     * Show success messages
     */
    success: createAction(EVENT.SUCCESS),

    /**
     * reset data
     */
    reset: createAction(EVENT.RESET),

    /**
     * Save events
     */
    saveEvents: createAction(EVENT.SAVE),
    
    /**
     * Save events
     */
    saveListViewEvents: createAction(EVENT.SAVE_LIST_VIEW_EVENTS),
    
    /**
     * Rest events
     */
    resetListViewEvents: createAction(EVENT.RESET_LIST_VIEW_EVENTS),
    
    /**
     * Save calendar statuses
     */
    saveStatuses: createAction(EVENT.SAVE_STATUSES),
    
    /**
     * Save event details
     */
    saveEvent: createAction(EVENT.SAVE_EVENT_DETAILS),
    
    /**
     * Clean event details
     */
    cleanEventDetails: createAction(EVENT.CLEAR_EVENT_DETAILS),

    /**
     * Clear errors and messages
     */
    clearMessages: createAction(EVENT.CLEAR_MESSAGES),

    /**
     * Save events' filters
     */
    saveFilters: createAction(EVENT.SAVE_FILTERS),
    
    /**
     * Fetch event filters
     * 
     * @param {JSON} params 
     */
    fetchEventFilters(params) {
        return (dispatch, getState) => calendarService.getCalendarFilters(params)
            .then(response => {
                dispatch(this.saveFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch events from API
     * 
     * @param {JSON} params 
     */
    fetchEvents(params) {
        return (dispatch, getState) => eventService.getEvents(params)
            .then(response => {
                dispatch(this.saveEvents(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch events for the ListView from API
     * 
     * @param {JSON} params 
     */
    fetchListViewEvents(params) {
        return (dispatch, getState) => eventService.searchEvents(params)
            .then(response => {
                dispatch(this.saveListViewEvents(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch events from API
     * 
     * @param {JSON} params 
     */
    fetchCalendarStatuses(params) {
        return (dispatch, getState) => eventService.getCalendarStatuses(params)
            .then(response => {
                dispatch(this.saveStatuses(response.data))
            })
            .catch(error => {
                // dispatch(this.error(error))
            })
    },

    /**
     * Fetch event from API
     * 
     * @param {JSON} params 
     */
    fetchEvent(params) {
        return (dispatch, getState) => eventService.getEvent(params)
            .then(response => {
                dispatch(this.saveEvent(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post create new event
     * ?NOTE: When we create an event from the Events view, we redirect to the event detail or we could redirect to the calendar view?
     * 
     * @param {PostCreateEventRequest} params 
     */
    postCreateEvent(params) {
        return (dispatch, getState) => eventService.postCreateEvent(params)
            .then(response => {
                dispatch(this.success({
                    header: 'calendarsActions.createEvent.success.header',
                    content: 'calendarsActions.createEvent.success.message',
                    refreshEvents: true,
                }))
                dispatch(push(
                    routes.private.CALENDAR_EVENT_DETAIL
                        .replace(':id', response.id)
                        .replace(':calendarId', params.calendarId)
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Update existing event
     * 
     * @param {PostCreateEventRequest} params 
     */
    putUpdateEvent(params) {
        return (dispatch, getState) => eventService.putUpdateEvent(params)
            .then(response => {
                dispatch(this.success({
                    header: 'eventsActions.updateEvent.success.header',
                    content: 'eventsActions.updateEvent.success.message',
                    navigateMain: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post delete event
     * 
     * @param {JSON} params 
     */
    postDeleteEvent(params) {
        return (dispatch, getState) => eventService.postDeleteEvent(params)
            .then(response => {
                dispatch(this.success({
                    // refreshData: true,
                    navigateMain: true,
                    refreshEvents: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
} 