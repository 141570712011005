import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { authActions } from '../actions/authActions'
import logoAnim from '../images/d6_logo.riv'
import { version } from "../../package.json"
import 'semantic-ui-css/semantic.min.css'

import { useRouteMatch } from 'react-router'
import { map } from 'lodash'
import routes from '../routes/route-constants'
import LogoTeal from './partials/LogoTeal'

const DataInitialLayout = () => {

    /**
     * Currently authenticated user
     */
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const router = useSelector(state => state.router)
    const matcher = useRouteMatch({
        location: router.location.pathname,
        path: map(routes.private).filter(
            x => ![routes.private.HOME].includes(x)
        )
    })
    const community = useSelector(state => state.navigation.community)
    const { t } = useTranslation()
    
    // onMount/onUpdate
    useEffect(() => {
        // Add loading class to add image background
        document.body.classList.add('loading')
        
        dispatch(authActions.start())
        dispatch(authActions.doGetSSOUser({
            communityId: community?.id ?? matcher?.params?.communityId ?? null,
        }))
        
        return () => {
            document.body.classList.remove('loading')
        }

    }, [])

    const renderLoaderState = () => {
        
        if (auth.loading) {
            
            return (
                <Loader 
                    inverted 
                    active={true} 
                    content={
                        <Fragment>
                            <LogoTeal />
                            {t("loader.authenticating")}
                            <p><small>{`V ${version}`}</small></p>
                        </Fragment>
                    } />
            )
        }
        
        if (auth.initialising) {
            return (
                <Loader 
                    inverted 
                    active={auth.initialising} 
                    content={
                        <Fragment>
                            <LogoTeal />
                            {t("loader.initialising")}
                            <p><small>{`V ${version}`}</small></p>
                        </Fragment>
                    } />
            )
        }
    }

    return (
        <div className='ui middle aligned center aligned grid data-init-screen'>
            {renderLoaderState()}
        </div>
    )
}

export default DataInitialLayout