import React, { createRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'
import { isString } from 'lodash'
import i18next from 'i18next'
import AnimLoader from './AnimLoader'

const  propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    confirmClassName: PropTypes.string,
    parentClassName: PropTypes.string,

    // Render prop to allow adding dynamic content,
    // like forms etc, or any  additional components
    render: PropTypes.func,
    
    // Tell Modal that the confirm button should show or not
    // When this flag is False, irrespective of the other settings
    // the button will be hidden, the same goes for showCancel
    showConfirm: PropTypes.bool,
    showCancel: PropTypes.bool,

    // Tell the Modal if it should use the render prop 
    // to add more children components to this modal
    withContent: PropTypes.bool,
    
    // Tell the Modal not to continue confirmation until
    // required field conditions are met
    required: PropTypes.bool,

    // Optional data to be parsed down
    data: PropTypes.object,

    // Lock the modal from being dismissed when user clicks outside
    dismissible: PropTypes.bool,
    
    // Loading capabilities
    loading: PropTypes.bool,
    
    // Override Confirm
    overrideConfirm: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([undefined])]),
}

const  defaultProps = {
    modalId: 'confirm-modal',
    open: false,
    showCancel: true,
    showConfirm: true,
    withContent: false,
    required: false,
    loading: false,
    dismissible: true,
    onClose: () => {},
    onConfirm: (data) => {},
    header: i18next.t("modal.confirm.default.header"),
    errors: [],
    className: null,
    message: i18next.t("modal.confirm.default.message"),
    confirmText: i18next.t("modal.confirm.default.button.delete"),
    cancelText: i18next.t("modal.confirm.default.button.cancel"),
    parentClassName: '',
    confirmClassName: 'primary',
    render: () => {},
    data: {},
    overrideConfirm: undefined,
}

const ConfirmModal = (props) => {
    
    const {
        modalId,
        open, 
        onClose,
        overrideCloseClick,
        header,
        message, 
        onConfirm,
        cancelText,
        confirmText,
        showCancel,
        showConfirm,
        withContent,
        className,
        parentClassName,
        confirmClassName,
        render,
        required,
        data,
        errors,
        loading,
        dismissible,
        overrideConfirm,
    } = props

    /** @type {React.MutableRefObject<Modal|null>} */
    const modalRef = createRef(null);

    useEffect(() => {
      /** @type {React.MutableRefObject<HTMLElement|null>} */
      const elemRef = modalRef.current?.ref
      if (elemRef.current) {
        elemRef.current.setAttribute('data-test-id', modalId)
      }
    }, [open])

    const renderMessage = () => {
      if (isString(message)) {
        return <p>{ message }</p>
      }

      return message
    }

    return (
        <Modal
            size="tiny"
            ref={modalRef}
            open={ open }
            onClose={ onClose }
            className={parentClassName}
            closeOnDimmerClick={dismissible}>
        {loading && <AnimLoader loading />}
        <Modal.Header className={className ? `${className} br-2 white-text` : "br-2 primary-color white-text"}>{ header }</Modal.Header>
        <Modal.Content>
            {!withContent && renderMessage()}
            {withContent && render({message: renderMessage(), required, errors, data})}
        </Modal.Content>
        <Modal.Actions className="no-border">
          { showCancel && (
            <Button className="secondary" onClick={overrideCloseClick ?? onClose}>
              { cancelText }
            </Button>
          )}
          { showConfirm && !overrideConfirm && (
            <Button className={confirmClassName} onClick={() => onConfirm(data)}>
              { confirmText }
            </Button>
          )}
          
          { showConfirm && overrideConfirm && overrideConfirm({message: renderMessage(), required, errors, data})}
        </Modal.Actions>
      </Modal>
    )
}

ConfirmModal.propTypes = propTypes
ConfirmModal.defaultProps = defaultProps

export default ConfirmModal