import { pickBy, identity } from "lodash"

class ListChannelsRequest {

    constructor({
        sort, 
        page,
        search,
        perPage,
        tagIds,
        communityId,
    }) {
        this.page = page
        this.sort = sort
        this.search = search
        this.perPage = perPage
        this.tagIds = tagIds
        this.communityId = communityId
    }


    getPayload = () => {
        
        let payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "per_page": this.perPage.value,
            "tag_ids": [].concat(this.tagIds).join(","),
            "community_id": this.communityId,
        }

        return pickBy(payload, identity)
    }
    
}

export default ListChannelsRequest