import React, { Component, Fragment, useMemo, useState } from "react"
import { Dropdown, Grid, Icon, Segment, Input } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'
import { map } from "lodash"
import PropTypes from 'prop-types'
import { constants } from "../../constants"
import i18next from "i18next"

const propTypes = {
    classNames: PropTypes.string,
    perPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalCount: PropTypes.number,
    currentPage: PropTypes.number,
}

const defaultProps = {
    totalPages: 0,
    currentPage: 0,
    totalCount: 0,
    classNames: 'page-descriptor'
}

const PagerDescriptor = (props) => {

    const {
        classNames,
        perPage,
        totalPages,
        totalCount,
        currentPage,
    } = props

    // We need to create a sequence formula based on Arithmetic Sequence Formula
    // P = y -> x of {totalCount}
    const x = () => {
        
        const A1 = perPage
        const N = currentPage
        const D = perPage

        if (totalPages == 0) return 0
        if (currentPage == totalPages) return totalCount

        return A1 + (N - 1) * D
    }
    
    const y = () => {
        if (totalPages == 0) return 0
        if (currentPage == 1) return 1
        
        return ( perPage * currentPage ) - perPage + 1
    }

    if (totalCount === 0) {
        return null
    }

    return (
        <span className={classNames}>
            {y()} - {x()} {i18next.t("pager.descriptor.of")} {totalCount}
        </span>
    )

}

PagerDescriptor.propTypes = propTypes
PagerDescriptor.defaultProps = defaultProps

export default PagerDescriptor