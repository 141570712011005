import React, { useState, useEffect } from 'react'
import BaseMorphed from './BaseMorphed'
import { Input, Grid, Form, TextArea, Icon, Accordion } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getError } from '../../../utils/helper'
import ErrorLabel from '../../partials/ErrorLabel'

export default function URLMorphed({ item }) {
  const [isCollapsed, setCollapsed] = useState(false)
  const [data, setData] = useState({
    title: item.data ? item.data.title : '',
    description: item.data ? item.data.description : '',
    url: item.data ? item.data.url : '',
    thumbnail: item.data ? item.data.thumbnail : '',
    originalId: item?.data?.originalId,
    host: item?.data?.host,
    resourceCategoryIds: item?.data?.resourceCategoryIds ?? [],
  })

  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(builderActions.saveTemplateData({
      item,
      data,
    }))
  }, [data, dispatch])

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setData((prev) =>({ ...prev, [name]: value }))
  }
  
  const handleURLChanged = (event, data) => {
    setData((prev) =>({ ...prev, url: data.value }))
  }

  const handleCategoryChange = ({name, value, e}) => {
    setData(prev => ({ ...prev, [name]: value }))
  }

  return (
    <BaseMorphed item={item} render={({ errors }) => (
      <Form>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                required
                error={!getError('url', errors).isValid}
                className="input-container theme-color">
                <label className='label'>{t(`messageBuilder.morphedTemplates.URLMorphed.input.url.placeholder`)}</label>
                <Input
                  name='url'
                  value={data.url}
                  onChange={handleURLChanged}
                  placeholder='https://www.example.com' />
              </Form.Field>
              <ErrorLabel noMargin error={getError("url", errors)} />
            </Grid.Column>
          </Grid.Row>
          <Accordion className='w-full'>
            <Accordion.Title
              className='vertical-align primary-color-text' 
              active={isCollapsed}
              onClick={() => setCollapsed(prev => !prev)}>
                {t("messageBuilder.morphedTemplates.URLMorphed.advanced.label")}
                <Icon name='dropdown' />
              </Accordion.Title>
            <Accordion.Content className='mg-bot-10' active={isCollapsed}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field
                      className="input-container theme-color">
                      <label className='label'>{t(`messageBuilder.morphedTemplates.URLMorphed.input.title.placeholder`)}</label>
                      <Input
                        name='title'
                        value={data.title}
                        onChange={handleInputChange}
                        placeholder={t(`messageBuilder.morphedTemplates.URLMorphed.input.title.placeholder`)} />
                    </Form.Field>
                    <ErrorLabel noMargin error={getError("title", errors)} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field
                      className="input-container theme-color">
                      <label className='label'>{t(`messageBuilder.morphedTemplates.URLMorphed.input.thumbnail.placeholder`)}</label>
                      <Input
                        label={{icon:'image no-margin'}}
                        name='thumbnail'
                        value={data.thumbnail}
                        onChange={handleInputChange}
                        placeholder={t(`messageBuilder.morphedTemplates.URLMorphed.input.thumbnail.placeholder`)} />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <p className='label'>{t('messageBuilder.morphedTemplates.EventMorphed.description')}</p>
                        <div className="input-container theme-color">
                            <TextArea
                                rows={4}
                                name="description"
                                value={data.description}
                                onChange={handleInputChange}
                                placeholder={t("components.morphed.text.placeholder")} />
                        </div>
                        <ErrorLabel error={getError("description", errors)} />
                    </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Grid>
      </Form>
    )}
    resourceCategory={{
      id: 'resourceCategoryIds',
      selected: data.resourceCategoryIds,
      onChange: handleCategoryChange,
    }} />
  )
}
