import React from 'react'
import { Button } from 'semantic-ui-react'
import { templateIcons } from '../../../constants'
import Text from './templates/Text'
import WebFrame from './templates/WebFrame'
import Contact from './templates/Contact'
import Payment from './templates/Payment'
import { Attachment, Video, Image, Location, Deadline, Form, Progress, Event, Date } from './templates'
import Vote from './templates/Vote'
import NoticeAttachment from './templates/NoticeAttachment'
import URLComponent from './templates/URLComponent'

const BuildTemplatePreview = ({ item }) => {
    switch (item.type) {
        case templateIcons.notice: {
            return <Button>{item.type}</Button>
        }
        case templateIcons.event: {
            return <Event item={item} />
        }
        case templateIcons.file: {
            return <Attachment item={item}/>
        }
        case templateIcons.payment: {
            return <Payment item={item} />
        }
        case templateIcons.text: {
            return <Text classNames='no-bg' item={item}/>
        }
        case templateIcons.attachment: {
            return <Attachment item={item}/>
        }
        case templateIcons.video:
        case templateIcons.vimeo:
        case templateIcons.youTube: {
            return <Video item={item}/>
        }
        case templateIcons.album:
        case templateIcons.image: {
            return <Image item={item}/>
        }
        case templateIcons.location: {
            return <Location item={item}/>
        }
        case templateIcons.deadline: {
            return <Deadline item={item}/>
        }
        case templateIcons.form: {
            return <Form item={item}/>
        }
        case templateIcons.iframe: {
            return <WebFrame item={item} />
        }
        case templateIcons.contact: {
            return <Contact item={item} />
        }
        case templateIcons.progressBar: {
            return <Progress item={item}/>
        }
        case templateIcons.date: {
            return <Date item={item}/>
        }
        case templateIcons.vote: {
            return <Vote item={item} />
        }
        case templateIcons.customTemplate: {
            return <Button>{item.name}</Button>
        }
        case templateIcons.url: {
            return <URLComponent item={item} />
        }
        case templateIcons.noticeAttachment: {
            return <NoticeAttachment item={item} />
        }
        default:
            return <Button>Unknown</Button>
    }
}

const MessageItem = ({ item }) => {
    
    if (!item.data) {
        return null
    }

    return (
        <BuildTemplatePreview item={item} />
    )
}

export default MessageItem
