import { 
    createAction
} from 'redux-actions'

import { actions as types }      from '../constants'
import { apiService, noticeService, tieringService, userService } from '../api'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'
import { noticesActions } from './noticesActions'
import { PostCreateNoticeRequest } from '../api/requestObjects'
import { tieringActions } from './tieringActions'

const { BUILDER } = types

export const builderActions = {

    /**
     * Initialize options
     */
    start: createAction(BUILDER.START),
    error: createAction(BUILDER.ERROR),
    startTemplates: createAction(BUILDER.START_TEMPLATES),
    
    /**
     * Show success messages
     */
    success: createAction(BUILDER.SUCCESS),
    
    showPreview: createAction(BUILDER.SHOW_PREVIEW),
    togglePreview: createAction(BUILDER.TOGGLE_PREVIEW),
    hidePreview: createAction(BUILDER.HIDE_PREVIEW),
    saveChannels: createAction(BUILDER.SAVE_CHANNELS),
    saveResourceGroups: createAction(BUILDER.SAVE_RESOURCE_GROUPS),
    saveTemplate: createAction(BUILDER.SAVE_TEMPLATE),
    saveTemplates: createAction(BUILDER.SAVE_TEMPLATES),
    saveRecipients: createAction(BUILDER.SAVE_RECIPIENTS),
    clearTemplates: createAction(BUILDER.COMPOSE_CLEAR),
    composeTemplate: createAction(BUILDER.COMPOSE_TEMPLATE),
    showPublishModal: createAction(BUILDER.SHOW_PUBLISH_MODAL),
    clearPublishModal: createAction(BUILDER.CLEAR_PUBLISH_MODAL),
    clearEditTemplates: createAction(BUILDER.COMPOSE_CLEAR_EDIT),
    composeTemplateDynamic: createAction(BUILDER.COMPOSE_TEMPLATE_DYNAMIC),
    composeComponentDynamic: createAction(BUILDER.COMPOSE_COMPONENT_DYNAMIC),
    
    deleteTemplate: createAction(BUILDER.COMPOSE_DELETE_ITEM),
    duplicateTemplate: createAction(BUILDER.COMPOSE_DUPLICATE_ITEM),
    
    saveTemplateData: createAction(BUILDER.COMPOSE_SAVE_TEMPLATE),
    saveTemplateTitle: createAction(BUILDER.COMPOSE_SAVE_TEMPLATE_TITLE),
    saveListItemPreview: createAction(BUILDER.COMPOSE_SAVE_LIST_ITEM_PREVIEW),
    saveEditableMessage: createAction(BUILDER.SAVE_EDIT_MESSAGE),
    saveTemplateEditableMessage: createAction(BUILDER.SAVE_EDIT_TEMPLATE_MESSAGE),
    
    clearMessages: createAction(BUILDER.CLEAR_MESSAGES),
    
    // Busy indicator for components taking their time
    busyIndicator: createAction(BUILDER.BUSY_INDICATOR),
    
    broadcastGeneral: createAction(BUILDER.GENERAL_DISPATCHER),
    cleanBroadcastGeneral: createAction(BUILDER.CLEAN_GENERAL_DISPATCHER),

    // AI Assisted Writing
    assistedWriting: createAction(BUILDER.ASSISTED_WRITING),

    handleErrorWithCodes(error) {
        return (dispatch) => {
            const code = error.response?.data?.code
            const message = error.response?.data?.message
            if (code && code === 1017) {
                dispatch(this.error({
                    response: {
                        data: {
                            message,
                            code,
                        }
                    }
                }))
                return
            }

            dispatch(this.error(error))
        }
    },

    /**
     * Get Templates from API
     * 
     * @param {JSON} params
     * @return {ReduxActions.Action}
     */
    fetchTemplates(params) {
        return (dispatch, getState) => noticeService.API.all([noticeService.getTemplates(params), tieringService.getComponents(params)])
            .then(([templates, tieredComponents]) => {    
                dispatch(this.saveTemplates({
                    templates: templates.data,
                    tieredTasks: tieredComponents.data.components,
                    blankNoticeDefaultComponents: tieredComponents.data.blank_notice_default_components
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post Save Template
     * 
     * @param {JSON} params
     * @return {ReduxActions.Action}
     */
    postSaveTemplate(params, builderPayload) {
        return (dispatch, getState) => noticeService.postSaveTemplateWithContent(params, builderPayload)
            .then(response => {
                dispatch(this.success({
                    header: 'builderActions.template.success.header',
                    content: 'builderActions.template.success.message',
                    // refreshData: true,
                    refreshDataPartial: true,
                }))
            })
            .catch(error => {
                dispatch(this.handleErrorWithCodes(error))
            })
    },

    /**
     * Post Update Template
     * 
     * @param {JSON} params
     * @return {ReduxActions.Action}
     */
    postUpdateTemplate(params, builderPayload) {
        return (dispatch, getState) => noticeService.postUpdateTemplateWithContent(params, builderPayload)
            .then(response => {
                dispatch(this.success({
                    header: 'builderActions.template.edit.success.header',
                    content: 'builderActions.template.edit.success.message',
                    // refreshData: true,
                    refreshBuilderPartial: true,
                }))
            })
            .catch(error => {
                dispatch(this.handleErrorWithCodes(error))
            })
    },
    
    /**
     * Post Delete Template
     * 
     * @param {JSON} params
     * @return {ReduxActions.Action}
     */
    postDeleteTemplate(params) {
        return (dispatch, getState) => noticeService.deleteTemplate(params)
            .then(response => {
                dispatch(this.success({
                    // header: 'Template Deleted',
                    // content: 'Template deleted successfully',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch Message Filters Channels
     * 
     * @param {JSON} params
     * @return {ReduxActions.Action}
     */
    fetchChannels(params) {
        return (dispatch, getState) => noticeService.getNoticeChannels(params)
            .then(response => {
                dispatch(this.saveChannels(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch Resource Groups
     * 
     * @param {JSON} params
     * @return {ReduxActions.Action}
     */
    fetchResourceGroups(params) {
        return (dispatch, getState) => noticeService.getNoticeResourceGroups(params)
            .then(response => {
                dispatch(this.saveResourceGroups(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Do a post request to check if the message can be published using the selected channels and recipients
     * 
     * @param {PostCreateNoticeRequest} params
     * @return {Promise}
     */
    fetPublishPermission(params) {
        return () => noticeService.getPublishPermission(params)
    },
    
    /**
     * Save Message
     * 
     * @param {PostCreateNoticeRequest} params
     * @return {ReduxActions.Action}
     */
    postSaveNotice(params) {
        return (dispatch) => noticeService.postSaveNotice(params)
            .then(response => {
                
                // When a user is allowed to publish notice, show Publish Modal on arrival
                // We will get the information from the selected community
                const notice = response.data
                dispatch(builderActions.showPublishModal({
                    canPublish: notice.can_publish,
                    showPublish: params.showPublish
                }))
                dispatch(push(
                    routes.private.NOTICE_DETAIL
                        .replace(":id", notice.id)
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.handleErrorWithCodes(error))
            })
    },
    
    /**
     * Save Message
     * 
     * @param {PostCreateNoticeRequest} params
     * @return {ReduxActions.Action}
     */
    putUpdateNotice(params) {
        return (dispatch) => noticeService.putUpdateNotice(params)
            .then(response => {
                
                // When a user is allowed to publish notice, show Publish Modal on arrival
                // We will get the information from the selected community
                const notice = response.data
                dispatch(builderActions.showPublishModal({
                    canPublish: notice.can_publish,
                    showPublish: params.showPublish
                }))
                dispatch(push(
                    routes.private.NOTICE_DETAIL
                        .replace(":id", notice.id)
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.handleErrorWithCodes(error))
            })
    },
    
    /**
     * Fetch Editable Notice
     * 
     * @return {Promise}
     */
    getEditableNotice(params) {
        return (dispatch) => noticeService.getEditableNotice(params)
            .then(response => {
                dispatch(this.saveEditableMessage({
                    ...response.data,
                    noticeId: params.noticeId
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch Custom Fake Notice
     * 
     * @return {Promise}
     */
    getCustomNotice(params) {
        
        // When we have persons filters, we need to fetch the persons for these specific filters
        if (params.personsFilers) {
            return (dispatch) => userService.getPersonsForFilter(params.personsFilers)
                .then(response => {
                    return noticeService.getCustomNotice({
                        ...params,
                        persons: response?.data ?? [],
                    })
                })
                .then(response => {
                    dispatch(this.saveEditableMessage({
                        ...response.data,
                        noticeId: params.noticeId
                    }))
                })
                .catch(error => {
                    dispatch(this.error(error))
                })
        }

        return (dispatch) => noticeService.getCustomNotice(params)
            .then(response => {
                dispatch(this.saveEditableMessage({
                    ...response.data,
                    noticeId: params.noticeId
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    }
} 