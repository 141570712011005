import i18next from "i18next"
import { capitalize } from "lodash"
import moment from "moment"
import { RRule } from "rrule"

export const rruleToText = (props) => {

    const language = {
        dayNames: moment.weekdays(),
        monthNames: moment.months(),
    }

    const {
        strRule
    } = props

    const getText = (id) => {
        return i18next.t(id)
    }

    const dateFormat = (year, month, day) => {
        return moment()
            .date(day)
            .year(year)
            .month(month)
            .format("L LT")
    }

    return strRule ? capitalize(RRule.fromString(strRule).toText(getText, language)) : ""
}