import { actions, constants } from '../constants'
import { map } from 'lodash'
import moment from 'moment'

const { RESOURCES } = actions

const initialState = {
    loading: false,
    resources: [],
    filters: {},
    resourceDetails: null,
    pagination: [],
    errors: null,
    messages: null,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case RESOURCES.RESET: {
            return {
                ...state, 
                loading: false, 
                pagination: [], 
                categories: [], 
                errors: null, 
                messages: null, 
                detail: null
            }
        }
        case RESOURCES.START: {
            return {...state, loading: true}
        }
        case RESOURCES.CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null}
        }
        case RESOURCES.ERROR: {
            let error = action.payload.response || {}

            error = error.data ? error.data.message : "error.errorOccurred"
            
            return {
                ...state, 
                loading: false, 
                errors: error, 
                messages: null, 
                detail: {
                    ...state.detail, 
                    loading: false
                }
            }
        }
        case RESOURCES.SUCCESS: {
            return {...state, loading: false, errors: null, messages: action.payload}
        }
        case RESOURCES.SAVE: {
            return {
                ...state, 
                loading: false, 
                categories: action.payload.data,
                categoriesTransformed: map(action.payload.data, c => {
                    return {
                        ...c,
                        actions: [
                            constants.MENU_ACTION_DELETE,
                            constants.MENU_ACTION_EDIT,
                        ]
                    }
                }), 
                pagination: action.payload.pagination
            }
        }
        case RESOURCES.CLEAR_RESOURCE_DETAILS: {
            return {
                ...state, 
                loading: false,
                detail: null, 
            }
        }
        default: {}
    }

    return state
}