import PropTypes from 'prop-types'
import React, {Component} from 'react'
import ListItem from './ListItem'

const propTypes = {
	disabled: PropTypes.bool.isRequired,
	labelKey: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	valueKey: PropTypes.string.isRequired
}

const List = (props) => {

	const  handleClick = (value) => {
        
        if (props.disabled) {
			return
		}

		props.onClick(value)
	}

    const {
        labelKey,
        options,
        valueKey
    } = props

    return (
        <ul className="msts__list">
            {options.map(item => (
                <ListItem
                    key={ item[valueKey] }
                    disabled={ item.disabled }
                    disabledReason={ item.disabled_reason }
                    highlighted={ item.highlighted }
                    label={ item[labelKey] }
                    value={ item[valueKey] }
                    onClick={ handleClick } />
            ))}
        </ul>
    )
}

List.propTypes = propTypes

export default List