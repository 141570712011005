import Bugsnag from "@bugsnag/js"

/**
 * This middleware will help in sending any handled Exceptions from the application to Bugsnag for a broader visibility of app usage
 * 
 * @param {*} store 
 * @returns 
 */
const logger = store => next => action => {
    
    let result = next(action)

    if (action?.type?.includes('_ERROR')) {
        Bugsnag.notify(action.payload)
    }

    return result
}
  
export default logger