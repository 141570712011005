import i18next from 'i18next'
import { first, flatten, map, size, take } from 'lodash'
import React, { Fragment } from 'react'
import { List, Image, Icon, Label } from 'semantic-ui-react'
import { messageBuilder, templateIcons } from '../../../constants'
import { getComponentTagsOrder, renderType } from '../../../utils/helper'
import TaskIcon from '../TaskIcon'
import logoPlaceholder from '../../../images/image_placeholder.png'
import AdaptiveIcon from '../../partials/timeline/AdaptiveIcon'
import moment from 'moment'

/**
 * Create component preview for list item
 * 
 */
const FormListPreview = ({item}) => {
    const forms = map(item.items, i => i.data)
    const form = first(forms)

    return (
        <Label className={`form-label item-type-form trim-text ${form?.data?.isRequired ? 'required' : ''}`}>
            <Icon name="talk" />
            {i18next.t('messageBuilder.preview.ListItem.response')}
        </Label>
    )
}

const EventListPreview = ({item}) => {

    return (
        <Label className={`form-label icon-text item trim-text`}>
            <TaskIcon task={item} />
            {(item.data && item.data.previewText) || renderType(item) || i18next.t('messageBuilder.preview.ListItem.previewText')}
        </Label>
    )
}

const IconCardListPreview = ({item}) => {
    const components = map(item.items, i => {
        return {
            ...i.data,
            type: i.type
        }
    })
    const component = first(components)

    return (
        <Label className={`form-label icon-text trim-text`}>
            <TaskIcon task={component} />
            {component?.previewText ?? component?.previewStartDate ?? renderType(component) ?? i18next.t('messageBuilder.preview.ListItem.previewText')}
        </Label>
    )
}

const renderImage = (image) => {
        
    if (!image.file) return ""        
    if (image.file.url) return image.file.url

    // For this last option, the image was recently decoded and gotten from a Blob, so we will preview using the base64 data
    return image.thumb
}

const ImageGroupCardListPreview = ({item, compact = false}) => {

    const allFiles = flatten(map(item.items, i => map(i.data.files)))
    const limit = 3

    return (
        <div className={`image-previews ${compact ? 'compact' : ''}`}>
            {map(take(allFiles, limit), (image, i) => (
                <Fragment key={i}>

                    {(size(allFiles) > limit) && i == (limit - 1) ? (
                        <MorePlainTextPreview count={(size(allFiles)-limit)} image={renderImage(image)} compact={compact} />
                    ) : (
                        <div className='image-component' data-src={renderImage(image)} style={{backgroundImage: `url("${renderImage(image)}")`}} />
                    )}
                </Fragment>
            ))}
        </div>
    )
}

const FileGroupCardListPreview = ({item, compact = false}) => {

    const allFiles = flatten(map(item.items, i => map(i.data.files)))
    const limit = 2

    const _renderName = (file) => {
        if (!file) return ""
        if (file?.fileName) return file?.fileName
    }


    // When we have more than x files, we should only show icons inside Labels

    if (size(allFiles) > 2 || compact) {
        return (
            <div className='file-previews with-bg'>
                <Label className={`file-previews f-compact`}>
                    {map(take(allFiles, limit), (file, i) => (
                        <AdaptiveIcon key={i} url={_renderName(file) ?? ""} size="large" className="preview mg-right-1" />
                    ))}
                    {(size(allFiles) > limit) && (
                        <strong className='vertical-align'>+{(size(allFiles)-limit)}</strong>
                    )}
                </Label>
            </div>
        )
    }
    
    return (
        <div className='file-previews'>
            {map(take(allFiles, limit), (file, i) => (
                <Fragment key={i}>

                    {(size(allFiles) > limit) && i == (limit - 1) ? (
                        <MorePlainTextPreview count={(size(allFiles)-limit)} />
                    ) : (
                        <Label className={`file-component icon-text trim-text`}>
                            <AdaptiveIcon url={_renderName(file) ?? ""} size="large" className="preview" />
                            {_renderName(file)}
                        </Label>
                    )}
                </Fragment>
            ))}
        </div>
    )
}

const MoreIconCardListPreview = ({count}) => {
    return (
        <Label className={`form-label icon-text trim-text more-item`}>
            +{count}&nbsp;{i18next.t('messageBuilder.preview.ListItem.moreItems')}
        </Label>
    )
}

const MorePlainTextPreview = ({count, image = null, compact = false}) => {
    
    if (image) {
        return (
            <Fragment>
                {compact ? (
                    <Fragment>
                        <div className='image-component preview' style={{backgroundImage: `url("${image}")`}} />
                        <div className='image-component preview more-count'>
                            <strong className='gray-text vertical-align'>+{count}</strong>
                        </div>
                    </Fragment>
                ) : (
                    <div className='image-component preview more-count' style={{backgroundImage: `url("${image}")`}}>
                        <strong className='white-text'>+{count}</strong>
                    </div>
                )}
            </Fragment>
        )
    }

    return (
        <Label className={`vertical-align`}>
            <strong>+{count}</strong>
        </Label>
    )
}

const PaymentListPreview = ({item}) => {

    const renderOptions = (data) => {
        
        if (data.isNew) {
            return (
                <span>{i18next.t('messageBuilder.preview.ListItem.status.new')}</span>
            )
        }
        
        if (data.isPaid) {
            return (
                <span>{i18next.t('messageBuilder.preview.ListItem.status.paid')}</span>
            )
        }

        return (
            <span>{i18next.t('messageBuilder.preview.ListItem.status.unpaid')}</span>
        )
    }
    
    const renderIcon = (data) => {
        
        if (data.isPaid) {
            return "check circle white-text"
        }

        return "info circle"
    }
    
    return (
        <Label className={`form-label trim-text ${!item.isPaid ? 'unpaid-item' : ''}`}>
            <Icon name="credit card"/>
            <span className="initial">{renderOptions(item)}</span>
        </Label>
    )
}

const ItemPreview = ({item, compact = false}) => {
    
    if (!item.items) {
        return null
    }

    switch (item.type) {
        case templateIcons.event: {
            return <EventListPreview item={item} />
            // return <IconCardListPreview item={item} compact={compact} />
        }
        case templateIcons.payment: {
            return <PaymentListPreview item={item} compact={compact} />
        }
        case templateIcons.file:
        case templateIcons.attachment: {
            return <FileGroupCardListPreview item={item} compact={compact} />
        }
        case templateIcons.video:
        case templateIcons.vimeo:
        case templateIcons.youTube: {
            return <IconCardListPreview item={item} compact={compact} />
        }
        case templateIcons.album:
        case templateIcons.image: {
            return <ImageGroupCardListPreview item={item} compact={compact} />
        }
        case templateIcons.location: {
            return <IconCardListPreview item={item} compact={compact} />
        }
        case templateIcons.deadline: {
            return <IconCardListPreview item={item} compact={compact} />
        }
        case templateIcons.form: {
            return <FormListPreview item={item} compact={compact} />
        }
        case templateIcons.contact: {
            return <IconCardListPreview item={item} compact={compact} />
        }
        case templateIcons.progressBar: {
            return <IconCardListPreview item={item} compact={compact} />
        }
        case templateIcons.url: {
            return <IconCardListPreview item={item} compact={compact} />
        }
        default: {
            return null
        }
    }
}

const ListItem = ({ item, contents }) => {
    
    // Only one type of Component will show, no matter how many those Components are in the list
    // We should only show a maximum of two preview tags and the others will show as a plus with number after
    const limit = 3
    const uniques = getComponentTagsOrder(contents)
    const trimmedOrNotTitle = (size(item.title ?? "") > messageBuilder.MAX_TITLE_LENGTH) ? item.title.substr(0, messageBuilder.MAX_TITLE_LENGTH).concat("...") : item.title

    return (
        <List.Item className={`preview-list-item ${item.important ? 'important' : ''} z-depth-1`}>
            <Image rounded className="logo-image" src={item.image || logoPlaceholder} />
            <List.Content className="list-item">
                <List.Header className="title-text trim-text" as='h4'>
                    <strong>{item.important && <Icon name="warning circle" />}{ trimmedOrNotTitle }</strong>
                </List.Header>
                {item.hasText && (
                    <List.Description className="description-text">
                        <p className="description-text" dangerouslySetInnerHTML={{__html: item.description ? ("").concat(item.description).slice(0, 100).concat("...") : `<p>${i18next.t("messageBuilder.preview.templates.Text.textPlaceholder")}</p>`}}></p>
                    </List.Description>
                )}
            </List.Content>
            {item.pinItem && <Icon name="pin" className="pin-item" />}
            <span className="date-text">{ moment(item.createdAt).format("D MMM") }</span>

            <div className="message-thumbnails">
                <div className='read-indicator'>
                    <Icon name="circle" />
                </div>
                <div className='items-list'>
                    {map(take(uniques, limit), (content, i) => (
                        <ItemPreview 
                            key={i} 
                            item={content} />)
                    )}
                    {(size(uniques) > limit) && (
                        <MoreIconCardListPreview 
                            count={(size(uniques)-limit)} />
                    )}
                </div>
            </div>
        </List.Item>
    )
}

export default ListItem
