import React from 'react'
import { List, Grid, Button, Input, Checkbox, Icon, Label } from 'semantic-ui-react'
import BaseTemplate from './BaseTemplate'
import { formatMoney, formatMoneyLocale } from '../../../../utils/helper'
import CurrencyInput from 'react-currency-input'
import { paymentTypes } from '../../../../constants'
import i18next, { t } from 'i18next'
import IntlCurrencyInput from '../../../../utils/IntlCurrencyInput'

const OpenPayment = ({item}) => {
    
    return (
        <Grid.Row columns={2}>
            <Grid.Column width={16} className="mg-bot-5">
                <p className="title">{ item.data ? item.data.title : "" }</p>
            </Grid.Column>
            <Grid.Column width={16}>
                <div className="ui input" style={{width: '100%'}}>
                    <IntlCurrencyInput
                        currencyOverride={item.data.currency === 'ZAR' ? {"ZAR": "R"} : null}
                        currency={item.data.currency}
                        config={{
                            locale: i18next.language,
                            formats: {
                                number: {
                                    [item.data.currency]: {
                                        currency: item.data.currency,
                                        style: "currency",
                                    }
                                }
                            }
                        }} />
                </div>
            </Grid.Column>
        </Grid.Row>
    )
}

const PartialPayment = ({item}) => {
    
    return (
        <Grid.Row columns={2} style={{gap: `1rem`}}>
            <Grid.Column width={16}>
                <p className="title">{ item.data ? item.data.title : "" }</p>
            </Grid.Column>
            <Grid.Column width={16}>
                <div className="ui input" style={{width: '100%'}}>
                    <IntlCurrencyInput
                        currencyOverride={item.data.currency === 'ZAR' ? {"ZAR": "R"} : null}
                        currency={item.data.currency}
                        config={{
                            locale: i18next.language,
                            formats: {
                                number: {
                                    [item.data.currency]: {
                                        currency: item.data.currency,
                                        style: "currency",
                                    }
                                }
                            }
                        }} />
                </div>
            </Grid.Column>
            <Grid.Column width={16}>
                <div className='partial-payments-note'>
                    {t('messageBuilder.morphedTemplates.PaymentMorphed.types.partialDescription')}
                </div>
            </Grid.Column>
            <Grid.Column width={16}>
                <div className="totals-container no-margin">
                    <span><strong>{t('messageBuilder.morphedTemplates.PaymentMorphed.types.partial.outstanding')}</strong></span>
                    <span><strong>{formatMoneyLocale(item.data.amount, i18next.language, item.data.currency)}</strong></span>
                </div>
                <hr />
                <div className="totals-container">
                    <span>{t('messageBuilder.morphedTemplates.PaymentMorphed.types.partialAmount')}</span>
                    <span>{formatMoneyLocale(item.data.amount, i18next.language, item.data.currency)}</span>
                </div>
                <div className="totals-container">
                    <span>{t('messageBuilder.morphedTemplates.PaymentMorphed.types.partial.paid')}</span>
                    <span>{formatMoneyLocale(0, i18next.language, item.data.currency)}</span>
                </div>
            </Grid.Column>
            {/* <Grid.Column width={16}>
                <div className="totals-container">
                    <Button className='not-relevant' size='mini' type='button'>
                        {t('messageBuilder.morphedTemplates.PaymentMorphed.types.partial.not-relevant')}
                    </Button>
                </div>
            </Grid.Column> */}
        </Grid.Row>
    )
}

const FixedPayment = ({item}) => {
    
    return (
        <Grid.Row columns={2} className="no-padding">
            <Grid.Column width={11} className="mg-bot-5">
                <p className="title">{ item.data ? item.data.title : "" }</p>
                <p className="amount">{ formatMoneyLocale(item.data.amount, i18next.language, item.data.currency) }</p>
            </Grid.Column>
            <Grid.Column width={5} className="pay-button-container">
                <Checkbox />
            </Grid.Column>
        </Grid.Row>
    )
}

const ItemisedPayment = ({item}) => {
    
    return (
        <Grid.Row columns={2} className="no-padding">
            {item.data?.showQuantity && (
                <Grid.Column width='16' className='mg-bot-5'>
                    <Label><Icon name='shopping bag' />{t('builder.components.payment.preview.quantity', { quantity: item.data?.maxQuantityLimit })}</Label>
                </Grid.Column>
            )}
            <Grid.Column width={8}>
                <p className="title">{ item.data ? item.data.title : "" }</p>
                <p className="amount">{ formatMoneyLocale(item.data.amount, i18next.language, item.data.currency) }</p>
            </Grid.Column>
            <Grid.Column width={8} className="itemised-controls">
                <Grid columns="3">
                    <Grid.Row>
                        <Grid.Column width={5} className="control minus">
                            <Button circular icon="minus" />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Input type="text" value={1} onChange={() => {}} />
                        </Grid.Column>
                        <Grid.Column width={5} className="control plus">
                            <Button circular icon="plus" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid.Row>
    )
}

const DisplayPayment = ({item}) => {
    switch (item.data.type) {
        case paymentTypes.fixed.value:
            return <FixedPayment item={item}/>
        case paymentTypes.open.value:
            return <OpenPayment item={item}/>
        case paymentTypes.itemised.value:
            return <ItemisedPayment item={item}/>
        case paymentTypes.partial.value:
            return <PartialPayment item={item}/>
        default:
            return null
    }
}

const Payment = ({ item }) => {
    return (
        <BaseTemplate classNames="payment-item" render={() => (
            <List.Content>
                <Grid className="no-margin">
                    <DisplayPayment item={item} />
                </Grid>
            </List.Content>
        )} />
    )
}

export default Payment
