import React, { createRef, Fragment, useEffect, useRef, useState } from 'react'
import { Button, Form, Grid, Icon, Input, Modal } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

import { isEmpty } from 'lodash'
import { calendarsActions } from '../../actions/calendarsActions'
import { useDispatch, useSelector } from 'react-redux'
import FormMessages from '../partials/FormMessages'
import AnimLoader from '../partials/AnimLoader'
import { getError } from '../../utils/helper'
import { tieringActions } from '../../actions/tieringActions'

const UpgradeModal = (props) => {
    
    const {t} = useTranslation()
    /** @type {React.MutableRefObject<Modal|null>} */
    const modalRef = createRef(null);
    const [validationMessages, setValidationMessages] = useState([])

    const profile = useSelector(state => state.auth.profile)
    const community = useSelector(state => state.navigation.community)
    const tieringManagement = useSelector(state => state.tiering)

    const dispatch = useDispatch()

    const [data, setData] = useState({
        email: profile?.email ?? "",
        phone_number: profile?.phone_number ?? "",
        community_id: community?.id
    })

    useEffect(() => {
        /** @type {React.MutableRefObject<HTMLElement|null>} */
        const elemRef = modalRef.current?.ref
        if (elemRef.current) {
            elemRef.current.setAttribute('data-test-id', 'upgrade-modal')
        }
    }, [tieringManagement.visible])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }

    const onClose = () => {
        dispatch(tieringActions.clearMessages())
        dispatch(tieringActions.modal(false))
    }
    
    const handleSaveData = () => {
        dispatch(tieringActions.start())

        // For AddOn request, we will use a separate call
        if (tieringManagement.isAddOn) {
            dispatch(tieringActions.doPostRequestAddOn({
                ...data,
                type: String(tieringManagement.component).toLowerCase()
            }))
            return
        }

        dispatch(tieringActions.doPostRequestPlan(data))
    }
    
    return (
        <Modal
            size="tiny"
            ref={modalRef}
            open={ tieringManagement.visible }
            onClose={ onClose }
            closeOnDimmerClick={false}
            className='create-event-modal'>
            {isEmpty(tieringManagement.messages) && <Modal.Header className="br-2 primary-color white-text">{ t('tiering.modal.header', {context: tieringManagement.isAddOn ? 'ad_on' : '', component: tieringManagement.component}) }</Modal.Header>}
            <Modal.Content>
                <Form error={(validationMessages.length > 0) || !isEmpty(tieringManagement.errors)} success={!isEmpty(tieringManagement.messages)}>
                    
                    {(tieringManagement.errors) && (
                        <FormMessages
                            onClose={() => dispatch(tieringActions.clearMessages())}
                            errors={tieringManagement.errors}
                            messages={tieringManagement.messages} />
                    )}
                    
                    {(tieringManagement.messages) && (
                        <Grid>
                            <Grid.Row textAlign='center'>
                                <Grid.Column>
                                    <Icon name='check circle' className='primary-text' size='huge' />
                                    <h4>{t(tieringManagement?.messages?.content)}</h4>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}

                    {isEmpty(tieringManagement.messages) && (
                        <Fragment>
                            <AnimLoader loading={tieringManagement.loading} />
                            <p>{t('tiering.modal.description', {context: tieringManagement.isAddOn ? 'ad_on' : ''})}</p>
                            <Grid>
                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field error={!getError("email", validationMessages).isValid}
                                            className="input-container theme-color">
                                            <label className='label'>{t('tiering.form.email')}</label>
                                            <Input 
                                                name="email"
                                                value={data.email} 
                                                onChange={handleInputChange} 
                                                autoComplete="off" 
                                                placeholder={t('tiering.form.email')} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns="equal">
                                    <Grid.Column>
                                        <Form.Field error={!getError("phone_number", validationMessages).isValid}
                                            className="input-container theme-color">
                                            <label className='label'>{t('tiering.form.phoneNumber')}</label>
                                            <Input 
                                                name="phone_number"
                                                value={data.phone_number} 
                                                onChange={handleInputChange} 
                                                autoComplete="off" 
                                                placeholder={t('tiering.form.phoneNumber')} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions className="br-2 no-border">
                
                <Button className="secondary" disabled={tieringManagement.loading} onClick={onClose}>
                    { t("modal.cancelText") }
                </Button>

                {isEmpty(tieringManagement.messages) && (
                    <Button className="primary" disabled={tieringManagement.loading} onClick={handleSaveData}>
                    { t("tiering.modal.confirmText", {context: tieringManagement.isAddOn ? 'ad_on' : ''}) }
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    )
}

export default UpgradeModal