import i18next from "i18next"
import { mapKeys, size, filter } from "lodash"
import { messageBuilder } from '../../constants'

/**
 * 
 * @typedef Request
 * @property {String} name
 * @property {Array} channelIds
 * @property {String} communityId
 * @property {Boolean} targetEveryone
 * @property {Boolean} sendReminderDayBefore
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostCreateICalCalendarRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        name,
        iCalUrl,
        channelIds,
        communityId,
        targetEveryone,
        syncAutomatically,
        sendReminderDayBefore,
    }) {
        this.name = name
        this.iCalUrl = iCalUrl
        this.channelIds = channelIds
        this.communityId = communityId
        this.targetEveryone = targetEveryone
        this.syncAutomatically = syncAutomatically
        this.sendReminderDayBefore = sendReminderDayBefore
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        const payload = {
            "name": this.name,
            "ical_url": this.iCalUrl,
            "community_id": this.communityId,
            // "is_sync_automatically": this.syncAutomatically,
            "notify_synced_events": this.sendReminderDayBefore,
            "target_everyone": this.targetEveryone,
        }

        const channelIds = filter(this.channelIds, c => c !== messageBuilder.TARGET_ALL_TAG_ID)

        payload["channel_ids"] = (channelIds.length > 0) ? channelIds : []
        
        return payload
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        if (!this.name) {
            errors.push({
                field: "name",
                isValid: false,
                message: i18next.t("calendar.accounts.feed.link.requiredName")
            })
        }
        
        // if (size(this.channelIds) === 0) {
        //     errors.push({
        //         field: "channelIds",
        //         isValid: false,
        //         message: i18next.t("calendar.accounts.feed.link.requiredChannels")
        //     })
        // }
        
        if (!this.iCalUrl) {
            errors.push({
                field: "iCalUrl",
                isValid: false,
                message: i18next.t("calendar.accounts.feed.link.requiredICalUrl")
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }
    
    /**
     * Determine error messages from the given data if any, for checking if an iCal exists
     * 
     * @returns {ValidationError}
     */
    validateCheckExists = () => {

        const errors = []

        if (!this.iCalUrl) {
            errors.push({
                field: "iCalUrl",
                isValid: false,
                message: i18next.t("calendar.accounts.feed.link.requiredICalUrl")
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostCreateICalCalendarRequest