import React from 'react'
import BaseIconCard from './BaseIconCard'
import BaseTemplate from './BaseTemplate'

const Location = ({ item }) => {
    return (
        <BaseTemplate classNames="no-padding no-auto-height icon-card-container" render={() => (
            <BaseIconCard
                icon="map marker alternate"
                content={() => (
                    <p className="no-margin black-text trim-text">{ item.data.location }</p>
                )} />
        )} />
    )
}

export default Location
