import React, { useState, createRef, useEffect, useRef } from 'react'

export default function CustomRangeSlider({value, name, onChange}) {

    return (
        <div className="custom-range-slider">
            <input 
                name={name}
                defaultValue={value}
                type="range"
                min={0}
                max={100}
                step={1}
                onChange={onChange} />
            <div className="range-track" style={{width: `calc(${value}% - 5px)`}}/>
        </div>
    )
}
