import React from 'react'
import logo from '../images/d6_logo_white.svg'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../actions/authActions'
import { Button, Grid } from 'semantic-ui-react'
import { useEffect } from 'react'
import { size } from 'lodash'
import { push } from 'connected-react-router'
import routes from '../routes/route-constants'

const NoLink = ({router}) => {

  const auth = useSelector(state => state.auth)
  const ssoUser = useSelector(state => state.auth.ssoUser)

  const dispatch = useDispatch()
  
  useEffect(() => {
    document.body.classList.add('no-link')
    dispatch(authActions.finishInitialise())

    // when user has communities, redirect them back to home
    if (size(auth?.profile?.other_communities) >  0)  {
      dispatch(push(routes.private.HOME))
    }

    return () => {
      document.body.classList.remove('no-link')
    }
  }, [])

  const handleLogout = () => {
    dispatch(authActions.start())
    dispatch(authActions.doLogout())
  }

  const renderLogo = () => {
    return logo
  }

  return (
    <div className="card-layout column">
      <div className="ui large form">
        <div className="ui segment custom">
          <div className="ui vertically divided grid split-container">
            <div className="two column row no-padding">

              <div className="column left-side">
                <img src={ renderLogo() } alt="" className="image" />
              </div>

              <div className="column right-side">

                  <div className="w-100 pad-bot-15 pad-top-15">
                    <div className="field">
                      <h2 className="welcome-header">
                          Hello{ssoUser && ssoUser.profile && `, ${ssoUser.profile.name}`}
                      </h2>

                      <p className="description-text">Welcome to d6 Communicator, it appears that you are not  linked to any communities in our system.</p>
                      <p className="description-text">To get the best experience from our communication platform, please contact <a href="mailto:support@d6.co.za">support@d6.co.za</a> for more information.</p>
                      <br/>
                    </div>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Button 
                            onClick={handleLogout}
                            loading={auth.loading}
                            disabled={auth.loading}
                            className="ui fluid large primary-color submit button">
                              Logout
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoLink