import { omit } from "lodash"
import ApiService from "./ApiService"

class TieringService extends ApiService {

    /**
     * Request plan add-on
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postRequestAddOn = async (params) => {
        
        const { POST_REQUEST_ADD_ON } = this.endpoints
        
        const payload = omit(params, ['community_id'])

        return this.API.post(this.getEndpoint({
            path: POST_REQUEST_ADD_ON,
            data: params.community_id
        }), payload)
    }
    
    /**
     * Request plan upgrade
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postRequestPlan = async (params) => {
        
        const { POST_REQUEST_PLAN_UPGRADE } = this.endpoints
        
        const payload = omit(params, ['community_id'])

        return this.API.post(this.getEndpoint({
            path: POST_REQUEST_PLAN_UPGRADE,
            data: params.community_id
        }), payload)
    }
    
    /**
     * Get persons plan limits
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getPersonPlanLimits = (params) => {
        
        const { GET_MEMBER_PLAN_LIMITS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_MEMBER_PLAN_LIMITS,
            params: {
                community_id: params.communityId
            }
        }))
    }
    
    /**
     * Get channels plan limits
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getChannelPlanLimits = (params) => {
        
        const { GET_CHANNEL_PLAN_LIMITS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_CHANNEL_PLAN_LIMITS,
            params: {
                community_id: params.communityId
            }
        }))
    }
    
    /**
     * Get import plan limits
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getImportPlanLimits = (params) => {
        
        const { GET_IMPORT_PLAN_LIMITS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_IMPORT_PLAN_LIMITS,
            data: params.communityId,
        }))
    }
   
    /**
     * Get system users  plan limits
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getUsersPlanLimits = (params) => {
        
        const { GET_USERS_PLAN_LIMITS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_USERS_PLAN_LIMITS,
            params: {
                community_id: params.communityId
            }
        }))
    }
    
    /**
     * Get templates plan limits
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getTemplatePlanLimits = (params) => {
        
        const { GET_TEMPLATE_PLAN_LIMITS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_TEMPLATE_PLAN_LIMITS,
            params: {
                community_id: params.communityId
            }
        }))
    }
    
    /**
     * Get templates plan limits
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getBuilderPlanLimits = (params) => {
        
        const { GET_BUILDER_PLAN_LIMITS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_BUILDER_PLAN_LIMITS,
            params: {
                community_id: params.communityId
            }
        }))
    }
    
    /**
     * Get notice builder components
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getComponents = (params) => {
        
        const { GET_BUILDER_COMPONENTS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_BUILDER_COMPONENTS,
            params: {
                community_id: params.communityId,
                withDefaults: 1,
            }
        }))
    }
}

export default TieringService