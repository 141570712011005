import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from '../actions/authActions'

const LogoutRedirect = () => {

  const dispatch = useDispatch()

  // Add the redirect URL to the address bar
  useEffect(() => {

    // Add loading class to add image background
    document.body.classList.add('link-calendar')

    dispatch(authActions.doLogout())

    return () => {
			document.body.classList.remove('link-calendar')
		}

  }, [])

  return (
    <div className="ui middle aligned center aligned grid no-auth full-ui-block data-init-screen" /> // We don't have to show anything here, since the loading animations might seem a bit jittery
  )
}

export default LogoutRedirect