import React, { Fragment } from 'react'
import { List, Grid, Icon, Image, Button } from 'semantic-ui-react'
import { MessageItem } from '.'
import { filter, first, map, reduce, size, sumBy } from 'lodash'
import i18next from 'i18next'
import { messageBuilder, templateIcons } from '../../../constants'
import { formatMoney, formatMoneyLocale, getComponentGroupedSequence, getTransformedFiles } from '../../../utils/helper'
import logoPlaceholder from '../../../images/image_placeholder.png'
import { useMemo } from 'react'
import moment from 'moment'

const PaymentsFooter = (props) => {
    
    const {
        items
    } = props

    // Lets get all the payment items
    const payments = filter(items, i => i.type === templateIcons.payment)

    // Only show the footer when there is payment items
    if (size(payments) === 0) {
        return <p className="spacer-bottom"></p>
    }

    const totalAmount = sumBy(payments, p => p.data.amount) ?? 0 //Setting the default value to Zero when there is only Open payments, since they dont require amounts
    const currency = first(payments).data.currency

    return (
        <Grid className="footer bg-primary white-text no-margin">
            <Grid.Row columns={2}>
                <Grid.Column width={10}>
                    <h4 className="no-margin">{ i18next.t('messageBuilder.preview.MessageView.totalAmount') }</h4>
                    <h4 className="no-margin">{ formatMoneyLocale(totalAmount, i18next.language,  currency) }</h4>
                </Grid.Column>
                <Grid.Column width={6} className="pay-button-container" verticalAlign="bottom" textAlign="right">
                    <Button>{i18next.t('messageBuilder.preview.MessageView.pay')}</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export const MessageView = ({listItem, items, render, className}) => {

    const trimmedOrNotTitle = (size(listItem.title ?? "") > messageBuilder.MAX_TITLE_LENGTH) ? listItem.title.substr(0, messageBuilder.MAX_TITLE_LENGTH).concat("...") : listItem.title
    const cacheHash = Buffer.from(JSON.stringify(items)).toString("base64")
    
    // Group Files
    const _items = useMemo(() => {
        let _local_items = map(items)

        // Show image components as singles, until the reach size > {messageBuilder.NUM_IMAGES_TO_ALBUM}
        _local_items = getTransformedFiles(_local_items)

        // Group Image
        _local_items = getComponentGroupedSequence(_local_items, templateIcons.image)
        _local_items = getComponentGroupedSequence(_local_items, templateIcons.file)

        return _local_items

    }, [cacheHash])
    
    return (
        <Fragment>
            <div className={`message-view ${className || ''}`}>
                <div className="header">
                    <Grid>
                        <Grid.Row columns={2} className="vertical-align">
                            <Grid.Column width={2}>
                                <Icon name="arrow left" className="icon"/>
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <h3 className='item-title'>{i18next.t("previewNav.messageView.notice")}</h3>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid className={`no-padding no-margin ${listItem.important ? 'important': ''}`}>
                        <Grid.Row columns={2} className="vertical-align">
                            <Grid.Column width={4}>
                                <Image rounded src={listItem.icon || logoPlaceholder}/>
                            </Grid.Column>
                            <Grid.Column width={12}>
                                <p className="no-margin comm-name">{ listItem.communityName }</p>
                                <p className="notice-title trim-text"><strong>{listItem.important && <Icon name="warning circle" />}{ trimmedOrNotTitle }</strong></p>
                                {/* <p className="no-margin date-text">{ moment(listItem.createdAt).format('L LT') }</p> */}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <List className="m-content preview-list">
                    {_items && map(_items, (item, index) => 
                        <MessageItem 
                            key={index} 
                            item={item} />
                    )}
                </List>
            </div>
            <PaymentsFooter items={items} />
        </Fragment>
    )
}


export default MessageView
