import React from 'react'
import { Fragment } from 'react'
import { Icon, List } from 'semantic-ui-react'
import BaseTemplate from './BaseTemplate'

const WebFrame = ({ item }) => {

    return (
        <BaseTemplate render={() => (
            <Fragment>
                {(item.data && item.data.url) ? (
                    <List.Content>
                        <iframe 
                            className="web-frame" 
                            title={ item.id }
                            src={ item.data.url }/>
                    </List.Content>
                ) : (
                    <List.Content className="web-frame-placeholder">
                        <Icon name="globe" />
                    </List.Content>
                )}
            </Fragment>
        )} />
    )
}

export default WebFrame
