import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, Form, Grid, Header, Image, List, Radio, Segment } from 'semantic-ui-react'
import { useEffect } from 'react'
import { find, map, size, filter } from 'lodash'
import routes from '../../routes/route-constants'
import { appendCommunity, classNames, getError, getParameterByName } from '../../utils/helper'
import { calendarsActions } from '../../actions/calendarsActions'
import { PostCreateICalCalendarRequest } from '../../api/requestObjects'
import { Trans, useTranslation } from 'react-i18next'
import ErrorLabel from '../partials/ErrorLabel'
import Role from '../partials/Role'
import AnimLoader from '../partials/AnimLoader'
import Messages from '../partials/Messages'
import { messageBuilder } from '../../constants'
import { calendarService } from '../../api'
import ConfirmModal from '../partials/ConfirmModal'

const CreateCalendarICal = () => {

	const router = useSelector(state => state.router)
	const navigation = useSelector(state => state.navigation)
	const calendarManagement = useSelector(state => state.calendars)

	const [calendarPayload, setCalendarPayload] = useState({
        name: "",
        iCalUrl: "",
        channelIds: [],
		targetEveryone: false,
        syncAutomatically: false,
        sendReminderDayBefore: false,
        communityId: navigation.communityId,
    })

	const [confirm, setConfirm] = useState({
        showModal: false,
        onConfirm: () => { },
        onClose: () => { },
        message: "",
        header: "",
        confirmText: "",
        cancelText: "",
    })
	
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const [validationMessages, setValidationMessages] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		document.body.classList.add('link-calendar')

		return () => {
			document.body.classList.remove('link-calendar')
		}
	}, [])
	
	useEffect(() => {
		
		if (!navigation.communityId) return;

		dispatch(calendarsActions.start())
		dispatch(calendarsActions.fetchCalendarFilters({ communityId: navigation.communityId }))
	}, [navigation.communityId])

	const handleNavigation = () => {
		// HARD Refresh
		window.location.href = appendCommunity(
			navigation.communityId,
			routes.private.MANAGE_CALENDARS
		)
	}

	const handleCloseConfirm = () => {
        setConfirm({
            showModal: false,
            onConfirm: () => { },
            onClose: () => { },
            message: "",
            header: "",
            confirmText: ""
        })
    }

	const handleConfirm = () => {
        setConfirm({
            showModal: true,
            showConfirm: true,
            onConfirm: () => handlePostOverwrite(),
            onClose: () => handleCloseConfirm(),
            confirmText: t('modal.confirmText'),
            cancelText: t('CalendarDetail.create.overwrite.confirm.no'),
            message: (
                <div>
                    <Trans
                        i18nKey="CalendarDetail.create.overwrite.confirm.header.message"
                        components={{
                            p: <p />,
                            strong: <strong className='red-text' />
                        }} />
                </div>
            ),
            header: t('CalendarDetail.create.overwrite.confirm.header'),
        })
    }

	const handleCheckChanged = ({name}) => {
        setCalendarPayload(prev => ({...prev, [name]: !prev[name]}))
    }

	const handleRolesChange = ({name, value}) => {
		let selections = value

		// When the current selection has our "-901" id already, we should remove it and select everything else that was already selected
        if (value.indexOf(messageBuilder.TARGET_ALL_TAG_ID) > -1) {
            selections = filter(value, c => c !== messageBuilder.TARGET_ALL_TAG_ID)
        }

        setCalendarPayload(prev => ({...prev, [name]: selections, targetEveryone: false}))
    }

	const handleInputChange = (event) => {
        
		const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setCalendarPayload(prev => ({
			...prev,
			[name]: value
		}))
    }

	const handlePostCreateCalendar = () => {
		
		handleCloseConfirm()

        const request = new PostCreateICalCalendarRequest(calendarPayload)
        const validator = request.validator()

        // Validate the request
        if (!validator.isValid) {
            setValidationMessages(validator.messages)
            return;
        }

        setValidationMessages([])
        dispatch(calendarsActions.start())
        dispatch(calendarsActions.postCreateICalCalendar(calendarPayload))
    }
	
	const handlePostOverwrite = () => {
		handleCloseConfirm()
		dispatch(calendarsActions.start())
        dispatch(calendarsActions.postCreateICalCalendar(calendarPayload))
    }

	const _handleAllTargetsClick = (_, {value}) => {
        
        // Remove all selections on recipients list
        const selections = [messageBuilder.TARGET_ALL_TAG_ID]
        
        setCalendarPayload(prev => ({
            ...prev,
			targetEveryone: true,
            channelIds: selections
        }))
    }

	const checkCalendarExistsAndWarn = () => {
		
		setValidationMessages([])
		
		const request = new PostCreateICalCalendarRequest(calendarPayload)
		const validator = request.validateCheckExists()

		// Validate the request
        if (!validator.isValid) {
            setValidationMessages(validator.messages)
            return;
        }

		setLoading(true)

		calendarService.checkICalCalendarExists({
			community_id: request.communityId,
			account_identifier: request.iCalUrl,
		}).then(response => {

			// When we get a 200 OK, we know the calendar exists, so we show confirmation
			handleConfirm()
		}).catch(error => {
			
			if (error?.response?.status === 404) {
				// When we get a 404 Not Found, we know the calendar does not exist, so we continue normally
				handlePostCreateCalendar()
				return;
			}

			dispatch(calendarsActions.error(error))

		}).finally(() => setLoading(false))
	}

	return (
		<div className="link-calendar-full-page">
			
			<AnimLoader loading={calendarManagement.loading || isLoading} />

			<ConfirmModal
				header={confirm.header}
				open={confirm.showModal}
				onClose={confirm.onClose}
				message={confirm.message}
				confirmClassName="primary danger"
				confirmText={confirm.confirmText}
				cancelText={confirm.cancelText}
				onConfirm={confirm.onConfirm}
				dismissible={false} />

			<Grid className="calendar-items-container">
				<Grid.Row columns={1}>
					<Grid.Column width={16}>
						<h1>{t('calendars.settings.modal.ical.title')}</h1>
						{calendarManagement.errors && (
							<Messages
								onClose={() => dispatch(calendarsActions.clearMessages())}
								errors={calendarManagement.errors || ""}/>
						)}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className="no-pad-top no-margin link-calendar-container no-scroll">
					<Grid.Column width={16}>
						<Segment className={classNames('list-header', (size(validationMessages) > 0) && 'has-error')} attached='top'>
							<Header as='h3'>
								{t('calendars.manage.link.ical.header')}
							</Header>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className="no-pad-bot ui form">
					<Grid.Column width={16}>
						<Form.Field 
							required
							error={!getError("name", validationMessages).isValid}
							className="input-container theme-color">
							<Form.Input 
								required
								error={getError("name", validationMessages).message}
								placeholder={t('calendars.manage.link.ical.name.placeholder')}
								name="name"
								value={calendarPayload.name}
								onChange={ handleInputChange }
								autoComplete="off"
								label={t('calendars.manage.link.ical.name.placeholder')} />
						</Form.Field>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className="no-pad-bot ui form">
					<Grid.Column width={16}>
						<Form.Field 
							required
							error={!getError("iCalUrl", validationMessages).isValid}
							className="input-container theme-color">
							<Form.Input 
								required
								error={getError("iCalUrl", validationMessages).message}
								placeholder={t('calendars.manage.link.ical.url.placeholder')}
								name="iCalUrl"
								value={calendarPayload.iCalUrl}
								onChange={ handleInputChange }
								autoComplete="off"
								label={t('calendars.manage.link.ical.url.description')} />
						</Form.Field>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={1} className="no-pad-bot ui form">
					<Grid.Column width={16}>
						<Form.Field
							required
							error={!getError("channelIds", validationMessages).isValid}
							className="input-container theme-color">

							<Role
								role={{
									id: "channelIds",
									name: t('calendar.ical.input.channels.title'),
									smallDescription: t('calendar.ical.input.channels.description')
								}}
								virtualised
								placeholder={t('calendar.input.channels.placeholder')}
								selected={calendarPayload.channelIds}
								onChange={handleRolesChange}
								options={[
									navigation?.community?.can_target_everyone ? { 
                                        onClick: _handleAllTargetsClick,
                                        key: messageBuilder.TARGET_ALL_TAG_ID, 
                                        value: messageBuilder.TARGET_ALL_TAG_ID, 
                                        text: t("messageBuilder.recipients.targetAll"),
                                        className: "special-actions",
                                    } : false,
								].concat(calendarManagement?.filters?.channels ?? []).filter(Boolean)} />

							<ErrorLabel className='ui pointing prompt label w-fit-content' error={getError("channelIds", validationMessages)} />
						</Form.Field>
					</Grid.Column>
					<Grid.Column>
						<div className='mg-top-15'>
							<Checkbox
								onChange={e => handleCheckChanged({name: 'sendReminderDayBefore'})} 
								checked={calendarPayload.sendReminderDayBefore} 
								label={t('calendar.detail.remindDayBefore')} />
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns="equal" className='mg-top-20'>
					<Grid.Column width={16} textAlign="right">
						<Button
							onClick={handleNavigation}
							className="secondary mg-right-5">
							{t('general.cancel')}
						</Button>
						<Button
							disabled={calendarManagement.loading}
							onClick={checkCalendarExistsAndWarn}
							className="primary no-margin">
							{t('calendars.ical.settings.modal.confirm.text')}
						</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}

export default CreateCalendarICal
