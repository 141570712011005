import { isEmpty } from "lodash"
import { filterNulls } from "../../utils/helper"

class GetAppDownloadInvitesBreakdownRequest {
    
    constructor({
        strategy,
        includeSMS,
        includeEmail,
        includeWhatsApp,
        personIds,
        communityId,
    }) {
        this.strategy = strategy
        this.personIds = personIds
        this.includeSMS = includeSMS
        this.includeEmail = includeEmail
        this.includeWhatsApp = includeWhatsApp
        this.communityId = communityId 
    }

    getPayload = () => {
        
        const payload = {
            "strategy": this.strategy,
            "include_sms": this.includeSMS,
            "community_id": this.communityId,
            "include_email": this.includeEmail,
            "include_whatsapp": this.includeWhatsApp,
        }

        if (!isEmpty(this.personIds)) {
            payload["person_ids"] = this.personIds
        }

        return filterNulls(payload, true)
    }

}

export default GetAppDownloadInvitesBreakdownRequest