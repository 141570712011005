import { dropEmptyKeys } from "../../utils/helper"

class InviteMembersRequest {
    
    constructor({
        sort, 
        page,
        search,
        strategy,
        personIds,
        includeSMS,
        channelIds,
        isReminder,
        communityId,
        includeEmail,
        includeWhatsApp,
    }) {
        this.page = page
        this.sort = sort
        this.search = search
        this.strategy = strategy
        this.personIds = personIds
        this.isReminder = isReminder
        this.channelIds = channelIds
        this.includeSMS = includeSMS
        this.communityId = communityId
        this.includeEmail = includeEmail
        this.includeWhatsApp = includeWhatsApp
    }

    getSelectionPayload = () => {
        
        const payload = {
            "strategy": this.strategy,
            "person_ids": this.personIds,
            "include_sms": this.includeSMS,
            "community_id": this.communityId,
            "include_email": this.includeEmail,
            "include_whatsapp": this.includeWhatsApp,
            "is_reminder": this.isReminder,
        }

        return dropEmptyKeys(payload)
    }

    getFiltersFiltersPayload = () => {
        
        const payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "strategy": this.strategy,
            "channel_ids": [].concat(this.channelIds).join(","),
            "include_sms": this.includeSMS,
            "community_id": this.communityId,
            "include_email": this.includeEmail,
            "include_whatsapp": this.includeWhatsApp,
            "is_reminder": this.isReminder,
        }


        return dropEmptyKeys(payload)
    }
    
}

export const INVITE_BY_SELECTION = 1
export const INVITE_BY_FILTERS = 2

export default InviteMembersRequest