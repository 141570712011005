import React, { Fragment } from 'react'
import { BaseTemplate } from '../../message-builder/preview/templates'
import { Label, List } from 'semantic-ui-react'
import { map, mapValues } from 'lodash'
import moment from 'moment'
import i18next from "i18next"

const BaseItem = ({ item, render }) => {
	return (
		<div className="mg-bot-10">
			<p className="no-margin">
				<strong>{item.title}</strong>
			</p>
			{item.subtitle && (
				<span className="mg-bot-2 mg-top-2">{item.subtitle}</span>
			)}
			{render()}
		</div>
	)
}

const PaymentMiniItem = ({ item }) => {
	const substitutions = mapValues(item.title?.dates, (value) => moment(value).format('L LT'))
	const label = i18next.t(item.title?.string ?? '', substitutions)

	return (
		<List.Item>
			<p className='black-text mg-bot-2'>{label}</p>
			<Label horizontal className="color-primary-light">
				{item.amount_formatted}
			</Label>
		</List.Item>
	)
}

const PaymentItemPreview = ({ item }) => {
	return (
		<BaseItem
			item={item}
			render={() => (
				<List divided selection className='no-margin'>
					{map(item.payments, (pItem, index) => (
						<PaymentMiniItem item={pItem} key={`payment-item-sub-${index}`} />
					))}
				</List>
			)}
		/>
	)
}

export default function PaymentItem({ hoverItem }) {
	return (
		<BaseTemplate
			render={() => (
				<Fragment>
					<List.Content>
						{map(hoverItem.items, (item, index) => (
							<PaymentItemPreview key={`payment-item-${index}`} item={item} />
						))}
					</List.Content>
				</Fragment>
			)}
		/>
	)
}
