import { replace } from 'connected-react-router'
import { identity, isNil, omitBy, pickBy } from 'lodash'
import { 
    createAction
} from 'redux-actions'

import { actions }      from '../constants'
import { createGetParams, createGetParamsNoURL, dropEmptyKeys, filterNulls, getParameterByName } from '../utils/helper'

const { NAVIGATION } = actions

export const navigationActions = {

    /**
     * Show SideBar dispatcher
     */
    showSidebar: createAction(NAVIGATION.SHOW_SIDEBAR),

    /**
     * Hide SideBar dispatcher
     */
    hideSidebar: createAction(NAVIGATION.HIDE_SIDEBAR),
    
    /**
     * Toggle SideBar visibility dispatcher
     */
    toggleSidebar: createAction(NAVIGATION.TOGGLE_SIDEBAR_VISIBILITY),
    
    /**
     * Toggle SideBar visibility when on Medium Screens and down
     */
     toggleMdSidebar: createAction(NAVIGATION.TOGGLE_MD_SIDEBAR_VISIBILITY),
    
    /**
     * Toggle selected language
     */
    toggleLocale: createAction(NAVIGATION.TOGGLE_ACTIVE_LOCALE),
    
    /**
     * Toggle BuilderSideBar visibility dispatcher
     */
    toggleBuilderSidebar: createAction(NAVIGATION.TOGGLE_BUILDER_SIDEBAR_VISIBILITY),

    /**
     * Save the current community selection to keep through pages
     */
    selectCommunity: createAction(NAVIGATION.SELECT_COMMUNITY),

    /**
     * Hide builder component navigation
     */
    hideBuilderSidebar: createAction(NAVIGATION.HIDE_BUILDER_SIDEBAR),
    
    /**
     * Show builder component navigation
     */
    showBuilderSidebar: createAction(NAVIGATION.SHOW_BUILDER_SIDEBAR),
    
    /**
     * Save filters to hash router
     */
    // _saveToHashRouter: createAction(NAVIGATION.SAVE_TO_HASH_ROUTER),
    saveToHashRouter: (payload) => {
        
        // Grab current hash values so we dont overwrite things
        const currentUrl = payload.url ??  window.location.origin
        let data = payload.data

        // Skip the route edit when there is no data to add to URL Bar
        if (!data) { return; }

        data = dropEmptyKeys(data)
        const params = createGetParamsNoURL(data)

        let url = currentUrl.split("?")[0] + "?".concat(params)
        window.history.replaceState(null, null, url)
    }
} 