import React, { useState, Fragment, useEffect } from 'react'

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import BaseMorphed from './BaseMorphed'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import i18next from 'i18next'
import { RichTextCleaner } from './RichTextCleaner'
import { getError, isLngSupported, convertMarkup, editorHotFix } from '../../../utils/helper'
import ErrorLabel from '../../partials/ErrorLabel'
import { CustomEmojiPicker } from './CustomEmojiPicker'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { messageBuilder } from '../../../constants'
import { draftToMarkdown } from 'markdown-draft-js'
import { AssistedWriting } from '../integrations/openai/AssistedWriting'
import { Entity } from 'draft-js'

export const TypeDropdown = (props) => {
    
    const {
        selected,
        onSelectionChange
    } = props

    const options = [
        {key: messageBuilder.MARKUP.html, value: messageBuilder.MARKUP.html, text: 'HTML'},
        {key: messageBuilder.MARKUP.markdown, value: messageBuilder.MARKUP.markdown, text: 'Markdown'},
        // {key: 'plain-text', value: 'plain-text', text: 'Plain Text'},
    ]

    const _default = find(options, {value: selected})

    const [state, setState] = useState(_default ?? options[0])

    const _handleChange = (e, data) => {
        const _selected = find(data.options, {value: data.value})
        setState(_selected)
        
        if (onSelectionChange) {
            onSelectionChange(_selected.value)
        }
    }

    return (
        <div className='rdw-type-wrapper' aria-label='rdw-type-wrapper'>
            <div className='rdw-option-wrapper'>
                <Dropdown
                    text={state?.text ?? i18next.t('components.text.typesDropdown.placeholder')}
                    options={options}
                    value={state.value}
                    onChange={_handleChange} />
            </div>
        </div>
    )
}

export default function TextMorphed({ item }) {
    
    const defaultContentState = {
        "blocks": [
            {
                "key": "637gr",
                "text": "",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
            }
        ],
        "entityMap": {}
    }

    const community = useSelector(state => state.navigation?.community)
    const communityId = useSelector(state => state.navigation?.communityId)

    const [data, setData] = useState({
        content: item.data ? item.data.content : "",
        jsonContent: item.data ? item.data.jsonContent : defaultContentState,
        originalId: item?.data?.originalId,
        mediaType:  item?.data?.mediaType ?? messageBuilder.MARKUP.markdown
    })

    const { i18n } = useTranslation()

    const checkLngSupport = isLngSupported(i18n.language)
    const isSupported = checkLngSupport.isValid
    const defaultLng = checkLngSupport.defaultLng

    const useAbleLocale = isSupported ? i18next.language : defaultLng

    const contentBlock = convertFromRaw(item.data ? item.data.jsonContent : defaultContentState)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentBlock))

    const onEditorChange = _editorState => {

        const _newState = editorHotFix(_editorState)
        const content = convertToRaw(_newState.getCurrentContent())

        const markdown = draftToMarkdown(content, {
            remarkablePreset: 'commonmark',
            preserveNewlines: true,
            remarkableOptions: {
                breaks: true
            }
        })

        setEditorState(_editorState)

        setData((prevData) => ({
            ...prevData,
            content: convertMarkup({
                type: prevData.mediaType,
                rawContents: content,
                convertedMD: markdown
            }),
            jsonContent: content,
        }))
    }

    const dispatch = useDispatch()
    
    useEffect(() => {
        // Trigger manual update of render type
        // _handleOptionChange(data.mediaType)
        onEditorChange(editorState)
    }, [])
    
    useEffect(() => {
        dispatch(builderActions.saveTemplateData({item, data}))
    }, [dispatch, data])


    const _handleOptionChange = (type) => {
        const _newState = editorHotFix(editorState)
        const content = convertToRaw(_newState.getCurrentContent())

        const markdown = draftToMarkdown(content, {
            remarkablePreset: 'commonmark',
            preserveNewlines: true,
            remarkableOptions: {
                breaks: true
            }
        })

        setData((prevData) => ({
            ...prevData,
            mediaType: type,
            content: convertMarkup({
                type: type,
                rawContents: content,
                // Not really conventional to say we are converting something but only to parse it as converted, but it does not matter because
                // this will only be used if its needed by the function
                convertedMD: markdown
            }),
            jsonContent: content,
        }))
    }

    //? @TODO: https://github.com/jpuri/react-draft-wysiwyg/blob/b5a6b84053f8d4725e334a9cbd141c73f1dc73d2/src/utils/handlePaste.js#L6
    const _onPasteContent = (text, html, _editorState, onChange) => {
        return true
    }

    const getModalConfig = (selected) => {
        switch (selected) {
            case messageBuilder.INTEGRATIONS.OPEN_AI.MODES.GENERATE:
                return {
                    header: i18next.t('MessageBuilder.ai.help-me-write'),
                }
            case messageBuilder.INTEGRATIONS.OPEN_AI.MODES.SIMPLIFY:
                return {
                    header: i18next.t('MessageBuilder.modal.ai.simplify.header'),
                }
            case messageBuilder.INTEGRATIONS.OPEN_AI.MODES.ELABORATE:
                return {
                    header: i18next.t('MessageBuilder.modal.ai.elaborate.header'),
                }
            case messageBuilder.INTEGRATIONS.OPEN_AI.MODES.SUMMARIZE:
                return {
                    header: i18next.t('MessageBuilder.modal.ai.summarize.header'),
                    actions: {
                        label: i18next.t('MessageBuilder.modal.ai.summarize.actions.label'), 
                        defaultOption: messageBuilder.INTEGRATIONS.OPEN_AI.SUMMARIZE_METHODS.TEXT,
                        options: [
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.SUMMARIZE_METHODS.TEXT, name: i18next.t('MessageBuilder.modal.ai.summarize.options.paragraph.caption')},
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.SUMMARIZE_METHODS.LIST, name: i18next.t('MessageBuilder.modal.ai.summarize.options.bullet-points.caption')}
                        ]
                    }
                }
            case messageBuilder.INTEGRATIONS.OPEN_AI.MODES.CHANGE_TONE:
                return {
                    header: i18next.t('MessageBuilder.modal.ai.change-tone.header'),
                    actions: {
                        label: i18next.t('MessageBuilder.modal.ai.change-tone.actions.label'),
                        defaultOption: messageBuilder.INTEGRATIONS.OPEN_AI.TONES.CASUAL,
                        options: [
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.TONES.FORMAL, name: i18next.t('MessageBuilder.modal.ai.change-tone.options.formal.caption')},
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.TONES.FUNNY, name: i18next.t('MessageBuilder.modal.ai.change-tone.options.funny.caption')},
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.TONES.FRIENDLY, name: i18next.t('MessageBuilder.modal.ai.change-tone.options.friendly.caption')},
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.TONES.ENTHUSIASTIC, name: i18next.t('MessageBuilder.modal.ai.change-tone.options.enthusiastic.caption')},
                            {id: messageBuilder.INTEGRATIONS.OPEN_AI.TONES.CASUAL, name: i18next.t('MessageBuilder.modal.ai.change-tone.options.casual.caption')},
                        ]
                    }
                }
            case messageBuilder.INTEGRATIONS.OPEN_AI.MODES.IMPROVE:
                return {
                    header: i18next.t('MessageBuilder.modal.ai.improve.header'),
                }
            default:
                console.warn(`Unable to handle the provided selection: [${selected}]`);
                return {
                    header: i18next.t('modal.assisted-writing.default.header')
                }
        }
    }

    const handleClick = (item, editorText, onFinishCallback) => {
        const modalConfig = getModalConfig(item)

        dispatch(builderActions.assistedWriting({
            open: true,
            header: modalConfig.header,
            data: {
                mode: item, 
                text: editorText,
                communityId: communityId,
                actions: modalConfig.actions,
            },
            onDone: onFinishCallback
        }))
    }

    return (
        <BaseMorphed item={item} render={({errors}) => (
            <Fragment>
                <p className='label'>{i18next.t('messageBuilder.morphedTemplates.TextMorphed.label.text')}</p>
                <Editor
                    spellCheck
                    stripPastedStyles
                    // handlePastedText={_onPasteContent}
                    editorState={editorState}
                    onEditorStateChange={onEditorChange}
                    placeholder={data.content ? "" : i18next.t("components.morphed.text.placeholder")}
                    toolbarCustomButtons={[
                        <RichTextCleaner key={0} />, 
                        <CustomEmojiPicker key={1} />, 
                        community.integrations_openai_aw_visible && <AssistedWriting key={2} onOptionClick={handleClick} />, 
                        // <TypeDropdown selected={data.mediaType} onSelectionChange={_handleOptionChange} />
                    ].filter(Boolean)}
                    localization={{
                        locale: useAbleLocale,
                        translations: i18next.getResource(useAbleLocale, "editor")
                    }}
                    // @TODO: Translate editor for all languages
                    toolbar={{
                        options: ['blockType', 'list', 'inline', 'link', 'history'],
                        inline: { inDropdown: false, options: ['bold', 'italic', 'strikethrough'] },
                        // inline: { inDropdown: false, options: ['bold', 'italic', 'strikethrough', 'monospace', 'superscript', 'subscript'] },
                        list: { inDropdown: true },
                        link: { inDropdown: true },
                        blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        },
                    }}
                    plugins={[]}
                    // handleReturn={_onEnterPressed}
                    // handleKeyCommand={null}
                />
                <ErrorLabel error={getError("text", errors)} />
            </Fragment>
        )} />
    )
}