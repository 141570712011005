/**
 * We have a need for Dropdowns to be performant, so they can handle large sets of data, to be specific around >1K items
 * The current SemanticUI Dropdown works, but it starts to struggle when number go beyond 1K mark, this class solves this problem by
 * extending the original Dropdown class, and adding DOM virtualisation so we only render the items that are visible on the parents viewport
 * 
 */

import React, {  } from 'react'
import { Dropdown, DropdownItem } from 'semantic-ui-react'
import { ViewportList } from "react-viewport-list"
import { isEmpty, some, includes, filter, escapeRegExp, isFunction, invoke, size} from "lodash"

/**
 * ? NOTE:  Borrowed this helper function from core React-Semantic-UI 
 * @see https://github.com/Semantic-Org/Semantic-UI-React/blob/master/src/modules/Dropdown/utils/getMenuOptions.js
 * 
 */
function getMenuOptions(config) {
  const {
    additionLabel,
    additionPosition,
    allowAdditions,
    deburr,
    multiple,
    options,
    search,
    searchQuery,
    value,
  } = config

  let filteredOptions = options

  // filter out active options
  if (multiple) {
    filteredOptions = filter(filteredOptions, (opt) => !includes(value, opt.value))
  }

  // filter by search query
  if (search && searchQuery) {
    if (isFunction(search)) {
      filteredOptions = search(filteredOptions, searchQuery)
    } else {
      // remove diacritics on search input and options, if deburr prop is set
      const strippedQuery = deburr ? deburr(searchQuery) : searchQuery

      const re = new RegExp(escapeRegExp(strippedQuery), 'i')

      filteredOptions = filter(filteredOptions, (opt) =>
        re.test(deburr ? deburr(opt.text) : opt.text),
      )
    }
  }

  // insert the "add" item
  if (allowAdditions && search && searchQuery && !some(filteredOptions, { text: searchQuery })) {
    const additionLabelElement = React.isValidElement(additionLabel)
      ? React.cloneElement(additionLabel, { key: 'addition-label' })
      : additionLabel || ''

    const addItem = {
      key: 'addition',
      // by using an array, we can pass multiple elements, but when doing so
      // we must specify a `key` for React to know which one is which
      text: [additionLabelElement, <b key='addition-query'>{searchQuery}</b>],
      value: searchQuery,
      className: 'addition',
      'data-additional': true,
    }
    if (additionPosition === 'top') filteredOptions.unshift(addItem)
    else filteredOptions.push(addItem)
  }

  return filteredOptions
}

export default class VirtualisedDropdown extends Dropdown {
  
  menuRef = React.createRef(null)
  
  renderMenu = () => {
    const { search, noResultsMessage, customItem, customNoResults } = this.props
    const { open } = this.state
    const ariaOptions = this.getDropdownMenuAriaOptions()
    
    const options = getMenuOptions({
      value: this.state.value,
      options: this.props.options,
      searchQuery: this.state.searchQuery,
      additionLabel: this.props.additionLabel,
      additionPosition: this.props.additionPosition,
      allowAdditions: this.props.allowAdditions,
      deburr: this.props.deburr,
      multiple: this.props.multiple,
      search: this.props.search,
    })

    if (!open) {
      return null
    }

    if (noResultsMessage !== null && search && isEmpty(options) && size(this.state.searchQuery) === 0) {
      return (
        <Dropdown.Menu open>
          {!customNoResults && (
            <DropdownItem>
              <div className='message'>{noResultsMessage}</div>
            </DropdownItem>
          )}

          {customNoResults}
          {customItem}
        </Dropdown.Menu>
      )
    }
    
    if (noResultsMessage !== null && search && isEmpty(options)) {
      return (
        <Dropdown.Menu open>
          <DropdownItem>
            <div className='message'>{noResultsMessage}</div>
          </DropdownItem>
        </Dropdown.Menu>
      )
    }

    return (
        <div {...ariaOptions} className='menu transition visible virtualised-menu' ref={this.menuRef}>
          <ViewportList
            viewportRef={this.menuRef}
            items={options}>
              {(item) => DropdownItem.create(
                {...item},{
                  generateKey: false,
                  overrideProps: (predefinedProps) => ({
                    onClick: (e, item) => {
                      
                      if (predefinedProps.onClick) {
                        predefinedProps.onClick(e, item)
                      }

                      this.handleItemClick(e, item)
                    },
                  }),
                }
              )}
          </ViewportList>
          {customItem}
        </div>
    )
  }
}