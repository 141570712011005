import React, { useEffect, useState } from 'react'
import {
  ProSidebar,
  Menu,
  SidebarHeader,
  SidebarContent,
} from 'react-pro-sidebar'


import { Trans, useTranslation } from 'react-i18next'
import { Button, Grid, Icon } from 'semantic-ui-react'
import { ListView, MessageView } from './preview'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, size } from 'lodash'
import { templateIcons } from '../../constants'
import { findTextComponent, findTextComponentDescription, setError, subscribe, unSubscribe } from '../../utils/helper'
import { Fragment } from 'react'
import { builderActions } from '../../actions/builderActions'
import { AppPreview } from '../partials/AppPreview'
import { noticeService } from '../../api'
import { noticesActions } from '../../actions/noticesActions'
import Config from '../../config'
import PreviewModal from '../partials/PreviewModal'
import PreviewModalView from '../partials/PreviewModalView'
import { PostCreateEventRequest, PostCreateNoticeRequest } from '../../api/requestObjects'
import FormMessages from '../partials/FormMessages'

const BuilderSidePreview = ({ visible }) => {
  
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const builder = useSelector(state => state.builder.previewData)
  const errors = useSelector(state => state.notices.errors)
  const toggled = useSelector(state => state.builder.togglePreview)
  const busyIndicator = useSelector(state => state.builder.busyIndicator)

  const [builderPayload, setBuilderPayload] = useState(null)
  const [previewLoader, setPreviewLoader] = useState(false)
  const [previewData, setPreviewData] = useState(null)
  const [isValid, setValid] = useState(true)
  
  const [preview, setPreview] = useState({
      showModal: false,
      selectedPlatform: "localApp",
      onClose: () => {},
  })

  const description = findTextComponentDescription(builder.messageView.items)
  const textComponent = findTextComponent(builder.messageView.items)

  const items = [
    {
      image: builder.listItem.icon,
      title: builder.listItem.title,
      createdAt: builder.listItem.createdAt,
      pinItem: builder.listItem.pinItem,
      important: builder.listItem.important,
      description: description,
      hasText: textComponent !== null
    }
  ]

  const handleToggle = (toggled) => dispatch(builderActions.togglePreview(toggled))

  const handleClosePreview = () => {
    setPreview({
        showModal: false,
        onClose: () => {},
        header: "",
        selectedPlatform: "localApp"
    })

    dispatch(noticesActions.clearMessages())
  }

  const handleShowPlatformPreview = ({platform}) => {
    setPreview((prev) => ({...prev, selectedPlatform: platform}))
  }

  const handleShowPreviews = () => {
    
    // Do nothing when there is no data
    if (!builderPayload) return

    // Begin loading
    setPreviewLoader(true)

    setPreview({
        showModal: true,
        onClose: () => handleClosePreview(),
        header: t("notices.detail.modal.showPreview"),
        selectedPlatform: "localApp"
    })
    
    const deadlineDate = moment(builderPayload.deadline_at)
    const deadlineTime = moment(builderPayload.deadline_time)
    const payload = {
      ...builderPayload,
      showPublish: false,
      components: builderPayload.rawComponents,
      deadlineAt: deadlineDate
          .hours(deadlineTime.hours())
          .minutes(deadlineTime.minutes())
          .seconds(deadlineTime.seconds())
          .toISOString(),
      noticeTitle: builderPayload.noticeTitle ?? "",
      sendDeadlineReminders: builderPayload.send_deadline_reminders,
    }

    // Fetch data for preview first
    noticeService.getLiveNoticePreview(payload)
        .then(response => {
            
            setPreviewLoader(false)
            setPreviewData({
              ...response.data,
              localApp: {
                listItem: {
                  ...builder.listItem,
                  image: builder.listItem.icon,
                },
                items: builder?.messageView?.items ?? [],
              }
            })

            // Init lightbox
            // const images = getLightBoxMedia({response: response.data?.app ?? {}})
            // setLightBox(prev => ({...prev, images: images}))
        }).catch(error => {
            dispatch(noticesActions.error(error))
            setPreviewLoader(false)
            setPreviewData(null)

            // setLightBox(prev => ({...prev, images: []}))
        })
  }

  useEffect(() => {
    const handleSubscription = (event) => {
      if (event.detail.origin !== Config.ssoClientRoot) return
      
      if (!event.detail.data) {
        setValid(false)
        return
      }

      const data = event.detail.data ?? {}
      const noticeRequest = new PostCreateNoticeRequest({
        ...data,
        components: data.rawComponents,
      })

      if (data.isExistingTemplate) {
        setValid(size(noticeRequest.templateValidator()) === 0 && data.canPublish)
      } else {
        setValid(size(noticeRequest.validator()) === 0 && data.canPublish)
      }

      setBuilderPayload(event.detail.data ?? null)
    }

    subscribe('onUpdatePreviewPayload', handleSubscription)
    
    return () => {
      unSubscribe('onUpdatePreviewPayload', handleSubscription)
    }
  }, [])

  if (!visible) {
    return null
  }

  return (
    <Fragment>
      <PreviewModal 
        header={preview.header}
        open={preview.showModal}
        onClose={preview.onClose}
        cancelText={t("general.close")}
        loading={previewLoader}
        message={
            <Grid>
                <Grid.Column width={16}>
                    {builderPayload && (
                      <p>
                          <Trans 
                              components={{
                                  strong: <strong/>
                              }}
                              values={{
                                  title: builderPayload.title
                              }}
                              i18nKey="notices.detail.preview.description" />
                      </p>
                    )}
                    <Button 
                        onClick={() => handleShowPlatformPreview({platform: "localApp"})} 
                        className={['secondary', preview.selectedPlatform == 'localApp' && 'primary solid'].filter(Boolean).join(' ')}>
                        <Icon name='mobile alternate' />{t("notices.detail.preview.button.app")}
                    </Button>
                    <Button 
                        onClick={() => handleShowPlatformPreview({platform: "sms"})} 
                        className={['secondary', preview.selectedPlatform == 'sms' && 'primary solid'].filter(Boolean).join(' ')}>
                        <Icon name='wechat' />{t("notices.detail.preview.button.sms")}
                    </Button>
                    <Button 
                        onClick={() => handleShowPlatformPreview({platform: "email"})} 
                        className={['secondary', preview.selectedPlatform == 'email' && 'primary solid'].filter(Boolean).join(' ')}>
                        <Icon name='mail' />{t("notices.detail.preview.button.email")}
                    </Button>
                </Grid.Column>
                
                <Grid.Column width={16}>
                    {/* <Lightbox
                        className='lightbox'
                        open={lightBox.open}
                        close={() => setLightBox(prev => ({ ...prev, open: false, activeIndex: 0 }))}
                        slides={lightBox.images}
                        plugins={[Thumbnails, Download, VideoPlugin]}
                        index={lightBox.activeIndex}
                        render={{
                            slide: ({ slide }) => renderCustomSlide(slide),
                            thumbnail: (props) => renderCustomThumbnail(props)
                        }}
                    /> */}
                    {errors && (
                        <FormMessages errors={errors} />
                    )}
                    <PreviewModalView 
                        // onShowFiles={size(lightBox.images) > 0 ? () => setLightBox(prev => ({...prev, open: true})) : null}
                        data={{
                            platform: preview.selectedPlatform, 
                            content: previewData ?? {}
                        }} />
                </Grid.Column>
            </Grid>
        } />
      <div className={`preview-sidebar-floating-btn-wrapper visible-md-down ${toggled ? 'open' : 'closed'}`}>
        <Button
          onClick={() => handleToggle(!toggled)}
          icon="eye">
        </Button>
      </div>
      <ProSidebar 
        breakPoint="lg"
        className="secondary-sidebar preview"
        toggled={toggled}
        onToggle={handleToggle}>

        <SidebarHeader>
          <div className='secondary-nav-header-title flex items-center'>
            <h3 className='flex-auto'>
              <Icon name="eye" />
              {t('previewNav.title')}
            </h3>
            <Button 
              size='tiny' 
              disabled={!isValid || busyIndicator?.loading}
              onClick={handleShowPreviews} 
              className='primary'>
                {t('previewNav.allPlatforms')}
            </Button>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu>
            <Grid>
              <Grid.Row>
                {(builder.listItem.title || size(builder.messageView.items) > 0) && (
                  <Grid.Column>
                    <label className="label ">{t('previewNav.listView')}</label>
                    <ListView 
                      items={items}
                      contents={builder.messageView.items}/>
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {/* {size(builder.messageView.items) === 0 && <label className="label">{t('previewNav.messageView')}</label>} */}
                  {size(builder.messageView.items) > 0 && (
                    <AppPreview
                      content={
                        <MessageView 
                          className={"no-margin no-padding"}
                          listItem={builder.listItem} 
                          items={builder.messageView.items} />
                      } />
                  )}

                  {(size(builder.messageView.items) === 0 && !builder.listItem.title) && (
                    <div style={{marginTop: 15}}>
                      <h3 className="center-align grey-text">{t("previewNav.noPreviewHeader")}</h3><br/>
                      <p className="no-margin center-align grey-text">{t("previewNav.noPreview.message")}</p>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu>
        </SidebarContent>

      </ProSidebar>
    </Fragment>
  )
}

export default BuilderSidePreview
