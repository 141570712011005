/**
 * 
 * Helper functions, tools and functionalities will come here
 */

import DineroFactory from "dinero.js"
import i18next, { t } from "i18next"
import { filter, find, first, get, isArray, isBoolean, isEmpty, isString, isUndefined, map, mapKeys, mapValues, pickBy, uniq, uniqBy, groupBy, orderBy, size, isNull, omitBy, forEach, last, flatten, isNil } from "lodash"
import moment from "moment-timezone"
import { parsePhoneNumber } from "react-phone-number-input"
import { matchPath } from "react-router"
import { calendarConstants, componentPriorities, constants, formTypes, messageBuilder, paymentTypes, templateIcons, timelineComponents, votingTypes } from "../constants"

import draftToHtml from "./draft-to-html"
import htmlToDraft from "html-to-draftjs"
import { ContentState, EditorState, convertToRaw, convertFromRaw } from "draft-js"
import { stateFromMarkdown } from 'draft-js-import-markdown'
import { stateToMarkdown } from "draft-js-export-markdown"
import routes from "../routes/route-constants"
import { arrayMoveImmutable } from "array-move"
import { handleNewLine, insertNewUnstyledBlock } from 'draftjs-utils'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import { Modifier } from "draft-js"
import { v4 } from "uuid"
import Config from "../config"

const availableLanguages = mapKeys(map(i18next.options.resources, (value, key) => key))

export const formatMoney = (n, c, d, t) => {
  var c = isNaN(c = Math.abs(c)) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

export const formatMoneyLocale = (money, locale, currency) => {
  // const formattedVal = new window.Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(money)
  
  if (currency) {
    const dinero = DineroFactory({ amount: money, currency: currency })
    const formattedVal = dinero.toFormat()
    return formattedVal
  }
  
  const dinero = DineroFactory({ amount: money })
  const formattedVal = dinero.toFormat()
  return formattedVal.replace("ZAR", "R")
}

/**
 * Convert a string into a title case string
 * 
 * @param {String} str 
 * @returns 
 */
export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

/**
 * Format Event Start and End Dates to Display formatt
 * 
 * @param {import("moment").Moment} startDate 
 * @param {import("moment").Moment} endDate 
 * @param {Boolean} allDay 
 * @returns 
 */
export function formatEventDates(startDate, endDate = null, allDay = false) {
  
  // When we dont have and end date, which should be very unlikely, but oh well
  if (!endDate) {
    return startDate.format("dddd, DD ")
      .concat(" @ ")
      .concat(startDate.format("HH:mm"))
  }

  // When the event is an all day event, we get rid of the times
  if (allDay) {
    return startDate.format("dddd, DD ")
      .concat(" - ")
      .concat(endDate.format("dddd, DD "))
  }
  
  // When the start date and end date is the same, we can morph the dates and only show the times
  if (startDate.isSame(endDate, 'dates')) {
    
    return startDate.format("dddd, DD ")
      .concat(" | ")
      .concat(startDate.format("HH:mm"))
      .concat(" - ")
      .concat(endDate.format("HH:mm"))

  }
  
  // When the start and end dates are not the same
  return startDate.format("dddd, DD ")
    .concat(" @")
    .concat(startDate.format("HH:mm"))
    .concat(" - ")
    .concat(endDate.format("dddd, DD "))
    .concat(" @")
    .concat(endDate.format("HH:mm"))
}

export const formatMobileNumber = (text) => {

  if (!text) return text

  return text.replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, "$1 $2 $3 $4")
}

/**
 * Create preview tags for components by attaching priority and grouping
 * 
 * @param {Array<{Component}>} items 
 * @returns {Array<{Component}>}
 */
export const getComponentTagsOrder = (items) => {

  // Grab all the components in this build, and create a copy
  const _items = [...map(items)]

  // Group these components by their component type
  const grouped = groupBy(_items, "type")

  // Transform the components to preview items
  const transformed = mapValues(grouped, group => {

    // Lets get the component type from this group by grabbing the first child
    const type = first(group).type

    return {
      type: type,
      items: map(group),
      order: componentPriorities[type],
    }
  })

  // return orderBy(map(transformed), ["order"], ["asc"])
  return orderBy(map(transformed), ["order"], ["desc"])
}

/**
 * Create preview components by grouping the items in files without flattening the entire List/Array
 * 
 * @param {Array<{Component}>} items 
 * @param {String} key The key that will be used to group the sequence 
 * @returns {Array<{Component}>}
 */
export const getComponentGroupedSequence = (items, key = templateIcons.file) => {

  // Create a fresh copy of this list
  const _items = [...map(items)]

  // Initialize an empty list to house temporary file components
  let _files = {}

  const sequence = map(_items, (component, k) => {

    // Skip Albums
    if (component.data._type === templateIcons.album) {
      _files = {}
      return component
    }

    // Clear list when we reach or line breaks
    if (component.type !== key) {
      _files = {}
      return component
    }

    const hasNextFile = ((component.type === key) && _items[k + 1]?.type === component.type && _items[k + 1]?.data?._type !== templateIcons.album)

    // We don't have to add the upcoming file item in the list, as we will just append it to the last item to be included
    if (hasNextFile) {
      _files = {
        ..._files,
        ...component.data.files
      }
      return null
    }

    return {
      ...component,
      data: {
        ...component.data,
        files: {..._files, ...component.data.files}
      },
    }
  })

  return filter(sequence, s => s !== null)
}

/**
 * Configure how an Album Component should display its images
 * 
 * @param {Array<{Component}>} items 
 * @returns {Array<{Component}>}
 */
export const getAlbumConfig = (items) => {

  const settings = {
    1: {
      main: 1,
      cols: 'equal',
      thumbs: 0,
      thumbChunks: 0
    },
    2: {
      main: 2,
      cols: 'equal',
      thumbs: 0,
      thumbChunks: 0,
    },
    3: {
      main: 1,
      cols: 'equal',
      thumbs: 2,
      thumbWidth: 4,
      thumbChunks: 1,
    },
    4: {
      main: 2,
      cols: 'equal',
      thumbs: 2,
      thumbWidth: 4,
      thumbChunks: 1,
    },
    5: {
      main: 1,
      cols: '3',
      thumbs: 4,
      thumbWidth: 6,
      thumbChunks: 2,
    },
    7: {
      main: 1,
      cols: 3,
      thumbs: 6,
      thumbWidth: 10,
      thumbChunks: 3,
    },
  }

  // How many images do we have?
  const total = size(items)

  // Using the number of images we can now get the Grid settings, when we are out of bounds
  // we will use the [7+] setting by default
  return settings[total] ?? settings[7]
}

/**
 * Configure when an Image Component should transform into an Album Component, and also handle when grouped File Components should be transformed to be single components
 * 
 * @param {Array<{Component}>} items 
 * @returns {Array<{Component}>}
 */
export const getTransformedFiles = (items) => {

  // Create a fresh copy of this list
  const _items = [...map(items)]

  const transformedData = []
  
  map(_items, (component, k) => {

    // We are only looking at Image and File components
    if (![templateIcons.image, templateIcons.file].includes(component.data._type)) {
      transformedData.push(component)
      return;
    }

    // When the number of images in a single component have not reached the max to be an album
    // We should get all the images out and add them as single components
    if ((component.data._type === templateIcons.image) && size(component.data.files) > messageBuilder.NUM_IMAGES_TO_ALBUM) {
      // Update the [_type] field to an Album
      const _component = {
        ...component,
        _type: templateIcons.album,
        data: {
          ...component.data,
          _type: templateIcons.album,
          resourceCategoryIds: component.data.resourceCategoryIds ?? null,
        }
      }

      transformedData.push(_component)
      return;
    }

    if (component.data._type === templateIcons.file) {

      // And now for our last trick, we will make all files that have been uploaded as a group, appear as single files
      map(component.data.files, file => {
        const _component = {
          type: component.type,
          id: file.id,
          data: {
            originalId: file.id,
            _type: component.type,
            id: file.id,
            files: {
              [file.id]: file
            },
            resourceCategoryIds: component.data.resourceCategoryIds ?? null
          }
        }
        transformedData.push(_component)
      })
      return
    }

    // And now for our last trick, we will make all images that have not reached album status, appear as single images
    map(component.data.files, file => {
      const _component = {
        type: component.type,
        _parentId: component.id,
        id: file.id,
        data: {
          originalId: file.id,
          albumTitle: null,
          _type: templateIcons.image,
          id: file.id,
          files: {
            [file.id]: file
          },
          resourceCategoryIds: component.data.resourceCategoryIds ?? null
        }
      }
      transformedData.push(_component)
    })
  })

  return transformedData
}

/**
 * Transform {Image and File} Components for update
 * 
 * @param {Array<{Component}>} items 
 * @param {string[]} originalComponentIds 
 * @returns {Array<{Component}>}
 */
export const getTransformedFilesForUpdate = (items, originalComponentIds = []) => {

  const results = getTransformedFiles(items).map((component) => {
    const type = component.data._type ?? component.type
    // We should be able to tell if the image is a recent upload or not, for recent uploads, the files will still contain their File Blob
    if ([templateIcons.image, templateIcons.file].includes(type)) {
      const isOriginal = originalComponentIds.includes(component.id)
      return {
        ...component,
        type: type,
        data: {
          ...component.data,
          originalId: isOriginal ? component.data.originalId : undefined
        },
      }
    }

    return {
      ...component,
      type: type
    }
  })
  
  return results
}

/**
 * Create Component content from the given item data
 * @param {Component} item 
 */
const transformComponent = (item) => {

  switch (item.type) {
    case templateIcons.notice: {
      return {
        type: item.type,
        id: item.id,
      }
    }
    case templateIcons.event: {
      let _selectedEvent = null

      if (item.attributes.event_id) {
        _selectedEvent = transformDataToSelectedEvent({
          id: item.attributes.event_id,
          title: item.attributes.title,
          source: item.attributes?.source,
          endDate: item.attributes.end_date,
          location: item.attributes.address,
          startDate: item.attributes.start_date,
        })
      }

      const mediaType = item.attributes.description_media_type ?? messageBuilder.MARKUP.plain
      const renderedContent = renderMarkup({
        type: mediaType,
        initial: item.attributes.description ?? '',
      })

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          title: item.attributes.title,
          content: renderedContent.markdown, // @NOTE: The converted rendered output is converted to the give media type, so we should not be fazed by the variable "markdown" its just legacy functions which will be updated.
          jsonContent: renderedContent.rawContents,
          mediaType: mediaType,
          addToCalendar: item.attributes.add_calendar,
          location: item.attributes.address,
          description: renderedContent.markdown, // @NOTE: The converted rendered output is converted to the give media type, so we should not be fazed by the variable "markdown" its just legacy functions which will be updated.
          allDay: item.attributes.all_day,
          eventId: item.attributes.event_id,
          selectedEvent: _selectedEvent,
          startDate: moment(item.attributes.start_date).format("yyyy-MM-DD"),
          startTime: moment(item.attributes.start_date).format("HH:mm"),
          endDate: moment(item.attributes.end_date).format("yyyy-MM-DD"),
          endTime: moment(item.attributes.end_date).format("HH:mm"),
          previewEndTime: moment(item.attributes.end_date).format("HH:mm"),
          previewStartTime: moment(item.attributes.start_date).format("HH:mm"),
          previewEndDate: moment(item.attributes.end_date).format("D MMM 'YY"),
          previewStartDate: moment(item.attributes.start_date).format("D MMM 'YY"),
        }
      }
    }
    case templateIcons.attachment:
    case templateIcons.file: {

      // TODO: Catering for future for multiple attachments upload
      const _files = [{
        index: 0,
        type: item.type,
        originalId: item.id,
        file: {
          id: item.id,
          blob: null,
          url: encodeURI(item.attributes.url)
        },
        loading: null,
        id: item.id,
        url: encodeURI(item.attributes.url),
        thumb: encodeURI(item.attributes.url),
        mime: item.attributes.mime_type,
        fileName: item.attributes.file_name,
        fileSize: item.attributes.bytes,
      }]

      return {
        type: item.type,
        _type: item.type,
        id: item.id,
        name: "File",
        data: {
          _type: item.type,
          files: mapKeys(_files, f => f.id),
          originalId: item.id,
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        },
      }
    }
    case templateIcons.payment: {
      const dinero = DineroFactory({ amount: item.attributes.amount_smallest_unit ?? 0 })
      const outstandingDinero = DineroFactory({ amount: item.attributes.outstanding_amount_smallest_unit ?? 0 })
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          amount: item.attributes.amount_smallest_unit ?? 0,
          title: item.attributes.title ?? '',
          type: item.attributes.type,
          floatAmount: dinero.toUnit(),
          showQuantity: !isNil(item.attributes.item_quantity_limit),
          maxQuantityLimit: item.attributes.item_quantity_limit,
          numItemsRemaining: item.attributes.num_items_remaining,
          amountPaid: item.attributes.paid_amount_smallest_unit ?? 0,
          amountOutstanding: item.attributes.outstanding_amount_smallest_unit ?? 0,
          amountOutstandingFloatAmount: outstandingDinero.toUnit(),
          currency: item.attributes.currency ?? DineroFactory.defaultCurrency,
          comment: ""
        }
      }
    }
    case templateIcons.text: {

      const type = item.attributes.media_type ?? messageBuilder.MARKUP.markdown

      const renderedHtml = renderMarkup({
        type: type,
        initial: item.attributes.text,
      })

      const defaultContentState = renderedHtml.rawContents

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          content: renderedHtml.markdown,
          jsonContent: defaultContentState,
          mediaType: type,
        }
      }
    }
    case templateIcons.video: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: encodeURI(item.attributes.url),
          fileSize: item.attributes.bytes,
          mimeType: item.attributes.mime_type,
          fileType: item.attributes.mime_type,
          fileName: item.attributes.file_name,
          thumbnailUrl: encodeURI(item.attributes.thumbnail_url),
        }
      }
    }
    case templateIcons.vimeo:
    case templateIcons.youTube: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: item.attributes.url,
          videoId: item.attributes.video_id,
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.noticeAttachment: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          noticeId: item.attributes.notice_id
        }
      }
    }
    case templateIcons.album: {

      const _files = map(item.attributes.medias, (media, i) => {
        return {
          index: i,
          type: media.type,
          originalId: media.id,
          file: {
            url: encodeURI(media.medium_url),
            id: media.id,
            blob: null,
            mime: media.mime_type,
          },
          loading: null,
          id: media.id,
          url: encodeURI(media.url),
          thumb: encodeURI(media.thumbnail_url),
          mime: media.mime_type,
          fileSize: media.bytes,
          mimeType: media.mime_type,
          fileName: media?.file_name,
          mediumUrl: encodeURI(media.medium_url),
          thumbnailUrl: encodeURI(media.thumbnail_url),
        }
      })

      return {
        id: item.id,
        type: templateIcons.image,
        _type: templateIcons.album,
        data: {
          originalId: item.id,
          albumTitle: item.attributes.name,
          _type: item.type,
          id: item.id,
          files: mapKeys(_files, f => f.id),
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.image: {

      const _files = [{
        index: 0,
        type: item.type,
        originalId: item.id,
        file: {
          url: encodeURI(item.attributes.medium_url),
          id: item.id,
          blob: null,
          mime: item.attributes.mime_type,
        },
        loading: null,
        id: item.id,
        url: encodeURI(item.attributes.url),
        thumb: encodeURI(item.attributes.thumbnail_url),
        mime: item.attributes.mime_type,
        fileSize: item.attributes.bytes,
        mimeType: item.attributes.mime_type,
        fileType: item.attributes.mime_type,
        fileName: item.attributes.file_name,
        mediumUrl: encodeURI(item.attributes.medium_url),
        thumbnailUrl: encodeURI(item.attributes.thumbnail_url),
      }]

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          albumName: null,
          _type: item.type,
          id: item.id,
          files: mapKeys(_files, f => f.id),
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.location: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          latitude: item.attributes.latitude,
          longitude: item.attributes.longitude,
          location: item.attributes.address,
          originalPlace: item.attributes.address
        }
      }
    }
    case templateIcons.url: {

      let website = null
      
      try {
        website = new URL(item.attributes.url)
      } catch (error) {
        website = null
      }

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: item.attributes.url,
          title: item.attributes.title,
          host: website?.host ?? null,
          description: item.attributes.description,
          thumbnail: item.attributes.thumbnail_url,
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.deadline: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
        }
      }
    }
    case formTypes.yesNo.post_name:
    case formTypes.textField.post_name:
    case formTypes.fileUpload.post_name:
    case formTypes.imageUpload.post_name:
    case formTypes.multipleChoice.post_name: {
      return {
        type: templateIcons.form,
        id: item.id,
        name: "Form",
        data: {
          originalId: item.id,
          question: item.attributes.title,
          min: item.attributes.min ?? 0,
          max: item.attributes.max ?? 2,
          isRequired: item.attributes.required,
          formType: item.type,
          singleSelect: item.attributes.single_selection,
          // We are transforming the options because we dont want the IDs to show up on the UI as they are UUIDs
          options: map(item.attributes.options ?? [], (o, i) => {
            return { id: i + 1, option: o.option }
          })
        }
      }
    }
    case templateIcons.vote: {

      // @TODO: Finalise Campaign image and Option Image return display
      
      const convertedText = renderMarkup({
        type: messageBuilder.MARKUP.markdown,
        initial: item.attributes.confirmation_markdown,
      })

      let resultAt = null

      if (isNull(item.attributes.results_visible_at)) {
        resultAt = 'invisible'
      } else {
        
        resultAt = 'immediately'

        // When the result_at is the same as the deadline date
        if (moment(item.attributes.results_visible_at).isSame(moment(item.attributes.deadline_at))) {
          resultAt = 'after_deadline'
        }
      }

      if (item.attributes.voter_results_view_option) {
        resultAt = item.attributes.voter_results_view_option
      }

      return {
        type: templateIcons.vote,
        id: item.id,
        name: "Vote",
        data: {
          originalId: item.id,
          voteType: item.attributes.campaign_type,
          canChangeResults: item.attributes.can_change_answer,
          visibleFor: item.attributes.results_system_access,
          resultAt: resultAt,
          shouldAcceptConfirmationDeclaration: !isEmpty(item.attributes.confirmation_markdown),
          declarationSnippet: item.attributes.confirmation_markdown,
          quorumPercentage: item.attributes.quorum_percentage,
          shouldBeAnonymous: item.attributes.is_voter_anonymous,
          numAssignedVotesPerson: item.attributes.num_assigned_votes_person,
          minRequiredVotesPerPerson: item.attributes.min_required_votes_per_person,
          content: convertedText.markup,
          jsonContent: convertedText.rawContents,
          // We are transforming the options because we dont want the IDs to show up on the UI as they are UUIDs
          options: map(item.attributes.options ?? [], (o, i) => {
            const convertedText = renderMarkup({
              type: messageBuilder.MARKUP.markdown,
              initial: o.description,
            })

            const _files = []

            if (o.image) {
              _files.push({
                type: item.type,
                originalId: o.id,
                file: {
                  url: encodeURI(o.image),
                  id: o.id,
                  blob: null,
                  mime:o.mime_type,
                },
                loading: null,
                id: o.id,
                url: encodeURI(o.image),
                thumb: encodeURI(o.image),
                mime:o.mime_type,
                fileSize:o.bytes,
                mimeType:o.mime_type,
                fileType:o.mime_type,
                fileName:o.file_name,
                mediumUrl: encodeURI(o.image),
                thumbnailUrl: encodeURI(o.image),
              })
            }

            return { 
              id: o.id,
              originalId: o.id, 
              title: o.title, 
              description: o.description, 
              content: convertedText.markup,
              jsonContent: convertedText.rawContents,
              files: mapKeys(_files, f => f.id)
            }
          })
        }
      }
    }
    case templateIcons.iframe: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: item.attributes.url,
        }
      }
    }
    case templateIcons.contact: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          website: item.attributes.website,
          birthday: item.attributes.birthday,
          lastName: item.attributes.last_name,
          email: item.attributes.primary_email,
          firstName: item.attributes.first_name,
          jobTitle: item.attributes.title_or_role ?? "",
          description: item.attributes.description,
          facebookUrl: item.attributes.facebook_url,
          twitterHandle: item.attributes.twitter_handle,
          secondaryEmail: item.attributes.secondary_email,
          phoneNumber: item.attributes.primary_phone_number,
          secondaryPhoneNumber: item.attributes.secondary_phone_number,
        }
      }
    }
    case templateIcons.progressBar: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          title: item.attributes.title,
          description: item.attributes.description,
          fromDisplay: item.attributes.from_display,
          toDisplay: item.attributes.to_display,
          currentDisplay: item.attributes.current_display,
          averageDisplay: item.attributes.average_display,
          showAverageMarker: item.attributes.average_percentage !== "",
          currentPercentage: parseFloat(item.attributes.current_percentage),
          averagePercentage: parseFloat(item.attributes.average_percentage),
          currentTint: item.attributes.current_tint,
          barTint: item.attributes.bar_tint,
        }
      }
    }
    case templateIcons.customTemplate: {
      return {
        type: item.type,
        id: item.id,
      }
    }
    default:
      return {
        type: item.type,
        id: item.id,
      }
  }
}

/**
 * Create Component content from the given item data for Template Data
 * @param {Component} item 
 */
const transformTemplateComponent = (item) => {
  
  const emptyComponent = {
    type: item.type,
    id: item.id
  }

  switch (item.type) {
    case templateIcons.event: {
      if (isEmpty(item.attributes)) {
        return emptyComponent
      }

      let _selectedEvent = null

      if (item.attributes?.event_id) {
        _selectedEvent = transformDataToSelectedEvent({
          id: item.attributes.event_id,
          title: item.attributes.title,
          source: item.attributes.source,
          endDate: item.attributes.end_date,
          location: item.attributes.address,
          startDate: item.attributes.start_date,
        })
      }

      const mediaType = item.attributes.description_media_type ?? messageBuilder.MARKUP.plain
      const renderedContent = renderMarkup({
        type: mediaType,
        initial: item.attributes.description ?? '',
      })

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          title: item.attributes.title,
          content: renderedContent.markdown,
          jsonContent: renderedContent.rawContents,
          addToCalendar: item.attributes.add_calendar,
          location: item.attributes.address,
          mediaType: mediaType,
          description: renderedContent.markdown,
          allDay: item.attributes.all_day,
          eventId: item.attributes.event_id,
          selectedEvent: _selectedEvent,
          startDate: moment(item.attributes.start_date).format("yyyy-MM-DD"),
          startTime: moment(item.attributes.start_date).format("HH:mm"),
          endDate: moment(item.attributes.end_date).format("yyyy-MM-DD"),
          endTime: moment(item.attributes.end_date).format("HH:mm"),
          previewEndTime: moment(item.attributes.end_date).format("HH:mm"),
          previewStartTime: moment(item.attributes.start_date).format("HH:mm"),
          previewEndDate: moment(item.attributes.end_date).format("D MMM 'YY"),
          previewStartDate: moment(item.attributes.start_date).format("D MMM 'YY"),
        }
      }
    }
    case templateIcons.attachment:
    case templateIcons.file: {
      
      if (isEmpty(item.attributes)) {
        return {
          ...emptyComponent,
          name: "File",
        }
      }

      // TODO: Catering for future for multiple attachments upload
      const _files = [{
        type: item.type,
        originalId: item.id,
        file: {
          id: item.id,
          blob: null,
          url: encodeURI(item.attributes.url)
        },
        loading: null,
        id: item.id,
        url: encodeURI(item.attributes.url),
        thumb: encodeURI(item.attributes.url),
        mime: item.attributes.mime_type,
        fileName: item.attributes.file_name,
        fileSize: item.attributes.bytes,
      }]

      return {
        type: item.type,
        _type: item.type,
        id: item.id,
        name: "File",
        data: {
          _type: item.type,
          files: mapKeys(_files, f => f.id),
          originalId: item.id,
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        },
      }
    }
    case templateIcons.payment: {
      
      const dinero = DineroFactory({ amount: item.attributes?.amount_smallest_unit ?? 0 })
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          amount: item.attributes?.amount_smallest_unit ?? 0,
          title: item.attributes?.title ?? '',
          type: item.attributes?.type ?? paymentTypes.fixed.value,
          floatAmount: dinero.toUnit(),
          showQuantity: !isNil(item.attributes?.item_quantity_limit),
          maxQuantityLimit: item.attributes?.item_quantity_limit,
          currency: DineroFactory.defaultCurrency,
          amountPaid: 0,
          amountOutstanding: 0,
          amountOutstandingFloatAmount: 0,
        }
      }
    }
    case templateIcons.text: {

      const type = item.attributes?.media_type ?? messageBuilder.MARKUP.markdown

      const renderedHtml = renderMarkup({
        type: type,
        initial: item.attributes?.text ?? '',
      })

      const defaultContentState = renderedHtml.rawContents

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          content: renderedHtml.markdown,
          jsonContent: defaultContentState,
          mediaType: type,
        }
      }
    }
    case templateIcons.video: {
      if (isEmpty(item.attributes)) {
        return emptyComponent
      }

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: encodeURI(item.attributes.url),
          fileSize: item.attributes.bytes,
          mimeType: item.attributes.mime_type,
          fileType: item.attributes.mime_type,
          fileName: item.attributes.file_name,
          thumbnailUrl: encodeURI(item.attributes.thumbnail_url),
        }
      }
    }
    case templateIcons.vimeo:
    case templateIcons.youTube: {
      
      if (isEmpty(item.attributes)) {
        return emptyComponent
      }

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: item.attributes.url,
          videoId: item.attributes.video_id,
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.album: {

      const _files = map(item.attributes.medias, media => {
        return {
          type: media.type,
          originalId: media.id,
          file: {
            url: encodeURI(media.url),
            id: media.id,
            blob: null,
            mime: media.mime_type,
          },
          loading: null,
          id: media.id,
          url: encodeURI(media.url),
          thumb: encodeURI(media.url),
          mime: media.mime_type,
          fileSize: media.bytes,
          mimeType: media.mime_type,
          fileName: media?.file_name,
          mediumUrl: encodeURI(media.url),
          thumbnailUrl: encodeURI(media.url),
        }
      })

      return {
        id: item.id,
        type: templateIcons.image,
        _type: templateIcons.album,
        data: {
          originalId: item.id,
          albumTitle: item.attributes.name,
          _type: item.type,
          id: item.id,
          files: mapKeys(_files, f => f.id),
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.image: {

      if (isEmpty(item.attributes)) {
        return {
          ...emptyComponent,
          _type: templateIcons.album,
        }
      }

      const _files = [{
        type: item.type,
        originalId: item.id,
        file: {
          url: encodeURI(item.attributes.url),
          id: item.id,
          blob: null,
          mime: item.attributes.mime_type,
        },
        loading: null,
        id: item.id,
        url: encodeURI(item.attributes.url),
        thumb: encodeURI(item.attributes.url),
        mime: item.attributes.mime_type,
        fileSize: item.attributes.bytes,
        mimeType: item.attributes.mime_type,
        fileType: item.attributes.mime_type,
        fileName: item.attributes.file_name,
        mediumUrl: encodeURI(item.attributes.url),
        thumbnailUrl: encodeURI(item.attributes.url),
      }]

      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          albumName: null,
          _type: item.type,
          id: item.id,
          files: mapKeys(_files, f => f.id),
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.location: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          latitude: item.attributes.latitude,
          longitude: item.attributes.longitude,
          location: item.attributes.address,
          originalPlace: item.attributes.address
        }
      }
    }
    case templateIcons.url: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: item.attributes.url,
          title: item.attributes.title,
          description: item.attributes.description,
          thumbnail: item.attributes.thumbnail_url,
          resourceCategoryIds: map(item.attributes.resource_categories ?? null, c => c.id)
        }
      }
    }
    case templateIcons.deadline: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
        }
      }
    }
    case formTypes.yesNo.post_name:
    case formTypes.textField.post_name:
    case formTypes.fileUpload.post_name:
    case formTypes.imageUpload.post_name:
    case formTypes.multipleChoice.post_name: {
      return {
        type: templateIcons.form,
        id: item.id,
        name: "Form",
        data: {
          originalId: item.id,
          question: item.attributes.title,
          isRequired: item.attributes.required,
          formType: item.type,
          min: item.attributes.min ?? 0,
          max: item.attributes.max ?? 2,
          singleSelect: item.attributes.single_selection,
          // We are transforming the options because we dont want the IDs to show up on the UI as they are UUIDs
          options: map(item.attributes.options ?? [], (o, i) => {
            return { id: i + 1, option: o.option }
          })
        }
      }
    }
    case templateIcons.vote: {

      // @TODO: Finalise Campaign image and Option Image return display
      
      const convertedText = renderMarkup({
        type: messageBuilder.MARKUP.markdown,
        initial: item.attributes.confirmation_markdown,
      })

      let resultAt = null

      if (isNull(item.attributes.results_visible_at)) {
        resultAt = 'invisible'
      } else {
        
        resultAt = 'immediately'

        // When the result_at is the same as the deadline date
        if (moment(item.attributes.results_visible_at).isSame(moment(item.attributes.deadline_at))) {
          resultAt = 'after_deadline'
        }
      }

      if (item.attributes.voter_results_view_option) {
        resultAt = item.attributes.voter_results_view_option
      }
      
      return {
        type: templateIcons.vote,
        id: item.id,
        name: "Vote",
        data: {
          originalId: item.id,
          voteType: item.attributes.campaign_type,
          canChangeResults: item.attributes.can_change_answer,
          visibleFor: item.attributes.results_system_access,
          resultAt: resultAt,
          shouldAcceptConfirmationDeclaration: !isEmpty(item.attributes.confirmation_markdown),
          declarationSnippet: item.attributes.confirmation_markdown,
          quorumPercentage: item.attributes.quorum_percentage,
          shouldBeAnonymous: item.attributes.is_voter_anonymous,
          numAssignedVotesPerson: item.attributes.num_assigned_votes_person,
          minRequiredVotesPerPerson: item.attributes.min_required_votes_per_person,
          content: convertedText.markup,
          jsonContent: convertedText.rawContents,
          // We are transforming the options because we dont want the IDs to show up on the UI as they are UUIDs
          options: map(item.attributes.options ?? [], (o, i) => {
            const convertedText = renderMarkup({
              type: messageBuilder.MARKUP.markdown,
              initial: o.description,
            })

            const _files = []

            if (o.image) {
              _files.push({
                type: item.type,
                originalId: o.id,
                file: {
                  url: encodeURI(o.image),
                  id: o.id,
                  blob: null,
                  mime:o.mime_type,
                },
                loading: null,
                id: o.id,
                url: encodeURI(o.image),
                thumb: encodeURI(o.image),
                mime:o.mime_type,
                fileSize:o.bytes,
                mimeType:o.mime_type,
                fileType:o.mime_type,
                fileName:o.file_name,
                mediumUrl: encodeURI(o.image),
                thumbnailUrl: encodeURI(o.image),
              })
            }

            return { 
              id: o.id,
              originalId: o.id, 
              title: o.title, 
              description: o.description, 
              content: convertedText.markup,
              jsonContent: convertedText.rawContents,
              files: mapKeys(_files, f => f.id)
            }
          })
        }
      }
    }
    case templateIcons.iframe: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          url: item.attributes.url,
        }
      }
    }
    case templateIcons.contact: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          website: item.attributes.website,
          birthday: item.attributes.birthday,
          lastName: item.attributes.last_name,
          email: item.attributes.primary_email,
          firstName: item.attributes.first_name,
          jobTitle: item.attributes.title_or_role ?? "",
          description: item.attributes.description,
          facebookUrl: item.attributes.facebook_url,
          twitterHandle: item.attributes.twitter_handle,
          secondaryEmail: item.attributes.secondary_email,
          phoneNumber: item.attributes.primary_phone_number,
          secondaryPhoneNumber: item.attributes.secondary_phone_number,
        }
      }
    }
    case templateIcons.progressBar: {
      return {
        type: item.type,
        id: item.id,
        data: {
          originalId: item.id,
          title: item.attributes.title,
          description: item.attributes.description,
          fromDisplay: item.attributes.from_display,
          toDisplay: item.attributes.to_display,
          currentDisplay: item.attributes.current_display,
          averageDisplay: item.attributes.average_display,
          showAverageMarker: item.attributes.average_percentage !== "",
          currentPercentage: parseFloat(item.attributes.current_percentage),
          averagePercentage: parseFloat(item.attributes.average_percentage),
          currentTint: item.attributes.current_tint,
          barTint: item.attributes.bar_tint,
        }
      }
    }
    case templateIcons.customTemplate: {
      return {
        type: item.type,
        id: item.id,
      }
    }
    default:
      return {
        type: item.type,
        id: item.id,
      }
  }
}

/**
 * Create Component Timeline content from the given item data
 * @param {Component} item 
 */
export const transformTimelineComponent = (item) => {

  switch (item.type) {
    case timelineComponents.file: {
      return {
        type: item.type,
        data: {
          'title': item.file_name,
          'url': item.url,
          'label': i18next.t('components.file'),
          'downloaded_at': item.downloaded_at,
          'num_downloads': item.num_downloads
        }
      }
    }
    case timelineComponents.iframe: {

      return {
        type: item.type,
        data: {
          'title': item.url,
          'label': i18next.t('components.webframe'),
          'interacted_at': item.interacted_at ? moment(item.interacted_at).format("L LT") : null,
          'num_interactions': item.num_interactions
        }
      }
    }
    case timelineComponents.location: {

      return {
        type: item.type,
        data: {
          'title': item.address,
          'latitude': item.latitude,
          'longitude': item.longitude,
          'label': i18next.t('components.location'),
          'interacted_at': item.interacted_at ? moment(item.interacted_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.formTypes.yesNo.post_name: {
      return {
        type: timelineComponents.form,
        data: {
          'type': item.type,
          'label': i18next.t('components.form').concat(" - ").concat(timelineComponents.formTypes.yesNo.name),
          'title': item.title,
          'required': item.required,
          'answer': item.answer,
          'answered_at': item.answered_at ? moment(item.answered_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.formTypes.textField.post_name: {
      return {
        type: timelineComponents.form,
        data: {
          'type': item.type,
          'label': i18next.t('components.form').concat(" - ").concat(timelineComponents.formTypes.textField.name),
          'title': item.title,
          'required': item.required,
          'answer': item.answer,
          'answered_at': item.answered_at ? moment(item.answered_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.formTypes.fileUpload.post_name: {
      return {
        type: timelineComponents.form,
        data: {
          'type': item.type,
          'label': i18next.t('components.form').concat(" - ").concat(timelineComponents.formTypes.fileUpload.name),
          'title': item.title,
          'required': item.required,
          'answer_url': item.answer_url,
          'answer_file_name': item.answer_file_name,
          'answer_mime_type': item.answer_mime_type,
          'answer_bytes': item.answer_bytes,
          'answered_at': item.answered_at ? moment(item.answered_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.formTypes.imageUpload.post_name: {
      return {
        type: timelineComponents.form,
        data: {
          'type': item.type,
          'label': i18next.t('components.form').concat(" - ").concat(timelineComponents.formTypes.imageUpload.name),
          'title': item.title,
          'required': item.required,
          'answer_url': item.answer_url,
          'answer_thumbnail_url': item.answer_thumbnail_url,
          'answered_at': item.answered_at ? moment(item.answered_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.formTypes.multipleChoice.post_name: {

      return {
        type: timelineComponents.form,
        data: {
          'type': item.type,
          'label': i18next.t('components.form').concat(" - ").concat(timelineComponents.formTypes.multipleChoice.name),
          'title': item.title,
          'required': item.required,
          'answers': item.answers,
          'answered_at': item.answered_at ? moment(item.answered_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.vote: {

      return {
        type: timelineComponents.vote,
        data: {
          'type': item.type,
          'label': i18next.t('components.vote'),
          'title': item.title,
          'required': item.required,
          'answers': item.answers,
          'answers_visible': item.vote_visible,
          'answered_at': item.answered_at ? moment(item.answered_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.youTube: {
      return {
        type: item.type,
        data: {
          'title': item.url,
          'label': i18next.t('components.youtube'),
          'video_id': item.video_id,
          'num_watches': item.num_watches,
          'watched_at': item.watched_at ? moment(item.watched_at).format("L LT") : null,
        }
      }
    }
    case timelineComponents.payment: {

      const formattedType = () => find(map(timelineComponents.paymentTypes), { post_name: item.payment_type })

      return {
        type: item.type,
        data: {
          'title': item.title,
          'type': item.payment_type,
          'label': formattedType()?.name ?? item.type,
          'amount': item.original_amount_formatted,
          'paid_amount': item.paid_amount_formatted,
          'outstanding_amount': item.outstanding_amount_formatted,
          'total_num_items': item.total_item_quantity,
          'paid_at': item.paid_at ? moment(item.paid_at).format("L LT") : null,
          'total_paid': item.total_paid_amount_formatted,
          'payment_paid_items': map(item.payment_paid_items, (payment) => {
            return {
              'paid_amount': payment.paid_amount_formatted,
              'paid_amount_smallest_unit': payment.paid_amount_smallest_unit,
              'outstanding_amount': payment.outstanding_amount_formatted,
              'num_items': payment.item_quantity,
              'comment': payment.comment,
              'paid_at': payment.paid_at ? moment(payment.paid_at).format("L LT") : null,
              'paid_by': payment.paid_by,
            }
          })
        }
      }
    }
    case timelineComponents.image: {
      return {
        type: timelineComponents.image,
        data: {
          'url': item.url,
          'title': item.file_name,
          'num_views': item.num_views,
          'label': i18next.t('components.image'),
          'thumbnail_url': item.thumbnail_url,
          'viewed_at': item.viewed_at ? moment(item.viewed_at).format("L LT") : null
        }
      }
    }
    case timelineComponents.album: {
      return {
        type: timelineComponents.album,
        data: {
          'title': item?.album_name ?? i18next.t("timeline.component.album.noTitle"),
          'num_views': item.num_opens,
          'label': i18next.t('components.album'),
          'thumbnail_url': item.thumbnail_url,
          'viewed_at': item.opened_at ? moment(item.opened_at).format("L LT") : null
        }
      }
    }
    case timelineComponents.url: {
      return {
        type: timelineComponents.url,
        data: {
          'title': item.url,
          'file_url': item.url,
          'num_views': item.num_opens,
          'label': i18next.t('components.url'),
          'viewed_at': item.opened_at ? moment(item.opened_at).format("L LT") : null
        }
      }
    }
    default:
      return {
        type: item.type,
        data: {}
      }
  }
}

/**
 * Create display text for the Tags on preview and return the type as title_case
 * 
 * @param {Component} item 
 */
export const renderType = (item) => {

  let type = ""

  switch (item.type) {
    case templateIcons.notice: {
      type = i18next.t('components.text')
      break;
    }
    case templateIcons.event: {
      type = i18next.t('components.event')
      break;
    }
    case templateIcons.attachment:
    case templateIcons.file: {
      type = i18next.t('components.file')
      break;
    }
    case templateIcons.payment: {
      type = i18next.t('components.payment')
      break;
    }
    case templateIcons.text: {
      type = i18next.t('components.text')
      break;
    }
    case templateIcons.video: {
      type = templateIcons.video.replace("_video", "")
      break;
    }
    case templateIcons.vimeo: {
      type = templateIcons.vimeo
      break;
    }
    case templateIcons.youTube: {
      type = i18next.t('components.youtube')
      break;
    }
    case templateIcons.image: {
      type = i18next.t('components.image')
      break;
    }
    case templateIcons.location: {
      type = i18next.t('components.location')
      break;
    }
    case templateIcons.url: {
      type = i18next.t('components.url')
      break;
    }
    case templateIcons.deadline: {
      type = templateIcons.deadline
      break;
    }
    case formTypes.yesNo.post_name:
    case formTypes.textField.post_name:
    case formTypes.fileUpload.post_name:
    case formTypes.imageUpload.post_name:
    case formTypes.multipleChoice.post_name: {
      type = i18next.t('components.form')
      break;
    }
    case votingTypes.standard.post_name:
    case votingTypes.zaSGB.post_name: {
      type = i18next.t('components.vote')
      break;
    }
    case templateIcons.iframe: {
      type = templateIcons.iframe
      break;
    }
    case templateIcons.contact: {
      type = i18next.t('components.contact')
      break;
    }
    case templateIcons.progressBar: {
      type = templateIcons.progressBar.replace("_bar", "")
      break;
    }
    case templateIcons.customTemplate: {
      type = templateIcons.customTemplate
      break;
    }
    default: {
      type = item.type
      break;
    }
  }

  return type.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  )
}

/**
 * Generate tasks from the response
 * 
 * @param {Object} response 
 */
export const getTasksFromResponse = ({ response }) => {

  let components = response.components

  components = map(components, c => transformComponent(c))

  return mapKeys(components, c => c.id)
}

/**
 * Get all the images and files from the preview call
 * 
 * @param {Object} response 
 */
export const getLightBoxMedia = ({ response }) => {

  let components = response.components

  const transformed = []

  forEach(components, (component) => {
    // we should only do this for images, albums, youtube, and files
    if (![templateIcons.album, templateIcons.image, templateIcons.file, templateIcons.youTube].includes(component.type)) {
      return
    }

    // Add all the images found in an Album Component
    if (component.type === templateIcons.album) {
      map(component.attributes.medias, (media) => {
        transformed.push({
          type: 'image',
          src: media.url,
        })
      })
      return
    }
    
    // Add all the images
    if (component.type === templateIcons.image) {
      transformed.push({
        type: 'image',
        src: component.attributes.url,
      })
      return
    }
    
    // Add all the videos
    if (component.type === templateIcons.youTube) {
      transformed.push({
        type: 'youtube',
        src: component.attributes.url,
      })
      return
    }
    
    // Add all the files
    if (component.type === templateIcons.file) {
      const guessedFileType = determineFileType(component.attributes.file_name ?? component.attributes.url)

      if (guessedFileType === 'image') {
        transformed.push({
          type: 'image',
          src: component.attributes.url,
        })
        return
      }
      
      if (['music', 'file video'].includes(guessedFileType)) {
        transformed.push({
          type: 'video',
          download: component.attributes.url,
          sources: [{
            src: component.attributes.url,
            type: component.attributes.mime_type
          }]
        })
        return
      }
      
      transformed.push({
        type: 'file',
        download: component.attributes.url,
        src: component.attributes.url,
      })
    }
  })

  return transformed
}

/**
 * Generate tasks from the template response
 * 
 * @param {Object} response 
 */
export const getTasksFromTemplateResponse = ({ response }) => {

  let components = response.components

  components = map(components, component => {
    return transformTemplateComponent({
      ...component,
      id: component.id ?? v4(),
      attributes: component.attributes ?? {}
    })
  })

  return mapKeys(components, c => c.id)
}

/**
 * Find a Text or Event component and return the first component found
 * 
 * @param {*} items 
 * @param {*} listItem 
 */
export const findTextComponent = (items) => {

  if (!items) return null

  const textComponents = filter(items, item => {
    return [templateIcons.text].includes(item.type)
  })

  const textComponent = first(textComponents)

  if (!textComponent) return null

  return textComponent
}

/**
 * Find a Text or Event component and return the first component 
 * found and only return its text contents
 * 
 * @param {*} items 
 * @param {*} listItem 
 */
export const findTextComponentDescription = (items) => {

  const textComponent = findTextComponent(items)

  if (!textComponent) return ""

  // Using the blocks from the text component extract text only
  const lines = (textComponent && textComponent.data) ? map(textComponent.data.jsonContent.blocks, 'text') : []
  const description = (lines.length > 0) ? lines.join(', ') : ""

  return description
}

/**
 * Determine if a specified link matches some of the patterns given
 * 
 * @param {*} param0 
 */
export const matchLink = ({ pathname, patterns, exact = true, strict = true }) => {

  const match = matchPath(pathname, {
    path: patterns,
    exact: exact,
    strict: strict
  })

  return match ? true : false
}

/**
 * Get the sync account strategy meta data given its unique id
 * 
 * @param {String} strategyId
 * @returns {import("../constants").Strategy}
 */
export const getCalendarStrategy = (strategyId) => {

  const strategies = mapKeys(calendarConstants.STRATEGIES, strategy => strategy.name)
  const strategy = strategies[strategyId] ?? calendarConstants.STRATEGIES.google

  return strategy
}

/**
 * Get the sync account strategy meta data given its unique id
 * 
 * @param {String} strategyName
 * @returns {import("../constants").Strategy}
 */
export const getCalendarStrategyByName = (strategyName) => {
  const strategy = calendarConstants.STRATEGIES[strategyName] ?? calendarConstants.STRATEGIES.d6
  return strategy
}

/**
 * Get named parameters from search params from given URL
 * 
 * @param {String} name 
 * @param {String} url 
 */
export const getParameterByName = (name, url, type = null, options = null) => {

  if (!url) {
    return null
  }

  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  var results = regex.exec(url)

  if (!results) return null

  if (!results[2]) return null

  // var final = decodeURIComponent(results[2].replace(/\+/g, " ")) // @TODO: Remember why we would strip or replace the "+" with an (" ") empty string
  var final = results[2]

  if (type === 'Number') {

    if (options) {
      return find(options, { text: final }) ?? null
    }

    return !isEmpty(final) ? parseInt(final) : null
  }
  
  if (type === 'Boolean') {
    return (final === 'true')
  }

  if (type === 'String')
    return decodeURI(final)

  if (type === 'Array')
    return !isEmpty(final) ? final.split(",") : []

  if (type === 'Object')
    return !isEmpty(final) ? final : null

  return final
}

/**
 * Using the YouTube url, we wil update the given url and make all URLs to use the embed code
 * this is to ensure that the videos are previewable when embedded into <iframe> components
 * 
 * @param {String} url 
 */
export const transformYouTubeVideoUrl = (url) => {

  if (!url) return ""

  // Validate that we have YouTube URL
  const regex = new RegExp("^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$")
  const isValid = regex.test(url)

  if (!isValid) return ""

  // Get the Video ID, which is the last part after the last forward slash in the URL
  const match = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)

  if (!match) return ""

  // When its an Embed link, we can simply just  remove that embed
  const videoId = match[1].replace("embed/", "")

  return `https://www.youtube.com/embed/${videoId}`
}

export const createGetParams = (params, url) => {

  let _url = url.concat("?")
  let _params = createGetParamsNoURL(params)

  return _url.concat(_params)
}

export const createGetParamsNoURL = (params) => {

  let _params = ""

  map(params, (value, key) => {

    if ([null, "", undefined].includes(value)) return;
    if (isArray(value) && isEmpty(value)) return;

    if (!['communityId'].includes(key)) {
      _params = _params.concat(key + "=" + value).concat("&")
    }

  })

  _params = _params.substring(0, _params.lastIndexOf("&"))

  return _params
}

/**
 * From URL params extract default values
 * 
 * @param {*} params: {key: Type} 
 */
export const getDefaults = (params) => {

  map(params, p => {
    p = getParameterByName()
  })

  return params
}

/**
 * Get a single error message using the field name as the key
 * 
 * @param {String} key 
 * @param {Map} items 
 * @returns {{field: ?String, message: ?String, isValid: Boolean}}
 */
export const getError = (key, items) => {
  return get(items, key, { field: null, message: null, isValid: true })
}

/**
 * Create an  error message that is friendly with the {@link getError} function
 * 
 * @param {String} key 
 * @param {String} message 
 * @returns {{field: String, message: String, isValid: Boolean}}
 */
export const setError = (key, message, isValid = false) => {
  return { [key]: { id: key, field: key, message: message, isValid: isValid } }
}

/**
 * Email validation RegEx helper
 * 
 */
export const mailValidator = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

/**
 * Website validation RegEx helper
 * 
 */
export const websiteValidator = (url) => {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  )
  return pattern.test(url)
}

/**
 * Phone validation helper
 * 
 */
export const phoneValidator = (number) => {

  if (!number) return false

  // We will get the country from the locale
  const country = localStorage.i18nextLng.split("-")[1]
  const phone = parsePhoneNumber(number, country)

  return phone?.isValid()
}

/**
 * Append communityId
 * 
 * @returns {String}
 */
export const appendCommunity = (communityId, url) => {
  return url.replace(':communityId', communityId ?? ':communityId')
}

/**
 * 
 * @param {*} response 
 */
export const downloadFileFromBlob = (response, filename) => {
  // var data = new Blob([response], {type: mime || 'text/csv'})
  var data = response.data
  var csvURL = window.URL.createObjectURL(data)
  var tempLink = document.createElement('a')
  tempLink.href = csvURL

  if (["application/csv", "text/plain", "text/html", "text/csv"].includes(data.type)) {
    tempLink.setAttribute('download', filename + '.csv')
  } else {
    tempLink.setAttribute('download', filename + '.xlsx')
  }

  tempLink.click()
}

/**
 * 
 * @param {*} response 
 */
export const downloadBlob = (response, filename) => {
  var data = new Blob([response], { type: 'text/csv' })
  var csvURL = window.URL.createObjectURL(data)
  var tempLink = document.createElement('a')
  tempLink.href = csvURL

  tempLink.setAttribute('download', filename + '.csv')

  tempLink.click()
}


/**
 * Check if the given language is supported in the system
 * 
 */
export const isLngSupported = (lng) => {
  return {
    'lng': lng,
    'defaultLng': 'en',
    'isValid': (availableLanguages[lng] !== undefined)
  }
}

/**
 * Convert the given EditorState Content to HTML, Markdown or Plain Text depending on the 
 * Wysywyg Editor's submit mode
 * 
 * @param {Object} payload
 */
export const convertMarkup = (payload) => {

  const {
    convertedMD,
    rawContents,
    type
  } = payload

  switch (type) {
    case messageBuilder.MARKUP.html: {
      return draftToHtml(rawContents)
    }
    case messageBuilder.MARKUP.markdown: {

      // ?NOTE: The option we are doing here may seem redundant in that we are converting to HTML only to not use the data
      // This is simply because we can check or do a validation on empty texts properly through the html data compared to the Markdown data
      const htmlVer = draftToHtml(rawContents)
      if (htmlVer.replace("<p></p>", "").replace(/[\r\n]+/, "").length === 0) {
        return ""
      }

      return convertedMD
    }
    case messageBuilder.MARKUP.plain: {
      const contentState = convertFromRaw(rawContents)
      const _editorState = EditorState.createWithContent(contentState)
      return _editorState.getCurrentContent().getPlainText()
    }
    default: {
      return ""
    }
  }
}

/**
 * Convert the give EditorState Content to HTML for preview usage
 * 
 * @param {Object} payload
 */
export const renderMarkup = (payload) => {

  const {
    initial,
    type
  } = payload

  let rawContents = null
  let markdown = null

  switch (type) {
    case messageBuilder.MARKUP.json: {
      rawContents = initial
      const _editorState = EditorState.createWithContent(convertFromRaw(initial))
      markdown = stateToMarkdown(_editorState.getCurrentContent())
      break
    }
    case messageBuilder.MARKUP.markdown: {

      // const _contentState = stateFromMarkdown(initial)
      const _contentState = convertFromRaw(markdownToDraft(initial, {
        // remarkablePreset: 'commonmark',
        preserveNewlines: true,
      }))
      const _editorState = EditorState.createWithContent(_contentState)

      rawContents = convertToRaw(_editorState.getCurrentContent())
      markdown = stateToMarkdown(_editorState.getCurrentContent())

      break
    }
    case messageBuilder.MARKUP.plain: {

      const { contentBlocks, entityMap } = htmlToDraft(initial)
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const _editorState = EditorState.createWithContent(contentState)
      rawContents = convertToRaw(_editorState.getCurrentContent())
      markdown = _editorState.getCurrentContent().getPlainText()

      break
    }
    default: {

      const { contentBlocks, entityMap } = htmlToDraft(initial)
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const editorState = EditorState.createWithContent(contentState)
      markdown = stateToMarkdown(editorState.getCurrentContent())

      rawContents = convertToRaw(editorState.getCurrentContent())
      break
    }
  }

  return {
    markup: draftToHtml(rawContents),
    rawContents: rawContents,
    markdown: markdown,
  }
}

/**
 * 1. Convert all empty strings on a payload to Null
 * 2. Remove all undefined keys on this payload
 * 3. When this data needs to be used for FormData we should convert nulls into empty strings and booleans 
 *    to use either 1s or 0s
 * 
 * @param {Object} payload 
 * @param {Boolean} isFormData [Default = false]
 */
export const filterNulls = (payload, isFormData = false) => {

  const transformed = mapValues(payload, (v, k) => {

    // #1
    if (isString(v) && isEmpty(v)) {
      return isFormData ? "" : null
    }
    
    // #3
    if (isBoolean(v)) {
      return !isFormData ? v : (v === true) ? 1 : 0
    }

    // Otherwise we just send back the original value given
    return v

  })


  // #2
  return pickBy(transformed, t => !isUndefined(t))
}

/**
 * 1. Remove all empty strings
 * 2. Remove all null keys
 * 3. Remove all undefined
 * 
 * @param {Object} payload 
 */
 export const dropEmptyKeys = (payload) => {

  const _payload = mapValues(payload, v => {
    return (isString(v) && isEmpty(v)) ? null : v
  })

  return omitBy(_payload, isNull)
}

/**
 * Append listing pagination parameters when navigating into detail pages
 * This will be widely used in Listings for Notices/Members/Users we will use the link on the breadcrumb links
 * 
 * @param {JSON} filters
 * @param {String} backUrl
 */
export const keepPaginationFilters = (filters, backUrl) => {

  // When these are not specified, we should return the original link
  if (!filters) return backUrl

  // For now we are going to keep the PerPage and Page
  const perPage = filters?.per_page
  const page = filters?.current_page

  return createGetParams({
    perPage: perPage,
    page: page,
  }, backUrl)
}

/**
 * Create a map of selections indexed by the page number
 * 
 * @param {Array} items
 * @param {Map} selections
 * @param {Number} pageNumber
 * 
 * @returns {Map<String,Array>}
 */
export const buildSelection = (items, selections, pageNumber) => {

  // Do we have any old selections
  const _selections = [...selections[pageNumber], ...items]

  return {
    ...selections,
    [pageNumber]: uniq(_selections)
  }
}

/**
 * Send Web Push Notifications
 * 
 * @param {Object} payload
 */
export const showNotification = async (payload) => {
  if (!('Notification' in window)) {
    console.warn("This browser does not support desktop notifications.")
    return;
  }
  
  const permission = window.Notification.permission
  
  const handleNotificationClick = (notification) => {
    if (!notification?.data) return;
    
    notification.close()
    
    // @TODO: Skip allowing clicks for now, since clicking on a notification when the tab is not focused will open a new tab
    // // For Notices
    // if (notification?.data?.type === templateIcons.notice) {
    //   window.parent.focus()
    //   window.location.href = routes.private.NOTICE_DETAIL
    //     .replace(':communityId', notification.data.communityId)
    //     .replace(':id', notification.data.id)
    // }
    // console.log("onClick", notification.data)
  }

  if (permission === "granted") {
    const notification  = new window.Notification(payload.title, {
      body: payload.body,
      icon: payload.icon,
      image: payload.icon,
      data: payload.data
    })

    notification.addEventListener("click", (event => {
      handleNotificationClick(event.target)
    }))
    return;
  }

  const requestPermission = await window.Notification.requestPermission()

  if (requestPermission === "granted") {
    const notification  = new window.Notification(payload.title, {
      body: payload.body,
      icon: payload.icon,
      image: payload.icon,
      data: payload.data,
    })

    notification.addEventListener("click", (event => {
      handleNotificationClick(event.target)
    }))
    return;
  }

  console.warn("Desktop notifications not granted permission.")
}

/**
 * Re-order an element in an array, and move it {from} its old location {to} its new location index
 * 
 * @param {*} originalArray 
 * @param {*} from 
 * @param {*} to 
 * @returns 
 */
export const immutableMove = (originalArray, from, to) => {
  // return originalArray.reduce((prev, current, idx, self) => {
  //     if (from === to) {
  //         prev.push(current);
  //     }
  //     if (idx === from) {
  //         return prev;
  //     }
  //     if (from < to) {
  //         prev.push(current);
  //     }
  //     if (idx === to) {
  //         prev.push(self[from]);
  //     }
  //     if (from > to) {
  //         prev.push(current);
  //     }
  //     return prev;
  // }, []);
  return arrayMoveImmutable(originalArray, from, to)
}/**
 * Check that the parsed locale is part of an RTL group of languages
 * 
 * @param {String} locale 
 */
export const isLocaleRtl = (locale) => {
  return {
    valid: i18next.dir(locale) === 'rtl',
    dir: i18next.dir(locale)
  }
}

/**
 * Patch Editor unable to parse markdown when there are trailing whitespace
 * 
 * @param {EditorState} editorState
 * 
 * @returns {EditorState}
 */
export const editorHotFix = (editorState) => {
  
  const _newBLocks = []

  const selectionState = editorState.getSelection()
  const anchorKey = selectionState.getAnchorKey()
  const currentContent = editorState.getCurrentContent()
  const currentContentBlock = currentContent.getBlockForKey(anchorKey)
  const start = selectionState.getStartOffset()
  const end = selectionState.getEndOffset()
  const selectedText = currentContentBlock.getText().slice(start, end)
  const currentText = currentContentBlock.getText()
  let _updatedState = editorState

  // if (currentText.indexOf("\n\n") !== -1 && currentContentBlock.getType() === 'unstyled') {
  if (currentText.indexOf("\n\n") !== -1) {
    // _updatedState = EditorState.createWithContent(
    //   ContentState.createFromText(currentText, "\n\n")
    // )
  }

  const _content = convertToRaw(_updatedState.getCurrentContent())

  _content.blocks.map((block, i) => {

    // we need to look inside the ranges, and determine if there is any whitespace before and after the word being style
    block.inlineStyleRanges.map(style => {
      const trimmer = block.text.substring(style.offset, style.offset + style.length)
      const emptyStart = trimmer.startsWith(' ')
      const emptyEnd = trimmer.endsWith(' ')

      if (emptyStart || emptyEnd) {
        // For the fix, the stylised text should start on the immediate text
        // So we will update the offset and length of the inline style
        const newOffset = block.text.indexOf(trimmer.trim())
        const newLength = trimmer.trim().length

        // Once we have removed and updated our style positions, we should return
        style.offset = newOffset
        style.length = newLength
      }

      return style
    })

    _newBLocks.push(block)

    return block
  })

  const _newState = EditorState.createWithContent(convertFromRaw(_content))

  return _newState
}

export const roundTo30Mins = (dateTime) => {
  const _original = moment(dateTime)
  // const _newDate = moment().startOf("hour").add(30, "minutes")
  const _newDate = moment()
  
  // Set the hours on this date object to the current hour
  _original.hours(_newDate.hours()).minutes(_newDate.minutes())

  // 1. Time is more 30 minutes, so we set the hours to the next hour
  if (_original.minutes() > 30) {
    _original.startOf("hour").add(1, "hour")
    return _original
  } 
  
  if (_original.minutes() < 30) {
    // 2. Time is less than 30 minutes, so we se the minutes to the next 30 
    _original.minutes(30)
    return _original
  }

  return _original
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ")
}

export const replaceLast = (text, toBeReplaced, replacementStr) => {
  var a = text.split("")
  a[text.lastIndexOf(toBeReplaced)] = ` ${replacementStr}`
  return a.join("")
}

/**
 * WeekOfMonth will returns 1 for the 7 first days of the month, then 2 from the 8th to the 14th, 3 from the 15th to the 21st, 4 from 22nd to 28th and 5 above
 * @see {carbon()->weekOfMonth} https://carbon.nesbot.com/docs/
 * 
 * @param {import("moment").Moment} date 
 * @returns 
 */
export function weekOfMonth(date) {

  const m = date.clone()
  const weekDay = m.day()
  const yearDay = m.dayOfYear()
  let count = 0

  m.startOf('month');
  while (m.dayOfYear() <= yearDay) { 
    if (m.day() == weekDay) {
        count++; 
    }
    m.add(1, 'days'); 
  }

  return count;
}


/**
 * Convert a give event data into a {ExistingEventComponent}'s selected data
 * 
 * @param {*} data 
 * @param {*} clickMeta 
 * @returns 
 */
export const transformDataToSelectedEvent = (data, clickMeta = null) => {
  
  let mergedTimes = moment(data.startDate).format('LT')
        
  if (data.endDate) {
    mergedTimes = mergedTimes.concat(` - ${moment(data.endDate).format('LT')}`)
  }

  return {
    key: data.id,
    value: data.id,
    id: data.id,
    title: data.title,
    text: data.text || data.title,
    location: data.location,
    className: data.className,
    day: moment(data.startDate).format('dddd MMM DD'),
    start_end_times: mergedTimes,
    image: {
      src: getCalendarStrategyByName(data.source).icon
    },
    description: data.content,
    _click_meta: clickMeta
  }
}

/**
 * Given these components, determine if a message is actionable. A message is {actionable} when there are either Forms and Payment components that exist.
 * 
 * @param {string[]} components
 * @returns {boolean}
 */
export const isActionableMessage = (components) => {
  
  let predicate = false
  
  components.forEach(component => {
    if ([templateIcons.payment, templateIcons.vote].concat(map(formTypes, "post_name")).includes(component)) {
      predicate = true
      return
    }
  })

  return predicate
}

/**
 * Given a URL determine and return the file type
 * 
 * @param {string} url
 * @returns {string}
 */
export const determineFileType = (url) => {
  // We will split the url paths using "." so we can take the last part of the link
  // more often the last part of the link will be the file extension
  /***
   *  @type {String} 
   */
  const file = last(url.split("."))

  // This is our icon grouping, which will act as our lookup table
  const icons = {
    'file pdf': ['pdf'],
    'image': ['png', 'jpeg', 'jpg', 'gif', 'heif', 'heic', 'heif-sequence', 'heic-sequence', 'svg'],
    'file word': ['doc', 'docx', 'rtf'],
    'file powerpoint': ['pptx'],
    'file excel': ['csv', 'xls', 'xlsx'],
    'file video': ['flv', 'mp4', 'm3u8', '3gp', 'mov', 'avi', 'wmv'],
    'music': ['mp3', 'ogg', 'm3u', 'mid'],
  }

  const defaultIcon = 'file'

  // Creating a map of all the values available for the various group
  const all = mapKeys(flatten(map(icons)))

  // Search for placement
  const foundIcon = all[file.toLowerCase()]

  // We will now loop through the groups to check where this icon belongs
  // const nIcon = filter(icons, (v, k) => (v.includes(foundIcon)))
  let nIcon = map(icons, (v, k) => v.includes(foundIcon) ? k : null).filter(x => !isNull(x))[0]
      nIcon = nIcon ?? defaultIcon

  return nIcon
}

/**
 * Transform the given response to dropdown friendly item
 * 
 * @param {object} data
 * @returns {object}
 */
export const toDropDownOption = (item) => {
  return {
    key: item.value ?? item.id, 
    value: item.value ?? item.id, 
    text: item.name
  }
}

  /**
 * Transform the given response to display for the recipients table
 * 
 * @param {object} data
 * @returns {object}
 */
export const transformToRecipients = (data) => {
  const transformed = map(data, c => {                    
    const app = {
        name: i18next.t('notices.detail.table.pills.app'),
        description: i18next.t('notices.detail.table.pills.app.description'),
        parentsDescription: i18next.t('notices.detail.table.pills.parents.description'),
        items: {
            "app_sent_at": {
                name: i18next.t('notices.detail.table.items.appSentAt'),
                value: c["app_sent_at"] || "",
                oValue: c["app_sent_at"],
            },
            "app_push_sent_at": {
                name: i18next.t('notices.detail.table.items.pushSentAt'),
                value: c["app_push_sent_at"] || "",
                oValue: c["app_push_sent_at"],
            },
            "app_opened_at": {
                name: i18next.t('notices.detail.table.items.appOpenedAt'),
                value: c["app_opened_at"] || "",
                oValue: c["app_opened_at"],
            },
        },
        parents: {},
    }
    
    const sms = {
        name: i18next.t('notices.detail.table.pills.sms'),
        description: i18next.t('notices.detail.table.pills.sms.description'),
        parentsDescription: i18next.t('notices.detail.table.pills.parents.description'),
        items: {
            "sms_sent_at": {
                name: i18next.t('notices.detail.table.items.sms.sendAt'),
                value: c["sms_sent_at"] || "",
                oValue: c["sms_sent_at"],
            },
            "sms_delivered_at": {
                name: i18next.t('notices.detail.table.items.sms.deliveredAt'),
                value: c["sms_delivered_at"] || "",
                oValue: c["sms_delivered_at"],
            },
            "sms_rejected_at": {
                name: i18next.t('notices.detail.table.items.sms.rejectedAt'),
                value: c["sms_rejected_at"] || "",
                oValue: c["sms_rejected_at"],
            },
        },
        parents: {
            "parent_sms_sent_at": {
                name: i18next.t('notices.detail.table.items.sms.sendAt'),
                value: c["parent_sms_sent_at"] || "",
                oValue: c["parent_sms_sent_at"] ?? null,
            },
            "parent_sms_delivered_at": {
                name: i18next.t('notices.detail.table.items.appOpenedAt'),
                value: c["parent_sms_delivered_at"] || "",
                oValue: c["parent_sms_delivered_at"] ?? null,
            },
            "parent_sms_rejected_at": {
                name: i18next.t('notices.detail.table.items.sms.rejectedAt'),
                value: c["parent_sms_rejected_at"] || "",
                oValue: c["parent_sms_rejected_at"] ?? null,
            },
        }
    }

    const email = {
        name: i18next.t('notices.detail.table.pills.email'),
        description: i18next.t('notices.detail.table.pills.email.description'),
        parentsDescription: i18next.t('notices.detail.table.pills.parents.description'),
        items: {
            "email_sent_at": {
                name: i18next.t('notices.detail.table.items.email.sentAt'),
                value: c["email_sent_at"] || "",
                oValue: c["email_sent_at"],
            },
            "email_opened_at": {
                name: i18next.t('notices.detail.table.items.email.deliveredAt'),
                value: c["email_opened_at"] || "",
                oValue: c["email_opened_at"],
            },
            "email_rejected_at": {
                name: i18next.t('notices.detail.table.items.email.rejectedAt'),
                value: c["email_rejected_at"] || "",
                oValue: c["email_rejected_at"],
            },
        },
        parents: {
            "parent_email_sent_at": {
                name: i18next.t('notices.detail.table.items.email.sentAt'),
                value: c["parent_email_sent_at"] || "",
                oValue: c["parent_email_sent_at"] ?? null,
            },
            "parent_email_delivered_at": {
                name: i18next.t('notices.detail.table.items.email.deliveredAt'),
                value: c["parent_email_delivered_at"] || "",
                oValue: c["parent_email_delivered_at"] ?? null,
            },
            "parent_email_rejected_at": {
                name: i18next.t('notices.detail.table.items.email.rejectedAt'),
                value: c["parent_email_rejected_at"] || "",
                oValue: c["parent_email_rejected_at"] ?? null,
            },
        }
    }
    
    const whatsapp = {
        name: i18next.t('notices.detail.table.pills.whatsapp'),
        description: i18next.t('notices.detail.table.pills.whatsapp.description'),
        parentsDescription: i18next.t('notices.detail.table.pills.parents.description'),
        items: {
            "whatsapp_sent_at": {
                name: i18next.t('notices.detail.table.items.email.sentAt'),
                value: c["whatsapp_sent_at"] || "",
                oValue: c["whatsapp_sent_at"],
            },
            "whatsapp_delivered_at": {
                name: i18next.t('notices.detail.table.items.email.deliveredAt'),
                value: c["whatsapp_delivered_at"] || "",
                oValue: c["whatsapp_delivered_at"],
            },
            "whatsapp_rejected_at": {
                name: i18next.t('notices.detail.table.items.email.rejectedAt'),
                value: c["whatsapp_rejected_at"] || "",
                oValue: c["whatsapp_rejected_at"],
            },
            "whatsapp_opened_at": {
                name: i18next.t('notices.detail.table.items.appOpenedAt'),
                value: c["whatsapp_opened_at"] || "",
                oValue: c["whatsapp_opened_at"],
            },
        },
    }

    const answers = map(c.answer_hovers ?? [], (answer) => {
      return {
        type: templateIcons.form,
        ...answer
      }
    })
    const payments = map(c.payment_hovers ?? [], (payment) => {
      return {
        type: templateIcons.payment,
        ...payment,
      }
    })
    
    const reads = map(c.read_hovers ?? [], (read) => {
      return {
        type: 'reads',
        ...read,
      }
    })

    return {
        // ...c,
        id: c.id,
        is_checked: false,
        first_name: c.first_name,
        last_name: c.last_name,
        email: c.email,
        is_parent: (size(c.items) > 0),
        phone_number: formatMobileNumber(c.phone_number),
        reminded_at: moment(c.reminder_sent_at).isValid() ? moment(c.reminder_sent_at).format("L LT") : null,
        is_notice_paid: (c.notice_paid_at !== null),
        paid_amount: c.notice_amount_paid_formatted,
        is_notice_answered: (c.notice_answered_at !== null),
        is_notice_voted: (c.notice_voted_at !== null),
        is_notice_read: (c.read_at !== null),
        distribution_status: {app, sms, email, whatsapp},
        answers: answers,
        payments: payments,
        reads: reads,
        items: c.items,
        subtitle: c.subtitle,
        actions: []
    }
  })

  return transformed
}

export const subscribe = (eventName, listener) => {
  document.addEventListener(eventName, listener)
}

export const unSubscribe = (eventName, listener) => {
  document.removeEventListener(eventName, listener)
}

export const publish = (eventName, data) => {
  const event = new CustomEvent(eventName, {
    detail: {
      origin: Config.ssoClientRoot,
      data: data
    },
  })
  document.dispatchEvent(event)
}

/**
 * Given the list of file_ids from some of our Media components like {ImageComponent, FileComponent, VoteComponent}
 * determine if these ids form part of files which failed to upload.
 * 
 * @param {string[]} files
 * @returns {boolean}
 */
export const isPartiallyUploaded = (files) => {
  
  let predicate = false
  
  files.forEach(file => {
    if (file.includes(messageBuilder.TEMP_ID_PREFIX)) {
      predicate = true
      return
    }
  })

  return predicate
}

/**
 * Given a Component ID we need to figure out if we have any cached data so we can be able to restore lost data on crash
 *
 */
export const getCachedComponentData = (id) => {
  
  const cachedData = JSON.parse(localStorage.getItem(messageBuilder.BUILDER_CACHE_KEY) ?? '{"columns":{},"tasks":{}}')
  
  const cacheOrReal = cachedData.tasks?.[id]?.data ?? {}

  return cacheOrReal
}

/** 
 * Checks whether an item can be deleted based on the lock status of items.
 *
 * This function checks the deletion eligibility of a particular item or a group
 * of selected items by determining if any of them are locked. If any item is
 * locked, it shows a confirmation modal and returns false, preventing the
 * deletion. Otherwise, it allows the deletion and returns true.
 *
 * @param {string} resource - The name of the resource to be used in the confirmation message.
 * @param {Array<Object>} items - List of items in the existing table.
 * @param {Array<string>} selectedItems - List of currently selected items or IDs.
 * @param {Function} handleCloseConfirm - Function to call when the confirmation modal is closed.
 * @param {Function} setConfirm - Function to display the confirmation modal.
 * @param {string} [itemId] - Optional. The ID of a single item to check.
 * @param {boolean} [parentLocked] - Optional. Whether the parent of this resource is itself locked or not, default=false.
 * @returns {boolean} - Returns true if the item(s) can be deleted (no items are locked), otherwise returns false.
 */
export const canDeleteItem = (resource, items, selectedItems, handleCloseConfirm, setConfirm, itemId, parentLocked = false) => {
  const _mappedItems = mapKeys(items ?? [], (m) => m.id)
  let _selected = null
  if (itemId) {
      _selected = [itemId]
  } else {
      _selected = [...selectedItems]
  }
  const _lockedItems = _selected.map((id) => _mappedItems[id]).filter((m) => m.is_locked)
  if (_lockedItems.length > 0 || parentLocked) {
      setConfirm({
          showModal: true,
          onConfirm: () => {},
          onClose: handleCloseConfirm,
          message: t("resources.locked.modal.description.label", {
              resource: resource,
          }),
          header: t('resources.locked.modal.header.label', {
              resource: resource,
          }),
          showConfirm: false,
      })
      return false
  }
  return true
}

export const isHTML = (editorState) => {
  const _content = convertToRaw(editorState.getCurrentContent())
  let hasRichStyles = false
  
  _content.blocks.map((block) => {

    // we need to look inside the ranges, and determine if there is any whitespace before and after the word being style
    if (block.inlineStyleRanges.length > 0 || block.entityRanges.length > 0) {
      hasRichStyles = true
      return block
    }

    // when there are any styles that are not "unstyled" we can safely say that there are rich styles
    if (block.type !== 'unstyled') {
      hasRichStyles = true
      return block
    }

    return block
  })

  return hasRichStyles
}