import i18next from "i18next"
import { filter, identity, isEmpty, mapKeys, merge, omit, pickBy } from "lodash"
import { templateIcons } from "../../constants"
import { filterNulls } from "../../utils/helper"

class PostSaveTemplateRequest {
    
    constructor({
        templateName,
        components,
        communityId,
        noticeTitle,
    }) {
        this.templateName = templateName 
        this.components = components 
        this.noticeTitle = noticeTitle 
        this.communityId = communityId 
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validator = () => {
        
        const errors = []
        
        if (isEmpty(this.templateName)) {
            errors.push({
                field: "templateName",
                isValid: false,
                message: i18next.t('requestObjects.PostSaveTemplateRequest.required.templateName')
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

    /**
     * ??NOTE: The builder payload provided here is assumed to be already pre-processed and cleaned
     * 
     * @param {Object} builderPayload 
     * @returns {Object} 
     */
    getPayload = (builderPayload) => {
        
        let payload = {
            "name": this.templateName,
            "components": this.components,
            "community_id": this.communityId,
            "notice_title": this.noticeTitle,
        }

        if (builderPayload) {
            payload = merge(payload, omit(builderPayload, ['title', 'community_id', 'pin_item', 'pin_until_date']))
        }

        return filterNulls(payload)
    }

}

export default PostSaveTemplateRequest