import i18next from "i18next"
import { mapKeys, isEmpty } from "lodash"
import { filterNulls } from "../../utils/helper"
import { parsePhoneNumber } from "react-phone-number-input"

class CreateMemberRequest {

    constructor({
        email,
        tagIds,
        lastName,
        idNumber, 
        firstName, 
        reference,
        channelIds,
        phoneNumber,
        communityId,
        notifyViaSms,
        notifyViaEmail,
        debtorReference,
        parentPersonIds,
        isPublicFollower,
        childrenPersonIds,
    }) {
        this.email = email
        this.tagIds = tagIds
        this.idNumber = idNumber
        this.lastName = lastName
        this.reference = reference
        this.firstName = firstName
        this.channelIds = channelIds
        this.phoneNumber = phoneNumber
        this.communityId = communityId
        this.debtorReference = debtorReference
        this.parentPersonIds = parentPersonIds
        this.isPublicFollower = isPublicFollower
        this.childrenPersonIds = childrenPersonIds
        
        this.notifyViaSms = notifyViaSms
        this.notifyViaEmail = notifyViaEmail
    }

    getPayload = () => {
        
        const payload = {
            "email": this.email,
            "tag_ids": this.tagIds,
            "last_name": this.lastName,
            "id_number": this.idNumber,
            "first_name": this.firstName,
            "channel_ids": this.channelIds,
            "community_id": this.communityId,
            "phone_number": this.phoneNumber,
            "unique_identifier": this.reference,
            "debtor_reference": this.debtorReference,
            "parent_person_ids": this.parentPersonIds,
            "child_person_ids": this.childrenPersonIds,
            "is_public_follower": this.isPublicFollower,
        }

        if (this.notifyViaEmail) {
            payload["send_app_download_invite_via_email"] = this.notifyViaEmail
        }
        
        if (this.notifyViaSms) {
            payload["send_app_download_invite_via_sms"] = this.notifyViaSms
        }

        return filterNulls(payload, true)
    }
    
    getUniquenessCheckerPayload = () => {
        
        const payload = {
            "email": this.email,
            "id_number": this.idNumber,
            "first_name": this.firstName,
            "phone_number": this.phoneNumber,
            "community_id": this.communityId,
            "unique_identifier": this.reference,
        }

        return filterNulls(payload, true)
    }


    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validateContactDetails = () => {
        
        const errors = []
        const phoneNumber = parsePhoneNumber(this.phoneNumber)
        if (phoneNumber && !phoneNumber.isValid()) {
            errors.push({
                field: "phoneNumber",
                isValid: false,
                message: "phoneNumber",
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validator = () => {
        
        const errors = []
        
        // if (!this.email || !mailValidator.test(this.email)) {
        //     errors.push({
        //         field: "email",
        //         isValid: false,
        //         message:!this.email ? i18next.t('requestObjects.CreateMemberRequest.requiredEmail') : i18next.t('requestObjects.CreateMemberRequest.invalidEmail'),
        //     })
        // }
        
        if (!this.firstName) {
            errors.push({
                field: 'firstName',
                isValid: false,
                message: i18next.t('requestObjects.CreateMemberRequest.requiredFirstName')
            })
        }
        
        if (!this.lastName) {
            errors.push({
                field: 'lastName',
                isValid: false,
                message: i18next.t('requestObjects.CreateMemberRequest.requiredLastName')
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }
    
}

export default CreateMemberRequest