import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from 'semantic-ui-react'
import { version } from "../../package.json"
import LogoTeal from './partials/LogoTeal'

const SuspenseFallback = () => {

    const { t } = useTranslation()

    // onMount/onUpdate
    useEffect(() => {
        // Add loading class to add image background
        document.body.classList.add('loading')
        
        return () => {
            document.body.classList.remove('loading')
        }

    }, [])

    return (
        <div className='ui middle aligned center aligned grid data-init-screen'>
            <Loader 
                inverted 
                active={true} 
                content={
                    <Fragment>
                        <LogoTeal />
                        {t("loader.initialising")}
                        <p><small>{`V ${version}`}</small></p>
                    </Fragment>
                } />
        </div>
    )
}

export default SuspenseFallback