import i18next from "i18next"
import { pickBy, identity, mapKeys, filter } from "lodash"
import PostCreateCalendarRequest from "./PostCreateCalendarRequest"
import { messageBuilder } from '../../constants'

/**
 * 
 * @typedef Request
 * @property {String} name
 * @property {Array} channelIds
 * @property {String} communityId
 * @property {String} syncAccountId
 * @property {Boolean} targetEveryone
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostUpdateCalendarRequest extends PostCreateCalendarRequest {

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        const payload = {
            "name": this.name,
            "sync_account_id": this.syncAccountId,
            "is_sync_automatically": this.syncAutomatically,
            "notify_synced_events": this.sendReminderDayBefore,
            "target_everyone": this.targetEveryone,
            "sync_event_channels": this.syncEventChannels,
        }

        const channelIds = filter(this.channelIds, c => c !== messageBuilder.TARGET_ALL_TAG_ID)

        payload["channel_ids"] = channelIds.length > 0 ? channelIds : []

        return payload
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        if (!this.name) {
            errors.push({
                field: "name",
                isValid: false,
                message: i18next.t("calendar.accounts.feed.link.requiredName")
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostUpdateCalendarRequest