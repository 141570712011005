import { identity, isEmpty, pickBy } from "lodash"

class GenericListRequest {

    constructor({
        sort, 
        search,
        perPage,
        page,
        communityId,
    }) {
        this.sort = sort
        this.search = search
        this.perPage = perPage
        this.page = page
        this.communityId = communityId
    }


    getPayload = () => {
        
        let payload = {
            "sort": this?.sort,
            "search": this?.search,
            "per_page": this?.perPage?.value,
            "page": this?.page,
            "community_id": this?.communityId,
        }

        // return payload
        return pickBy(payload, identity)
    }
    
}

export default GenericListRequest