import { Dropdown } from 'semantic-ui-react'

export default class EventSearchDropdown extends Dropdown {
  
  /**
   * ? NOTE:  We override the default implementation of search since we have a custom debounced input that we would like to use instead
   * @see https://github.com/Semantic-Org/Semantic-UI-React/blob/master/src/modules/Dropdown/Dropdown.js#L547
   * 
   */
  handleSearchChange = (e, { value }) => {

    e.stopPropagation()

    const { minCharacters } = this.props
    const { open } = this.state
    const newQuery = value

    this.setState({ searchQuery: newQuery, selectedIndex: 0 })

    // open search dropdown on search query
    if (!open && newQuery.length >= minCharacters) {
      this.open()
      return
    }

    // close search dropdown if search query is too small
    if (open && minCharacters !== 1 && newQuery.length < minCharacters) this.close()
  }
}