import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const  propTypes = {
    errors: PropTypes.string,
    messages: PropTypes.object,
    errorHeader: PropTypes.string,
    onClose: PropTypes.func,
    isServerError: PropTypes.bool
}

const  defaultProps = {
    errors: "",
    messages: {},
    errorHeader: "",
    onClose: () => {},
    isServerError: false,
}

const FormMessages = (props) => {
    
    const {
        errors,
        messages,
        errorHeader,
        onClose,
        isServerError
    } = props

    const { t } = useTranslation()

    return (
        <Fragment>
            {(messages && messages.header) && (
                <Message success onDismiss={onClose}>
                    <Message.Header>{t(messages.header)}</Message.Header>
                    <Message.Content>
                        <p>{t(messages.content)}</p>
                    </Message.Content>
                </Message>
            )}

            {errors && (
                <Message error onDismiss={onClose}>
                    <Message.Header>{errorHeader || t('modal.errorOccurred')}</Message.Header>
                    <Message.Content>
                        <p>{isServerError ? errors : t(errors)}</p>
                    </Message.Content>
                </Message>
            )}
        </Fragment>
    )
}

FormMessages.propTypes = propTypes
FormMessages.defaultProps = defaultProps

export default FormMessages