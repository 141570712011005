import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Image } from 'semantic-ui-react'
import { useEffect } from 'react'
import routes from '../../routes/route-constants'
import i18next from 'i18next'
import iCalIcon from '../../images/icons/ical.png'
import msIcon from '../../images/icons/microsoft.svg'
import Config from '../../config'
import { appendCommunity, getParameterByName } from '../../utils/helper'
import { calendarsActions } from '../../actions/calendarsActions'
import { push } from 'connected-react-router'
import { isString } from 'lodash'

const ReAuthCalendar = (props) => {

	let previousUrl = null
	let windowObjectReference = null

	const navigation = useSelector(state => state.navigation)
	const calendarManagement = useSelector(state => state.calendars)
	const [isLoadingGoogle, setLoadGoogle] = useState(false)
	const [isLoadingMicrosoft, setLoadMicrosoft] = useState(false)
	const communityId = props.match.params.communityId
	const syncAccountId = props.match.params.id
	const source = getParameterByName('source', window.location.href, 'String')

	const dispatch = useDispatch()

	useEffect(() => {
		document.body.classList.add('link-calendar')

		return () => {
			document.body.classList.remove('link-calendar')
			dispatch(calendarsActions.clearMessages())
		}
	}, [])

	/**
	 *
	 * @param {CustomEvent} event
	 * @returns
	 */
	const receiveMessage = (event) => {

		// Do we trust the sender of this message? (might be
		// different from what we originally opened, for example).
		if (event.detail.origin !== Config.ssoClientRoot) {
			return
		}

		const { detail } = event

		// if we trust the sender and the source is our popup
		if (detail.source === 'Google') {

			const syncPayload = {
				authCode: detail.authCode,
				id: syncAccountId,
				communityId: navigation.communityId
			}
			setLoadGoogle(true)
			dispatch(calendarsActions.postReAuthSyncAccount(syncPayload))
		}
		
		// if we trust the sender and the source is our popup
		if (detail.source === 'Microsoft') {

			const syncPayload = {
				authCode: detail.authCode,
				id: syncAccountId,
				communityId: navigation.communityId
			}
			setLoadMicrosoft(true)
			dispatch(calendarsActions.postReAuthSyncAccount(syncPayload))
		}
	}

	/**
	 * A helper that user a timer to check for any changes in the external popup
	 *
	 * The timer will run every half a second to check if the window has been closed or not
	 * this helps us keep track so we can update the parent window incase anything wrong may have happened
	 */
	const _initWindowStateListeners = () => {
		const timer = setInterval(() => {
			if (!windowObjectReference) {
				timer && clearInterval(timer)
				return
			}

			if (windowObjectReference.closed) {
				clearInterval(timer)
				
				setLoadGoogle(false)
				setLoadMicrosoft(false)

				dispatch(calendarsActions.reset())
			}
		}, 500)
	}

	const openSignInWindow = (url, name, listenerName) => {

		// window features
		const strWindowFeatures =
			'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

		if (windowObjectReference === null || windowObjectReference.closed) {
			/* if the pointer to the window object in memory does not exist
					or if such pointer exists but the window was closed 
			*/
			windowObjectReference = window.open(url, name, strWindowFeatures)
		} else if (previousUrl !== url) {
			/* if the resource to load is different,
				then we load it in the already opened secondary window and then
				we bring such window back on top/in front of its parent window. 
			*/
			windowObjectReference = window.open(url, name, strWindowFeatures)
			windowObjectReference.focus()
		} else {
			/* else the window reference must exist and the window
				is not closed; therefore, we can bring it back on top of any other
				window with the focus() method. There would be no need to re-create
				the window or to reload the referenced resource. 
			*/
			windowObjectReference.focus()
		}

		// add the listener for receiving a message from the popup
		window.document.addEventListener(listenerName, receiveMessage, { once: true })

		// @TODO: we need a way to be able to tell if the popup is taking longer than it should.
		_initWindowStateListeners()

		// assign the previous URL
		previousUrl = url
	}

	const handleAuthenticateWithGoogle = () => {
		setLoadGoogle(true)
		dispatch(calendarsActions.clearMessages())

		dispatch(
			calendarsActions.fetchGoogleAuth({
				community_id: navigation.communityId,
				callback_url: `${Config.ssoClientRoot}/${navigation.communityId}/google/calendar/callback?accountId=${syncAccountId}&source=Google`
			})
		).then(response => {
			/**
			 * Once we now have the data, we should automatically redirect to the given link in response for Google to authenticate us
			 *
			 */
			openSignInWindow(response.data.authorisation_url, 'Google', 'onGoogleFinished')
		})
	}
	
	const handleAuthenticateWithMicrosoft = () => {
		setLoadMicrosoft(true)
		dispatch(calendarsActions.clearMessages())

		dispatch(
			calendarsActions.fetchMicrosoftAuth({
				community_id: navigation.communityId,
				callback_url: `${Config.ssoClientRoot}/${navigation.communityId}/microsoft/calendar/callback?accountId=${syncAccountId}&source=Microsoft`
			})
		).then(response => {
			/**
			 * Once we now have the data, we should automatically redirect to the given link in response for onMicrosoftFinished to authenticate us
			 *
			 */
			openSignInWindow(response.data.authorisation_url, 'Microsoft', 'onMicrosoftFinished')
		})
	}

	const handleAuthenticateWithICal = () => {
		dispatch(push(appendCommunity(
			navigation.communityId,
			routes.private.CALENDAR_CREATE_ICAL
		)))
	}

	const handleNavigation = () => {
		// HARD Refresh
		window.location.href = appendCommunity(
			navigation.communityId,
			routes.private.MANAGE_CALENDARS
		)
	}

	return (
		<div className="ui middle aligned center aligned grid no-auth full-ui-block data-init-screen">
			<div className="link-calendar-full-page">
				<Grid className="calendar-provider-buttons">
					<Grid.Row columns={1}>
						<Grid.Column width={16} textAlign="center">
							<h1>{i18next.t('link.calendar.re-auth.title')}</h1>
							<p>
								{i18next.t('link.calendar.re-auth.subtitle')}
							</p>
							{!calendarManagement.loading && calendarManagement.errors && (
								<div className="error-explanation">
									<p className="error-message red-text">
										{isString(calendarManagement.errors) ? calendarManagement.errors : i18next.t('error.errorOccurredCrash')}
									</p>
								</div>
							)}
						</Grid.Column>
					</Grid.Row>
					{source === 'GOOGLE_CALENDAR' && (
						<Grid.Row columns={1} className="no-pad-bot">
							<Grid.Column width={16}>
								<Button
									loading={isLoadingGoogle}
									disabled={isLoadingGoogle || isLoadingMicrosoft}
									onClick={handleAuthenticateWithGoogle}
									className="link-calendar no-margin fluid"
								>
									<span>
										<svg
											className="svg"
											width={18}
											height={18}
											viewBox="0 0 18 18"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M17.64 9.2c0-.637-.057-1.251-.164-1.84H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
												fillRule="evenodd"
												fillOpacity={1}
												fill="#4285f4"
												stroke="none"
											/>
											<path
												d="M9.003 18c2.43 0 4.467-.806 5.956-2.18L12.05 13.56c-.806.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H.96v2.332C2.44 15.983 5.485 18 9.003 18z"
												fillRule="evenodd"
												fillOpacity={1}
												fill="#34a853"
												stroke="none"
											/>
											<path
												d="M3.964 10.712c-.18-.54-.282-1.117-.282-1.71 0-.593.102-1.17.282-1.71V4.96H.957C.347 6.175 0 7.55 0 9.002c0 1.452.348 2.827.957 4.042l3.007-2.332z"
												fillRule="evenodd"
												fillOpacity={1}
												fill="#fbbc05"
												stroke="none"
											/>
											<path
												d="M9.003 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.464.891 11.428 0 9.002 0 5.485 0 2.44 2.017.96 4.958L3.967 7.29c.708-2.127 2.692-3.71 5.036-3.71z"
												fillRule="evenodd"
												fillOpacity={1}
												fill="#ea4335"
												stroke="none"
											/>
										</svg>
										<span>{i18next.t('link.calendar.google')}</span>
									</span>
								</Button>
							</Grid.Column>
						</Grid.Row>
					)}
					{source === 'MICROSOFT_CALENDAR' && (
						<Grid.Row columns={1} className="no-pad-bot">
							<Grid.Column width={16}>
								<Button
									loading={isLoadingMicrosoft}
									disabled={isLoadingGoogle || isLoadingMicrosoft}
									onClick={handleAuthenticateWithMicrosoft}
									className="link-calendar no-margin fluid"
								>
									<span>
										<Image src={msIcon} width={18} height={18} />
										{i18next.t('link.calendar.microsoft')}
									</span>
								</Button>
							</Grid.Column>
						</Grid.Row>
					)}
					{source === 'ICAL' && (
						<Grid.Row columns={1} className="no-pad-bot">
							<Grid.Column width={16}>
								<Button
									onClick={handleAuthenticateWithICal}
									disabled={isLoadingGoogle || isLoadingMicrosoft}
									className="link-calendar no-margin fluid"
								>
									<span>
										<Image src={iCalIcon} width={18} height={18} />
										{i18next.t('link.calendar.iCal')}
									</span>
								</Button>
							</Grid.Column>
						</Grid.Row>
					)}
					<Grid.Row columns="equal">
						<Grid.Column width={16} textAlign="right">
							<Button
								onClick={handleNavigation}
								className="secondary no-margin"
							>
								{i18next.t('general.cancel')}
							</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		</div>
	)
}

export default ReAuthCalendar
