import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Message, Modal } from 'semantic-ui-react'
import { isUndefined, map, size } from 'lodash'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

const  propTypes = {
    errors: PropTypes.string,
    messages: PropTypes.object,
    render: PropTypes.func,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    showCancel: PropTypes.bool,
    closeOnDimmerClick: PropTypes.bool,
    showConfirm: PropTypes.bool,
    errorHeader: PropTypes.string,
    errorCode: PropTypes.oneOfType([PropTypes.number, undefined]),
}

const  defaultProps = {
    errors: "",
    messages: {},
    render: () => {},
    onClose: () => {},
    onConfirm: () => {},
    cancelText: "modal.cancelModalText",
    confirmText: "modal.confirmText",
    errorHeader: "",
    showCancel: true,
    showConfirm: false,
    closeOnDimmerClick: true,
    errorCode: null,
}

const Messages = (props) => {
    
    const {
        errors,
        render,
        onClose,
        onConfirm,
        messages,
        errorCode,
        cancelText,
        confirmText,
        showCancel,
        showConfirm,
        errorHeader,
        closeOnDimmerClick,
    } = props
    
    const [open, setOpen] = useState(true)
    const { t, i18n } = useTranslation()

    const handleClose = () => {
        setOpen(false)
        
        onClose && onClose()
    } 

    const isError = (size(errors) > 0)
    const hasTranslation = i18n.exists(errors)

    const isLockedData = (code) => {
        switch (code) {
            case 1200:
                return {
                    message: t("resources.locked.modal.description.label", {
                        resource: t('sidebar.communityMembers').toLowerCase()
                    }),
                    header: t('resources.locked.modal.header.label', {
                        resource: t('sidebar.communityMembers').toLowerCase()
                    })
                }
            case 1201:
                return {
                    message: t("resources.locked.modal.description.label", {
                        resource: t('sidebar.channel').toLowerCase()
                    }),
                    header: t('resources.locked.modal.header.label', {
                        resource: t('sidebar.channel').toLowerCase()
                    })
                }
            case 1202:
                return {
                    message: t("resources.locked.modal.description.label", {
                        resource: t('sidebar.events').toLowerCase()
                    }),
                    header: t('resources.locked.modal.header.label', {
                        resource: t('sidebar.events').toLowerCase()
                    })
                }
            case 1203:
                return {
                    message: t("resources.locked.modal.description.label", {
                        resource: t('sidebar.users').toLowerCase()
                    }),
                    header: t('resources.locked.modal.header.label', {
                        resource: t('sidebar.users').toLowerCase()
                    })
                }
            case 1204:
                return {
                    message: t("resources.locked.modal.description.label", {
                        resource: t('sidebar.userInvites').toLowerCase()
                    }),
                    header: t('resources.locked.modal.header.label', {
                        resource: t('sidebar.userInvites').toLowerCase()
                    })
                }
            case 1017:
                return {
                    message: errors,
                    header: t('general.plan.upgrade'),
                }
        
            default:
                return {}
        }
    }

    const { message, header } = isLockedData(errorCode)
    
    return (
        <Modal
            size="tiny"
            open={ open }
            onClose={ handleClose }
            closeOnDimmerClick={ closeOnDimmerClick }>
        {!header && (<Modal.Header className={`white-text ${isError ? 'red': 'primary-color'}`}>{t(messages.header) || errorHeader ||  t('modal.errorOccurred')}</Modal.Header>)}
        {header && (<Modal.Header className='white-text primary-color'>{header}</Modal.Header>)}
        <Modal.Content>
            {(messages.header && !header) && (
                <Fragment>
                    {messages.content && (
                        <Message info>
                            <Message.List items={[t(messages.content)]} />
                        </Message>
                    )}
                </Fragment>
            )}

            { (isError && !message) && (
                <Message error>
                    {hasTranslation && <Message.List items={[t(errors)]} />}
                    {!hasTranslation && <Message.List items={[errors]} />}
                </Message>
            )}

            {message && (<p>{message}</p>)}

            {/* Render any additional components if any */}
            {render && render()}
        </Modal.Content>
        <Modal.Actions className="no-border">
            {showCancel && (
                <Button className={errorCode ? 'secondary' : 'tertiary'} onClick={handleClose}>
                    { t(cancelText) }
                </Button>
            )}
            {showConfirm && (
                <Button className='primary' onClick={onConfirm}>
                    { t(confirmText) }
                </Button>
            )}
        </Modal.Actions>
      </Modal>
    )
}

Messages.propTypes = propTypes
Messages.defaultProps = defaultProps

export default Messages