import React, { Fragment, useEffect, useState } from 'react'
import { Grid, List, Image, Icon, Loader } from 'semantic-ui-react'
import { debounce, delay, first, isEmpty, map } from 'lodash'
import BaseTemplate from './BaseTemplate'
import { noticeService } from '../../../../api'
import { websiteValidator } from '../../../../utils/helper'
import { t } from 'i18next'

export default function URLComponent({ item }) {
	const [state, setState] = useState({
		loading: true,
		url: item.data?.url,
		title: item.data?.title,
		description: item.data?.description,
		thumbnail: item.data?.thumbnail,
		siteUnreachable: false,
	})

	useEffect(() => {

		let website = null

		try {
			website = new URL(item.data?.url)
		} catch (error) {
			website = null
		}

		const handleGetMeta = async () => {

			if (isEmpty(item.data.url)) {
				setState((prev) => ({ 
					...prev, 
					loading: false, 
					siteUnreachable: true
				}))
				return
			}

			const isValid = websiteValidator(item.data?.url || '')
			
			// We do not need to continue when we have an invalid URL
			if (!isValid) {
				setState((prev) => ({
					...prev, 
					loading: false, 
					host: null, 
					title: null, 
					description: null, 
					thumbnail: null, 
					siteUnreachable: true
				}))
				return
			}

			try {
				
				setState((prev) => ({
					...prev, 
					loading: true,
				}))

				const metadata = await noticeService.getWebsiteMeta({ url: item.data.url })
				setState((prev) => ({
					...prev, 
					loading: false,
					host: website?.host,
					siteUnreachable: false,
					title: item.data?.title || metadata?.data?.title,
					description: item.data?.description || metadata?.data?.description,
					thumbnail: item.data?.thumbnail || metadata?.data?.thumbnailUrl,
				}))
			} catch (error) {
				setState((prev) => ({
					...prev, 
					loading: false, 
					host: null, 
					title: null, 
					description: null, 
					thumbnail: null,
					siteUnreachable: true,
				}))
			}

		}

		// When we have the title and the originalId we can skip the fetch call to the API to get the metadata
		if (item.data?.title && item.data?.originalId) {
			setState((prev) => ({ 
				...prev, 
				loading: false,
				host: website?.host
			}))
			return;
		}

		handleGetMeta()

	}, [item.data])

	if (state.loading) {
		return (
			<BaseTemplate
				render={() => (
					<List.Content>
						<Grid className='url-component'>
							<Grid.Row></Grid.Row>
							<Grid.Row>
								<Grid.Column className='relative' width={16} verticalAlign='middle'>
									<Loader active size='big' />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</List.Content>
				)}
			/>
		)
	}
	
	if (state.siteUnreachable) {
		return (
			<BaseTemplate
				render={() => (
					<List.Content className="url-placeholder">
						<Icon name="globe" />
						<small>{t('message-builder.preview.templates.url-component.site-unreachable')}</small>
					</List.Content>
				)}
			/>
		)
	}
	
	return (
		<BaseTemplate
			render={() => (
				<List.Content>
					<Grid className='url-component'>
						<Grid.Row columns={2} verticalAlign='middle'>
							<Grid.Column width={10} verticalAlign='middle'>
								<h4 className='no-margin'>{item.data?.title || state.title}</h4>
								{state.description && <p className='no-margin'>{item.data?.description || state.description}</p>}
								{state.host && <p className='no-margin primary-text'>{state.host}</p>}
							</Grid.Column>
							{state.thumbnail && (
								<Grid.Column width={6} verticalAlign='middle'>
									<Image className='url-thumb-preview' src={item.data?.thumbnail || state.thumbnail} />
									<Icon name='chevron right' className='no-margin' />
								</Grid.Column>
							)}
						</Grid.Row>
					</Grid>
				</List.Content>
			)}
		/>
	)
}
