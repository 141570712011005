import React, { useState, Fragment } from 'react'
import { Grid, Input } from 'semantic-ui-react'

export default function TextFieldQuestionPreview({item}) {
    
    return (
        <Fragment>
            <Grid.Row>
                <Grid.Column>
                    <div className="input-container theme-color">
                        <label className='label mg-bot-5 black-text'>
                            {item.data.question} {item.data.isRequired && <span style={{marginLeft: 5}} className="secondary-color-text">*</span>}
                        </label>
                        <Input 
                            name="question"
                            type="question"
                            autoComplete="off" 
                            placeholder={"Answer"} />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Fragment>
    )
}
