import { omit } from "lodash"
import {
    GenericListRequest,
    ListEventsRequest,
    ListTargetPersonsRequest,
    PostCreateEventRequest,
    PostUpdateEventRequest,
    SearchEventsRequest,
} from "../api/requestObjects"

import ApiService from "./ApiService"

class EventApiService extends ApiService {

    /**
     * Get a specific event by the unique id
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getEvent = (params) => {

        const { GET_EVENT } = this.endpoints
        
        // return this.API.get("http://localhost:3001/api/v1/events/".concat(params.eventId))
        return this.API.get(this.getEndpoint({
            path: GET_EVENT,
            data: params.eventId,
            params: {
                use_description_media_type: 1,
            }
        }))
    }
    
    /**
     * Fetch all the events linked to this calendar-feed
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getCalendarEvents = (params) => {

        const { GET_CALENDAR_EVENTS } = this.endpoints
        const calendarId = params.calendarId
        
        params = omit(params, ['calendarId'])
        
        const request = new GenericListRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_EVENTS,
            data: calendarId,
            params: request.getPayload()
        }))
    }
    
    /**
     * Fetch all the latest events that match the search term not bound by date ranges
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    searchBuilderEvents = (params) => {

        const { POST_BUILDER_SEARCH_EVENTS } = this.endpoints
        
        const request = new SearchEventsRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_BUILDER_SEARCH_EVENTS,
        }), request.getPayload())
    }

    /**
     * Fetch all the latest events that match the search term not bound by date ranges
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    searchEvents = (params) => {

        const { POST_SEARCH_EVENTS } = this.endpoints
        
        const request = new SearchEventsRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_SEARCH_EVENTS,
        }), request.getPayload())
    }
    
    /**
     * Fetch all the events for this community for a specified range
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getEvents = (params) => {

        const { GET_EVENTS_RANGE } = this.endpoints
        
        const request = new ListEventsRequest(params)

        return this.API.post(this.getEndpoint({
            path: GET_EVENTS_RANGE,
        }), request.getPayload())
    }
    
    /**
     * Fetch the current calendar statuses
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getCalendarStatuses = (params) => {

        const { GET_CALENDAR_STATUSES } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_STATUSES,
            data: params.communityId,
        }))
    }
    
    /**
     * Create a new Event linked to this calendar-feed
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateEvent = (params) => {

        const { POST_CREATE_EVENT } = this.endpoints
        
        const request = new PostCreateEventRequest(params)
        return this.API.post(this.getEndpoint({
            path: POST_CREATE_EVENT,
        }), request.getPayload())
    }
    
    /**
     * Update the calendar event
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    putUpdateEvent = (params) => {

        const { PUT_UPDATE_EVENT } = this.endpoints
        
        const request = new PostUpdateEventRequest(params)
        
        return this.API.put(this.getEndpoint({
            path: PUT_UPDATE_EVENT,
            data: request.eventId,
        }), request.getPayload())
    }

    /**
     * Delete an event
     * 
     * @param {Object} params
     * @param {String} params.eventId
     * @returns {Promise}
     */
    postDeleteEvent = (params) => {

        const { DELETE_EVENT } = this.endpoints

        const payload = omit(params, ['eventId'])

        return this.API.delete(this.getEndpoint({
            path: DELETE_EVENT,
            data: params.eventId,
        }), {data: payload})
    }

    /**
     * Fetch target-able recipients
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getTargetRecipients = (params) => {

        const { GET_MEMBERS } = this.endpoints
        const payload = new ListTargetPersonsRequest(params)

        return this.API(this.getEndpoint({
            path: GET_MEMBERS,
            params: payload.getPayload()
        }))
    }
}

export default EventApiService