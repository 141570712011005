import React, { Fragment, useEffect, useState } from 'react'
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
} from 'react-pro-sidebar'

import { FaBroadcastTower, FaCalendar, FaCog, FaUserShield } from 'react-icons/fa'
import { MdInbox, MdSmartphone } from 'react-icons/md'

import { Trans, useTranslation } from 'react-i18next'
import routes from '../../routes/route-constants'
import { Link, matchPath } from 'react-router-dom'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../actions/builderActions'
import { map, size } from 'lodash'
import { push } from 'connected-react-router'
import { appendCommunity } from '../../utils/helper'
import ConfirmModal from './ConfirmModal'
import { TieredDropdownItem } from './CustomDropdown'

const ShowMenuConditional = ({ render, community, isVisible }) => {

  if (community) {

    if (community[isVisible]) {
      return render()
    }

    return <Fragment />
  }

  return <Fragment />
}

const SideNavigation = ({
  rtl,
  toggled,
  collapsed,
  handleToggleChange,
  handleCollapsedChange,
}) => {

  const { t } = useTranslation()
  const community = useSelector(state => state.navigation.community)
  const navigation = useSelector(state => state.navigation)
  const loadingTemplates = useSelector(state => state.builder.loadingTemplates)
  const _templates = useSelector(state => state.builder._templates)
  const builderMessages = useSelector(state => state.builder.messages)
  const router = useSelector(state => state.router)
  const dispatch = useDispatch()

  const [menuOpen, setMenuOpen] = useState(false)
  
  const [confirm, setConfirm] = useState({
    showModal: false,
    onConfirm: () => {},
    onClose: () => {},
    message: "",
    header: "",
    confirmText: "",
    cancelText: "",
  })

  const determineActiveLink = (path, patterns, exact, strict) => {

    const match = matchPath(path, {
      path: patterns,
      exact: exact,
      strict: strict
    })

    return match ? true : false
  }

  const handleCreatorClick = () => {

    if (collapsed) {
      handleCollapsedChange(!collapsed)
    }
  }

  const handleNavigateToBuilder = (template) => {

    if (template) {
      const url = routes.private.MESSAGE_BUILDER_DYNAMIC
        .concat('/?templateId=')
        .concat(template.id)
        .replace(':communityId', navigation.communityId)

      dispatch(push(url))
      return;
    }

    const url = routes.private.CREATE_NOTICE.replace(':communityId', navigation.communityId)

    // Doing a full builder reset so we start fully from a clean slate.
    dispatch(push(url, {
      resetBuilder: true
    }))
  }

  // Fetch templates from the API
  useEffect(() => {

    if (!navigation.communityId) return;

    // We dont need to fetch the templates again if they were loaded by another component from somewhere
    // if (has(builder._templates, "community")) return;

    // Stop fetching templates when user cannot create notices
    if (community && !community.can_create_notices) return;

    dispatch(builderActions.startTemplates())
    dispatch(builderActions.fetchTemplates({
      communityId: navigation.communityId
    }))

  }, [navigation.communityId])
  
  // Refresh templates upon builder refreshData flag
  useEffect(() => {
    
    if (!builderMessages) return;

    if (builderMessages.refreshData || builderMessages.refreshDataPartial) {
      dispatch(builderActions.startTemplates())
      dispatch(builderActions.fetchTemplates({
        communityId: navigation.communityId
      }))
    }

  }, [builderMessages])



  const handleDeleteTemplate = (task) => {
    handleCloseConfirm()
    dispatch(builderActions.startTemplates())
    dispatch(builderActions.postDeleteTemplate({
      templateId: task.id
    }))
  }

  const handleCloseConfirm = () => setConfirm({})

  const confirmDeleteTemplate = (e, task) => {
    e.stopPropagation()

    setConfirm({
      showModal: true,
      onConfirm: () => handleDeleteTemplate(task),
      onClose: () => handleCloseConfirm(),
      message: (
        <Trans
          i18nKey="modal.builder.sidenavigation.message"
          components={{
            strong: <strong/>
          }}
          values={{
            name: task.caption
          }}
          />
      ),
      header: t('modal.builder.sidenavigation.header'),
      cancelText: t('modal.builder.sidenavigation.cancelText'),
      confirmText: t('modal.builder.sidenavigation.confirmText'),
    })
  }

  return (
    <ProSidebar
      collapsed={collapsed}
      breakPoint="lg"
      rtl={rtl}
      toggled={toggled}
      onToggle={handleToggleChange}>

      <SidebarContent>

        <ShowMenuConditional community={community} isVisible={"can_create_notices"} render={() => (
          <Menu className="overflow-hide">
            
            <ConfirmModal
              header={confirm.header}
              open={confirm.showModal}
              onClose={confirm.onClose}
              message={confirm.message}
              onConfirm={confirm.onConfirm}
              confirmText={confirm.confirmText}/>

            <Dropdown
              floating
              className="create-button-container-dropdown"
              onOpen={() => setMenuOpen(true)}
              onClose={() => setMenuOpen(false)}
              icon={
                <div className={`create-button-container ${collapsed ? 'collapsed' : ''}`}>
                  <div
                    className='pro-inner-item no-hover'>
                    {collapsed && (
                      <Button
                        loading={loadingTemplates}
                        disabled={loadingTemplates}
                        // onClick={handleCreatorClick}
                        className='create-button collapsed'
                        size='tiny' 
                        circular />
                    )}

                    {!collapsed && (
                      <Button
                        loading={loadingTemplates}
                        disabled={loadingTemplates}
                        // onClick={handleCreatorClick}
                        circular={collapsed}
                        className={`create-button no-margin icon`}>
                        <Icon name="plus" />
                        <span className="hide-on-collapse"> {t('sidebar.createNew')}</span>
                      </Button>
                    )}
                  </div>
                </div>
              }>
              {_templates && (
                <Dropdown.Menu>
                  {map(_templates.public, (template, i) => (
                    <TieredDropdownItem
                      key={i} 
                      template={template}
                      originalProps={{
                        className: "vertical-align",
                        onClick: () => handleNavigateToBuilder(template),
                        key: i,
                        icon: template.icon,
                        text: template.caption,
                      }}
                      />
                  ))}
                  {size(_templates.public) > 0 && <Dropdown.Divider />}
                  {map(_templates.user, (template, i) => (
                    <Dropdown.Item
                      key={i}
                      onClick={() => handleNavigateToBuilder(template)}
                      content={
                        <p className='u-template' title={template.caption}>
                          {template.caption}<Icon onClick={(e) => confirmDeleteTemplate(e, template)} className="trash delete-icon" />
                        </p>
                      } />
                  ))}
                  {size(_templates.user) > 0 && <Dropdown.Divider />}
                  {map(_templates.community, (template, i) => (<Dropdown.Item key={i} onClick={() => handleNavigateToBuilder(template)} ext={template.caption} />))}
                  {size(_templates.community) > 0 && <Dropdown.Divider />}
                  <Dropdown.Item onClick={() => handleNavigateToBuilder(null)} text={t('partials.SideNavigation.custom')} />
                </Dropdown.Menu>
              )}
            </Dropdown>
          </Menu>
        )} />

        <ShowMenuConditional community={community} isVisible={"can_create_notices"} render={() => (
          <Menu>
            <MenuItem
              active={determineActiveLink(
                router.location.pathname,
                [routes.private.HOME, routes.private.HOME_COMM],
                true, true
              )}
              icon={<Icon name="home" />}>
              {navigation.communityId && <Link to={appendCommunity(navigation.communityId, routes.private.HOME_COMM)}>{t('sidebar.home')}</Link>}
              {!navigation.communityId && <Link to={routes.private.HOME}>{t('sidebar.home')}</Link>}
            </MenuItem>
          </Menu>
        )} />

        {(community && (community.can_view_notices || community.can_create_notices)) && (
          <Menu>
            <MenuItem 
              active={determineActiveLink(router.location.pathname, [routes.private.MANAGE_NOTICE, routes.private.CREATE_NOTICE, routes.private.NOTICE_DETAIL], true, true)}
              icon={<Icon name="mail" />}>
              <Link to={appendCommunity(navigation.communityId, routes.private.MANAGE_NOTICE)}><span className='text'>{t('sidebar.notice.manage')}</span></Link>
            </MenuItem>
          </Menu>
        )}

        <Menu className={(community && (
          community.can_view_sms ||
          community.can_view_email ||
          community.can_view_persons ||
          community.can_view_users
        ) ? '' : 'has-no-content')}>
          <ShowMenuConditional community={community} isVisible={"can_view_sms"} render={() => (
            <MenuItem icon={<MdSmartphone className="icon" />}>
              <Link to={appendCommunity(navigation.communityId, routes.private.SMS)}><span className='text'>{t('sidebar.sms')}</span></Link>
            </MenuItem>
          )} />

          <ShowMenuConditional community={community} isVisible={"can_view_email"} render={() => (
            <MenuItem icon={<MdInbox className="icon" />}>
              <Link to={appendCommunity(navigation.communityId, routes.private.EMAIL)}><span className='text'>{t('sidebar.email')}</span></Link>
            </MenuItem>
          )} />

          {/* <MenuItem className="hide-on-collapse non-selectable">
            <small>{t('sidebar.users')}</small>
          </MenuItem> */}
          <ShowMenuConditional community={community} isVisible={"can_view_persons"} render={() => (
            <MenuItem
              active={determineActiveLink(
                router.location.pathname,
                [
                  routes.private.MANAGE_MEMBERS, 
                  routes.private.MEMBER_DETAIL, 
                  routes.private.MEMBER_CREATE,
                  routes.private.MEMBERS_IMPORT, 
                ],
                true, true
              )}
              icon={<Icon name="users" />}>
              <Link to={appendCommunity(navigation.communityId, routes.private.MANAGE_MEMBERS)}><span className='text'>{t('sidebar.communityMembers')}</span></Link>
            </MenuItem>
          )} />

          <ShowMenuConditional community={community} isVisible={"can_view_users"} render={() => (
            <Dropdown as="li" icon={
              <div
                className={`pro-inner-item ${determineActiveLink(
                  router.location.pathname,
                  [routes.private.MANAGE_USERS, routes.private.USER_DETAIL, routes.private.INVITES, routes.private.INVITE_USER],
                  true, true) ? 'active' : ''}`}>
                <span className="pro-icon-wrapper">
                  <span className="pro-icon">
                    <FaUserShield className="icon" />
                  </span>
                </span>
                <span className="pro-item-content"><span className='text'>{t('sidebar.systemUsers')}</span></span>
                <span className="hide-on-collapse pro-arrow-wrapper">
                  <span className="pro-arrow"></span>
                </span>
              </div>
            }
              floating
              pointing='left'
              className={`link item pro-menu-item with-dropdown ${determineActiveLink(
                router.location.pathname,
                [routes.private.MANAGE_USERS, routes.private.USER_DETAIL, routes.private.INVITES, routes.private.INVITE_USER],
                true, true) ? 'active' : ''}`}>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link className="black-text" to={appendCommunity(navigation.communityId, routes.private.MANAGE_USERS)}>{t('sidebar.systemUsers')}</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="black-text" to={appendCommunity(navigation.communityId, routes.private.INVITES)}>{t('sidebar.userInvites')}</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )} />

        </Menu>

        <Menu className={(community && (
          community.can_view_channels
        ) ? '' : 'has-no-content')}>
          {/* <MenuItem className="hide-on-collapse non-selectable">
            <small>{t('sidebar.distribution')}</small>
          </MenuItem> */}
          <ShowMenuConditional community={community} isVisible={"can_view_channels"} render={() => (
            <MenuItem
              active={determineActiveLink(
                router.location.pathname,
                [routes.private.CHANNELS, routes.private.CHANNEL_DETAIL],
                true, true
              )}
              icon={<FaBroadcastTower className="icon" />}>
              <Link to={appendCommunity(navigation.communityId, routes.private.CHANNELS)}><span className='text'>{t('sidebar.channel')}</span></Link>
            </MenuItem>
          )} />
        </Menu>
        
        <Menu className={(community && (
          community.can_view_calendars
        ) ? '' : 'has-no-content')}>
          <ShowMenuConditional community={community} isVisible={"can_view_calendars"} render={() => (
            <MenuItem
              active={determineActiveLink(
                router.location.pathname,
                [
                  routes.private.CALENDAR,
                  routes.private.MANAGE_EVENTS,
                  routes.private.CALENDAR_DETAIL,
                  routes.private.MANAGE_CALENDARS,
                  routes.private.CALENDAR_EVENT_EDIT,
                  routes.private.CALENDAR_EVENT_DETAIL,
                  routes.private.CALENDAR_GOOGLE_AUTH_CALLBACK,
                  routes.private.MANAGE_CALENDAR_ACCOUNTS,
                ],
                true, true
              )}
              icon={<FaCalendar className="icon" />}>
              <Link to={appendCommunity(navigation.communityId, routes.private.MANAGE_EVENTS)}><span className='text'>{t('sidebar.calendar')}</span></Link>
            </MenuItem>
          )} />
        </Menu>

        <Menu className={(community && (
          community.can_view_settings
        ) ? '' : 'has-no-content')}>
          <ShowMenuConditional community={community} isVisible={"can_view_settings"} render={() => (
            <Dropdown as="li" icon={
              <div
                className={`pro-inner-item ${determineActiveLink(
                  router.location.pathname,
                  [
                    routes.private.SETTING_BILLING, 
                    routes.private.SETTING_ACCOUNT, 
                    routes.private.SETTINGS_CONTACT,
                  ],
                  true, true) ? 'active' : ''}`}>
                <span className="pro-icon-wrapper">
                  <span className="pro-icon">
                    <FaCog className="icon" />
                  </span>
                </span>
                <span className="pro-item-content"><span className='text'>{t('sidebar.settings')}</span></span>
                <span className="hide-on-collapse pro-arrow-wrapper">
                  <span className="pro-arrow"></span>
                </span>
              </div>
            }
              floating
              pointing='left'
              className={`link item pro-menu-item with-dropdown ${determineActiveLink(
                router.location.pathname,
                [
                  routes.private.SETTING_BILLING,
                  routes.private.SETTING_ACCOUNT,
                  routes.private.SETTINGS_CONTACT,
                  routes.private.SETTINGS_CONTACT_DETAIL,
                  routes.private.SETTINGS_MESSAGE_PUBLISHING,
                ],
                true, true) ? 'active' : ''}`}>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link className="black-text" to={appendCommunity(navigation.communityId, routes.private.SETTING_ACCOUNT)}>{t('sidebar.settings.account')}</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="black-text" to={appendCommunity(navigation.communityId, routes.private.SETTINGS_CONTACT)}>{t('sidebar.settings.contact')}</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="black-text" to={appendCommunity(navigation.communityId, routes.private.SETTINGS_MESSAGE_PUBLISHING)}>{t('sidebar.settings.message-publishing')}</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )} />
        </Menu>
      </SidebarContent>

    </ProSidebar>
  )
}

export default SideNavigation
