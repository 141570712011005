class PostAttachMembersRequest {

    constructor({
        personIds,
        channelId,
        shouldNotify,
    }) {
        this.personIds = personIds
        this.channelId = channelId
        this.shouldNotify = shouldNotify
    }

    getPayload = () => {
        
        const payload = {
            "person_ids": this.personIds,
            "notify_subscription_changes_via_app": this.shouldNotify
        }

        return payload
    }
    
}

export default PostAttachMembersRequest