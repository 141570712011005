import {
    createAction
} from 'redux-actions'

import { actions } from '../constants'
import { calendarService, eventService } from '../api'
import { push, replace } from 'connected-react-router'
import routes from '../routes/route-constants'
import { appendCommunity } from '../utils/helper'
import { PostCreateEventRequest, PostCreateICalCalendarRequest, PostUpdateEventRequest } from '../api/requestObjects'
import { eventsActions } from './eventsActions'
import moment from 'moment'

const { CALENDAR } = actions

export const calendarsActions = {

    /**
     * Start loading
     */
    start: createAction(CALENDAR.START),
    
    /**
     * Start loading
     */
    startSources: createAction(CALENDAR.START_SOURCES),
    
    /**
     * Start loading detail page
     */
    loadDetail: createAction(CALENDAR.START_DETAIL),
    
    /**
     * Stop loading detail page
     */
    stopDetail: createAction(CALENDAR.END_DETAIL),

    /**
     * Show error messages
     */
    error: createAction(CALENDAR.ERROR),

    /**
     * Show success messages
     */
    success: createAction(CALENDAR.SUCCESS),

    /**
     * reset data
     */
    reset: createAction(CALENDAR.RESET),

    /**
     * Save calendars
     */
    saveCalendars: createAction(CALENDAR.SAVE),
    
    /**
     * Save sync accounts
     */
    saveAccounts: createAction(CALENDAR.SAVE_SYNC_ACCOUNTS),

    /**
     * Save calendar details
     */
    saveCalendar: createAction(CALENDAR.SAVE_CALENDAR_DETAILS),
    
    /**
     * Save calendar events details
     */
     saveCalendarEvents: createAction(CALENDAR.SAVE_CALENDAR_EVENT_DETAILS),
    
    /**
     * Save calendar sources
     */
    saveCalendarSources: createAction(CALENDAR.SAVE_CALENDAR_SOURCES),
    
    /**
     * Save account details
     */
    saveAccount: createAction(CALENDAR.SAVE_SYNC_ACCOUNT),

    /**
     * Clean calendar details
     */
    cleanCalendarDetails: createAction(CALENDAR.CLEAR_CALENDAR_DETAILS),

    /**
     * Clear errors and messages
     */
    clearMessages: createAction(CALENDAR.CLEAR_MESSAGES),

    /**
     * Save calendars' filters
     */
    saveFilters: createAction(CALENDAR.SAVE_FILTERS),


    /**
     * Google Auth Request Via Relay API
     * 
     * @param {JSON} params 
     */
    fetchGoogleAuth(params) {
        return (dispatch, getState) => calendarService.fetchGoogleAuth(params)
    },

    /**
     * Google Auth Request Via Relay API
     * 
     * @param {JSON} params 
     */
    fetchMicrosoftAuth(params) {
        return (dispatch, getState) => calendarService.fetchMicrosoftAuth(params)
    },

    /**
     * Post create sync account
     * 
     * @param {JSON} params 
     */
    postCreateGoogleSyncAccount(params) {
        return (dispatch, getState) => calendarService.postCreateGoogleSyncAccount(params)
            .then(response => {
                dispatch(this.saveAccount(response.data))
                
                //? NOTE: Can we can show a modal message for success?
                // dispatch(this.success({
                //     header: 'calendarsActions.createCalendar.success.header',
                //     content: 'calendarsActions.createCalendar.success.message',
                // }))

                // We will redirect to create calendar with a flag to activate the second step
                // where we would have selected the recently created sync account
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.CALENDAR_CREATE
                        .concat('?syncMethod=').concat(params?.syncMethod ?? 0)
                        .concat('&accountId=').concat(response.data.id)
                        .concat('&strategyId=').concat(response.data.strategy)
                )))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post create sync account
     * 
     * @param {JSON} params 
     */
    postCreateMicrosoftSyncAccount(params) {
        return (dispatch, getState) => calendarService.postCreateMicrosoftSyncAccount(params)
            .then(response => {
                dispatch(this.saveAccount(response.data))
                
                //? NOTE: Can we can show a modal message for success?
                // dispatch(this.success({
                //     header: 'calendarsActions.createCalendar.success.header',
                //     content: 'calendarsActions.createCalendar.success.message',
                // }))

                // We will redirect to create calendar with a flag to activate the second step
                // where we would have selected the recently created sync account
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.CALENDAR_CREATE
                        .concat('?syncMethod=').concat(params?.syncMethod ?? 0)
                        .concat('&accountId=').concat(response.data.id)
                        .concat('&strategyId=').concat(response.data.strategy)
                )))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post re-auth sync account
     * 
     * @param {JSON} params 
     */
    postReAuthSyncAccount(params) {
        return (dispatch, getState) => calendarService.postReAuthSyncAccount(params)
            .then(response => {
                dispatch(push(appendCommunity(
                    params.communityId,
                    routes.private.MANAGE_CALENDARS
                )))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Initiates an asynchronous re-sync of events from relay-calendars to relay-api, optionally forcing a re-sync from SOURCE to relay-calendars.
     * 
     * @param {JSON} params 
     */
    doForcedSync(params) {
        return (dispatch, getState) => calendarService.doForcedSync(params)
            .then(response => {

                dispatch(this.success({
                    // header: 'calendarsActions.syncCalendar.success.header',
                    // content: 'calendarsActions.syncCalendar.success.message',
                    refreshData: true,
                }))
                dispatch(this.stopDetail())
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Initiates an asynchronous re-sync of events from relay-calendars to relay-api, optionally forcing a re-sync from SOURCE to relay-calendars.
     * 
     * @param {JSON} params 
     */
    doSyncAll(params) {
        return (dispatch, getState) => calendarService.doSyncAll(params)
            .then(response => {

                dispatch(this.success({
                    refreshStatuses: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch sync accounts from API
     * 
     * @param {JSON} params 
     */
    fetchSyncAccounts(params) {
        return (dispatch, getState) => calendarService.getSyncAccounts(params)
            .then(response => {
                dispatch(this.saveAccounts(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch sync account sources
     * 
     * @param {JSON} params 
     */
    fetchCalendarSyncSources(params) {
        return (dispatch, getState) => calendarService.getCalendarSyncSources(params)
            .then(response => {
                const data = {
                    strategyId: params.strategyId,
                    ...response.data,
                }
                dispatch(this.saveCalendarSources(data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch calendar filters
     * 
     * @param {JSON} params 
     */
    fetchCalendarFilters(params) {
        return (dispatch, getState) => calendarService.getCalendarFilters(params)
            .then(response => {
                dispatch(this.saveFilters(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch calendars from API
     * 
     * @param {JSON} params 
     */
    fetchCalendars(params) {
        return (dispatch, getState) => calendarService.getCalendars(params)
            .then(response => {
                dispatch(this.saveCalendars(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Fetch calendar from API
     * 
     * @param {JSON} params 
     */
    fetchCalendar(params) {
        return (dispatch, getState) => calendarService.getCalendar(params)
            .then(response => {
                dispatch(this.saveCalendar(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Fetch calendar events from API
     * 
     * @param {JSON} params 
     */
    fetchCalendarEvents(params) {
        return (dispatch, getState) => calendarService.getCalendarEvents(params)
            .then(response => {
                dispatch(this.saveCalendarEvents(response.data))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post create new Google calendar
     * 
     * @param {PostCreateCalendarRequest} params 
     */
    postCreateCalendar(params) {
        return (dispatch, getState) => calendarService.postCreateCalendar(params)
            .then(response => {
                dispatch(this.success({
                    header: 'calendarsActions.createCalendar.success.header',
                    content: 'calendarsActions.createCalendar.success.message',
                }))
                
                window.location.href = routes.private.MANAGE_CALENDARS.replace(':communityId', params.communityId)
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post create new iCal calendar
     * 
     * @param {PostCreateICalCalendarRequest} params 
     */
    postCreateICalCalendar(params) {
        return (dispatch, getState) => calendarService.postCreateICalCalendar(params)
            .then(response => {
                
                dispatch(this.success({
                    header: 'calendarsActions.createCalendar.ical.success.header',
                    content: 'calendarsActions.createCalendar.ical.success.message',
                }))
                
                window.location.href = routes.private.MANAGE_CALENDARS.replace(':communityId', params.communityId)
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post create new event linked to the given calendar id
     * ?NOTE: When creating an event from the Calendar detail view, we will redirect back to the calendar detail when event creation is complete
     * 
     * @param {PostCreateEventRequest} params 
     */
     postCreateCalendarEvent(params) {
        return (dispatch, getState) => eventService.postCreateEvent(params)
            .then(response => {
                
                dispatch(this.success({
                    header: 'calendarsActions.createEvent.success.header',
                    content: 'calendarsActions.createEvent.success.message',
                    refreshEvents: true,
                }))

                // dispatch(push(
                //     routes.private.CALENDAR_DETAIL
                //         .replace(':id', params.calendarId)
                //         .replace(':communityId', params.communityId)
                // ))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Update existing event
     * 
     * @param {PostUpdateEventRequest} params 
     */
    putUpdateCalendarEvent(params) {
        
        return (dispatch, getState) => eventService.putUpdateEvent(params)
            .then(response => {

                dispatch(eventsActions.success({
                    header: 'eventsActions.updateEvent.success.header',
                    content: 'eventsActions.updateEvent.success.message',
                    navigateMain: true,
                }))
            })
            .catch(error => {
                // dispatch(this.error(error))
                dispatch(eventsActions.error(error))
            })
    },
    
    /**
     * Update existing calendar
     * 
     * @param {PostCreateCalendarRequest} params 
     */
    putUpdateCalendar(params) {
        return (dispatch, getState) => calendarService.putUpdateCalendar(params)
            .then(response => {
                dispatch(this.success({
                    header: 'calendarsActions.updateCalendar.success.header',
                    content: 'calendarsActions.updateCalendar.success.message',
                    refreshByCalendarStatus: {
                        updated: moment().toISOString()
                    },
                }))
                dispatch(this.stopDetail())
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },

    /**
     * Post delete calendar
     * 
     * @param {JSON} params 
     */
    postDeleteCalendar(params) {
        return (dispatch, getState) => calendarService.postDeleteCalendar(params)
            .then(response => {
                dispatch(push(routes.private.MANAGE_CALENDARS.replace(':communityId', params.communityId)))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post unlink calendar
     * 
     * @param {JSON} params 
     */
    postUnlinkCalendar(params) {
        return (dispatch, getState) => calendarService.postUnlinkCalendar(params)
            .then(response => {
                dispatch(push(routes.private.MANAGE_CALENDARS.replace(':communityId', params.communityId)))
                dispatch(this.success({
                    header: 'calendarsActions.updateCalendar.success.header',
                    content: 'calendarsActions.updateCalendar.success.message',
                    refreshData: true,
                }))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Post delete community sync account
     * 
     * @param {JSON} params 
     */
    postDeleteAccount(params) {
        return (dispatch, getState) => calendarService.postDeleteAccount(params)
            .then(response => {

                dispatch(this.success({
                    refreshData: true,
                    navigateMain: true,
                }))

                dispatch(push(
                    routes.private.MANAGE_CALENDAR_ACCOUNTS
                        .replace(':communityId', params.communityId)
                ))
            })
            .catch(error => {
                dispatch(this.error(error))
            })
    },
    
    /**
     * Determine if there has been any calendar updates since last checked.
     * 
     * @param {JSON} params 
     */
    checkCalendarStatus(params) {
        return (dispatch, getState) => calendarService.checkCalendarStatus(params)
            .then(response => {
                dispatch(this.success({
                    refreshByCalendarStatus: response.data,
                }))
            })
            .catch(error => {
                // dispatch(this.error(error))
            })
    },
} 