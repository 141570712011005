import React, { useState, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import { flatten, isNull, last, map, mapKeys } from 'lodash'

const AdaptiveIcon = (props) => {

    const {
        url,
        size,
        className,
    } = props
         
    const [icon, setIcon] = useState({
        name: 'file',
        color: 'grey-text'
    })

    useEffect(() => {
        // We will split the url paths using "." so we can take the last part of the link
        // more often the last part of the link will be the file extension
        /***
         *  @type {String} 
         */
        const file = last(url.split("."))

        // This is our icon grouping, which will act as our lookup table
        const icons = {
            'file pdf': ['pdf'],
            'image': ['png', 'jpeg', 'jpg', 'gif', 'heif', 'heic', 'heif-sequence', 'heic-sequence', 'svg'],
            'file word': ['doc', 'docx', 'rtf'],
            'file powerpoint': ['pptx'],
            'file excel': ['csv', 'xls', 'xlsx'],
            'file video': ['flv', 'mp4', 'm3u8', '3gp', 'mov', 'avi', 'wmv'],
            'music': ['mp3', 'ogg', 'm3u', 'mid'],
        }
        
        const iconColors = {
            'file pdf': 'red-text',
            'image': 'blue-text',
            'file word': 'blue-text',
            'file powerpoint': 'orange-text',
            'file excel': 'green-text',
            'file video': 'grey-text',
            'music': 'grey-text',
        }

        const defaultIcon = 'file'

        // Creating a map of all the values available for the various group
        const all = mapKeys(flatten(map(icons)))

        // Search for placement
        const foundIcon = all[file.toLowerCase()]

        // We will now loop through the groups to check where this icon belongs
        // const nIcon = filter(icons, (v, k) => (v.includes(foundIcon)))
        let nIcon = map(icons, (v, k) => v.includes(foundIcon) ? k : null).filter(x => !isNull(x))[0]
            nIcon = nIcon ?? defaultIcon
        
        const iconColor = iconColors[nIcon]
        
        setIcon({
            name: nIcon,
            color: iconColor,
        })
        
    }, [url])

    return <Icon name={icon.name} size={size ?? "huge"} className={[icon.color, 'adaptive-icon', className].join(" ")} />
}

export default AdaptiveIcon