import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import routes from './route-constants'
import Login from '../views/Login'
import LoginRedirect from '../views/LoginRedirect'
import GoogleRedirectCallback from '../views/GoogleRedirectCallback'

export default function Routes() {
    
    return (
        <Switch>
            <Route 
                path={ routes.public.LOGIN }
                render={(props) => <Login {...props}/>} />
            
            <Route 
                path={ routes.public.LOGIN_REDIRECT }
                component={LoginRedirect} />

            {/* <Route
                path={routes.private.CALENDAR_GOOGLE_AUTH_CALLBACK}
                exact={true}
                component={GoogleRedirectCallback} /> */}

            {/* Fallback to Login, Keep initial URL */}
            <Route 
                render={(props) => <Redirect to={{ 
                    pathname: routes.public.LOGIN, 
                    state: {from: props.location.pathname.concat(props.location.search)} 
                }} />}
            />

            {/* <Redirect 
                to={ routes.public.LOGIN } /> */}
        </Switch>
    )
}
