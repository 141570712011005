import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import { Button, Form, Grid, Icon, Popup } from 'semantic-ui-react'
import PhoneNumberInput from './PhoneNumberInput'
import SearchLocationInput from '../../utils/SearchLocationInput'
import { Fragment } from 'react'
import { actions } from '../../constants'
import { isFunction, isObject } from 'lodash'

const propTypes = {
	icon: PropTypes.string,
    caption: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.oneOf([null])]),
    cover: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.oneOf([null])]),
    actions: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
    data: PropTypes.object,
    errors: PropTypes.bool,
    classNames: PropTypes.array,
}

const defaultProps = {
	icon: "mail",
	caption: null,
    content: <p />,
    description: null,
    cover: null,
    actions: null,
    data: null,
    errors: null,
    classNames: [],
}

const PageSection = (props) => {

    const {
        icon,
        cover,
        data,
        errors,
        actions,
        caption,
        content,
        classNames,
        description,
    } = props
    
    const logoInputRef = createRef()
    const coverInputRef = createRef()

    if (cover) {
        return (
            <div className="page-section white-bg with-cover">
                <div className="header-container text-center">
                    <div className="buttons-gradient">
                        <Button 
                            onClick={() => coverInputRef.current.click()} 
                            className="secondary white update-cover-button">
                            {i18next.t("partials.PageSection.updateCover")}
                        </Button>
                        <input 
                            type="file" 
                            onChange={cover.onCoverChosen}
                            accept='image/jpeg,image/gif,image/png,image/heif,image/heic,image/heif-sequence,image/heic-sequence'
                            name='coverImageFile'
                            ref={coverInputRef}
                            style={{display: "none"}}/>
                    </div>
                    <div className="cover-image" style={{
                        backgroundImage: `url(${cover.cover_image})`
                    }}>
                        <div className="profile-image" style={{
                            backgroundSize: 'contain',
                            backgroundImage: `url(${cover.image})`
                        }}>
                            <Button 
                                size="huge" 
                                circular 
                                className='update-profile-button z-depth-1' 
                                icon="upload"
                                onClick={() => logoInputRef.current.click()} />
                            <input 
                                type="file" 
                                onChange={cover.onLogoChosen}
                                accept='image/jpeg,image/gif,image/png,image/heif,image/heic,image/heif-sequence,image/heic-sequence'
                                ref={logoInputRef}
                                name='imageFile'
                                style={{display: "none"}}/>
                        </div>
                    </div>
                </div>
                <div className="page-sub-caption">
                    <p className="grey-text no-margin"><strong>{i18next.t('partials.PageSection.recommendedSize')}</strong></p>
                    <p className="grey-text">{i18next.t('partials.PageSection.recommendedSizeDescription')}</p>
                </div>
                <Form error={errors} className="page-content">
                    <Grid className="collapse-on-small-down">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h3 className="section-header">
                                    {icon&& <Icon className='primary-color-text' name={icon} />}
                                    {caption}
                                </h3>
                            </Grid.Column>
                        </Grid.Row>
                        {description && (
                            <Grid.Row stretched className='no-margin no-padding'>
                                <Grid.Column width={16}>
                                    <p className="section-description grey-text">{description}</p>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        {isFunction(content) ? content(data) : content}
                    </Grid>
                </Form>
            </div>
        )
    }

    return (
        <Fragment>
            <div className={["page-section white-bg", ...classNames].join(' ')}>
                <div className="no-margin">
                    {caption && (
                        <h3 className="section-header no-padding">
                            {icon && <Icon className='primary-color-text' name={icon} />}
                            {caption}
                        </h3>
                    )}
                    {description && (
                        <Grid.Row stretched>
                            <p className="section-description grey-text">{description}</p>
                        </Grid.Row>
                    )}
                    <Grid.Row stretched>
                        {isFunction(content) ? content(data) : content}
                    </Grid.Row>
                </div>
            </div>
            {actions && (
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column className='right-align'>
                            { actions.custom && !actions.custom?.popup && (
                                <Button 
                                    size="small"
                                    floated={actions.custom?.floated ?? undefined}
                                    onClick={actions.custom.onClick} 
                                    disabled={actions.custom?.disabled ?? false}
                                    className={actions.custom?.className ?? 'button secondary'}>
                                    {actions.custom.caption}
                                </Button>
                            )}

                            { actions.custom && actions.custom.popup && (
                                <Popup
                                    basic
                                    flowing
                                    size="tiny"
                                    className="morphed"
                                    trigger={
                                        <Button 
                                            size="small"
                                            floated={actions.custom?.floated ?? undefined}
                                            className={'button secondary disabled-actionable'}>
                                            {actions.custom.caption}
                                        </Button>
                                    }
                                    content={actions.custom.popup}
                                />
                            )}

                            { actions.cancel && (
                                <Button 
                                    size="small"
                                    floated={actions.cancel?.floated ?? undefined}
                                    onClick={actions.cancel.onClick} 
                                    disabled={actions.cancel?.disabled ?? false}
                                    className='button secondary'>
                                    {actions.cancel.caption}
                                </Button>
                            )}
                            { actions.primary && (
                                <Button
                                    size="small"
                                    floated={actions.primary?.floated ?? undefined}
                                    disabled={actions.primary?.disabled ?? false}
                                    loading={actions.primary?.loading ?? false}
                                    onClick={actions.primary.onClick} 
                                    className='button primary'>
                                    {actions.primary.caption}
                                </Button>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Fragment>
    )
}

PageSection.propTypes = propTypes
PageSection.defaultProps = defaultProps

export default PageSection

