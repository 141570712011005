import { identity, isString, map, pickBy, pick } from "lodash"
import { filterNulls } from "../../utils/helper"

class GetPublishBreakdownRequest {
    
    constructor({
        strategy,
        platforms,
        noticeId,
        communityId,
        sendPush,
        pinItem,
        scheduledPublishAt,
    }) {
        this.pinItem = pinItem
        this.sendPush = sendPush
        this.strategy = strategy
        this.noticeId = noticeId
        this.platforms = platforms
        this.communityId = communityId 
        this.scheduledPublishAt = scheduledPublishAt 
    }

    isValid = () => {
        return true
    }

    getSelectedPlatform = () => {
        const _platforms = map(this.platforms, (o, i) => (o.selected === false) ? null : i)
        return _platforms.filter(isString) 
    }

    getPayload = () => {
        
        let payload = {
            "strategy": this.strategy,
            "platforms": this.getSelectedPlatform(),
        }

        if (this.scheduledPublishAt) {
            payload['scheduled_publish_at'] = this.scheduledPublishAt
        }

        // When App is un-checked we should also remove the "pin_notice" and "send_push_notification"
        if (payload.platforms.includes("app")) {
            payload = {
                ...payload,
                "pin_notice": this.pinItem,
                "send_push_notification": this.sendPush,
            }
        }

        // When the users unticks the app, we should soft target to app  and "send_push_notification"
        payload['soft_target_platform_app'] = !payload.platforms.includes("app")
        if (payload['soft_target_platform_app']) {
            payload['send_push_notification'] = this.sendPush
        }

        return filterNulls(payload)
    }
    
    getExcludePayload = (params) => {
        
        const payload = {
            ...this.getPayload(),
            ...pick(params, ['sort', 'per_page', 'page'])
        }

        return filterNulls(payload)
    }
    
    getExcludeDownloadPayload = (params) => {
        
        const payload = {
            ...this.getExcludePayload(params),
            export: true,
        }

        return payload
    }

}

export default GetPublishBreakdownRequest