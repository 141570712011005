import React from 'react'
import { List } from 'semantic-ui-react'
import BaseTemplate from './BaseTemplate'

// TODO: Remove this component preview once Event Descriptions are aligned with Text Components
const EventText = ({ item, classNames = '' }) => {
    
    const content = item?.data?.content ?? ""
    
    return (
        <BaseTemplate classNames={classNames} render={() => (
            <List.Content>
                <List.Description className="description-text">
                    <div>{ content }</div>
                </List.Description>
            </List.Content>
        )} />
    )
}

export default EventText
