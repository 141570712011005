import i18next from "i18next"
import { mapKeys, isEmpty } from "lodash"
import { filterNulls } from "../../utils/helper"

class UpdateMemberRequest {

    constructor({
        email,
        tagIds,
        optIns,
        lastName,
        idNumber, 
        firstName, 
        reference,
        channelIds,
        phoneNumber,
        communityId,
        debtorReference,
        isPublicFollower,
        parentPersonIds,
        childrenPersonIds,
    }) {
        this.email = email
        this.tagIds = tagIds
        this.optIns = optIns
        this.idNumber = idNumber
        this.lastName = lastName
        this.reference = reference
        this.firstName = firstName
        this.channelIds = channelIds
        this.phoneNumber = phoneNumber
        this.communityId = communityId
        this.debtorReference = debtorReference
        this.parentPersonIds = parentPersonIds
        this.childrenPersonIds = childrenPersonIds
        this.isPublicFollower = isPublicFollower
    }

    getPayload = () => {
        
        const payload = {
            "email": this.email,
            "tag_ids": this.tagIds,
            "last_name": this.lastName,
            "id_number": this.idNumber,
            "first_name": this.firstName,
            "channel_ids": this.channelIds,
            "opt_in_platforms": this.optIns,
            "community_id": this.communityId,
            "phone_number": this.phoneNumber,
            "unique_identifier": this.reference,
            "debtor_reference": this.debtorReference,
            "parent_person_ids": this.parentPersonIds,
            "child_person_ids": this.childrenPersonIds,
            "is_public_follower": this.isPublicFollower,
        }

        return filterNulls(payload)
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validator = () => {
        
        const errors = []
        
        // if (!this.email || !mailValidator.test(this.email)) {
        //     errors.push({
        //         field: "email",
        //         isValid: false,
        //         message:!this.email ? i18next.t('requestObjects.CreateMemberRequest.requiredEmail') : i18next.t('requestObjects.CreateMemberRequest.invalidEmail'),
        //     })
        // }
        
        if (!this.firstName) {
            errors.push({
                field: "firstName",
                isValid: false,
                message: i18next.t('requestObjects.CreateMemberRequest.requiredFirstName'),
            })
        }
        
        if (!this.lastName) {
            errors.push({
                field: "lastName",
                isValid: false,
                message: i18next.t('requestObjects.CreateMemberRequest.requiredLastName'),
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }
    
}

export default UpdateMemberRequest