import { UploadFileRequest } from "../api/requestObjects"
import Config from "../config"
import { unsupportedImageMimes } from "../constants"
import ApiService from "./ApiService"

class UploadService extends ApiService {

    /**
     * Upload the given file into Relay Uploads
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    upload = async (params) => {
        
        const { UPLOAD_FILE } = this.endpoints
        const request = new UploadFileRequest(params)

        const response = await this.API.post(this.getBaseUploadEndpoint({
            path: UPLOAD_FILE,
        }), request.getPayload())

        const data = {
            url: null,
            blob: null
        }

        if (unsupportedImageMimes.includes(params.mime)) {

            // When we have a HEIF/HEIC we should get the blob preview using the api
            const previewData = await this.getFilePreview({fileId: response.data.id})
            data.blob = new Blob([previewData.data], {type: "image/jpg"})
            data.url = URL.createObjectURL(data.blob)
        }

        return Promise.resolve({
            id: response.data.id,
            data: data,
        })
    }
    
    /**
     * Get the JPEG preview of this given HEIF/HEIC image from Relay Uploads
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getFilePreview = (params) => {
        
        const { GET_FILE_PREVIEW } = this.endpoints

        return this.API.get(this.getBaseUploadEndpoint({
            path: GET_FILE_PREVIEW.replace("%s", params.fileId),
        }), { 
            params: {
                mode: 'thumbnail',
            },
            responseType: "blob" 
        })
    }
    
    /**
     * [Fake/Test] Upload the given file into Relay Uploads
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    mock = async (params) => {
        const { UPLOAD_FILE } = this.endpoints
        const request = new UploadFileRequest(params)

        // const response = await this.API.post("http://localhost:14300/api/upload/v1/files", request.getPayload())
        const response = await this.API.post("http://localhost:3001/api/upload/v1/files", request.getPayload())

        const data = {
            url: null,
            blob: null
        }
        
        if (unsupportedImageMimes.includes(params.mime)) {

            // When we have a HEIF/HEIC we should get the blob preview using the api
            const previewData = await this.getFilePreview({fileId: response.data.id})
            data.blob = new Blob([previewData.data], {type: "image/jpg"})
            data.url = URL.createObjectURL(data.blob)
        }

        return Promise.resolve({
            id: response.data.id,
            data: data,
        })
    }

    /**
     * Return API endpoint with given path
     * 
     * @return 	{String}
     */
    getBaseUploadEndpoint({ path, version = "v1" }) {
        return Config.uploadsBaseUrl
            .concat("/")
            .concat(version)
            .concat(path)
    }
}

export default UploadService