import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'

const propTypes = {
    customRow: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    sectionOne: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    sectionSix: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    sectionTwo: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    sectionFour: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    sectionFive: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    sectionThree: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]),
    showFilters: PropTypes.bool,
}

const defaultProps = {
    sectionOne: null,
    sectionTwo: null,
    sectionThree: null,
    sectionFour: null,
    sectionFive: null,
    sectionSix: null,
    customRow: null,
    showFilters: true,
}

/**
 * We are using this placeholder to make sure we have consistent pager containers across the board
 * 
 * @param {*} props 
 * @returns {JSX.Element}
 */
const PaginationContainer = (props) => {

    const {
        sectionOne,
        sectionTwo,
        sectionThree,
        sectionFour,
        sectionFive,
        sectionSix,
        showFilters,
        customRow,
    } = props

    return (
        <div className="pagination-container">
            {showFilters ? (
                <Grid className='filters-section'>
                    <Grid.Row className='no-pad-bot'>
                        <Grid.Column width={4}>
                            {sectionOne}
                        </Grid.Column>
                        <Grid.Column width={12} verticalAlign="bottom" textAlign="right">
                            {sectionTwo}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        {/* <Grid.Column width={4}> */}
                            {sectionThree}
                        {/* </Grid.Column> */}
                        {/* <Grid.Column width={12}> */}
                            {sectionFour}
                        {/* </Grid.Column> */}
                    </Grid.Row>
                    {customRow}
                </Grid>
            ) : null}
            <Grid className={showFilters ? 'pagers-section' : ''}>
                <Grid.Row>
                    <Grid.Column computer={9} tablet={9} mobile={16} className='action___buttons'>
                        {sectionFive}
                    </Grid.Column>
                    <Grid.Column computer={7} tablet={7} mobile={16}>
                        {sectionSix}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

PaginationContainer.propTypes = propTypes
PaginationContainer.defaultProps = defaultProps

export default PaginationContainer
