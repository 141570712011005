import i18next from "i18next"
import { pickBy, identity, mapKeys, isEmpty, isUndefined, filter, uniq, isNil, size, isNumber, upperCase, map } from "lodash"
import { filterNulls } from "../../utils/helper"
import moment from "moment"
import { calendarConstants } from "../../constants"

/**
 * 
 * @typedef Request
 * @property {Number} interval
 * @property {String} frequency
 * @property {Array}  days
 * @property {Array}  _days
 * @property {String} until
 * @property {String} doesEnd
 * @property {String} endsOnDate
 * @property {String} monthlyOn
 * @property {Number} afterOccurrences
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */


class PostEventRecurringRulesRequest {

    /**
     * 
     * @param {Request} request
     */
    constructor({
        days,
        until,
        doesEnd,
        interval,
        frequency,
        endsOnDate,
        monthlyOn,
        afterOccurrences,
    }) {
        this.days = days
        this.until = until
        this.doesEnd = doesEnd
        this.interval = interval
        this.frequency = frequency
        this.monthlyOn = monthlyOn
        this.endsOnDate = endsOnDate
        this.afterOccurrences = afterOccurrences
    }

    /**
     * Post data ready for usage by the API
     * 
     * @returns {Request}
     */
    getPayload = () => {

        const payload = {
            frequency: this.frequency,
            interval: this.interval,
            days: uniq(this.days).map(i => calendarConstants.REPEAT_WEEKDAYS[i]?.index),
            _days: uniq(this.days).map(i => calendarConstants.REPEAT_WEEKDAYS[i]?.rruleKey.weekday),
            _raw_days: uniq(this.days).map(i => calendarConstants.REPEAT_WEEKDAYS[i]?.rruleKey.toString()),
            count: null,
            until: null,
            doesEnd: this.doesEnd,
            monthlyOn: null,
            endsOnDate: this.endsOnDate,
            afterOccurrences: null
        }

        if (this.frequency !== calendarConstants.REPEAT_FREQUENCY.WEEKLY.key) {
            payload["days"] = null
            payload["_days"] = null
        }
        
        if (this.frequency == calendarConstants.REPEAT_FREQUENCY.MONTHLY.key) {
            payload["monthlyOn"] = this.monthlyOn
        }

        if (this.doesEnd === 'never') {
            payload["until"] = null
        } else if (this.doesEnd === 'ends_on') {
            payload["until"] = moment(this.endsOnDate).toISOString()
        } else if (this.doesEnd === 'ends_after') {
            payload["count"] = parseInt(this.afterOccurrences)
            payload["afterOccurrences"] = this.afterOccurrences
        }

        return payload
    }

    /**
     * Determine error messages from the given data if any
     * 
     * @returns {ValidationError}
     */
    validator = () => {

        const errors = []

        if (isEmpty(this.frequency)) {
            errors.push({
                field: "frequency",
                isValid: false,
                message: i18next.t("calendar.events.create.requiredFrequency")
            })
        }
        
        if (!isNumber(parseInt(this.interval))) {
            errors.push({
                field: "interval",
                isValid: false,
                message: i18next.t("calendar.events.create.requiredInterval")
            })
        }
        
        if (parseInt(this.interval) < 1) {
            errors.push({
                field: "interval",
                isValid: false,
                message: i18next.t("calendar.events.create.min.requiredInterval")
            })
        }
        
        if (size(this.days) === 0 && this.frequency === calendarConstants.REPEAT_FREQUENCY.WEEKLY.key) {
            errors.push({
                field: "days",
                isValid: false,
                message: i18next.t("calendar.events.create.requiredDays")
            })
        }
        
        if (parseInt(this.interval) > 5 && this.frequency === calendarConstants.REPEAT_FREQUENCY.YEARLY.key) {
            errors.push({
                field: "frequency",
                isValid: false,
                message: i18next.t("calendar.events.create.requiredFrequency.maxReached")
            })
        }
        
        if (this.doesEnd === 'ends_on' && !moment(this.endsOnDate).isValid()) {
            errors.push({
                field: "endsOnDate",
                isValid: false,
                message: i18next.t("calendar.events.create.requiredEndsOn")
            })
        }
        
        if (this.doesEnd === 'ends_after' && !isNumber(parseInt(this.afterOccurrences))) {
            errors.push({
                field: "endsAfter",
                isValid: false,
                message: i18next.t("calendar.events.create.requiredOccurrences")
            })
        }
        
        if (this.doesEnd === 'ends_after' && parseInt(this.afterOccurrences) <= 0) {
            errors.push({
                field: "endsAfter",
                isValid: false,
                message: i18next.t("calendar.events.create.min.requiredOccurrences")
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default PostEventRecurringRulesRequest