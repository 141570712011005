import PropTypes from 'prop-types'
import React, {Component} from 'react'
import { Input } from 'semantic-ui-react'

const propTypes = {
	clearFilterText: PropTypes.string.isRequired,
	clearable: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
}

const Icon = ({
    clearFilterText,
    clearable,
    disabled,
    value,
    handleClickClear,
}) => {

    if (clearable && value && !disabled) {
        return { 
            name: 'cancel', 
            link: true, 
            title: clearFilterText,
            onClick: handleClickClear
        }
    }

    return { 
        name: 'search'
    }
}

const Filter = (props) => {

	const handleChange = (e) => {
		props.onChange(e.target.value)
	}

	const handleClickClear = () => {
		props.onChange('')
	}

    const {
        disabled,
        placeholder,
        value
    } = props

    return (
        <Input
            type="text"
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            icon={Icon({...props, handleClickClear})}
            autoComplete="off" 
            onChange={handleChange}/>
    )
}

Filter.propTypes = propTypes

export default Filter