
class GetMemberFiltersRequest {

    constructor({
        communityId, 
    }) {
        this.communityId = communityId
    }


    getPayload = () => {
        return {
            "community_id": this.communityId,
            "extended": 1,
        }
    }
    
}

export default GetMemberFiltersRequest