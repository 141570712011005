import { actions, calendarConstants, constants } from '../constants'
import { map, size } from 'lodash'

import { getCalendarStrategy } from '../utils/helper'
import moment from "moment"
import i18next from 'i18next'

const { CALENDAR } = actions

const initialState = {
    calendars: [],
    loading: false,
    loadingSources: false,
    detail: {
        loading: false,
    },
    sources: {
        accounts: null,
        account: null,
        data: null,
        dataTransformed: null
    },
    filters: {},
    pagination: {},
    error: null,
    messages: null,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case CALENDAR.RESET: {
            return {
                ...state,
                calendars: [],
                loading: false,
                detail: null,
                filters: {},
                pagination: {},
                error: null,
                messages: null,
                loadingSources: false,
            }
        }
        case CALENDAR.START: {
            return { ...state, loading: true }
        }
        case CALENDAR.START_SOURCES: {
            return { ...state, loadingSources: true }
        }
        case CALENDAR.START_DETAIL: {
            return { 
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                }
            }
        }
        case CALENDAR.END_DETAIL: {
            return { 
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                }
            }
        }
        case CALENDAR.CLEAR_MESSAGES: {
            return { ...state, messages: null, errors: null }
        }
        case CALENDAR.ERROR: {
            let error = action.payload.response || {}

            if (navigator.onLine) {
                error = error?.data?.message || "error.errorOccurred"
            } else {
                error = "general.errors.network.message"
            }

            return {
                ...state,
                loading: false,
                loadingSources: false,
                errors: error,
                messages: null,
                detail: {
                    ...state.detail,
                    loading: false
                }
            }
        }
        case CALENDAR.SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                messages: action.payload,
                detail: {
                    ...state.detail,
                    loading: false
                }
            }
        }
        case CALENDAR.SAVE_SYNC_ACCOUNT: {

            return {
                ...state,
                loading: false,
                sources: {
                    ...state.sources,
                    account: action.payload.data
                }
            }
        }
        case CALENDAR.SAVE_SYNC_ACCOUNTS: {

            return {
                ...state,
                loading: false,
                sources: {
                    ...state.sources,
                    account: null,
                    pagination: action.payload.pagination,
                    accountsTransformed: map(action.payload.data, c => {
                        
                        const strategy = getCalendarStrategy(c.strategy)
                        const strategyName = strategy.displayName

                        return {
                            key: c.id, 
                            value: c.id, 
                            text: c.name,
                            image: {
                                rounded: true,
                                src: strategy.icon
                            },
                        }
                    }),
                    accountsTransformedManagement: map(action.payload.data, c => {
                        
                        const strategy = getCalendarStrategy(c.strategy)
                        const strategyName = strategy.displayName
                        const _actions = [
                            constants.MENU_ACTION_DELETE,
                        ]

                        return {
                            ...c, 
                            value: c.id, 
                            actions: _actions,
                            display_name: c.name,
                            image_icon: strategy.icon,
                            source_name: strategyName,
                        }
                    }),
                },
            }
        }
        case CALENDAR.SAVE: {

            return {
                ...state,
                loading: false,
                calendars: action.payload.data,
                calendarsTransformed: map(action.payload.data, c => {
                    const channels = {color: 'color-primary-light', name: i18next.t("notices.manage.table.pills.channels", {count: size(c.channels)}), items: map(c.channels, 'name')}
                    const everyone = {color: 'color-secondary-dark', name: i18next.t("messageBuilder.recipients.targetAll")}
                    const strategy = getCalendarStrategy(c.sync_account.strategy)

                    const _actions = []

                    // When sync is in progress for this calendar, we should stop users from spamming the API
                    if (!c.is_syncing && !c.is_default && c.is_sync_automatically) {
                        _actions.push(constants.MENU_ACTION_SYNC)
                    }

                    // Hide actions for `is_default` since we cannot manage the general calendar
                    if (!c.is_default) {
                        _actions.push(constants.MENU_ACTION_MANAGE)
                    } else {
                        _actions.push(constants.MENU_ACTION_VIEW)
                    }

                    let _sync_status = ''
                    
                    if (!c.is_syncing) {
                        _sync_status = c.synced_at ? moment(c.synced_at).format('L LT') : null
                    } else {
                        _sync_status = i18next.t("calendar.detail.label.syncedInProgress")
                    }

                    if (c.sync_account?.auth_required) {
                        _actions.push(constants.MENU_ACTION_REAUTHENTICATE)
                    }

                    return {
                        ...c,
                        actions: _actions,
                        channels: { channels, persons: [], everyone: c.target_everyone ? everyone : null},
                        // disabled: c.is_default,
                        read_only: c.is_default ?? c.is_readonly,
                        image_icon: strategy.icon,
                        status: c.sync_account.auth_required ? 'warning' : 'default',
                        synced_at: {
                            status: c.is_syncing ? calendarConstants.STATUS.SYNCING : calendarConstants.STATUS.SYNCED,
                            text: _sync_status
                        },
                    }
                }),
                pagination: action.payload.pagination
            }
        }
        case CALENDAR.SAVE_CALENDAR_DETAILS: {

            const detail = action.payload

            const strategy = getCalendarStrategy(detail.sync_account.strategy)

            return {
                ...state,
                loading: false,
                detail: {
                    ...state.detail,
                    ...detail,
                    read_only: detail.is_default,
                    is_ical: (strategy.name === calendarConstants.STRATEGIES.ical.name),
                    // loading: false,
                    is_internal: (strategy.name === calendarConstants.STRATEGIES.d6.name),
                    syncStatus: {
                        strategy,
                        id: detail.sync_account.id,
                        syncedAt: detail.synced_at ? moment.utc(detail.synced_at).format('L LT') : null,
                        failedAt: detail.sync_failed_at ? moment.utc(detail.sync_failed_at).fromNow() : null,
                        isSyncing: detail.is_syncing,
                        failedReason: detail.sync_failed_reason,
                        authRequired: detail.sync_account && detail.sync_account.auth_required,
                    },
                    channels: map(detail.channels, 'id')
                },
            }
        }
        case CALENDAR.SAVE_CALENDAR_EVENT_DETAILS: {

            return {
                ...state,
                loading: false,
                detail: {
                    ...state.detail,
                    events: {
                        data: action.payload.data,
                        pagination: action.payload.pagination,
                        dataTransformed: map(action.payload.data, c => {

                            return {
                                id: c.id,
                                name: c.title,
                                start_date: moment(c.start_date).format('DD MMM Y'),
                                start_time: moment(c.start_date).format('h:mm'),
                                is_multiday: c.all_day,
                                is_repeat: c.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                                isRepeating: c.repeat !== calendarConstants.EVENT_REPEAT_OPTIONS.NEVER.key,
                                source: c.source,
                                channels: {
                                    data: c.channels
                                },
                                actions: [
                                    constants.MENU_ACTION_VIEW,
                                    constants.MENU_ACTION_DELETE
                                ],
                                is_locked: c.is_locked,
                                locked_reason: c.locked_reason,
                                _click_meta: {
                                    id: c.id,
                                    title: c.title, 
                                    end: c.end_date,
                                    start: c.start_date,
                                    read_only: c.is_readonly,
                                    extendedProps: {
                                        _calendar_feed_id: c.calendar_feed_id
                                    }
                                }
                            }
                        }),
                    }
                },
            }
        }
        case CALENDAR.SAVE_CALENDAR_SOURCES: {

            return {
                ...state,
                loadingSources: false,
                sources: {
                    ...state.sources,
                    data: action.payload.data,
                    pagination: action.payload.pagination,
                    dataTransformed: map(action.payload.data, r => {
                        const defaultSource = action.payload.strategyId
                        // We need to get the icon image from the sync account that was used to fetch this data
                        const strategyName = defaultSource ?? state?.detail?.sync_account?.strategy ?? state?.sources?.account?.strategy
                        const strategy = getCalendarStrategy(strategyName)

                        return {
                            key: r.id, 
                            value: r.id, 
                            text: "".concat(r.name).concat(" ").concat(r.is_readonly ? i18next.t("calendar.sources.readOnly") : ""),
                            image: {
                                rounded: true,
                                src: strategy.icon
                            },
                        }
                    }),
                },
            }
        }
        case CALENDAR.SAVE_FILTERS: {
            
            return {
                ...state, 
                loading: false, 
                filters: {
                    channels: map(action.payload.channels, r => {
                        const calendars = map(r.calendars, "name")
                        return {
                            key: r.id, 
                            value: r.id, 
                            text: calendars.length > 0 ? i18next.t("channels.alreadyLinked_plural", {count: calendars.length,name: r.name, calendars: calendars.join(",")}) : r.name,
                            disabled: calendars.length > 0
                        }
                    }),
                },
            }
        }
        case CALENDAR.CLEAR_CALENDAR_DETAILS: {
            return {
                ...state,
                loading: false,
                detail: {
                    loading: false,
                },
            }
        }
        default: { }
    }

    return state
}