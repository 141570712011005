import React, { useEffect, useRef } from 'react'

import { Button, Dropdown, Grid, Icon, Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { builderActions } from '../../../actions/builderActions'
import { map } from 'lodash'
import { builderConstants } from '../../../constants'
import { FaCrown } from 'react-icons/fa'
import { Trans } from 'react-i18next'
import { templateInfo } from '../templates/Template'
import CategoriesDropdown from '../../partials/Role'
import { t } from 'i18next'

export default function BaseMorphed({render, item, required, resourceCategory, className, onReset, upgradeRequired, resetDisabled}) {
    
    const dispatch = useDispatch()
    const ref = useRef(null)
    const resourceGroups = useSelector(state => state.builder?.filters?.resourceGroups)
    const canViewResourceCategories = useSelector(state => state.navigation?.community?.can_view_resource_categories)

    const handleOnDuplicate = (data) => {
        dispatch(builderActions.duplicateTemplate(data))
    }

    const handleOnDelete = () => dispatch(builderActions.deleteTemplate(item))

    const handleDataChange = ({item, data}) => {}

    const handleScrollToItem = () => {}
    // const handleScrollToItem = () => ref.current.scrollIntoView({behavior: 'smooth'})

    // Scroll to the item when it gets added into the content-area
    useEffect(() => {

        if (item.isNew) {
            handleScrollToItem()
        }

        return () => {}
    }, [item.id])

    const isValid = () => {
        return item.errors.isValid
    }

    const getErrors = () => {
        return item.errors.messages
    }

    return (
        <div ref={ref} data-test-component-type={item.type} id={'morph-'.concat(item.id)} className={`transformed-content-container ${isValid() === false ? 'has-errors' : ''} ${className || ''}`}>
            <div className="content-container">
                {render({
                    onDataChange: handleDataChange,
                    errors: getErrors()
                })}
            </div>
            <Grid columns="equal" className='icons-container vertical-align'>
                <Grid.Column textAlign='left' className='no-padding'>
                    <div className='flex' style={{padding: '1rem'}}>
                        {(canViewResourceCategories && resourceCategory) && (
                            <CategoriesDropdown
                                role={{
                                    id: resourceCategory.id ?? 'resourceCategoryIds',
                                    name: t("builder.components.morphedTemplates.resourceCategory"),
                                }}
                                showLabel={false}
                                placeholder={t("builder.components.morphedTemplates.resourceCategory.placeholder")}
                                selected={resourceCategory.selected}
                                onChange={resourceCategory.onChange ?? (() => {})}
                                options={resourceGroups ?? []}
                                className="w-full"
                            />
                        )}
                        {required && required({})}
                    </div>
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle" textAlign="center" className='no-padding'>
                    <div className='drag-indicator'>
                        <svg className='material-icons' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#2A7EE2"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                    </div>
                </Grid.Column>
                <Grid.Column textAlign='right' className='no-padding'>
                    <Button.Group icon>
                        {upgradeRequired && (
                            <Button className="button">
                                <Popup
                                    basic
                                    flowing
                                    size="tiny"
                                    className="morphed"
                                    trigger={<FaCrown className="info-icon morphed"/>}
                                    content={
                                        <Trans
                                            as="p"
                                            i18nKey='tiering.message.builder.components.message.upgradeRequired'
                                            values={{
                                                component: upgradeRequired.component
                                            }}
                                            components={{
                                                strong: <strong />
                                            }} />
                                    }
                                />
                            </Button>
                        )}
                        {onReset && (
                            <Button disabled={resetDisabled} className="button" onClick={onReset}>
                                <Icon name="repeat" />
                            </Button>
                        )}
                        {templateInfo[item.type].info && (
                            <Button className="button">
                                <Popup
                                    basic
                                    flowing
                                    size="tiny"
                                    className="morphed"
                                    trigger={<Icon className="info circle info-icon morphed"/>}
                                    content={templateInfo[item.type].info}
                                />
                            </Button>
                        )}
                        <Button className="button" onClick={() => handleOnDuplicate(item)}>
                            <Icon name="copy outline" />
                        </Button>
                        <Button className="button" onClick={handleOnDelete}>
                            <Icon name="trash" />
                        </Button>
                    </Button.Group>
                </Grid.Column>
            </Grid>
        </div>
    )
}
