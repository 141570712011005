import i18next from "i18next"
import { initReactI18next } from "react-i18next"

import en from "./en.json"
import af from "./af.json"
import ar from "./ar.json"
import es from "./es.json"
import fr from "./fr.json"

import enEditor from "./editor/en"
import esEditor from "./editor/es"

import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import moment from "moment"
import 'moment/min/locales.min'
import dateEs from 'date-fns/locale/es'
import dateAf from 'date-fns/locale/af'
import dateAr from 'date-fns/locale/ar'
import dateFr from 'date-fns/locale/fr'

import Config from "../config"
import { registerLocale, setDefaultLocale } from "react-datepicker"

const defaultLocale = 'en'
const locale = localStorage.getItem("i18nextLng")

// Register the locales
registerLocale('ar', dateAr)
registerLocale('af', dateAf)
registerLocale('es', dateEs)
registerLocale('fr', dateFr)

moment.locale(locale || defaultLocale)
setDefaultLocale(locale || defaultLocale)

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3', //? NOTE: Keeping old v3 version, helps with Pluralization because the alternative would be to rename all available keys 
    resources: {
      "af": {
        translations: af,
        editor: enEditor
      },
      ar: {
        translations: ar,
        editor: enEditor
      },
      en: {
        translations: en,
        editor: enEditor
      },
      "es": {
        translations: es,
        editor: esEditor
      },
      "fr": {
        translations: fr,
        editor: enEditor
      }
    },
    // lng: locale || "en",
    fallbackLng: (code) => {
      
      const fallbacks = []
      // Only use the pure base language when we find variants.
      const langPart = code.split('-')[0];
      if (langPart !== code) {
        fallbacks.push(langPart)

      }
      
      if (langPart !== defaultLocale) {
        fallbacks.push('en')
      }

      return fallbacks
    },
    debug: !Config.isProd,

    // Common namespace being used
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase()
        if(value instanceof Date) return moment(value).format(format)
        return value
      }
    }
  })

i18next.format = (key) => {
  return i18next.t("utils.format", {text: i18next.t(key)})
}

export default i18next