import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import routes from '../routes/route-constants'
import { Button, Grid, Icon, Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { appendCommunity } from '../utils/helper'
import logoAnim from '../images/d6_logo.riv'
import { authActions } from '../actions/authActions'
import LogoTeal from './partials/LogoTeal'

const NotAllowed = () => {

  const navigation = useSelector(state => state.navigation)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // onMount/onUpdate
  useEffect(() => {
    // Add loading class to add image background
    document.body.classList.add('loading')

    return () => {
      document.body.classList.remove('loading')
    }

  }, [])

  const handleNavigateHome = () => {

    // HARD Refresh
    window.location.href = appendCommunity(
      navigation.communityId,
      routes.private.HOME_COMM
    )
  }

  return (
    <div className='ui middle aligned center aligned grid no-auth full-ui-block data-init-screen'>
      <div className="loader-container">
        <Loader
          inverted
          active
          content={
            <Fragment>
              <LogoTeal />
              <div className='error-explanation'>
                <p className='error-message'>{t("error.notAllowedError")}</p>
                <Grid className='mg-top-10'>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <Button className='primary' onClick={handleNavigateHome}>{t('general.takeMeHome')}</Button>
                      <Button className='primary' onClick={() => dispatch(authActions.doLogout())}>{t('general.logout')}</Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Fragment>
          } />
      </div>
    </div>
  )

}

export default NotAllowed