import Config from "../config"
import { UserManager, WebStorageStateStore } from "oidc-client"

class AuthService {
    
    constructor() {

        const settings = {
            scope: Config.ssoAuthScope,
            authority: Config.ssoAuthUrl,
            client_id: Config.ssoClientID,
            response_type: Config.ssoResponseType,
            post_logout_redirect_uri: `${Config.ssoClientRoot}/signout-callback-oidc`,
            redirect_uri: `${Config.ssoClientRoot}/signin-oidc`,
            silent_redirect_uri: `${Config.ssoClientRoot}/silent-renew.html`,
            automaticSilentRenew: true,
            checkSessionInterval: 1000,
            silentRequestTimeout: 10000,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
        }

        this.userManager = new UserManager(settings)

        /**
         * We need to get the authenticated session in a synchronous manner, for a more fluid flow.
         * So we borrowed this functionality from OIDC UserManager
         *
         * @see {@link UserManager:L612}
         */
        this.originalUserStoreKey = `oidc.user:${settings.authority}:${settings.client_id}`
        this.userStoreKey = `session.${this.originalUserStoreKey}`
    }

    /**
     * Base URL for the Identity server
     *
     * @returns {String}
     */
    ssoBaseUrl = () => "https://id.zipalong.tech/api"
    
    /**
     * Get the instance of the UserManager
     * 
     * @returns {UserManager}
     */
    getManager = () => this.userManager

    /**
     * Get Authenticated User
     * 
     * @returns {Promise}
     */
    getUser = () => this.userManager.getUser()

    /**
     * Login User
     * 
     * @returns {Promise}
     */
    login = () => this.userManager.signinRedirect()
    
    /**
     * Renew Token
     * 
     * @returns {Promise}
     */
    renewToken = () => this.userManager.signinSilent()

    /**
     * Logout Authenticate User
     * 
     * @returns {Promise}
     */
    logout = () => this.userManager.signoutRedirect()
    
    /**
     * Cleanup Manager
     * 
     * @returns {Promise}
     */
    completeLogout = () => {
        this.userManager.signoutRedirectCallback()
            .then(_ => {
                this.userManager.removeUser()
                this.userManager.clearStaleState()
            })
            .catch(e => {
                console.error(e)
            })
    }
}

export default AuthService