import { actions, builderConstants, constants, messageBuilder } from '../constants'
import { size, map, find, merge, isNull, mapValues, mapKeys, orderBy, flatMap, flatten } from 'lodash'
import moment from 'moment'
import { formatMobileNumber, toDropDownOption, transformToRecipients } from '../utils/helper'
import i18next from 'i18next'
import { chartColors, colorPalette } from '../constants/initial-data'
import { v4 } from 'uuid'
import Color from 'color'

const { NOTICE } = actions

const initialState = {
    loading: false,
    notices: [],
    filters: {},
    noticeDetails: null,
    pagination: [],
    errors: null,
    messages: null,
}

const _getGraphMeta = (type, gType = null) => {
    switch (type) {
        case 'primary_graphs': {
            return {
                class_names: ["summary-chart"],
                icon: "credit card outline",
            }
        }
        case 'vote_graphs':
        case 'file_graphs':
        case 'youtube_graphs':
        case 'payment_graphs':
        case 'response_graphs': {

            const pred = [
                'value_with_title',
                'pie_with_alternate_title',
                'value_with_alternate_title'
            ].includes(gType)

            if (pred) {
                return {
                    class_names: ["form-chart"],
                }
            }

            return {
                class_names: ["summary-chart"],
                icon: "credit card outline",
            }
        }
        default: {
            return {}
        }
    }
}

// Function to generate new colors and add them to the palette
const generateNewColors = (numColorsToAdd) => {
    const colors = {
        azure: {
            200: "#0d325f",
            300: "#134b8f",
            400: "#1a64be",
            500: "#2a7ee2",
            600: "#5698e8",
            700: "#80b2ee",
            800: "#abcbf3",
        },
        orange_peel: {
            200: "#663d00",
            300: "#995c00",
            400: "#cc7a00",
            500: "#ff9800",
            600: "#ffad33",
            700: "#ffc266",
            800: "#ffd699",
        },
        yellow_green: {
            200: "#45561c",
            300: "#67812a",
            400: "#8aad38",
            500: "#a7c957",
            600: "#b8d377",
            700: "#c9de99",
            800: "#dbe9bb",
        },
        robin_egg_blue: {
            200: "#00514c",
            300: "#017a72",
            400: "#01a298",
            500: "#01c9bc",
            600: "#0bfeee",
            700: "#48fef2",
            800: "#85fef6",
        },
        air_superiority_blue: {
            200: "#2b3e47",
            300: "#415d6a",
            400: "#577c8e",
            500: "#759aab",
            600: "#90adbb",
            700: "#acc2cc",
            800: "#c7d6dd",
        },
        vanilla: {
            200: "#9d9108",
            300: "#ebd90c",
            400: "#f6e954",
            500: "#faf2a1",
            600: "#fbf5b5",
            700: "#fcf8c8",
            800: "#fdfada",
        },
    }

    const all = flatten(map(colors, c => map(c)))

    if (numColorsToAdd > size(all)) {
        const remaining = numColorsToAdd - size(all)
        const newPaint = Color('green') // @NOTE: We this color might seem out of place, but it will only show as last resort if we run out of out destined colors
        const merged = generateMoreColors(remaining, newPaint, all)
        return all.concat(merged)
    }

    return all
}

/**
 * Generate {numColorsToAdd} colors for a given {baseColor} by mixing it with the given {colors}
 * 
 * @param {Number} numColorsToAdd 
 * @param {Color} baseColor 
 * @param {string[]} colors
 * @returns {string[]}
 */
const generateMoreColors = (numColorsToAdd, baseColor, colors) => {
    const newColors = [];
    for (let i = 0; i < numColorsToAdd; i++) {
        const ratio = i * (1 / numColorsToAdd)
        const existingColor = Color(colors[i])
        const newColor = baseColor.mix(existingColor).rotate(ratio)
        newColors.push(newColor.hex())
    }
    return newColors
}

const _transformGraphData = (graphs) => {

    const colors = map(colorPalette)

    const transformedData = map(graphs, (g, key) => {

        return {
            key,
            graphs: map(g, graph => {
                const newColors = []
                const predicate = size(graph.slices) > size(colors)
                if (predicate) {
                    const remainColors = generateNewColors(size(graph.slices))
                    newColors.push(...remainColors)
                } else {
                    newColors.push(...colors)
                }

                const data = {
                    label: graph.subtitle,
                    type: graph.type,
                    datasets: map(graph.slices, (slice, i) => {
                        return {
                            label: slice.title,
                            displayValue: slice.title,
                            percentage: slice.count,
                            color: newColors[i]
                        }
                    }),
                    meta: _getGraphMeta(key, graph.type),
                    votes: graph.votes,
                    notice_id: graphs.notice_id,
                    showLegend: graph.show_legend,
                    description: graph.description
                }

                switch (graph.type) {
                    case 'pie_with_title': {
                        data['title'] = graph.title.toUpperCase()
                        break;
                    }
                    case 'value_with_title':
                    case 'value_with_alternate_title': {
                        data['label'] = graph.subtitle
                        data['title'] = graph.title
                        data['data'] = {
                            label: graph.value_subtitle,
                            displayValue: graph.value,
                            percentage: graph.value,
                            color: '#00acac',
                            legend: graph.legend_subtitle
                        }
                        break;
                    }
                    case 'pie_with_alternate_title': {
                        data['label'] = graph.subtitle
                        data['title'] = graph.title
                        data['showExpandedLegend'] = true
                        break;
                    }
                    default: {
                        data['title'] = graph.title
                    }
                }

                if (graph.legend_subtitle) {
                    data['totals'] = {
                        label: graph.legend_subtitle.header,
                        displayValue: graph.legend_subtitle.value,
                    }
                }
                
                if (graph.secondary_legend_subtitle) {
                    data['secondaryTotals'] = {
                        label: graph.secondary_legend_subtitle.header,
                        displayValue: graph.secondary_legend_subtitle.value,
                    }
                }

                return data
            })
        }
    })

    return mapKeys(transformedData, g => g.key)
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case NOTICE.RESET: {
            return {
                ...state,
                loading: false,
                pagination: [],
                notices: [],
                filters: {},
                errors: null,
                messages: null,
                noticeDetails: null
            }
        }
        case NOTICE.START: {
            return { ...state, loading: true }
        }
        case NOTICE.START_RECIPIENT_LOAD: {
            return {
                ...state,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: true
                }
            }
        }
        case NOTICE.START_GRAPHS_LOAD: {
            return {
                ...state,
                noticeDetails: {
                    ...state.noticeDetails,
                    loadingGraphs: true
                }
            }
        }
        case NOTICE.CLEAR_MESSAGES: {
            return { ...state, messages: null, errors: null }
        }
        case NOTICE.ERROR: {
            let error = action.payload.response || {}

            error = error.data ? error.data.message : "error.errorOccurred"

            return {
                ...state,
                loading: false,
                errors: error,
                messages: null,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: false,
                    loadingGraphs: false,
                }
            }
        }
        case NOTICE.SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                messages: action.payload,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: false
                }
            }
        }
        case NOTICE.SAVE: {

            return {
                ...state,
                loading: false,
                notices: action.payload.data,
                noticesTransformed: map(action.payload.data, c => {

                    const channels = { color: 'color-primary-light', name: i18next.t("notices.manage.table.pills.channels", { count: size(c.channels) }), items: c.channels }
                    const persons = { color: 'color-primary-light', name: i18next.t("notices.manage.table.pills.recipients", { count: size(c.persons) }), items: c.persons }
                    const everyone = { color: 'color-secondary-dark', name: i18next.t("messageBuilder.recipients.targetAll") }

                    const statusAt = c.status_at

                    const _actions = []

                    // For [Archived] messages there should not be any actions for [Edit, Delete]
                    if (![
                        messageBuilder.STATUS.ARCHIVED,
                        messageBuilder.STATUS.PUBLISHING,
                    ].includes(c.status)
                    ) {
                        _actions.push(constants.MENU_ACTION_VIEW)
                    }

                    // We should not have access to editing, while publishing or once published
                    if (![
                        messageBuilder.STATUS.ARCHIVED,
                        messageBuilder.STATUS.PUBLISHING,
                        messageBuilder.STATUS.PUBLISHED].includes(c.status)
                    ) {
                        _actions.push(constants.MENU_ACTION_DELETE)
                        _actions.push(constants.MENU_ACTION_EDIT)
                    }

                    return {
                        ...c,
                        status_at: moment(statusAt).format("L LT"),
                        attention_required: (c.status === 'draft'),
                        channels: { channels, persons, everyone: c.target_everyone ? everyone : null },
                        actions: _actions
                    }
                }),
                pagination: action.payload.pagination
            }
        }
        case NOTICE.SAVE_NOTICE_GRAPH_DETAILS: {
            return {
                ...state,
                loading: false,
                noticeDetails: {
                    ...state.noticeDetails,
                    graphs: _transformGraphData({
                        ...action.payload,
                        notice_id: state.noticeDetails?.id ?? null
                    }),
                    loadingGraphs: false,
                },
            }
        }
        case NOTICE.SAVE_NOTICE_DETAILS: {

            let tableHeaders = {
                0: {
                    name: constants.TABLE_KEY_CHECKBOX,
                    key: {
                        name: constants.TABLE_KEY_CHECKBOX,
                        type: constants.TABLE_KEY_CHECKBOX
                    }
                },
                1: {
                    name: i18next.t("notices.detail.table.headers.firstName"),
                    sortable: true,
                    key: {
                        sub: "subtitle",
                        name: "first_name"
                    }
                },
                2: {
                    name: i18next.t("notices.detail.table.headers.lastName"),
                    sortable: true,
                    key: {
                        name: "last_name"
                    }
                },
                3: {
                    name: i18next.t("notices.detail.table.headers.read"),
                    key: {
                        name: "reads",
                        type: constants.TABLE_KEY_RECIPIENT_TAGS
                    }
                },
                4: {
                    name: i18next.t("notices.detail.table.headers.answered"),
                    key: {
                        name: "answers",
                        type: constants.TABLE_KEY_RECIPIENT_TAGS
                    }
                },
                5: {
                    name: i18next.t("notices.detail.table.headers.paid"),
                    key: {
                        name: "payments",
                        type: constants.TABLE_KEY_RECIPIENT_TAGS
                    }
                },
                // 6: {
                //     name: i18next.t("notices.detail.table.headers.reminded"),
                //     key: {
                //         name: "reminded_at",
                //     }
                // },
                7: {
                    name: i18next.t("notices.detail.table.headers.status"),
                    key: {
                        name: "distribution_status",
                        classNames: 'distribution-status',
                        type: constants.TABLE_KEY_DISTRIBUTION
                    }
                },
                9: {
                    name: "",
                    key: {
                        classNames: 'actions',
                        name: constants.TABLE_KEY_ACTIONS,
                        type: constants.TABLE_KEY_ACTIONS
                    }
                }
            }

            const noticeDetails = action.payload

            if (!noticeDetails.payable) {
                tableHeaders[5] = null
            }

            if (!noticeDetails.answerable && !noticeDetails.votable) {
                tableHeaders[4] = null
            }

            tableHeaders = map(tableHeaders).filter(x => !isNull(x))

            return {
                ...state,
                loading: false,
                noticeDetails: {
                    ...state.noticeDetails,
                    ...noticeDetails,
                    published_at: (noticeDetails.status === "published") ? moment(noticeDetails.status_at).fromNow() : null,
                    headers: tableHeaders,
                    loading: false,
                    channels: action.payload.channels//map(action.payload.channels, 'id'),
                },
            }
        }
        case NOTICE.SAVE_NOTICE_RECIPIENT_DETAILS: {

            const recipient = action.payload

            return {
                ...state,
                loading: false,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: false,
                    recipient,
                },
            }
        }
        case NOTICE.SAVE_NOTICE_MANUAL_PAYMENTS: {

            const payments = action.payload

            const components = map(payments.components ?? [], p => {
                return {
                    id: p.id,
                    type: 'payment',
                    attributes: {
                        ...p,
                        currency: payments.currency_iso_4217,
                    },
                    created_at: moment().toISOString(),
                    updated_at: moment().toISOString(),
                }
            })

            return {
                ...state,
                loading: false,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: false,
                    payments: {
                        ...payments,
                        components,
                    },
                },
            }
        }
        case NOTICE.SAVE_NOTICE_DETAILS_RECIPIENTS: {

            const interactions = [
                {
                    name: i18next.t('noticesReducer.interactions.read'),
                    value: constants.INTERACTIONS.READ,
                },
                {
                    name: i18next.t('noticesReducer.interactions.unread'),
                    value: constants.INTERACTIONS.UNREAD,
                },
                {
                    name: i18next.t('noticesReducer.interactions.answered'),
                    value: constants.INTERACTIONS.ANSWERED,
                },
                {
                    name: i18next.t('noticesReducer.interactions.not-answered'),
                    value: constants.INTERACTIONS.NOT_ANSWERED,
                },
                {
                    name: i18next.t('noticesReducer.interactions.paid'),
                    value: constants.INTERACTIONS.PAID,
                },
                {
                    name: i18next.t('noticesReducer.interactions.not-paid'),
                    value: constants.INTERACTIONS.NOT_PAID,
                },
                {
                    name: i18next.t('noticesReducer.interactions.reminded'),
                    value: constants.INTERACTIONS.REMINDED,
                },
                {
                    name: i18next.t('noticesReducer.interactions.not-reminded'),
                    value: constants.INTERACTIONS.NOT_REMINDED,
                }
            ]

            const relationships = [
                {
                    name: i18next.t('noticesReducer.relationships.root-only'),
                    value: constants.RECIPIENT_GROUP_BYS.GROUP_BY_ROOT_ONLY,
                },
                {
                    name: i18next.t('noticesReducer.relationships.children-only'),
                    value: constants.RECIPIENT_GROUP_BYS.GROUP_BY_CHILDREN_ONLY,
                },
                {
                    name: i18next.t('noticesReducer.relationships.parent-only'),
                    value: constants.RECIPIENT_GROUP_BYS.GROUP_BY_PARENTS_ONLY,
                },
                {
                    name: i18next.t('noticesReducer.relationships.child-parent'),
                    value: constants.RECIPIENT_GROUP_BYS.GROUP_BY_CHILD_PARENT,
                },
                {
                    name: i18next.t('noticesReducer.relationships.parent-child'),
                    value: constants.RECIPIENT_GROUP_BYS.GROUP_BY_PARENT_CHILD,
                },
            ]

            let filters = state.noticeDetails?.filters ?? {}

            const _interactions = map(interactions, r => { return toDropDownOption(r) })
            const _relationships = map(relationships, r => { return toDropDownOption(r) })

            filters = {
                ...filters,
                interactions: _interactions,
                relationships: _relationships,
            }

            // We need to display the users in a way that shows a nesting of rows depending on how many items are found int the {response.items} key of the recipients
            const users = transformToRecipients(action.payload.data).map((user) => {
                const parentId = v4()
                // We know from here that the items int the nested {user.items} are basically a list of recipients, we should pluck these out and tag them as the nests under the current {user}
                const nested = transformToRecipients(user.items).map((child, index) => {
                    return {
                        ...child,
                        type: 'child'.concat((index + 1) === size(user.items) ? ' last' : ''), // We need to add an indicator that the nesting/group should terminate here
                        parent: parentId,
                    }
                })
                return [{
                    ...user,
                    type: (size(user.items) > 0) ? 'main' : '',
                    parent: parentId,
                }].concat(nested)
            })

            return {
                ...state,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: false,
                    pagination: action.payload.pagination,
                    users: flatMap(users),
                    filters: filters,
                },
            }
        }
        case NOTICE.SAVE_FILTERS: {
            return {
                ...state,
                loading: false,
                filters: {
                    channels: map(action.payload.channels, r => { return { key: r.id, value: r.id, text: r.name } }),
                    statuses: map(action.payload.statuses, r => { return { key: r.status, value: r.status, text: r.display_name } }),
                },
            }
        }
        case NOTICE.SAVE_NOTICE_DETAILS_RECIPIENTS_FILTERS: {

            const filters = state.noticeDetails?.filters ?? {}

            return {
                ...state,
                noticeDetails: {
                    ...state.noticeDetails,
                    loading: false,
                    filters: {
                        ...filters,
                        tags: map(action.payload.tags, r => { return { key: r.id, value: r.id, text: r.name } }),
                        channels: map(action.payload.channels, r => { return { key: r.id, value: r.id, text: r.name } }),
                        recipient: {
                            targetted_channels: map(action.payload.notice_channels, r => { return toDropDownOption(r) }),
                            all_channels: map(action.payload.community_channels, r => { return toDropDownOption(r) }),
                        }
                    },
                }
            }
        }
        case NOTICE.CLEAR_NOTICE_DETAILS: {
            return {
                ...state,
                loading: false,
                noticeDetails: null,
            }
        }
        default: { }
    }

    return state
}