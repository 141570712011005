import { pickBy, identity, isEmpty, isNil } from "lodash"
import { dropEmptyKeys, filterNulls } from "../../utils/helper"

class ListMembersRequest {

    constructor({
        sort, 
        page,
        search,
        tagIds,
        perPage,
        hasApp,
        channelIds,
        personIds,
        isFollower,
        communityId,
        shouldNotify,
    }) {
        this.page = page
        this.sort = sort
        this.tagIds = tagIds
        this.hasApp = hasApp
        this.search = search
        this.perPage = perPage
        this.personIds = personIds
        this.isFollower = isFollower
        this.channelIds = channelIds
        this.communityId = communityId
        this.shouldNotify = shouldNotify
    }


    getPayload = () => {
        
        const payload = {
            "page": this.page,
            "sort": this.sort,
            "search": this.search,
            "per_page": this.perPage.value,
            "community_id": this.communityId,
            "tag_ids": [].concat(this.tagIds).join(","),
            "channel_ids": [].concat(this.channelIds).join(","),
        }

        if (!isNil(this.shouldNotify)) {
            payload["notify_subscription_changes_via_app"] = this.shouldNotify
        }

        if (!isNil(this.personIds)) {
            payload["person_ids"] = this.personIds
        }
        
        if (this.hasApp !== -1 && !isNil(this.hasApp)) {
            payload["has_app"] = this.hasApp
        }
        
        if (this.isFollower !== -1 && !isNil(this.isFollower)) {
            payload["is_public_follower"] = this.isFollower
        }

        return dropEmptyKeys(payload)
    }
    
}

export default ListMembersRequest