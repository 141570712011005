import React from 'react'
import logo from '../../images/d6_logo_teal.svg'

export default function LogoTeal() {
  return (
    <div className='loader-image'>
        <img src={ logo } alt="" className="image" />
    </div>
  )
}
