import { isFunction } from 'lodash'
import React, { Fragment } from 'react'
import { Grid, Icon } from 'semantic-ui-react'

const BaseIconCard = ({ icon, content }) => {
    
    const renderContent = () => {
        if (isFunction(content)) {
            return content()
        }

        return content
    }
    
    return (
        <Grid columns={icon ? 2 : 'equal'} stretched className="vertical-align no-padding no-margin">
            {icon && (
                <Grid.Column width={2} className="no-padding no-margin">
                    <div className="icon-container">
                        <Icon name={icon} />
                    </div>
                </Grid.Column>
            )}
            <Grid.Column width={icon ? 14 : 16} className="content-container">
                {renderContent()}
            </Grid.Column>
        </Grid>
    )
}

export default BaseIconCard
