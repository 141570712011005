import React, { Fragment } from 'react'
import BaseIconCard from './BaseIconCard'
import BaseTemplate from './BaseTemplate'

const Contact = ({ item }) => {
    return (
        <BaseTemplate classNames="no-padding no-auto-height icon-card-container" render={() => (
            <BaseIconCard
                icon="phone square"
                content={() => (
                    <Fragment>
                        <strong className="no-margin contact-name">{`${item.data.firstName || ""} ${item.data.lastName || ""}`}</strong>
                        <p className="no-margin contact-job-title">{ item.data.jobTitle }</p>
                        {item.data.email && <p className="no-margin contact-email">{ item.data.email }</p>}
                        {item.data.phoneNumber && <p className="no-margin contact-number">{ item.data.phoneNumber }</p>}
                    </Fragment>
                )} />
        )} />
    )
}

export default Contact
