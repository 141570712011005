import React, { useEffect, useState } from "react"
import { Icon, Message } from "semantic-ui-react"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import ConfirmModal from "../partials/ConfirmModal"
import { tieringActions } from "../../actions/tieringActions"
import { goBack, push } from "connected-react-router"

const LimitIndicator = (props) => {

    const {
        backUrl,
        showAsModal,
        headerI18nKey,
        messageI18nKey,
        currentPageDescription,
        tieringManagementLimitsOverrides,
    } = props


    const {t} = useTranslation()
    const dispatch = useDispatch()

    const tieringManagementLimits = tieringManagementLimitsOverrides ?? useSelector(state => state.tiering?.limits)

    const {
        usage,
        limit,
        visible,
        show_limit_warning,
        show_limit_reached,
    } = tieringManagementLimits ?? {}
    
    const [open, setOpen] = useState(false)

    const _closeModal = () => {
        setOpen(false)
        dispatch(backUrl ? push(backUrl) : goBack())
    }
    
    const _handleConfirm = () => {
        _closeModal()
        // setOpen(false)
        dispatch(tieringActions.modal(true))
    }

    // @TODO: Allow users to dismiss these messages, for their convenience.
    const _handleDismiss = () => {
        dispatch(tieringActions.reset())
    }

    useEffect(() => {
      
        if (!tieringManagementLimits) return;
        
        setOpen(visible && showAsModal && (show_limit_reached ?? false))

    }, [tieringManagementLimits])
    
    if (!tieringManagementLimits) {
        return null
    }
    
    if (!visible) {
        return null
    }
    
    if (limit === null) {
        return null
    }

    const _renderIndicator = () => {
        
        if (show_limit_reached) {
            return (
                <Message negative>
                    <Trans
                        as="p"
                        i18nKey={messageI18nKey}
                        values={{
                            max: limit
                        }}
                        components={{
                            strong: <strong />
                        }} />
                </Message>
            )
        }

        if (show_limit_warning) {
            return (
                <p className="description"><Icon name="group" className="_warning" />{usage} {t("pager.descriptor.of")} {limit} {currentPageDescription}</p>
            )
        }
        
        return (
            <p className="description"><Icon name="group" className="healthy" />{usage} {t("pager.descriptor.of")} {limit} {(usage > 0) ? currentPageDescription : null}</p>
        )
    }

    return (
        <div className="indicators">
            {_renderIndicator()}
            
            <ConfirmModal
                modalId="limit-indicator-modal"
                dismissible={false}
                header={t(headerI18nKey)}
                open={open}
                onClose={_closeModal}
                message={(
                    <Trans
                        i18nKey={messageI18nKey}
                        values={{
                            max: limit
                        }}
                        components={{
                            strong: <strong />
                        }} />
                )}
                onConfirm={_handleConfirm}
                showConfirm
                confirmText={t('tiering.modal.actions.confirm')}
                cancelText={t('modal.cancelText')} />
        </div>
    )
}

export const LimitIndicatorModalOnly = (props) => {
    
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const community = useSelector(state => state.navigation?.community)
    const tieringManagementModal = useSelector(state => state.tiering?.modal)

    const {
        show,
        header,
        message,
        component,
    } = tieringManagementModal ?? {}

    if (!show) {
        return null
    }

    const _closeModal = () => {
       dispatch(tieringActions.modalMini({
            show: false
        }))
    }
    
    const _handleConfirm = () => {
        _closeModal()
        dispatch(tieringActions.modal(true))
    }

    return (
        <ConfirmModal
            modalId="limit-indicator-modal"
            dismissible={false}
            header={t(header)}
            open={show}
            onClose={_closeModal}
            message={(
                <Trans
                    i18nKey={message}
                    values={{
                        component: component
                    }}
                    components={{
                        strong: <strong />
                    }} />
            )}
            onConfirm={_handleConfirm}
            showConfirm={community?.tiering_show_upgrade}
            confirmText={t('tiering.modal.actions.confirm')}
            cancelText={t('modal.cancelText')} />
    )
}

export const LimitIndicatorMini = (props) => {
    
    const {
        limit,
        status,
        visible,
        className = "",
        messageI18nKey,
    } = props ?? {}

    if (!visible) {
        return null
    }

    return (
        <Message {...status} className={className}>
            <Trans
                as="p"
                i18nKey={messageI18nKey}
                values={{
                    max: limit
                }}
                components={{
                    strong: <strong />
                }} />
        </Message>
    )
}

const _getContext = ({memberVisible, channelVisible}) => {
        
    // 1. When both limits are reached, we will give the Localisation context to be "_both"
    if (memberVisible && channelVisible) {
        return 'both'
    }
    
    // 2. When either one of the limits are reached, we show default Localisation
    if (memberVisible) {
        return 'base' // "base" context does not exist, so the default base locale will be displayed
    }
    
    // 3. If it happens that members limits are not reached but channels are, we give the context to be "_channels"
    if (channelVisible) {
        return 'channels'
    }
}

export const LimitIndicatorImports = (props) => {
    
    const {
        status,
        memberLimit,
        channelLimit,
        memberVisible,
        channelVisible,
        className = "",
    } = props ?? {}

    if (!memberVisible && !channelVisible) {
        return null
    }

    return (
        <Message {...status} className={className}>
            <Trans
                as="p"
                i18nKey="tiering.message.members.imports.message.warningOrLimitReached"
                context={_getContext({memberVisible, channelVisible})}
                values={{
                    max: memberLimit,
                    maxChannels: channelLimit,
                }}
                components={{
                    strong: <strong />
                }} />
        </Message>
    )
}

export default LimitIndicator