import React from 'react'
import { matchPath } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import routes from '../../routes/route-constants'
import { Fragment } from 'react'
import Messages from './Messages'
import { authActions } from '../../actions/authActions'
import UpgradeModal from '../tiering/UpgradeModal'
import { LimitIndicatorModalOnly } from '../tiering/LimitIndicator'

const Main = (props) => {
  
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const router = useSelector(state => state.router)
  const navigation = useSelector(state => state.navigation)

  const determineActiveLink = (path, patterns, exact, strict) => {
    
    const match = matchPath(path, {
      path: patterns,
      exact: exact,
      strict: strict
    })

    return match ? true : false
  }

  return (
    <main 
      className={`${determineActiveLink(
        router.location.pathname, 
        [routes.private.CREATE_NOTICE], 
        true, true) ? 'message-builder' : ''}`}>

      {/* Show Auth Messages Here */}
      <Fragment>
        { auth.errors && (
          <Messages 
            errorHeader={auth.errors.header}
            errors={auth.errors.message || auth.errors} />
        )}
      </Fragment>

      {/* Show Upgrade Modal */}
      <UpgradeModal />
      <LimitIndicatorModalOnly />

      <div className="default-content-area">
        {props.children}
      </div>

      {/* Add  */}
      <footer>
       {/* Footer */}
      </footer>
    </main>
  )
}

export default Main
