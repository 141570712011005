import React from 'react'
import { List, Icon, Grid, ImageGroup } from 'semantic-ui-react'
import BaseTemplate from './BaseTemplate'

import { Fragment } from 'react'
import { chunk, difference, first, map, size, take } from 'lodash'
import { messageBuilder, templateIcons } from '../../../../constants'
import { getAlbumConfig } from '../../../../utils/helper'
import { useMemo } from 'react'

const AlbumView = (props) => {

    const {} = props
    
    const images = map(props.images)

    const renderImage = (image) => {
        
        if (!image.file) return ""        
        if (image.file.url) return image.file.url

        // For this last option, the image was recently decoded and gotten from a Blob, so we will preview using the base64 data
        return image.thumb
    }

    // We will use a memoized function to avoid meaningless re-rendering
    const settings = useMemo(() => {
        return getAlbumConfig(images)
    }, [images])
    
    const MoreView = ({images, config}) => {

        // We will grab {minTake} items from the list so we can display them first
        let initial = take(images, config.main)

        // The remaining items will be displayed as smaller thumbnails
        let remaining = difference(images, initial)
        
        // We will again take {config.thumbs} items from the list so we can display these as thumbnails, 
        // but also keep count of the ones that are no longer in preview as {+Leftover}
        let remainingSliced = take(remaining, config.thumbs)

        return (
            <Grid.Row columns={"equal"} stretched>
                 
                 {map(initial, (image, k) => (
                    <Grid.Column key={k}>
                        <div className="image-component preview" data-src={renderImage(image)}  style={{backgroundImage: `url("${renderImage(image)}")`}} />
                    </Grid.Column>
                ))}
                
                {(size(remaining) > 0) && (
                    <Grid.Column className='small-thumbs' width={config.thumbWidth}>
                        
                        {map(chunk(remainingSliced, config.thumbChunks), (chunk, ck) => (
                            <div key={ck} className='chunk-container'>
                                {map(chunk, (image, k) => (
                                    <div key={k} data-src={renderImage(image)} className={`ui image image-component preview ${(size(remaining) > config.thumbs) && (ck == 1) && k + 1 == size(chunk) ? 'more-count' : ''}`} style={{backgroundImage: `url("${renderImage(image)}")`}}>
                                        {((size(remaining) > config.thumbs) && (ck == 1) && k + 1 == size(chunk)) && (
                                            <small><strong>+{size(remaining) - config.thumbs}</strong></small>
                                        )}
                                    </div>
                                ))}
                                
                                {(size(chunk) !== config.thumbChunks) && (
                                    <div className="ui image image-component preview empty" />
                                )}
                            </div>
                        ))}
                        
                    </Grid.Column>
                )}
            </Grid.Row>
            
        )
    }
    
    return (
        <Grid className='album-component'>
            <MoreView config={settings} images={images} />
        </Grid>
    )
}

const GridView = (props) => {

    const {} = props
    
    const images = map(props.images)

    const renderImage = (image) => {
        
        if (!image.file) return ""        
        if (image.file.url) return image.file.url

        // For this last option, the image was recently decoded and gotten from a Blob, so we will preview using the base64 data
        return image.thumb
    }

    return (
        <Grid celled='internally' className='image-groups' columns={2}>
            <Grid.Row columns="equal">
                {map(images, (image, k) => (
                    <Grid.Column key={k} width={8}>
                        <div className="image-component preview" style={{backgroundImage: `url("${renderImage(image)}")`}} />
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    )
}

const Image = ({ item }) => {
    
    const {
        files,
        _type,
    } = item.data

    if (_type === templateIcons.image) {
        return (
            <BaseTemplate classNames={'image-groups'} render={() => (
                <Fragment>
                    {size(files) > 0 ? (
                        <List.Content>
                            <GridView images={files} />
                        </List.Content>
                    ) : (
                        <List.Content className="image-placeholder">
                            <Icon name="image" />
                        </List.Content>
                    )}
                </Fragment>
            )} />
        )
    }
    
    return (
        <BaseTemplate render={() => (
            <Fragment>
                {size(files) > 0 ? (
                    <List.Content>
                        {((item.data?.albumTitle && size(files) > messageBuilder.NUM_IMAGES_TO_ALBUM) || item?.data?.originalId !== undefined) && (<h4>{item.data?.albumTitle}</h4>)}
                        <AlbumView images={files} />
                    </List.Content>
                ) : (
                    <List.Content className="image-placeholder">
                        <Icon name="image" />
                    </List.Content>
                )}
            </Fragment>
        )} />
    )
}

export default Image
