import { map, size } from 'lodash'
import React, { Fragment } from 'react'
import { Grid, Icon, List } from 'semantic-ui-react'
import AdaptiveIcon from '../../../partials/timeline/AdaptiveIcon'
import BaseIconCard from './BaseIconCard'
import BaseTemplate from './BaseTemplate'

export const renderSizeInMB = (file) => {
    const mbVal = (file?.fileSize ?? 0) / 1000000
    return mbVal.toFixed(4) + " MB"
}

export const renderName = (file) => {
    if (!file) return ""
    if (file?.fileName) return file?.fileName
}

const Attachment = ({ item }) => {
    
    // TODO: Files have the potential to be multiple selection in the future, we are handling that, but for now wil always pick the first
    const files = map(item?.data?.files ?? [])

    return (
        <Grid role="preview-list-item">
            <Grid.Row columns={size(files) > 1 ? 2 : 'equal'} centered={size(files) === 1}>
                {map(files, (file, k) => (
                    <Grid.Column key={k} width={8}>
                        <BaseTemplate classNames="no-padding no-auto-height icon-card-container file-component" render={() => (
                            <Fragment>
                                <List.Content className="image-placeholder file-attachment">
                                    <AdaptiveIcon url={renderName(file) ?? ""} size="large" className="preview" />
                                </List.Content>
                                <div className='file-details'>
                                    <p className='no-margin f-name'>{renderName(file) ?? ""}</p>
                                    <p className='no-margin f-size'>{renderSizeInMB(file)}</p>
                                </div>
                            </Fragment>
                        )} />
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    )
}

export default Attachment
