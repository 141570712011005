import { actions } from '../constants'

const { TABLE } = actions

const initialState = {
    loading: false,
    tableId: null,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case TABLE.START: {
            return {
                ...state, 
                loading: true,
            }
        }
        case TABLE.RESET: {
            return {
                loading: false,
                tableId: null,
            }
        }
        case TABLE.CLEAR_ALL: {
            
            const payload = action.payload ?? {}

            return {
                ...state, 
                ...payload,
                type: action.type,
                loading: false,
            }
        }
        default: {}
    }

    return state
}