import i18next from 'i18next'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function GoogleRedirectCallback() {
	const router = useSelector(state => state.router)

	useEffect(() => {
		// get the URL parameters which will include the auth token
		const params = window.location.search
		const source = router.location.query.source
		const authCode = router.location.query.code

		if (window.opener) {
			
			// Create a custom JS Event that will fire on the Opening document window.
			// Where anything listening on `onGoogleFinished` will capture the event message detail.
			const event = new CustomEvent('onGoogleFinished', {
				detail: {
					params, 
					source, 
					authCode, 
					origin: window.origin
				}
			})
			
			window.opener.document.dispatchEvent(event)
			// window.opener.postMessage({params, source, authCode})
			
			// close the popup
			window.close()
		}
	}, [])
	
	return <p>{i18next.t("general.loading")}</p>
}
