/**
 *  Get data from envs
 */
const common = {
    env: process.env.REACT_APP_NODE_ENV,
    isProd: (process.env.REACT_APP_NODE_ENV === 'production'),
    isStaging: (process.env.REACT_APP_NODE_ENV === 'staging'),
    isLocal: (process.env.REACT_APP_NODE_ENV === 'local'),
    baseUrl: process.env.REACT_APP_API_BASE_URL,


    // SSO Configurations
    ssoAuthUrl: process.env.REACT_APP_SSO_AUTH_URL,
    ssoClientID: process.env.REACT_APP_SSO_CLIENT_ID,
    ssoAuthScope: process.env.REACT_APP_SSO_AUTH_SCOPE,
    ssoClientRoot: process.env.REACT_APP_SSO_CLIENT_ROOT,
    ssoResponseType: process.env.REACT_APP_SSO_RESPONSE_TYPE,

    // Google Maps API Key
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    
    // Google Maps Secret Key
    googleSecretHash: process.env.REACT_APP_GOOGLE_API_SECRET_HASH,
    
    // Bugsnag Api Key
    bugsnagApiKey: process.env.REACT_APP_BUGSNAG_KEY,

    // Relay Uploads
    uploadsBaseUrl: process.env.REACT_APP_UPLOADS_BASE_URL,

    // Datadog
    datadog: {
        appId: process.env.REACT_APP_DATADOG_APP_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    },
    
    // MS Clarity
    clarity: {
        projectId: process.env.REACT_APP_MS_CLARITY_PROJECT_ID,
    }
}

const Config = {
    ...common
}

export default Config
