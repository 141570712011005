import i18next from "i18next"
import { pickBy, identity, size, mapKeys } from "lodash"
import { filterNulls } from "../../utils/helper"

class CreateChannelRequest {

    constructor({
        name,
        tagIds,
        isHidden, 
        canLeave,
        isDefault,
        inviteOnly,
        communityId,
        allMembers,
        specificMembers,
        allCurrentMembers,
        allNewMembers,
        selectedUsers,
        whoCanJoin,
        canAnyone
    }) {
        this.name = name
        this.tagIds = tagIds
        this.canLeave = canLeave
        this.isHidden = isHidden
        this.isDefault = isDefault
        this.inviteOnly = inviteOnly
        this.allMembers = allMembers
        this.communityId = communityId
        this.allNewMembers = allNewMembers
        this.whoCanJoin = whoCanJoin
        this.canAnyone = canAnyone
        this.users = selectedUsers
    }

    getPayload = () => {
        
        const payload = {
            "name": this.name,
            "can_leave": this.canLeave,
            "is_hidden": this.isHidden,
            "invite_only": this.inviteOnly,
            "community_id": this.communityId,
            "is_default": this.isDefault
        }

        // 1. When "Everyone" is selected, we send through the flag
        if (this.whoCanJoin === "anyone") {
            payload['attach_all_persons_in_community'] = true
        }
        
        // 2. When "Tags" is selected, we send through the tag ids
        if (size(this.tagIds) > 0 && this.whoCanJoin === "tags") {
            
            // 2.1 When "Private" is also selected here, we send the tag ids
            if (this.inviteOnly) {
                payload["tag_ids"] = this.tagIds
            }

            if (!this.inviteOnly) {   
                payload['attach_persons_with_tag_ids'] = this.tagIds
            }
            
        }
        
        // 3. When "Individuals" is selected, we send the person ids
        if (size(this.users) > 0 && this.whoCanJoin === "individuals") {

            // 3.1 When "Private" is also selected here, we send the person ids
            if (this.inviteOnly) {
                payload["person_ids"] = this.users
            }

            payload['attach_persons_with_ids'] = this.users
        }

        return filterNulls(payload)
    }

    /**
     * 
     * @returns {{isValid: Boolean, messages: Array}}
     */
    validator = () => {
        
        const errors = []
        
        if (!this.name) {
            errors.push({
                field: "name",
                isValid: false,
                message: i18next.t('requestObjects.CreateChannelRequest.requiredChannelName')
            })
        }
        
        if (this.whoCanJoin === "tags" && size(this.tagIds) == 0) {
            errors.push({
                field: "tags",
                isValid: false,
                message: i18next.t('requestObjects.CreateChannelRequest.requiredTags')
            })
        }

        return {
            isValid: (errors.length === 0),
            messages: mapKeys(errors, error => error.field)
        }
    }

}

export default CreateChannelRequest