import React from 'react'
import { Grid, List, Progress as SProgress } from 'semantic-ui-react'
import BaseTemplate from './BaseTemplate'

const Progress = ({ item }) => {
    return (
        <BaseTemplate render={() => (
            <List.Content>
                <Grid className="no-margin">
                    <Grid.Row columns={2} className="vertical-align">
                        <Grid.Column width={11} className="no-margin">
                            <p className="black-text no-margin">{item.data.title}</p>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <p className="black-text no-margin">Avg = |</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row stretched columns={2} className="vertical-align">
                        <Grid.Column verticalAlign="middle" width={12} className="marks-preview no-padding">
                            <SProgress percent={item.data.currentPercentage} size='tiny' />
                            {item.data.showAverageMarker && (
                                (item.data.averagePercentage > 0) ? (
                                    <span style={{left: ((item.data.averagePercentage >= 100 ? "99.1" : item.data.averagePercentage) + '%')}} className="avg-line"></span>
                                ) : null
                            )}
                        </Grid.Column>
                        <Grid.Column verticalAlign="middle" width={4}>
                            <span className="mark-value">{item.data.currentPercentage} %</span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Content>
        )} />
    )
}

export default Progress
