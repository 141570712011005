import React from "react"
import PropTypes from 'prop-types'
import { Trans } from "react-i18next"
import { Icon, Label } from "semantic-ui-react"
import i18next from "i18next"

const propTypes = {
	items: PropTypes.number,            // The number of items selected
	label: PropTypes.string,            // The caption to show when the items are selected, this is determined by which items are being displayed for the table
	labelPlural: PropTypes.string,      // The plural form of the caption
	classNames: PropTypes.string,       // Additional classnames

    //Functions
    onClear: PropTypes.func,                // The action when the clear button is clicked
}

const defaultProps = {
	items: 0,
	label: i18next.t("general.table.selection.label"),
	labelPlural: i18next.t("general.table.selection.labelPlural"),
    classNames: "",
    //Functions
    onClear: () => {},
}

const TableSelectionIndicator = (props) => {
    
    const {
        items,
        label,
        onClear,
        classNames,
        labelPlural,
    } = props

    return (
        <Label as="h3" className={`black-text selection-indicator ${classNames}`}>
            <Trans 
                i18nKey='table.selection.label'
                count={items}
                values={{
                    items: items,
                    label: label,
                    labelPlural: labelPlural
                }} />
                <Icon name='close' onClick={onClear} />
        </Label>
    )
}

TableSelectionIndicator.propTypes = propTypes
TableSelectionIndicator.defaultProps = defaultProps

export default TableSelectionIndicator