import i18next from "i18next"
import { mapKeys } from "lodash"
import { messageBuilder } from "../../constants"

/**
 * @typedef Request
 * @property {String} text
 * @property {?String} mode
 * @property {String} selectedOption
 * @property {String} communityId
 */
class AssistedWritingRequest {

  /**
   * 
   * @param {Request} payload
   */
  constructor(payload) {
    this.text = payload.text
    this.mode = payload.mode
    this.communityId = payload.communityId
    this.selectedOption = payload.selectedOption
  }

  /**
   * Post data ready for usage by the API
   * 
   * @returns {Request}
   */
  getPayload = () => {

    const payload = {
      "community_id": this.communityId,
      "text": this.text,
    }

    if (this.mode === messageBuilder.INTEGRATIONS.OPEN_AI.MODES.CHANGE_TONE) {
      payload['tone'] = this.selectedOption
    }
    
    if (this.mode === messageBuilder.INTEGRATIONS.OPEN_AI.MODES.SUMMARIZE) {
      payload['method'] = this.selectedOption
    }
    
    return payload
  }

  /**
 * Determine error messages from the given data if any
 * 
 * @returns {ValidationError}
 */
  validator = () => {
    const errors = []

    if (!this.text) {
      errors.push({
        field: "text",
        isValid: false,
        message: i18next.t("requests.integrations.openai.required.text")
      })
    }

    return {
      isValid: (errors.length === 0),
      messages: mapKeys(errors, error => error.field)
    }
  }

}

export default AssistedWritingRequest