import i18next from "i18next"
import { pickBy, identity, mapKeys, filter, map } from "lodash"
import moment from "moment"
import { imageConversionTypes, unsupportedImageMimes } from "../../constants"
import { filterNulls, mailValidator, phoneValidator } from "../../utils/helper"

/**
 * @typedef Request
 * @property {File} file
 * @property {?String} url
 * @property {?String} mime
 * @property {String} type
 * @property {?String} metadata
 * @property {?String} expiresAt
 */

/**
 * @typedef ValidationError
 * @property {Boolean} isValid
 * @property {Array} messages
 */

class UploadFileRequest {

  /**
   * 
   * @param {Request} payload
   */
  constructor(payload) {
    this.url = payload.url
    this.mime = payload.mime
    this.file = payload.file
    this.type = payload.type
    this.metadata = payload.metadata
    this.expiresAt = payload.expiresAt

    this.resizes = [
      {
        'width': 96,
        'height': 96
      },
      {
        'width': 960,
        'height': 960
      },
    ]
  }

  /**
   * Post data ready for usage by the API
   * 
   * @returns {Request}
   */
  getPayload = () => {

    let payload = {
      // "url": this.url ?? null,
      "file": this.file ?? null,
      // "metadata": this.metadata ?? null,
      // "expires_at": moment().add(1, "day").endOf("day").toISOString(),
    }

    // We need to create FormData since the request has to be a multipart/form-data
    const formData = new FormData()

    // We only need to send data that is not null
    payload = filterNulls(payload)

    // We will add resizes only for Image Uploads
    if (this.type === 'image') {
      
      // Add Resizes
      formData.append("resizes[0][width]", 96)
      formData.append("resizes[0][height]", 96)
      formData.append("resizes[1][width]", 960)
      formData.append("resizes[1][height]", 960)
    }

    // We are going to request conversion when we only have a heic/heif
    if (this.type === 'image' && unsupportedImageMimes.includes(this.mime)) {
      formData.append('convert_image_to', imageConversionTypes.jpeg)
    }

    // Add our request payload to the form-data
    map(payload, (value, key) => formData.append(key, value))

    return formData
  }

  /**
 * Determine error messages from the given data if any
 * 
 * @returns {ValidationError}
 */
  validator = () => {
    const errors = []

    if (!this.file) {
      errors.push({
        field: "file",
        isValid: false,
        message: i18next.t("requests.uploads.requiredFile")
      })
    }

    return {
      isValid: (errors.length === 0),
      messages: mapKeys(errors, error => error.field)
    }
  }

}

export default UploadFileRequest