import ApiService from "./ApiService"
import {
    ListUsersRequest,
    GetUserFiltersRequest,
    UpdateUserRequest,
    DetachUserRequest,
    InviteUserRequest,
    ListInviteUsersRequest,
    ListMembersRequest,
    GetMemberFiltersRequest,
    UpdateMemberRequest,
    CreateMemberRequest,
    ImportMembersRequest,
    PromoteOrDemoteMembersRequest,
    ExportMembersRequest,
    InviteMemberRequest,
    GetAppDownloadInvitesBreakdownRequest,
    InviteMembersRequest,
    GetAppDownloadInvitesBreakdownByFiltersRequest,
    GenericListRequest
} from "../api/requestObjects"
import { EXPORT_BY_SELECTION } from "../api/requestObjects/ExportMembersRequest"
import { INVITE_BY_FILTERS, INVITE_BY_SELECTION } from "../api/requestObjects/InviteMembersRequest"

class UserApiService extends ApiService {

    /**
     * Fetch filters  for user management screen
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getFilters = ({ communityId }) => {

        const { GET_USER_FILTERS } = this.endpoints
        let payload = new GetUserFiltersRequest({ communityId: communityId })

        return this.API(this.getEndpoint({
            path: GET_USER_FILTERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch filters for invitations screen
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getInviteFilters = ({ communityId }) => {

        const { GET_USER_INVITE_FILTERS } = this.endpoints
        let payload = new GetUserFiltersRequest({ communityId: communityId })

        return this.API.get(this.getEndpoint({
            path: GET_USER_INVITE_FILTERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch filters for community user management screen
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getMemberFilters = ({ communityId }) => {
        
        const { GET_MEMBER_FILTERS } = this.endpoints
        const payload = new GetMemberFiltersRequest({ communityId: communityId })

        return this.API(this.getEndpoint({
            path: GET_MEMBER_FILTERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch parent child persons
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getParentChildFilters = (params) => {

        const { GET_PARENT_CHILD_PERSONS } = this.endpoints
        const payload = new GetMemberFiltersRequest(params)

        return this.API(this.getEndpoint({
            path: GET_PARENT_CHILD_PERSONS,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch list of users
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getUsers = (params) => {

        const { GET_USERS } = this.endpoints
        const payload = new ListUsersRequest(params)

        return this.API(this.getEndpoint({
            path: GET_USERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Fetch list of community members
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getMembers = (params) => {

        const { GET_MEMBERS } = this.endpoints
        const payload = new ListMembersRequest(params)

        return this.API(this.getEndpoint({
            path: GET_MEMBERS,
            params: payload.getPayload()
        }))
    }

    /**
     * Post update user details
     * Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postUpdateUser = (params) => {

        const { PUT_USER_UPDATE } = this.endpoints
        const payload = new UpdateUserRequest(params)

        return this.API.put(this.getEndpoint({
            path: PUT_USER_UPDATE,
            data: params.userId,
        }), payload.getPayload())
    }

    /**
     * Post update member details
     * validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postUpdateMember = (params) => {

        const { PUT_MEMBER_UPDATE } = this.endpoints
        const payload = new UpdateMemberRequest(params)

        return this.API.put(this.getEndpoint({
            path: PUT_MEMBER_UPDATE,
            data: params.userId,
        }), payload.getPayload())
    }

    /**
     * Post create new member
     * validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postCreateMember = (params) => {

        const { POST_MEMBER_CREATE } = this.endpoints
        const createMemberRequest = new CreateMemberRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_MEMBER_CREATE,
        }), createMemberRequest.getPayload())
    }
    
    /**
     * Given the payload, check if there is a member who already exists with the given details.
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postCheckMemberUniqueness = (params) => {

        const { POST_MEMBER_CHECK_UNIQUENESS } = this.endpoints
        const createMemberRequest = new CreateMemberRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_MEMBER_CHECK_UNIQUENESS,
        }), createMemberRequest.getUniquenessCheckerPayload())
    }

    /**
     * Post detach user
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDetachUser = (params) => {

        const { POST_USER_DETACH } = this.endpoints
        const payload = new DetachUserRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_USER_DETACH,
            data: params.userId,
        }), payload.getPayload())
    }

    /**
     * Post detach multiple users
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postDetachManyUsers = (payload) => {

        const { POST_DELETE_MULTI_SYS_USERS } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_DELETE_MULTI_SYS_USERS,
        }), payload)
    }

    /**
     * Fetch specific user details
     * 
     * @param {JSON} {userID}
     * @return {Promise}
     */
    getUser = ({ userId, communityId }) => {

        const { GET_USER } = this.endpoints

        return this.API(this.getEndpoint({
            path: GET_USER,
            data: userId,
            params: {
                community_id: communityId
            }
        }))
    }

    /**
     * Fetch specific member details
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getMember = ({ userId, communityId }) => {

        const { GET_MEMBER } = this.endpoints

        return this.API(this.getEndpoint({
            path: GET_MEMBER,
            data: userId,
            params: {
                community_id: communityId
            }
        }))
    }

    /**
     * Fetch specific member audit history logs
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getMemberHistory = (params) => {

        const { GET_MEMBER_HISTORY } = this.endpoints
        const request = new GenericListRequest(params)

        // @TODO: Remove when all is ready.
        // return this.API.get(`http://localhost:3001/api/v1/mock-audit-logs`)
        
        return this.API(this.getEndpoint({
            path: GET_MEMBER_HISTORY,
            data: params.userId,
            params: request.getPayload()
        }))
    }

    /**
     * Post delete member
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDeleteMember = (params) => {

        const { DELETE_MEMBER } = this.endpoints

        const payload = {
            notify_subscription_changes_via_app: params.notify_subscription_changes_via_app,
            notify_community_subscription_changes_via_app: params.notify_community_subscription_changes_via_app
        }

        return this.API.delete(this.getEndpoint({
            path: DELETE_MEMBER,
            data: params.memberId
        }), { data: payload })
    }

    /**
     * Delete Multiple members
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postDeleteMultiMembers = (payload) => {

        const { POST_DELETE_MULTI_MEMBERS } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: POST_DELETE_MULTI_MEMBERS,
        }), { data: payload })
    }

    /**
     * Delete Multiple members via filters
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDeleteMembersByFilters = (params) => {

        const { POST_DELETE_MULTI_MEMBERS_VIA_FILTERS } = this.endpoints
        const request = new ListMembersRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_DELETE_MULTI_MEMBERS_VIA_FILTERS,
        }), request.getPayload())
    }

    /**
     * Add/Remove multiple members to member list
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postPromoteOrDemote = (payload) => {

        const { POST_PROMOTE_OR_DEMOTE_MEMBER } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_PROMOTE_OR_DEMOTE_MEMBER,
        }), payload)
    }

    /**
     * Export members by selection or by filters
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postExportMembers = (payload) => {

        const { POST_EXPORT_MULTI_MEMBERS, POST_EXPORT_MEMBERS } = this.endpoints
        const request = new ExportMembersRequest(payload)

        // Export selected person ids
        if (payload.exportType === EXPORT_BY_SELECTION) {

            return this.API({
                // responseType: "blob",
                method: "POST",
                data: request.getExportBySelectionPayload(),
                url: this.getEndpoint({
                    path: POST_EXPORT_MEMBERS,
                })
            })
        }

        return this.API({
            // responseType: "blob",
            method: "POST",
            data: request.getExportByFiltersPayload(),
            url: this.getEndpoint({
                path: POST_EXPORT_MULTI_MEMBERS,
            })
        })
    }

    /**
     * Add/Remove multiple members to member list by applied filter
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postPromoteOrDemoteByFilters = (payload) => {

        const { POST_PROMOTE_DEMOTE_MULTI_MEMBERS_VIA_FILTERS } = this.endpoints
        const request = new PromoteOrDemoteMembersRequest(payload)

        return this.API.post(this.getEndpoint({
            path: POST_PROMOTE_DEMOTE_MULTI_MEMBERS_VIA_FILTERS,
        }), request.getPayload())
    }

    /**
     *  Invite members to to App
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postInviteMemberToApp = (payload) => {

        const { POST_INVITE_MEMBERS_TO_APP, POST_REMIND_INVITE_MEMBERS_TO_APP } = this.endpoints
        const request = new InviteMemberRequest(payload)

        return this.API.post(this.getEndpoint({
            path: !request.isReminder ? POST_INVITE_MEMBERS_TO_APP : POST_REMIND_INVITE_MEMBERS_TO_APP,
            data: request.personId,
        }), request.getPayload())
    }

    /**
     * Invite members by selection or by filters
     * 
     * @param {JSON} payload
     * @return {Promise}
     */
    postInviteMembersToApp = (payload) => {

        const { POST_APP_INVITE_MULTI_MEMBERS, POST_APP_INVITE_MULTI_MEMBERS_BY_FILTERS } = this.endpoints
        const request = new InviteMembersRequest(payload)

        if (payload.inviteType === INVITE_BY_SELECTION) {
            return this.API.post(this.getEndpoint({
                path: POST_APP_INVITE_MULTI_MEMBERS,
            }), request.getSelectionPayload())
        }

        return this.API.post(this.getEndpoint({
            path: POST_APP_INVITE_MULTI_MEMBERS_BY_FILTERS,
        }), request.getFiltersFiltersPayload())
    }

    /**
     * Fetch App Download Breakdown
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getAppDownloadInviteBreakdown = (params) => {

        const { GET_APP_DOWNLOAD_BREAKDOWN, GET_APP_DOWNLOAD_BREAKDOWN_VIA_FILTERS } = this.endpoints
        const basicRequest = new GetAppDownloadInvitesBreakdownRequest(params)

        if (params.inviteType === INVITE_BY_SELECTION) {
            return this.API.post(this.getEndpoint({
                path: GET_APP_DOWNLOAD_BREAKDOWN,
            }), basicRequest.getPayload())
        }

        const filtersRequest = new GetAppDownloadInvitesBreakdownByFiltersRequest(params)

        return this.API.post(this.getEndpoint({
            path: GET_APP_DOWNLOAD_BREAKDOWN_VIA_FILTERS,
        }), filtersRequest.getPayload())
    }

    /**
     *  Fetch persons through applied filters
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getPersonsForFilter = (params) => {

        const { GET_MEMBERS_VIA_FILTERS } = this.endpoints
        const request = new ListMembersRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_MEMBERS_VIA_FILTERS,
            params: request.getPayload()
        }))
    }

    /**
     * Fetch user invites
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getInvites = (params) => {

        const { GET_USER_INVITES } = this.endpoints
        const payload = new ListInviteUsersRequest(params)

        return this.API(this.getEndpoint({
            path: GET_USER_INVITES,
            params: payload.getPayload()
        }))
    }

    /**
     * Post invite user
     * Validated from UI
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postInviteUser = (params) => {

        const { POST_USER_INVITE } = this.endpoints
        const payload = new InviteUserRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_USER_INVITE,
        }), payload.getPayload())
    }

    /**
     * Post invite user
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postResendInvite = (params) => {

        const { POST_RESEND_USER_INVITE } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_RESEND_USER_INVITE,
            data: params.inviteId,
        }))
    }

    /**
     * Post delete invite
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDeleteMultiInvites = (payload) => {

        const { POST_DELETE_MULTI_INVITES } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: POST_DELETE_MULTI_INVITES,
        }), { data: payload })
    }

    /**
     * Post delete invites
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postDeleteInvite = (params) => {

        const { DELETE_INVITE } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: DELETE_INVITE,
            data: params.inviteId
        }))
    }

    /**
     * Fetch Invitation details
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    getInvitationDetails = (params) => {

        const { GET_INVITE_DETAILS } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_INVITE_DETAILS,
            data: params.token
        }))
    }

    /**
     * Post Accept Invitation
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postAcceptInvite = (params) => {

        const { POST_ACCEPT_INVITE } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: POST_ACCEPT_INVITE,
            data: params.inviteId
        }))
    }

    /**
     * Post Import Members
     * 
     * @param {JSON} params
     * @return {Promise}
     */
    postImportMembers = (params) => {

        const { POST_IMPORT_MEMBERS } = this.endpoints
        const payload = new ImportMembersRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_IMPORT_MEMBERS,
            data: payload.communityId
        }), payload.getPayload())
    }
}

export default UserApiService