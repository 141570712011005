import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import logoAnim from '../../images/d6_logo.riv'
import i18next from 'i18next'
import LogoTeal from './LogoTeal'

const propTypes = {
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
    classNames: PropTypes.array, // Additional classes to apply to this component
}

const defaultProps = {
    loading: false,
    loadingText: i18next.t("general.loading"),
    classNames: [],
}

const AnimLoader = (props) => {

    const {
        loading,
        classNames,
        loadingText,
    } = props

    return (
        <Dimmer active={loading} inverted>
            <Loader
                inverted
                className={['general-loader', ...classNames].join(" ")}
                content={
                    <Fragment>
                        <div className='rive-loader'>
                            <LogoTeal />
                        </div>
                        {loadingText}
                    </Fragment>
                } />
        </Dimmer>
    )
}

AnimLoader.propTypes = propTypes
AnimLoader.defaultProps = defaultProps

export default AnimLoader
