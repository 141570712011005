import React, { useState, useEffect } from 'react'

import { EditorState } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import Switch from "react-switch"
import BaseMorphed from './BaseMorphed'
import { Input, Grid, Popup, Button, Icon, Dropdown, Checkbox } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch } from 'react-redux'
import Slider from 'react-rangeslider'
import CustomRangeSlider from '../../partials/CustomRangeSlider'
import { useTranslation } from 'react-i18next'
import ErrorLabel from '../../partials/ErrorLabel'
import { getError } from '../../../utils/helper'

export default function ProgressMorphed({item}) {
    
    const [data, setData] = useState({
        title: item.data ? item.data.title : "",
        showAverageMarker: item.data ? item.data.showAverageMarker : false,
        description: item.data ? item.data.description : "",
        fromDisplay: item.data ? item.data.fromDisplay : "0",
        toDisplay: item.data ? item.data.toDisplay : "0",
        currentDisplay: item.data ? item.data.currentDisplay : "",
        averageDisplay: item.data ? item.data.averageDisplay : "",
        currentPercentage: item.data ? item.data.currentPercentage : 0.0,
        averagePercentage: item.data ? item.data.averagePercentage : 0.0,
        currentTint: item.data ? item.data.currentTint : "#56CBC8",
        barTint: item.data ? item.data.barTint : "#FFBB21",
        originalId: item?.data?.originalId,
    })

    const [showAverageMarker, setShowAverageMarker] = useState(false)

    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({
            item, 
            data: {
                ...data,
                toDisplay: ("").concat(data.currentPercentage),
                currentDisplay: ("").concat(data.currentPercentage),
                averageDisplay: ("").concat(data.averagePercentage),
                averagePercentage: data.averagePercentage,
                currentPercentage: data.currentPercentage,
            }
        }))
        return () => {}
    }, [data, dispatch])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }

    const handleTitleChange = (e, d) => {
        setData({...data, title: d.value})
    }

    const handleAvgChange = (e, value) => {

        setData({...data, showAverageMarker: !showAverageMarker})
        setShowAverageMarker(!showAverageMarker)
    }

    return (
        <BaseMorphed item={item} render={({errors}) => (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.ProgressMorphed.label.title')}</label>
                            <Input 
                                value={data.title} 
                                onChange={handleTitleChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.ProgressMorphed.label.title')} />
                        </div>
                        <ErrorLabel error={getError("title", errors)} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className="input-container theme-color">
                            <Grid>
                                <Grid.Column width={5}>
                                    <label className='label'>{t('messageBuilder.morphedTemplates.ProgressMorphed.label.progress')}</label>
                                </Grid.Column>
                            </Grid>
                            <Grid>
                                <Grid.Row columns={2}>
                                    <Grid.Column style={{paddingBottom: '10px'}} width={5}>
                                        <CustomRangeSlider 
                                            value={data.currentPercentage}
                                            name="currentPercentage"
                                            onChange={handleInputChange} />
                                    </Grid.Column>

                                    <Grid.Column width={5} className="vertical-align">
                                        <label className='label'><strong>{ data.currentPercentage } %</strong></label>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <ErrorLabel error={getError("current_percentage", errors)} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div className='vertical-align'>
                            <Checkbox
                                checked={showAverageMarker}
                                onChange={handleAvgChange} />
                            <span className='caption mg-left-15'>{t('messageBuilder.morphedTemplates.ProgressMorphed.label.showAvgMarker')}</span>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                            <div className="input-container theme-color">
                                <Grid>
                                    <Grid.Column width={5}>
                                        <label className='label'>{t('messageBuilder.morphedTemplates.ProgressMorphed.label.average')}</label>
                                    </Grid.Column>
                                </Grid>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column style={{paddingBottom: '10px'}} width={5}>
                                            <CustomRangeSlider 
                                                value={data.averagePercentage}
                                                name="averagePercentage"
                                                onChange={handleInputChange} />
                                        </Grid.Column>

                                        <Grid.Column width={5} className="vertical-align">
                                            <label className='label'><strong>{ data.averagePercentage } %</strong></label>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Grid.Column>
                </Grid.Row>
            </Grid>
        )} />
    )
}
