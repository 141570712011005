import React from 'react'
import { List } from 'semantic-ui-react'
import BaseTemplate from './BaseTemplate'

import CandidatesPreview from './voteTypes/CandidatesPreview'

const Vote = ({ item }) => {
	return (
		<BaseTemplate
			render={() => (
				<List.Content>
                    <CandidatesPreview item={item} />
				</List.Content>
			)}
		/>
	)
}

export default Vote
