import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Input } from 'semantic-ui-react'
import { debounce, deburr, size } from 'lodash'
import i18next from 'i18next'

const propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    loading: PropTypes.bool,
    eventSearch: PropTypes.bool,
    placeholder: PropTypes.string,
    interval: PropTypes.number,
    limit: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([undefined])]),
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    escapeSpecialCharacters: PropTypes.bool,

    // Functions
    onChange: PropTypes.func,
}

const defaultProps = {
    title: i18next.t("general.input.search"),
    name: "search",
    loading: false,
    escapeSpecialCharacters: true,
    interval: 1000,
    value: "",
    defaultValue: "",
    eventSearch: false,
    ignoreEncoding: false,
    placeholder: i18next.t("general.input.searchPlaceholder"),

    onChange: (value) => {}
}

const StatefulDebouncedSearchInput = props => {

    const {
        name,
        title,
        loading,
        interval,
        onChange,
        placeholder,
        defaultValue,
        eventSearch,
        ignoreEncoding,
        escapeSpecialCharacters,
        autoFocus,
        autoSelect,
        limit,
    } = props

    let decodedDefaultValue = defaultValue
    
    const inputRef = useRef(null)

    try {
        decodedDefaultValue = decodeURIComponent(defaultValue ?? '')
    } catch (error) {
        console.log('URI Component not decodable: ' + defaultValue)
    }

    // const decodedDefaultValue = defaultValue
    const [state, setState] = useState(decodedDefaultValue)

    const handleSearchChange = (e, data) => {

        // When there is a limit and the user is typing, we are not going to search nor are we going to to update the state
        if (limit && size(data.value) > limit) {
            // When we are getting the value from a paste event we are going to truncate the paste if it is too long
            if (e?.nativeEvent?.inputType === 'insertFromPaste') {
                setState(data.value.substring(0, limit))
                delayedHandleChange(e?.nativeEvent)
            }
            return
        }

        setState(data.value)
        delayedHandleChange(e)
    }

    const delayedHandleChange = debounce(jsEvent => {
        const target = jsEvent.target
        let value = target.value

        if (escapeSpecialCharacters && !ignoreEncoding) {
            value = encodeURIComponent(value)
        }

        if (onChange) {
            // When Searching for calendar events we need the original JS Event so SemanticUI can be happy
            if (eventSearch) {
                onChange(jsEvent, {value})
                return;
            }
            onChange(value)
        }

    }, interval)

    const handleSelectionOnClick = () => {
        if (inputRef.current && autoSelect) {
          inputRef.current.select()
        }
    }

    useEffect(() => {
      if (inputRef.current && autoFocus) {
        inputRef.current.focus()
      }
    }, [autoFocus])
    
    return (
        <div className="input-container theme-color">
            <label className='label'>{title}</label>
            <Input 
                ref={inputRef}
                icon="search"
                placeholder={placeholder}
                name={name} 
                loading={loading}
                value={state}
                autoComplete="off"
                onClick={handleSelectionOnClick}
                onChange={handleSearchChange} />
        </div>
    )
}

StatefulDebouncedSearchInput.propTypes = propTypes
StatefulDebouncedSearchInput.defaultProps = defaultProps

export default StatefulDebouncedSearchInput
