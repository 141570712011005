import {
    createAction
} from 'redux-actions'

import { actions } from '../constants'

const { TABLE } = actions

export const tableActions = {

    /**
     * Start loading
     */
    loading: createAction(TABLE.START),

    /**
     * Reset reducer
     */
    reset: createAction(TABLE.RESET),
    
    /**
     * Clear All Selections
     */
    clearSelection: createAction(TABLE.CLEAR_ALL),
    
} 