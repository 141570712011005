import React, { useState, Fragment } from 'react'
import { Grid, Input } from 'semantic-ui-react'

export default function FileUploadQuestion({item, data, handleInputChange, t}) {
    
    return (
        <Fragment>
            <Grid.Row>
                <Grid.Column>
                    <div className="input-container theme-color">
                        <label className='label'>{t("general.form.file.placeholder")}</label>
                        <Input 
                            value={data.question} 
                            name="question"
                            type="question"
                            autoComplete="off" 
                            onChange={(e, {value}) => handleInputChange({name: "question", value})} 
                            placeholder={t("general.form.file.placeholder")} />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Fragment>
    )
}
