import Big from 'big.js';

export default function formatCurrency(value, localeConfig, currencyName) {
  const numberConfig = localeConfig.formats.number[currencyName];
  const formatter = new global.Intl.NumberFormat(localeConfig.locale, numberConfig);

  return formatter.format(Big(value));
}

export function toUnit(amount, iso4217code) {
  const currency = getISO4217byCode(iso4217code)
  const digits = parseInt(currency.D)

  // Calculate the smallest unit amount using the ratio
  return parseInt(parseFloat(amount * 10 ** digits).toFixed(digits))
  // return amount * 10 ** digits
}

/**
 * Get the conversion ration using the given decimals from a currency
 * 
 * @link https://en.wikipedia.org/wiki/ISO_4217:Minor units of currency
 * @param {string} currencyDecimals 
 * @returns {Number}
 */
function getConversionRatio(currencyDecimals) {

  switch (currencyDecimals) {
    case "1":
      return 10
    case "2":
      return 100
    case "3":
      return 1000
    default:
      return 0
  }
}

/**
 * Get a specific ISO_4217 currency using a currency code
 * 
 * @param {string} currencyCode 
 * @returns {Object}
 */
export function getISO4217byCode(currencyCode) {
  // If for some reason when we dont find the corresponding object, we can default to a ZAR
  // Because not adding anything will throw an Error
  return iso4217Currencies[currencyCode] ?? iso4217Currencies["ZAR"]
}

/**
 * List of active ISO_4217
 * 
 * @link https://en.wikipedia.org/wiki/ISO_4217
 * @returns {Object}
 */
export const iso4217Currencies = {
  "AED": {
    "Code": "AED",
    "Num": "784",
    "D": "2",
    "Currency": "United Arab Emirates dirham"
  },
  "AFN": {
    "Code": "AFN",
    "Num": "971",
    "D": "2",
    "Currency": "Afghan afghani"
  },
  "ALL": {
    "Code": "ALL",
    "Num": "008",
    "D": "2",
    "Currency": "Albanian lek"
  },
  "AMD": {
    "Code": "AMD",
    "Num": "051",
    "D": "2",
    "Currency": "Armenian dram"
  },
  "ANG": {
    "Code": "ANG",
    "Num": "532",
    "D": "2",
    "Currency": "Netherlands Antillean guilder"
  },
  "AOA": {
    "Code": "AOA",
    "Num": "973",
    "D": "2",
    "Currency": "Angolan kwanza"
  },
  "ARS": {
    "Code": "ARS",
    "Num": "032",
    "D": "2",
    "Currency": "Argentine peso"
  },
  "AUD": {
    "Code": "AUD",
    "Num": "036",
    "D": "2",
    "Currency": "Australian dollar"
  },
  "AWG": {
    "Code": "AWG",
    "Num": "533",
    "D": "2",
    "Currency": "Aruban florin"
  },
  "AZN": {
    "Code": "AZN",
    "Num": "944",
    "D": "2",
    "Currency": "Azerbaijani manat"
  },
  "BAM": {
    "Code": "BAM",
    "Num": "977",
    "D": "2",
    "Currency": "Bosnia and Herzegovina convertible mark"
  },
  "BBD": {
    "Code": "BBD",
    "Num": "052",
    "D": "2",
    "Currency": "Barbados dollar"
  },
  "BDT": {
    "Code": "BDT",
    "Num": "050",
    "D": "2",
    "Currency": "Bangladeshi taka"
  },
  "BGN": {
    "Code": "BGN",
    "Num": "975",
    "D": "2",
    "Currency": "Bulgarian lev"
  },
  "BHD": {
    "Code": "BHD",
    "Num": "048",
    "D": "3",
    "Currency": "Bahraini dinar"
  },
  "BIF": {
    "Code": "BIF",
    "Num": "108",
    "D": "0",
    "Currency": "Burundian franc"
  },
  "BMD": {
    "Code": "BMD",
    "Num": "060",
    "D": "2",
    "Currency": "Bermudian dollar"
  },
  "BND": {
    "Code": "BND",
    "Num": "096",
    "D": "2",
    "Currency": "Brunei dollar"
  },
  "BOB": {
    "Code": "BOB",
    "Num": "068",
    "D": "2",
    "Currency": "Boliviano"
  },
  "BOV": {
    "Code": "BOV",
    "Num": "984",
    "D": "2",
    "Currency": "Bolivian Mvdol (funds code)"
  },
  "BRL": {
    "Code": "BRL",
    "Num": "986",
    "D": "2",
    "Currency": "Brazilian real"
  },
  "BSD": {
    "Code": "BSD",
    "Num": "044",
    "D": "2",
    "Currency": "Bahamian dollar"
  },
  "BTN": {
    "Code": "BTN",
    "Num": "064",
    "D": "2",
    "Currency": "Bhutanese ngultrum"
  },
  "BWP": {
    "Code": "BWP",
    "Num": "072",
    "D": "2",
    "Currency": "Botswana pula"
  },
  "BYN": {
    "Code": "BYN",
    "Num": "933",
    "D": "2",
    "Currency": "Belarusian ruble"
  },
  "BZD": {
    "Code": "BZD",
    "Num": "084",
    "D": "2",
    "Currency": "Belize dollar"
  },
  "CAD": {
    "Code": "CAD",
    "Num": "124",
    "D": "2",
    "Currency": "Canadian dollar"
  },
  "CDF": {
    "Code": "CDF",
    "Num": "976",
    "D": "2",
    "Currency": "Congolese franc"
  },
  "CHE": {
    "Code": "CHE",
    "Num": "947",
    "D": "2",
    "Currency": "WIR euro (complementary currency)"
  },
  "CHF": {
    "Code": "CHF",
    "Num": "756",
    "D": "2",
    "Currency": "Swiss franc"
  },
  "CHW": {
    "Code": "CHW",
    "Num": "948",
    "D": "2",
    "Currency": "WIR franc (complementary currency)"
  },
  "CLF": {
    "Code": "CLF",
    "Num": "990",
    "D": "4",
    "Currency": "Unidad de Fomento (funds code)"
  },
  "CLP": {
    "Code": "CLP",
    "Num": "152",
    "D": "0",
    "Currency": "Chilean peso"
  },
  "CNY": {
    "Code": "CNY",
    "Num": "156",
    "D": "2",
    "Currency": "Chinese yuan[8]"
  },
  "COP": {
    "Code": "COP",
    "Num": "170",
    "D": "2",
    "Currency": "Colombian peso"
  },
  "COU": {
    "Code": "COU",
    "Num": "970",
    "D": "2",
    "Currency": "Unidad de Valor Real (UVR) (funds code)[9]"
  },
  "CRC": {
    "Code": "CRC",
    "Num": "188",
    "D": "2",
    "Currency": "Costa Rican colon"
  },
  "CUC": {
    "Code": "CUC",
    "Num": "931",
    "D": "2",
    "Currency": "Cuban convertible peso"
  },
  "CUP": {
    "Code": "CUP",
    "Num": "192",
    "D": "2",
    "Currency": "Cuban peso"
  },
  "CVE": {
    "Code": "CVE",
    "Num": "132",
    "D": "2",
    "Currency": "Cape Verdean escudo"
  },
  "CZK": {
    "Code": "CZK",
    "Num": "203",
    "D": "2",
    "Currency": "Czech koruna"
  },
  "DJF": {
    "Code": "DJF",
    "Num": "262",
    "D": "0",
    "Currency": "Djiboutian franc"
  },
  "DKK": {
    "Code": "DKK",
    "Num": "208",
    "D": "2",
    "Currency": "Danish krone"
  },
  "DOP": {
    "Code": "DOP",
    "Num": "214",
    "D": "2",
    "Currency": "Dominican peso"
  },
  "DZD": {
    "Code": "DZD",
    "Num": "012",
    "D": "2",
    "Currency": "Algerian dinar"
  },
  "EGP": {
    "Code": "EGP",
    "Num": "818",
    "D": "2",
    "Currency": "Egyptian pound"
  },
  "ERN": {
    "Code": "ERN",
    "Num": "232",
    "D": "2",
    "Currency": "Eritrean nakfa"
  },
  "ETB": {
    "Code": "ETB",
    "Num": "230",
    "D": "2",
    "Currency": "Ethiopian birr"
  },
  "EUR": {
    "Code": "EUR",
    "Num": "978",
    "D": "2",
    "Currency": "Euro"
  },
  "FJD": {
    "Code": "FJD",
    "Num": "242",
    "D": "2",
    "Currency": "Fiji dollar"
  },
  "FKP": {
    "Code": "FKP",
    "Num": "238",
    "D": "2",
    "Currency": "Falkland Islands pound"
  },
  "GBP": {
    "Code": "GBP",
    "Num": "826",
    "D": "2",
    "Currency": "Pound sterling"
  },
  "GEL": {
    "Code": "GEL",
    "Num": "981",
    "D": "2",
    "Currency": "Georgian lari"
  },
  "GHS": {
    "Code": "GHS",
    "Num": "936",
    "D": "2",
    "Currency": "Ghanaian cedi"
  },
  "GIP": {
    "Code": "GIP",
    "Num": "292",
    "D": "2",
    "Currency": "Gibraltar pound"
  },
  "GMD": {
    "Code": "GMD",
    "Num": "270",
    "D": "2",
    "Currency": "Gambian dalasi"
  },
  "GNF": {
    "Code": "GNF",
    "Num": "324",
    "D": "0",
    "Currency": "Guinean franc"
  },
  "GTQ": {
    "Code": "GTQ",
    "Num": "320",
    "D": "2",
    "Currency": "Guatemalan quetzal"
  },
  "GYD": {
    "Code": "GYD",
    "Num": "328",
    "D": "2",
    "Currency": "Guyanese dollar"
  },
  "HKD": {
    "Code": "HKD",
    "Num": "344",
    "D": "2",
    "Currency": "Hong Kong dollar"
  },
  "HNL": {
    "Code": "HNL",
    "Num": "340",
    "D": "2",
    "Currency": "Honduran lempira"
  },
  "HRK": {
    "Code": "HRK",
    "Num": "191",
    "D": "2",
    "Currency": "Croatian kuna"
  },
  "HTG": {
    "Code": "HTG",
    "Num": "332",
    "D": "2",
    "Currency": "Haitian gourde"
  },
  "HUF": {
    "Code": "HUF",
    "Num": "348",
    "D": "2",
    "Currency": "Hungarian forint"
  },
  "IDR": {
    "Code": "IDR",
    "Num": "360",
    "D": "2",
    "Currency": "Indonesian rupiah"
  },
  "ILS": {
    "Code": "ILS",
    "Num": "376",
    "D": "2",
    "Currency": "Israeli new shekel"
  },
  "INR": {
    "Code": "INR",
    "Num": "356",
    "D": "2",
    "Currency": "Indian rupee"
  },
  "IQD": {
    "Code": "IQD",
    "Num": "368",
    "D": "3",
    "Currency": "Iraqi dinar"
  },
  "IRR": {
    "Code": "IRR",
    "Num": "364",
    "D": "2",
    "Currency": "Iranian rial"
  },
  "ISK": {
    "Code": "ISK",
    "Num": "352",
    "D": "0",
    "Currency": "Icelandic króna (plural: krónur)"
  },
  "JMD": {
    "Code": "JMD",
    "Num": "388",
    "D": "2",
    "Currency": "Jamaican dollar"
  },
  "JOD": {
    "Code": "JOD",
    "Num": "400",
    "D": "3",
    "Currency": "Jordanian dinar"
  },
  "JPY": {
    "Code": "JPY",
    "Num": "392",
    "D": "0",
    "Currency": "Japanese yen"
  },
  "KES": {
    "Code": "KES",
    "Num": "404",
    "D": "2",
    "Currency": "Kenyan shilling"
  },
  "KGS": {
    "Code": "KGS",
    "Num": "417",
    "D": "2",
    "Currency": "Kyrgyzstani som"
  },
  "KHR": {
    "Code": "KHR",
    "Num": "116",
    "D": "2",
    "Currency": "Cambodian riel"
  },
  "KMF": {
    "Code": "KMF",
    "Num": "174",
    "D": "0",
    "Currency": "Comoro franc"
  },
  "KPW": {
    "Code": "KPW",
    "Num": "408",
    "D": "2",
    "Currency": "North Korean won"
  },
  "KRW": {
    "Code": "KRW",
    "Num": "410",
    "D": "0",
    "Currency": "South Korean won"
  },
  "KWD": {
    "Code": "KWD",
    "Num": "414",
    "D": "3",
    "Currency": "Kuwaiti dinar"
  },
  "KYD": {
    "Code": "KYD",
    "Num": "136",
    "D": "2",
    "Currency": "Cayman Islands dollar"
  },
  "KZT": {
    "Code": "KZT",
    "Num": "398",
    "D": "2",
    "Currency": "Kazakhstani tenge"
  },
  "LAK": {
    "Code": "LAK",
    "Num": "418",
    "D": "2",
    "Currency": "Lao kip"
  },
  "LBP": {
    "Code": "LBP",
    "Num": "422",
    "D": "2",
    "Currency": "Lebanese pound"
  },
  "LKR": {
    "Code": "LKR",
    "Num": "144",
    "D": "2",
    "Currency": "Sri Lankan rupee"
  },
  "LRD": {
    "Code": "LRD",
    "Num": "430",
    "D": "2",
    "Currency": "Liberian dollar"
  },
  "LSL": {
    "Code": "LSL",
    "Num": "426",
    "D": "2",
    "Currency": "Lesotho loti"
  },
  "LYD": {
    "Code": "LYD",
    "Num": "434",
    "D": "3",
    "Currency": "Libyan dinar"
  },
  "MAD": {
    "Code": "MAD",
    "Num": "504",
    "D": "2",
    "Currency": "Moroccan dirham"
  },
  "MDL": {
    "Code": "MDL",
    "Num": "498",
    "D": "2",
    "Currency": "Moldovan leu"
  },
  "MGA": {
    "Code": "MGA",
    "Num": "969",
    "D": "2",
    "Currency": "Malagasy ariary"
  },
  "MKD": {
    "Code": "MKD",
    "Num": "807",
    "D": "2",
    "Currency": "Macedonian denar"
  },
  "MMK": {
    "Code": "MMK",
    "Num": "104",
    "D": "2",
    "Currency": "Myanmar kyat"
  },
  "MNT": {
    "Code": "MNT",
    "Num": "496",
    "D": "2",
    "Currency": "Mongolian tögrög"
  },
  "MOP": {
    "Code": "MOP",
    "Num": "446",
    "D": "2",
    "Currency": "Macanese pataca"
  },
  "MRU": {
    "Code": "MRU",
    "Num": "929",
    "D": "2",
    "Currency": "Mauritanian ouguiya"
  },
  "MUR": {
    "Code": "MUR",
    "Num": "480",
    "D": "2",
    "Currency": "Mauritian rupee"
  },
  "MVR": {
    "Code": "MVR",
    "Num": "462",
    "D": "2",
    "Currency": "Maldivian rufiyaa"
  },
  "MWK": {
    "Code": "MWK",
    "Num": "454",
    "D": "2",
    "Currency": "Malawian kwacha"
  },
  "MXN": {
    "Code": "MXN",
    "Num": "484",
    "D": "2",
    "Currency": "Mexican peso"
  },
  "MXV": {
    "Code": "MXV",
    "Num": "979",
    "D": "2",
    "Currency": "Mexican Unidad de Inversion (UDI) (funds code)"
  },
  "MYR": {
    "Code": "MYR",
    "Num": "458",
    "D": "2",
    "Currency": "Malaysian ringgit"
  },
  "MZN": {
    "Code": "MZN",
    "Num": "943",
    "D": "2",
    "Currency": "Mozambican metical"
  },
  "NAD": {
    "Code": "NAD",
    "Num": "516",
    "D": "2",
    "Currency": "Namibian dollar"
  },
  "NGN": {
    "Code": "NGN",
    "Num": "566",
    "D": "2",
    "Currency": "Nigerian naira"
  },
  "NIO": {
    "Code": "NIO",
    "Num": "558",
    "D": "2",
    "Currency": "Nicaraguan córdoba"
  },
  "NOK": {
    "Code": "NOK",
    "Num": "578",
    "D": "2",
    "Currency": "Norwegian krone"
  },
  "NPR": {
    "Code": "NPR",
    "Num": "524",
    "D": "2",
    "Currency": "Nepalese rupee"
  },
  "NZD": {
    "Code": "NZD",
    "Num": "554",
    "D": "2",
    "Currency": "New Zealand dollar"
  },
  "OMR": {
    "Code": "OMR",
    "Num": "512",
    "D": "3",
    "Currency": "Omani rial"
  },
  "PAB": {
    "Code": "PAB",
    "Num": "590",
    "D": "2",
    "Currency": "Panamanian balboa"
  },
  "PEN": {
    "Code": "PEN",
    "Num": "604",
    "D": "2",
    "Currency": "Peruvian sol"
  },
  "PGK": {
    "Code": "PGK",
    "Num": "598",
    "D": "2",
    "Currency": "Papua New Guinean kina"
  },
  "PHP": {
    "Code": "PHP",
    "Num": "608",
    "D": "2",
    "Currency": "Philippine peso[13]"
  },
  "PKR": {
    "Code": "PKR",
    "Num": "586",
    "D": "2",
    "Currency": "Pakistani rupee"
  },
  "PLN": {
    "Code": "PLN",
    "Num": "985",
    "D": "2",
    "Currency": "Polish złoty"
  },
  "PYG": {
    "Code": "PYG",
    "Num": "600",
    "D": "0",
    "Currency": "Paraguayan guaraní"
  },
  "QAR": {
    "Code": "QAR",
    "Num": "634",
    "D": "2",
    "Currency": "Qatari riyal"
  },
  "RON": {
    "Code": "RON",
    "Num": "946",
    "D": "2",
    "Currency": "Romanian leu"
  },
  "RSD": {
    "Code": "RSD",
    "Num": "941",
    "D": "2",
    "Currency": "Serbian dinar"
  },
  "RUB": {
    "Code": "RUB",
    "Num": "643",
    "D": "2",
    "Currency": "Russian ruble"
  },
  "RWF": {
    "Code": "RWF",
    "Num": "646",
    "D": "0",
    "Currency": "Rwandan franc"
  },
  "SAR": {
    "Code": "SAR",
    "Num": "682",
    "D": "2",
    "Currency": "Saudi riyal"
  },
  "SBD": {
    "Code": "SBD",
    "Num": "090",
    "D": "2",
    "Currency": "Solomon Islands dollar"
  },
  "SCR": {
    "Code": "SCR",
    "Num": "690",
    "D": "2",
    "Currency": "Seychelles rupee"
  },
  "SDG": {
    "Code": "SDG",
    "Num": "938",
    "D": "2",
    "Currency": "Sudanese pound"
  },
  "SEK": {
    "Code": "SEK",
    "Num": "752",
    "D": "2",
    "Currency": "Swedish krona (plural: kronor)"
  },
  "SGD": {
    "Code": "SGD",
    "Num": "702",
    "D": "2",
    "Currency": "Singapore dollar"
  },
  "SHP": {
    "Code": "SHP",
    "Num": "654",
    "D": "2",
    "Currency": "Saint Helena pound"
  },
  "SLL": {
    "Code": "SLL",
    "Num": "694",
    "D": "2",
    "Currency": "Sierra Leonean leone"
  },
  "SOS": {
    "Code": "SOS",
    "Num": "706",
    "D": "2",
    "Currency": "Somali shilling"
  },
  "SRD": {
    "Code": "SRD",
    "Num": "968",
    "D": "2",
    "Currency": "Surinamese dollar"
  },
  "SSP": {
    "Code": "SSP",
    "Num": "728",
    "D": "2",
    "Currency": "South Sudanese pound"
  },
  "STN": {
    "Code": "STN",
    "Num": "930",
    "D": "2",
    "Currency": "São Tomé and Príncipe dobra"
  },
  "SVC": {
    "Code": "SVC",
    "Num": "222",
    "D": "2",
    "Currency": "Salvadoran colón"
  },
  "SYP": {
    "Code": "SYP",
    "Num": "760",
    "D": "2",
    "Currency": "Syrian pound"
  },
  "SZL": {
    "Code": "SZL",
    "Num": "748",
    "D": "2",
    "Currency": "Swazi lilangeni"
  },
  "THB": {
    "Code": "THB",
    "Num": "764",
    "D": "2",
    "Currency": "Thai baht"
  },
  "TJS": {
    "Code": "TJS",
    "Num": "972",
    "D": "2",
    "Currency": "Tajikistani somoni"
  },
  "TMT": {
    "Code": "TMT",
    "Num": "934",
    "D": "2",
    "Currency": "Turkmenistan manat"
  },
  "TND": {
    "Code": "TND",
    "Num": "788",
    "D": "3",
    "Currency": "Tunisian dinar"
  },
  "TOP": {
    "Code": "TOP",
    "Num": "776",
    "D": "2",
    "Currency": "Tongan paʻanga"
  },
  "TRY": {
    "Code": "TRY",
    "Num": "949",
    "D": "2",
    "Currency": "Turkish lira"
  },
  "TTD": {
    "Code": "TTD",
    "Num": "780",
    "D": "2",
    "Currency": "Trinidad and Tobago dollar"
  },
  "TWD": {
    "Code": "TWD",
    "Num": "901",
    "D": "2",
    "Currency": "New Taiwan dollar"
  },
  "TZS": {
    "Code": "TZS",
    "Num": "834",
    "D": "2",
    "Currency": "Tanzanian shilling"
  },
  "UAH": {
    "Code": "UAH",
    "Num": "980",
    "D": "2",
    "Currency": "Ukrainian hryvnia"
  },
  "UGX": {
    "Code": "UGX",
    "Num": "800",
    "D": "0",
    "Currency": "Ugandan shilling"
  },
  "USD": {
    "Code": "USD",
    "Num": "840",
    "D": "2",
    "Currency": "United States dollar"
  },
  "USN": {
    "Code": "USN",
    "Num": "997",
    "D": "2",
    "Currency": "United States dollar (next day) (funds code)"
  },
  "UYI": {
    "Code": "UYI",
    "Num": "940",
    "D": "0",
    "Currency": "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)"
  },
  "UYU": {
    "Code": "UYU",
    "Num": "858",
    "D": "2",
    "Currency": "Uruguayan peso"
  },
  "UYW": {
    "Code": "UYW",
    "Num": "927",
    "D": "4",
    "Currency": "Unidad previsional[15]"
  },
  "UZS": {
    "Code": "UZS",
    "Num": "860",
    "D": "2",
    "Currency": "Uzbekistan som"
  },
  "VES": {
    "Code": "VES",
    "Num": "928",
    "D": "2",
    "Currency": "Venezuelan bolívar soberano[13]"
  },
  "VND": {
    "Code": "VND",
    "Num": "704",
    "D": "0",
    "Currency": "Vietnamese đồng"
  },
  "VUV": {
    "Code": "VUV",
    "Num": "548",
    "D": "0",
    "Currency": "Vanuatu vatu"
  },
  "WST": {
    "Code": "WST",
    "Num": "882",
    "D": "2",
    "Currency": "Samoan tala"
  },
  "XAF": {
    "Code": "XAF",
    "Num": "950",
    "D": "0",
    "Currency": "CFA franc BEAC"
  },
  "XAG": {
    "Code": "XAG",
    "Num": "961",
    "D": ".",
    "Currency": "Silver (one troy ounce)"
  },
  "XAU": {
    "Code": "XAU",
    "Num": "959",
    "D": ".",
    "Currency": "Gold (one troy ounce)"
  },
  "XBA": {
    "Code": "XBA",
    "Num": "955",
    "D": ".",
    "Currency": "European Composite Unit (EURCO) (bond market unit)"
  },
  "XBB": {
    "Code": "XBB",
    "Num": "956",
    "D": ".",
    "Currency": "European Monetary Unit (E.M.U.-6) (bond market unit)"
  },
  "XBC": {
    "Code": "XBC",
    "Num": "957",
    "D": ".",
    "Currency": "European Unit of Account 9 (E.U.A.-9) (bond market unit)"
  },
  "XBD": {
    "Code": "XBD",
    "Num": "958",
    "D": ".",
    "Currency": "European Unit of Account 17 (E.U.A.-17) (bond market unit)"
  },
  "XCD": {
    "Code": "XCD",
    "Num": "951",
    "D": "2",
    "Currency": "East Caribbean dollar"
  },
  "XDR": {
    "Code": "XDR",
    "Num": "960",
    "D": ".",
    "Currency": "Special drawing rights"
  },
  "XOF": {
    "Code": "XOF",
    "Num": "952",
    "D": "0",
    "Currency": "CFA franc BCEAO"
  },
  "XPD": {
    "Code": "XPD",
    "Num": "964",
    "D": ".",
    "Currency": "Palladium (one troy ounce)"
  },
  "XPF": {
    "Code": "XPF",
    "Num": "953",
    "D": "0",
    "Currency": "CFP franc (franc Pacifique)"
  },
  "XPT": {
    "Code": "XPT",
    "Num": "962",
    "D": ".",
    "Currency": "Platinum (one troy ounce)"
  },
  "XSU": {
    "Code": "XSU",
    "Num": "994",
    "D": ".",
    "Currency": "SUCRE"
  },
  "XTS": {
    "Code": "XTS",
    "Num": "963",
    "D": ".",
    "Currency": "Code reserved for testing"
  },
  "XUA": {
    "Code": "XUA",
    "Num": "965",
    "D": ".",
    "Currency": "ADB Unit of Account"
  },
  "XXX": {
    "Code": "XXX",
    "Num": "999",
    "D": ".",
    "Currency": "No currency "
  },
  "YER": {
    "Code": "YER",
    "Num": "886",
    "D": "2",
    "Currency": "Yemeni rial"
  },
  "ZAR": {
    "Code": "ZAR",
    "Num": "710",
    "D": "2",
    "Currency": "South African rand"
  },
  "ZMW": {
    "Code": "ZMW",
    "Num": "967",
    "D": "2",
    "Currency": "Zambian kwacha"
  },
  "ZWL": {
    "Code": "ZWL",
    "Num": "932",
    "D": "2",
    "Currency": "Zimbabwean dollar"
  }
}