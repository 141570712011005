import { actions } from '../constants'

const { TIERING } = actions

const initialState = {
    messages: null,
    error: null,
    visible: false,
    modal: null,
    loading: false,
    limits: null,
    builderLimits: null,
    isAddOn: false,
    component: null,
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case TIERING.SHOW: {
            return {
                ...state, 
                visible: (action.payload.visible || action.payload) ?? false,
                isAddOn: action.payload?.isAddOn ?? false,
                component: action.payload?.component ?? null,
            }
        }
        case TIERING.SHOW_MINI: {
            return {
                ...state, 
                modal: {
                    show: action.payload.show ?? false,
                    header: action.payload.header ?? null,
                    message: action.payload.message ?? null,
                    component: action.payload.component ?? '',
                },
            }
        }
        case TIERING.START: {
            return {
                ...state, 
                loading: true,
            }
        }
        case TIERING.MESSAGES: {
            return {
                ...state, 
                loading: false,
                messages: action.payload,
            }
        }
        case TIERING.CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null, loading: false, isAddOn: false, component: null}
        }
        case TIERING.SAVE_LIMITS: {
            return {
                ...state, 
                limits: action.payload,
                loading: false,
            }
        }
        case TIERING.SAVE_BUILDER_LIMITS: {
            return {
                ...state, 
                builderLimits: action.payload,
                loading: false,
            }
        }
        case TIERING.ERROR: {
            let error = action.payload.response
            error = error?.data?.message ?? "error.errorOccurred"

            return { 
                ...state, 
                // visible: true, 
                errors: error, 
                messages: null ,
                loading: false,
            }
        }
        case TIERING.RESET: {
            return {
                ...state,
                // visible: false,
                error: null,
                limits: null,
                messages: null,
                builderLimits: null,
                isAddOn: false,
                component: null,
            }
        }
    }

    return state
}