import React, { useState, Fragment, useEffect } from 'react'

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg"
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { removeInlineStyles, RichTextCleaner } from './RichTextCleaner'
import { isLngSupported, convertMarkup, isHTML } from '../../../utils/helper'
import { CustomEmojiPicker } from './CustomEmojiPicker'
import { find } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'semantic-ui-react'
import { messageBuilder } from '../../../constants'

export default function EventNotesRichTextEditor({ content, jsonContent, mediaType, onChange, label, placeholder }) {
    
    const defaultContentState = {
        "blocks": [
            {
                "key": "637gr",
                "text": "",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
            }
        ],
        "entityMap": {}
    }

    const [data, setData] = useState({
        content: content ?? "",
        jsonContent: jsonContent ?? defaultContentState,
        mediaType:  mediaType ?? messageBuilder.MARKUP.plain
    })

    const { i18n } = useTranslation()

    const checkLngSupport = isLngSupported(i18n.language)
    const isSupported = checkLngSupport.isValid
    const defaultLng = checkLngSupport.defaultLng

    const useAbleLocale = isSupported ? i18next.language : defaultLng

    const contentBlock = convertFromRaw(jsonContent ?? defaultContentState)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentBlock))

    const onEditorChange = _editorState => {

        const content = convertToRaw(_editorState.getCurrentContent())

        setEditorState(_editorState)

        // We will be trying to determine if we have plain text or HTML content on the fly as updates or changes are made.
        const isRichText = isHTML(_editorState)

        setData((prevData) => ({
            ...prevData,
            mediaType: isRichText ? messageBuilder.MARKUP.html : messageBuilder.MARKUP.plain,
            content: convertMarkup({
                type: isRichText ? messageBuilder.MARKUP.html : messageBuilder.MARKUP.plain,
                rawContents: content,
                convertedMD: ''
            }),
            jsonContent: content,
        }))
    }

    const dispatch = useDispatch()
    
    useEffect(() => {
        // Trigger manual update of render type
        // _handleOptionChange(data.mediaType)
        onEditorChange(editorState)
    }, [])
    
    useEffect(() => {
        
        // Send Back updates to our callback
        if (onChange) {
            onChange(data.content, data.jsonContent, data.mediaType)
        }

    }, [data.content, data.mediaType])

    const _handleOptionChange = (value) => {
        setData((prevData) => ({
            ...prevData,
            mediaType: value
        }))
    }
    
    return (
        <Fragment>
            {label && <p className='label'>{label}</p>}
            <Editor
                spellCheck
                stripPastedStyles
                editorState={editorState}
                onEditorStateChange={onEditorChange}
                placeholder={data.content ? "" : i18next.t("components.morphed.text.placeholder")}
                toolbarCustomButtons={[
                    <RichTextCleaner key={0} isEventNotes />, 
                    <CustomEmojiPicker key={1} />, 
                    // <TypeDropdown key={3} selected={data.mediaType} onSelectionChange={_handleOptionChange} />
                ].filter(Boolean)}
                localization={{
                    locale: useAbleLocale,
                    translations: i18next.getResource(useAbleLocale, "editor")
                }}
                // @TODO: Translate editor for all languages
                toolbar={{
                    options: ['blockType', 'list', 'inline', 'link', 'history'],
                    inline: { inDropdown: false, options: ['bold', 'italic', 'strikethrough'] },
                    list: { inDropdown: true },
                    link: { inDropdown: true },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    },
                }}
                plugins={[]}
            />
        </Fragment>
    )
}