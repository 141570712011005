import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, List, Grid, Icon, Image, Modal, Segment } from 'semantic-ui-react'
import { ListView, MessageView } from '../message-builder/preview'
import moment from "moment"
import { findTextComponent, findTextComponentDescription, getTasksFromResponse } from '../../utils/helper'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { AppPreview } from './AppPreview'
import { isEmpty, map } from 'lodash'
import { MdArrowBack, MdCamera, MdCameraAlt, MdMoreVert, MdSend } from 'react-icons/md'
import logoPlaceholder from '../../images/image_placeholder.png'

const propTypes = {
    data: PropTypes.object,
    previewRef: PropTypes.any,
}

const defaultProps = {
    data: {},
    onShowFiles: undefined
}

export const RenderListView = ({ listItem, contents, classNames = 'preview-modal' }) => {
    return (
        <ListView
            items={[listItem]}
            classNames={classNames}
            contents={map(contents)} />
    )
}

// export const RenderWhatsAppView = ({contents}) => {
//     return (
//         <div className='message-view no-padding no-margin'>
//             <div className="header">
//                 <Grid className='no-margin'>
//                     <Grid.Row columns={3} className="vertical-align">
//                         <Grid.Column width={2}>
//                             <Icon name="arrow left" className="icon"/>
//                         </Grid.Column>
//                         <Grid.Column width={3}>
//                             <Image rounded src={contents.chat_logo}/>
//                         </Grid.Column>
//                         <Grid.Column width={11}>
//                             <h3 className='item-title'>{contents.chat_phone_number}</h3>
//                         </Grid.Column>
//                     </Grid.Row>
//                 </Grid>
//             </div>
//             <List className="m-content preview-list">
//                 <p className="from-them">
//                     <h6>{contents.header_text}</h6>
//                     <span>{contents.body_text}</span>
//                 </p>
//             </List>
//         </div>
//     )
// }

export const RenderWhatsAppView = ({ contents }) => {
    return (
        <div className="page">
            <div className="marvel-device">
                <div className="top-bar" />
                <div className="sleep" />
                <div className="volume" />
                <div className="camera" />
                <div className="screen">
                    <div className="screen-container">
                        <div className="status-bar">
                            <div className="time" />
                            <div className="battery">
                                <i className="zmdi zmdi-battery" />
                            </div>
                            <div className="network">
                                <i className="zmdi zmdi-network" />
                            </div>
                            <div className="wifi">
                                <i className="zmdi zmdi-wifi-alt-2" />
                            </div>
                            <div className="star">
                                <i className="zmdi zmdi-star" />
                            </div>
                        </div>
                        <div className="chat">
                            <div className="chat-container">
                                <div className="user-bar">
                                    <div className="back">
                                        <MdArrowBack />
                                    </div>
                                    <div className="avatar">
                                        <img src={contents.chat_logo ?? logoPlaceholder} alt="Avatar" />
                                    </div>
                                    <div className="name">
                                        <span>{contents.chat_phone_number}</span>
                                        <span className="status">{contents.online_text}</span>
                                    </div>
                                    <div className="actions more">
                                        <MdMoreVert />
                                    </div>
                                </div>
                                <div className="conversation">
                                    <div className="conversation-container">
                                        <div className="message notice">
                                            <Icon name='info circle' />{contents.disclaimer}
                                        </div>
                                        <div className="message received">
                                            <span id="random">
                                                <strong>{contents.header_text}</strong><br/><br/>
                                                {contents.body_text}
                                            </span>
                                            <span className="metadata">
                                                <span className="sent-from">{contents.sender}</span>
                                                <span className="time">{contents.time_received}</span>
                                            </span>
                                            {!isEmpty(contents.actions) && (
                                                <span className="metadata-actions">
                                                    {map(contents.actions ?? [], (action) => (
                                                        <a>{action.icon}&nbsp;{action.title}</a>
                                                    ))}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <form className="conversation-compose">
                                        <div className="emoji">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} id="smiley" x={3147} y={3209}><path fillRule="evenodd" clipRule="evenodd" d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489" /></svg>
                                        </div>
                                        <input className="input-msg" name="input" placeholder="Type a message" autoComplete="off" autoFocus />
                                        <div className="photo">
                                            <MdCameraAlt/>
                                        </div>
                                        <button className="send">
                                            <div className="circle">
                                                <MdSend />
                                            </div>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PreviewModalView = (props) => {

    const {
        data,
        onShowFiles,
    } = props

    const { t } = useTranslation()

    const openNewTab = (link) => {
        window.open(link, "_blank")
    }

    const renderView = () => {

        const platform = data.platform
        const content = data.content[platform]

        if (!content) {
            return { size: "tiny", content: "" }
        }

        switch (platform) {
            case "sms":
                return {
                    size: "large",
                    content: (
                        <div className="no-select-disabled">
                            <h5 className='black-text'><i>{i18next.t("notices.detail.preview.pleaseNote")}</i></h5>
                            <AppPreview phoneClass='sms-preview' contentClass='speech-bubble' content={renderSMS(content)} />
                        </div>
                    )
                }
            case "whatsapp":
                return {
                    size: "large",
                    content: (
                        <div className="no-select-disabled">
                            <h5 className='black-text'><i>{i18next.t("notices.detail.preview.pleaseNote")}</i></h5>
                            <AppPreview
                                phoneClass='whatsapp-preview'
                                contentClass='speech-bubble'
                                content={
                                    <RenderWhatsAppView
                                        contents={content} />
                                } />
                        </div>
                    )
                }
            case "email":
                return {
                    size: "large",
                    content: (
                        <div>
                            <h5 className='black-text'><i>{i18next.t("notices.detail.preview.pleaseNote")}</i></h5>
                            {renderEmail(content)}
                        </div>
                    )
                }
            case "app": {

                const tasks = getTasksFromResponse({ response: content })
                const description = findTextComponentDescription(tasks)
                const textComponent = findTextComponent(tasks)

                const listItem = {
                    title: content.title,
                    pinItem: content.pin_item,
                    important: content.important,
                    icon: content.community_image,
                    image: content.community_image,
                    communityName: content.community_name,
                    description: description,
                    hasText: textComponent !== null,
                    createdAt: content.created_at ? moment(content.created_at) : moment()
                }

                const renderContents = (
                    <div>
                        <h5 className='black-text'><i>{i18next.t("notices.detail.preview.pleaseNote")}</i></h5>
                        {onShowFiles && (
                            <Button
                                onClick={onShowFiles}
                                size='tiny'
                                className='secondary'>
                                <Icon name='eye' />{t("notices.detail.preview.all-files")}
                            </Button>
                        )}
                        <Grid columns="2">
                            <Grid.Column>
                                {/* {renderListView({contents: tasks, listItem})} */}
                                <RenderListView
                                    contents={tasks}
                                    listItem={listItem} />
                            </Grid.Column>
                            <Grid.Column>
                                <AppPreview content={renderMessageView({ items: tasks, listItem })} />
                            </Grid.Column>
                        </Grid>
                    </div>
                )

                return { content: renderContents }
            }
            case "localApp": {

                const tasks = content.items
                const listItem = content.listItem

                const renderContents = (
                    <div>
                        <h5 className='black-text'><i>{i18next.t("notices.detail.preview.pleaseNote")}</i></h5>
                        {onShowFiles && (
                            <Button 
                                onClick={onShowFiles}
                                size='tiny'
                                className='secondary'>
                                <Icon name='eye' />{t("notices.detail.preview.all-files")}
                            </Button>
                        )}
                        <Grid columns="2">
                            <Grid.Column>
                                {/* {renderListView({contents: tasks, listItem})} */}
                                <RenderListView
                                    contents={tasks}
                                    listItem={listItem} />
                            </Grid.Column>
                            <Grid.Column>
                                <AppPreview 
                                    content={renderMessageView({
                                        items: tasks, 
                                        listItem
                                    })} />
                            </Grid.Column>
                        </Grid>
                    </div>
                )

                return { content: renderContents}
            }
            default: {
                return { size: "tiny", content: "" }
            }

        }
    }

    const renderSMS = (content) => (<p className="from-them">{content}</p>)

    const renderEmail = (content) => {

        const html = encodeURIComponent(content)

        return (
            <iframe
                src={`data:text/html,<style>img,a{user-select:none;pointer-events:none;}</style>${html}`}
                title="Email Preview"
                style={{
                    padding: 20,
                    minHeight: 500,
                    width: "100%"
                }}
                frameBorder="none" />
        )
    }

    const renderMessageView = ({ listItem, items }) => {

        return (
            <MessageView
                className="no-padding no-margin"
                items={items}
                listItem={listItem} />
        )
    }

    const preview = renderView()

    return (
        <Segment inverted className='preview-bg'>
            {preview.content}
        </Segment>
    )
}

PreviewModalView.propTypes = propTypes
PreviewModalView.defaultProps = defaultProps

export default PreviewModalView