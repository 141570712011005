import React, { useState, useEffect } from 'react'

import BaseMorphed from './BaseMorphed'
import { Input, Grid } from 'semantic-ui-react'
import { builderActions } from '../../../actions/builderActions'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function DeadlineMorphed({item}) {
    
    const [data, setData] = useState({
        date: item.data ? item.data.date : moment().format("Y-M-D"),
        time: item.data ? item.data.time : moment().format("HH:mm"),
        originalId: item?.data?.originalId
    })

    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
        dispatch(builderActions.saveTemplateData({
            item, 
            data: {
                ...data,
                previewText: `${data.date} ${data.time}`
            }
        }))
        return () => {}
    }, [data, dispatch])

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setData({...data, [name]: value})
    }

    return (
        <BaseMorphed item={item} render={( ) => (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <label className='label'>{t('messageBuilder.morphedTemplates.DeadlineMorphed.deadline')}</label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.DeadlineMorphed.date')}</label>
                            <Input 
                                value={data.date} 
                                name="date"
                                type="date"
                                onChange={handleInputChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.DeadlineMorphed.date')} />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div className="input-container theme-color">
                            <label className='label'>{t('messageBuilder.morphedTemplates.DeadlineMorphed.time')}</label>
                            <Input 
                                value={data.time} 
                                name="time"
                                type="time"
                                onChange={handleInputChange} 
                                autoComplete="off" 
                                placeholder={t('messageBuilder.morphedTemplates.DeadlineMorphed.time')} />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )} />
    )
}
