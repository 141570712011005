import { identity, isEmpty, isNil, pickBy } from "lodash"

class ListNoticesRequest {

    constructor({
        sort, 
        page,
        search,
        perPage,
        statuses,
        channelIds,
        communityId,
        targetEveryone,

    }) {
        this.page = page
        this.sort = sort
        this.search = search
        this.perPage = perPage
        this.statuses = statuses
        this.channelIds = channelIds
        this.communityId = communityId
        this.targetEveryone = targetEveryone
    }


    getPayload = () => {
        const payload = {
            "sort": this.sort,
            "search": this.search,
            "per_page": this.perPage.value,
            "page": this.page,
            "community_id": this.communityId,
        }

        if (!isEmpty(this.channelIds)) {
            payload["channel_ids"] = this.channelIds
        }
        
        if (!isNil(this.targetEveryone)) {
            payload["target_everyone"] = this.targetEveryone
        }

        if (!isEmpty(this.statuses)) {
            payload["statuses"] = [].concat(this.statuses).join(",")
        }

        return pickBy(payload, identity)
    }
    
}

export default ListNoticesRequest