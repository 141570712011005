import { omit } from "lodash"
import {
    CustomRequest,
    ListCalendarsRequest,
    GenericListRequest,
    PostCreateCalendarRequest,
    PostCreateSyncAccountRequest,
    PostCreateEventRequest,
    PostUpdateCalendarRequest,
    PostCreateICalCalendarRequest,
} from "../api/requestObjects"

import ApiService from "./ApiService"
import { dropEmptyKeys } from "../utils/helper"

class CalendarApiService extends ApiService {


    /**
     * Create a new google sync account
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateGoogleSyncAccount = (params) => {

        const { POST_CREATE_GOOGLE_SYNC_ACCOUNT } = this.endpoints
        const request = new PostCreateSyncAccountRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CREATE_GOOGLE_SYNC_ACCOUNT,
        }), request.getPayload())
    }

    /**
     * Create a new microsoft sync account
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateMicrosoftSyncAccount = (params) => {

        const { POST_CREATE_MICROSOFT_SYNC_ACCOUNT } = this.endpoints
        const request = new PostCreateSyncAccountRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CREATE_MICROSOFT_SYNC_ACCOUNT,
        }), request.getPayload())
    }
    
    /**
     * Re-Authenticate an existing sync account
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postReAuthSyncAccount = (params) => {

        const { POST_RE_AUTHORISE_CALENDAR_SYNC_ACCOUNT } = this.endpoints
        const request = new PostCreateSyncAccountRequest(params)

        return this.API.put(this.getEndpoint({
            path: POST_RE_AUTHORISE_CALENDAR_SYNC_ACCOUNT,
            data: params.id,
        }), request.getPayload())
    }
    
    /**
     * Create a new calendar
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateCalendar = (params) => {

        const { POST_LINK_CALENDAR_FEED } = this.endpoints
        const request = new PostCreateCalendarRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_LINK_CALENDAR_FEED,
        }), request.getPayload())
    }

    /**
     * Confirm groups removal from events upon removing groups on Calendar
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postChannelsUpdateConfirmation = (params) => {

        const { POST_GET_REMOVE_CHANNELS_CONFIRMATION } = this.endpoints
        const request = new PostUpdateCalendarRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_GET_REMOVE_CHANNELS_CONFIRMATION,
            data: params.calendarId
        }), request.getPayload())
    }
    
    /**
     * Update an existing calendar
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    putUpdateCalendar = (params) => {

        const { PUT_UPDATE_CALENDAR } = this.endpoints
        const request = new PostUpdateCalendarRequest(params)

        return this.API.put(this.getEndpoint({
            path: PUT_UPDATE_CALENDAR,
            data: params.calendarId
        }), request.getPayload())
    }

    /**
     * Create a new iCal calendar
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateICalCalendar = (params) => {

        const { POST_LINK_CALENDAR_ICAL_FEED } = this.endpoints
        const request = new PostCreateICalCalendarRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_LINK_CALENDAR_ICAL_FEED,
        }), request.getPayload())
    }
    
    /**
     * Check if an iCal calendar already exists
     * 
     * @param {JSON} payload
     * @returns {Promise}
     */
    checkICalCalendarExists = (payload) => {

        const { POST_CHECK_ICAL_FEED_EXISTS } = this.endpoints
        return this.API.post(this.getEndpoint({
            path: POST_CHECK_ICAL_FEED_EXISTS,
        }), payload)
    }

    /**
     * Update an existing calendar
     * ?NOTE: Validated from UI
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    putUpdateICalCalendar = (params) => {
        // @TODO: Implement update
        throw new Error("@TODO: Method [putUpdateICalCalendar] not implemented.")
    }

    /**
     * Google Authorization
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    fetchGoogleAuth = (params) => {

        const { POST_CALENDARS_GOOGLE_AUTH } = this.endpoints
        const request = new CustomRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CALENDARS_GOOGLE_AUTH,
        }), request.getPayload())
    }

    /**
     * Microsoft Authorization
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    fetchMicrosoftAuth = (params) => {

        const { POST_CALENDARS_MICROSOFT_AUTH } = this.endpoints
        const request = new CustomRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CALENDARS_MICROSOFT_AUTH,
        }), request.getPayload())
    }
    
    /**
     * Fetch calendar sync accounts for this community
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getSyncAccounts = (params) => {

        const { GET_CALENDAR_SYNC_ACCOUNTS } = this.endpoints
        const request = new GenericListRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_SYNC_ACCOUNTS,
            params: request.getPayload()
        }))
    }
    
    /**
     * Initiates an asynchronous re-sync of events from relay-calendars to relay-api, optionally forcing a re-sync from SOURCE to relay-calendars.
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
     doForcedSync = (params) => {

        const { POST_CALENDAR_RESYNC } = this.endpoints
        const calendarId = params.calendarId

        params = omit(params, ['calendarId'])
        const request = new CustomRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_CALENDAR_RESYNC,
            data: calendarId,
        }), request.getPayload())
    }
    
    /**
     * Initiates an asynchronous re-sync of events from relay-calendars to relay-api, optionally forcing a re-sync from SOURCE to relay-calendars.
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
     doSyncAll = (params) => {

        const { POST_SYNC_ALL } = this.endpoints

        const request = new CustomRequest(params)

        return this.API.post(this.getEndpoint({
            path: POST_SYNC_ALL,
        }), request.getPayload())
    }
    
    /**
     * Fetch all the events linked to this calendar-feed
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getCalendarEvents = (params) => {

        const { GET_CALENDAR_EVENTS } = this.endpoints
        const calendarId = params.calendarId
        
        params = omit(params, ['calendarId'])
        
        const request = new GenericListRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_EVENTS,
            data: calendarId,
            params: request.getPayload()
        }))
    }
    
    /**
     * Create a new Event linked to this calendar-feed
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    postCreateCalendarEvent = (params) => {

        const { POST_CREATE_EVENT } = this.endpoints
        
        const request = new PostCreateEventRequest(params)
        return this.API.post(this.getEndpoint({
            path: POST_CREATE_EVENT,
            data: request.calendarId,
        }), request.getPayload())
    }
    
    /**
     * Fetch calendar sources for a specific sync account
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
     getCalendarSyncSources = (params) => {

        const { GET_CALENDAR_SOURCES } = this.endpoints
        const request = new GenericListRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_SOURCES,
            data: params.syncAccountId,
            params: request.getPayload()
        }))
    }
    
    /**
     * Fetch calendars
     * 
     * @param {JSON} params
     * @returns {Promise}
     */
    getCalendars = (params) => {

        const { GET_CALENDARS } = this.endpoints
        const request = new ListCalendarsRequest(params)

        return this.API.get(this.getEndpoint({
            path: GET_CALENDARS,
            params: request.getPayload()
        }))
    }

    /**
     * Fetch filters for community calendar management screen
     * 
     * @param {JSON} params
     * @return {Promise}
     */
     getCalendarFilters = (params) => {

        const { GET_CALENDAR_FILTERS } = this.endpoints
        const payload = new CustomRequest({ community_id: params.communityId, calendar_id: params.calendarId })

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_FILTERS,
            params: payload.getPayload()
        }))
    }
    
    /**
     * Fetch a specific calendar by its unique ID
     * 
     * @param {Object} params
     * @param {String} params.calendarId
     * @returns {Promise}
     */
    getCalendar = (params) => {

        const { GET_CALENDAR } = this.endpoints

        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR,
            data: params.calendarId,
        }))
    }

    /**
     * Delete calendar
     * 
     * @param {Object} params
     * @param {String} params.calendarId
     * @returns {Promise}
     */
    postDeleteCalendar = (params) => {

        const { DELETE_CALENDAR } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: DELETE_CALENDAR,
            data: params.calendarId,
        }))
    }
    
    /**
     * Unlinks calendar
     * 
     * @param {Object} params
     * @param {String} params.calendarId
     * @returns {Promise}
     */
    postUnlinkCalendar = (params) => {

        const { UNLINK_CALENDAR } = this.endpoints

        return this.API.post(this.getEndpoint({
            path: UNLINK_CALENDAR,
            data: params.calendarId,
        }))
    }
    
    /**
     * Delete calendar sync account
     * 
     * @param {Object} params
     * @param {String} params.accountId
     * @returns {Promise}
     */
    postDeleteAccount = (params) => {

        const { DELETE_CALENDAR_ACCOUNT } = this.endpoints

        return this.API.delete(this.getEndpoint({
            path: DELETE_CALENDAR_ACCOUNT,
            data: params.accountId,
        }))
    }
    
    /**
     * Determine if there has been any calendar updates since last checked.
     * 
     * @param {Object} params
     * @param {String} params.calendarId
     * @param {String} params.communityId
     * @param {String} params.lastChecked
     * @returns {Promise}
     */
    checkCalendarStatus = (params) => {

        const { GET_CALENDAR_STATUS } = this.endpoints
        
        const payload = dropEmptyKeys({
            last_checked_at: params.lastChecked,
            calendar_feed_id: params.calendarId ?? null,
        })
        
        return this.API.get(this.getEndpoint({
            path: GET_CALENDAR_STATUS,
            data: params.communityId,
            params: payload
        }))
    }
}

export default CalendarApiService