import React, { Suspense } from 'react'
import {
    Switch,
    Route,
    Redirect
} from 'react-router'
import routes from './route-constants'
import ComingSoon from '../views/ComingSoon'
import Blocked from '../views/Blocked'

import MessageBuilderRedirector from '../views/MessageBuilderRedirector'
import { getParameterByName } from '../utils/helper'
import { calendarConstants, constants } from '../constants'
import SuspenseFallback from '../views/SuspenseFallback'

const Calendar = React.lazy(() => import('../views/calendar/Calendar'))
const ManageCalendars = React.lazy(() => import('../views/calendar/ManageCalendars'))
const ProfileDetails = React.lazy(() => import('../views/profile/ProfileDetails'))
const RecipientDetails = React.lazy(() => import('../views/notices/RecipientDetails'))
const Account = React.lazy(() => import('../views/Account'))
const ManageContacts = React.lazy(() => import('../views/settings/ManageContacts'))
const MessagePublishSettings = React.lazy(() => import('../views/settings/MessagePublishSettings'))
const ContactDetail = React.lazy(() => import('../views/settings/contact/ContactDetail'))
const CategoryDetail = React.lazy(() => import('../views/settings/category/CategoryDetail'))
const UpdateEmail = React.lazy(() => import('../views/profile/UpdateEmail'))
const UpdatePhone = React.lazy(() => import('../views/profile/UpdatePhone'))
const OtpScreen = React.lazy(() => import('../views/profile/OtpScreen'))
const EditMessageRedirector = React.lazy(() => import('../views/EditMessageRedirector'))
const CalendarDetail = React.lazy(() => import('../views/calendar/CalendarDetail'))
const ManageAccounts = React.lazy(() => import('../views/calendar/ManageAccounts'))
const CustomMessageRedirector = React.lazy(() => import('../views/CustomMessageRedirector'))
const EventDetail = React.lazy(() => import('../views/calendar/events/EventDetail'))
const LogoutRedirect = React.lazy(() => import('../views/LogoutRedirect'))

const GoogleRedirectCallback = React.lazy(() => import('../views/GoogleRedirectCallback'))
const LinkCalendarChooser = React.lazy(() => import('../views/calendar/LinkCalendarChooser'))
const ReAuthCalendar = React.lazy(() => import('../views/calendar/ReAuthCalendar'))
const MicrosoftRedirectCallback = React.lazy(() => import('../views/MicrosoftRedirectCallback'))

const MessageBuilder = React.lazy(() => import('../views/MessageBuilder'))
const ManageUsers = React.lazy(() => import('../views/system-users/ManageUsers'))
const UserDetails = React.lazy(() => import('../views/system-users/UserDetails'))
const ManageInvites = React.lazy(() => import('../views/system-users/ManageInvites'))
const InviteUser = React.lazy(() => import('../views/system-users/InviteUser'))
const MemberDetails = React.lazy(() => import('../views/community-members/MemberDetails'))
const ManageMembers = React.lazy(() => import('../views/community-members/ManageMembers'))
const ImportMembers = React.lazy(() => import('../views/community-members/ImportMembers'))
const ChannelDetails = React.lazy(() => import('../views/channels/ChannelDetails'))
const ManageChannels = React.lazy(() => import('../views/channels/ManageChannels'))
const CreateChannel = React.lazy(() => import('../views/channels/CreateChannel'))
const ManageNotices = React.lazy(() => import('../views/notices/ManageNotices'))
const NoticeDetails = React.lazy(() => import('../views/notices/NoticeDetails'))

export default function AuthenticatedRoutes() {
    return (
        <Suspense fallback={<SuspenseFallback />}>
            <Switch>
                <Route
                    path={routes.private.HOME}
                    exact={true}
                    component={ComingSoon} />

                <Route
                    path={routes.private.BLOCKED}
                    exact={true}
                    component={Blocked} />

                <Route
                    path={routes.private.NOT_ALLOWED}
                    exact={true}
                    component={Blocked} />
                
                <Route
                    path={routes.private.LOGOUT_REDIRECT}
                    exact={true}
                    component={LogoutRedirect} />

                <Route
                    path={routes.private.HOME_COMM}
                    exact={true}
                    component={ComingSoon} />

                <Route
                    path={routes.private.CREATE_NOTICE}
                    exact
                    strict>
                    <MessageBuilder />
                </Route>

                <Route
                    path={routes.private.MANAGE_USERS}
                    exact={true}>
                    <ManageUsers
                        defaults={{
                            homeUrl: routes.private.MANAGE_USERS,
                            page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                            sort: getParameterByName("sort", window.location.href, 'String'),
                            search: getParameterByName("search", window.location.href, 'String'),
                            communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                            channelIds: getParameterByName("channelIds", window.location.href, 'Array') ?? [],
                            channelRoleIds: getParameterByName("channelRoleIds", window.location.href, 'Array') ?? [],
                            communityRoleIds: getParameterByName("communityRoleIds", window.location.href, 'Array') ?? [],
                        }} />
                </Route>

                <Route
                    path={routes.private.MANAGE_MEMBERS}
                    exact={true}>
                    <ManageMembers
                        defaults={{
                            homeUrl: routes.private.MANAGE_MEMBERS,
                            page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                            sort: getParameterByName("sort", window.location.href, 'String'),
                            search: getParameterByName("search", window.location.href, 'String'),
                            tagIds: getParameterByName("tagIds", window.location.href, 'Array') ?? [],
                            communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                            channelIds: getParameterByName("channelIds", window.location.href, 'Array') ?? [],
                            hasApp: getParameterByName("hasApp", window.location.href, 'Number') ?? null,
                            isFollower: getParameterByName("isFollower", window.location.href, 'Number') ?? null,
                        }} />
                </Route>

                <Route
                    path={routes.private.INVITES}
                    exact={true}>
                    <ManageInvites
                        defaults={{
                            homeUrl: routes.private.INVITES,
                            page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                            sort: getParameterByName("sort", window.location.href, 'String'),
                            search: getParameterByName("search", window.location.href, 'String'),
                            communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                            channelIds: getParameterByName("channelIds", window.location.href, 'Array') ?? [],
                            channelRoleIds: getParameterByName("channelRoleIds", window.location.href, 'Array') ?? [],
                            communityRoleIds: getParameterByName("communityRoleIds", window.location.href, 'Array') ?? [],
                        }} />
                </Route>

                <Route
                    path={routes.private.CHANNELS}
                    exact={true}>
                    <ManageChannels
                        defaults={{
                            homeUrl: routes.private.CHANNELS,
                            page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                            sort: getParameterByName("sort", window.location.href, 'String'),
                            search: getParameterByName("search", window.location.href, 'String'),
                            tagIds: getParameterByName("tagIds", window.location.href, 'Array') ?? [],
                            perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                            communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                        }} />
                </Route>

                <Route
                    path={routes.private.INVITE_USER}
                    exact={true}
                    component={InviteUser} />

                <Route
                    path={routes.private.MEMBER_CREATE}
                    exact={true}
                    render={(props) => (
                        <MemberDetails
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            }}
                            isCreate={true}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.CHANNEL_CREATE}
                    exact={true}
                    component={CreateChannel} />

                <Route
                    path={routes.private.MEMBERS_IMPORT}
                    exact={true}
                    component={ImportMembers} />

                <Route
                    path={routes.private.USER_DETAIL}
                    exact={true}
                    render={(props) => (
                        <UserDetails
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.MEMBER_DETAIL}
                    exact={true}
                    render={(props) => (
                        <MemberDetails
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.CHANNEL_DETAIL}
                    exact={true}
                    render={(props) => (
                        <ChannelDetails
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: getParameterByName("communityId", window.location.href, 'String') || null,
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.NOTICE_DETAIL}
                    exact={true}
                    render={(props) => (
                        <NoticeDetails
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null,
                                page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                search: getParameterByName("search", window.location.href, 'String'),
                                tagIds: getParameterByName("tagIds", window.location.href, 'Array') ?? [],
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                channels: getParameterByName("channels", window.location.href, 'Array') ?? [],
                                interactions: getParameterByName("interactions", window.location.href, 'Array') ?? [],
                                relationship: getParameterByName("relationship", window.location.href, 'String') ?? constants.RECIPIENT_GROUP_BYS.GROUP_BY_ROOT_ONLY,
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.NOTICE_RECIPIENT_DETAIL}
                    exact={true}
                    render={(props) => (
                        <RecipientDetails
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.SETTING_ACCOUNT}
                    exact={true}
                    render={(props) => (
                        <Account
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />
                <Route
                    path={routes.private.SETTINGS_MESSAGE_PUBLISHING}
                    exact={true}
                    render={(props) => (
                        <MessagePublishSettings
                            defaults={{
                                homeUrl: routes.private.SETTINGS_MESSAGE_PUBLISHING,
                                page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                search: getParameterByName("search", window.location.href, 'String'),
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />
                <Route
                    path={routes.private.SETTINGS_CONTACT}
                    exact={true}
                    render={(props) => (
                        <ManageContacts
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />
                <Route
                    path={routes.private.MANAGE_NOTICE}
                    exact={true}
                    render={(props) => (
                        <ManageNotices
                            {...props}
                            defaults={{
                                homeUrl: routes.private.MANAGE_NOTICE,
                                page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                search: getParameterByName("search", window.location.href, 'String'),
                                statuses: getParameterByName("statuses", window.location.href, 'Array') ?? [],
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                channelIds: getParameterByName("channelIds", window.location.href, 'Array') ?? [],
                                communityId: props.match.params.communityId ?? null
                            }} />
                    )} />

                <Route
                    path={routes.private.MESSAGE_BUILDER}
                    exact={true}
                    component={ComingSoon} />

                <Route
                    path={routes.private.SMS}
                    exact={true}
                    component={ComingSoon} />

                <Route
                    path={routes.private.EMAIL}
                    exact={true}
                    component={ComingSoon} />

                <Route
                    path={routes.private.CHANNELS}
                    exact={true}
                    component={ComingSoon} />

                <Route
                    path={routes.private.MESSAGE_BUILDER_DYNAMIC}
                    exact={true}>
                    <MessageBuilderRedirector />
                </Route>

                <Route
                    path={routes.private.NOTICE_UPDATE}
                    exact={true}>
                    <EditMessageRedirector />
                </Route>

                <Route
                    path={routes.private.NOTICE_CUSTOM}
                    exact={true}
                    render={(props) => (
                        <CustomMessageRedirector router={props.router} />
                    )} />

                            
                <Route
                    path={routes.private.MANAGE_EVENTS}
                    exact={true}
                    render={(props) => (
                        <Calendar 
                            router={props}
                            defaults={{
                                homeUrl: routes.private.MANAGE_EVENTS,
                                communityId: props.match.params.communityId ?? null,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                search: getParameterByName("search", window.location.href, 'String'),
                                viewType: getParameterByName("viewType", window.location.href, 'Number') ?? calendarConstants.VIEW_TYPES.calendarView.id,
                                endDate: getParameterByName("endDate", window.location.href, 'String'),
                                startDate: getParameterByName("startDate", window.location.href, 'String'),
                                isToday: getParameterByName("isToday", window.location.href, 'Boolean') ?? true,
                                channelIds: getParameterByName("channelIds", window.location.href, 'Array') ?? [],
                            }} />
                    )} />

                <Route
                    path={routes.private.MANAGE_CALENDAR_ACCOUNTS}
                    exact={true}
                    render={(props) => (
                        <ManageAccounts 
                            router={props}
                            defaults={{
                                homeUrl: routes.private.MANAGE_CALENDAR_ACCOUNTS,
                                page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                search: getParameterByName("search", window.location.href, 'String'),
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                communityId: props.match.params.communityId ?? null
                            }} />
                    )} />

                <Route
                    path={routes.private.MANAGE_CALENDARS}
                    exact={true}
                    render={(props) => (
                        <ManageCalendars
                            {...props}
                            router={props}
                            defaults={{
                                homeUrl: routes.private.MANAGE_CALENDARS,
                                page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                search: getParameterByName("search", window.location.href, 'String'),
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                channelIds: getParameterByName("channelIds", window.location.href, 'Array') ?? [],
                                communityId: props.match.params.communityId ?? null
                            }} />
                    )} />

                <Route
                    path={routes.private.CALENDAR_EVENT_EDIT}
                    exact={true}
                    render={(props) => (
                        <EventDetail router={props}/>
                    )} />

                <Route
                    path={routes.private.CALENDAR_EVENT_DETAIL}
                    exact={true}
                    render={(props) => (
                        <EventDetail router={props}/>
                    )} />

                <Route
                    path={routes.private.CALENDAR_DETAIL}
                    exact={true}
                    render={(props) => (
                        <CalendarDetail
                            router={props}
                            defaults={{
                                homeUrl: routes.private.CALENDAR_DETAIL,
                                page: getParameterByName("page", window.location.href, 'Number') ?? 1,
                                sort: getParameterByName("sort", window.location.href, 'String'),
                                search: getParameterByName("search", window.location.href, 'String'),
                                perPage: getParameterByName("perPage", window.location.href, 'Number', constants.PAGINATION_DEFAULTS) || constants.PAGINATION_DEFAULTS[0],
                                communityId: props.match.params.communityId ?? null
                            }} />
                    )} />

                <Route
                    path={routes.private.SETTINGS_CONTACT_CREATE}
                    exact={true}
                    render={(props) => (
                        <ContactDetail
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.SETTINGS_CONTACT_CATEGORY_CREATE}
                    exact={true}
                    render={(props) => (
                        <CategoryDetail
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.SETTINGS_CONTACT_DETAIL}
                    exact={true}
                    render={(props) => (
                        <ContactDetail
                            detail
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />

                <Route
                    path={routes.private.SETTINGS_CONTACT_CATEGORY_DETAIL}
                    exact={true}
                    render={(props) => (
                        <CategoryDetail
                            detail
                            defaults={{
                                homeUrl: props.location.pathname,
                                communityId: props.match.params.communityId ?? null
                            }}
                            router={props} />
                    )} />

                <Route
                    path={[routes.private.UPDATE_DETAILS, routes.private.UPDATE_DETAILS_NO_COMM]}
                    exact={true}
                    render={(props) => (
                        <ProfileDetails />
                    )} />

                <Route
                    path={routes.private.USER_DETAIL_UPDATE_EMAIL}
                    exact={true}
                    render={(props) => (
                        <UpdateEmail />
                    )} />

                <Route
                    path={routes.private.USER_DETAIL_UPDATE_PHONE}
                    exact={true}
                    render={(props) => (
                        <UpdatePhone />
                    )} />

                <Route
                    path={routes.private.USER_DETAIL_UPDATE_PHONE_OTP}
                    exact={true}
                    render={(props) => (
                        <OtpScreen {...props} />
                    )} />

                <Route
                    path={routes.private.USER_DETAIL_UPDATE_EMAIL_OTP}
                    exact={true}
                    render={(props) => (
                        <OtpScreen {...props} />
                    )} />

                <Route
                    path={routes.private.CALENDAR_GOOGLE_AUTH_CALLBACK}
                    exact={true}
                    component={GoogleRedirectCallback} />

                <Route
                    path={routes.private.CALENDAR_MICROSOFT_AUTH_CALLBACK}
                    exact={true}
                    component={MicrosoftRedirectCallback} />

                <Route
                    path={routes.private.CALENDAR_CHOOSE_SYNC_METHODS}
                    exact={true}
                    render={(props) => (
                        <LinkCalendarChooser {...props} />
                    )} />
                
                <Route
                    path={routes.private.CALENDAR_RE_AUTH}
                    exact={true}
                    render={(props) => (
                        <ReAuthCalendar {...props} />
                    )} />

                <Route path={routes.public.WEB_FORM} />

                {/* Fallback to home */}
                <Redirect
                    to={routes.private.HOME} />
                </Switch>
        </Suspense>
    )
}
