import { actions, builderConstants, formTypes, messageBuilder, templateIcons, votingTypes } from '../constants'
import { initialData, activeData, resetData } from '../constants/initial-data'
import { mapKeys, merge, map, without, mapValues, every, uniq, assign, clone, cloneDeep } from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import logo from "../images/example_logo_2.png"
import DineroFactory from 'dinero.js'
import { getTasksFromResponse, getTasksFromTemplateResponse } from '../utils/helper'

const { BUILDER } = actions

const initialState = {
    loading: true,
    loadingTemplates: true,
    showPreview: false,
    togglePreview: false,
    showPublish: false,
    canPublish: false,
    filters: {
        channels: [],
    },
    recipients: {
        channels: [],
        persons: [],
    },
    templates: {
        columnOrder: []
    },
    _templates: {},
    contentTemplates: activeData,
    errors: null,
    errorCode: null,
    noticeTitle: "",
    previewData: {
        listItem: {
            icon: logo,
            title: null,
            description: "",
            communityName: "Community Name",
            createdAt: null,
            important: false,
            pinItem: false,
        },
        messageView: {
            title: null,
            items: {}
        }
    }
}

const defaultContentState = {
    "blocks": [
        {
            "key": "637gr",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
        }
    ],
    "entityMap": {}
}

/**
 * Generate tasks from the template
 * 
 * @param {Object} template 
 */
const _getTasksFromTemplate = ({template, oTasks}) =>  {
    
    let components = template.components

    // Save various types of Forms
    components = map(components, c => {
        
        switch (c) {
            case formTypes.yesNo.post_name:
            case formTypes.textField.post_name:
            case formTypes.fileUpload.post_name:
            case formTypes.imageUpload.post_name: 
            case formTypes.multipleChoice.post_name: {
                return 'form'
            }
            default:
                return c
        }
    })

    // Create a list of current tasks
    let _oTasks = map(oTasks)

    // Map the current list using the types, for ease of search
    _oTasks = mapKeys(_oTasks, o => o.type)

    // Now that the tasks are mapped by type, we can lookup  our components
    // Since our component list only  contains types
    components = components.map(cc => _oTasks[cc])

    return components
}

/**
 * Generate tasks from the template with data
 * 
 * @param {Object} template 
 */
const _getTasksFromTemplateWithData = ({template, oTasks}) =>  {
    
    let components = template.components

    // Save various types of Forms
    components = map(components, c => {
        
        switch (c.type) {
            case formTypes.yesNo.post_name:
            case formTypes.textField.post_name:
            case formTypes.fileUpload.post_name:
            case formTypes.imageUpload.post_name: 
            case formTypes.multipleChoice.post_name: {
                return {
                    ...c,
                    type: 'form'
                }
            }
            default:
                return c
        }
    })

    // Create a list of current tasks
    let _oTasks = map(oTasks)

    // Map the current list using the types, for ease of search
    _oTasks = mapKeys(_oTasks, o => o.type)

    // Now that the tasks are mapped by type, we can lookup  our components
    // Since our component list only  contains types
    components = components.map(cc => {
        return {..._oTasks[cc.type], data: cc.data ?? {}}
    })

    return components
}

const transformTemplateData = (t) => {
    return {
        id: t.id, 
        icon: t.icon, 
        caption: t.name, 
        notice_title: t.notice_title, 
        add_on_required: t.add_on_required,
        upgrade_required: t.upgrade_required,
    }
}

const toTemplateWithData = (t, _transformedTasks) => {
    const components = getTasksFromTemplateResponse({response: t})
    return {
        id: t.id,
        name: t.name,
        users: t.users,
        persons: t.persons,
        channels: t.channels,
        important: t.important,
        deadline_at: t.deadline_at,
        notice_title: t.notice_title,
        target_everyone: t.target_everyone,
        publish_relationship: t.publish_relationship,
        send_deadline_reminders: t.send_deadline_reminders,
        tasks: _getTasksFromTemplateWithData({
            template: {components: components}, 
            oTasks: _transformedTasks
        }),
        type: templateIcons.customTemplate,
        icon_name: t.icon,
        isFavourite: true,
        isUserTemplate: false,
    }
}

/**
 * Transform the templates for usage on side menu
 * 
 * @param {*} response 
 */
const _transformSidebarTemplates = ({response})  => {
    // add the Public Templates first
    const publicTemplates = map(response.public_templates, t => transformTemplateData(t))

    // Then add Community Templates
    const communityTemplates = map(response.community_templates, t => transformTemplateData(t))
    
    // Then add User Templates
    const userTemplates = map(response.user_templates, t => transformTemplateData(t))

    return {
        user: userTemplates,
        public: publicTemplates,
        community: communityTemplates
    }
}

/**
 * Transform the response to conform to the standard of how templates are used
 * with React-Beautiful-DnD
 * 
 * @param {Object} response 
 */
const _transformTemplates = ({response, tieredTasks})  => {
    
    const data = {
        tasks: [],
        columns: {...initialData.columns},
        columnOrder: [...initialData.columnOrder]
    }

    let _transformedTasks = map(initialData.tasks, t => {
        let tiering = tieredTasks[t.type] ?? {upgrade_required: false}

        if (t.type === templateIcons.form) {
            tiering = mapKeys(map(formTypes, "post_name").map(t => tieredTasks[t]), t => t.type)
            tiering.upgrade_required = every(map(tiering, "upgrade_required"), i => i === true)
        }
        

        return {
            ...t,
            disabled: tiering.upgrade_required ?? false,
            tiering: tiering
        }
    })

    _transformedTasks = mapKeys(_transformedTasks, (task, key) => task.id)

    // Lets create the tasks
    // The first set of tasks we should have will be the initial which will not be dynamic

    // Initial tasks
    const initialTasks = map(_transformedTasks)
    
    // add the Public Templates first
    let publicTemplates = response.public_templates
    
    // Then add Community Templates
    let communityTemplates = response.community_templates
    
    // Then add User Templates
    let userTemplates = response.user_templates
    
    // Decorate our templates to match our task standard format
    publicTemplates = map(publicTemplates, t => toTemplateWithData(t, _transformedTasks))
    
    // Decorate our templates to match our task standard format
    communityTemplates = map(communityTemplates, t => toTemplateWithData(t, _transformedTasks))
    
    // Decorate our templates to match our task standard format
    userTemplates = map(userTemplates, t => toTemplateWithData(t, _transformedTasks))
    userTemplates = map(userTemplates, t => {
        return {...t, isUserTemplate: true}
    })

    // Merge all the templates
    data.tasks = data.tasks
        .concat(initialTasks)
        .concat(publicTemplates)
        .concat(communityTemplates)
        .concat(userTemplates)

    // Convert Public Templates from List to Map<K,V>, where K=task.id
    data.tasks = mapKeys(data.tasks, (task, key) =>  task.id)

    // Assign tasks to respective columns
    // First we assign all the public templates ids to 'column-1', which  is "Templates"
    data.columns['column-1'].taskIds = map(publicTemplates, "id")
        .concat(map(communityTemplates, "id"))
        .concat(map(userTemplates, "id"))
    
    // Add the rest of the initial tasks into 'column-2', which  is "Components"
    data.columns['column-2'].taskIds = map(initialTasks, "id")
    
    return data
}

/**
 * We need to transform the data that we get into a "fake" template because thats the signature of what a complete message is
 * a complete message is a collection of components similar to templates, but unlike templates
 * the message has components that has data that should be retained on the builder area.
 * 
 * @param {Object} response 
 */
const _transformToEditableMessageTemplates = ({response, state, noticeId})  => {
    
    const data = {
        tasks: map(state.templates.tasks),
        columns: cloneDeep(state.templates.columns),
        columnOrder: state.templates.columnOrder
    }

    // Most of the legwork has already been done by the template creator, so what we need is to simply inject this message
    // template into the existing list of tasks
    
    // Capture the components and their data respectively
    const components = getTasksFromResponse({response: response})

    // create the message template structure
    const messageTemplate = {
        name: "Edit Message",
        components: map(components, "type"),
        icon: null,
        isFavourite: false,
        isUserTemplate: false,
        notice_title: response.title,
        id: noticeId, // We can use the noticeId to make it easier to grab thi
        type: templateIcons.customTemplate,
        created_at: moment().toISOString(),
        tasks: _getTasksFromTemplateWithData({
            template: {components: components},
            oTasks: state.templates.tasks
        })
    }

    // Merge all the templates
    data.tasks = data.tasks
        .concat([messageTemplate])

    // Convert Templates from List to Map<K,V>, where K=task.id
    data.tasks = mapKeys(data.tasks, (task, key) =>  task.id)

    // Assign tasks to respective columns
    // Add the message templates to 'column-5', which is "Edit Message"
    data.columns[builderConstants.messageEditArea].taskIds = map([messageTemplate], "id")
    
    return data
}

/**
 * We need to transform the data that we get into a "fake" template because thats the signature of what a complete message is
 * a complete message is a collection of components similar to templates, but unlike templates
 * the message has components that has data that should be retained on the builder area.
 * 
 * @param {Object} response 
 */
const _transformToEditableTemplate = ({payload, state})  => {
    
    const data = {
        tasks: map(state.templates.tasks),
        columns: cloneDeep(state.templates.columns),
        columnOrder: state.templates.columnOrder
    }

    // Most of the legwork has already been done by the template creator, so what we need is to simply inject this message
    // template into the existing list of tasks
    
    const { template, builderData } = payload

    // Capture the components and their data respectively
    const components = [].concat(template.tasks)

    // create the message template structure
    const messageTemplate = {
        name: template.name,
        components: map(components, "type"),
        icon: null,
        isFavourite: false,
        isUserTemplate: template.isUserTemplate ?? false,
        notice_title: template.notice_title,
        id: template.id, // We can use the noticeId to make it easier to grab thi
        type: templateIcons.customTemplate,
        created_at: moment().toISOString(),
        tasks: components,
    }

    // Merge all the templates
    data.tasks = data.tasks
        .concat([messageTemplate])

    // Convert Templates from List to Map<K,V>, where K=task.id
    data.tasks = mapKeys(data.tasks, (task, key) =>  task.id)

    // Assign tasks to respective columns
    // Add the message templates to 'column-5', which is "Edit Message"
    data.columns[builderConstants.messageEditArea].taskIds = map([messageTemplate], "id")
    
    const editableContent = {
        community_id: builderData.community.id,
        community_name: builderData.community.name,
        community_image: builderData.community.image,
        channels: builderData.channels,
        users: builderData.users,
        persons: builderData.persons,
        notice_title: builderData.title,
        important: builderData.important,
        deadline_at: builderData.deadline_at,
        target_everyone: builderData.target_everyone,
        publish_relationship: builderData.publish_relationship,
        send_deadline_reminders: builderData.send_deadline_reminders,
        components: components,
    }
    
    return {
        templates: data, 
        editableContent
    }
}

/**
 * Handle dragging and dropping of tasks on the sidebar
 * 
 * @param {Object}  params
 */
const _handleDnDSidebar = ({data, state}) => {
    const { destination, source, draggableId } = data

    const start = state.templates.columns[source.droppableId]
    const finish = state.templates.columns[destination.droppableId]

    // Future allow some special functions when these events happen
    // We are not going to allow dragging from one list to another at the moment
    // Also disabling the moving tasks around in the same list
    if (start === finish)  {
        // Do Nothing
        return {...state}
    }
    
    if (start !== finish)  {
        // Do Nothing
        return {...state}
    }

    // Dragging around the same list
    if (start === finish) {
        const newTaskIds = Array.from(start.taskIds)
        newTaskIds.splice(source.index, 1)
        newTaskIds.splice(destination.index, 0, draggableId)

        const newColumn = {
            ...start,
            taskIds: newTaskIds
        }

        const newState = {
            ...state.templates,
            columns: {
                ...state.templates.columns,
                [newColumn.id]: newColumn
            }
        }

        return {
            ...state,
            templates: newState
        }
    }

    // Moving from one list to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
        ...start,
        taskIds: startTaskIds
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
        ...finish,
        taskIds: finishTaskIds
    }

    const newState = {
        ...state.templates,
        columns: {
            ...state.templates.columns,
            [newStart.id]: newStart,
            [newFinish.id]: newFinish
        }
    }

    return {
        ...state, 
        templates: newState,
    }
}

/**
 * 
 * @param {Object} param
 */
const _generateNewTaskId = ({oTaskId, state}) => uuidv4()

/**
 * Generate new task onDrop of component or template on content-area
 * 
 * @param {{state, tasks, data}} param0 
 */
const _generateNewTasks = ({state, tasks, data}) => {
    
    const { destination, source, draggableId } = data

    const finish = state.templates.columns[destination.droppableId]
    let newTemplates = state.contentTemplates.tasks
    let finishTaskIds = Array.from(state.contentTemplates.columns[builderConstants.contentArea].taskIds)
    const itemsCount = Array.from(state.contentTemplates.columns[builderConstants.contentArea].taskIds).length
    
    const draggable = state.templates.tasks[draggableId]
    let _oTaskId = draggableId

    // When the Draggable is a template, we should use the Task id of the first task in the Template's tasks
    if (draggable.type === templateIcons.customTemplate && finishTaskIds.length > 0) {
        _oTaskId = finishTaskIds[0]
    }

    map(tasks, task => {
        const newId = _generateNewTaskId({
            oTaskId: _oTaskId,
            state
        })

        const newTask = {
            ...task,
            id: newId,
            isNew: true,
        }

        // Mark old items as old
        newTemplates = mapValues(newTemplates, nt => {
            return {
                ...nt,
                isNew: false,
            }
        })

        newTemplates = merge(newTemplates, {[newId]: newTask})

        if (draggable.type === templateIcons.customTemplate) {
            
            // Dropping another template on the content area resets the order of the components in the current template being dropped
            if (itemsCount === 0) {
                finishTaskIds = finishTaskIds.concat(newId)
            } else {
                finishTaskIds.splice(destination.index, 0, newId)
            }
        } else {
            finishTaskIds.splice(destination.index, 0, newId)
        }
    })
    
    const newFinish = {
        ...finish,
        taskIds: finishTaskIds
    }

    return {
        newFinish,
        newTemplates
    }
}

/**
 * 
 * @param {Object} param 
 */
const _generateNewTaskFromDrop = ({data, state}) => {
    
    const { draggableId } = data

    const task = state.templates.tasks[draggableId]

    // TODO: Handle bug when there is no task found here
    // Here we need to determine whether we are dropping a component or a template
    // When we drop a Template, we need to extract the components from the task
    if (task.type === templateIcons.customTemplate) {
        
        // Grab the tasks and generate new data
        const _data = _generateNewTasks({state, tasks: task.tasks, data})
        return {..._data, noticeTitle: task.notice_title}
    }

    return _generateNewTasks({state, tasks: [task], data})
}

/**
 * 
 * @param {Object} param 
 */
const _duplicateTask = ({originalTask, state}) => {

    const newId = _generateNewTaskId({
        oTaskId: originalTask.id.replace('a-', ''), // Just stripping away the 'a-' prefix
        state
    })

    const newTask = {
        ...originalTask,
        id: newId,
        data: {},
        isNew: true,
    }

    // Text component gets special treatment
    if ([templateIcons.text, templateIcons.event].includes(newTask.type)) {
        newTask.data = {
            content: "",
            jsonContent: defaultContentState
        }
    }
    
    // Form component also gets special treatment
    if ([templateIcons.form].includes(newTask.type)) {
        newTask.data = {
            formType: formTypes.textField.post_name,
        }
    }
    
    // Template component also gets special treatment
    if ([templateIcons.payment].includes(newTask.type)) {
        newTask.data = {
            type: null,
            currency: DineroFactory.defaultCurrency,
            amount: 0,
            floatAmount: 0,
        }
    }

    // Mark old items as old
    const _oldTemplates = mapValues(state.contentTemplates.tasks, nt => {
        return {
            ...nt,
            isNew: false,
        }
    })

    const newTemplates = merge(_oldTemplates, {[newId]: newTask})
    
    const taskIds = Array.from(state.contentTemplates.columns[builderConstants.contentArea].taskIds)

    // We always working on the same column [builderConstants.contentArea]
    const column = state.contentTemplates.columns[builderConstants.contentArea]

    // We need to get the original item's index in the list, so that we can insert after
    const index = taskIds.indexOf(originalTask.id)

    // Insert the new task id into the tasks list immediately after the original item
    taskIds.splice(index + 1, 0, newId)

    const newColumn = {
        ...column,
        taskIds: taskIds
    }

    return {
        newColumn,
        newTemplates
    }
}

/**
 * Handle the drag and dropping of items on the content area
 * 
 * @param {Object}  params
 */
const _handleDnDContent = ({data, state}) => {
    
    const { destination, source, draggableId } = data

    const finish = state.templates.columns[destination.droppableId]
    const start = state.templates.columns[source.droppableId]
    const cachedData = JSON.parse(localStorage.getItem(messageBuilder.BUILDER_CACHE_KEY) ?? '{"columns":{},"tasks":{}}')

    // Dragging around the same list
    if (start === finish) {
        // We will always be using the content template tasks than the originals
        const newTaskIds = Array.from(state.contentTemplates.columns[destination.droppableId].taskIds)
        newTaskIds.splice(source.index, 1)
        newTaskIds.splice(destination.index, 0, draggableId)

        const newColumn = {
            ...start,
            taskIds: newTaskIds
        }

        // Re-order the tasks to match the order of the parent column they reside
        // We will create new tasks from the original
        const tasks = newColumn.taskIds.map(taskId => {
            const task = state.contentTemplates.tasks[taskId]
            const cacheOrReal = cachedData.tasks?.[task.id]?.data ?? task.data ?? {}
            return {
                ...task,
                data: cacheOrReal
            }
        })

        return {
            ...state,
            contentTemplates: {
                ...state.contentTemplates,
                columns: {
                    [newColumn.id]: newColumn
                },
                // We will also update the status of the items in the list to isNew=false, because at this stage these items are just being shuffled around
                tasks: mapValues(state.contentTemplates.tasks, ct => {
                    const cacheOrReal = cachedData.tasks?.[ct.id]?.data ?? ct.task ?? {}
                    return {
                        ...ct,
                        data: cacheOrReal,
                        isNew: false,
                    }
                })
            },
            previewData: {
                ...state.previewData,
                messageView: {
                    items: tasks
                }
            },
        }
    }
    
    // Create a new task as a copy of the current task, also give it a  new id
    const { 
        newFinish, 
        newTemplates, 
        noticeTitle
    } = _generateNewTaskFromDrop({data, state})

    return {
        ...state,
        noticeTitle: noticeTitle || state.previewData.listItem.title,
        contentTemplates: {
            ...state.contentTemplates,
            columns: {
                [newFinish.id]: newFinish,
            },
            tasks: newTemplates
        }
    }
}

/**
 * We are going to use the selected template to dynamically drop its contents into 
 * the content area AKA "column-4"
 * 
 * Since we want to try and not repeat ourselves too much, we will reuse most of the code for _handleDnDContent
 * now in order to do that successfully, we have made inspection of what the result of a <Draggable> yields
 * and in  short we will transform this template to match the APIs needed for a Draggable item
 * 
 * @link <Draggable> https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md
 * @param {String} templateId 
 * @param {String} templateIndex 
 */
const _transformDynamicTemplates = (templateId, templateIndex) => {
    
    const { contentArea, templateArea } = builderConstants
    
    // We know that the destination will never change for our content area, so this will be a fixed place being "column-4"
    const destination = {
        droppableId: contentArea,
        index: 0,
    }

    // We know  the source for our templates is also the same place all the time, and this will not change "column-1"
    const source = {
        droppableId: templateArea,
        index: templateIndex
    }

    const draggable = {
        source,
        destination,
        draggableId: templateId
    }

    return draggable
}

/**
 * When a component/task is clicked, we need to dynamically simulate a DnD to be able  to add the clicked item onto the builder's 
 * content area "column-4"
 * 
 * @param {*} taskId 
 * @param {*} taskIndex 
 * @param {*} newIndex 
 */
const _transformDynamicComponent = (taskId, taskIndex, newIndex) => {
    
    const { contentArea, componentArea } = builderConstants

    // We know that the destination will never change for our content area, so this will be a fixed place being "column-4"
    // However: The {index} will determine where inside the content area the component/task should be placed
    // so we need to customize that anything that is added with a click, is just appended at the bottom of the list
    const destination = {
        droppableId: contentArea,
        index: newIndex,
    }

    // We know  the source for our components/tasks is also the same place all the time, and this will not change "column-2"
    const source = {
        droppableId: componentArea,
        index: taskIndex
    }

    const draggable = {
        source,
        destination,
        draggableId: taskId
    }

    return draggable
}

/**
 * @param state
 * @param action <Promise>
 */
export default function reducer(state = initialState, action) {

    switch (action.type) {
        case BUILDER.START: {
            return {...state, loading: true}
        }
        case BUILDER.START_TEMPLATES: {
            return {...state, loadingTemplates: true}
        }
        case BUILDER.CLEAR_MESSAGES: {
            return {...state, messages: null, errors: null, busyIndicator: null, assistedWriting: null}
        }
        case BUILDER.COMPOSE_CLEAR: {
            
            // localStorage.removeItem(messageBuilder.BUILDER_CACHE_KEY)
            
            return {
                ...state,
                noticeTitle: "" ,
                contentTemplates: {
                    ...resetData.content,
                    tasks: {}
                }, 
                recipients: {
                    channels: [],
                    persons: [],
                    targetEveryone: null,
                },
                previewData: resetData.preview,
                busyIndicator: null,
            }
        }
        case BUILDER.COMPOSE_CLEAR_EDIT: {
            return {
                ...state,
                editableContent: null,
            }
        }
        case BUILDER.SUCCESS: {
            return {...state, loading: false, loadingTemplates: false, errors: null, errorCode: null, messages: action.payload}
        }
        case BUILDER.SHOW_PREVIEW: {
            return {...state, showPreview: true, togglePreview: false}
        }
        case BUILDER.TOGGLE_PREVIEW: {
            return {...state, togglePreview: action.payload}
        }
        case BUILDER.HIDE_PREVIEW: {
            return {...state, showPreview: false, togglePreview: false}
        }
        case BUILDER.ERROR: {
            const error = action.payload.response || {}
            
            const message = error.data?.message ?? "error.errorOccurred"

            return {
                ...state, 
                loading: false,
                loadingTemplates: false, 
                errors: message,
                errorCode: error?.data?.code ?? null
            }
        }
        case BUILDER.SAVE_CHANNELS: {
            return {
                ...state, 
                loading: false, 
                errors: null,
                errorCode: null,
                filters: {
                    ...state.filters,
                    channels: map(action.payload, r => {return {key: r.id, value: r.id, text: r.name}}),
                },
            }
        }
        case BUILDER.SAVE_RESOURCE_GROUPS: {
            return {
                ...state, 
                loading: false, 
                errors: null,
                errorCode: null,
                filters: {
                    ...state.filters,
                    resourceGroups: map(action.payload, r => {return {key: r.id, value: r.id, text: r.name}}),
                },
            }
        }
        case BUILDER.SAVE_TEMPLATE: {
            return {
                ...state, 
                loading: false,
                loadingTemplates: false,
                errors: null,
                errorCode: null,
                template: action.payload,
            }
        }
        case BUILDER.SHOW_PUBLISH_MODAL: {
            return {
                ...state,
                canPublish: action.payload.canPublish, 
                showPublish: action.payload.showPublish ?? true,
            }
        }
        case BUILDER.CLEAR_PUBLISH_MODAL: {
            return {
                ...state, 
                canPublish: false,
                showPublish: false,
            }
        }
        case BUILDER.SAVE_TEMPLATES: {
            return {
                ...state, 
                loading: false, 
                errors: null,
                errorCode: null,
                loadingTemplates: false,
                _templates: _transformSidebarTemplates({
                    response: action.payload.templates
                }),
                templates: _transformTemplates({
                    response:  action.payload.templates,
                    tieredTasks: action.payload.tieredTasks,
                }),
                blankNoticeDefaultComponents: action.payload.blankNoticeDefaultComponents ?? [],
            }
        }
        case BUILDER.COMPOSE_TEMPLATE_DYNAMIC: {
            
            const templateId = action.payload.id
            const isCustom = action.payload.is_custom
            const noticeTitle = action.payload.noticeTitle || state.previewData.listItem.title
            const templateIndex = action.payload.index
            const transformedTemplates = _transformDynamicTemplates(templateId, templateIndex)
            const data = _handleDnDContent({data: transformedTemplates, state: {...state}})
            
            return {...data, noticeTitle: noticeTitle, is_custom: isCustom}
        }
        case BUILDER.SAVE_EDIT_MESSAGE: {
            
            const templates = _transformToEditableMessageTemplates({
                state: state,
                noticeId: action.payload.noticeId,
                response: action.payload,
            })

            return {
                ...state, 
                loading: false, 
                errors: null,
                errorCode: null,
                loadingTemplates: false,
                editableContent: action.payload,
                templates: templates,
            }
        }
        case BUILDER.SAVE_EDIT_TEMPLATE_MESSAGE: {
            
            const {templates, editableContent} = _transformToEditableTemplate({
                state: state,
                payload: action.payload,
            })

            return {
                ...state, 
                loading: false, 
                errors: null,
                errorCode: null,
                loadingTemplates: false,
                editableContent: editableContent,
                templates: templates,
            }
        }
        case BUILDER.COMPOSE_COMPONENT_DYNAMIC: {

            const taskId = action.payload.id
            const oldIndex = action.payload.index

            const itemsCount = Array.from(state.contentTemplates.columns[builderConstants.contentArea].taskIds).length
            const newIndex = itemsCount + 1
            const transformedTasks = _transformDynamicComponent(taskId, oldIndex, newIndex)
            const data = _handleDnDContent({data: transformedTasks, state: state})
            
            return {...data}
        }
        case BUILDER.COMPOSE_TEMPLATE: {

            const { destination } = action.payload

            // When  we are not dropping the tasks on the content, we need to handle differently
            if (destination.droppableId !== builderConstants.contentArea) {
                return _handleDnDSidebar({data: action.payload, state: state})
            }

            return _handleDnDContent({data: action.payload, state: state})
        }
        case BUILDER.COMPOSE_SAVE_LIST_ITEM_PREVIEW: {
            return {
                ...state,
                previewData: {
                    ...state.previewData,
                    listItem: {
                        ...state.previewData.listItem,
                        title: action.payload.title,
                        pinItem: action.payload.pinItem,
                        important: action.payload.important,
                        icon: action.payload.communityLogo,
                        createdAt: action.payload.createdAt,
                        communityName: action.payload.communityName,
                    }
                }
            }
        }
        case "@@story.init": {
            return {
                ...state,
                contentTemplates: action.payload,
                previewData: {
                    ...state.previewData,
                    messageView: {
                        items: action.payload.tasks
                    }
                },
            }
        }
        case BUILDER.BUSY_INDICATOR: {
            return {
                ...state,
                busyIndicator: action.payload,
            }
        }
        case BUILDER.ASSISTED_WRITING: {
            return {
                ...state,
                assistedWriting: action.payload,
            }
        }
        case BUILDER.COMPOSE_SAVE_TEMPLATE: {

            const task =  action.payload.item
            const data = action.payload.data

            const _tasks = state.contentTemplates.columns[builderConstants.contentArea].taskIds.map(
                taskId => {
                    if (taskId === task.id) {
                        return {
                            ...state.contentTemplates.tasks[taskId],
                            data: assign({}, data),
                        }
                    }
                    
                    return {
                        ...state.contentTemplates.tasks[taskId]
                    }
                }
            )

            const newCachedData = {
                ...state.contentTemplates,
                tasks: mapKeys(_tasks, t => t.id)
            }
            
            localStorage.setItem(messageBuilder.BUILDER_CACHE_KEY, JSON.stringify(newCachedData))

            return {
                ...state,
                contentTemplates: {
                    ...state.contentTemplates,
                    tasks: mapKeys(_tasks, t => t.id)
                },
                previewData: {
                    ...state.previewData,
                    messageView: {
                        items: _tasks
                    }
                },
            }
        }
        case BUILDER.COMPOSE_DUPLICATE_ITEM: {

            const { newColumn, newTemplates } = _duplicateTask({
                originalTask: action.payload, 
                state
            })

            const tasks = map(newColumn.taskIds, taskId => newTemplates[taskId])

            return {
                ...state,
                contentTemplates: {
                    ...state.contentTemplates,
                    columns: {
                        [newColumn.id]: newColumn
                    },
                    tasks: newTemplates
                },
                previewData: {
                    ...state.previewData,
                    messageView: {
                        items: tasks
                    }
                },
            }
        }
        case BUILDER.COMPOSE_DELETE_ITEM: {
            
            const task = action.payload

            // Find the original task in the current list
            const oTask = state.contentTemplates.tasks[task.id]
            const column = state.contentTemplates.columns[builderConstants.contentArea]
            const newTaskIds = without(column.taskIds, oTask.id)

            // Create new list of tasks from the newly filtered tasks
            const _newTasks = map(newTaskIds, taskId => state.contentTemplates.tasks[taskId])
            
            // Reset all items to isNew=false, since we are deleting at least one
            const newTasks = map(_newTasks, t => {
                return {...t, isNew: false}
            })

            // Convert the list into an Object mapped by the task id
            const tasks = mapKeys(newTasks, (task, key) => task.id)

            return {
                ...state,
                contentTemplates: {
                    ...state.contentTemplates,
                    columns: {
                        [column.id]: {
                            ...column,
                            taskIds: newTaskIds
                        }
                    },
                    tasks: tasks
                },
                previewData: {
                    ...state.previewData,
                    messageView: {
                        items: tasks
                    }
                },
            }
        }
        case BUILDER.GENERAL_DISPATCHER: {

            return {...state, custom: action.payload}
        }
        case BUILDER.CLEAN_GENERAL_DISPATCHER: {

            return {...state, custom: null}
        }
        case BUILDER.SAVE_RECIPIENTS: {
            
            const persons = action.payload?.users ?? []
            const channels = action.payload?.channels ?? []
            const targetEveryone = action.payload?.targetEveryone

            return {
                ...state,
                recipients: {
                    persons: uniq([...state.recipients.persons, ...persons]),
                    channels: uniq([...state.recipients.channels, ...channels]),
                    targetEveryone,
                },
            }
        }
        default: {}
    }

    return {
        ...state
    }
}